<mat-list-item [disableRipple]="bcs.disableRippleEffect"
  [style.background-color]="(selectedProspect$ | async)?.id == prospect.id ? 'rgba(158, 158, 158, 0.2)': null">
  <div mat-list-icon class="type-icon"
    [ngClass]="prospect.typeId == 3 ? prospect.vendorId && prospect.associateId == null ? 'lead' : 'showroom' : 'retention'"
    fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="prospect.typeId == 3; else retention">
      <div *ngIf="!prospect.vendorId; else lead">S</div>
      <ng-template #lead>
        <div *ngIf="prospect.associateId == null; else matchedLead">L</div>
        <ng-template #matchedLead>
          <div>S</div>
        </ng-template>
      </ng-template>
    </div>
    <ng-template #retention>
      R
    </ng-template>
  </div>
  <div *ngIf="prospect?.embedded?.associate; else freeform" mat-line fxLayoutAlign="start center">
    <span
      [style.font-weight]="(prospect.updatedDate == null || updatedDate <= enteredDate) && prospect.typeId == 3? '500': null">
      {{prospect.embedded?.associate?.classId ==='RETAI' ?
      prospect?.embedded?.associate?.firstName : prospect?.embedded?.associate?.firstName && prospect?.embedded?.associate?.lastName ?
      prospect.embedded?.associate?.lastName + ', ' + prospect.embedded?.associate?.firstName : prospect.embedded?.associate?.firstName}}
    </span>
    <span *ngIf="localSettings && localSettings.salesPeople.length > 1 && prospect?.embedded?.primaryEmployeeAssociate"
      class="salesperson-span">
      {{prospect?.embedded?.primaryEmployeeAssociate?.lastName && prospect?.embedded?.primaryEmployeeAssociate?.firstName ?
        ' - ' +  prospect.embedded.primaryEmployeeAssociate.lastName + ', ' + prospect.embedded.primaryEmployeeAssociate.firstName :
        ' - ' + prospect.embedded.primaryEmployeeAssociate.firstName}}</span>
  </div>
  <ng-template #freeform>
    <div mat-line fxLayoutAlign="start center">
      <span>{{prospect.freeformName}}</span>
      <span
        *ngIf="localSettings && localSettings.salesPeople.length > 1 && prospect?.embedded?.primaryEmployeeAssociate"
        class="salesperson-span">
        {{prospect?.embedded?.primaryEmployeeAssociate?.lastName && prospect?.embedded?.primaryEmployeeAssociate?.firstName ?
          ' - ' +  prospect.embedded.primaryEmployeeAssociate.lastName + ', ' + prospect.embedded.primaryEmployeeAssociate.firstName :
          ' - ' + prospect.embedded.primaryEmployeeAssociate.firstName}}</span>
    </div>
  </ng-template>
  <p mat-line class="secondary-text" *ngIf="prospect.typeId == 4">Available {{prospect.availableDate | moment: 'dddd,
    MMM Do, YYYY'}}</p>
  <p mat-line class="secondary-text"
    *ngIf="prospect.typeId == 3 && (prospect.updatedDate != null && updatedDate > enteredDate); else entered">Updated
    {{prospect.updatedDate | moment: 'dddd, MMM Do, YYYY'}}</p>
  <ng-template #entered>
    <p mat-line class="secondary-text" *ngIf="prospect.typeId == 3">Entered {{prospect.enteredDate | moment: 'dddd, MMM
      Do, YYYY'}}</p>
  </ng-template>
  <p mat-line *ngIf="prospect.typeId == 3" class="secondary-text">{{prospect.vehicleDescription}}</p>
  <p mat-line *ngIf="prospect.typeId == 4" class="secondary-text">{{prospect.embedded?.vehicle?.year + ' '
    +prospect.embedded?.vehicle?.make
    + ' ' + prospect.embedded?.vehicle?.model }}</p>
</mat-list-item>