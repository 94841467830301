import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compareObjects',
})
export class CompareObjectsPipe implements PipeTransform {
  transform(object1: any, object2: any): any {
    if (object1 == null || object2 == null) {
      return false;
    }

    let flag = true;
    const keys = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys.length !== keys2.length) {
      return false;
    }

    for (let i = 0; i < keys.length; i++) {
      if (object1[keys[i]] != object2[keys[i]] && keys[i] != 'embedded' && keys[i] != 'updatedDate') {
        flag = false;
        break;
      }
    }

    return flag;
  }
}
