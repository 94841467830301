import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AssociateReport } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-associate-card',
  templateUrl: './associate-card.component.html',
  styleUrls: ['./associate-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssociateCardComponent implements OnInit {
  @Input() associate: AssociateReport;
  @Input() selected: boolean;
  @Input() associateSearchTypeLookup: any;

  constructor() {}

  ngOnInit() {}
}
