import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './+state/settings.reducer';
import { CrmSettingsStateService } from './crm-settings-state.service';
@NgModule({
  imports: [CommonModule, StoreModule.forFeature('crmSettings', reducer)],
  providers: [CrmSettingsStateService]
})
export class CrmSettingsModule {}
