import { Associate } from '../associates/associate.model'
import { Channel } from './channel.model'
import { CustomerType } from '../associates/customer-type.model';
import { Employee } from '../associates/employee.model';
import { ProspectAction } from './prospect-action.model';
import { ProspectType } from './prospect-type.model';
import { Source } from './source.model';
import { OemRooftopLink } from '../system/oem-rooftop-link.model';
import { Sale } from '../vehicle/sale.model';
import { Vehicle } from '../vehicle/vehicle.model';

export class Prospect {
  actionedVehicleId: number = null;
  actionId: number = null;
  actionWhenLost: number = null;
  associateId: string = null;
  availableDate: Date = null;
  cellPhoneNumber: string = null;
  channelId: number = null;
  closedById: string = null;
  closedDate: Date = null;
  conversionRecordId: string = null;
  customerTypeId: string = null;
  embedded?: ProspectEmbed = null;
  emailAddress: string = null;
  employeeCreatedId: string = null;
  enteredDate: Date = null;
  freeformName: string = null;
  id: number = null;
  leadOutcomeId: number = null;
  matchedDate: Date = null;
  newSaleId: number = null;
  noticeDate: Date = null;
  rooftopId: number = null;
  phoneNumber: string = null;
  previousEmployeeId: string = null;
  primaryEmployeeId: string = null;
  saleId: number = null;
  secondaryEmployeeId: string = null;
  sourceId: number = null;
  statusId: string = null;
  typeId: number = null;
  updatedDate: Date = null;
  usedSearchStockFlag: string = null;
  vehicleDescription: string = null;
  vehicleId: number = null;
  vendorAssociateId: string = null;
  vendorId: string = null;
  vendorLeadId: string = null;
  vendorName: string = null;
  [key: string]: string | Date | number | ProspectEmbed;

  constructor(obj?: Prospect) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class ProspectEmbed {
  action?: ProspectAction = null;
  associate?: Associate = null;
  channel?: Channel = null;
  closedByAssociate?: Associate = null;
  customerType?: CustomerType = null;
  employee?: Employee = null;
  primaryEmployeeAssociate?: Associate = null;
  rooftop?: OemRooftopLink = null;
  sale?: Sale = null;
  source?: Source = null;
  prospectType?: ProspectType = null;
  vehicle?: Vehicle = null;
}
