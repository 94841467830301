import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { CrmAppointmentQueryParameters as AppointmentQueryParameters } from '@quorum/models/xs-query';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromSalesAppointments from './sales-appointments.actions';
import {
  isSalesAppointmentsLoadingSelector,
  selectAll as selectProspectsCombined,
} from './sales-appointments.interfaces';

@Injectable()
export class SalesAppointmentsStateService {
  constructor(private store: Store<any>) {}

  selectSalesAppointmentsLoading() {
    return this.store.pipe(select(isSalesAppointmentsLoadingSelector));
  }

  selectSalesAppointmentsCombined() {
    return combineLatest(this.store.pipe(select(selectProspectsCombined)), this.selectSalesAppointmentsLoading()).pipe(
      map(([salesAppointments, isLoading]) => {
        return { salesAppointments, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result);
      })
    );
  }

  getSalesAppointmentsFromServer(queryParams: AppointmentQueryParameters) {
    this.store.dispatch(new fromSalesAppointments.GetSalesAppointmentsFromServer(queryParams));
  }

  clearSalesAppointmentsFromState() {
    this.store.dispatch(new fromSalesAppointments.ClearSalesAppointmentsFromState());
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
