import { Action } from '@ngrx/store';
import { OemRooftopLinkQueryParameters } from '@quorum/models/xs-query';
import { OemRooftopLink } from '@quorum/models/xs-resource';

export const GET_ROOFTOPS_FROM_SERVER = '[CRM] Get Rooftops From Server';
export class GetRooftopsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_ROOFTOPS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: OemRooftopLinkQueryParameters) {}
}

export const GET_ROOFTOPS_FROM_SERVER_SUCCESS = '[CRM] Get Rooftops From Server Success';
export class GetRooftopsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_ROOFTOPS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { rooftops: OemRooftopLink[] }) {}
}

export const GET_ROOFTOPS_FROM_SERVER_FAILURE = '[CRM] Get Rooftops From Server Failure';
export class GetRooftopsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_ROOFTOPS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type RooftopsAction = GetRooftopsFromServer | GetRooftopsFromServerSuccess | GetRooftopsFromServerFailure;
