<div fxFlexFill fxLayout="column">
  <h1>Create or Match Associate</h1>
  <form mat-dialog-content fxLayout="column" fxLayoutAlign="start start" [formGroup]="leadMatchingForm"
    class="lead-matching-form" fxFlex>
    <div class="options-container" fxLayout.lt-sm="column" fxLayout.gt-xs="row-reverse">
      <div class="prospect-info-div" fxLayout="column" fxFlex="49">
        <div class="card-header-container" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutAlign="start start" class="title-container">
            <span class="mat-body-2">Lead/Freeform Prospect Information</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="card-content-container" fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="center center">
            <div fxLayoutAlign="start center">
              <span class="mat-body-1 card-line-1"> {{data.prospect.freeformName}}</span>
            </div>
            <div fxLayoutAlign="start center">
              <span class="secondary-text">
                {{data.prospect.emailAddress ? data.prospect.emailAddress : 'No email provided'}}</span>
            </div>
            <div fxLayoutAlign="start center">
              <span class="secondary-text" *ngIf="data.prospect.phoneNumber; else noPhone">
                {{data.prospect.phoneNumber | phoneNumber}}</span>
              <ng-template #noPhone>
                <span class="secondary-text">No phone number provided</span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="start start" class="radio-div" fxFlex="49">
        <mat-radio-group fxLayout="column" fxLayoutGap="12px" formControlName="createOrMatch" required
          (change)="autoFocusSearch($event.value)">
          <mat-radio-button [value]="0">
            Create New Associate from Prospect
          </mat-radio-button>
          <mat-radio-button [value]="2">
            Search Existing Associate
          </mat-radio-button>
          <mat-radio-button [value]="1">
            Select Matched Associate
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="column" fxFlex class="content-container"
      *ngIf="leadMatchingForm.get('createOrMatch').value === 1; else matchExistingAssociates">
      <div fxFlex class="loading-container" *ngIf="isLoading$ | async;">
        <div class="loading">Loading</div>
      </div>
      <div fxLayout="column" *ngIf="matchedAssociate$ | async as matchedAssociates"
        [class.disabled]="leadMatchingForm.get('createOrMatch').value === 0">
        <ng-container *ngIf="matchedAssociates.length > 0; else noMatchesFound">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="mat-body-2">Existing Associates</span>
          </div>
          <span class="mat-caption padding-bottom-8">Select an existing associate to match with. Closest matches
            are displayed first. Selecting an associate will display any existing prospects for that associate.</span>
          <div fxLayout="row wrap" fxLayoutAlign="start start">
            <ng-container *ngIf="associates$ | async as associates">
              <crm-associate-card fxFlex="49" fxFlex.gt-md="49" fxFlex.xs="100" *ngFor="let associate of associates"
                [associate]="associate" (click)="selectAssociate(associate, 'matched')"
                [selected]="selectedAssociate?.id === associate.id"
                [associateSearchTypeLookup]="associateSearchTypeLookup"></crm-associate-card>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #noMatchesFound>
          <div fxFlex class="loading-container">
            <span class="mat-body-2">No Matches Found</span>
          </div>
        </ng-template>
        <div id="prospectData">
          <ng-container *ngIf="selectedAssociate">
            <div fxLayout="column">
              <div fxFlex class="loading-container" *ngIf="isLoadingExistingProspectsForSelectedAssociate$ | async">
                <div class="loading">Loading</div>
              </div>
              <div id="yourExistingProspectsList" *ngIf="ownExistingProspects$ | async as existingProspects"
                fxLayout="column">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <span class="mat-body-2">Your Existing Prospects</span>
                  </div>
                  <span fxHide.xs class="mat-caption padding-bottom-8">The following prospects exist on the selected
                    associate.</span>
                </div>
                <div *ngIf="existingProspects && existingProspects.length > 0; else noExistingProspectsFound"
                  fxLayout="row wrap">
                  <crm-prospect-card fxFlex="48" fxFlex.gt-md="48" fxFlex.xs="100"
                    *ngFor="let prospect of existingProspects | orderBy: ['-prospectUpdatedDate', '-prospectEnteredDate']"
                    [prospect]="prospect" [ngClass]="{'card-container': true}"></crm-prospect-card>
                </div>
                <ng-template #noExistingProspectsFound>
                  <div fxFlex class="loading-prospects-container" fxLayoutAlign="center center">
                    <span class="mat-body-2">No Prospects Found</span>
                  </div>
                </ng-template>
              </div>
              <div id="othersExistingProspectsList" fxLayout="column"
                *ngIf="othersExistingProspects$ | async as existingProspects">
                <div fxLayout="column">
                  <div fxLayout="row" fxLayoutAlign="start center">
                    <h3 style="color:#E1322F;font-weight:500;margin-bottom:2px;margin-top:8px;">Other Salesperson's
                      Existing
                      Prospects
                    </h3>
                  </div>
                  <span fxHide.xs class="mat-caption padding-bottom-8">The following prospects are
                    already linked to other salespeople. If
                    you match a prospect that belongs to another salesperson, a notification will be sent to alert
                    your
                    management team.</span>
                </div>
                <div *ngIf="existingProspects && existingProspects.length > 0; else noExistingProspectsFound"
                  fxLayout="row wrap">
                  <crm-prospect-card fxFlex="48" fxFlex.gt-md="48" fxFlex.xs="100"
                    *ngFor="let prospect of existingProspects | orderBy: ['-prospectUpdatedDate', '-prospectEnteredDate']"
                    [prospect]="prospect" [ngClass]="{'card-container': true}"></crm-prospect-card>
                </div>
                <ng-template #noExistingProspectsFound>
                  <div fxFlex class="loading-prospects-container" fxLayoutAlign="center center">
                    <span class="mat-body-2">No Prospects Found</span>
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-template #matchExistingAssociates>
      <div fxLayout="column" fxFlex class="content-container"
        *ngIf="leadMatchingForm.get('createOrMatch').value === 2; else createNewAssociate">
        <mat-form-field>
          <input #associateSearch matInput autocomplete="off"
            placeholder="Associate Search (name, email, phone #, address, etc)" aria-label="Search"
            formControlName="search">
        </mat-form-field>
        <div fxFlex class="loading-container" *ngIf="isLoadingExistingAssociates$ | async; else loaded">
          <div class="loading">Loading</div>
        </div>
        <ng-template #loaded>
          <div fxLayout="column" *ngIf="existingAssociateMatches$ | async as existingAssociateMatches">
            <ng-container *ngIf="existingAssociateMatches.length > 0; else noExistingMatchesFound">
              <div fxLayout="row" fxLayoutAlign="start center">
                <span class="mat-body-2">Existing Associates</span>
              </div>
              <span class="mat-caption padding-bottom-8">Select an existing associate to match with.</span>
              <div fxLayout="row wrap" fxLayoutAlign="start start" class="results-inner-container" fxFlex>
                <ng-container *ngIf="existingAssociates$ | async as existingAssociates">
                  <crm-associate-card fxFlex="49" fxFlex.gt-md="49" fxFlex.xs="100"
                    *ngFor="let existingAssociate of existingAssociates" [associate]="existingAssociate"
                    (click)="selectAssociate(existingAssociate, 'existing')"
                    [selected]="selectedAssociate?.id === existingAssociate.id">
                  </crm-associate-card>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #noExistingMatchesFound>
              <div fxFlex class="loading-container">
                <span class="mat-body-2">No Matches Found</span>
              </div>
            </ng-template>

            <div id="prospectData">
              <ng-container *ngIf="selectedAssociate">
                <div fxLayout="column">
                  <div fxFlex class="loading-container" *ngIf="isLoadingExistingProspectsForSelectedAssociate$ | async">
                    <div class="loading">Loading</div>
                  </div>
                  <div id="yourExistingProspectsList" *ngIf="ownExistingProspects$ | async as existingProspects"
                    fxLayout="column">
                    <div fxLayout="column">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <span class="mat-body-2">Your Existing Prospects</span>
                      </div>
                      <span fxHide.xs class="mat-caption padding-bottom-8">The following prospects exist on the selected
                        associate.</span>
                    </div>
                    <div *ngIf="existingProspects && existingProspects.length > 0; else noExistingProspectsFound"
                      fxLayout="row wrap">
                      <crm-prospect-card fxFlex="48" fxFlex.gt-md="48" fxFlex.xs="100"
                        *ngFor="let prospect of existingProspects | orderBy: ['-prospectUpdatedDate', '-prospectEnteredDate']"
                        [prospect]="prospect" [ngClass]="{'card-container': true}"></crm-prospect-card>
                    </div>
                    <ng-template #noExistingProspectsFound>
                      <div fxFlex class="loading-prospects-container" fxLayoutAlign="center center">
                        <span class="mat-body-2">No Prospects Found</span>
                      </div>
                    </ng-template>
                  </div>
                  <div id="othersExistingProspectsList" fxLayout="column"
                    *ngIf="othersExistingProspects$ | async as existingProspects">
                    <div fxLayout="column">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <h3 style="color:#E1322F;font-weight:500;margin-bottom:2px;margin-top:8px;">Other Salesperson's
                          Existing
                          Prospects
                        </h3>
                      </div>
                      <span fxHide.xs class="mat-caption padding-bottom-8">The following prospects are
                        already linked to other salespeople. If
                        you match a prospect that belongs to another salesperson, a notification will be sent to alert
                        your
                        management team.</span>
                    </div>
                    <div *ngIf="existingProspects && existingProspects.length > 0; else noExistingProspectsFound"
                      fxLayout="row wrap">
                      <crm-prospect-card fxFlex="48" fxFlex.gt-md="48" fxFlex.xs="100"
                        *ngFor="let prospect of existingProspects | orderBy: ['-prospectUpdatedDate', '-prospectEnteredDate']"
                        [prospect]="prospect" [ngClass]="{'card-container': true}"></crm-prospect-card>
                    </div>
                    <ng-template #noExistingProspectsFound>
                      <div fxFlex class="loading-prospects-container" fxLayoutAlign="center center">
                        <span class="mat-body-2">No Prospects Found</span>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </div>

          </div>
        </ng-template>
      </div>
      <ng-template #createNewAssociate>
        <div fxLayout="column" fxFlex class="content-container">
          <div fxLayout="column" *ngIf="existingLocations; else noLocationMatchesFound">
            <ng-container *ngIf="existingLocations.length > 0">
              <div fxLayout=" row" fxLayoutAlign="start center">
                <span class="mat-body-2">Existing Locations</span>
              </div>
              <span class="mat-caption padding-bottom-8">The following locations match the address information on the
                prospect/lead.
                Please select an existing location. If no location is selected it will be set automatically to the
                dealer's location.</span>
              <mat-nav-list fxFlex fxLayout="row wrap">
                <mat-list-item *ngFor="let location of existingLocations" fxFlex="50" fxFlex.xs="100" fxFlex.sm="50"
                  (click)="selectLocation(location)" [ngClass]="{selected: selectedLocationId === location.id}">
                  <div mat-list-icon fxLayoutAlign="center center">
                    <mat-icon>home</mat-icon>
                  </div>
                  <h4 mat-line>
                    {{location.description}}</h4>
                  <p mat-line>
                    {{location.provStateId && location.countryId ? location.provStateId + ', ' + location.countryId: location.provStateId ? location.provStateId : location.countryId ?  location.countryId :''}}
                  </p>
                </mat-list-item>
              </mat-nav-list>
            </ng-container>
          </div>
          <ng-template #noLocationMatchesFound>
            <div fxFlex class="no-location-matches-container">
              <span class="mat-body-2">No Location Matches Found</span>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </form>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    <button mat-button
      (click)="createAssociate(data.prospect, dialogRef, customerUsingSoftwareAssociate, data.latestResponseLead)"
      color="primary" *ngIf="leadMatchingForm.get('createOrMatch').value === 0">Create</button>
    <button mat-button
      (click)="matchExistingAssociate(data.prospect, dialogRef, selectedAssociate, salespersonTakeoverData)"
      color="primary"
      *ngIf="leadMatchingForm.get('createOrMatch').value === 1 || leadMatchingForm.get('createOrMatch').value === 2"
      [disabled]="!selectedAssociate">Match</button>
  </div>
</div>