import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromCommunicator from './communicator.reducer';
import * as CommunicatorSelectors from './communicator.selectors';
import { Actions, ofType } from '@ngrx/effects';
import { sendCommunicatorMessage } from '@quorum/communicator-ngrx';
import { Conversation, Member, Event } from '@quorum/models/xs-resource';
import { HttpHeaders } from '@angular/common/http';
import { ConversationQueryParameters } from '@quorum/models/xs-query';

@Injectable()
export class CommunicatorFacade {
  loaded$ = this.store.pipe(select(CommunicatorSelectors.getCommunicatorLoaded));
  allCommunicator$ = this.store.pipe(select(CommunicatorSelectors.getAllCommunicator));
  selectedCommunicator$ = this.store.pipe(select(CommunicatorSelectors.getSelected));

  constructor(private store: Store<fromCommunicator.CommunicatorPartialState>, private actions$: Actions) {}

  sendCommunicatorMessage(
    conversationQueryParameters: ConversationQueryParameters,
    conversation: Conversation,
    members: Member[],
    event: Event,
    optionalHeader: HttpHeaders
  ) {
    this.store.dispatch(
      sendCommunicatorMessage({
        conversationQueryParameters: conversationQueryParameters,
        conversation: conversation,
        members: members,
        event: event,
        optionalHeader: optionalHeader,
      })
    );
  }

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
