import { LocalSettingsState } from './local-settings.interfaces';

export const initialLocalSettingsState: LocalSettingsState = {
  accessibility: { boldText: false, increaseContrast: false, largeText: false, theme: 'light' },
  defaultActivityTab: 'A',
  defaultProspectTab: 'P',
  activitiesFilter: ['A', 'D', 'P', 'T', 'S'],
  range: 2,
  rangePast: 7,
  monthsOverdue: 1,
  salesPeople: [],
  startDate: new Date(),
  prospectFilter: { typeId: [3, 4, 6], channel: [], source: [], status: [1, 2], sort: '-enteredDate' },
  activityListDisplayEmployee: 'activitySalesperson',
  prospectSortBy: '-enteredDate',
  settingsChanged: []
};
