import { Component, OnInit, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { GroupByPipe } from 'ngx-pipes';
import { RouterStateService } from '@quorum/sha-router';
import { ActivatedRoute } from '@angular/router';
import { MomentPipe } from '@quorum/common-pipes';
import { LocalSettingsState } from '@quorum/xsr-salesplanner-legacy/state';
import { Observable } from 'rxjs';
import { LocalSettingsStateService, SelectedActivityStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { share, shareReplay } from 'rxjs/operators';
@Component({
  selector: 'crm-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.css'],
  providers: [GroupByPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListComponent implements OnInit, OnChanges {
  @Input() activities: Array<any>;
  @Input() overdue: boolean;
  @Input() combinedData: boolean;
  groupedActivities: Array<any>;
  yesterday: Date;
  today: Date;
  tomorrow: Date;
  hidden: boolean = true;
  activityGroupExpandedStatus: any = {};
  localSettings$: Observable<LocalSettingsState>;
  selectedActivity$: Observable<any>;
  constructor(
    private groupByPipe: GroupByPipe,
    private route: ActivatedRoute,
    private routerStateService: RouterStateService,
    private localSettingsStateService: LocalSettingsStateService,
    private selectedActivityStateService: SelectedActivityStateService
  ) {}

  ngOnInit() {
    this.groupedActivities = this.groupByPipe.transform(this.activities, 'activityDate');
    this.yesterday = new MomentPipe().transform(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      'dddd, MMMM Do, YYYY'
    );
    this.today = new MomentPipe().transform(new Date(new Date().setDate(new Date().getDate())), 'dddd, MMMM Do, YYYY');
    this.activityGroupExpandedStatus[
      `${new MomentPipe().transform(new Date(new Date().setDate(new Date().getDate())), 'YYYYMMDD')}`
    ] = true;
    this.tomorrow = new MomentPipe().transform(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      'dddd, MMMM Do, YYYY'
    );
    this.localSettings$ = this.localSettingsStateService.selectLocalSettingsFromState().pipe(shareReplay(1));
    this.selectedActivity$ = this.selectedActivityStateService.selectSelectedActivity().pipe(shareReplay(1));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activities && !changes.activities.isFirstChange()) {
      this.groupedActivities = this.groupByPipe.transform(this.activities, 'activityDate');
    }
  }
  selectActivity(activity: any, localSettings: LocalSettingsState) {
    let queryParams: any;
    switch (localSettings.defaultActivityTab) {
      case 'A':
        queryParams = { tab: 'activity' };
        break;
      case 'P':
        queryParams = { tab: 'prospect' };
        break;
      case 'V':
        queryParams = { tab: 'vehicle' };
        break;
      default:
        queryParams = { tab: 'activity' };
        break;
    }
    switch (activity.activityType) {
      case 'Appointment':
        this.routerStateService.go([`appointments`, `${activity.activityId}`], {
          queryParams: queryParams,
          relativeTo: this.route.parent,
        });
        break;
      case 'Task':
        this.routerStateService.go([`tasks`, `${activity.activityId}`], {
          queryParams: queryParams,
          relativeTo: this.route.parent,
        });
        break;
      case 'Service':
        this.routerStateService.go([`service-appointments`, `${activity.activityId}`], {
          queryParams: queryParams,
          relativeTo: this.route.parent,
        });
        break;
      case 'Promotion':
        this.routerStateService.go([`dealer-promotions`, `${activity.activityId}`, `${activity.activitySequence}`], {
          queryParams: queryParams,
          relativeTo: this.route.parent,
        });
        break;
      case 'Delivery':
        this.routerStateService.go([`deliveries`, `${activity.activityId}`], {
          queryParams: queryParams,
          relativeTo: this.route.parent,
        });
        break;
    }
  }

  trackByFunction(index: number, item: any) {
    return index;
  }

  toggleGroup(activityGroup: number) {
    if (this.activityGroupExpandedStatus.hasOwnProperty(activityGroup)) {
      this.activityGroupExpandedStatus[activityGroup] = !this.activityGroupExpandedStatus[activityGroup];
    } else {
      this.activityGroupExpandedStatus[activityGroup] = true;
    }
  }
}
