import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import * as fromLocalSettings from './local-settings.actions';
import { localSettingsStateSelector } from './local-settings.interfaces';
import { map } from 'rxjs/operators';
@Injectable()
export class LocalSettingsStateService {
  constructor(private store: Store<any>) {}

  selectLocalSettingsFromState() {
    return this.store.pipe(
      select(localSettingsStateSelector),
      map((localSettings) => {
        return new DeepCopyPipe().transform(localSettings);
      })
    );
  }

  updateLocalSettingsInState(localSettings: any) {
    this.store.dispatch(new fromLocalSettings.UpdateLocalSettingsInState(localSettings));
  }
  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
