<div fxLayout="column" [ngClass]="{selected: selected, 'card-container': true}">
  <div class="card-header-container" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" class="title-container" fxFlex>
      <div *ngIf="associateSearchTypeLookup; else personIcon" class="header-icon">
        <mat-icon *ngIf="associateSearchTypeLookup.name.indexOf(associate.id) > -1">person</mat-icon>
        <mat-icon *ngIf="associateSearchTypeLookup.email.indexOf(associate.id) > -1">email</mat-icon>
        <mat-icon *ngIf="associateSearchTypeLookup.phone.indexOf(associate.id) > -1">call</mat-icon>
      </div>
      <ng-template #personIcon>
        <div class="header-icon" fxLayoutAlign="center center">
          <mat-icon>person</mat-icon>
        </div>
      </ng-template>
      <div class="flex-column">
        <span class="title mat-body-2">{{[associate.fullName] | nameSafety}}</span>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="card-content-container" fxLayout="column">
    <div fxLayout="column">
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon">people</mat-icon>
        <span>{{associate.openProspects ? 'Existing prospects' : 'No existing prospects'}}</span>
      </div>
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">location_on</mat-icon>
        <span
          class="secondary-text">{{associate.addressLine1 && associate.locationName && associate.provStateId ? associate.addressLine1 + ', ' + associate.locationName + ', ' + associate.provStateId : 'No address provided'}}</span>
      </div>

      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">email</mat-icon>
        <span class="secondary-text">{{associate.emailAddress ? associate.emailAddress : 'No email provided'}}</span>
      </div>
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">phone</mat-icon>
        <span class="secondary-text" *ngIf="associate.cellPhoneNumber; else noCellPhone">
          C: {{associate.cellPhoneNumber | phoneNumber}}
        </span>
        <ng-template #noCellPhone>
          <span class="secondary-text">No cell phone provided</span>
        </ng-template>
      </div>
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">phone</mat-icon>
        <span class="secondary-text" *ngIf="associate.homePhoneNumber; else noHomePhone">
          M: {{associate.homePhoneNumber | phoneNumber}}
        </span>
        <ng-template #noHomePhone>
          <span class="secondary-text">No main phone provided</span>
        </ng-template>
      </div>
    </div>
  </div>
</div>