import * as fromDeliveries from './deliveries.actions';
import { deliveriesAdapter, DeliveriesState, initialState } from './deliveries.interfaces';

export function reducer(
  state: DeliveriesState = initialState,
  action: fromDeliveries.DeliveriesAction
): DeliveriesState {
  switch (action.type) {
    case fromDeliveries.GET_DELIVERIES_FROM_SERVER: {
      return {
        ...state,
        loading: true
      };
    }
    case fromDeliveries.GET_DELIVERIES_FROM_SERVER_SUCCESS: {
      return {
        ...deliveriesAdapter.addMany(action.payload.deliveries, state),
        loading: false
      };
    }

    case fromDeliveries.CLEAR_DELIVERIES_FROM_STATE: {
      return {
        ...deliveriesAdapter.removeAll({ ...state, loading: false })
      };
    }

    case fromDeliveries.UPDATE_DELIVERY_ON_SERVER_SUCCESS: {
      return {
        ...deliveriesAdapter.updateOne(action.payload, state)
      };
    }

    default: {
      return state;
    }
  }
}
