import { Action } from '@ngrx/store';
import {
  AvailableQueryParameters,
  InEquityQueryParameters,
  OverMileageLeaseQueryParameters,
  ServiceAppointmentQueryParameters,
  SubPrimeQueryParameters,
} from '@quorum/models/xs-query';

export const SET_AVAILABLE_PARAMS_IN_STATE = '[EQUITY MINING] Set Available Params in state';
export class SetAvailableParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_AVAILABLE_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: AvailableQueryParameters }) {}
}

export const SET_IN_EQUITY_PARAMS_IN_STATE = '[EQUITY MINING] Set In Equity Params in state';
export class SetInEquityParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_IN_EQUITY_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: InEquityQueryParameters }) {}
}

export const SET_OVER_MILEAGE_PARAMS_IN_STATE = '[EQUITY MINING] Set Over Mileage Params in state';
export class SetOverMileageParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_OVER_MILEAGE_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: OverMileageLeaseQueryParameters }) {}
}

export const SET_SUB_PRIME_PARAMS_IN_STATE = '[EQUITY MINING] Set Sub Prime Params in state';
export class SetSubPrimeParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_SUB_PRIME_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: SubPrimeQueryParameters }) {}
}

export const SET_SERVICE_APPOINTMENTS_PARAMS_IN_STATE = '[EQUITY MINING] Set Service Appointments Params in state';
export class SetServiceAppointmentsParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_SERVICE_APPOINTMENTS_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: ServiceAppointmentQueryParameters }) {}
}

export const SET_CUSTOMERS_PARAMS_IN_STATE = '[EQUITY MINING] Set Customers Params in state';
export class SetCustomersParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_CUSTOMERS_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: any }) {}
}

export const SET_UNSOLD_VEHICLES_SEARCH_PARAMS_IN_STATE = '[EQUITY MINING] Set Unsold Vehicles Search Params in state';
export class SetUnsoldVehiclesParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_UNSOLD_VEHICLES_SEARCH_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: any }) {}
}

export const SET_VEHICLES_SEARCH_PARAMS_IN_STATE = '[EQUITY MINING] Set Vehicles Search Params in state';
export class SetVehiclesParamsInState implements Action {
  readonly feature = 'equitymining';
  readonly type = SET_VEHICLES_SEARCH_PARAMS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { params: any }) {}
}

export type EquityMiningAction =
  | SetAvailableParamsInState
  | SetInEquityParamsInState
  | SetOverMileageParamsInState
  | SetSubPrimeParamsInState
  | SetServiceAppointmentsParamsInState
  | SetUnsoldVehiclesParamsInState
  | SetVehiclesParamsInState
  | SetCustomersParamsInState;
