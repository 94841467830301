import { VehicleStatus as Status } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface VehicleStatusesState extends EntityState<Status> {
  loading: boolean;
}

export const vehicleStatusesAdapter: EntityAdapter<Status> = createEntityAdapter<Status>({
  selectId: (vehicleStatus: Status) => vehicleStatus.id
});

export const initialState: VehicleStatusesState = vehicleStatusesAdapter.getInitialState({ loading: true });
export const vehicleStatusesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleStatuses
);

export const vehicleStatusesLoadingSelector = createSelector(
  vehicleStatusesSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleStatusesAdapter.getSelectors(
  vehicleStatusesSelector
);
