<ng-container>
  <h1 mat-dialog-title>Settings</h1>
  <div mat-dialog-content fxLayout="column" *ngIf="blackBookPriceTypes$ | async as blackbookPriceTypes">
    <div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label>
          Rooftop:
        </label>
        <mat-form-field>
          <mat-select [disabled]="data.settings.salesPlannerView == 0 && data.settings.teamLead != true"
            [(ngModel)]="selectedRooftopId" (selectionChange)="getBlackbookSetting(selectedRooftopId)">
            <mat-option *ngFor="let rooftop of data.rooftops" [value]="rooftop.id">
              {{rooftop.displayCode}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label>
          Default Price Type:
        </label>
        <mat-form-field>
          <mat-select [disabled]="data.settings.salesPlannerView == 0 && data.settings.teamLead != true"
            [(ngModel)]="blackbookSetting.blackbookPriceTypeId">
            <mat-option *ngFor="let priceType of blackbookPriceTypes" [value]="priceType.id">{{priceType.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label>
          Marketing Cost:
        </label>
        <mat-form-field>
          <input [disabled]="data.settings.salesPlannerView == 0 && data.settings.teamLead != true" class="mask-input"
            [(ngModel)]="blackbookSetting.marketingCost" [textMask]="{mask: currencyMask}" matInput>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label>
          Reconditioning Cost:
        </label>
        <mat-form-field>
          <input [disabled]="data.settings.salesPlannerView == 0 && data.settings.teamLead != true" class="mask-input"
            [(ngModel)]="blackbookSetting.reconditioningCost" [textMask]="{mask: currencyMask}" matInput>
        </mat-form-field>
      </div>
    </div>
    <p class="mat-caption">Changes to these values will not come into effect until the vehicle
      matching process is run or a
      singular lookup is
      performed.</p>

  </div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button color="primary" (click)="onCancel()">Cancel</button>
    <button mat-button color="primary" (click)="onSave(blackbookSetting)">Save</button>
  </div>
</ng-container>