import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SystemControlValueQueryParameters } from '@quorum/models/xs-query';
import { filter, map, take } from 'rxjs/operators';
import * as fromSystemControl from './+state/system-control.actions';
import { selectEntities } from './+state/system-control.interfaces';

@Injectable()
export class SystemControlStateService {
  constructor(private store: Store<any>) {}

  getSystemControlValue(constantRef: string, rooftopId: number) {
    this.store
      .pipe(
        select(selectEntities),
        take(1),
        map((systemControlValues) => {
          let systemControlExists = Object.keys(systemControlValues).includes(`${constantRef}-${rooftopId}`);
          let params = new SystemControlValueQueryParameters({
            constantReference: constantRef,
            rooftopId: rooftopId.toString()
          });

          if (!systemControlExists) {
            this.store.dispatch(new fromSystemControl.GetSystemControlValuesFromServer(params));
          }
        })
      )
      .subscribe();

    return this.store.pipe(
      select(selectEntities),
      map(systemControlValues => {
        let filtered = Object.keys(systemControlValues)
          .filter(key => constantRef === key.substr(0, key.indexOf('-')))
          .reduce((obj, key) => {
            return systemControlValues[key];
          }, {});
        return filtered;
      }),
      filter((systemControlValues: any) => {
        return !this.isEmpty(systemControlValues);
      })
    );
  }

  getSystemControlValues(constantRefs: string[], rooftopId: number) {
    this.store
      .pipe(
        select(selectEntities),
        take(1),
        map(systemControlValues => {
          let constantRefsArray = constantRefs.filter(
            x => !Object.keys(systemControlValues).includes(x + '-' + rooftopId)
          );
          let params = new SystemControlValueQueryParameters({
            constantReference: constantRefsArray.join(','),
            rooftopId: rooftopId.toString()
          });

          if (constantRefsArray.length > 0) {
            this.store.dispatch(new fromSystemControl.GetSystemControlValuesFromServer(params));
          }
        })
      )
      .subscribe();

    return this.store.pipe(
      select(selectEntities),
      map(systemControlValues => {
        let filtered = Object.keys(systemControlValues)
          .filter(key => constantRefs.includes(key.substr(0, key.indexOf('-'))))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key.substr(0, key.indexOf('-'))]: systemControlValues[key]
            };
          }, {});

        return filtered;
      }),
      filter((systemControlValues: any) => {
        return !this.isEmpty(systemControlValues) && Object.keys(systemControlValues).length === constantRefs.length;
      })
    );
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
