import { Address } from './address.model';
import { Associate } from './associate.model';
import { Contact } from './contact.model';
import { CustomerSubType } from './customer-sub-type.model';
import { CustomerType } from './customer-type.model';
import { Language } from './language.model';
import { Manufacturer } from './manufacturer.model';
import { StatementCycle } from './statement-cycle.model';
import { StatementTerm } from './statement-term.model';
import { WarrantySubmissionType } from '../fixed-operations/warranty-submission-type.model';
export class Customer {
  acceptCheque: string = null;
  applyArInterest: string = null;
  associateId: string = null;
  customerSubTypeId: number = null;
  customerTypeId: number = null;
  creditFlag: string = null;
  creditLimit: number = null;
  creditProviderCustomerId: string = null;
  driversLicenseNumber: string = null;
  embedded?: CustomerEmbed = null;
  firstTime: string = null;
  fleetApprovedAmount: number = null;
  gender: string = null;
  gmCardAmount: number = null;
  gstRegistration: string = null;
  labourRateHour: number = null;
  languageId: number = null;
  leaseVehicleOperatorId: string = null;
  mailFlag: string = null;
  manufacturerCustomerId: string = null;
  openStatements: string = null;
  partSalesNotes: string = null;
  poFlag: string = null;
  printMailCopyFlag: string = null;
  printStatements: string = null;
  pstExemptEffectiveDate: Date = null;
  pstVendorNumber: string = null;
  salutation: string = null;
  secondaryLanguageId: number = null;
  serviceNoticeAssociateId: string = null;
  serviceNoticeFlag: string = null;
  shopFlag: string = null;
  siFleetAccountNumber: string = null;
  socialInsuranceNumber: number = null;
  statementCellPhoneNumber: string = null;
  statementCycleId: number = null;
  statementEmailAddress: string = null;
  statementTermsId: number = null;
  useCustomerFlag: string = null;
  warrantySubmissionTypeId: string = null;
  wholesalerFlag: string = null;
  workOrderGlobalDiscount: number = null;
  workOrderPrintDiscount: string = null;
  xTimePrivacyFlag: string = null;
  zeroBalanceStatements: string = null;

  constructor(obj?: Customer) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class CustomerEmbed {
  addresses?: Address[] = null;
  associate?: Associate = null;
  contact?: Contact = null;
  creditProviderCustomerAssociate?: Associate = null;
  customerType?: CustomerType = null;
  language?: Language = null;
  manufacturer?: Manufacturer = null;
  manufacturerAssociate?: Associate = null;
  secondaryLanguage?: Language = null;
  serviceNoticeAssociate?: Associate = null;
  statementCycle?: StatementCycle = null;
  statementTerm?: StatementTerm = null;
  customerSubType?: CustomerSubType = null;
  warrantySubmissionType?: WarrantySubmissionType = null;
}
