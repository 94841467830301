export class BlackbookValuesSetting {
  blackbookPriceTypeId: number = null;
  id: number = null;
  tradeOffset: number = null;
  vehicleId: number = null;

  constructor(obj?: BlackbookValuesSetting) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
