import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Vehicle } from '@quorum/models/xs-resource';
import { Observable } from 'rxjs';

@Component({
  selector: 'crm-vehicle-profile-dialog',
  templateUrl: './vehicle-profile-dialog.component.html',
  styleUrls: ['./vehicle-profile-dialog.component.scss'],
})
export class VehicleProfileDialogComponent implements OnInit {
  payTypes: any[];
  saleStays: any[];
  constructor(
    public dialogRef: MatDialogRef<VehicleProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public vehicleData: {
      vehicle: Vehicle;
      freeformFirstName: string;
      freeformLastName: string;
      associateFirstName: string;
      associateLastName: string;
      salespersonFirstName: string;
      salespersonLastName: string;
      crmDropdowns$: Observable<any>;
    }
  ) {}

  ngOnInit(): void {
    this.payTypes = [
      { id: '1', description: 'List Price' },
      { id: '3', description: 'Ad Price' },
      { id: 'L', description: 'MSRP' },
    ];

    this.saleStays = [
      { id: 'P', description: 'Pending' },
      { id: 'B', description: 'Business office' },
      { id: 'S', description: 'Sold' },
    ];
  }
}
