import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { map, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'unitOfMeasure',
})
export class UnitOfMeasurePipe implements PipeTransform {
  constructor(
    private authenticationStateService?: AuthenticationStateService,
    private systemControlStateService?: SystemControlStateService
  ) {}
  transform(value: any) {
    return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
      switchMap((employee) => {
        return this.systemControlStateService.getSystemControlValue('CL_UNIT_OF_MEASURE', employee.rooftopId);
      }),
      map((unit) => {
        if (parseInt(value) >= 0) {
          return value + (unit.value == 'M' ? ' mi' : ' km');
        } else {
          return 'N/A';
        }
      })
    );
  }
}
