import { Injectable } from '@angular/core';
// eslint-disable-next-line max-len
import { ServiceGroup } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { SystemControlValue } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromSystemControl from './system-control.actions';
import { SystemControlState } from './system-control.interfaces';
@Injectable()
export class SystemControlEffects {
  
  getSystemControlValuesFromServer = createEffect(() => this.dataPersistence.fetch(
    fromSystemControl.GET_SYSTEM_CONTROL_VALUES_FROM_SERVER,
    {
      run: (action: fromSystemControl.GetSystemControlValuesFromServer, state: any) => {
        return this.apiService
          .get<SystemControlValue[]>(`v/1/system/system-control-values`, {
            params: action.payload,
          })
          .pipe(
            map((systemControlValues) => {
              let scts: string[] = action.payload.constantReference.split(',');
              scts.forEach((sct) => {
                if (
                  !systemControlValues.some(
                    (systemControlValue: SystemControlValue) => systemControlValue.constantReference === sct
                  )
                ) {
                  let systemControl: SystemControlValue = new SystemControlValue();
                  systemControl.constantReference = sct;
                  systemControl.rooftopId = parseInt(action.payload.rooftopId);
                  systemControl.value = '0';
                  systemControlValues.push(systemControl);
                }
              });
              if (systemControlValues.length > 0) {
                return new fromSystemControl.GetSystemControlValuesFromServerSuccess({
                  systemControlValues: systemControlValues,
                });
              } else {
                return new fromSystemControl.GetSystemControlValuesFromServerSuccess({
                  systemControlValues: [],
                });
              }
            })
          );
      },
      onError: (action: fromSystemControl.GetSystemControlValuesFromServer, error) => {
        console.error('Error', error);
      },
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<SystemControlState>,
    private apiService: ApiService
  ) {}
}
