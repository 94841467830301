import { SubTypeQueryParameters } from '@quorum/models/xs-query';
import { SubType } from '@quorum/models/xs-resource';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_SUBTYPES_FROM_SERVER = '[CRM] Get Vehicle Sub Types From Server';
export class GetVehicleSubTypesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SUBTYPES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SubTypeQueryParameters) {}
}

export const GET_VEHICLE_SUBTYPES_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Sub Types From Server Success';
export class GetVehicleSubTypesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SUBTYPES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleSubTypes: SubType[] }) {}
}

export const GET_VEHICLE_SUBTYPES_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Sub Types From Server Failure';
export class GetVehicleSubTypesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SUBTYPES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VehicleSubTypesAction =
  | GetVehicleSubTypesFromServer
  | GetVehicleSubTypesFromServerSuccess
  | GetVehicleSubTypesFromServerFailure;
