import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Vendor } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromVendors from './vendors.actions';
import { VendorsState } from './vendors.interfaces';

@Injectable()
export class VendorsEffects {
  
  getVendorsFromServer = createEffect(() => this.dataPersistence.fetch(fromVendors.GET_VENDORS_FROM_SERVER, {
    run: (action: fromVendors.GetVendorsFromServer, state: VendorsState) => {
      return this.apiService
        .get<Vendor[]>(`v/1/system/vendors`, {
          params: action.payload,
        })
        .pipe(
          map((vendors) => {
            return new fromVendors.GetVendorsFromServerSuccess({
              vendors: vendors,
            });
          })
        );
    },
    onError: (action: fromVendors.GetVendorsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VendorsState>,
    private apiService: ApiService
  ) {}
}
