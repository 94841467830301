import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { ProspectCreateDialogComponent, ProspectCreateProgressDialogComponent } from '@quorum/crm-prospects-ng-ui';
import {
  AvailableQueryParameters,
  CustomerSearchQueryParameters,
  InEquityQueryParameters,
  OverMileageLeaseQueryParameters,
  ReportingServiceAppointmentQueryParameters as ServiceAppointmentQueryParameters,
  SubPrimeQueryParameters,
  LeadGeneratorVehicleSearchQueryParameters,
} from '@quorum/models/xs-query';
import {
  Action,
  Associate,
  Available,
  Channel,
  Class,
  CustomerSearch,
  Employee,
  InEquity,
  OverMileageLease,
  Prospect,
  ProvinceState,
  ReportingServiceAppointment as ServiceAppointment,
  Source,
  SubPrime,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AddDeductDialogComponent } from './add-deduct-dialog/add-deduct-dialog.component';

@Directive()
export class TabBaseComponent implements OnDestroy, AfterViewInit {
  dropdowns$: Observable<any>;
  prospectDropdowns$: Observable<any>;
  routerState$: Subscription;
  searchAvailable: Observable<any>;
  authenticatedEmployee: Employee;
  defaultFormModel:
    | AvailableQueryParameters
    | InEquityQueryParameters
    | OverMileageLeaseQueryParameters
    | SubPrimeQueryParameters
    | ServiceAppointmentQueryParameters
    | CustomerSearchQueryParameters;
  filterForm: FormGroup;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sort: string;
  @ViewChild('searchButton') searchButton: any;
  private formInititialized = new ReplaySubject(1);
  public formRef: ElementRef;
  @ViewChild('filterFormRef')
  set setTabSet(content: any) {
    if (!!content) {
      this.formRef = content;
      this.formInititialized.next(true);
    }
  }
  searchResult: any = { data: [], headers: null };
  createProspectProgressRef: MatDialogRef<any>;
  postProspectProcessSubscription: Subscription;
  mileageLabel: string;
  countryCode: string;
  leadGeneratorActionId: number;

  constructor(
    public apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public crmEntityStateService: CrmEntityStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public routerStateService: RouterStateService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public equityMiningStateService: EquityMiningStateService,
    public routeUrl: string,
    public systemControlStateService: SystemControlStateService
  ) {}

  ngAfterViewInit() {
    this.formInititialized.pipe(take(1)).subscribe((d) => {
      this.searchAvailable = fromEvent(this.formRef.nativeElement, 'submit');
      this.searchAvailable
        .pipe(
          switchMap((d) => this.route.queryParams.pipe(take(1))),
          switchMap((params) => {
            if (params) {
              this.equityMiningStateService.updateReportParameters(this.routeUrl, params);
              this.isLoading$.next(true);
              return this.apiService
                .getWithHeaders<
                  Available[] | OverMileageLease[] | SubPrime[] | InEquity[] | ServiceAppointment[] | CustomerSearch[]
                >(`v/1/reporting/crm/${this.routeUrl}`, { params: params })
                .pipe(
                  map((response) => {
                    this.isLoading$.next(false);

                    return { data: response.body, headers: response.headers };
                  })
                );
            } else {
              this.isLoading$.next(false);
              return of({ data: null, headers: null });
            }
          })
        )
        .subscribe((sresult) => {
          this.searchResult = sresult;
          this.cdr.detectChanges();
        });
    });
  }

  Init() {
    this.filterForm = this.fb.group(this.defaultFormModel);
    if (this.filterForm.get('name')) {
      this.filterForm
        .get('name')
        .setValidators([Validators.required, Validators.minLength(2), Validators.pattern("^[a-zA-Z0-9 \\-\\,']+$")]);
    }
    this.filterForm.get('rooftopId').setValidators(Validators.required);
    this.filterForm.updateValueAndValidity();

    this.filterForm.valueChanges
      .pipe(startWith(null), pairwise(), debounceTime(1000))
      .subscribe(([previousValue, currentValue]) => {
        if (previousValue && previousValue.page === currentValue.page) {
          this.sort = currentValue.sort;
          this.filterForm.patchValue({ ...currentValue, page: 0 }, { emitEvent: false });
          this.updateRouter({ ...currentValue, page: 0 });
        } else {
          this.updateRouter(currentValue);
        }
      });

    this.dropdowns$ = combineLatest(
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops) => rooftops.filter((rooftop: any) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.authenticationStateService.selectAuthenticatedEmployee()
    ).pipe(
      take(1),
      map(([rooftops, salespeople, authenticatedEmployee]) => {
        return { rooftops, salespeople, authenticatedEmployee };
      }),
      tap((results) => {
        this.authenticatedEmployee = results.authenticatedEmployee;
        this.defaultFormModel.salespersonId.push(results.authenticatedEmployee.associateId);
        this.defaultFormModel.rooftopId = results.authenticatedEmployee.rooftopId.toString();
        this.defaultFormModel.userEmployeeId = results.authenticatedEmployee.associateId;
        this.routerState$ = this.routerStateService
          .selectRouterState()
          .pipe(
            filter((routerState) => routerState.state.url.indexOf('lead-generator') > -1),
            take(1)
          )
          .subscribe((routerState) => {
            let queryParams: any = routerState.state.root.queryParams;
            if (this.dropdownsStateService.isEmpty(queryParams)) {
              this.filterForm.patchValue(this.defaultFormModel, { emitEvent: true });
            } else {
              if (queryParams.sort) {
                this.sort = queryParams.sort;
              }
              this.defaultFormModel = {
                ...this.defaultFormModel,
                ...queryParams,
                salespersonId: Array.isArray(queryParams.salespersonId)
                  ? [...this.defaultFormModel.salespersonId, ...queryParams.salespersonId]
                  : [...this.defaultFormModel.salespersonId, queryParams.salespersonId],
              };
              if (queryParams.saleTypeId) {
                this.defaultFormModel = {
                  ...this.defaultFormModel,
                  saleTypeId: Array.isArray(queryParams.salespersonId)
                    ? [...this.defaultFormModel.saleTypeId, ...queryParams.saleTypeId]
                    : [...this.defaultFormModel.saleTypeId, queryParams.saleTypeId],
                };
              }
              if (queryParams.serviceAdvisorId) {
                this.defaultFormModel = {
                  ...this.defaultFormModel,
                  serviceAdvisorId: Array.isArray(queryParams.serviceAdvisorId)
                    ? [...queryParams.serviceAdvisorId]
                    : [queryParams.serviceAdvisorId],
                };
              }

              if (queryParams.inEquity) {
                this.defaultFormModel = {
                  ...this.defaultFormModel,
                  inEquity: Array.isArray(queryParams.inEquity) ? [...queryParams.inEquity] : [queryParams.inEquity],
                };
              }

              this.filterForm.patchValue(this.defaultFormModel, {
                emitEvent: false,
              });
              this.formInititialized.pipe(delay(1000), take(1)).subscribe((d) => {
                this.searchButton.nativeElement.click();
              });
            }
          });
      })
    );

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        take(1)
      )
      .subscribe((employee: Employee) => {
        this.systemControlStateService
          .getSystemControlValues(['CL_DEALER_COUNTRY_ID'], employee.rooftopId)
          .pipe(take(1))
          .subscribe((sct) => {
            this.mileageLabel = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'mi.' : 'km';
            this.countryCode = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'U' : 'C';
          });
      });

    this.dropdownsStateService
      .selectProspectActions()
      .pipe(
        filter((prospectActions) => prospectActions != null && prospectActions.length > 0),
        take(1)
      )
      .subscribe((prospectActions) => {
        this.leadGeneratorActionId = prospectActions.find((ac: Action) => ac.description === 'Lead Generator').id;
        this.postProspectProcessSubscription = this.crmEntityStateService
          .postProspectCreationProcess(this.leadGeneratorActionId)
          .subscribe((prospect: Prospect) => {
            this.createProspectProgressRef.close();
            let snackBarRef = this.snackBar.open(`Prospect ${prospect.id} created.`, 'OPEN', {
              duration: 4000,
              horizontalPosition: 'start',
            });

            snackBarRef
              .onAction()
              .pipe(take(1))
              .subscribe((action) => {
                this.routerStateService.go([`sales-planner/prospects/${prospect.id}`], {
                  relativeTo: this.route.parent.parent,
                });
              });
          });
      });

    this.prospectDropdowns$ = combineLatest(
      this.dropdownsStateService
        .selectChannels()
        .pipe(filter((channels) => !this.dropdownsStateService.isEmpty(channels))),
      this.dropdownsStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes))),
      this.dropdownsStateService
        .selectMessageTypes()
        .pipe(filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes))),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops) => rooftops.filter((rooftop: any) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService
        .selectSources()
        .pipe(filter((sources) => !this.dropdownsStateService.isEmpty(sources))),
      this.dropdownsStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownsStateService.isEmpty(associateClasses))),
      this.dropdownsStateService.selectProspectActions().pipe(
        filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions)),
        tap((prospectActions) => {
          this.leadGeneratorActionId = prospectActions.find((ac: Action) => ac.description === 'Lead Generator').id;
        })
      ),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(
        ([
          channels,
          customerTypes,
          messageTypes,
          rooftops,
          salespeople,
          sources,
          associateClasses,
          prospectActions,
          provStates,
        ]) => {
          return {
            channels,
            customerTypes,
            messageTypes,
            rooftops,
            salespeople,
            sources,
            associateClasses,
            prospectActions,
            provStates,
          };
        }
      )
    );
  }

  ngOnDestroy() {
    this.routerState$.unsubscribe();
    this.postProspectProcessSubscription.unsubscribe();
  }

  getReport(queryParams: AvailableQueryParameters = null) {
    // this.searchButton.nativeElement.click();
    //(<BehaviorSubject<AvailableQueryParameters>>this.queryParamSub$).next(queryParams);
    this.searchButton.nativeElement.click();
  }

  pageChanged(event: any, filterForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.page == event.pageIndex;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.searchButton.nativeElement.click();
      });

    filterForm.patchValue({ ...filterForm.value, page: event.pageIndex, pageSize: event.pageSize });
  }

  sortChanged(sortValue: string, filterForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.sort == sortValue;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.searchButton.nativeElement.click();
      });

    filterForm.patchValue({ ...filterForm.value, sort: sortValue });
  }

  updateRouter(queryParams: any) {
    if (typeof queryParams.availableDateFrom == 'object') {
      queryParams.availableDateFrom = new Date(queryParams.availableDateFrom).toISOString();
    }
    if (typeof queryParams.availableDateTo == 'object') {
      queryParams.availableDateTo = new Date(queryParams.availableDateTo).toISOString();
    }

    if (typeof queryParams.appointmentDateFrom == 'object') {
      queryParams.appointmentDateFrom = new Date(queryParams.appointmentDateFrom).toISOString();
    }
    if (typeof queryParams.appointmentDateTo == 'object') {
      queryParams.appointmentDateTo = new Date(queryParams.appointmentDateTo).toISOString();
    }

    let navExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    };

    this.routerStateService.go('', navExtras);
  }

  trackByFunction(index: number, item: Available) {
    return item.associateId;
  }

  openDetailsDialog(authenticatedEmployee: Employee, reportItem: any, countryCode: string) {
    const dialogRef = this.dialog.open(AddDeductDialogComponent, {
      width: '600px',
      data: {
        reportItem: reportItem,
        rooftopId: authenticatedEmployee.rooftopId,
        countryCode: countryCode,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse.reload) {
        this.searchButton.nativeElement.click();
        this.snackBar.open(`Vehicle ${dialogResponse.vehicleId} updated`, null, {
          duration: 3000,
          horizontalPosition: 'start',
        });
      }
    });
  }

  openVehicleSearch(result: Available) {
    let queryParams: LeadGeneratorVehicleSearchQueryParameters = new LeadGeneratorVehicleSearchQueryParameters({
      categoryId: '2',
      make: result.vehicleMake,
      modelCode: result.vehicleModelCode,
      rooftopId: this.defaultFormModel.rooftopId,
      subCategoryId: '1',
      vehicleStatusId: '2',
    });

    this.routerStateService.go(['vehicle-search'], {
      queryParams: queryParams,
      relativeTo: this.route.parent.parent,
    });
  }

  openCreateProspectDialog(reportType: string, reportItem: any, authenticatedEmployee: Employee): void {
    combineLatest(
      this.prospectDropdowns$.pipe(take(1)),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1))
    )
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe((combinedData) => {
        let leadGeneratorChannel = combinedData.dropdowns.channels.find(
          (channel: Channel) => channel.description == 'Lead Generator'
        );
        let reportTypeSource = combinedData.dropdowns.sources.find(
          (source: Source) => source.description.toLowerCase() == reportType
        );

        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (combinedData.sct.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef = this.dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: combinedData.dropdowns,
            newProspect: {
              ...new Prospect(),
              actionId: this.leadGeneratorActionId,
              actionedVehicleId: reportItem.vehicleId,
              associateId: reportItem.associateId,
              channelId: leadGeneratorChannel.id,
              customerTypeId: '1',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: authenticatedEmployee.associateId,
              rooftopId: authenticatedEmployee.rooftopId,
              sourceId: reportTypeSource.id,
              statusId: '1',
              typeId: 3,
              vehicleId: reportItem.vehicleId,
            },
            newAssociate: {
              ...new Associate(),
              id: reportItem.associateId,
              firstName: reportItem.customerFirstName,
              lastName: reportItem.customerLastName,
            },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields: {
              associate: ['name'],
              prospect: ['channelId', 'sourceId', 'actionId'],
              contact: [],
            },
          },
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = this.dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
          }
        });
      });
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['..', 'sales-planner', 'prospects', prospectId], {
      relativeTo: this.route.parent,
    });
  }

  selectTeam(salesTeam: any, filterForm: FormGroup) {
    let selectedTeamSalespeople = salesTeam.children.map((salesperson: any) => salesperson.EmployeeId);
    let selectedSalespeople = filterForm.get('salespersonId').value;

    let existingCheck = selectedSalespeople.some((el: any) => selectedTeamSalespeople.includes(el));

    selectedTeamSalespeople.forEach((salesPersonId: string) => {
      if (existingCheck) {
        if (selectedSalespeople.includes(salesPersonId)) {
          selectedSalespeople.splice(selectedSalespeople.indexOf(salesPersonId, 0), 1);
        }
      } else {
        if (!selectedSalespeople.includes(salesPersonId)) {
          selectedSalespeople.push(salesPersonId);
        }
      }
    });

    filterForm.patchValue({
      salespersonId: selectedSalespeople,
    });
  }

  clearSearchResults() {
    this.searchResult = { data: [], headers: null };
  }
}
