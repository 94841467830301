<div fxLayout="column" *ngIf="(loadDetail$ | async) as loadDetail" fxFlexFill>
  <div class="my-4">
    <mat-toolbar *ngIf="prospect != null && !isProspectLoading; else secondaryToolbar">
      <mat-toolbar-row
        *ngIf="prospect?.embedded?.associate && prospect?.embedded?.associate?.firstName; else freeformName"
        class="flex flex-row items-center" [ngClass.xs]="'my-8'">
        <button fxHide.gt-xs mat-icon-button color="primary" (click)="backClicked()" fxLayoutAlign="center center">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div class="w-full flex justify-center items-center flex-col">
          <span class="toolbar-title"
            [ngStyle.xs]="{'margin-left': '-40px'}">{{prospect?.embedded?.associate?.firstName}}
            {{prospect?.embedded?.associate?.lastName}}</span>
          <div class="text-xs flex flex-row text-grey-500 font-normal justify-center" [ngClass.xs]="'flex-col'">
            <div class="flex flex-col mr-5">
              <div>
                <span class="text-grey-600 font-medium">Email: </span>
                {{prospect?.embedded?.associate?.emailAddress ? prospect?.embedded?.associate?.emailAddress : '-'}}
              </div>
              <div>
                <span class="text-grey-600 font-medium">Cell Phone: </span>
                {{prospect?.embedded?.associate?.embedded?.addresses[0]?.cellPhoneNumber ?
                (prospect?.embedded?.associate?.embedded?.addresses[0]?.cellPhoneNumber | phoneNumber) : '-' }}
              </div>
              <div>
                <span class="text-grey-600 font-medium">Main Phone: </span>
                {{prospect?.embedded?.associate?.embedded?.addresses[0]?.homePhoneNumber
                ? (prospect?.embedded?.associate?.embedded?.addresses[0]?.homePhoneNumber | phoneNumber) : '-' }}
              </div>
            </div>
            <div class="flex flex-col" *ngIf="(componentData$ | async) as componentData">
              <div>
                <span class="text-grey-600 font-medium">Message preference: </span>
                {{componentData.associatePreferences?.messagePreference ?
                componentData.associatePreferences?.messagePreference : '-'}}
              </div>
              <div>
                <span class="text-grey-600 font-medium">Communication preference: </span>
                {{componentData.associatePreferences?.contactPreference ?
                componentData.associatePreferences?.contactPreference : '-'}}
              </div>
            </div>
          </div>
        </div>
      </mat-toolbar-row>
      <ng-template #freeformName>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
          <button fxHide.gt-xs mat-icon-button color="primary" (click)="backClicked()" fxLayoutAlign="center center">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <div class="w-full flex justify-center items-center flex-col">
            <span fxFlex class="toolbar-title"
              [ngStyle.xs]="{'margin-left': '-40px'}">{{prospect?.freeformName.split(',').length > 1 ?
              prospect?.freeformName.split(',')[1].trim() +
              ' ' +
              prospect?.freeformName.split(',')[0].trim(): prospect?.freeformName}}</span>
            <div class="text-xs flex flex-col text-grey-500 font-normal justify-center">
              <div><span class="text-grey-600 font-medium">Email: </span>
                {{prospect?.emailAddress ? prospect?.emailAddress : '-'}}
              </div>
              <div><span class="text-grey-600 font-medium">Main Phone: </span>
                {{prospect?.phoneNumber ? (prospect?.phoneNumber | phoneNumber) : '-' }}
              </div>
            </div>
          </div>
        </mat-toolbar-row>
      </ng-template>
    </mat-toolbar>
    <ng-template #secondaryToolbar>
      <mat-toolbar>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
          <button fxHide.gt-xs mat-icon-button color="primary" (click)="backClicked()" fxLayoutAlign="center center">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <span fxFlex class="toolbar-title" [ngStyle.xs]="{'margin-left': '-40px'}"
            *ngIf="activity$ | async as selectedActivity">{{selectedActivity?.embedded?.customerAssociate?.firstName}}
            {{selectedActivity?.embedded?.customerAssociate?.lastName}}</span>
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-template>
  </div>

  <div fxLayout="column" *ngIf="(route$ | async) as route" fxFlex>
    <div id="detailTabContainer" fxFlex>
      <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="selectTab($event)">
        <mat-tab label="Activity">
          <div fxLayout="column" class="margin-panel">
            <div class="mat-elevation-z1 elevated-panel">
              <ng-container *ngIf="activity$ | async as selectedActivity" [ngSwitch]="route">
                <crm-appointment-card [appointment]="selectedActivity" *ngSwitchCase="'Appointment'"
                  (appointmentUpdated)="appointmentUpdated($event)">
                </crm-appointment-card>
                <div *ngIf="(componentData$ | async) as componentData">
                  <crm-task-card [task]="selectedActivity" [prospect]="componentData.prospect"
                    [associate]="componentData.prospect.embedded?.associate"
                    [address]="componentData.prospect.embedded?.associate?.embedded?.addresses[0]"
                    [dropdowns]="componentData.dropdownsCopy" *ngSwitchCase="'Task'"></crm-task-card>
                </div>

                <crm-delivery-card [delivery]="selectedActivity" *ngSwitchCase="'Delivery'"></crm-delivery-card>
                <crm-service-card [serviceAppointment]="selectedActivity" *ngSwitchCase="'Service'"></crm-service-card>
                <crm-promo-card [promo]="selectedActivity" *ngSwitchCase="'Promotion'"></crm-promo-card>
              </ng-container>
            </div>

            <div fxLayout="column" class="panel" *ngIf="selectedActivityType=='Delivery'">
              <div id="purchaseOrderDetailsDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                (click)="togglePanel('purchaseOrderDetails')">
                <div fxLayoutAlign="center center">
                  <span class="mat-subheading-2 subheading-tweak">Purchase Order Details</span>
                  <span class="secondary"
                    *ngIf="(activityPurchaseOrderDetails$ | async) as activityPurchaseOrderDetails">
                    {{ activityPurchaseOrderDetails.length }}</span>
                </div>
                <div *ngIf="!purchaseOrderDetailsPanel; else showPurchaseOrderDetailsPanel"
                  fxLayoutAlign="center center" class="pointer">
                  <span class="mat-caption">Show</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
                <ng-template #showPurchaseOrderDetailsPanel>
                  <div fxLayoutAlign="center center" class="pointer">
                    <span class="mat-caption">Hide</span>
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </div>
                </ng-template>
              </div>
              <div fxLayout="column" *ngIf="purchaseOrderDetailsPanel">
                <div>
                  <crm-activity-purchase-order-details
                    *ngIf="(activityPurchaseOrderDetails$ | async) as activityPurchaseOrderDetails"
                    [activityPurchaseOrderDetails]="activityPurchaseOrderDetails">
                  </crm-activity-purchase-order-details>
                </div>
              </div>
            </div>

            <div fxLayout="column" class="panel">
              <div id="closedActivitiesDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                (click)="togglePanel('closedActivities')">
                <div fxLayoutAlign="center center">
                  <span class="mat-subheading-2 subheading-tweak pointer">Closed
                    Activities</span><span class="secondary"
                    *ngIf="(selectedProspectClosedActivities$ | async); let closedActivities">{{ closedActivities.length
                    }}</span>
                </div>
                <div *ngIf="!closedActivitiesPanel; else showClosedActivityPanel" fxLayoutAlign="center center"
                  class="pointer">
                  <span class="mat-caption">Show</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
                <ng-template #showClosedActivityPanel>
                  <div fxLayoutAlign="center center" class="pointer">
                    <span class=" mat-caption">Hide</span>
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </div>
                </ng-template>
              </div>
              <div fxLayout="column" *ngIf="closedActivitiesPanel">
                <div *ngIf="(selectedProspectClosedActivities$ | async); let closedActivities">
                  <mat-nav-list>
                    <ng-container
                      *ngFor="let activity of (closedActivities | orderBy: '-activityCompletedDate'); let i = index">
                      <ng-container *ngIf="combinedObs$ | async as combinedData">
                        <crm-activity-list-item [activity]="activity" (click)="selectActivity(activity)"
                          [combinedData]="combinedData" [selectedActivity$]="selectedActivity$" [grouped]="false"
                          [localSettings$]="localSettings$">
                        </crm-activity-list-item>
                      </ng-container>
                      <mat-divider inset *ngIf="i < closedActivities.length"></mat-divider>
                    </ng-container>
                  </mat-nav-list>
                </div>
              </div>
            </div>

            <div fxLayout="column" class="panel">
              <div id="openActivitiesDiv" fxLayout="row" class="pointer" fxLayoutAlign="space-between center"
                (click)="togglePanel('openActivities')">
                <div fxLayoutAlign="center center">
                  <span class="mat-subheading-2 subheading-tweak pointer">Open
                    Activities</span><span class="secondary"
                    *ngIf="(selectedProspectOpenActivities$ | async) as openActivities">{{ openActivities.length
                    }}</span>
                </div>
                <div *ngIf="!openActivitiesPanel; else showOpenActivityPanel" fxLayoutAlign="center center"
                  class="pointer">
                  <span class="mat-caption">Show</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
                <ng-template #showOpenActivityPanel>
                  <div fxLayoutAlign="center center" class="pointer">
                    <span class="mat-caption">Hide</span>
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </div>
                </ng-template>
              </div>
              <div fxLayout="column" *ngIf="openActivitiesPanel">
                <div *ngIf="(selectedProspectOpenActivities$ | async) as openActivities">
                  <mat-nav-list *ngIf="combinedObs$ | async as combinedData">
                    <ng-container
                      *ngFor="let activity of openActivities | activitiesFilter: ['Appointment', 'Service', 'Delivery'] | orderBy: 'activityDueDate';let i = index;">
                      <crm-activity-list-item [activity]="activity" [grouped]="false" [combinedData]="combinedData"
                        [selectedActivity$]="selectedActivity$" [localSettings$]="localSettings$"
                        (click)="selectActivity(activity)">
                      </crm-activity-list-item>
                      <mat-divider inset *ngIf="i < openActivities.length - 1"></mat-divider>
                    </ng-container>
                    <ng-container
                      *ngFor="let activity of openActivities | activitiesFilter: ['Task', 'Promotion'] | orderBy: 'activityDueDate';let i = index;">
                      <crm-activity-list-item [activity]="activity" [grouped]="false" [combinedData]="combinedData"
                        [selectedActivity$]="selectedActivity$" [localSettings$]="localSettings$"
                        (click)="selectActivity(activity)">
                      </crm-activity-list-item>
                      <mat-divider inset *ngIf="i < openActivities.length - 1"></mat-divider>
                    </ng-container>
                  </mat-nav-list>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Prospect" [disabled]="prospect == null">
          <div *ngIf="prospect != null">
            <div class="margin-panel">
              <div class="mat-elevation-z1 elevated-panel">
                <crm-prospect *ngIf="(componentData$ | async) as componentData" [prospect]="componentData.prospect"
                  [associate]="componentData.prospect.embedded?.associate"
                  [address]="componentData.prospect.embedded?.associate?.embedded?.addresses[0]"
                  [dropdowns]="componentData.dropdownsCopy"
                  (prospectUpdated)="prospectUpdated($event, componentData.dropdownsCopy)"></crm-prospect>
                <ng-container *ngIf="!prospect.vendorId || prospect.associateId && prospect.vendorId">
                  <crm-prospect-aggregate *ngIf="prospectAggregate$ | async as prospectAggregate"
                    [prospectAggregate]="prospectAggregate"></crm-prospect-aggregate>
                </ng-container>
              </div>

              <div fxLayout="column" class="panel pointer" *ngIf="prospect.typeId != 4">
                <div id="openSalesDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                  (click)="togglePanel('openSales')">
                  <div fxLayoutAlign="center center">
                    <span class="mat-subheading-2 subheading-tweak">Sales</span>
                    <span class="secondary" *ngIf="(prospectOpenSales$ | async) as openSales">
                      ({{ openSales.length }})</span>
                  </div>
                  <div *ngIf="!openSalesPanel; else showOpenSalesPanel" fxLayoutAlign="center center" class="pointer">
                    <span class="mat-caption">Show</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </div>
                  <ng-template #showOpenSalesPanel>
                    <div fxLayoutAlign="center center" class="pointer">
                      <span class="mat-caption">Hide</span>
                      <mat-icon>keyboard_arrow_up</mat-icon>
                    </div>
                  </ng-template>
                </div>
                <div fxLayout="column" *ngIf="openSalesPanel">
                  <div>
                    <crm-prospect-open-sales-list *ngIf="(prospectOpenSales$ | async) as prospectOpenSales"
                      [openSales]="prospectOpenSales"></crm-prospect-open-sales-list>
                  </div>
                </div>
              </div>

              <div fxLayout="column" class="panel pointer"
                *ngIf="!prospect.vendorId || prospect.associateId && prospect.vendorId">
                <div id="notesDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                  (click)="togglePanel('notes')">
                  <div fxLayoutAlign="center center">
                    <span class="mat-subheading-2 subheading-tweak">Notes</span>
                  </div>
                  <div *ngIf="!notesPanel; else showNotesPanel" fxLayoutAlign="center center" class="pointer">
                    <span class="mat-caption">Show</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </div>
                  <ng-template #showNotesPanel>
                    <div fxLayoutAlign="center center" class="pointer">
                      <span class="mat-caption">Hide</span>
                      <mat-icon>keyboard_arrow_up</mat-icon>
                    </div>
                  </ng-template>
                </div>
                <div fxLayout="column" *ngIf="notesPanel">
                  <div class="padded-panel">
                    <crm-prospect-notes [prospectId]="prospect.id" [prospectNotes$]="prospectNotes$"
                      [disableAddAction]="prospect.statusId == '2' ? true : false"></crm-prospect-notes>
                  </div>
                </div>
              </div>

              <div fxLayout="column" class="panel pointer" *ngIf="prospect.typeId != 4">
                <div id="responseLeadsDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                  (click)="togglePanel('responseLeads')">
                  <div fxLayoutAlign="center center">
                    <span class="mat-subheading-2 subheading-tweak">Response Leads</span>
                    <span class="secondary" *ngIf="(responseLeads$ | async) as responseLeads">
                      ({{ responseLeads.length }})</span>
                  </div>
                  <div *ngIf="!responseLeadsPanel; else showResponseLeadsPanel" fxLayoutAlign="center center"
                    class="pointer">
                    <span class="mat-caption">Show</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </div>
                  <ng-template #showResponseLeadsPanel>
                    <div fxLayoutAlign="center center" class="pointer">
                      <span class="mat-caption">Hide</span>
                      <mat-icon>keyboard_arrow_up</mat-icon>
                    </div>
                  </ng-template>
                </div>
                <div fxLayout="column" *ngIf="responseLeadsPanel">
                  <div>
                    <crm-prospect-response-leads *ngIf="(responseLeads$ | async) as responseLeads"
                      [responseLeads]="responseLeads"></crm-prospect-response-leads>
                  </div>
                </div>
              </div>

              <div fxLayout="column" class="panel pointer" *ngIf="prospect.typeId != 4">
                <div id="vehicleOfInterestDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                  (click)="togglePanel('vehicleOfInterest')">
                  <div fxLayoutAlign="center center">
                    <span class="mat-subheading-2 subheading-tweak">Vehicle of Interest</span>
                  </div>
                  <div *ngIf="!vehicleOfInterestPanel; else showVehicleOfInterestPanel" fxLayoutAlign="center center"
                    class="pointer">
                    <span class="mat-caption">Show</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </div>
                  <ng-template #showVehicleOfInterestPanel>
                    <div fxLayoutAlign="center center" class="pointer">
                      <span class="mat-caption">Hide</span>
                      <mat-icon>keyboard_arrow_up</mat-icon>
                    </div>
                  </ng-template>
                </div>
                <div fxLayout="column" *ngIf="vehicleOfInterestPanel">
                  <div>
                    <crm-prospect-vehicle-of-interest
                      *ngIf="(prospectVehicleOfInterest$ | async) as prospectVehicleOfInterest"
                      [prospectId]="prospect.id" [prospectVehicleOfInterest]="prospectVehicleOfInterest">
                    </crm-prospect-vehicle-of-interest>
                  </div>
                </div>
              </div>

              <div fxLayout="column" class="panel"
                *ngIf="prospect.typeId != 4 && (!prospect.vendorId || prospect.associateId && prospect.vendorId)">
                <div id="prospectActionsDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                  (click)="togglePanel('actions')">
                  <div fxLayoutAlign="center center">
                    <span class="mat-subheading-2 subheading-tweak">Actions</span>
                  </div>
                  <div *ngIf="!actionsPanel; else showOpenActivityPanel" fxLayoutAlign="center center" class="pointer">
                    <span class="mat-caption">Show</span>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </div>
                  <ng-template #showOpenActivityPanel>
                    <div fxLayoutAlign="center center" class="pointer">
                      <span class="mat-caption">Hide</span>
                      <mat-icon>keyboard_arrow_up</mat-icon>
                    </div>
                  </ng-template>
                </div>
                <div fxLayout="column" *ngIf="actionsPanel">
                  <div class="margin-panel">
                    <crm-prospect-actions *ngIf="(prospectActions$ | async) as actions" [actions]="actions"
                      [prospectActions$]="selectedProspectActions$" [prospect]="prospect"
                      (actionCreated)="createProspectAction($event, prospect)"
                      (actionDeleted)="deleteProspectAction($event)"></crm-prospect-actions>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Vehicle" [disabled]="!vehicleTabEnabled">
          <div *ngIf="(prospectVehicles$ | async) as prospectVehicles" class="margin-panel">
            <div class="mat-elevation-z1 elevated-panel" fxLayout="column">
              <div fxLayout="column">
                <div fxLayoutAlign="space-between center" class="vehicle-header">
                  <form [formGroup]="vehicleSelectionForm" fxFlex fxLayout="row" fxLayoutAlign="space-between start">
                    <mat-icon>directions_car</mat-icon>
                    <mat-form-field class="no-underline" fxFlex>
                      <mat-select formControlName="vehicleId"
                        (selectionChange)="selectVehicle($event.value, prospectVehicles, prospect, true)"
                        placeholder="Please select or add a vehicle from the dropdown...">
                        <mat-option [value]="0">-- Add a vehicle --</mat-option>
                        <mat-option *ngFor="let vehicle of prospectVehicles | orderBy: '-year'" [value]="vehicle">
                          {{vehicle.year}} {{vehicle.make}} {{vehicle.model}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form>

                  <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="drillDownEnabled">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openTradeValuation(selectedVehicle.id)"
                      [disabled]="combinedData.salesPlannerView !== 1" *ngIf="combinedObs$ | async as combinedData">
                      <span>Open Trade Valuation</span>
                    </button>
                  </mat-menu>
                </div>
                <mat-divider></mat-divider>

                <div fxFlex class="loading-container" *ngIf="isVehicleAggregateLoading$ | async; else vehicleData">
                  <mat-spinner diameter="50"></mat-spinner>
                </div>
                <ng-template #vehicleData>
                  <div fxLayout="column" class="padded-panel"
                    *ngIf="vehicleAggregate$ | async as vehicleAggregate; else noVehicleSale">
                    <div fxLayout="column" fxFlex="49" class="bordered-panel">
                      <div fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutAlign="center center">
                          <h3 class="slim-bold-h3">
                            Vehicle ID:
                            <a *ngIf="browserControlCommunicationService.drilldownEnabled; else noDrilldownEnabled "
                              style="color: rgb(0,0,238); cursor: 'pointer'"
                              (click)="browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [vehicleAggregate.vehicleId.toString()])"
                              class="mat-body-1">{{ vehicleAggregate.vehicleId }}</a>
                            <ng-template #noDrilldownEnabled>
                              <a style="color: rgb(0,0,238); cursor: 'pointer'" (click)="openVehicleProfileInfo()"
                                class="mat-body-1">{{ vehicleAggregate.vehicleId }}</a>
                            </ng-template>
                          </h3>
                          <span class="secondary">- Available
                            {{ vehicleAggregate.availableDate | moment: 'MMM DD, YYYY' }}</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center"
                          *ngIf="leadGeneratorSct$ | async as leadGeneratorEnabled">
                          <ng-container *ngIf="vehicleAggregate.actioned === true; else notActioned">
                            <button mat-icon-button type="button" *ngIf="prospect?.typeId === 4"
                              (click)="openProspect(vehicleAggregate.prospectId)">
                              <mat-icon>person</mat-icon>
                            </button>
                          </ng-container>
                          <ng-template #notActioned>
                            <button mat-icon-button type="button" *ngIf="prospect?.typeId === 4"
                              (click)="openCreateProspectDialog(selectedVehicle, authenticatedEmployee, prospect, leadGeneratorEnabled)">
                              <mat-icon>person_add</mat-icon>
                            </button>
                          </ng-template>
                          <div *ngIf="leadGeneratorEnabled === '1'">
                            <span class="option-spacer">|</span>
                            <button mat-button [color]="vehicleAggregate.equityValue > 0 ? 'accent' : 'black'"
                              (click)="openBlackBookEditorDialog(vehicleAggregate, countryCode, authenticatedEmployee, prospect)">{{vehicleAggregate.equityValue
                              | currency}}</button>
                          </div>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div fxLayout="column">
                        <div fxLayoutAlign="start center">
                          <span class="primary-bullet">· </span><span>{{ vehicleAggregate.term }}
                            {{ vehicleAggregate.paymentFrequencyId }}
                            {{ vehicleAggregate.saleTypeDescription }} Sold by
                            {{
                            vehicleAggregate.originalSalespersonFirstName + ' ' +
                            vehicleAggregate.originalSalespersonLastName
                            }}
                            (ID: <a [ngStyle]="{
                                  color: browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)' : '#000',
                                  cursor: browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''
                                }" (click)="
                                  browserControlCommunicationService.drilldown(
                                    'salesPlanner',
                                    'w_vehicle_sales_maintain',
                                    [vehicleAggregate.saleId]
                                  )
                                ">{{ vehicleAggregate.saleId }}</a>)</span>
                        </div>
                      </div>
                      <div fxLayout="column">
                        <div fxLayoutAlign="start center">
                          <span class="primary-bullet">· </span><span>{{
                            vehicleAggregate.remainingPayments ? vehicleAggregate.remainingPayments : 0
                            }}
                            Remaining Payments of {{ vehicleAggregate.payment | currency }} @
                            {{ vehicleAggregate.interestRate }}%</span>
                        </div>

                        <div fxLayoutAlign="start center">
                          <span class="primary-bullet">·</span>
                          <span>Vehicles current mileage is
                            {{ vehicleAggregate.currentMileage }} {{mileageLabel}}</span>
                        </div>
                        <div fxLayoutAlign="start center">
                          <div fxLayoutAlign="start center">
                            <span class="primary-bullet">·</span>
                            <span>{{ vehicleAggregate.remainingBalance | currency }}
                              Remaining</span>
                          </div>
                          <div *ngIf="vehicleAggregate.saleTypeId === 'L'" fxLayoutAlign="start center">
                            <span>,
                              {{ vehicleAggregate.residualValue | currency }} Residual</span>
                          </div>
                        </div>
                        <div fxLayoutAlign="start center" *ngIf="leadGeneratorSct$ | async as leadGeneratorEnabled">
                          <div fxLayoutAlign="start center" *ngIf="leadGeneratorEnabled === '1'">
                            <span class="primary-bullet">·
                            </span><span>{{ vehicleAggregate.tradeValue + vehicleAggregate.addDeductsTotal | currency }}
                              Trade
                              Value</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #noVehicleSale>
                    <div fxLayout="column" class="pointer" *ngIf="vehicleSelectionForm.get('vehicleId').value !== null">
                      <div fxLayout="column" fxFlex="49" class="bordered-panel">
                        <div fxLayoutAlign="space-between center">
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <h3 class="slim-bold-h3">
                              Vehicle ID:
                              <a *ngIf="browserControlCommunicationService.drilldownEnabled; else noDrilldownEnabled"
                                style="color:rgb(0,0,238);cursor:pointer"
                                (click)="browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [selectedVehicle?.id])"
                                class="mat-body-1">{{ selectedVehicle?.id }}</a>
                              <ng-template #noDrilldownEnabled>
                                <a style="color:rgb(0,0,238);cursor:pointer" (click)="openVehicleProfileInfo()"
                                  class="mat-body-1">{{ selectedVehicle?.id }}</a>
                              </ng-template>
                            </h3>
                            <span>- Available
                              {{ selectedVehicle?.availableDate | moment: 'MMM DD, YYYY'}}</span>
                          </div>
                          <div fxLayout="row" fxLayoutAlign="center center"
                            *ngIf="leadGeneratorSct$ | async as leadGeneratorEnabled;">
                            <button mat-icon-button type="button" *ngIf="prospect?.typeId === 4"
                              (click)="openCreateProspectDialog(selectedVehicle, authenticatedEmployee, prospect, leadGeneratorEnabled)">
                              <mat-icon>person_add</mat-icon>
                            </button>
                            <div *ngIf="leadGeneratorEnabled === '1'">
                              <span class="option-spacer">|</span>
                              <span class="mat-body-2" class="accent-text">{{
                                selectedVehicle?.equityValue | currency
                                }}</span>
                            </div>
                          </div>
                        </div>

                        <mat-divider></mat-divider>
                        <div fxLayout="column">
                          <div fxLayoutAlign="start center">
                            <span class="primary-bullet">·</span>
                            <span>{{ selectedVehicle?.lastSaleTerm ? selectedVehicle.lastSaleTerm + ' M ' +
                              (selectedVehicle?.prospectTypeId ? (selectedVehicle?.embedded?.prospectType ?
                              selectedVehicle?.embedded?.prospectType.description : '') : '') : 'Unknown sale term' }}
                              sold by {{ selectedVehicleSalespersonName ? selectedVehicleSalespersonName :
                              'unknown salesperson'}}
                            </span>
                          </div>
                          <div fxLayoutAlign="start center">
                            <span class="primary-bullet">·</span>
                            <span *ngIf="estimatedConversion; else notEstimated">Estimated
                              {{ selectedVehicleRemainingPayments}}
                              remaining payments of an estimated
                              {{ '$' + selectedVehicle.price1Amount/selectedVehicle.lastSaleTerm}}
                              @
                              {{ selectedVehicle?.lastSaleInterestRate ? selectedVehicle.lastSaleInterestRate + '%':
                              'unknown interest rate'}}
                            </span>
                            <ng-template #notEstimated>
                              <span>{{ selectedVehicleRemainingPayments ? selectedVehicleRemainingPayments :
                                'Unknown # of'}}
                                remaining payments of
                                {{ selectedVehicle?.lastPaymentAmount ? '$' + selectedVehicle.lastPaymentAmount :
                                'unknown amount'}}
                                @
                                {{ selectedVehicle?.lastSaleInterestRate ? selectedVehicle.lastSaleInterestRate + '%':
                                'unknown interest rate'}}
                              </span>
                            </ng-template>
                          </div>
                          <div fxLayoutAlign="start center">
                            <span class="primary-bullet">·</span>
                            <span>Vehicles current mileage is
                              {{ selectedVehicle?.currentMileage ? selectedVehicle.currentMileage + ' ' + mileageLabel :
                              'unknown'}}
                            </span>
                          </div>
                          <div fxLayoutAlign="start center">
                            <span class="primary-bullet">·</span>
                            <span>{{estimatedConversion ? 'Estimated' : '' }}
                              {{selectedVehicleConversionRemainingBalance ? '$' +
                              selectedVehicleConversionRemainingBalance + ' Remaining' : 'Unknown remaining balance'}},
                              {{selectedVehicle?.lastResidualAmount ? selectedVehicle.lastResidualAmount :
                              'Unknown residual amount'}}
                            </span>
                          </div>
                          <div fxLayoutAlign="start center">
                            <span class="primary-bullet">·</span>
                            <span>Vehicles unit # is
                              {{ selectedVehicle?.stockNumber ? selectedVehicle.stockNumber : 'unknown'}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
            </div>

            <div fxLayout="column" class="panel pointer">
              <div id="serviceHistoryDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
                (click)="togglePanel('serviceHistory')">
                <div fxLayoutAlign="center center">
                  <span class="mat-subheading-2 subheading-tweak">Service History</span>
                  <span class="secondary" *ngIf="(vehicleServiceHistory$ | async) as serviceHistory">
                    {{ serviceHistory.length }}</span>
                </div>
                <div *ngIf="!serviceHistoryPanel; else showServiceHistoryPanel" fxLayoutAlign="center center"
                  class="pointer">
                  <span class="mat-caption">Show</span>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
                <ng-template #showServiceHistoryPanel>
                  <div fxLayoutAlign="center center" class="pointer">
                    <span class="mat-caption">Hide</span>
                    <mat-icon>keyboard_arrow_up</mat-icon>
                  </div>
                </ng-template>
              </div>
              <div fxLayout="column" *ngIf="serviceHistoryPanel">
                <div>
                  <crm-vehicle-service-history *ngIf="(vehicleServiceHistory$ | async) as vehicleServiceHistory"
                    [vehicleServiceHistory]="vehicleServiceHistory"></crm-vehicle-service-history>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>