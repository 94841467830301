import { Injectable } from '@angular/core';
import { GenericModel } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromVehicleGenericModels from './vehicle-generic-models.actions';
import { VehicleGenericModelsState } from './vehicle-generic-models.interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleGenericModelsEffects {
  
  getVehicleGenericModelsFromServer = createEffect(() => this.dataPersistence.fetch(
    fromVehicleGenericModels.GET_VEHICLE_GENERIC_MODELS_FROM_SERVER,
    {
      run: (action: fromVehicleGenericModels.GetVehicleGenericModelsFromServer, state: VehicleGenericModelsState) => {
        return this.apiService
          .get<GenericModel[]>(`v/1/vehicles/generic-models`, {
            params: action.payload
          })
          .pipe(
            map(vehicleGenericModels => {
              return new fromVehicleGenericModels.GetVehicleGenericModelsFromServerSuccess({
                vehicleGenericModels: vehicleGenericModels
              });
            })
          );
      },
      onError: (action: fromVehicleGenericModels.GetVehicleGenericModelsFromServer, error) => {
        console.error('Error', error);
      }
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleGenericModelsState>,
    private apiService: ApiService
  ) {}
}
