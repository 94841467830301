import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import {
  AssociateQueryParameters,
  LocationQueryParameters,
  ProspectReportQueryParameters,
} from '@quorum/models/xs-query';
import {
  Action,
  Address,
  Associate,
  AssociateCustomerType as CustomerType,
  AssociateReport,
  Channel,
  Class,
  Employee,
  Location,
  MessageType,
  OemRooftopLink,
  Prospect,
  ProspectReport,
  ProvinceState,
  Source,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { CustomValidators } from '@quorum/sha-validators';
import {
  LocalSettingsState,
  LocalSettingsStateService,
  SalesPlannerStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import emailMask from 'text-mask-addons/dist/emailMask';

@Component({
  selector: 'crm-prospect-create-dialog',
  templateUrl: './prospect-create-dialog.component.html',
  styleUrls: ['./prospect-create-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectCreateDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper', { static: true }) stepperElement: MatStepper;
  @ViewChild('associateName') associateName: ElementRef;
  @ViewChild('freeformName') freeformName: ElementRef;
  @ViewChild('location') location: MatSelect;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  assocRequired = true;
  associateProspects$: Observable<ProspectReport[]> | BehaviorSubject<ProspectReport[]>;
  authenticatedUser$: Subscription;
  authenticatedEmployee$: Observable<Employee>;
  combinedData$: Observable<any>;
  authenticatedUser: AuthenticatedUser;
  emailAddressMask = emailMask;
  emailAddressSystemControl$: Subscription;
  emailAddressSystemControl: any;
  emailProspects$: Observable<ProspectReport[]>;
  emailRequired = false;
  cellPhoneRequired = false;
  filteredLocations: Location[] = [];
  freeform: boolean;
  newAssociate = false;
  othersProspects$: Observable<ProspectReport[]>;
  ownProspects$: Observable<ProspectReport[]>;
  phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  phoneProspects$: Observable<ProspectReport[]>;
  nameLeads$: Observable<ProspectReport[]>;
  prospects$: Observable<ProspectReport[]>;
  queryParamSub$: Observable<any> | BehaviorSubject<ProspectReportQueryParameters>;
  sourceChannelSystemControl$: Subscription;
  sourceChannelSystemControl: any;
  othersExistingProspect: ProspectReport;
  prospectSearchTypeLookup: { associate: number[]; email: number[]; phone: number[]; name: number[] } = {
    associate: [],
    email: [],
    phone: [],
    name: [],
  };
  destroy$ = new Subject();

  associateForm: any = this.fb.group({
    classId: [],
    emailAddress: ['', [Validators.email]],
    name: ['', [CustomValidators.noWhitespaceValidator]],
    contactPreferenceId: ['L'],
    messagePreferenceId: [null],
  });

  contactForm: any = this.fb.group({
    homePhoneNumber: [''],
    cellPhoneNumber: [''],
    addressLine1: [''],
    locationId: [{ value: '', disabled: true }],
    provStateId: [''],
    countryId: [''],
    postalZipCode: [''],
  });

  prospectForm: any = this.fb.group({
    freeformName: ['', [CustomValidators.noWhitespaceValidator]],
    primaryEmployeeId: [],
    secondaryEmployeeId: [],
    rooftopId: [],
    phoneNumber: ['', []],
    emailAddress: ['', [Validators.email]],
    customerTypeId: ['3'],
    channelId: [null],
    sourceId: [null],
    employeeCreatedId: [],
    statusId: [1],
    typeId: [3],
    classId: [],
    actionId: [],
  });

  countries = [
    { name: 'Canada', id: 'CAN' },
    { name: 'United States', id: 'USA' },
  ];
  localSettings$: Observable<LocalSettingsState>;
  locationAutocompleteOptionSelected = false;
  selectedAssoc: AssociateReport;
  associateNameSub$: Subscription;
  locations$: Observable<Location[]>;
  locations: Location[];
  autoCompleteSelected = false;
  currentAssociateName: string;
  associateLookupComplete: boolean;
  ownExistingProspect: boolean;
  leads$: Observable<ProspectReport[]>;
  filteredProvStates$: Observable<ProvinceState[]>;
  filteredAssociates$: BehaviorSubject<AssociateReport[]> = new BehaviorSubject<AssociateReport[]>([]);

  constructor(
    public dialogRef: MatDialogRef<ProspectCreateDialogComponent>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dropdowns: {
        salespeople: any;
        rooftops: OemRooftopLink[];
        customerTypes: CustomerType[];
        sources: Source[];
        channels: Channel[];
        associateClasses: Class[];
        messageTypes: MessageType[];
        prospectActions: Action[];
        provStates: ProvinceState[];
      };
      newProspect: Prospect;
      newAssociate: Associate;
      newAddress: Address;
      requiredFields: { associate: string[]; contact: string[]; prospect: string[] };
      disabledFields: { associate: string[]; contact: string[]; prospect: string[] };
    },
    private authenticationStateService: AuthenticationStateService,
    private fb: FormBuilder,
    private routerStateService: RouterStateService,
    private localSettingsStateService: LocalSettingsStateService,
    private cdr: ChangeDetectorRef,
    private dropdownsStateService: DropdownsStateService,
    private salesPlannerStateService: SalesPlannerStateService
  ) {}

  ngOnInit() {
    this.freeform = false;

    this.setupValidators();
    this.setupDisabledFields();
    //this.stepperElement.selectedIndex = 1;

    this.emailRequired = this.data.requiredFields.associate.indexOf('emailAddress') > -1 ? true : false;

    if (this.data.newAssociate.id != null) {
      this.associateForm.patchValue({
        classId: this.data.newAssociate.classId,
        name: `${this.data.newAssociate.lastName}, ${this.data.newAssociate.firstName}`,
      });
    } else {
      this.associateForm.patchValue({
        classId: this.data.newAssociate.classId,
      });
    }

    this.prospectForm.patchValue({
      associateId: this.data.newProspect.associateId,
      channelId: this.data.newProspect.channelId,
      employeeCreatedId: this.data.newProspect.employeeCreatedId,
      rooftopId: this.data.newProspect.rooftopId,
      primaryEmployeeId: this.data.newProspect.primaryEmployeeId,
      sourceId: this.data.newProspect.sourceId,
      actionId: this.data.newProspect.actionId,
    });

    this.authenticatedUser$ = this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((authenticatedUser: AuthenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
      });

    this.authenticatedEmployee$ = this.authenticationStateService.selectAuthenticatedEmployee().pipe(
      switchMap((employee: Employee) => {
        return this.apiService.get<AssociateReport[]>(`v/1/associates/associates/${employee.associateId}`, {
          params: new AssociateQueryParameters({ embed: ['addresses'] }),
        });
      }),
      take(1)
    );

    this.queryParamSub$ = new BehaviorSubject<any>(null).pipe(
      mergeMap((queryParams: ProspectReportQueryParameters) => {
        if (queryParams) {
          return this.apiService.get<ProspectReport[]>('v/1/reporting/crm/prospects', { params: queryParams }).pipe(
            map((prospects) => {
              return { queryParams: queryParams, prospects: prospects };
            })
          );
        } else {
          return of({
            queryParams: { associateId: 'dank', prospectEmailAddress: 'dank', prospectPhoneNumber: 'dank' },
            prospects: [],
          });
        }
      }),
      shareReplay(1)
    );

    if (this.data.newProspect.associateId) {
      const associateParams: AssociateQueryParameters = { id: this.data.newProspect.associateId };
      this.apiService
        .get<Associate[]>('v/1/reporting/associates', { params: associateParams })
        .pipe(takeUntil(this.destroy$))
        .subscribe((associates) => {
          this.queryProspects(
            'associateId',
            this.data.newProspect.associateId,
            <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
            associates,
            this.data.dropdowns.provStates
          );
        });
    }

    this.associateProspects$ = this.queryParamSub$.pipe(
      filter((prospectsObject) => prospectsObject.queryParams.associateId != null),
      map((prospectsObject) => prospectsObject.prospects)
    );
    this.emailProspects$ = this.queryParamSub$.pipe(
      filter(
        (prospectsObject) =>
          prospectsObject.queryParams.prospectEmailAddress != null ||
          ('prospectEmailAddress' in prospectsObject.queryParams &&
            prospectsObject.queryParams.prospectEmailAddress === undefined)
      ),
      map((prospectsObject) => prospectsObject.prospects)
    );
    this.phoneProspects$ = this.queryParamSub$.pipe(
      filter(
        (prospectsObject) =>
          prospectsObject.queryParams.prospectPhoneNumber != null ||
          ('prospectPhoneNumber' in prospectsObject.queryParams &&
            prospectsObject.queryParams.prospectPhoneNumber === undefined)
      ),
      map((prospectsObject) => prospectsObject.prospects)
    );

    this.nameLeads$ = this.queryParamSub$.pipe(
      filter((prospectsObject) => prospectsObject.queryParams.search != null),
      map((prospectsObject) => {
        return prospectsObject.prospects.filter(
          (prospect: ProspectReport) =>
            prospect.prospectFreeformName.toLowerCase().includes(prospectsObject.queryParams.search.toLowerCase()) &&
            prospect.vendorId !== null
        );
      }),
      startWith([])
    );

    this.prospects$ = combineLatest([this.associateProspects$, this.emailProspects$, this.phoneProspects$]).pipe(
      map(([associateProspects, emailProspects, phoneProspects]) => {
        this.prospectSearchTypeLookup.associate = associateProspects.map((asc) => asc.id);

        this.prospectSearchTypeLookup.email = emailProspects
          .filter((asc) => {
            return this.prospectSearchTypeLookup.associate.indexOf(asc.id) == -1;
          })
          .map((asc) => asc.id);
        this.prospectSearchTypeLookup.phone = phoneProspects
          .filter((asc) => {
            return (
              this.prospectSearchTypeLookup.associate.indexOf(asc.id) == -1 &&
              this.prospectSearchTypeLookup.email.indexOf(asc.id) == -1
            );
          })
          .map((asc) => asc.id);
        const concatArray = associateProspects.concat(phoneProspects).concat(emailProspects);
        const idArray: any[] = [];
        const finalArray: any[] = [];

        concatArray.forEach((el: ProspectReport) => {
          if (idArray.indexOf(el.id) === -1) {
            idArray.push(el.id);
            finalArray.push(el);
          }
        });
        return finalArray;
      }),
      shareReplay(1)
    );

    this.leads$ = combineLatest([
      this.prospects$.pipe(
        map((prospects) =>
          prospects.filter((prospect) => prospect.salespersonId === null && prospect.vendorId !== null)
        )
      ),
      this.nameLeads$,
    ]).pipe(
      map(([phoneEmailLeads, nameLeads]) => {
        this.prospectSearchTypeLookup.name = nameLeads
          .filter((asc) => {
            return (
              this.prospectSearchTypeLookup.associate.indexOf(asc.id) == -1 &&
              this.prospectSearchTypeLookup.email.indexOf(asc.id) == -1 &&
              this.prospectSearchTypeLookup.phone.indexOf(asc.id) == -1
            );
          })
          .map((asc) => asc.id);

        const concatArray = phoneEmailLeads.concat(nameLeads);
        const idArray: any[] = [];
        const finalArray: any[] = [];

        concatArray.forEach((el: ProspectReport) => {
          if (idArray.indexOf(el.id) === -1) {
            idArray.push(el.id);
            finalArray.push(el);
          }
        });
        return finalArray;
      }),
      shareReplay(1)
    );

    this.ownProspects$ = this.prospects$.pipe(
      filter((prospects) => prospects != null),
      map((prospects) =>
        prospects.filter(
          (prospect) => prospect.salespersonId == this.authenticatedUser.user.xselleratorEmployeeId.toString()
        )
      ),
      tap((prospects) => {
        this.ownExistingProspect = prospects.length > 0 ? true : false;
        const ownProspectsList = document.getElementById('ownProspectsList');
        if (prospects.length > 0 && ownProspectsList) {
          ownProspectsList.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
        } else {
          this.stepperScroll({ selectedIndex: this.stepperElement.selectedIndex, focusAssociate: false });
        }
      })
    );

    this.othersProspects$ = combineLatest([
      this.prospects$.pipe(filter((prospects) => prospects != null)),
      this.dropdownsStateService.selectEmployees().pipe(
        filter((employees) => !this.dropdownsStateService.isEmpty(employees)),
        map((employees) => employees.find((employee) => employee.networkUserName === 'ORPHAN'))
      ),
    ]).pipe(
      map(([prospects, orphanEmployee]) => {
        return prospects.filter(
          (prospect) =>
            prospect.salespersonId !== null &&
            prospect.salespersonId !== this.authenticatedUser.user.xselleratorEmployeeId.toString() &&
            prospect.salespersonId !== orphanEmployee?.associateId
        );
      }),
      tap((prospects) => {
        this.othersExistingProspect = prospects[0];
        const othersExistingProspectsList = document.getElementById('othersExistingProspectsList');
        if (prospects.length > 0 && othersExistingProspectsList) {
          othersExistingProspectsList.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
        } else {
          this.stepperScroll({ selectedIndex: this.stepperElement.selectedIndex, focusAssociate: false });
        }
      })
    );

    this.associateForm
      .get('emailAddress')
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (value) {
          this.prospectForm.patchValue({ emailAddress: value }, { emitEvent: false });
          this.associateForm.patchValue({ contactPreferenceId: 'E' });
          this.queryProspects(
            'emailAddress',
            value,
            <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
            null,
            this.data.dropdowns.provStates
          );
        } else {
          this.queryProspects(
            'emailAddress',
            undefined,
            <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
            null,
            this.data.dropdowns.provStates
          );
        }
      });

    this.associateNameSub$ = this.associateForm['controls'].name.valueChanges
      .pipe(
        filter((input: string) => input.length >= 3),
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        tap((value: string) => {
          if (value !== this.data.newProspect.associateId) {
            this.currentAssociateName = value;
            this.autocompleteSelected(false);
            this.newAssociate = false;
            this.prospectForm.patchValue({ ...this.data.newProspect, freeformName: value }, { emitEvent: false });
            this.associateForm.get('emailAddress').disable();
            this.associateForm.get('classId').disable();
            return from(value);
          }
        }),
        switchMap((value: any) => {
          if (value !== this.data.newProspect.associateId) {
            const params: AssociateQueryParameters = new AssociateQueryParameters({
              search: value,
            });
            this.associateLookupComplete = false;
            return this.apiService.get<AssociateReport[]>('v/1/reporting/associates', { params: params }).pipe(
              map((associates) => {
                this.associateLookupComplete = true;
                this.filteredAssociates$.next(associates);

                this.associateName.nativeElement.focus();
                this.cdr.detectChanges();
                Object.keys(this.contactForm.value).forEach((key) => {
                  if (key !== 'locationId') {
                    this.contactForm.get(key).enable({ emitEvent: false });
                  }
                });
              }),
              catchError((error) => {
                this.associateForm.enable({ emitEvent: false });
                this.associateName.nativeElement.focus();
                this.contactForm.enable({ emitEvent: false });
                return of(error);
              })
            );
          } else {
            this.filteredAssociates$.next([]);
            return of(null);
          }
        })
      )
      .subscribe();

    /**
     * Selecting 'Add New' from the associate autocomplete
     */
    this.associateForm['controls'].name.valueChanges
      .pipe(
        filter((input: string) => input === '0'),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.filteredLocations = [];
        this.newAssociate = true;
        this.associateForm.patchValue({
          classId: this.data.newAssociate.classId,
          emailAddress: null,
        });
        this.contactForm.reset();
        this.prospectForm.reset();
        this.prospectForm.patchValue({ freeformName: this.currentAssociateName }, { emitEvent: false });

        this.associateForm.get('emailAddress').enable();
        this.prospectForm.get('emailAddress').enable();
        this.associateForm.get('classId').enable();

        this.contactForm.get('addressLine1').setValidators(Validators.required);
        this.contactForm.get('addressLine1').updateValueAndValidity();
        this.contactForm.get('addressLine1').enable();

        this.contactForm.get('countryId').setValidators(Validators.required);
        this.contactForm.get('countryId').updateValueAndValidity();
        this.contactForm.get('countryId').enable();

        this.contactForm.get('homePhoneNumber').setValidators(Validators.required);
        this.contactForm.get('homePhoneNumber').updateValueAndValidity();
        this.contactForm.get('homePhoneNumber').enable();

        this.prospectForm.get('phoneNumber').setValidators(Validators.required);
        this.prospectForm.get('phoneNumber').updateValueAndValidity();
        this.prospectForm.get('phoneNumber').enable();

        this.contactForm.get('cellPhoneNumber').enable();

        this.contactForm
          .get('provStateId')
          .setValidators([
            Validators.required,
            CustomValidators.dropdownOptionSelectedValidator(this.data.dropdowns.provStates),
          ]);
        this.contactForm.get('provStateId').updateValueAndValidity();
        this.contactForm.get('provStateId').enable();

        this.contactForm.get('postalZipCode').setValidators(Validators.required);
        this.contactForm.get('postalZipCode').updateValueAndValidity();
        this.contactForm.get('postalZipCode').enable();

        this.contactForm.get('locationId').setValidators(Validators.required);
        this.contactForm.get('locationId').updateValueAndValidity();
        this.contactForm.get('locationId').enable();
        //this.getLocations(this.contactForm.get('provStateId').value, this.contactForm.get('countryId').value);
      });

    this.contactForm
      .get('homePhoneNumber')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (value) {
          this.prospectForm.patchValue({ ...this.prospectForm, phoneNumber: value });
        }
      });

    this.contactForm
      .get('cellPhoneNumber')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value: any) => {
        this.contactForm.patchValue({ ...this.contactForm, homePhoneNumber: value });
      });

    this.prospectForm
      .get('emailAddress')
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (value) {
          this.associateForm.patchValue({ emailAddress: value }, { emitEvent: false });
          this.queryProspects(
            'emailAddress',
            value,
            <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
            null,
            this.data.dropdowns.provStates
          );
        } else {
          this.queryProspects(
            'emailAddress',
            undefined,
            <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
            null,
            this.data.dropdowns.provStates
          );
        }
      });

    this.prospectForm
      .get('freeformName')
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (value) {
          this.associateForm.patchValue({ ...this.associateForm, name: value }, { emitEvent: false });
        }
      });

    this.prospectForm
      .get('phoneNumber')
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value) {
          this.contactForm.patchValue({ ...this.contactForm, homePhoneNumber: value }, { emitEvent: false });

          const formattedNumber = value.replace(/[\s()-]/g, '');
          if (formattedNumber.length === 10) {
            this.queryProspects(
              'phoneNumber',
              formattedNumber,
              <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
              null,
              this.data.dropdowns.provStates
            );
          } else {
            this.queryProspects(
              'phoneNumber',
              undefined,
              <BehaviorSubject<ProspectReportQueryParameters>>this.queryParamSub$,
              null,
              this.data.dropdowns.provStates
            );
          }
        }
      });
    this.localSettings$ = this.localSettingsStateService.selectLocalSettingsFromState();

    this.filteredProvStates$ = this.contactForm.get('provStateId').valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      map((provStateName: string) =>
        provStateName ? this._filterProvinceState(provStateName || '') : this.data.dropdowns.provStates.slice()
      )
    );

    this.contactForm
      .get('locationId')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((input: any) => {
        const location =
          input?.description && input?.provStateId && input?.countryId
            ? `${input.description} ${input.provStateId}, ${input.countryId}`
            : input;
        this.filteredLocations = location ? this._filterLocations(location || '') : this.locations?.slice();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  provinceStateSelected(provState: ProvinceState) {
    this.contactForm.patchValue(
      {
        countryId: provState?.countryId,
        provStateId: provState,
      },
      { emitEvent: false }
    );

    const params: LocationQueryParameters = new LocationQueryParameters({
      provStateId: provState?.id,
      pageSize: 2000,
    });

    this.apiService
      .get<Location[]>('v/1/associates/locations', { params: params })
      .pipe(takeUntil(this.destroy$))
      .subscribe((locations) => {
        this.locations = locations;
        this.contactForm
          .get('locationId')
          .setValidators(CustomValidators.dropdownOptionSelectedValidator(this.locations));
        CustomValidators.dropdownOptionSelectedValidator(this.locations);
        this.contactForm.patchValue({ locationId: '' });
        this.contactForm.get('locationId').enable();
      });
  }

  locationSelected(location: Location) {
    this.contactForm.patchValue({
      location: location,
    });
  }

  displayAssociateName(filteredAssociates: AssociateReport[], currentName: string, value: string) {
    let displayAssociate: AssociateReport;
    if (filteredAssociates.length > 0) {
      displayAssociate = filteredAssociates.find((associate: AssociateReport) => associate.id === value);
      if (displayAssociate) {
        return displayAssociate.firstName
          ? `${displayAssociate.lastName}, ${displayAssociate.firstName}`
          : `${displayAssociate.fullName}`;
      } else {
        return currentName;
      }
    } else {
      return value;
    }
  }

  async queryProspects(
    type: string,
    value: string,
    queryParamSub$: any,
    filteredAssociates: AssociateReport[],
    provStates: ProvinceState[]
  ) {
    const queryParams: ProspectReportQueryParameters = new ProspectReportQueryParameters({
      prospectStatusId: ['1'],
      showRecentShowroom: 'true',
      showRecentRetention: 'true',
    });

    let selectedAssociate: AssociateReport;
    let phoneNumbers = '';

    if (filteredAssociates && filteredAssociates.length > 0) {
      selectedAssociate = filteredAssociates.find((associate) => associate.id === value);
      this.selectedAssoc = selectedAssociate;
      if (selectedAssociate) {
        this.currentAssociateName = selectedAssociate.firstName
          ? `${selectedAssociate.lastName}, ${selectedAssociate.firstName}`
          : `${selectedAssociate.fullName}`;

        this.associateForm.patchValue({
          ...this.associateForm.value,
          emailAddress: `${selectedAssociate.emailAddress}`,
          classId: selectedAssociate.classId,
        });

        this.associateForm.get('emailAddress').disable();
        this.associateForm.get('classId').disable();
        this.contactForm.patchValue(
          {
            ...this.contactForm.value,
            addressLine1: selectedAssociate.addressLine1,
            homePhoneNumber: selectedAssociate.homePhoneNumber,
            cellPhoneNumber: selectedAssociate.cellPhoneNumber,
            countryId: selectedAssociate.countryId,
            postalZipCode: selectedAssociate.postalZipCode,
            provStateId: provStates.find((provState: ProvinceState) => provState.id === selectedAssociate.provStateId),
            locationId: {
              countryId: selectedAssociate.countryId,
              description: selectedAssociate.locationName,
              id: selectedAssociate.locationId,
              postalCode: selectedAssociate.postalZipCode,
              provStateId: selectedAssociate.provStateId,
            },
          },
          { emitEvent: false }
        );

        Object.keys(this.contactForm.value).forEach((key) => this.contactForm.get(key).disable({ emitEvent: false }));

        this.prospectForm.patchValue({
          ...this.prospectForm.value,
          freeformName: selectedAssociate.firstName
            ? `${selectedAssociate.lastName}, ${selectedAssociate.firstName}`
            : `${selectedAssociate.fullName}`,
          emailAddress: `${selectedAssociate.emailAddress}`,
          phoneNumber: `${selectedAssociate.homePhoneNumber}`,
        });
        this.prospectForm.get('emailAddress').disable();
        this.prospectForm.get('phoneNumber').clearValidators();
        this.prospectForm.get('phoneNumber').updateValueAndValidity();
      }
    }
    switch (type) {
      case 'associateId':
        if (selectedAssociate) {
          this.data.newProspect.associateId = value;
          queryParams.associateId = [value];

          const toastOptions: { message: string; actionText: string; options: { duration: number } } = {
            message: `Searching for existing prospects...`,
            actionText: null,
            options: { duration: 3500 },
          };

          this.salesPlannerStateService.addToast(toastOptions);
          await new Promise((wait) => setTimeout(wait, 3500));

          this.stepperElement.linear = false;
          this.stepperElement.selected.completed = true;
          this.stepperElement.next();
          this.stepperElement.selected.completed = true;
          this.stepperElement.selectedIndex = 3;
          this.stepperElement.linear = true;
        } else {
          this.associateForm.enable({ emitEvent: false });
          this.associateForm.patchValue({ name: this.currentAssociateName }, { emitEvent: false });
          Object.keys(this.contactForm.value).forEach((key) => this.contactForm.get(key).enable({ emitEvent: false }));
          this.prospectForm.patchValue(
            { ...this.data.newProspect, freeformName: value, customerTypeId: '1' },
            { emitEvent: false }
          );
          queryParams.search = this.currentAssociateName;
        }
        break;
      case 'phoneNumber':
        if (value) {
          phoneNumbers = phoneNumbers.concat(`${value.replace(/\D+/g, '')}`);
        }
        if (
          this.contactForm.get('cellPhoneNumber').value &&
          this.contactForm.get('cellPhoneNumber').value.replace(/\D+/g, '').length === 10
        ) {
          phoneNumbers = phoneNumbers.concat(`,${this.contactForm.get('cellPhoneNumber').value.replace(/\D+/g, '')}`);
        }

        queryParams.prospectPhoneNumber = phoneNumbers ? phoneNumbers : undefined;
        break;
      case 'emailAddress':
        this.data.newAssociate.emailAddress = value;
        queryParams.prospectEmailAddress = value;
        break;
    }
    this.filteredAssociates$.next([]);
    queryParamSub$.next(queryParams);
  }

  selectProspect(prospect: Prospect, localSettings: LocalSettingsState) {
    this.dialogRef.close();
    let queryParams: any;
    switch (localSettings.defaultProspectTab) {
      case 'A':
        queryParams = { tab: 'activity' };
        break;
      case 'P':
        queryParams = { tab: 'prospect' };
        break;
      case 'V':
        queryParams = { tab: 'vehicle' };
        break;
      default:
        queryParams = { tab: 'activity' };
        break;
    }
    this.routerStateService.go([`crm/sales-planner/prospects/${prospect.id}`], {
      queryParams: queryParams,
    });
  }

  setupValidators() {
    this.data.requiredFields.associate.forEach((field) => {
      this.associateForm
        .get(field)
        .setValidators(
          this.associateForm.get(field).validator
            ? [Validators.required, this.associateForm.get(field).validator]
            : Validators.required
        );
      this.associateForm.get(field).updateValueAndValidity();
      this.associateForm.markAllAsTouched();
    });

    this.data.requiredFields.contact.forEach((field) => {
      this.contactForm.get(field).setValidators(Validators.required);
      this.contactForm.get(field).updateValueAndValidity();
      this.contactForm.markAllAsTouched();
    });

    this.data.requiredFields.prospect.forEach((field) => {
      this.prospectForm
        .get(field)
        .setValidators(
          this.prospectForm.get(field).validator
            ? [Validators.required, this.prospectForm.get(field).validator]
            : Validators.required
        );
      this.prospectForm.get(field).updateValueAndValidity();
      this.prospectForm.markAllAsTouched();
    });
  }

  setupDisabledFields() {
    this.data.disabledFields.associate.forEach((field) => {
      this.associateForm.get(field).disable({ emitEvent: false });
    });

    this.data.disabledFields.contact.forEach((field) => {
      this.contactForm.get(field).disable({ emitEvent: false });
    });

    this.data.disabledFields.prospect.forEach((field) => {
      if (field !== '') {
        this.prospectForm.get(field).disable({ emitEvent: false });
      }
    });
  }

  reInitFormFields(freeform: boolean, emailRequired: boolean) {
    if (freeform && emailRequired) {
      this.prospectForm.get('phoneNumber').setValidators(Validators.required);
      this.prospectForm.get('phoneNumber').updateValueAndValidity();
      this.prospectForm.markAllAsTouched();

      this.filteredAssociates$.next([]);
    } else {
      this.prospectForm.get('phoneNumber').clearValidators();
      this.prospectForm.get('phoneNumber').updateValueAndValidity();
      this.prospectForm.markAllAsTouched();
    }
  }

  emitData(
    data: any,
    freeform: boolean,
    othersExistingProspect: ProspectReport,
    authenticatedUser: AuthenticatedUser,
    dialogRef: MatDialogRef<ProspectCreateDialogComponent>,
    ownExistingProspect: boolean
  ) {
    const newAddress: Address = {
      ...data.newAddress,
      cellPhoneNumber: this.contactForm.get('cellPhoneNumber').value
        ? this.contactForm.get('cellPhoneNumber').value.replace(/\D+/g, '')
        : null,
      homePhoneNumber: this.contactForm.get('homePhoneNumber').value
        ? this.contactForm.get('homePhoneNumber').value.replace(/\D+/g, '')
        : null,
      addressLine1: this.contactForm.get('addressLine1').value,
      locationId: this.contactForm.get('locationId').value.id,
      provStateId: this.contactForm.get('provStateId').value.id,
      countryId: this.contactForm.get('countryId').value,
      postalZipCode: this.contactForm.get('postalZipCode').value,
    };

    if (this.associateForm.get('name').value.split(',').length > 1) {
      data.newAssociate.firstName = this.associateForm.get('name').value.split(',')[1].trim();
      this.data.newAssociate.lastName = this.associateForm.get('name').value.split(',')[0].trim();
    } else {
      data.newAssociate.firstName = this.associateForm.get('name').value;
      data.newAssociate.lastName = '';
    }

    let newAssociate: Associate = {
      ...data.newAssociate,
      classId: this.associateForm.get('classId').value,
      emailAddress: this.associateForm.get('emailAddress').value,
      contactPreferenceId: this.associateForm.get('contactPreferenceId').value,
      messagePreferenceId: this.associateForm.get('messagePreferenceId').value,
    };

    if (
      this.contactForm.get('cellPhoneNumber').value !== null &&
      this.contactForm.get('cellPhoneNumber').value.length > 0
    ) {
      newAssociate = { ...newAssociate, messagePreferenceId: 2 };
    } else if (
      this.associateForm.get('emailAddress').value !== null &&
      this.associateForm.get('emailAddress').value.length > 0 &&
      this.associateForm.get('messagePreferenceId').value === null
    ) {
      newAssociate = { ...newAssociate, messagePreferenceId: 1 };
    }
    let newProspect: Prospect = {
      ...data.newProspect,
      channelId: this.prospectForm.get('channelId').value,
      customerTypeId: this.prospectForm.get('customerTypeId').value,
      emailAddress: this.prospectForm.get('emailAddress').value,
      freeformName: this.prospectForm.get('freeformName').value,
      rooftopId: this.prospectForm.get('rooftopId').value,
      phoneNumber: this.prospectForm.get('phoneNumber').value
        ? this.prospectForm.get('phoneNumber').value.replace(/\D+/g, '')
        : null,
      primaryEmployeeId: this.prospectForm.get('primaryEmployeeId').value,
      secondaryEmployeeId: this.prospectForm.get('secondaryEmployeeId').value,
      sourceId: this.prospectForm.get('sourceId').value,
      actionId: this.prospectForm.get('actionId').value,
    };

    if (freeform || this.newAssociate) {
      newProspect = { ...newProspect, associateId: null };
    }
    let salespersonTakeoverMessage: string = null;
    if (othersExistingProspect && othersExistingProspect.salespersonId != null && !ownExistingProspect) {
      salespersonTakeoverMessage = `${authenticatedUser.user.firstName} ${
        authenticatedUser.user.lastName
      } has taken over Customer Prospect ID ${othersExistingProspect.id}, ${
        othersExistingProspect.associateId
          ? [othersExistingProspect.prospectFirstName, othersExistingProspect.prospectLastName]
              .filter(Boolean)
              .join(' ')
          : othersExistingProspect.prospectFreeformName
      }, from ${othersExistingProspect.salespersonFirstName} ${othersExistingProspect.salespersonLastName}`;

      const prospectTakeoverActionId: number = this.data.dropdowns.prospectActions.find(
        (ac: Action) => ac.description.toLowerCase() === 'prospect takeover'
      ).id;
      newProspect = { ...newProspect, actionId: prospectTakeoverActionId };
    }

    dialogRef.close({
      freeform: freeform,
      newAddress: newAddress,
      newAssociate: newAssociate,
      newProspect: newProspect,
      salespersonTakeoverMessage: salespersonTakeoverMessage,
    });
  }

  stepperScroll(event: any) {
    const stepId = this.stepperElement._getStepLabelId(event.selectedIndex);
    const stepElement = document.getElementById(stepId);

    if (stepElement && event.focusAssociate != false) {
      setTimeout(() => {
        if (this.associateName && stepId == 'cdk-step-label-0-1') {
          this.associateName.nativeElement.focus();
        } else if (this.freeformName && stepId === 'cdk-step-label-0-1') {
          this.freeformName.nativeElement.focus();
        }

        stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }, 500);
    }
  }

  autocompleteSelected(selected: boolean) {
    this.autoCompleteSelected = selected;
    this.stepperElement.selected.completed = selected;
  }

  ngAfterViewInit() {
    this.stepperElement.next();
    this.cdr.detectChanges();
  }

  setPostalCode(postalCode: string) {
    this.contactForm.patchValue({
      postalZipCode: postalCode,
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  private _filterProvinceState(value: string): ProvinceState[] {
    if (value === '') {
      return this.data.dropdowns.provStates.slice();
    }
    return this.data.dropdowns.provStates.filter((provState) =>
      provState.name.toLowerCase().startsWith(value.toLowerCase())
    );
  }

  private _filterLocations(value: string): Location[] {
    if (value === '') {
      return this.locations?.slice();
    }

    return this.locations?.filter((location) =>
      `${location.description} ${location.provStateId}, ${location.countryId}`
        .toLowerCase()
        .includes(value.toLowerCase())
    );
  }

  displayProvStateName(provState?: ProvinceState): string {
    return provState ? provState.name : '';
  }

  displayLocation(location?: Location): string {
    return location ? `${location.description} ${location.provStateId}, ${location.countryId}` : '';
  }
}
