import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'crm-rooftop-selection-dialog',
  templateUrl: './rooftop-selection-dialog.component.html',
})
export class RooftopSelectionDialog {
  rooftopIdForm = this.fb.group({
    rooftopId: ['', Validators.required],
  });
  constructor(
    public dialogRef: MatDialogRef<RooftopSelectionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { dropdowns: any },
    private fb: FormBuilder
  ) {}
}
