import * as fromAssociateClasses from './associate-classes.actions';
import { associateClassesAdapter, AssociateClassesState, initialState } from './associate-classes.interfaces';

export function reducer(
  state: AssociateClassesState = initialState,
  action: fromAssociateClasses.AssociateClassesAction
): AssociateClassesState {
  switch (action.type) {
    case fromAssociateClasses.GET_ASSOCIATE_CLASSES_FROM_SERVER_SUCCESS: {
      return {
        ...associateClassesAdapter.addMany(action.payload.associateClasses, state)
      };
    }

    default: {
      return state;
    }
  }
}
