import { Action } from '@ngrx/store';
import { ChannelQueryParameters } from '@quorum/models/xs-query';
import { Channel } from '@quorum/models/xs-resource';

export const GET_CHANNELS_FROM_SERVER = '[CRM] Get Channels From Server';
export class GetChannelsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_CHANNELS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ChannelQueryParameters) {}
}

export const GET_CHANNELS_FROM_SERVER_SUCCESS = '[CRM] Get Channels From Server Success';
export class GetChannelsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_CHANNELS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { channels: Channel[] }) {}
}

export const GET_CHANNELS_FROM_SERVER_FAILURE = '[CRM] Get Channels From Server Failure';
export class GetChannelsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_CHANNELS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ChannelsAction = GetChannelsFromServer | GetChannelsFromServerSuccess | GetChannelsFromServerFailure;
