export class LeadGeneratorVehicleSearchQueryParameters {
  actioned?: string;
  equityThreshold?: number;
  inEquity?: string;
  make?: string;
  modelCode?: string;
  page?: number;
  pageSize?: number;
  pegCode?: string;
  rooftopId?: string;
  salespersonId?: string[];
  saleTypeId?: string[];
  sort?: string;
  termFrom?: number;
  termTo?: number;
  userEmployeeId?: string;
  vehicleDescription?: string;
  vehicleId?: string;
  vehicleSubTypeId?: string;
  vehicleYearFrom?: number;
  vehicleYearTo?: number;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}
