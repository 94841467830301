import { Injectable } from '@angular/core';
import { Status } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromVehicleStatuses from './vehicle-statuses.actions';
import { VehicleStatusesState } from './vehicle-statuses.interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleStatusesEffects {
  
  getVehicleStatusesFromServer = createEffect(() => this.dataPersistence.fetch(fromVehicleStatuses.GET_VEHICLE_STATUSES_FROM_SERVER, {
    run: (action: fromVehicleStatuses.GetVehicleStatusesFromServer, state: VehicleStatusesState) => {
      return this.apiService
        .get<Status[]>(`v/1/vehicles/vehicle-statuses`, {
          params: action.payload
        })
        .pipe(
          map(vehicleStatuses => {
            return new fromVehicleStatuses.GetVehicleStatusesFromServerSuccess({
              vehicleStatuses: vehicleStatuses
            });
          })
        );
    },
    onError: (action: fromVehicleStatuses.GetVehicleStatusesFromServer, error) => {
      console.error('Error', error);
    }
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleStatusesState>,
    private apiService: ApiService
  ) {}
}
