export class BlackbookDrilldownQueryParameters {
  country?: string;
  customerid?: string;
  make?: string;
  mileage?: string;
  model?: string;
  series?: string;
  style?: string;
  year?: string;

  constructor(obj?: BlackbookDrilldownQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
