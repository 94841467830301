import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { RouterStateService } from '@quorum/sha-router';
import { Observable } from 'rxjs';
import { map, filter, switchMap } from 'rxjs/operators';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';

@Injectable()
export class EquityMiningEnabledGuard implements CanActivate, CanActivateChild {
  constructor(
    private authenticationStateService: AuthenticationStateService,
    private routerStateService: RouterStateService,
    private systemControlStateService: SystemControlStateService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
      filter(employee => employee != null),
      switchMap(employee => {
        return this.systemControlStateService
          .getSystemControlValue('CL_LEAD_GENERATOR_ENABLED', employee.rooftopId)
          .pipe(
            map(sct => {
              if (sct.value == '1') {
                return true;
              } else {
                this.routerStateService.go(['/crm/sales-planner'], {});
                return false;
              }
            })
          );
      })
    );
    // .take(1) // this should allow you to grab the current value each time canActivate is run
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }
}
