import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReportingVehicleSearch as VehicleSearch } from '@quorum/models/xs-resource';

export interface VehicleSearchState extends EntityState<VehicleSearch> {
  isLoading: boolean;
}

export const vehicleSearchResultsAdapter: EntityAdapter<VehicleSearch> = createEntityAdapter<VehicleSearch>({
  selectId: (vehicleSearchResult: VehicleSearch) => vehicleSearchResult.vehicleId,
});

export const initialState: VehicleSearchState = vehicleSearchResultsAdapter.getInitialState({
  ids: [],
  entities: {},
  isLoading: false,
});

export const vehiclesSearchFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const vehicleSearchResultsSelector = createSelector(
  vehiclesSearchFeatureSelector,
  (state: any) => state.vehicleSearch
);
export const vehicleSearchResultsIsLoadingSelector = createSelector(
  vehicleSearchResultsSelector,
  (state: any) => state.isLoading
);
export const { selectIds, selectEntities, selectAll, selectTotal } =
  vehicleSearchResultsAdapter.getSelectors(vehicleSearchResultsSelector);
