import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ShaServicesModule } from '@applications/sha-services';
import { NxModule } from '@nrwl/angular';
import { ApiInterceptor } from '@quorum/api';
import { AuthenticationGuard, AuthenticationStateService } from '@quorum/authentication/services';
import {
  AvailableListComponent,
  AvailableTabComponent,
  CrmEquityMiningNgUiModule,
  ShellComponent as LeadManagementShellComponent,
  SubPrimeListComponent,
  VehiclesListComponent,
  CustomersListComponent,
  ServiceAppointmentsListComponent,
  OverMileageListComponent,
} from '@quorum/crm-equity-mining-ng-ui';
import { EquityMiningEnabledGuard } from '@quorum/crm-equity-mining/services';
import { CrmNgUiModule, ShellComponent as CrmShellComponent } from '@quorum/crm-ng-ui';
import {
  AddProspectVehicleShellComponent,
  CrmProspectsNgUiModule,
  ProspectLeadsComponent,
} from '@quorum/crm-prospects-ng-ui';
import {
  CrmSalesPlannerNgUiModule,
  DetailComponent,
  MasterComponent,
  ShellComponent,
} from '@quorum/crm-sales-planner-ng-ui';
import {
  CrmVehicleSearchNgUiModule,
  ShellComponent as VehicleSearchShellComponent,
} from '@quorum/crm-vehicle-search-ng-ui';
import { ShaRouterModule } from '@quorum/sha-router';
import { InEquityTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/in-equity-tab/in-equity-tab.component';
// eslint-disable-next-line max-len
import { OverMileageLeaseTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/over-mileage-tab/over-mileage-tab.component';
// eslint-disable-next-line max-len
import { ServiceAppointmentsTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/service-appointments-tab/service-appointments-tab.component';
import { SubPrimeTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/sub-prime-tab/sub-prime-tab.component';
// eslint-disable-next-line max-len
import { UnsoldVehiclesSearchTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/unsold-vehicles-search-tab/unsold-vehicles-search-tab.component';
// eslint-disable-next-line max-len
import { VehiclesSearchTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/vehicles-search-tab/vehicles-search-tab.component';
import { CrmEquityMiningModule } from 'libs/crm-equity-mining/src/lib/crm-equity-mining.module';
import { CrmAppStateModule } from 'libs/crm-root/src/lib/crm-root.module';
import { AppComponent } from './app.component';
import { authenticationStagesSetup, checkAuthentication } from './app.initializer';
import { CustomersTabComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/customers-tab/customers-tab.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ListShellComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/list-shell/list-shell.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NxModule.forRoot(),
    CrmAppStateModule,
    CrmNgUiModule,
    CrmSalesPlannerNgUiModule,
    CrmVehicleSearchNgUiModule,
    CrmEquityMiningNgUiModule,
    CrmEquityMiningModule,
    ShaRouterModule,
    CrmProspectsNgUiModule,
    RouterModule.forRoot(
      [
        {
          path: 'login',
          loadChildren: () => import('@quorum/authentication-ng-ui').then((m) => m.AuthenticationNgUiModule),
        },
        {
          path: 'crm',
          component: CrmShellComponent,
          canActivate: [AuthenticationGuard],
          children: [
            { path: '', redirectTo: 'sales-planner', pathMatch: 'full' },
            {
              path: 'sales-planner',
              component: ShellComponent,
              canActivate: [AuthenticationGuard],
              children: [
                {
                  path: '',
                  component: MasterComponent,
                  outlet: 'master',
                },
                {
                  path: 'tasks',
                  children: [
                    {
                      path: ':id',
                      component: DetailComponent,
                    },
                  ],
                },
                {
                  path: 'prospects',
                  children: [
                    {
                      path: ':id',
                      component: DetailComponent,
                    },
                    {
                      path: ':id/vehicle-search',
                      component: VehicleSearchShellComponent,
                    },
                    {
                      path: ':id/add-prospect-vehicle',
                      component: AddProspectVehicleShellComponent,
                    },
                  ],
                },
                {
                  path: 'appointments',
                  children: [
                    {
                      path: ':id',
                      component: DetailComponent,
                    },
                  ],
                },
                {
                  path: 'service-appointments',
                  children: [
                    {
                      path: ':id',
                      component: DetailComponent,
                    },
                  ],
                },
                {
                  path: 'dealer-promotions',
                  children: [
                    {
                      path: ':id/:sequence',
                      component: DetailComponent,
                    },
                  ],
                },
                {
                  path: 'deliveries',
                  children: [
                    {
                      path: ':id',
                      component: DetailComponent,
                    },
                  ],
                },
              ],
            },
            {
              path: 'prospects',
              component: ProspectLeadsComponent,
            },
            {
              path: 'lead-generator/list',
              component: ListShellComponent,
              children: [
                {
                  path: 'available',
                  component: AvailableListComponent,
                },
                {
                  path: 'over-mileage-lease',
                  component: OverMileageListComponent,
                },
                {
                  path: 'sub-prime',
                  component: SubPrimeListComponent,
                },
                {
                  path: 'service-appointments',
                  component: ServiceAppointmentsListComponent,
                },
                {
                  path: 'customers',
                  component: CustomersListComponent,
                },
                {
                  path: 'vehicles',
                  component: VehiclesListComponent,
                },
              ],
            },
            {
              path: 'lead-generator',
              component: LeadManagementShellComponent,
              canActivate: [AuthenticationGuard, EquityMiningEnabledGuard],
              children: [
                { path: '', pathMatch: 'full', redirectTo: 'available' },
                {
                  path: 'available',
                  component: AvailableTabComponent,
                },
                {
                  path: 'in-equity',
                  component: InEquityTabComponent,
                },
                {
                  path: 'over-mileage-lease',
                  component: OverMileageLeaseTabComponent,
                },
                {
                  path: 'sub-prime',
                  component: SubPrimeTabComponent,
                },
                {
                  path: 'unsold-vehicles',
                  component: UnsoldVehiclesSearchTabComponent,
                },
                {
                  path: 'service-appointment',
                  component: ServiceAppointmentsTabComponent,
                },
                {
                  path: 'vehicles',
                  component: VehiclesSearchTabComponent,
                },
                {
                  path: 'customers',
                  component: CustomersTabComponent,
                },
              ],
            },
            {
              path: 'vehicle-search',
              component: VehicleSearchShellComponent,
            },
          ],
        },
        { path: '', pathMatch: 'full', redirectTo: 'crm/sales-planner' },
      ],
      { relativeLinkResolution: 'legacy' }
    ),
    ShaServicesModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: authenticationStagesSetup,
      multi: true,
      deps: [AuthenticationStateService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkAuthentication,
      multi: true,
      deps: [AuthenticationStateService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true, hasBackdrop: true } },
  ],
})
export class AppModule {}
