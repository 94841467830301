import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { InEquityQueryParameters } from '@quorum/models/xs-query';
import { RouterStateService } from '@quorum/sha-router';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { TabBaseComponent } from '../tab-base.component';

@Component({
  selector: 'crm-in-equity-tab',
  templateUrl: './in-equity-tab.component.html',
  styleUrls: ['./in-equity-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InEquityTabComponent extends TabBaseComponent implements OnInit {
  constructor(
    public apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public crmEntityStateService: CrmEntityStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public routerStateService: RouterStateService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public equityMiningStateService: EquityMiningStateService,
    public systemControlStateService: SystemControlStateService
  ) {
    super(
      apiService,
      authenticationStateService,
      browserControlCommunicationService,
      crmEntityStateService,
      dialog,
      dropdownsStateService,
      routerStateService,
      fb,
      snackBar,
      route,
      cdr,
      equityMiningStateService,
      'in-equity',
      systemControlStateService
    );
  }

  ngOnInit() {
    this.sort = '-availableDate';
    this.defaultFormModel = new InEquityQueryParameters({
      actioned: '',
      salespersonId: [],
      rooftopId: '',
      saleTypeId: ['L'],
      sort: '-availableDate',
      pageSize: 25,
      page: 0,
      userEmployeeId: null,
    });
    this.Init();
  }
}
