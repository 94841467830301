import { FormGroup, FormControl } from '@angular/forms';

export { createFormGroupFromObject };

function createFormGroupFromObject(object: any) {
  let fg: FormGroup = new FormGroup({});
  Object.keys(object).forEach(key => {
    if (key != 'embedded') {
      fg.addControl(key, new FormControl(object[key] != null ? object[key] : null));
    }
  });
  return fg;
}
