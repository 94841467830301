<div>
  <div fxLayout="column">
    <div id="header" fxLayout="row" class="header-container" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div fxLayout="column">
          <span fxLayoutAlign="start center" class="mat-subheading-2">
            <mat-icon>event_note</mat-icon>Delivery {{ deliveryCopy?.id }}
          </span>
          <span class="mat-caption" *ngIf="deliveryCopy.deliveryFlag === '1'">Delivered</span>
          <div>
            <span style="font-size: small;" class="">
              <span *ngIf="deliveryCopy.embedded?.salesManagerAssociate">
                <span class="font-medium">Sales Manager:</span>
                {{deliveryCopy.embedded.salesManagerAssociate?.lastName}},
                {{deliveryCopy.embedded.salesManagerAssociate?.firstName}}
              </span>
              <span
                *ngIf="deliveryCopy.embedded?.salesManagerAssociate && deliveryCopy.embedded?.businessManagerAssociate">
                —
              </span>
              <span *ngIf="deliveryCopy.embedded?.businessManagerAssociate">
                <span class="font-medium">Business Manager:</span>
                {{deliveryCopy.embedded.businessManagerAssociate?.lastName}},
                {{deliveryCopy.embedded.businessManagerAssociate?.firstName}}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <form fxLayout="row wrap" [formGroup]="deliveryForm">
      <mat-form-field fxFlex="50">
        <input matInput autocomplete="off" formControlName="deliveryDate" [matDatepicker]="picker" placeholder="Date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input matInput type="time" name="time" formControlName="deliveryTime" placeholder="Time" />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select placeholder="Delivered" name="delivered" formControlName="deliveryFlag"
          [disabled]="['P', 'S'].includes(deliveryCopy.statusId)">
          <mat-option value="0"> No </mat-option>
          <mat-option value="1"> Yes </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div fxLayout="row" fxLayoutAlign="center center" class="button-container">
      <button mat-raised-button color="primary" (click)="updateDelivery(deliveryCopy)">UPDATE</button>
    </div>
  </div>
</div>