import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Action as ProspectAction } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface ProspectActionsState extends EntityState<ProspectAction> {}

export const prospectActionsAdapter: EntityAdapter<ProspectAction> = createEntityAdapter<ProspectAction>({
  selectId: (action: ProspectAction) => action.id,
});

export const initialState: ProspectActionsState = prospectActionsAdapter.getInitialState({});

export const prospectActionsSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.prospectActions
);

const actionsSelector = prospectActionsAdapter.getSelectors(prospectActionsSelector);

export const selectProspectClosedActions = createSelector(
  actionsSelector.selectAll,
  (prospectActions: ProspectAction[]) => {
    /**
     * categoryId: 1 = Opportunity, 2 = Lead, 3 = Sale, 4 = Lost Sale
     */
    return prospectActions.filter((action: ProspectAction) => action.categoryId > 2 && action.description !== 'Sold');
  }
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  prospectActionsAdapter.getSelectors(prospectActionsSelector);
