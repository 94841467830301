import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticatedUser, Employee, VehicleOfInterest } from '@quorum/models/xs-resource';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'libs/api/src/lib/api.service';
import { BlackbookDrilldownQueryParameters } from '@quorum/models/xs-query';
import { filter, pairwise, startWith, take } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';

@Component({
  selector: 'crm-prospect-vehicle-of-interest-dialog',
  templateUrl: './prospect-vehicle-of-interest-dialog.component.html',
  styleUrls: ['./prospect-vehicle-of-interest-dialog.component.scss'],
})
export class ProspectVehicleOfInterestDialogComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;

  isLinear = true;

  firstFormGroup: FormGroup = new FormGroup({
    year: new FormControl({ value: '', disabled: false }, Validators.required),
    make: new FormControl({ value: '', disabled: true }, Validators.required),
    model: new FormControl({ value: '', disabled: true }, Validators.required),
    trim: new FormControl({ value: '', disabled: true }),
  });
  secondFormGroup: FormGroup = new FormGroup({
    colour: new FormControl({ value: '', disabled: false }),
    maxMileage: new FormControl({ value: '', disabled: false }),
    maxPrice: new FormControl({ value: '', disabled: false }),
    vin: new FormControl({ value: '', disabled: false }),
  });

  mileageLabel: string = '';

  years: any[] = [];
  makes: any[] = [];
  models: any[] = [];
  trims: any[] = [];

  clss = 'all';
  rooftopId: number;
  loadVehicleOfInterest: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { prospectId: number; vehicleOfInterest: VehicleOfInterest },
    public dialogRef: MatDialogRef<ProspectVehicleOfInterestDialogComponent>,
    private apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public systemControlStateService: SystemControlStateService
  ) {}

  ngOnInit(): void {
    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        take(1)
      )
      .subscribe((employee: Employee) => {
        this.rooftopId = employee.rooftopId;

        if (this.data.vehicleOfInterest) {
          this.loadVehicleOfInterest = true;
          this.buildYearsDropdown();

          this.secondFormGroup.get('colour')?.setValue(this.data.vehicleOfInterest.colour, { emitEvent: false });
          this.secondFormGroup
            .get('maxMileage')
            ?.setValue(this.data.vehicleOfInterest.maxMileage, { emitEvent: false });
          this.secondFormGroup.get('maxPrice')?.setValue(this.data.vehicleOfInterest.maxPrice, { emitEvent: false });
          this.secondFormGroup.get('vin')?.setValue(this.data.vehicleOfInterest.vin, { emitEvent: false });
        } else {
          this.handleFilterFormChanges();
          this.buildYearsDropdown();
        }

        this.systemControlStateService
          .getSystemControlValues(['CL_DEALER_COUNTRY_ID'], employee.rooftopId)
          .pipe(take(1))
          .subscribe((sct) => {
            this.mileageLabel = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'mi.' : 'km';
          });
      });
  }

  buildYearsDropdown(): void {
    this.apiService
      .get<any>(`v/1/reporting/vehicles/blackbook-drilldown/${this.clss}/${this.rooftopId}`)
      .subscribe((response) => {
        this.years = response.map((result: any) => result.year);

        if (this.data.vehicleOfInterest && this.loadVehicleOfInterest) {
          this.firstFormGroup.get('year')?.setValue(this.data.vehicleOfInterest.year.toString(), { emitEvent: false });
          this.buildMakesDropdown(this.data.vehicleOfInterest.year.toString());
          this.firstFormGroup.get('make')?.enable({ emitEvent: false });
        }
      });
  }

  buildMakesDropdown(year: string): void {
    this.apiService
      .get<any>(`v/1/reporting/vehicles/blackbook-drilldown/${this.clss}/${year}/${this.rooftopId}`)
      .subscribe((response) => {
        this.makes = response.make;

        if (this.data.vehicleOfInterest && this.loadVehicleOfInterest) {
          this.firstFormGroup.get('make')?.setValue(this.data.vehicleOfInterest.make, { emitEvent: false });
          this.buildModelsDropdown(this.data.vehicleOfInterest.year.toString(), this.data.vehicleOfInterest.make);
          this.firstFormGroup.get('model')?.enable({ emitEvent: false });
        }
      });
  }

  buildModelsDropdown(year: string, make: string): void {
    this.apiService
      .get<any>(`v/1/reporting/vehicles/blackbook-drilldown/${this.clss}/${year}/${make}/${this.rooftopId}`)
      .subscribe((response) => {
        this.models = response.model;

        if (this.data.vehicleOfInterest && this.loadVehicleOfInterest) {
          this.firstFormGroup.get('model')?.setValue(this.data.vehicleOfInterest.model, { emitEvent: false });
          this.buildTrimsDropdown(
            this.data.vehicleOfInterest.year.toString(),
            this.data.vehicleOfInterest.make,
            this.data.vehicleOfInterest.model
          );
        }
      });
  }

  buildTrimsDropdown(year: string, make: string, model: string): void {
    let queryParams = new BlackbookDrilldownQueryParameters({
      model: model,
    });
    this.apiService
      .get<any>(`v/1/reporting/vehicles/blackbook-drilldown/${this.clss}/${year}/${make}/${this.rooftopId}`, {
        params: queryParams,
      })
      .subscribe((response) => {
        this.trims = response.series;

        if (this.data.vehicleOfInterest && this.loadVehicleOfInterest) {
          if (this.data.vehicleOfInterest.trim) {
            this.firstFormGroup.get('trim')?.setValue(this.data.vehicleOfInterest.trim, { emitEvent: false });
          }
          this.firstFormGroup.get('trim')?.enable({ emitEvent: false });

          this.handleFilterFormChanges();
          this.loadVehicleOfInterest = false;
        }
      });
  }

  handleFilterFormChanges(): void {
    this.firstFormGroup.valueChanges
      .pipe(startWith(this.firstFormGroup.value), pairwise())
      .subscribe(([previousFormValue, currentFormValue]) => {
        if (previousFormValue && previousFormValue.year !== currentFormValue.year) {
          currentFormValue.make = '';
          currentFormValue.model = '';
          currentFormValue.trim = '';

          this.firstFormGroup.get('make')?.setValue('', { emitEvent: false });
          this.firstFormGroup.get('model')?.setValue('', { emitEvent: false });
          this.firstFormGroup.get('trim')?.setValue('', { emitEvent: false });

          this.firstFormGroup.get('make')?.enable({ emitEvent: false });
          this.firstFormGroup.get('model')?.disable({ emitEvent: false });
          this.firstFormGroup.get('trim')?.disable({ emitEvent: false });

          this.buildMakesDropdown(currentFormValue.year);
        } else if (previousFormValue && previousFormValue.make !== currentFormValue.make) {
          currentFormValue.model = '';
          currentFormValue.trim = '';

          this.firstFormGroup.get('model')?.setValue('', { emitEvent: false });
          this.firstFormGroup.get('trim')?.setValue('', { emitEvent: false });

          this.firstFormGroup.get('model')?.enable({ emitEvent: false });
          this.firstFormGroup.get('trim')?.disable({ emitEvent: false });

          this.buildModelsDropdown(currentFormValue.year, currentFormValue.make);
        } else if (previousFormValue && previousFormValue.model !== currentFormValue.model) {
          currentFormValue.trim = '';

          this.firstFormGroup.get('trim')?.setValue('', { emitEvent: false });

          this.firstFormGroup.get('trim')?.enable({ emitEvent: false });

          this.buildTrimsDropdown(currentFormValue.year, currentFormValue.make, currentFormValue.model);
        } else {
          if (currentFormValue.year) {
            this.buildMakesDropdown(currentFormValue.year);
            this.firstFormGroup.get('make')?.enable({ emitEvent: false });
          }
          if (currentFormValue.make) {
            this.buildModelsDropdown(currentFormValue.year, currentFormValue.make);
            this.firstFormGroup.get('model')?.enable({ emitEvent: false });
          }
          if (currentFormValue.model) {
            this.buildTrimsDropdown(currentFormValue.year, currentFormValue.make, currentFormValue.model);
            this.firstFormGroup.get('trim')?.enable({ emitEvent: false });
          }
        }
      });
  }

  resetForms() {
    this.stepper.selectedIndex = 0;

    this.firstFormGroup.reset({
      year: { value: '', disabled: false },
      make: { value: '', disabled: true },
      model: { value: '', disabled: true },
      trim: { value: '', disabled: true },
    });

    this.secondFormGroup.reset({
      colour: { value: '', disabled: false },
      maxMileage: { value: '', disabled: false },
      maxPrice: { value: '', disabled: false },
      vin: { value: '', disabled: false },
    });
  }

  save() {
    this.dialogRef.close({
      ...this.firstFormGroup.value,
      ...this.secondFormGroup.value,
    });
  }
}
