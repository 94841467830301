import { VehicleQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';
import { Vehicle, VehicleDetail } from '@quorum/models/xs-resource';

export const GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER = '[CRM] Get Selected Prospect Vehicles From Server';
export class GetSelectedProspectVehiclesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleQueryParameters) {}
}

export const GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Vehicles From Server Success';
export class GetSelectedProspectVehiclesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicles: Vehicle[] }) {}
}

export const GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Vehicles From Server Failure';
export class GetSelectedProspectVehiclesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER = '[CRM] Get Vehicle Aggregate Data From Server';
export class GetVehicleAggregateDataFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: number) {}
}

export const GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Aggregate Data From Server Success';
export class GetVehicleAggregateDataFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: VehicleDetail) {}
}

export const GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Aggregate Data From Server Failure';
export class GetVehicleAggregateDataFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const REASSIGN_VEHICLE = '[CRM] Reassign Vehicle';
export class ReassignVehicle implements Action {
  readonly feature = 'crm';
  readonly type = REASSIGN_VEHICLE;
  readonly triggerStorageSync = false;
  constructor(public payload: { vehicleParams: Vehicle; prospectId: number }) {}
}

export const ADD_NEW_VEHICLE = '[CRM] Add New Vehicle';
export class AddNewVehicle implements Action {
  readonly feature = 'crm';
  readonly type = ADD_NEW_VEHICLE;
  readonly triggerStorageSync = false;
  constructor(public payload: { vehicleParams: Vehicle; translateRequired: boolean }) {}
}

export type VehiclesAction =
  | GetSelectedProspectVehiclesFromServer
  | GetSelectedProspectVehiclesFromServerSuccess
  | GetSelectedProspectVehiclesFromServerFailure
  | GetVehicleAggregateDataFromServer
  | GetVehicleAggregateDataFromServerSuccess
  | GetVehicleAggregateDataFromServerFailure
  | ReassignVehicle
  | AddNewVehicle;
