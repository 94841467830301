<h1 mat-dialog-title>New Note</h1>
<div mat-dialog-content>
  <div>
    <form fxLayout="row wrap" fxLayoutAlign="space-between start" [formGroup]="noteForm"
      (keydown.enter)="onAddClick(noteForm.get('note').value, data.prospectId, data.currentUser, data.notes)">
      <mat-form-field fxFlex>
        <textarea cdkFocusInitial name="note" autocomplete="false" rows="7" matInput placeholder="Enter note"
          formControlName="note" id="note"></textarea>
      </mat-form-field>
    </form>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="onAddClick(noteForm.get('note').value, data.prospectId, data.currentUser, data.notes)"
    color="primary" [disabled]="noteForm.invalid">Add</button>
</div>