import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DropdownEffects } from './+state/dropdowns.effects';
import { crmDropdownsReducer } from './+state/dropdowns.reducer';
import { DropdownsStateService } from './+state/dropdowns-state.service';
import { AssociateClassesEffects } from './+state/associate-classes/associate-classes.effects';
import { ChannelsEffects } from './+state/channels/channels.effects';
import { HtmlMappingTemplatesEffects } from './+state/html-mapping-templates/html-mapping-templates.effects';
import { DirectMessageTemplatesEffects } from './+state/direct-message-templates/direct-message-templates.effects';
import { RooftopsEffects } from './+state/rooftops/rooftops.effects';
import { SourcesEffects } from './+state/sources/sources.effects';
import { VehicleCategoriesEffects } from './+state/vehicle-categories/vehicle-categories.effects';
import { VehicleColoursEffects } from './+state/vehicle-colours/vehicle-colours.effects';
import { VehicleGenericModelsEffects } from './+state/vehicle-generic-models/vehicle-generic-models.effects';
import { VehicleMakesEffects } from './+state/vehicle-makes/vehicle-makes.effects';
import { VehicleStatusesEffects } from './+state/vehicle-statuses/vehicle-statuses.effects';
import { VehicleSubcategoriesEffects } from './+state/vehicle-subcategories/vehicle-subcategories.effects';
import { VehicleSubTypesEffects } from './+state/vehicle-subtypes/vehicle-subtypes.effects';
import { EmployeesEffects } from './+state/employees/employees.effects';
import { VendorsEffects } from './+state/vendors/vendors.effects';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('crmDropdowns', crmDropdownsReducer),
    EffectsModule.forFeature([
      DropdownEffects,
      AssociateClassesEffects,
      ChannelsEffects,
      HtmlMappingTemplatesEffects,
      DirectMessageTemplatesEffects,
      RooftopsEffects,
      SourcesEffects,
      VehicleCategoriesEffects,
      VehicleColoursEffects,
      VehicleGenericModelsEffects,
      VehicleMakesEffects,
      VehicleStatusesEffects,
      VehicleSubcategoriesEffects,
      VehicleSubTypesEffects,
      EmployeesEffects,
      VendorsEffects,
    ]),
  ],
  providers: [DropdownsStateService],
})
export class CrmDropdownsModule {}
