import { createFeatureSelector } from '@ngrx/store';
import {
  AvailableQueryParameters,
  InEquityQueryParameters,
  OverMileageLeaseQueryParameters,
  ServiceAppointmentQueryParameters,
  SubPrimeQueryParameters,
} from '@quorum/models/xs-query';

export interface EquityMiningState {
  available: AvailableQueryParameters;
  'in-equity': InEquityQueryParameters;
  'over-mileage-lease': OverMileageLeaseQueryParameters;
  'sub-prime': SubPrimeQueryParameters;
  'service-appointment': ServiceAppointmentQueryParameters;
  'unsold-vehicles': any;
  vehicles: any;
  customers: any;
}

export const initialState: EquityMiningState = {
  available: {},
  'in-equity': {},
  'over-mileage-lease': {},
  'sub-prime': {},
  'service-appointment': {},
  'unsold-vehicles': {},
  vehicles: {},
  customers: {},
};

export const equityMiningFeatureSelector = createFeatureSelector<any>('equityMining');
