import { Action } from '@ngrx/store';
import { AssociateCustomerTypeQueryParameters as CustomerTypeQueryParameters } from '@quorum/models/xs-query';
import { AssociateCustomerType as CustomerType } from '@quorum/models/xs-resource';

export const GET_CUSTOMER_TYPES_FROM_SERVER = '[CRM] Get Associate Customer Types From Server';
export class GetAssociateCustomerTypesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_CUSTOMER_TYPES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: CustomerTypeQueryParameters) {}
}

export const GET_CUSTOMER_TYPES_FROM_SERVER_SUCCESS = '[CRM] Get Associate Customer Types From Server Success';
export class GetAssociateCustomerTypesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_CUSTOMER_TYPES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { customerTypes: CustomerType[] }) {}
}

export const GET_CUSTOMER_TYPES_FROM_SERVER_FAILURE = '[CRM] Get Associate Customer Types From Server Failure';
export class GetAssociateCustomerTypesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_CUSTOMER_TYPES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type AssociateCustomerTypesAction =
  | GetAssociateCustomerTypesFromServer
  | GetAssociateCustomerTypesFromServerSuccess
  | GetAssociateCustomerTypesFromServerFailure;
