import * as fromVehicleMakes from './vehicle-makes.actions';
import { initialState, vehicleMakesAdapter, VehicleMakesState } from './vehicle-makes.interfaces';

export function reducer(
  state: VehicleMakesState = initialState,
  action: fromVehicleMakes.VehicleMakesAction
): VehicleMakesState {
  switch (action.type) {
    case fromVehicleMakes.GET_VEHICLE_MAKES_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleMakesAdapter.addMany(action.payload.vehicleMakes, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
