import { HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventTypes } from '@models/server-common';
import { Actions, ofType } from '@ngrx/effects';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { CommunicatorFacade } from '@quorum/communicator-ngrx';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { CrmSettingsState } from '@quorum/crm-settings/state';
import { ConversationQueryParameters, LocationQueryParameters } from '@quorum/models/xs-query';
import {
  Action,
  Address,
  Associate,
  Channel,
  Conversation,
  DirectMessageTemplate,
  Employee,
  Event,
  HtmlMappingTemplate,
  Location,
  Member,
  Prospect,
  ProvinceState,
  ResponseLead,
  SalesTeamMaintenance,
  Source,
  SystemControlValue,
  Template,
  TokenUser,
  XselleratorConversation,
} from '@quorum/models/xs-resource';
import { EmailTemplateDialogComponent, TextMessageDialogComponent } from '@quorum/sha-components-ng-ui';
import {
  SalesPlannerStateService,
  SelectedActivityStateService,
  UiStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CommunicatorDialogEditorComponent } from '@quorum/xsr-template-editor-ng-ui';
import { BrowserControlCommunicationService } from 'libs/sha-services/src/lib/browserControlCommunication.service';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, take, tap } from 'rxjs/internal/operators';
import * as CommunicatorActions from '../../../../communicator-ngrx/src/lib/+state/communicator.actions';
import { LeadMatchingDialogComponent } from '../lead-matching-dialog/lead-matching-dialog.component';
// eslint-disable-next-line max-len
import { ProspectChooseEmailPhoneDialogComponent } from '../prospect-choose-phone-email-dialog/prospect-choose-phone-email-dialog.component';
import { ProspectCloseDialogComponent } from '../prospect-close-dialog/prospect-close-dialog.component';
// eslint-disable-next-line max-len
import { ProspectCreateProgressDialogComponent } from '../prospect-create-progress-dialog/prospect-create-progress-dialog.component';
import { Attachment } from 'libs/xsr-template-editor-ng-ui/src/lib/communicator-dialog-editor/communicator-dialog-editor.component';

@Component({
  selector: 'crm-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('associateEmailAddress') associateEmailAddress: ElementRef;
  @ViewChild('contactCellPhoneNumber') contactCellPhoneNumber: ElementRef;
  @ViewChild('location') location: ElementRef;
  @Input() prospect: Prospect = new Prospect();
  @Input() associate: Associate = new Associate();
  @Input() address: Address = new Address();
  @Input() dropdowns: any;
  @Output() currentUser: TokenUser;
  @Output()
  prospectUpdated: EventEmitter<{
    prospectCopy: Prospect;
    updatedProspect: Prospect;
    updatedAssociate: Associate;
    updatedAddress: Address;
  }> = new EventEmitter<{
    prospectCopy: Prospect;
    updatedProspect: Prospect;
    updatedAssociate: Associate;
    updatedAddress: Address;
  }>();
  associateCopy: Associate = new Associate();
  prospectCopy: Prospect = new Prospect();
  addressCopy: Address = new Address();
  editMode: boolean = false;

  prospectDetailsPanel: boolean;
  prospectDetailsPanelStatus$: Subscription;
  phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  associateForm: any = this.fb.group({
    emailAddress: [{ value: '', disabled: !this.editMode }, [Validators.email]],
    contactPreferenceId: [{ value: null, disabled: !this.editMode }],
    messagePreferenceId: [{ value: null, disabled: !this.editMode }],
  });

  prospectForm: any = this.fb.group({
    emailAddress: [{ value: '', disabled: !this.editMode }, [Validators.email]],
    phoneNumber: [{ value: '', disabled: !this.editMode }],
    primaryEmployeeId: [{ value: '', disabled: !this.editMode }],
    secondaryEmployeeId: [{ value: '', disabled: !this.editMode }],
    rooftopId: [{ value: '', disabled: !this.editMode }],
    channelId: [{ value: '', disabled: !this.editMode }],
    statusId: [{ value: '', disabled: !this.editMode }],
    sourceId: [{ value: '', disabled: !this.editMode }],
  });

  contactForm: any = this.fb.group({
    homePhoneNumber: [{ value: '', disabled: !this.editMode }],
    cellPhoneNumber: [{ value: '', disabled: !this.editMode }],
    addressLine1: [{ value: '', disabled: !this.editMode }, Validators.required],
    locationId: [{ value: '', disabled: !this.editMode }, Validators.required],
    provStateId: [{ value: '', disabled: !this.editMode }, Validators.required],
    countryId: [{ value: '', disabled: !this.editMode }, Validators.required],
    postalZipCode: [{ value: '', disabled: !this.editMode }],
  });

  previousSecondaryEmployeeId: string;

  drilldownEnabled: boolean;
  settings: CrmSettingsState;
  latestResponseLead: ResponseLead;
  responseLeads$: Observable<ResponseLead[]>;
  directMessageTemplates$: Observable<DirectMessageTemplate[]>;
  htmlMappingTemplates$: Observable<HtmlMappingTemplate[]>;
  provinceStates$: Observable<ProvinceState[]>;
  systemControl$: Subscription;
  communicatorEnabled: boolean;
  emailRequired: boolean;
  cellPhoneRequired: boolean;
  teamLeads$: Observable<any>;
  locationDescription: string;
  createProspectProgressRef: MatDialogRef<any>;
  choosePhoneEmailRef: MatDialogRef<any>;
  selectedProspect$: Subscription;
  authenticatedEmployee: Employee;
  postProspectProcessSubscription: Subscription;
  provStates: ProvinceState[];
  locations$: Observable<Location[]>;
  prospectClosedActions$: Observable<Action[]>;
  selectedProspectClosedAction: Action;

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private uiStateService: UiStateService,
    private fb: FormBuilder,
    private browserControlCommunicationService: BrowserControlCommunicationService,
    private settingsStateService: CrmSettingsStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    public dialog: MatDialog,
    private dropdownsStateService: DropdownsStateService,
    private salesPlannerStateService: SalesPlannerStateService,
    private systemControlStateService: SystemControlStateService,
    private crmEntityStateService: CrmEntityStateService,
    private cdr: ChangeDetectorRef,
    public communicatorFacade: CommunicatorFacade,
    private actions$: Actions
  ) {}

  ngOnInit() {
    this.prospectDetailsPanelStatus$ = this.uiStateService
      .selectProspectDetailsVisible()
      .subscribe((res) => (this.prospectDetailsPanel = res));
    this.drilldownEnabled = this.browserControlCommunicationService.drilldownEnabled;
    this.responseLeads$ = this.selectedActivityStateService.selectSelectedProspectResponseLeads();
    this.responseLeads$.pipe(take(1)).subscribe((responseLeads) => {
      this.latestResponseLead = responseLeads[responseLeads.length - 1];
    });
    this.directMessageTemplates$ = this.dropdownsStateService.selectTextMessageTemplates().pipe(
      map((templates) => {
        return templates.filter((template) => ['NEW', 'USE', 'LEA', 'UNA', 'GEN'].includes(template.departmentId));
      })
    );
    this.htmlMappingTemplates$ = this.dropdownsStateService.selectHtmlMappingTemplates();
    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        take(1),
        tap((employee) => (this.authenticatedEmployee = employee))
      )
      .subscribe((employee) => {
        this.systemControl$ = this.systemControlStateService
          .getSystemControlValue('CL_COMMUNICATOR_ENABLE', this.authenticatedEmployee.rooftopId)
          .subscribe((systemControl: SystemControlValue) => {
            this.communicatorEnabled = systemControl.value === '1';
          });
      });

    this.contactForm
      .get('homePhoneNumber')
      .valueChanges.pipe(debounceTime(1000))
      .subscribe((value: any) => {
        if (value) {
          this.prospectForm.patchValue({ ...this.prospectForm, phoneNumber: value });
        }
      });

    this.teamLeads$ = this.dropdownsStateService.selectSalesTeams().pipe(
      filter((salesTeams) => !this.dropdownsStateService.isEmpty(salesTeams)),
      map((salesTeams) => salesTeams.filter((salesTeam: SalesTeamMaintenance) => salesTeam.teamLead === true))
    );

    this.selectedProspect$ = this.selectedActivityStateService
      .selectSelectedProspect()
      .pipe(filter((prospect) => prospect != null))
      .subscribe((prospect) => {
        if (
          prospect.associateId != null &&
          prospect.associateId != this.prospect.associateId &&
          this.createProspectProgressRef != null
        ) {
          this.createProspectProgressRef.close();
        }
      });

    this.postProspectProcessSubscription = this.crmEntityStateService
      .postProspectCreateAndUpdate()
      .subscribe((prospect: Prospect) => {
        if (this.createProspectProgressRef) {
          this.createProspectProgressRef.close();
        }
      });

    this.provinceStates$ = this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(
      tap((provs) => {
        this.provStates = provs;
      })
    );
  }

  toggleProspectDetailsData() {
    this.uiStateService.toggleProspectDetailsPanel(!this.prospectDetailsPanel);
  }

  ngOnDestroy() {
    this.prospectDetailsPanelStatus$.unsubscribe();
    this.systemControl$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.settingsStateService.selectSettingsFromState().subscribe((settings) => (this.settings = settings));

    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        tap((user) => (this.currentUser = user.user))
      )
      .subscribe();

    this.contactForm.get('provStateId').valueChanges.subscribe((provState: any) => {
      this.contactForm.patchValue({
        countryId: provState.countryId,
      });
      const params: LocationQueryParameters = new LocationQueryParameters({
        provStateId: provState,
        pageSize: 2000,
      });
      this.locations$ = this.apiService.get<Location[]>('v/1/associates/locations', { params: params }).pipe(
        tap((locations) => {
          if (this.associateCopy) {
            this.contactForm.patchValue({ locationId: this.associateCopy.embedded.addresses[0].locationId });
          } else {
            this.contactForm.patchValue({ locationId: '' });
            this.contactForm.get('locationId').enable();
          }
        })
      );
    });

    if (changes.prospect && changes.prospect.currentValue) {
      this.prospectCopy = JSON.parse(JSON.stringify(changes.prospect.currentValue));
      if (this.prospectCopy.associateId == null) {
        this.associateCopy = new Associate();
        this.contactForm.disable({ emitEvent: false });
        this.associateForm.disable({ emitEvent: false });
      }
      Object.keys(this.prospectForm.controls).forEach((key) => {
        this.prospectForm.get(key).setValue(this.prospectCopy[key], { emitEvent: false });
      });
      this.previousSecondaryEmployeeId = this.prospectCopy.secondaryEmployeeId;

      if (!this.prospectForm.get('primaryEmployeeId').value) {
        this.enableEditMode(this.settings, this.prospectCopy, this.currentUser);
      }

      this.prospectClosedActions$ = this.dropdownsStateService.selectProspectClosedActions().pipe(
        tap((prospectClosedActions) => {
          this.selectedProspectClosedAction = prospectClosedActions?.find(
            (action) => action.id === this.prospectCopy.actionId
          );
        })
      );
    }
    if (changes.associate && changes.associate.currentValue) {
      this.associateCopy = JSON.parse(JSON.stringify(changes.associate.currentValue));
      Object.keys(this.associateForm.controls).forEach((key) => {
        this.associateForm.get(key).setValue(this.associateCopy[key], { emitEvent: false });
      });
      this.prospectForm.controls['emailAddress'].setValue(this.associateCopy.emailAddress);
    }
    if (changes.address && changes.address.currentValue) {
      this.addressCopy = JSON.parse(JSON.stringify(changes.address.currentValue));
      Object.keys(this.contactForm.controls).forEach((key) => {
        if (key === 'provStateId') {
          this.contactForm.get(key).setValue(this.addressCopy[key]);
        } else {
          this.contactForm.get(key).setValue(this.addressCopy[key], { emitEvent: false });
        }
      });
      this.prospectForm.controls['phoneNumber'].setValue(this.addressCopy.homePhoneNumber);
    }
  }

  updateProspect(
    updatedAssociate: Associate,
    updatedProspect: Prospect,
    updatedAddress: Address,
    currentUser: TokenUser,
    prospectUpdated: EventEmitter<{
      prospectCopy: Prospect;
      updatedProspect: Prospect;
      updatedAssociate: Associate;
      updatedAddress: Address;
    }>,
    prospectClosedActions: Action[]
  ): void {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      Object.keys(this.prospectForm.controls).forEach((key) => {
        this.prospectForm.get(key).disable({ emitEvent: false });
      });
      Object.keys(this.contactForm.controls).forEach((key) => {
        this.contactForm.get(key).disable({ emitEvent: false });
      });
      Object.keys(this.associateForm.controls).forEach((key) => {
        this.associateForm.get(key).disable({ emitEvent: false });
      });
    }

    updatedProspect.updatedDate = new Date();
    Object.keys(this.prospectForm.controls).forEach((key) => {
      if (updatedProspect.hasOwnProperty(key)) {
        updatedProspect[key] = this.prospectForm.controls[key].value;
      }
    });

    if (updatedProspect.associateId != null) {
      Object.keys(this.associateForm.controls).forEach((key) => {
        if (updatedAssociate.hasOwnProperty(key)) {
          updatedAssociate[key] = this.associateForm.controls[key].value;
        }
      });

      Object.keys(this.contactForm.controls).forEach((key) => {
        if (updatedAddress.hasOwnProperty(key)) {
          updatedAddress[key] = this.contactForm.controls[key].value;
        }
      });

      updatedAddress.homePhoneNumber = this.contactForm.controls['homePhoneNumber'].value
        ? this.contactForm.controls['homePhoneNumber'].value.replace(/\D+/g, '')
        : null;
      updatedAddress.cellPhoneNumber = this.contactForm.controls['cellPhoneNumber'].value
        ? this.contactForm.controls['cellPhoneNumber'].value.replace(/\D+/g, '')
        : null;
      updatedProspect.emailAddress = this.associateForm.controls['emailAddress'].value
        ? this.associateForm.controls['emailAddress'].value
        : null;
      updatedProspect.phoneNumber = updatedAddress.homePhoneNumber;
    } else {
      if (updatedProspect.phoneNumber) {
        updatedProspect.phoneNumber = updatedProspect.phoneNumber.replace(/\D+/g, '');
      }
    }

    if (updatedProspect.primaryEmployeeId != this.prospect.primaryEmployeeId) {
      updatedProspect.previousEmployeeId = this.prospect.primaryEmployeeId;
    }

    this.prospectForm.markAsPristine();
    this.associateForm.markAsPristine();
    this.contactForm.markAsPristine();

    prospectUpdated.emit({
      prospectCopy: JSON.parse(JSON.stringify(this.prospect)),
      updatedProspect: JSON.parse(JSON.stringify(updatedProspect)),
      updatedAssociate: JSON.parse(JSON.stringify(updatedAssociate)),
      updatedAddress: JSON.parse(JSON.stringify(updatedAddress)),
    });
  }

  cancelUpdate(prospect: Prospect, associate: Associate, address: Address) {
    if (prospect) {
      this.prospectCopy = JSON.parse(JSON.stringify(prospect));
    }
    if (associate) {
      this.associateCopy = JSON.parse(JSON.stringify(associate));
    }
    if (address) {
      this.addressCopy = JSON.parse(JSON.stringify(address));
    }
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.prospectForm.markAsPristine();
      Object.keys(this.prospectForm.controls).forEach((key) => {
        this.prospectForm.get(key).disable({ emitEvent: false });
        this.prospectForm.get(key).setValue(this.prospectCopy[key], { emitEvent: false });
      });
      this.contactForm.markAsPristine();
      Object.keys(this.contactForm.controls).forEach((key) => {
        this.contactForm.get(key).disable({ emitEvent: false });
        this.contactForm.get(key).setValue(this.addressCopy[key], { emitEvent: false });
      });
      this.associateForm.markAsPristine();
      Object.keys(this.associateForm.controls).forEach((key) => {
        this.associateForm.get(key).disable({ emitEvent: false });
        this.associateForm.get(key).setValue(this.associateCopy[key], { emitEvent: false });
      });
      this.updateMessagePreferenceRequiredFields({ value: null });
    }
  }

  enableEditMode(settings: any, prospect: Prospect, currentUser: TokenUser) {
    this.editMode = !this.editMode;
    if (this.editMode) {
      Object.keys(this.prospectForm.controls).forEach((key) => {
        this.prospectForm.controls[key].markAsTouched();
        if (settings.salesPlannerView == 1) {
          if (key === 'channelId') {
            if (
              this.dropdowns.channels.find(
                (channel: Channel) => channel.id === this.prospectForm.get('channelId').value
              ).description !== 'Lead Generator'
            ) {
              this.prospectForm.get(key).enable({ emitEvent: false });
            }
          } else if (key === 'sourceId') {
            if (
              !['available', 'over mileage', 'sub-prime', 'service appointments'].includes(
                this.dropdowns.sources
                  .find((source: Source) => source.id === this.prospectForm.get('sourceId').value)
                  .description.trim()
                  .toLowerCase()
              )
            ) {
              this.prospectForm.get(key).enable({ emitEvent: false });
            }
          } else {
            this.prospectForm.get(key).enable({ emitEvent: false });
          }
        } else {
          if (prospect.primaryEmployeeId == currentUser.xselleratorEmployeeId) {
            if (prospect.typeId === 4) {
              if (key != 'statusId') {
                this.prospectForm.get(key).enable({ emitEvent: false });
              }
            }
          } else if (prospect.primaryEmployeeId == null) {
            if (key == 'primaryEmployeeId') {
              this.prospectForm.get(key).enable({ emitEvent: false });
            }
          } else {
            if (key == 'secondaryEmployeeId') {
              this.prospectForm.get(key).enable({ emitEvent: false });
            }
          }
        }
        this.prospectForm.get(key).updateValueAndValidity();
      });

      Object.keys(this.contactForm.controls).forEach((key) => {
        this.contactForm.get(key).enable({ emitEvent: false });
        this.contactForm.get(key).updateValueAndValidity({ emitEvent: false });
      });

      Object.keys(this.associateForm.controls).forEach((key) => {
        this.associateForm.get(key).enable({ emitEvent: false });
        this.associateForm.get(key).updateValueAndValidity();
      });
    }
  }

  toggle(employeeId: any) {
    if (this.previousSecondaryEmployeeId == employeeId) {
      this.prospectForm.patchValue({
        secondaryEmployeeId: '',
      });
      this.previousSecondaryEmployeeId = '';
    } else {
      this.previousSecondaryEmployeeId = employeeId;
    }
  }

  openAssociate(associateId: string) {
    const params: string[] = [associateId.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_associate_valid_types_maintain', params);
  }

  createAssociate(prospect: Prospect) {
    const params: string[] = [prospect.freeformName, prospect.phoneNumber, prospect.emailAddress];
    this.browserControlCommunicationService.drilldown('newProspect', 'w_customer_and_vehicle_quick_entry', params);
    this.browserControlCommunicationService.receivePowerBuilderMessage().then((messageObject: any) => {
      if (messageObject.associate.Id !== '' && messageObject.associate.Name !== '') {
        this.selectedActivityStateService
          .selectSelectedProspectOpenSales()
          .pipe(take(1))
          .subscribe((openSales) => {
            openSales.forEach((openSale) => {
              openSale = { ...openSale, customerId: messageObject.associate.Id };
              this.selectedActivityStateService.updateSelectedProspectOpenSaleOnServer(openSale);
            });
          });
        const updatedProspect: Prospect = new DeepCopyPipe().transform(prospect);
        updatedProspect.associateId = messageObject.associate.Id;
        updatedProspect.typeId = 3;
        this.crmEntityStateService.updateProspectOnServer(updatedProspect, false);
      }
    });
  }

  openCustomerSummary(associateId: string) {
    const params: string[] = [associateId.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_associate_customer_profile', params);
  }

  openCustomerVehicles(associateId: string) {
    const params: string[] = [associateId.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_associate_display_name', params);
  }

  openCommunicator(prospect: Prospect) {
    const params: string[] = [prospect.rooftopId.toString(), prospect.associateId.toString(), prospect.id.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'OpenCommunicator', params);
  }

  openVendorMatch(prospect: Prospect, dialog: MatDialog, latestResponseLead: ResponseLead, drilldownEnabled: boolean) {
    const dialogRef = this.dialog.open(LeadMatchingDialogComponent, {
      data: {
        prospect: prospect,
        latestResponseLead: latestResponseLead,
      },
      width: '800px',
      height: '700px',
      panelClass: 'responsive-dialog',
    });

    dialogRef.afterClosed().subscribe((leadMatchingObject) => {
      if (leadMatchingObject) {
        if (leadMatchingObject.prospect && leadMatchingObject.associate && leadMatchingObject.address) {
          let updatedProspect: Prospect = new DeepCopyPipe().transform(leadMatchingObject.prospect);
          let updatedAssociate: Associate = new DeepCopyPipe().transform(leadMatchingObject.associate);
          let updatedAddress: Address = new DeepCopyPipe().transform(leadMatchingObject.address);
          let phoneSet: boolean;
          let emailSet: boolean;

          updatedProspect = { ...updatedProspect, associateId: leadMatchingObject.associate.id };


          if (
            leadMatchingObject.prospect.phoneNumber !== leadMatchingObject.address.homePhoneNumber ||
            leadMatchingObject.prospect.emailAddress !== leadMatchingObject.associate.emailAddress
          ) {
            const prospectPhone = leadMatchingObject.prospect.phoneNumber;
            const homePhone = leadMatchingObject.address.homePhoneNumber;
            const cellPhone = leadMatchingObject.prospect.cellPhoneNumber
              ? leadMatchingObject.prospect.cellPhoneNumber
              : latestResponseLead?.cellPhoneNumber || '';

            if (!!prospectPhone !== !!homePhone) {
              const phone = prospectPhone || homePhone;
              updatedProspect = {
                ...updatedProspect,
                phoneNumber: phone,
              };
              updatedAddress = {
                ...updatedAddress,
                homePhoneNumber: phone,
                cellPhoneNumber: cellPhone,
              };
              phoneSet = true;
            } else if ((!prospectPhone && !homePhone) || prospectPhone === homePhone) {
              updatedAddress = {
                ...updatedAddress,
                cellPhoneNumber: cellPhone,
              };
              phoneSet = true;
            }

            if (!leadMatchingObject.prospect.emailAddress && leadMatchingObject.associate.emailAddress) {
              updatedProspect = { ...updatedProspect, emailAddress: leadMatchingObject.associate.emailAddress };
              updatedAssociate = { ...updatedAssociate, emailAddress: leadMatchingObject.associate.emailAddress };
              emailSet = true;
            } else if (leadMatchingObject.prospect.emailAddress && !leadMatchingObject.associate.emailAddress) {
              updatedProspect = { ...updatedProspect, emailAddress: leadMatchingObject.prospect.emailAddress };
              updatedAssociate = { ...updatedAssociate, emailAddress: leadMatchingObject.prospect.emailAddress };
              emailSet = true;
            } else if (
              (!leadMatchingObject.prospect.emailAddress && !leadMatchingObject.associate.emailAddress) ||
              leadMatchingObject.prospect.emailAddress === leadMatchingObject.associate.emailAddress
            ) {
              emailSet = true;
            }
            if (phoneSet && emailSet) {
              this.createProspectProgressRef = dialog.open(ProspectCreateProgressDialogComponent, {
                data: { message: 'Creating associate and updating prospect records...' },
              });

              this.selectedActivityStateService
                .selectSelectedProspectOpenSales()
                .pipe(take(1))
                .subscribe((openSales) => {
                  openSales.forEach((openSale) => {
                    openSale = { ...openSale, customerId: leadMatchingObject.prospect.associateId };
                    this.selectedActivityStateService.updateSelectedProspectOpenSaleOnServer(openSale);
                  });
                });

              this.crmEntityStateService.updateProspectAndAssociateOnServer(
                updatedAssociate,
                updatedProspect,
                updatedAddress,
                leadMatchingObject.salespersonTakeoverData
              );
            } else {
              this.choosePhoneEmailRef = dialog.open(ProspectChooseEmailPhoneDialogComponent, {
                data: {
                  prospect: leadMatchingObject.prospect,
                  associate: leadMatchingObject.associate,
                  address: leadMatchingObject.address,
                  responseLead: latestResponseLead,
                },
                width: '600px',
                height: '600px',
                panelClass: 'responsive-dialog',
              });
              this.choosePhoneEmailRef.afterClosed().subscribe((chooseEmailPhoneObject) => {
                if (chooseEmailPhoneObject) {
                  this.createProspectProgressRef = dialog.open(ProspectCreateProgressDialogComponent, {
                    data: { message: 'Updating prospect and associate records...' },
                  });
                  updatedProspect = {
                    ...updatedProspect,
                    phoneNumber: chooseEmailPhoneObject.mainPhoneNumber,
                    emailAddress: chooseEmailPhoneObject.emailAddress,
                  };

                  updatedAssociate = { ...updatedAssociate, emailAddress: chooseEmailPhoneObject.emailAddress };
                  updatedAddress = {
                    ...updatedAddress,
                    homePhoneNumber: chooseEmailPhoneObject.mainPhoneNumber,
                    cellPhoneNumber: chooseEmailPhoneObject.cellPhoneNumber,
                  };

                  this.selectedActivityStateService
                    .selectSelectedProspectOpenSales()
                    .pipe(take(1))
                    .subscribe((openSales) => {
                      openSales.forEach((openSale) => {
                        openSale = { ...openSale, customerId: leadMatchingObject.prospect.associateId };
                        this.selectedActivityStateService.updateSelectedProspectOpenSaleOnServer(openSale);
                      });
                    });
                  this.crmEntityStateService.updateProspectAndAssociateOnServer(
                    updatedAssociate,
                    updatedProspect,
                    updatedAddress,
                    leadMatchingObject.salespersonTakeoverData
                  );
                }
              });
            }
          } else {
            this.createProspectProgressRef = dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Updating prospect and associate records...' },
            });

            this.selectedActivityStateService
              .selectSelectedProspectOpenSales()
              .pipe(take(1))
              .subscribe((openSales) => {
                openSales.forEach((openSale) => {
                  openSale = { ...openSale, customerId: leadMatchingObject.prospect.associateId };
                  this.selectedActivityStateService.updateSelectedProspectOpenSaleOnServer(openSale);
                });
              });

            this.crmEntityStateService.updateProspectOnServer(
              updatedProspect,
              false,
              leadMatchingObject.salespersonTakeoverData
            );
          }
        } else {

          this.createProspectProgressRef = dialog.open(ProspectCreateProgressDialogComponent, {
            data: { message: 'Creating associate and updating prospect records...' },
          });
          this.crmEntityStateService.updateProspectAndCreateAssociateAndAddressOnServer(
            leadMatchingObject.newAddress,
            leadMatchingObject.newAssociate,
            leadMatchingObject.prospect
          );
        }
      }
    });
  }

  openLeads(prospectId: number) {
    const params: string[] = [prospectId.toString()];
    this.browserControlCommunicationService.drilldown('existingProspect', 'OpenLeads', params);
  }

  updateMessagePreferenceRequiredFields(event: any) {
    this.contactForm.get('cellPhoneNumber').clearValidators();
    this.associateForm.get('emailAddress').clearValidators();
    switch (event.value) {
      case 1:
        this.associateEmailAddress.nativeElement.focus();
        this.associateForm.get('emailAddress').setValidators([Validators.required, Validators.email]);
        break;
      case 2:
        this.contactCellPhoneNumber.nativeElement.focus();
        this.contactForm.get('cellPhoneNumber').setValidators([Validators.required]);
        break;
      default:
        break;
    }
    this.associateForm.get('emailAddress').updateValueAndValidity();
    this.contactForm.get('cellPhoneNumber').updateValueAndValidity();
  }

  openCommunicatorTextDialog(
    directMessageTemplates: DirectMessageTemplate[],
    prospect: Prospect,
    currentUser: TokenUser
  ) {
    const conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
      transactionTypeId: 5,
      messageTypeId: 18,
      transactionId: prospect.id,
    });

    let event: Event = new Event({
      conversationId: null,
      memberId: currentUser.id.toString(),
      eventTypeId: EventTypes.Message,
      content: 'Generating message...',
      guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      sentDate: new Date(),
      sent: false,
      sendToCustomer: true,
      id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
    });

    const conversation: Conversation = new Conversation({
      dmsMessageTypeId: 18,
      dmsTransactionTypeId: 5,
      dmsTransactionId: prospect.id,
      createdBy: currentUser.id,
    });

    const members: Member[] = [];
    members.push(
      new Member({
        userId: currentUser.id.toString(),
        avatarUrl: currentUser.avatarUrl,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        nickname: currentUser.firstName,
        isEmployee: true,
        isRead: true,
        isArchived: false,
        isExcluded: false,
        isPinnedToUi: false,
      })
    );

    members.push(
      new Member({
        userId: prospect.embedded.associate.id,
        firstName: prospect.embedded.associate.firstName,
        lastName: prospect.embedded.associate.lastName,
        nickname: prospect.embedded.associate.firstName,
        isEmployee: false,
        isRead: true,
        isArchived: false,
        isExcluded: false,
        isPinnedToUi: false,
      })
    );

    const toastOptions: any = {
      message: `Communicator message sent.`,
      actionText: null,
      options: { duration: 3000 },
    };

    this.actions$.pipe(ofType(CommunicatorActions.sendMessageToConversationSuccess), take(1)).subscribe((success) => {
      this.selectedActivityStateService.createSentEmailTextProspectAction(prospect, this.dropdowns, this.currentUser);
    });

    this.apiService.get(`/quorum/stores/${currentUser.storeId}`).subscribe((response) => {
      // QTemplates enabled
      if (response.useCommunicatorCloudServices) {
        this.apiService
          .get(`v/1/quorum/templates/store-gallery/${currentUser.destination}`, {
            params: { messageTypeId: 18 },
          })
          .subscribe((communicatorTemplates) => {
            const dialogRef = this.dialog.open(CommunicatorDialogEditorComponent, {
              data: {
                currentUser: currentUser,
                messageType: 'text',
                templates: communicatorTemplates,
              },
              height: '100%',
              width: '100%',
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult && dialogResult.template) {
                this.apiService
                  .post(`v/1/quorum/templates/item-specific/${currentUser.destination}`, dialogResult.template)
                  .subscribe((itemSpecificTemplate: Template) => {
                    event = { ...event, content: 'Generating message...' };
                    const optionalHeader = new HttpHeaders({ 'template-id': itemSpecificTemplate.id });
                    this.communicatorFacade.sendCommunicatorMessage(
                      conversationQueryParameters,
                      conversation,
                      members,
                      event,
                      optionalHeader
                    );

                    this.salesPlannerStateService.addToast(toastOptions);
                  });
              } else if (dialogResult.messageContent) {
                event = { ...event, content: dialogResult.messageContent };
                const optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'false' });
                this.communicatorFacade.sendCommunicatorMessage(
                  conversationQueryParameters,
                  conversation,
                  members,
                  event,
                  optionalHeader
                );

                this.salesPlannerStateService.addToast(toastOptions);
              }
            });
          });
      } else {
        const dialogRef = this.dialog.open(TextMessageDialogComponent, {
          width: '500px',
          height: '350px',
          data: {
            directMessageTemplates: directMessageTemplates,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            event = { ...event, content: result.textMessageBody };
            const optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'false' });

            this.communicatorFacade.sendCommunicatorMessage(
              conversationQueryParameters,
              conversation,
              members,
              event,
              optionalHeader
            );

            this.salesPlannerStateService.addToast(toastOptions);
          }
        });
      }
    });
  }

  openCommunicatorEmailDialog(
    directMessageTemplates: DirectMessageTemplate[],
    htmlMappingTemplates: HtmlMappingTemplate[],
    associate: Associate,
    prospect: Prospect,
    currentUser: TokenUser
  ) {
    this.apiService.get(`/quorum/stores/${this.currentUser.storeId}`).subscribe((response) => {
      if (response.useCommunicatorCloudServices) {
        this.apiService
          .get(`v/1/quorum/templates/store-gallery/${this.currentUser.destination}`, {
            params: { messageTypeId: 18 },
          })
          .subscribe((templates) => {
            const dialogRef = this.dialog.open(CommunicatorDialogEditorComponent, {
              data: { currentUser: this.currentUser, messageType: 'email', templates: templates },
              height: '100%',
              width: '100%',
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
                this.apiService
                  .post(`v/1/quorum/templates/item-specific/${currentUser.destination}`, dialogResult.template)
                  .subscribe((itemSpecificTemplate: Template) => {
                    const conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                      transactionTypeId: 5,
                      messageTypeId: 18,
                      transactionId: prospect.id,
                    });

                    const event: Event = new Event({
                      attachments: dialogResult.attachments
                        ? dialogResult.attachments.map((a: Attachment) => a.url).join(',')
                        : null,
                      conversationId: null,
                      memberId: currentUser.id.toString(),
                      eventTypeId: EventTypes.Message,
                      content: 'Generating email...',
                      guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                      sentDate: new Date(),
                      sent: false,
                      sendToCustomer: true,
                      id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
                    });

                    const conversation: Conversation = new Conversation({
                      dmsMessageTypeId: 18,
                      dmsTransactionTypeId: 5,
                      dmsTransactionId: prospect.id,
                      createdBy: currentUser.id,
                    });

                    const members: Member[] = [];
                    members.push(
                      new Member({
                        userId: currentUser.id.toString(),
                        avatarUrl: currentUser.avatarUrl,
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        nickname: currentUser.firstName,
                        isEmployee: true,
                        isRead: true,
                        isArchived: false,
                        isExcluded: false,
                        isPinnedToUi: false,
                      })
                    );

                    members.push(
                      new Member({
                        userId: prospect.embedded.associate.id,
                        firstName: prospect.embedded.associate.firstName,
                        lastName: prospect.embedded.associate.lastName,
                        nickname: prospect.embedded.associate.firstName,
                        isEmployee: false,
                        isRead: true,
                        isArchived: false,
                        isExcluded: false,
                        isPinnedToUi: false,
                      })
                    );

                    const optionalHeader = new HttpHeaders({ 'template-id': itemSpecificTemplate.id });

                    this.communicatorFacade.sendCommunicatorMessage(
                      conversationQueryParameters,
                      conversation,
                      members,
                      event,
                      optionalHeader
                    );

                    const toastOptions: any = {
                      message: `Communicator email sent.`,
                      actionText: null,
                      options: { duration: 3000 },
                    };

                    this.salesPlannerStateService.addToast(toastOptions);
                  });
              }
            });
          });
        this.actions$
          .pipe(ofType(CommunicatorActions.sendMessageToConversationSuccess), take(1))
          .subscribe((success) => {
            this.selectedActivityStateService.createSentEmailTextProspectAction(
              prospect,
              this.dropdowns,
              this.currentUser
            );
          });
      } else {
        const dialogRef = this.dialog.open(EmailTemplateDialogComponent, {
          data: {
            directMessageTemplates: directMessageTemplates,
            htmlMappingTemplates: htmlMappingTemplates,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const xselleratorConversation: XselleratorConversation = new XselleratorConversation();
            xselleratorConversation.senderId = currentUser.xselleratorEmployeeId;
            xselleratorConversation.customerId = associate.id;
            xselleratorConversation.emailTemplateLanguageId = result.htmlLanguageTemplate;
            xselleratorConversation.message = result.messageBody;
            xselleratorConversation.automatedMessageTypeId = 18;
            xselleratorConversation.oemRooftopId = prospect.rooftopId;
            xselleratorConversation.subject = result.subject;
            xselleratorConversation.emailTemplateId = result.htmlMappingTemplate;
            xselleratorConversation.transactionId = prospect.id.toString();
            xselleratorConversation.transactionTypeId = 5;

            return this.apiService
              .post<any>(`v/1/communicator/conversations/xsellerator/transactions`, xselleratorConversation)
              .subscribe((result) => {
                const toastOptions: any = {
                  message: `Communicator message sent.`,
                  actionText: null,
                  options: { duration: 3000 },
                };

                this.salesPlannerStateService.addToast(toastOptions);
              });
          }
        });
      }
    });
  }

  reopenCloseProspect(
    prospect: Prospect,
    address: Address,
    associate: Associate,
    currentUser: TokenUser,
    prospectClosedActions: Action[],
    prospectUpdated: EventEmitter<{
      prospectCopy: Prospect;
      updatedProspect: Prospect;
      updatedAssociate: Associate;
      updatedAddress: Address;
    }>
  ) {
    if (prospect.statusId == '1') {
      const dialogRef = this.dialog.open(ProspectCloseDialogComponent, {
        data: {
          prospectClosedActions: prospectClosedActions.filter((prospectAction) => prospectAction.activeFlag === '1'),
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          prospect.statusId = '2';
          prospect.closedDate = new Date();
          prospect.closedById = currentUser.xselleratorEmployeeId;
          prospect.actionId = result.prospectClosedAction;
          prospectUpdated.emit({
            prospectCopy: JSON.parse(JSON.stringify(this.prospect)),
            updatedProspect: JSON.parse(JSON.stringify(prospect)),
            updatedAssociate: JSON.parse(JSON.stringify(associate)),
            updatedAddress: JSON.parse(JSON.stringify(address)),
          });
        }
      });
    } else {
      prospect.statusId = '1';
      prospectUpdated.emit({
        prospectCopy: JSON.parse(JSON.stringify(this.prospect)),
        updatedProspect: JSON.parse(JSON.stringify(prospect)),
        updatedAssociate: JSON.parse(JSON.stringify(associate)),
        updatedAddress: JSON.parse(JSON.stringify(address)),
      });
    }
  }

  setPostalCodeAndCountryCode(postalCode: string, countryId: string) {
    this.contactForm.patchValue({
      postalZipCode: postalCode,
      countryId: countryId,
    });
  }
}
