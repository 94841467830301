<h1>Communicator</h1>
<div mat-dialog-content>
  <form [formGroup]="textMessageForm">
    <div fxLayout="column">
      <span class="mat-body-1" style="color:rgb(25,118,210);width:100%;">Send a message:</span>
      <div fxLayout="column" style="width:100%;">
        <mat-form-field>
          <mat-select placeholder="Message Template" name="messageTemplate" formControlName="textMessageTemplates">
            <mat-option *ngFor="let messageTemplate of data.directMessageTemplates" [value]="messageTemplate.id"
              (click)="setMessageBody(messageTemplate)">{{messageTemplate.templateName}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <textarea placeholder="Message Body" name="messageBody" autocomplete="false" rows="7" matInput
            formControlName="textMessageBody" required></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="textMessageForm.value" cdkFocusInitial color="primary" [disabled]="!textMessageForm.valid">Send</button>
</div>