import { VehicleCategory } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface VehicleCategoriesState extends EntityState<VehicleCategory> {
  loading: boolean;
}

export const vehicleCategoriesAdapter: EntityAdapter<VehicleCategory> = createEntityAdapter<VehicleCategory>({
  selectId: (vehicleCategories: VehicleCategory) => vehicleCategories.id
});

export const initialState: VehicleCategoriesState = vehicleCategoriesAdapter.getInitialState({ loading: true });
export const vehicleCategoriesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleCategories
);

export const vehicleCategoriesLoadingSelector = createSelector(
  vehicleCategoriesSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleCategoriesAdapter.getSelectors(
  vehicleCategoriesSelector
);
