import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Task } from '@quorum/models/xs-resource';

export interface TasksState extends EntityState<Task> {
  loading: boolean;
}

export const tasksAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: (task: Task) => task.id,
});

export const initialState: TasksState = tasksAdapter.getInitialState({ loading: null });
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const tasksSelector = createSelector(salesplannerLegacyFeatureSelector, (state: any) => state.activities.tasks);

export const isTasksLoadingSelector = createSelector(tasksSelector, (state: any) => state.loading);
export const { selectIds, selectEntities, selectAll, selectTotal } = tasksAdapter.getSelectors(tasksSelector);
