import * as fromEmployees from './employees.actions';
import { employeesAdapter, EmployeesState, initialState } from './employees.interfaces';

export function reducer(state: EmployeesState = initialState, action: fromEmployees.EmployeesAction): EmployeesState {
  switch (action.type) {
    case fromEmployees.GET_EMPLOYEES_FROM_SERVER_SUCCESS: {
      return {
        ...employeesAdapter.addMany(action.payload.employees, state)
      };
    }

    default: {
      return state;
    }
  }
}
