import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationExtras } from '@angular/router';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { SettingsDialogComponent } from '@quorum/crm-sales-planner-ng-ui';
import { ProspectQueryParameters, ProspectReportQueryParameters } from '@quorum/models/xs-query';
import { AuthenticatedUser } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { ActivityQueryParameters } from '@quorum/xsr-salesplanner-legacy';
import {
  ActivitiesStateService,
  LocalSettingsState,
  LocalSettingsStateService,
  UiStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { BrowserControlCommunicationService } from 'libs/sha-services/src/lib/browserControlCommunication.service';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit {
  sidenavStatus$: Subscription;
  sidenav: boolean;
  authenticatedUser$: Observable<AuthenticatedUser>;
  localSettings$: Observable<LocalSettingsState>;
  salespeople$: Observable<any>;
  drilldownEnabled: boolean;
  primaryRouteUrl: string;
  leadGeneratorSct$: Observable<any>;

  defaultProspectLeadQueryParams: ProspectReportQueryParameters = {
    ...new ProspectReportQueryParameters(),
    rooftopId: null,
    salespersonId: null,
    prospectStatusId: ['1'],
    prospectTypeId: null,
    prospectChannelId: null,
    prospectSourceId: null,
    actionId: null,
    customerTypeId: null,
    vendorId: null,
    assigned: null,
    matched: null,
    categoryId: null,
    prospectEnteredDateFrom: null,
    prospectEnteredDateTo: null,
    sort: '-prospectEnteredDate',
    pageSize: 25,
    page: 0,
  };

  prospectLeads$: Observable<any>;

  @ViewChild('snav') public sidenavComponent: MatSidenav;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(
    public dialog: MatDialog,
    private activitiesStateService: ActivitiesStateService,
    private crmEntityStateService: CrmEntityStateService,
    private uiStateService: UiStateService,
    private authenticationStateService: AuthenticationStateService,
    private localSettingsStateService: LocalSettingsStateService,
    private dropdownStateService: DropdownsStateService,
    private browserControlCommunicationService: BrowserControlCommunicationService,
    private routerStateService: RouterStateService,
    private systemControlStateService: SystemControlStateService
  ) {}

  ngOnInit() {
    this.localSettings$ = this.localSettingsStateService.selectLocalSettingsFromState();

    this.salespeople$ = this.dropdownStateService
      .selectSalespeople()
      .pipe(filter((salespeople) => !this.dropdownStateService.isEmpty(salespeople)));

    this.sidenavStatus$ = this.uiStateService.selectSidenavVisible().subscribe((res) => {
      this.sidenav = res;
    });

    this.prospectLeads$ = this.uiStateService.selectProspectLeads();

    this.authenticatedUser$ = this.authenticationStateService.selectAuthenticatedUser();
    this.drilldownEnabled = this.browserControlCommunicationService.drilldownEnabled;
    this.routerStateService.selectRouterState().subscribe((routerState) => {
      if (routerState.state.root.firstChild.firstChild.url[0]) {
        this.primaryRouteUrl = routerState.state.root.firstChild.firstChild.url[0].path;
      }
    });

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        take(1),
        tap((employee) => {
          this.defaultProspectLeadQueryParams.rooftopId = employee.rooftopId.toString();
        })
      )
      .subscribe((employee) => {
        this.leadGeneratorSct$ = this.systemControlStateService
          .getSystemControlValue('CL_LEAD_GENERATOR_ENABLED', employee.rooftopId)
          .pipe(
            map((sct) => {
              return sct.value;
            })
          );
      });
  }

  closeSidenav() {
    this.uiStateService.toggleSidenav(false);
  }

  logout() {
    this.authenticationStateService.logout();
  }

  salesMetrics() {
    let params: string[] = ['N/A'];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_sales_metrics', params);
  }

  showroomLog() {
    let params: string[] = ['N/A'];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_showroom_log', params);
  }

  openSettingsDialog(salespeople$: Observable<any>, localSettings: LocalSettingsState): void {
    this.closeSidenav();
    const dialogRef = this.dialog.open(SettingsDialogComponent, {
      data: {
        dropdowns: { salespeople$: salespeople$ },
        localSettings: new DeepCopyPipe().transform(localSettings),
      },
      panelClass: 'responsive-dialog',
    });

    dialogRef.afterClosed().subscribe((result: LocalSettingsState) => {
      if (result) {
        this.localSettingsStateService.updateLocalSettingsInState(result);
        if (
          result.settingsChanged.includes('range') ||
          result.settingsChanged.includes('salespeople') ||
          result.settingsChanged.includes('activityListDisplayEmployee')
        ) {
          let activityQueryParameters: ActivityQueryParameters = {
            activityTypes: result.activitiesFilter,
            startDate: result.startDate,
            employeeId: result.salesPeople,
          };
          console.log(result);
          let prospectQueryParameters: ProspectQueryParameters = {
            employeeId: result.salesPeople,
            statusId: result.prospectFilter.status,
            typeId: result.prospectFilter.typeId,
            page: 0,
            pageSize: 50,
            sort: localSettings.prospectFilter.sort,
            embed: ['associate', 'primaryEmployeeAssociate', 'vehicle', 'address'],
          };

          this.activitiesStateService.getActivitiesFromServer(activityQueryParameters, result);
          this.crmEntityStateService.clearProspectState();
          this.crmEntityStateService.getProspectsFromServer(prospectQueryParameters);
        }
      }
    });
  }

  routeToProspects(localSettings: LocalSettingsState, field?: string) {
    let prospectLeadQueryParams: ProspectReportQueryParameters;
    if (field === 'unassigned') {
      prospectLeadQueryParams = { ...this.defaultProspectLeadQueryParams, salespersonId: ['0'] };
    } else if (field === 'unmatched') {
      prospectLeadQueryParams = {
        ...this.defaultProspectLeadQueryParams,
        matched: 'false',
        salespersonId: localSettings.salesPeople,
      };
    } else {
      prospectLeadQueryParams = { ...this.defaultProspectLeadQueryParams, salespersonId: localSettings.salesPeople };
    }

    let navExtras: NavigationExtras = {
      queryParams: prospectLeadQueryParams,
    };

    this.routerStateService.go(['/', 'crm', 'prospects'], navExtras);
  }
}
