<div fxLayout="column" class="qcontainer">
  <form fxLayout="column" [formGroup]="prospectFilterForm">
    <div *ngIf="queryParams != null" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field fxFlex>
        <input matInput formControlName="search" placeholder="Search...">
      </mat-form-field>
      <button mat-icon-button class="filter-button" (click)="displayFilters = !displayFilters">
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
    <div fxLayout="column" *ngIf="displayFilters">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <mat-form-field fxFlex="45">
          <mat-select placeholder="Status" formControlName="statusId" name="Status" multiple>
            <mat-option (click)="toggleAllSelection(statusSelectAll=!statusSelectAll, 'statusId', statuses)">
              All
            </mat-option>
            <mat-select-trigger>
              {{prospectFilterForm.get('statusId').value.length === 2? 'All' :
              prospectFilterForm.get('statusId').value[0] === 1 ? 'Open': 'Closed'}}
            </mat-select-trigger>
            <mat-option *ngFor="let status of statuses" [value]="status.id">
              {{status.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="45">
          <mat-select placeholder="Type" formControlName="typeId" name="Type" multiple>
            <mat-select-trigger>
              {{prospectFilterForm.get('typeId').value.length === 3 ? 'All' :
              (prospectFilterForm.get('typeId').value.length < 3 && prospectFilterForm.get('typeId').value.length> 1)
                ? 'Multiple Selected': prospectFilterForm.get('typeId').value[0] === 3 ? 'Showroom' :
                prospectFilterForm.get('typeId').value[0] === 4 ?
                'Retention'
                : 'Lead'}}
            </mat-select-trigger>
            <mat-option (click)="toggleAllSelection(typeSelectAll=!typeSelectAll, 'typeId', types)">
              All
            </mat-option>
            <mat-option *ngFor="let type of types" [value]="type.id">
              {{type.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="45">
          <mat-select placeholder="Channel" formControlName="channelId" name="Channel" multiple
            *ngIf="channels$ | async as channels">
            <mat-option (click)="toggleAllSelection(channelSelectAll=!channelSelectAll, 'channelId', channels)">
              All
            </mat-option>
            <mat-option *ngFor="let channel of channels" [value]="channel.id">
              {{channel.description}}
            </mat-option>
            <mat-select-trigger>
              {{ prospectFilterForm.get('channelId').value | mapSelections: channels }}
            </mat-select-trigger>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="45">
          <mat-select placeholder="Source" formControlName="sourceId" name="Source" multiple
            *ngIf="sources$ | async as sources">
            <mat-option (click)="toggleAllSelection(sourceSelectAll=!sourceSelectAll, 'sourceId', sources)">
              All
            </mat-option>
            <mat-select-trigger>
              {{ prospectFilterForm.get('sourceId').value | mapSelections: sources }}
            </mat-select-trigger>
            <mat-option *ngFor="let source of sources" [value]="source.id">
              {{source.description}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div fxFlex fxLayoutAlign="center center">
          <mat-form-field fxFlex="45">
            <mat-select placeholder="Sort" formControlName="sort" name="sort">
              <mat-option value="-enteredDate">
                Entered Date
              </mat-option>
              <mat-option value="-updatedDate">
                Updated Date
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" class="button-container">
        <button mat-raised-button color="primary" (click)="apply(prospectFilterForm.value)">Apply</button>
      </div>
    </div>
  </form>
</div>