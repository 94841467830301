import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrmAppointment as Appointment } from '@quorum/models/xs-resource';

export interface ProspectSalesAppointmentsState extends EntityState<Appointment> {}

export const prospectSalesAppointmentsAdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: (salesAppointment: Appointment) => salesAppointment.id,
});

export const initialState: ProspectSalesAppointmentsState = prospectSalesAppointmentsAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const prospectSalesAppointmentsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectSalesAppointments
);

export const { selectIds, selectEntities, selectAll, selectTotal } = prospectSalesAppointmentsAdapter.getSelectors(
  prospectSalesAppointmentsSelector
);
