<div *ngIf="prospectVehicleOfInterest" class="w-full pt-3">
    <div class='flex flex-row flex-wrap px-4 justify-between'>
        <div *ngFor="let vehicle of prospectVehicleOfInterest"
            class='mb-3 border-grey-300 border-solid border rounded-xl relative' style="width: 49%;">
            <div class="absolute h-full top-0 right-0 py-3 px-3 flex flex-col justify-between items-center">
                <button type="button" (click)="showDeleteModal=!showDeleteModal;deleteVehicle=vehicle"
                    class="bg-white rounded-md text-red-400 focus:outline-none focus:ring-2 focus:ring-offset-quorum-grey-comp">
                    <span class="sr-only">Close</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <button mat-icon-button color="primary"
                    (click)="openVehicleOfInterestDialog(dialog, prospectId, vehicle)">
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
            <div class="py-4 px-5 flex flex-col border-solid border-grey-300" style="width: 90%;">
                <span class="flex flex-row items-center py-1">
                    <span class='font-medium text-md  mr-2'>Vehicle: </span>
                    <span>{{vehicle.year}} {{vehicle.make}} {{vehicle.model}} {{vehicle.trim}}</span>
                </span>
                <div *ngIf="vehicle.colour" class='flex items-center py-1'>
                    <span class='font-medium text-md mr-2'>Colour:</span>
                    <span>{{vehicle.colour}}</span>
                </div>
                <div *ngIf="vehicle.maxMileage" class='flex items-center py-1'>
                    <span class='font-medium text-md mr-2'>Maximum Mileage:</span>
                    <span>{{vehicle.maxMileage | number | unitOfMeasure | async}}</span>
                </div>
                <div *ngIf="vehicle.maxPrice" class='flex items-center py-1'>
                    <span class='font-medium text-md mr-2'>Maximum Price:</span>
                    <span>${{vehicle.maxPrice | number}}</span>
                </div>
                <div *ngIf="vehicle.vin" class='flex items-center py-1'>
                    <span class='font-medium text-md mr-2'>VIN:</span>
                    <span>{{vehicle.vin}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-4 flex justify-end w-full">
        <button mat-raised-button color="primary"
            (click)="openVehicleOfInterestDialog(dialog, prospectId, null)">Add</button>
    </div>
    <div *ngIf="showDeleteModal" class="fixed z-10 inset-0 overflow-y-auto bg-opacity-50 bg-black"
        aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
            <div
                class="inline-block bg-white rounded-lg px-5 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-80 p-6">
                <div class="absolute top-0 right-0 pt-4 pr-4">
                    <button type="button" (click)="showDeleteModal=!showDeleteModal"
                        class="bg-white rounded-md text-grey-400 hover:text-grey-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span class="sr-only">Close</span>
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="lightGrey" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div class="w-full flex-col">
                    <div class="w-full flex justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14 text-red-600 text-center" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <h2 class="text-grey-700 mt-3 text-center">
                        Confirm delete?
                    </h2>
                    <p class="text-grey-500 text-center">
                        Are you sure you want to delete this vehicle of interest?
                    </p>
                </div>

                <div class="flex flex-row-reverse justify-center">
                    <div>
                        <button type="button" (click)="showDeleteModal=!showDeleteModal"
                            class="mt-3 inline-flex justify-center rounded-lg border bg-grey-300 shadow-sm px-4 py-2 text-sm font-medium text-white hover:bg-grey-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Cancel
                        </button>
                        <button type="button"
                            (click)="showDeleteModal=!showDeleteModal;deleteVehicleOfInterest(deleteVehicle)"
                            class="ml-6 inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-red-600 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>