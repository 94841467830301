import { DirectMessageTemplate } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface DirectMessageTemplatesState extends EntityState<DirectMessageTemplate> {}

export const directMessageTemplatesAdapter: EntityAdapter<DirectMessageTemplate> =
  createEntityAdapter<DirectMessageTemplate>({
    selectId: (directMessageTemplate: DirectMessageTemplate) => directMessageTemplate.id,
  });

export const initialState: DirectMessageTemplatesState = directMessageTemplatesAdapter.getInitialState();
export const directMessageTemplatesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.directMessageTemplates
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  directMessageTemplatesAdapter.getSelectors(directMessageTemplatesSelector);
