export class SaleQueryParameters {
  contractDateFrom?: string;
  contractDateTo?: string;
  customerId?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  deliveryFlag?: string;
  embed?: any;
  id?: string;
  page?: number;
  pageSize?: number;
  postedDateFrom?: string;
  postedDateTo?: string;
  retentionProspectId?: string;
  rooftopId?: string;
  salespersonId?: string[];
  showroomProspectId?: string;
  statusId?: string;
  vehicleId?: string;

  constructor(obj?: SaleQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
