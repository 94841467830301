import { Sale } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface DeliveriesState extends EntityState<Sale> {
  loading: boolean;
}

export const deliveriesAdapter: EntityAdapter<Sale> = createEntityAdapter<Sale>({
  selectId: (delivery: Sale) => delivery.id
});

export const initialState: DeliveriesState = deliveriesAdapter.getInitialState({ loading: null });
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const deliveriesSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.activities.deliveries
);

export const isDeliveriesLoadingSelector = createSelector(
  deliveriesSelector,
  (state: any) => state.loading
);
export const { selectIds, selectEntities, selectAll, selectTotal } = deliveriesAdapter.getSelectors(deliveriesSelector);
