import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { SalesTeamMaintenance } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface SalesTeamsState extends EntityState<any> {
  loading: boolean;
}

export const salesTeamsAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (salesTeam: SalesTeamMaintenance) => salesTeam.id,
});

export const initialState: SalesTeamsState = salesTeamsAdapter.getInitialState({ loading: true });
export const salesTeamsSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.salesTeams);

export const salesTeamsLoadingSelector = createSelector(salesTeamsSelector, (state: any) => state.loading);

export const { selectIds, selectEntities, selectAll, selectTotal } = salesTeamsAdapter.getSelectors(salesTeamsSelector);
