import { VehicleOfInterest } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ProspectVehicleOfInterestState extends EntityState<VehicleOfInterest> {}

export const prospectVehicleOfInterestAdapter: EntityAdapter<VehicleOfInterest> =
  createEntityAdapter<VehicleOfInterest>({
    selectId: (prospectVehicleOfInterest: VehicleOfInterest) => prospectVehicleOfInterest.id,
  });

export const initialState: ProspectVehicleOfInterestState = prospectVehicleOfInterestAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const selectedProspectVehicleOfInterestSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectVehicleOfInterest
);

export const { selectIds, selectEntities, selectAll, selectTotal } = prospectVehicleOfInterestAdapter.getSelectors(
  selectedProspectVehicleOfInterestSelector
);
