import { Action } from '@ngrx/store';
import { CrmAppointmentQueryParameters as AppointmentQueryParameters  } from '@quorum/models/xs-query';
import { CrmAppointment as Appointment } from '@quorum/models/xs-resource';

export const GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER = '[CRM] Get Prospect Sales Appointments From Server';
export class GetProspectSalesAppointmentsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: AppointmentQueryParameters) {}
}

export const GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER_SUCCESS =
  '[CRM] Get Prospect Sales Appointments From Server Success';
export class GetProspectSalesAppointmentsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { salesAppointments: Appointment[] }) {}
}

export const GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER_FAILURE =
  '[CRM] Get Prospect Sales Appointments From Server Failure';
export class GetProspectSalesAppointmentsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const ADD_PROSPECT_SALES_APPOINTMENT_TO_STATE = '[CRM] Add Prospect Sales Appointment to State';
export class AddProspectSalesAppointmentToState implements Action {
  readonly feature = 'crm';
  readonly type = ADD_PROSPECT_SALES_APPOINTMENT_TO_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: Appointment) {}
}

export type ProspectSalesAppointmentsAction =
  | GetProspectSalesAppointmentsFromServer
  | GetProspectSalesAppointmentsFromServerSuccess
  | GetProspectSalesAppointmentsFromServerFailure
  | AddProspectSalesAppointmentToState;
