import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { HtmlMappingTemplate } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromHtmlMappingTemplates from './html-mapping-templates.actions';
import { HtmlMappingTemplatesState } from './html-mapping-templates.interfaces';
@Injectable()
export class HtmlMappingTemplatesEffects {
  
  getHtmlMappingTemplatesFromServer = createEffect(() => this.dataPersistence.fetch(
    fromHtmlMappingTemplates.GET_HTML_MAPPING_TEMPLATES_FROM_SERVER,
    {
      run: (action: fromHtmlMappingTemplates.GetHtmlMappingTemplatesFromServer, state: any) => {
        if (state.crmDropdowns.htmlMappingTemplates.ids.length == 0) {
          return this.apiService
            .get<HtmlMappingTemplate[]>(`v/1/common/html-templates/mapping-templates`, {
              params: action.payload,
            })
            .pipe(
              map((htmlMappingTemplates) => {
                return new fromHtmlMappingTemplates.GetHtmlMappingTemplatesFromServerSuccess({
                  htmlMappingTemplates: htmlMappingTemplates,
                });
              })
            );
        }
      },
      onError: (action: fromHtmlMappingTemplates.GetHtmlMappingTemplatesFromServer, error) => {
        console.error('Error', error);
      },
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<HtmlMappingTemplatesState>,
    private apiService: ApiService
  ) {}
}
