import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Task } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTaskDialogComponent implements OnInit, AfterViewInit {
  taskForm = this.fb.group({
    dueDate: [Validators.required],
    employeeId: [Validators.required],
    note: ['', Validators.required],
    prospectId: [],
    taskEmployeeId: [],
  });

  constructor(
    public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { dropdowns: { salespeople: any }; newTask: Task },
    private fb: FormBuilder,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.taskForm.patchValue({
      dueDate: this.data.newTask.dueDate,
      employeeId: this.data.newTask.employeeId,
      prospectId: this.data.newTask.prospectId,
      taskEmployeeId: this.data.newTask.taskEmployeeId,
    });
  }

  onSubmit() {
    this.dialogRef.close(this.taskForm.value);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.selectRootElement('#task-objectives-text-area').focus();
    }, 250);
  }
}
