import * as fromEquityMining from './equity-mining.actions';
import { EquityMiningState, initialState } from './equity-mining.interfaces';

export function reducer(
  state: EquityMiningState = initialState,
  action: fromEquityMining.EquityMiningAction
): EquityMiningState {
  switch (action.type) {
    case fromEquityMining.SET_AVAILABLE_PARAMS_IN_STATE: {
      return {
        ...state,
        available: action.payload.params
      };
    }
    case fromEquityMining.SET_IN_EQUITY_PARAMS_IN_STATE: {
      return {
        ...state,
        'in-equity': action.payload.params
      };
    }
    case fromEquityMining.SET_OVER_MILEAGE_PARAMS_IN_STATE: {
      return {
        ...state,
        'over-mileage-lease': action.payload.params
      };
    }
    case fromEquityMining.SET_SUB_PRIME_PARAMS_IN_STATE: {
      return {
        ...state,
        'sub-prime': action.payload.params
      };
    }
    case fromEquityMining.SET_SERVICE_APPOINTMENTS_PARAMS_IN_STATE: {
      return {
        ...state,
        'service-appointment': action.payload.params
      };
    }
    case fromEquityMining.SET_CUSTOMERS_PARAMS_IN_STATE: {
      return {
        ...state,
        customers: action.payload.params
      };
    }
    case fromEquityMining.SET_UNSOLD_VEHICLES_SEARCH_PARAMS_IN_STATE: {
      return {
        ...state,
        'unsold-vehicles': action.payload.params
      };
    }

    case fromEquityMining.SET_VEHICLES_SEARCH_PARAMS_IN_STATE: {
      return {
        ...state,
        vehicles: action.payload.params
      };
    }

    default: {
      return state;
    }
  }
}
