import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Channel } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromVehicleMakes from './vehicle-makes.actions';
import { VehicleMakesState } from './vehicle-makes.interfaces';

@Injectable()
export class VehicleMakesEffects {
  
  getVehicleMakesFromServer = createEffect(() => this.dataPersistence.fetch(fromVehicleMakes.GET_VEHICLE_MAKES_FROM_SERVER, {
    run: (action: fromVehicleMakes.GetVehicleMakesFromServer, state: VehicleMakesState) => {
      return this.apiService
        .get<Channel[]>(`v/1/vehicles/vehicle-makes`, {
          params: action.payload,
        })
        .pipe(
          map((vehicleMakes) => {
            return new fromVehicleMakes.GetVehicleMakesFromServerSuccess({
              vehicleMakes: vehicleMakes,
            });
          })
        );
    },
    onError: (action: fromVehicleMakes.GetVehicleMakesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleMakesState>,
    private apiService: ApiService
  ) {}
}
