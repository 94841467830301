import { NgModule } from '@angular/core';
import { AssociateCardComponent } from './associate-card/associate-card.component';
import { CommonModule } from '@angular/common';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [CommonModule, CommonMaterialComponentsModule, CommonPipesModule, FlexLayoutModule],
    exports: [AssociateCardComponent],
    declarations: [AssociateCardComponent]
})
export class CrmAssociateCardNgUiModule {}
