<div fxLayout="column" *ngIf="{prospectClosedActions: prospectClosedActions$ | async} as data">
  <div id="header" fxLayout="row" [ngStyle]="{
      'background-color':
        prospectCopy.typeId == 3
          ? prospectCopy.vendorId && prospectCopy.associateId == null
            ? 'rgba(128, 128, 128, 0.1)'
            : '#e8f0fe'
          : '#e6f4ea'
    }" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxFlex>
      <div fxLayout="column" class="full-width">
        <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
          <span fxLayoutAlign="start center" class="mat-subheading-2">
            <mat-icon class="person-icon">person</mat-icon>Prospect {{ prospectCopy?.id }}
          </span>
          <div *ngIf="!prospectCopy.vendorId; else leadsMenu">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId"
                (click)="openAssociate(associateCopy.id)">
                <mat-icon>person</mat-icon>
                <span>Associate Profile</span>
              </button>
              <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId"
                (click)="openCustomerSummary(associateCopy.id)">
                <mat-icon>person</mat-icon>
                <span>Customer Summary</span>
              </button>
              <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId"
                (click)="openCustomerVehicles(associateCopy.id)">
                <mat-icon>directions_car</mat-icon>
                <span>Customer Vehicles</span>
              </button>
              <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId && communicatorEnabled"
                (click)="openCommunicator(prospectCopy)">
                <mat-icon>email</mat-icon>
                <span>Open Communicator</span>
              </button>
              <div *ngIf="associateCopy.messagePreferenceId == 2; else emailTemplate">
                <div *ngIf="(directMessageTemplates$ | async) as directMessageTemplates">
                  <button mat-menu-item
                    *ngIf="prospectCopy.associateId && associateCopy.messagePreferenceId == 2 && communicatorEnabled"
                    (click)="openCommunicatorTextDialog(directMessageTemplates, prospect, currentUser)">
                    <mat-icon>email</mat-icon>
                    <span>Send Message</span>
                  </button>
                </div>
              </div>
              <ng-template #emailTemplate>
                <div *ngIf="(directMessageTemplates$ | async) as directMessageTemplates">
                  <div *ngIf="(htmlMappingTemplates$ | async) as htmlMappingTemplates">
                    <button mat-menu-item
                      *ngIf="prospectCopy.associateId && associateCopy.messagePreferenceId == 1 && communicatorEnabled"
                      (click)="openCommunicatorEmailDialog(directMessageTemplates, htmlMappingTemplates, associate,
                        prospect, currentUser)">
                      <mat-icon>email</mat-icon>
                      <span>Send Message</span>
                    </button>
                  </div>
                </div>
              </ng-template>
              <a [href]="'tel:' + prospectCopy.phoneNumber" *ngIf="!drilldownEnabled && prospectCopy.phoneNumber"
                mat-menu-item>
                <mat-icon>call</mat-icon>
                <span>Call Main</span>
              </a>
              <a [href]="'tel:' + addressCopy.cellPhoneNumber" *ngIf="!drilldownEnabled && addressCopy?.cellPhoneNumber"
                mat-menu-item>
                <mat-icon>call</mat-icon>
                <span>Call Cell</span>
              </a>
              <button mat-menu-item *ngIf="settings?.salesPlannerView === 1 || prospectCopy.typeId === 3"
                (click)="reopenCloseProspect(prospectCopy, addressCopy, associateCopy, currentUser, data.prospectClosedActions, prospectUpdated)">
                <div *ngIf="prospectCopy.statusId === '1'; else reopenProspect">
                  <mat-icon>lock</mat-icon>
                  <span>Close Prospect</span>
                </div>

                <ng-template #reopenProspect>
                  <mat-icon>lock_open</mat-icon>
                  <span>Reopen Prospect</span>
                </ng-template>
              </button>
            </mat-menu>
          </div>
          <ng-template #leadsMenu>
            <div>
              <button mat-icon-button [matMenuTriggerFor]="leadMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #leadMenu="matMenu">
                <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId"
                  (click)="openAssociate(associateCopy.id)">
                  <mat-icon>person</mat-icon>
                  <span>Associate Profile</span>
                </button>
                <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId"
                  (click)="openCustomerSummary(associateCopy.id)">
                  <mat-icon>person</mat-icon>
                  <span>Customer Summary</span>
                </button>
                <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId"
                  (click)="openCustomerVehicles(associateCopy.id)">
                  <mat-icon>directions_car</mat-icon>
                  <span>Customer Vehicles</span>
                </button>
                <button mat-menu-item *ngIf="drilldownEnabled && prospectCopy.associateId && communicatorEnabled"
                  (click)="openCommunicator(prospectCopy)">
                  <mat-icon>email</mat-icon>
                  <span>Open Communicator</span>
                </button>
                <div *ngIf="associateCopy.messagePreferenceId == 2; else emailTemplate">
                  <div *ngIf="(directMessageTemplates$ | async) as directMessageTemplates">
                    <button mat-menu-item
                      *ngIf="prospectCopy.associateId && associateCopy.messagePreferenceId == 2 && communicatorEnabled"
                      (click)="openCommunicatorTextDialog(directMessageTemplates, prospect, currentUser)">
                      <mat-icon>email</mat-icon>
                      <span>Send Message</span>
                    </button>
                  </div>
                </div>
                <ng-template #emailTemplate>
                  <div *ngIf="(directMessageTemplates$ | async) as directMessageTemplates">
                    <div *ngIf="(htmlMappingTemplates$ | async) as htmlMappingTemplates">
                      <button mat-menu-item *ngIf="
                          prospectCopy.associateId && associateCopy.messagePreferenceId == 1 && communicatorEnabled"
                        (click)="openCommunicatorEmailDialog(directMessageTemplates, htmlMappingTemplates, associate,
                        prospect, currentUser)">
                        <mat-icon>email</mat-icon>
                        <span>Send Message</span>
                      </button>
                    </div>
                  </div>
                </ng-template>
                <a [href]="'tel:' + prospectCopy.phoneNumber" *ngIf="!drilldownEnabled && prospectCopy.phoneNumber"
                  mat-menu-item>
                  <mat-icon>call</mat-icon>
                  <span>Call Main</span>
                </a>
                <a [href]="'tel:' + addressCopy.cellPhoneNumber"
                  *ngIf="!drilldownEnabled && addressCopy?.cellPhoneNumber" mat-menu-item>
                  <mat-icon>call</mat-icon>
                  <span>Call Cell</span>
                </a>
                <div *ngIf="responseLeads$ | async as responseLeads">
                  <button mat-menu-item *ngIf="responseLeads.length > 0" (click)="openLeads(prospectCopy.id)">
                    <mat-icon>list_alt</mat-icon>
                    <span>Open Leads</span>
                  </button>
                </div>
                <button mat-menu-item
                  (click)="reopenCloseProspect(prospectCopy, addressCopy, associateCopy, currentUser, data.prospectClosedActions, prospectUpdated)">
                  <div *ngIf="prospectCopy.statusId === '1'; else reopenProspect">
                    <mat-icon>lock</mat-icon>
                    <span>Close Prospect</span>
                  </div>

                  <ng-template #reopenProspect>
                    <mat-icon>lock_open</mat-icon>
                    <span>Reopen Prospect</span>
                  </ng-template>
                </button>
              </mat-menu>
            </div>
          </ng-template>
        </div>

        <div fxLayout="column">
          <ng-container *ngIf="prospectCopy.typeId === 3; else available">
            <div *ngIf="prospectCopy.associateId === null; else matched" fxLayout="row" fxLayoutAlign="start center">
              <ng-container *ngIf="prospectCopy.vendorId; else unmatchedFreeformProspect">
                <span class="card-header-text"><strong>Unmatched Lead</strong> via {{ prospectCopy.vendorName }}</span>
              </ng-container>
              <ng-template #unmatchedFreeformProspect>
                <span class="card-header-text"><strong>Unmatched Prospect</strong></span>
              </ng-template>
              <button mat-flat-button class="match-button"
                (click)="openVendorMatch(prospectCopy, dialog, latestResponseLead, drilldownEnabled)">
                Match
              </button>
            </div>
            <ng-template #matched>
              <ng-container *ngIf="prospectCopy.vendorId; else matchedFreeformProspect">
                <span class="card-header-text">Matched Lead via {{ prospectCopy.vendorName }} on
                  {{prospectCopy?.matchedDate | date:'mediumDate'}}</span>
              </ng-container>
              <ng-template #matchedFreeformProspect>
                <span class="card-header-text" *ngIf="prospectCopy?.matchedDate">Matched on
                  {{prospectCopy?.matchedDate | date:'mediumDate'}}</span>
              </ng-template>
            </ng-template>
            <span class="card-header-text">Entered {{ prospectCopy.enteredDate | moment: 'MMMM Do, YYYY' }}</span>
          </ng-container>
          <ng-template #available>
            <span class="card-header-text">Available {{ prospectCopy.availableDate | moment: 'MMMM Do, YYYY' }}</span>
          </ng-template>

          <div class="card-header-text flex flex-col" *ngIf="prospect.statusId == '2'">
            <span>Closed {{ prospectCopy.closedDate | moment:'MMMM Do, YYYY' }} by
              {{ prospectCopy.embedded.closedByAssociate ? prospectCopy.embedded.closedByAssociate.firstName + ' ' +
              prospectCopy.embedded.closedByAssociate.lastName : 'Unknown' }}</span>
            <span class="text-sm text-grey-500">
              {{selectedProspectClosedAction.description ? selectedProspectClosedAction.description : ''}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="prospectsDiv" class="detail-header pointer" fxLayout="row" fxLayoutAlign="space-between center"
    (click)="toggleProspectDetailsData()">
    <div fxLayout="column">
      <span>Details</span>
    </div>
    <div *ngIf="!prospectDetailsPanel; else showProspectDetailsPanel" fxLayoutAlign="center center" class="pointer">
      <span class="mat-caption">Show</span>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
    <ng-template #showProspectDetailsPanel>
      <div fxLayoutAlign="center center" class="pointer">
        <span class="mat-caption">Hide</span>
        <mat-icon>keyboard_arrow_up</mat-icon>
      </div>
    </ng-template>
  </div>
  <div class="prospect-details-panel" *ngIf="prospectDetailsPanel">
    <div fxLayout="row" fxLayoutAlign="end end">
      <button mat-icon-button color="primary" *ngIf="!editMode; else saveButton"
        (click)="enableEditMode(settings, prospect, currentUser);$event.stopPropagation(); !prospectDetailsPanel && toggleProspectDetailsData()">
        <mat-icon>edit</mat-icon>
      </button>
      <ng-template #saveButton>
        <div fxLayout="row">
          <div class="unsaved-div" [ngClass]="{'cancel-icon-unsaved': prospectForm.dirty }" fxLayout="row"
            *ngIf="(prospectForm && prospectForm.dirty) || (associateForm && associateForm.dirty) || (contactForm && contactForm.dirty)">
            Unsaved Changes
          </div>
          <button mat-icon-button class="secondary cancel-button"
            (click)="cancelUpdate(prospect, associate, address);$event.stopPropagation();">
            <mat-icon
              [ngClass]="{'cancel-icon-unsaved': prospectForm.dirty || associateForm.dirty || contactForm.dirty}">cancel
            </mat-icon>
          </button>
          <button
            [disabled]="prospectCopy.associateId == null ? prospectForm.invalid : associateForm.invalid || contactForm.invalid || prospectForm.invalid"
            mat-icon-button color="primary"
            (click)="updateProspect(associateCopy, prospectCopy, addressCopy, currentUser, prospectUpdated, data.prospectClosedActions);$event.stopPropagation();">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </ng-template>
    </div>
    <div *ngIf="prospectCopy.associateId != null">
      <h4>Associate</h4>
      <form [formGroup]="associateForm" fxLayoutAlign="start center" fxLayout="row wrap">
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <input #associateEmailAddress matInput type="email" placeholder="Email" name="emailAddress"
            [required]="associateForm.get('messagePreferenceId').value === 1" formControlName="emailAddress" />
        </mat-form-field>

        <mat-form-field fxFlex="33" fxFlex.xs="100" *ngIf="associateCopy != null">
          <mat-select placeholder="Message Preference" name="messagePreferenceId" formControlName="messagePreferenceId"
            (selectionChange)="updateMessagePreferenceRequiredFields($event)">
            <mat-option *ngFor="let messageType of dropdowns.messageTypes" [value]="messageType.id">
              {{ messageType.description }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="33" fxFlex.xs="100" *ngIf="associateCopy != null">
          <mat-select placeholder="Communication Preference" name="contactPreferenceId"
            formControlName="contactPreferenceId">
            <mat-option value="E">Email</mat-option>
            <mat-option value="F">Fax</mat-option>
            <mat-option value="L">Letter</mat-option>
            <mat-option value="H">Phone</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div *ngIf="prospectCopy.associateId != null">
      <h4>Contact</h4>
      <form fxLayoutAlign="start center" fxLayout="row wrap" [formGroup]="contactForm">
        <ng-template>Fill Out Contact Information</ng-template>
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <input #contactCellPhoneNumber matInput autocomplete="off"
            [required]="associateForm.get('messagePreferenceId').value === 2"
            [textMask]="{ mask: phoneNumberMask, guide: false }" name="cellPhoneNumber" placeholder="Cell Phone"
            formControlName="cellPhoneNumber" />
        </mat-form-field>
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <input matInput autocomplete="off" [textMask]="{ mask: phoneNumberMask, guide: false }" name="homePhoneNumber"
            placeholder="Main Phone" formControlName="homePhoneNumber" />
        </mat-form-field>
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <input matInput autocomplete="off" name="addressLine1" placeholder="Address" formControlName="addressLine1"
            required />
        </mat-form-field>
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <mat-select placeholder="Province/State" name="provStateId" formControlName="provStateId" required>
            <mat-option *ngFor="let provinceState of provinceStates$ | async | orderBy: 'name'"
              [value]="provinceState.id">
              {{provinceState.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <mat-select #location placeholder="Location" name="locationId" formControlName="locationId" required>
            <mat-option *ngFor="let location of locations$ | async"
              (click)="setPostalCodeAndCountryCode(location.postalCode, location.countryId)" [value]="location.id">
              {{location.description}} {{location.provStateId}}, {{location.countryId}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="33" fxFlex.xs="100">
          <input matInput autocomplete="off" name="postalZipCode" placeholder="Postal/Zip Code"
            formControlName="postalZipCode" required />
        </mat-form-field>
      </form>
    </div>
    <h4>Prospect</h4>
    <form fxLayout="row wrap" fxLayoutAlign="start center" [formGroup]="prospectForm">
      <mat-form-field fxFlex="33" fxFlex.xs="100" *ngIf="prospectCopy.associateId == null">
        <input matInput autocomplete="off" [textMask]="{ mask: phoneNumberMask, guide: false }" name="phoneNumber"
          placeholder="Main Phone" formControlName="phoneNumber" />
      </mat-form-field>
      <mat-form-field fxFlex="33" fxFlex.xs="100" *ngIf="prospectCopy.associateId == null">
        <input matInput autocomplete="off" name="emailAddress" placeholder="Email" formControlName="emailAddress" />
      </mat-form-field>
      <mat-form-field fxFlex="33" fxFlex.xs="100">
        <mat-select placeholder="Salesperson" name="primaryEmployeeId" formControlName="primaryEmployeeId" required>
          <mat-option [value]="dropdowns.salespeople.employeeId.EmployeeId">
            {{ dropdowns.salespeople.employeeId.EmployeeName }}</mat-option>
          <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams" [label]="salesTeam.teamName">
            <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
              {{ salesperson.EmployeeName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="33" fxFlex.xs="100">
        <mat-select placeholder="Secondary Salesperson" name="secondaryEmployeeId"
          formControlName="secondaryEmployeeId">
          <mat-option (click)="toggle(dropdowns.salespeople.employeeId.EmployeeId)"
            [value]="dropdowns.salespeople.employeeId.EmployeeId">
            {{ dropdowns.salespeople.employeeId.EmployeeName }}</mat-option>
          <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams" [label]="salesTeam.teamName">
            <mat-option *ngFor="let salesperson of salesTeam.children" (click)="toggle(salesperson.EmployeeId)"
              [value]="salesperson.EmployeeId">
              {{ salesperson.EmployeeName }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="33" fxFlex.xs="100">
        <mat-select placeholder="Rooftop" name="rooftopId" formControlName="rooftopId">
          <mat-option *ngFor="let rooftop of dropdowns.rooftops" [value]="rooftop.id">{{ rooftop.displayCode }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="33" fxFlex.xs="100">
        <mat-select placeholder="Channel" name="channelId" formControlName="channelId">
          <mat-option *ngFor="let channel of dropdowns.channels | orderBy: 'description'" [value]="channel.id">
            {{ channel.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="33" fxFlex.xs="100">
        <mat-select placeholder="Source" name="sourceId" formControlName="sourceId">
          <mat-optgroup>
            <label fxFlex>
              Dealer Sources
            </label>
            <mat-option
              *ngFor="let source of dropdowns.sources | orderBy: ['description'] | filterBy: ['dealerAdded']: true"
              [value]="source.id">
              {{ source.description }}
            </mat-option>
          </mat-optgroup>
          <mat-optgroup disabled>
            <label fxFlex>
              Vendor Sources
            </label>
            <mat-option
              *ngFor="let source of dropdowns.sources | orderBy: ['description'] | filterBy: ['dealerAdded']: false"
              [value]="source.id">
              {{source.description}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>