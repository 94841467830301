import { Action } from '@ngrx/store';
import { EmployeeQueryParameters } from '@quorum/models/xs-query';
import { Employee } from '@quorum/models/xs-resource';

export const GET_EMPLOYEES_FROM_SERVER = '[CRM] Get Employees From Server';
export class GetEmployeesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_EMPLOYEES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: EmployeeQueryParameters) {}
}

export const GET_EMPLOYEES_FROM_SERVER_SUCCESS = '[CRM] Get Employees From Server Success';
export class GetEmployeesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_EMPLOYEES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { employees: Employee[] }) {}
}

export const GET_EMPLOYEES_FROM_SERVER_FAILURE = '[CRM] Get Employees From Server Failure';
export class GetEmployeesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_EMPLOYEES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type EmployeesAction = GetEmployeesFromServer | GetEmployeesFromServerSuccess | GetEmployeesFromServerFailure;
