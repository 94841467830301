import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticationState, AuthenticationStatus, fromAuthentication } from '@quorum/authentication/state';
import {
  fromAssociateClasses,
  fromChannels,
  fromCustomerTypes,
  fromEmployees,
  fromMessageTypes,
  fromProspectActions,
  fromRooftops,
  fromSalespeople,
  fromSources,
  fromTaskOutcomes,
  fromVehicleCategories,
  fromVehicleColours,
  fromVehicleGenericModels,
  fromVehicleMakes,
  fromVehicleStatuses,
  fromVehicleSubcategories,
  fromVehicleSubTypes,
  fromVendors,
} from '@quorum/crm-dropdowns/state';
import { ShellComponent as EquityMiningShellComponent } from '@quorum/crm-equity-mining-ng-ui';
import { ProspectLeadsComponent } from '@quorum/crm-prospects-ng-ui';
// eslint-disable-next-line max-len
import { fromSettings } from '@quorum/crm-settings/state';
import { ShellComponent as VehicleSearchShellComponent } from '@quorum/crm-vehicle-search-ng-ui';
import { Employee } from '@quorum/models/xs-resource';
import { fromRouter, Go } from '@quorum/sha-router';
import { LocalSettingsState } from '@quorum/xsr-salesplanner-legacy/services';
import { fromLocalSettings, fromSalesPlannerLegacy, fromUi } from '@quorum/xsr-salesplanner-legacy/state';
import { ListShellComponent } from 'libs/crm-equity-mining-ng-ui/src/lib/list-shell/list-shell.component';
// eslint-disable-next-line max-len
import { AddProspectVehicleShellComponent } from 'libs/crm-prospects-ng-ui/src/lib/add-prospect-vehicle-shell/add-prospect-vehicle-shell.component';
import { CookieService } from 'ngx-cookie-service';
import { from, of, race } from 'rxjs';
import { delay, filter, map, mergeAll, switchMap } from 'rxjs/operators';
@Injectable()
export class AppEffects {
  
  go = createEffect(() => this.d.pessimisticUpdate(fromRouter.NAVIGATE, {
    run: (m: Go, state: any) => {
      let actions: Action[] = [];
      if (state.authentication.authenticatedUser) {
        actions.push(new fromUi.GetUnassignedProspectLeadsFromServer({}));
        actions.push(new fromUi.GetUnmatchedProspectLeadsFromServer({}));
      }

      this.router.navigate(m.payload.path, m.payload.extras);
      return from(actions);
    },
    onError: (action: Go, err) => {},
  }));

  
  goToRoot = createEffect(() => this.d.pessimisticUpdate(fromRouter.NAVIGATE_TO_ROOT, {
    run: (m: Go, state: any) => {
      this.router.navigate(['']);
    },
    onError: (action: Go, err) => {},
  }), { dispatch: false });

  
  userAuthenticated = createEffect(() => this.d.fetch(fromAuthentication.USER_AUTHENTICATED, {
    run: (a: fromAuthentication.UserAuthenticated, state: AuthenticationState) => {
      console.log(this.cookieService.get('X-Source'));
      let actions: Action[] = [];
      actions.push(
        new fromAuthentication.UpdateAuthenticationStages({
          id: 1,
          changes: { authenticationStatus: AuthenticationStatus.completed },
        })
      );

      return from(actions).pipe(delay(500));
    },
    onError: (a: fromAuthentication.UserAuthenticated, error) => {
      return new fromAuthentication.UserAuthenticatedError(error);
    },
  }));

  
  authenticationStages = createEffect(() => this.d.fetch(fromAuthentication.UPDATE_AUTHENTICATION_STAGES, {
    run: (a: fromAuthentication.UpdateAuthenticationStages, state: any) => {
      let actions: Action[] = [];
      if (a.payload.id == 1 && a.payload.changes.authenticationStatus == AuthenticationStatus.completed) {
        if (
          this.cookieService.get('X-Source') &&
          this.cookieService.get('X-Source').toLowerCase() !=
            state.authentication.authenticatedUser.user.version.toLowerCase()
        ) {
          console.log('we need to refresh for the correct version');
          console.log(state.authentication.authenticatedUser.user.version);
          this.cookieService.set('X-Source', state.authentication.authenticatedUser.user.version);
          actions.push(
            new fromAuthentication.UpdateAuthenticationStages({
              id: 3,
              changes: { authenticationStatus: AuthenticationStatus.inProgress },
            })
          );
        } else {
          actions.push(
            new fromAuthentication.UpdateAuthenticationStages({
              id: 2,
              changes: { authenticationStatus: AuthenticationStatus.completed },
            })
          );
          actions.push(new fromAuthentication.GetEmployeeFromServer(state.authentication.authenticatedUser.user));
        }
      }
      if (a.payload.id == 3 && a.payload.changes.authenticationStatus == AuthenticationStatus.inProgress) {
        setTimeout(() => {
          location.reload();
        }, 500);
      }

      return from(actions).pipe(delay(500));
    },
    onError: (a: fromAuthentication.UpdateAuthenticationStages, error) => {
      return new fromAuthentication.UserAuthenticatedError(error);
    },
  }));

  
  getEmployeeFromServer = createEffect(() => this.d.fetch(fromAuthentication.GET_EMPLOYEE_FROM_SERVER, {
    run: (action: fromAuthentication.GetEmployeeFromServer, state: AuthenticationState) => {
      return this.apiService.get<Employee>(`v/1/associates/employees/${action.payload.xselleratorEmployeeId}`).pipe(
        map((employee) => {
          return new fromAuthentication.GetEmployeeFromServerSuccess(employee);
        })
      );
    },
    onError: (a: fromAuthentication.GetEmployeeFromServer, error) => {
      return new fromAuthentication.GetEmployeeFromServerFailure({
        message: 'Error retrieving user profile.',
        error: error.reason,
      });
    },
  }));

  
  getEmployeeFromServerSuccess = createEffect(() => this.d.fetch(fromAuthentication.GET_EMPLOYEE_FROM_SERVER_SUCCESS, {
    run: (action: fromAuthentication.GetEmployeeFromServerSuccess, state: any) => {
      const actions: Action[] = [];

      actions.push(
        new fromAuthentication.UpdateAuthenticationStages({
          id: 4,
          changes: { authenticationStatus: AuthenticationStatus.completed },
        })
      );
      if (
        state.salesplannerLegacy.localSettings.salesPeople &&
        state.salesplannerLegacy.localSettings.salesPeople.length == 0
      ) {
        actions.push(
          new fromLocalSettings.UpdateLocalSettingsInState({
            ...state.salesplannerLegacy.localSettings,
            salesPeople: [action.payload.associateId],
          })
        );
      }
      actions.push(new fromSettings.GetCrmSettingsFromServer({ associateId: action.payload.associateId }));
      return of(actions).pipe(delay(500), mergeAll());
    },
    onError: (a: fromAuthentication.GetEmployeeFromServerSuccess, error) => {
      return new fromAuthentication.GetEmployeeFromServerFailure(error);
    },
  }));

  
  getSalesPlannerSettingsFromServer = createEffect(() => this.d.fetch(fromSettings.GET_CRM_SETTINGS_FROM_SERVER, {
    run: (action: fromSettings.GetCrmSettingsFromServer, state: AuthenticationState) => {
      return this.apiService.get<any>(`v/1/crm/legacy/sales-planner-settings`, { params: action.payload }).pipe(
        map((settings) => {
          return new fromSettings.GetCrmSettingsFromServerSuccess(settings);
        })
      );
    },
    onError: (a: fromSettings.GetCrmSettingsFromServer, error) => {
      // return new fromAuthentication.GetEmployeeFromServerFailure({
      //   message: 'Error retrieving user profile.',
      //   error: error.reason
      // });
    },
  }));

  
  getSalesplannerSettingsFromServerSuccess = createEffect(() => this.d.fetch(fromSettings.GET_CRM_SETTINGS_FROM_SERVER_SUCCESS, {
    run: (action: fromSettings.GetCrmSettingsFromServerSuccess, state: AuthenticationState) => {
      return from([
        new fromAuthentication.UpdateAuthenticationStages({
          id: 5,
          changes: { authenticationStatus: AuthenticationStatus.completed },
        }),
      ]).pipe(delay(500));
    },
    onError: (a: fromSettings.GetCrmSettingsFromServerSuccess, error) => {
      return new fromAuthentication.GetEmployeeFromServerFailure(error);
    },
  }));

  
  loadVehicleSearchDropdowns = createEffect(() => this.d.navigation(VehicleSearchShellComponent, {
    run: (a: ActivatedRouteSnapshot, state: any) => {
      return race(
        this.router.events.pipe(
          filter((events) => {
            return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
          }),
          map((event) => {
            return { action: 'finished', event };
          })
        ),
        this.router.events.pipe(
          filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
          map((event) => {
            return { action: 'cancelled', event };
          })
        )
      ).pipe(
        switchMap((event: any) => {
          if (event.action === 'cancelled') {
            return from([]);
          } else {
            return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
              filter((employee) => employee !== null),
              switchMap((employee: Employee) => {
                const localSettings: LocalSettingsState = state.salesplannerLegacy.localSettings;
                let actions: Action[] = [];

                if (state.crmDropdowns.vehicleCategories.ids.length <= 0) {
                  actions.push(new fromVehicleCategories.GetVehicleCategoriesFromServer({}));
                }
                if (state.crmDropdowns.vehicleColours.ids.length <= 0) {
                  actions.push(new fromVehicleColours.GetVehicleColoursFromServer({}));
                }
                if (state.crmDropdowns.vehicleGenericModels.ids.length <= 0) {
                  actions.push(new fromVehicleGenericModels.GetVehicleGenericModelsFromServer({}));
                }
                if (state.crmDropdowns.vehicleMakes.ids.length <= 0) {
                  actions.push(new fromVehicleMakes.GetVehicleMakesFromServer({}));
                }
                if (state.crmDropdowns.vehicleStatuses.ids.length <= 0) {
                  actions.push(new fromVehicleStatuses.GetVehicleStatusesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubcategories.ids.length <= 0) {
                  actions.push(new fromVehicleSubcategories.GetVehicleSubcategoriesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubTypes.ids.length <= 0) {
                  actions.push(new fromVehicleSubTypes.GetVehicleSubTypesFromServer({}));
                }
                if (state.crmDropdowns.rooftops.ids.length <= 0) {
                  actions.push(new fromRooftops.GetRooftopsFromServer({}));
                }

                if (state.crmDropdowns.channels.ids.length <= 0) {
                  actions.push(new fromChannels.GetChannelsFromServer({}));
                }
                if (state.crmDropdowns.sources.ids.length <= 0) {
                  actions.push(new fromSources.GetSourcesFromServer({}));
                }
                if (
                  !state.crmDropdowns.salespeople.teams ||
                  (state.crmDropdowns.salespeople.teams && state.crmDropdowns.salespeople.teams.length <= 0)
                ) {
                  actions.push(new fromSalespeople.GetSalespeopleFromServer({ employeeId: employee.associateId }));
                }

                if (state.crmDropdowns.associateClasses.ids.length <= 0) {
                  actions.push(new fromAssociateClasses.GetAssociateClassesFromServer({}));
                }

                if (state.crmDropdowns.messageTypes.ids.length <= 0) {
                  actions.push(new fromMessageTypes.GetAssociateMessageTypesFromServer({}));
                }
                if (state.crmDropdowns.customerTypes.ids.length <= 0) {
                  actions.push(new fromCustomerTypes.GetAssociateCustomerTypesFromServer({}));
                }
                if (state.crmDropdowns.prospectActions.ids.length <= 0) {
                  actions.push(new fromProspectActions.GetProspectActionsFromServer({}));
                }
                if (state.crmDropdowns.taskOutcomes.ids.length <= 0) {
                  actions.push(new fromTaskOutcomes.GetTaskOutcomesFromServer({}));
                }

                return of(actions).pipe(mergeAll());
              })
            );
          }
        })
      );
    },
    onError: (a: ActivatedRouteSnapshot, e: any) => {
      console.log(e);
      return null;
    },
  }));

  
  loadAddVehicleDropdowns = createEffect(() => this.d.navigation(AddProspectVehicleShellComponent, {
    run: (a: ActivatedRouteSnapshot, state: any) => {
      return race(
        this.router.events.pipe(
          filter((events) => {
            return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
          }),
          map((event) => {
            return { action: 'finished', event };
          })
        ),
        this.router.events.pipe(
          filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
          map((event) => {
            return { action: 'cancelled', event };
          })
        )
      ).pipe(
        switchMap((event: any) => {
          if (event.action === 'cancelled') {
            return from([]);
          } else {
            return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
              filter((employee) => employee !== null),
              switchMap((employee: Employee) => {
                const localSettings: LocalSettingsState = state.salesplannerLegacy.localSettings;
                let actions: Action[] = [];

                if (state.crmDropdowns.vehicleColours.ids.length <= 0) {
                  actions.push(new fromVehicleColours.GetVehicleColoursFromServer({}));
                }
                if (state.crmDropdowns.vehicleStatuses.ids.length <= 0) {
                  actions.push(new fromVehicleStatuses.GetVehicleStatusesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubcategories.ids.length <= 0) {
                  actions.push(new fromVehicleSubcategories.GetVehicleSubcategoriesFromServer({}));
                }

                console.log(actions);
                return of(actions).pipe(mergeAll());
              })
            );
          }
        })
      );
    },
    onError: (a: ActivatedRouteSnapshot, e: any) => {
      console.log(e);
      return null;
    },
  }));

  
  loadEquityListDropdowns = createEffect(() => this.d.navigation(ListShellComponent, {
    run: (a: ActivatedRouteSnapshot, state: any) => {
      return race(
        this.router.events.pipe(
          filter((events) => {
            return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
          }),
          map((event) => {
            return { action: 'finished', event };
          })
        ),
        this.router.events.pipe(
          filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
          map((event) => {
            return { action: 'cancelled', event };
          })
        )
      ).pipe(
        switchMap((event: any) => {
          if (event.action === 'cancelled') {
            return from([]);
          } else {
            return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
              filter((employee) => employee !== null),
              switchMap((employee: Employee) => {
                let actions: Action[] = [];

                if (state.crmDropdowns.rooftops.ids.length <= 0) {
                  actions.push(new fromRooftops.GetRooftopsFromServer({}));
                }

                function isEmpty(obj: any) {
                  for (var key in obj) {
                    if (obj.hasOwnProperty(key)) return false;
                  }
                  return true;
                }

                if (state.crmDropdowns.associateClasses.ids.length <= 0) {
                  actions.push(new fromAssociateClasses.GetAssociateClassesFromServer({}));
                }
                if (isEmpty(state.crmDropdowns.salespeople)) {
                  actions.push(new fromSalespeople.GetSalespeopleFromServer({ employeeId: employee.associateId }));
                }
                if (state.crmDropdowns.channels.ids.length <= 0) {
                  actions.push(new fromChannels.GetChannelsFromServer({}));
                }
                if (state.crmDropdowns.sources.ids.length <= 0) {
                  actions.push(new fromSources.GetSourcesFromServer({}));
                }
                if (state.crmDropdowns.messageTypes.ids.length <= 0) {
                  actions.push(new fromMessageTypes.GetAssociateMessageTypesFromServer({}));
                }
                if (state.crmDropdowns.customerTypes.ids.length <= 0) {
                  actions.push(new fromCustomerTypes.GetAssociateCustomerTypesFromServer({}));
                }
                if (state.crmDropdowns.prospectActions.ids.length <= 0) {
                  actions.push(new fromProspectActions.GetProspectActionsFromServer({}));
                }
                if (state.crmDropdowns.employees.ids.length <= 0) {
                  actions.push(new fromEmployees.GetEmployeesFromServer({}));
                }
                return of(actions).pipe(mergeAll());
              })
            );
          }
        })
      );
    },
    onError: (a: ActivatedRouteSnapshot, e: any) => {
      console.log(e);
      return null;
    },
  }));

  
  loadEquityMiningDropdowns = createEffect(() => this.d.navigation(EquityMiningShellComponent, {
    run: (a: ActivatedRouteSnapshot, state: any) => {
      return race(
        this.router.events.pipe(
          filter((events) => {
            return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
          }),
          map((event) => {
            return { action: 'finished', event };
          })
        ),
        this.router.events.pipe(
          filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
          map((event) => {
            return { action: 'cancelled', event };
          })
        )
      ).pipe(
        switchMap((event: any) => {
          if (event.action === 'cancelled') {
            return from([]);
          } else {
            return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
              filter((employee) => employee !== null),
              switchMap((employee: Employee) => {
                let actions: Action[] = [];

                if (state.crmDropdowns.rooftops.ids.length <= 0) {
                  actions.push(new fromRooftops.GetRooftopsFromServer({}));
                }

                function isEmpty(obj: any) {
                  for (var key in obj) {
                    if (obj.hasOwnProperty(key)) return false;
                  }
                  return true;
                }

                if (state.crmDropdowns.associateClasses.ids.length <= 0) {
                  actions.push(new fromAssociateClasses.GetAssociateClassesFromServer({}));
                }
                if (isEmpty(state.crmDropdowns.salespeople)) {
                  actions.push(new fromSalespeople.GetSalespeopleFromServer({ employeeId: employee.associateId }));
                }
                if (state.crmDropdowns.channels.ids.length <= 0) {
                  actions.push(new fromChannels.GetChannelsFromServer({}));
                }
                if (state.crmDropdowns.sources.ids.length <= 0) {
                  actions.push(new fromSources.GetSourcesFromServer({}));
                }
                if (state.crmDropdowns.messageTypes.ids.length <= 0) {
                  actions.push(new fromMessageTypes.GetAssociateMessageTypesFromServer({}));
                }
                if (state.crmDropdowns.customerTypes.ids.length <= 0) {
                  actions.push(new fromCustomerTypes.GetAssociateCustomerTypesFromServer({}));
                }
                if (state.crmDropdowns.prospectActions.ids.length <= 0) {
                  actions.push(new fromProspectActions.GetProspectActionsFromServer({}));
                }

                if (state.crmDropdowns.vehicleCategories.ids.length <= 0) {
                  actions.push(new fromVehicleCategories.GetVehicleCategoriesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubcategories.ids.length <= 0) {
                  actions.push(new fromVehicleSubcategories.GetVehicleSubcategoriesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubTypes.ids.length <= 0) {
                  actions.push(new fromVehicleSubTypes.GetVehicleSubTypesFromServer({}));
                }
                if (state.crmDropdowns.vehicleMakes.ids.length <= 0) {
                  actions.push(new fromVehicleMakes.GetVehicleMakesFromServer({}));
                }
                if (state.crmDropdowns.vehicleGenericModels.ids.length <= 0) {
                  actions.push(new fromVehicleGenericModels.GetVehicleGenericModelsFromServer({}));
                }
                if (state.crmDropdowns.vehicleColours.ids.length <= 0) {
                  actions.push(new fromVehicleColours.GetVehicleColoursFromServer({}));
                }
                if (state.crmDropdowns.vehicleStatuses.ids.length <= 0) {
                  actions.push(new fromVehicleStatuses.GetVehicleStatusesFromServer({}));
                }
                if (state.crmDropdowns.employees.ids.length <= 0) {
                  actions.push(new fromEmployees.GetEmployeesFromServer({}));
                }
                return of(actions).pipe(mergeAll());
              })
            );
          }
        })
      );
    },
    onError: (a: ActivatedRouteSnapshot, e: any) => {
      console.log(e);
      return null;
    },
  }));

  
  loadProspectLeadDropdowns = createEffect(() => this.d.navigation(ProspectLeadsComponent, {
    run: (a: ActivatedRouteSnapshot, state: any) => {
      return race(
        this.router.events.pipe(
          filter((events) => {
            return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
          }),
          map((event) => {
            return { action: 'finished', event };
          })
        ),
        this.router.events.pipe(
          filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
          map((event) => {
            return { action: 'cancelled', event };
          })
        )
      ).pipe(
        switchMap((event: any) => {
          if (event.action === 'cancelled') {
            return from([]);
          } else {
            return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
              filter((employee) => employee !== null),
              switchMap((employee: Employee) => {
                let actions: Action[] = [];

                if (state.crmDropdowns.rooftops.ids.length <= 0) {
                  actions.push(new fromRooftops.GetRooftopsFromServer({}));
                }

                function isEmpty(obj: any) {
                  for (var key in obj) {
                    if (obj.hasOwnProperty(key)) return false;
                  }
                  return true;
                }

                if (state.crmDropdowns.associateClasses.ids.length <= 0) {
                  actions.push(new fromAssociateClasses.GetAssociateClassesFromServer({}));
                }
                if (isEmpty(state.crmDropdowns.salespeople)) {
                  actions.push(new fromSalespeople.GetSalespeopleFromServer({ employeeId: employee.associateId }));
                }
                if (state.crmDropdowns.channels.ids.length <= 0) {
                  actions.push(new fromChannels.GetChannelsFromServer({}));
                }
                if (state.crmDropdowns.sources.ids.length <= 0) {
                  actions.push(new fromSources.GetSourcesFromServer({}));
                }
                if (state.crmDropdowns.messageTypes.ids.length <= 0) {
                  actions.push(new fromMessageTypes.GetAssociateMessageTypesFromServer({}));
                }
                if (state.crmDropdowns.customerTypes.ids.length <= 0) {
                  actions.push(new fromCustomerTypes.GetAssociateCustomerTypesFromServer({}));
                }
                if (state.crmDropdowns.prospectActions.ids.length <= 0) {
                  actions.push(new fromProspectActions.GetProspectActionsFromServer({}));
                }

                if (state.crmDropdowns.vehicleCategories.ids.length <= 0) {
                  actions.push(new fromVehicleCategories.GetVehicleCategoriesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubcategories.ids.length <= 0) {
                  actions.push(new fromVehicleSubcategories.GetVehicleSubcategoriesFromServer({}));
                }
                if (state.crmDropdowns.vehicleSubTypes.ids.length <= 0) {
                  actions.push(new fromVehicleSubTypes.GetVehicleSubTypesFromServer({}));
                }
                if (state.crmDropdowns.vehicleMakes.ids.length <= 0) {
                  actions.push(new fromVehicleMakes.GetVehicleMakesFromServer({}));
                }
                if (state.crmDropdowns.vehicleGenericModels.ids.length <= 0) {
                  actions.push(new fromVehicleGenericModels.GetVehicleGenericModelsFromServer({}));
                }
                if (state.crmDropdowns.vehicleColours.ids.length <= 0) {
                  actions.push(new fromVehicleColours.GetVehicleColoursFromServer({}));
                }
                if (state.crmDropdowns.vehicleStatuses.ids.length <= 0) {
                  actions.push(new fromVehicleStatuses.GetVehicleStatusesFromServer({}));
                }
                if (state.crmDropdowns.employees.ids.length <= 0) {
                  actions.push(new fromEmployees.GetEmployeesFromServer({}));
                }

                if (state.crmDropdowns.vendors.ids.length <= 0) {
                  actions.push(new fromVendors.GetVendorsFromServer({}));
                }
                return of(actions).pipe(mergeAll());
              })
            );
          }
        })
      );
    },
    onError: (a: ActivatedRouteSnapshot, e: any) => {
      console.log(e);
      return null;
    },
  }));

  
  logout = createEffect(() => this.d.pessimisticUpdate(fromAuthentication.LOGOUT, {
    run: (a: fromAuthentication.Logout, state: AuthenticationState) => {
      return from([
        new fromSalesPlannerLegacy.ResetState({}),
        new fromUi.ResetState(true),
        new fromLocalSettings.ResetLocalSettingsState({}),
      ]);
    },
    onError: (a: fromAuthentication.Logout, error) => {},
  }));

  
  userAuthenticatedError = createEffect(() => this.d.fetch(fromAuthentication.USER_AUTHENTICATED_ERROR, {
    run: (a: fromAuthentication.UserAuthenticatedError, state: AuthenticationState) => {
      return from([
        new fromSalesPlannerLegacy.ResetState({}),
        new fromUi.ResetState(true),
        new fromLocalSettings.ResetLocalSettingsState({}),
      ]);
    },
    onError: (a: fromAuthentication.UserAuthenticatedError, error) => {
      console.log('Error during user authenticated error effect.');
    },
  }));

  constructor(
    private actions: Actions,
    private d: DataPersistence<any>,
    private cookieService: CookieService,
    private router: Router,
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService
  ) {}
}
