<div fxFlexFill fxLayout="column">
  <h1>Confirm Phone # and Email Address</h1>

  <form mat-dialog-content fxLayout="column" fxLayoutAlign="start start" [formGroup]="phoneEmailForm" fxFlex>
    <div>
      <span class="mat-body-2">Choose a main phone number:</span>
      <mat-radio-group fxLayout="column" fxLayoutGap="12px" formControlName="mainPhoneNumber" required>
        <mat-radio-button *ngFor="let phoneNumber of mainPhoneNumbers | keyvalue" [value]="phoneNumber.key">
          {{phoneNumber.key | phoneNumber}} {{phoneNumber.value}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <span class="mat-body-2">Choose a cell phone number:</span>
      <mat-radio-group fxLayout="column" fxLayoutGap="12px" formControlName="cellPhoneNumber" required>
        <mat-radio-button *ngFor="let phoneNumber of cellPhoneNumbers | keyvalue" [value]="phoneNumber.key">
          {{phoneNumber.key | phoneNumber}} {{phoneNumber.value}}
        </mat-radio-button>
        <mat-radio-button [value]="null">None</mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <span class="mat-body-2">Choose an email address:</span>
      <mat-radio-group fxLayout="column" fxLayoutGap="12px" formControlName="emailAddress" required>
        <mat-radio-button *ngFor="let emailAddress of emailAddresses | keyvalue" [value]="emailAddress.key">
          {{emailAddress.key}} {{emailAddress.value}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button cdkFocusInitial (click)="confirmPhoneEmail(phoneEmailForm.value, dialogRef)"
      color="primary">Confirm</button>
  </div>
</div>