import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Vehicle } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AvailableQueryParameters, ProspectQueryParameters } from '@quorum/models/xs-query';
import { Available, Prospect } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { fromProspects } from '@quorum/xsr-entities/state';
import { of } from 'rxjs';
import { flatMap, map, mergeAll } from 'rxjs/operators';
import * as fromVehicles from './vehicles.actions';
import { VehiclesState } from './vehicles.interfaces';

@Injectable()
export class SelectedProspectVehiclesEffects {
  
  getProspectVehiclesFromServer = createEffect(() => this.dataPersistence.fetch(fromVehicles.GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromVehicles.GetSelectedProspectVehiclesFromServer, state: VehiclesState) => {
      return this.apiService
        .get<Vehicle[]>(`v/1/vehicles/vehicles`, {
          params: action.payload,
        })
        .pipe(
          map((vehicles) => {
            return new fromVehicles.GetSelectedProspectVehiclesFromServerSuccess({
              vehicles: vehicles,
            });
          })
        );
    },
    onError: (action: fromVehicles.GetSelectedProspectVehiclesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getVehicleAggregateDataFromServer = createEffect(() => this.dataPersistence.fetch(fromVehicles.GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromVehicles.GetVehicleAggregateDataFromServer, state: VehiclesState) => {
      return this.apiService
        .get<Available[]>(`v/1/reporting/crm/available`, {
          params: new AvailableQueryParameters({
            vehicleId: action.payload.toString(),
            sort: 'availableDate',
            saleStatusId: ['P,S'],
          }),
        })
        .pipe(
          map((available) => {
            return new fromVehicles.GetVehicleAggregateDataFromServerSuccess(available[0] ? available[0] : null);
          })
        );
    },
    onError: (action: fromVehicles.GetVehicleAggregateDataFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  reassignVehicle = createEffect(() => this.dataPersistence.fetch(fromVehicles.REASSIGN_VEHICLE, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromVehicles.ReassignVehicle, state: any) => {
      let actions: Action[] = [];
      return this.apiService
        .put<Vehicle>(`v/1/vehicles/vehicles/${action.payload.vehicleParams.id}`, action.payload.vehicleParams)
        .pipe(
          flatMap((vehicle) => {
            console.log(vehicle);
            actions.push(
              new fromVehicles.GetSelectedProspectVehiclesFromServer({
                associateId: vehicle.associateId,
                embed: ['prospectType'],
              })
            );
            let prospectQueryParameters: ProspectQueryParameters = {
              vehicleId: vehicle.id,
              associateId: action.payload.vehicleParams.embedded.associate.id,
            };
            return this.apiService.get<Prospect[]>(`v/1/crm/prospects`, { params: prospectQueryParameters }).pipe(
              map((prospects: Prospect[]) => prospects.filter((prospect: Prospect) => prospect.typeId === 4)),
              flatMap((prospects) => {
                if (prospects.length > 0) {
                  actions.push(
                    new fromProspects.UpdateProspectOnServer({
                      prospect: { ...prospects[0], statusId: '2' },
                      displayToast: false,
                    })
                  );
                }
                this.routerStateService.go(['/crm/sales-planner/prospects', `${action.payload.prospectId}`], {
                  queryParams: { tab: 'vehicle' },
                  relativeTo: this.route,
                });

                return of(actions).pipe(mergeAll());
              })
            );
          })
        );
    },
    onError: (action: fromVehicles.ReassignVehicle, error) => {
      console.error('Error', error);
    },
  }));

  // @Effect()
  // addNewVehicle = this.dataPersistence.fetch(fromVehicles.ADD_NEW_VEHICLE, {
  //   id: (a: any, state: any) => {
  //     return 1;
  //   },
  //   run: (action: fromVehicles.AddNewVehicle, state: any) => {
  //     let actions: Action[] = [];
  //     return this.apiService
  //       .post<Vehicle>(`v/1/vehicles/vehicles`, action.payload.vehicleParams, {
  //         params: { translateRequired: action.payload.translateRequired }
  //       })
  //       .pipe(
  //         flatMap(vehicle => {
  //           console.log(vehicle);
  //           let prospectQueryParameters: ProspectQueryParameters = {
  //             vehicleId: vehicle.id,
  //             associateId: action.payload.vehicleParams.embedded.associate.id
  //           };
  //           return this.apiService.get<Prospect[]>(`v/1/crm/prospects`, { params: prospectQueryParameters }).pipe(
  //             map((prospects: Prospect[]) => prospects.filter((prospect: Prospect) => prospect.typeId === 4)),
  //             flatMap(prospects => {
  //               if (prospects.length > 0) {
  //                 actions.push(
  //                   new fromProspects.UpdateProspectOnServer({
  //                     prospect: { ...prospects[0], statusId: '2' },
  //                     displayToast: false
  //                   })
  //                 );
  //               }
  //               return of(actions).pipe(mergeAll());
  //             })
  //           );
  //         })
  //       );
  //   },
  //   onError: (action: fromVehicles.AddNewVehicle, error) => {
  //     console.error('Error', error);
  //   }
  // });

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehiclesState>,
    private apiService: ApiService,
    private routerStateService: RouterStateService,
    private route: ActivatedRoute
  ) {}
}
