import { Action } from '@ngrx/store';
import { ActionQueryParameters } from '@quorum/models/xs-query';
import { Action as ProspectAction } from '@quorum/models/xs-resource';
export const GET_PROSPECT_ACTIONS_FROM_SERVER = '[CRM] Get Prospect Actions From Server';
export class GetProspectActionsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_ACTIONS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ActionQueryParameters) {}
}

export const GET_PROSPECT_ACTIONS_FROM_SERVER_SUCCESS = '[CRM] Get Prospect Actions From Server Success';
export class GetProspectActionsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_ACTIONS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { actions: ProspectAction[] }) {}
}

export const GET_PROSPECT_ACTIONS_FROM_SERVER_FAILURE = '[CRM] Get Prospect Actions From Server Failure';
export class GetProspectActionsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_ACTIONS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectActionsAction =
  | GetProspectActionsFromServer
  | GetProspectActionsFromServerSuccess
  | GetProspectActionsFromServerFailure;
