import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromSelectedProspectDeliveries from './prospect-deliveries.actions';
import { ProspectDeliveriesState } from './prospect-deliveries.interfaces';
import { Sale } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';

@Injectable()
export class SelectedProspectDeliveriesEffects {
  
  getSelectedProspectDeliveriesFromServer = createEffect(() => this.dataPersistence.fetch(
    fromSelectedProspectDeliveries.GET_PROSPECT_DELIVERIES_FROM_SERVER,
    {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectDeliveries.GetProspectDeliveriesFromServer, state: any) => {
        return this.apiService.get<Sale[]>(`v/1/vehicles/sales`, { params: action.payload }).pipe(
          map(deliveries => {
            return new fromSelectedProspectDeliveries.GetProspectDeliveriesFromServerSuccess({ deliveries });
          })
        );
      },
      onError: (action: fromSelectedProspectDeliveries.GetProspectDeliveriesFromServer, error) => {
        console.error('Error', error);
      }
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<ProspectDeliveriesState>,
    private apiService: ApiService
  ) {}
}
