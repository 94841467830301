import { Associate } from '../associates/associate.model';
import { Code } from './code.model';
import { Colour } from './colour.model';
import { Employee } from '../associates/employee.model';
import { GenericModel } from './generic-model.model';
import { Group } from './group.model';
import { Manufacturer } from '../associates/manufacturer.model';
import { OemRooftopLink } from '@quorum/models/xs-resource';
import { ReceiptMethod } from './receipt-method.model';
import { Rpo } from './rpo.model';

import { SubCategory } from './sub-category.model';
import { SubType } from './sub-type.model';
import { Type } from './type.model';
import { UnitOfMeasure } from './unit-of-measure.model';
import { VehicleProspectType } from './vehicle-prospect-type.model';
import { VehicleCategory } from './vehicle-category.model';
import { VehicleCost } from './vehicle-cost.model';

import { Sale } from './sale.model';
import { SalesSubType } from './sales-sub-type.model';

export class Vehicle {
  actualCashValue: number = null;
  apxCode: string = null;
  associateId: string = null;
  availableDate: Date = null;
  availableDatePreSold: Date = null;
  body: string = null;
  businessManagerId: string = null;
  cabConfiguration: string = null;
  categoryDescription: string = null;
  categoryId: string = null;
  categoryIdWhenSold: string = null;
  convertibleFlag: string = null;
  codeId: string = null;
  creditCardExpirationDate: Date = null;
  creditCardNumber: any = null;
  currentMileage: number = null;
  cylinder: number = null;
  daysInStock: number = null;
  deliveryDate: Date = null;
  deliveryMileage: number = null;
  doors: string = null;
  driverId: string = null;
  driveTrain: string = null;
  embedded?: VehicleEmbed = null;
  employeeId: string = null;
  endPaymentDate: Date = null;
  engine: string = null;
  enteredDate?: Date = null;
  equityValue: number = null;
  extendedWarrantyCaresManualTab: number = null;
  extendedWarrantyDailyRentalAllowance: number = null;
  extendedWarrantyEffectiveFlag: string = null;
  extendedWarrantyExpiryDate: Date = null;
  extendedWarrantyExpiryMileage: number = null;
  extendedWarrantyId: number = null;
  extendedWarrantyPlanId: string = null;
  extendedWarrantyTowLimit: number = null;
  exteriorColour: string = null;
  exteriorColourCode: string = null;
  fleetCode: string = null;
  fleetUnitCode: string = null;
  fuelMileage1: number = null;
  fuelMileage2: number = null;
  fuelType: string = null;
  fuelType1: string = null;
  fuelType2: string = null;
  fuelUnitOfMeasure?: object = null;
  genericModelId: number = null;
  gmDrivingCondition: string = null;
  grossWeight: number = null;
  groupId: number = null;
  hscCode: string = null;
  id: number = null;
  interiorColour?: object = null;
  interiorColourDescription: string = null;
  interiorColourCode: string = null;
  invoiceNumber: string = null;
  japanModelCode: string = null;
  keyCodeDoor: string = null;
  keyCodeIgnition: string = null;
  lastMileageDate: Date = null;
  lastPaymentAmount: number = null;
  lastPaymentDate: Date = null;
  lastResidualAmount: number = null;
  lastSaleInterestRate: number = null;
  lastSaleTerm: number = null;
  leaseCompany: string = null;
  leaseVehicleOperatorId: string = null;
  leaseVehicleOperatorName: string = null;
  licensePlateNumber: string = null;
  litres: number = null;
  make: string = null;
  manufacturerId: string = null;
  misc1: string = null;
  misc2: string = null;
  misc3: string = null;
  misc4: string = null;
  model: string = null;
  modelCode: string = null;
  modifiedDate: Date = null;
  netWeight: number = null;
  optimumCertificationNumber: string = null;
  optimumUsedFlag: string = null;
  orderDate: Date = null;
  orderNumber: string = null;
  passengerFlag: string = null;
  paymentFrequency: string = null;
  payType1: string = null;
  payType2: string = null;
  payType3: string = null;
  pegCode: string = null;
  price1Amount: number = null;
  price2Amount: number = null;
  price3Amount: number = null;
  pricingType?: object = null;
  productionDate: Date = null;
  prospectColourId: number = null;
  prospectDate: Date = null;
  prospectRange: string = null;
  prospectTypeId: number = null;
  retailDealerNumber: number = null;
  receiptMethodId: number = null;
  receivedDate: Date = null;
  regularWarrantyId: number = null;
  rooftopId: number = null;
  saleId: number = null;
  salespersonId: string = null;
  saleSubTypeId: string = null;
  saleTypeId: string = null;
  seatingCapacity: number = null;
  serviceContractNumber: string = null;
  serviceNoticeFlag: string = null;
  serviceRooftopId: number = null;
  sendToWeb: string = null;
  soldDate: Date = null;
  stationWagonFlag: string = null;
  stockNumber: string = null;
  statusCode: string = null;
  subCategoryId: number = null;
  subTypeId: number = null;
  suffixCode: string = null;
  summerTireStorageLocation: string = null;
  totalNumberOfPayments: number = null;
  toyotaServiceNoticeTemplateId: number = null;
  transmission: string = null;
  trimLevel: string = null;
  truckBox: string = null;
  truckVanAxle: string = null;
  truckVanTonRating: string = null;
  typeId: string = null;
  unitOfMeasureId: string = null;
  uvc: string = null;
  year: number = null;
  vehicleInventorySearchFlag: string = null;
  vin: string = null;
  vinDownload: string = null;
  warrantyConversionDescription: string = null;
  warrantyDeductibleAmount: number = null;
  warrantyDeductibleBuydownAmount: number = null;
  warrantyEffectiveDate: Date = null;
  warrantyEffectiveMileage: number = null;
  winterTireStorageLocation: string = null;

  constructor(obj?: Vehicle) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class VehicleEmbed {
  associate?: Associate = null;
  category?: VehicleCategory = null;
  code?: Code = null;
  cost?: VehicleCost = null;
  driverAssociate?: Associate = null;
  employee?: Employee = null;
  genericModel?: GenericModel = null;
  group?: Group = null;
  manufacturer?: Manufacturer = null;
  oemRooftopLink?: OemRooftopLink = null;
  prospectColour?: Colour = null;
  prospectType?: VehicleProspectType = null;
  receiptMethod?: ReceiptMethod = null;
  rpos?: Rpo[] = null;
  sale?: Sale = null;
  saleSubType?: SalesSubType = null;
  serviceOemrtRooftopLink?: OemRooftopLink = null;
  subCategory?: SubCategory = null;
  subType?: SubType = null;
  type?: Type = null;
  unitOfMeasure?: UnitOfMeasure = null;
}
