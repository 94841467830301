import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { CommunicatorService } from '@applications/sha-services';

import { EventTypes } from '@models/server-common';

import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticationStatus, fromAuthentication } from '@quorum/authentication/state';
import { CommunicatorFacade } from '@quorum/communicator-ngrx';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import {
  fromAssociateClasses,
  fromChannels,
  fromCustomerTypes,
  fromEmployees,
  fromMessageTypes,
  fromProspectActions,
  fromRooftops,
  fromSalespeople,
  fromSalesTeams,
  fromSources,
  fromTaskOutcomes,
  fromVendors,
} from '@quorum/crm-dropdowns/state';
import { AddProspectVehicleShellComponent } from '@quorum/crm-prospects-ng-ui';
import { DetailComponent, MasterComponent, ShellComponent } from '@quorum/crm-sales-planner-ng-ui';
import { ShellComponent as VehicleSearchShellComponent } from '@quorum/crm-vehicle-search-ng-ui';
import {
  CrmAppointmentQueryParameters as AppointmentQueryParameters,
  ConversationQueryParameters,
  DealerPromotionQueryParameters,
  ProspectActionQueryParameters,
  ProspectQueryParameters,
  ProspectReportQueryParameters,
  SaleQueryParameters,
  TaskQueryParameters,
  UserQueryParameters,
  ServiceAppointmentQueryParameters,
} from '@quorum/models/xs-query';
import { Conversation, Sale, Employee, Event, Member, ProspectReport } from '@quorum/models/xs-resource';
import { fromProspects } from '@quorum/xsr-entities/state';
import { fromUi } from '@quorum/xsr-salesplanner-legacy/state';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { combineLatest, from, of, race } from 'rxjs';
import { filter, map, mergeAll, switchMap, take } from 'rxjs/operators';
import * as fromDealerPromotions from './activities/dealer-promotions/dealer-promotions.actions';
import * as fromDeliveries from './activities/deliveries/deliveries.actions';
import * as fromSalesAppointments from './activities/sales-appointments/sales-appointments.actions';
import * as fromServiceAppointments from './activities/service-appointments/service-appointments.actions';
import * as fromTasks from './activities/tasks/tasks.actions';
import { LocalSettingsState } from './local-settings/local-settings.interfaces';
import { LocalSettingsStateService } from './local-settings/local-settings.state.service';
import { SalesPlannerStateService } from './sales-planner.state.service';
import * as fromSalesplanner from './salesplanner-legacy.actions';
import { SalesplannerLegacyState } from './salesplanner-legacy.interfaces';
import * as fromSelectedProspectActions from './selected-activity/prospect-actions/prospect-actions.actions';
import * as fromSelectedProspectNotes from './selected-activity/prospect-notes/prospect-notes.actions';
// eslint-disable-next-line max-len
import * as fromSelectedProspectSalesAppointments from './selected-activity/prospect-sales-appointments/prospect-sales-appointments.actions';
import * as fromSelectedProspectTasks from './selected-activity/prospect-tasks/prospect-tasks.actions';
import * as fromSelectedProspectResponseLeads from './selected-activity/response-leads/response-leads.actions';
import * as fromSelectedActivity from './selected-activity/selected-activity.actions';
import * as fromSelectedProspectVehicles from './selected-activity/vehicles/vehicles.actions';
import { UiStateService } from './ui/ui-state-service';

@Injectable()
export class SalesplannerLegacyEffects {
  previousDetail: any = null;

  test = createEffect(() =>
    this.dataPersistence.navigation(ShellComponent, {
      run: (a: ActivatedRouteSnapshot, state: any) => {
        return race(
          this.router.events.pipe(
            filter((events) => {
              return events instanceof NavigationEnd && events.urlAfterRedirects === state.router.state.url;
            }),
            map((event) => {
              return { action: 'finished', event };
            })
          ),
          this.router.events.pipe(
            filter((events) => events instanceof NavigationCancel && events.url === state.router.state.url),
            map((event) => {
              return { action: 'cancelled', event };
            })
          )
        ).pipe(
          switchMap((event) => {
            if (event.action === 'cancelled') {
              return from([]);
            } else {
              return this.authenticationStateService.selectAuthenticatedEmployee().pipe(
                filter((employee) => employee !== null),
                switchMap((employee: Employee) => {
                  const localSettings: LocalSettingsState = state.salesplannerLegacy.localSettings;
                  let actions: Action[] = [];
                  if (
                    a.children.find((child) => child.component === MasterComponent) &&
                    state.salesplannerLegacy.featureLoaded == false
                  ) {
                    let dateFrom = new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate() - localSettings.rangePast
                    ).toISOString();

                    let dateTo = new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      new Date().getDate() + localSettings.range + 1
                    ).toISOString();

                    actions.push(new fromSalesplanner.UpdateFeatureLoadingInState({ loaded: true }));

                    let queryParams = new ProspectQueryParameters({
                      employeeId: localSettings.salesPeople,
                      statusId: localSettings.prospectFilter.status,
                      typeId: localSettings.prospectFilter.typeId,
                      page: 0,
                      pageSize: 50,
                      sort: localSettings.prospectFilter.sort,
                      embed: ['associate', 'primaryEmployeeAssociate', 'vehicle', 'address'],
                    });

                    if (localSettings.prospectFilter.channel.length > 0) {
                      queryParams = { ...queryParams, channel: localSettings.prospectFilter.channel };
                    }

                    if (localSettings.prospectFilter.source.length > 0) {
                      queryParams = { ...queryParams, source: localSettings.prospectFilter.source };
                    }

                    actions.push(new fromProspects.GetProspectsFromServer(queryParams));

                    actions.push(new fromUi.GetUnassignedProspectLeadsFromServer({}));
                    actions.push(new fromUi.GetUnmatchedProspectLeadsFromServer({}));

                    if (localSettings.activitiesFilter.includes('A')) {
                      actions.push(
                        new fromSalesAppointments.GetSalesAppointmentsFromServer(
                          new AppointmentQueryParameters({
                            appointmentDateFrom: dateFrom,
                            appointmentDateTo: dateTo,
                            taskEmployeeId: localSettings.salesPeople
                              ? localSettings.salesPeople
                              : state.authentication.authenticatedUser.user.xselleratorEmployeeId,
                            embed: ['prospect', 'taskEmployeeAssociate', 'employeeAssociate'],
                          })
                        )
                      );
                    } else {
                      actions.push(new fromSalesAppointments.ClearSalesAppointmentsFromState());
                    }

                    if (localSettings.activitiesFilter.includes('P')) {
                      actions.push(
                        new fromDealerPromotions.GetDealerPromotionsFromServer(
                          new DealerPromotionQueryParameters({
                            dueDateFrom: dateFrom,
                            dueDateTo: dateTo,
                            taskEmployeeId: localSettings.salesPeople
                              ? localSettings.salesPeople
                              : state.authentication.authenticatedUser.user.xselleratorEmployeeId,
                            embed: ['vehicle', 'prospect', 'dealerPromotionHeader', 'taskEmployeeAssociate'],
                            open: '1',
                          })
                        )
                      );
                    } else {
                      actions.push(new fromDealerPromotions.ClearDealerPromotionsFromState());
                    }
                    if (localSettings.activitiesFilter.includes('S')) {
                      actions.push(
                        new fromServiceAppointments.GetServiceAppointmentsFromServer(
                          new ServiceAppointmentQueryParameters({
                            appointmentDateFrom: dateFrom,
                            appointmentDateTo: dateTo,
                            salespersonId: localSettings.salesPeople,
                            embed: 'customerAssociate,taskEmployeeAssociate,serviceAdvisorAssociate,vehicle',
                          })
                        )
                      );
                    } else {
                      actions.push(new fromServiceAppointments.ClearServiceAppointmentsFromState());
                    }

                    if (localSettings.activitiesFilter.includes('T')) {
                      actions.push(
                        new fromTasks.GetDealerTasksFromServer(
                          new TaskQueryParameters({
                            dueDateFrom: dateFrom,
                            dueDateTo: dateTo,
                            taskEmployeeId: localSettings.salesPeople
                              ? localSettings.salesPeople
                              : state.authentication.authenticatedUser.user.xselleratorEmployeeId,
                            embed: 'prospect,taskEmployeeAssociate',
                            open: '1',
                            pageSize: 2000,
                          })
                        )
                      );
                    } else {
                      actions.push(new fromTasks.ClearTasksFromState());
                    }

                    if (localSettings.activitiesFilter.includes('D')) {
                      actions.push(
                        new fromDeliveries.GetDeliveriesFromServer(
                          new SaleQueryParameters({
                            salespersonId: localSettings.salesPeople,
                            embed: 'vehicle,customerAssociate,status,salespersonAssociate',
                            deliveryDateFrom: dateFrom,
                            deliveryDateTo: dateTo,
                            statusId: 'S,2',
                          })
                        )
                      );
                    } else {
                      actions.push(new fromDeliveries.ClearDeliveriesFromState());
                    }

                    actions.push(new fromAssociateClasses.GetAssociateClassesFromServer({}));
                    actions.push(new fromChannels.GetChannelsFromServer({}));
                    actions.push(new fromSources.GetSourcesFromServer({}));
                    actions.push(new fromRooftops.GetRooftopsFromServer({}));
                    actions.push(new fromSalespeople.GetSalespeopleFromServer({ employeeId: employee.associateId }));
                    actions.push(new fromSalesTeams.GetSalesTeamsFromServer({}));
                    actions.push(new fromMessageTypes.GetAssociateMessageTypesFromServer({}));
                    actions.push(new fromCustomerTypes.GetAssociateCustomerTypesFromServer({}));
                    actions.push(new fromProspectActions.GetProspectActionsFromServer({}));
                    actions.push(new fromEmployees.GetEmployeesFromServer({}));
                    actions.push(new fromVendors.GetVendorsFromServer({}));

                    actions.push(
                      new fromTaskOutcomes.GetTaskOutcomesFromServer({
                        taskTypeId: 'A,F',
                        rooftopId: [employee.rooftopId.toString(), '-1'],
                      })
                    );
                  }

                  if (
                    a.children.find((child: any) =>
                      child.children.find(
                        (innerChild: any) =>
                          innerChild.component === DetailComponent ||
                          innerChild.component === VehicleSearchShellComponent ||
                          innerChild.component === AddProspectVehicleShellComponent
                      )
                    )
                  ) {
                    let detailComponent = a.children.find((child: any) =>
                      child.children.find(
                        (innerChild: any) =>
                          innerChild.component === DetailComponent ||
                          innerChild.component === VehicleSearchShellComponent ||
                          innerChild.component === AddProspectVehicleShellComponent
                      )
                    );

                    if (
                      this.previousDetail == null ||
                      (this.previousDetail.url.path != detailComponent.url &&
                        this.previousDetail.children[0].url[0].path != detailComponent.children[0].url[0].path) ||
                      (this.previousDetail.children[0].url[0]?.path == detailComponent.children[0].url[0]?.path &&
                        this.previousDetail.children[0].url[1]?.path != detailComponent.children[0].url[1]?.path)
                    ) {
                      console.log('we need to load detail!');
                      this.previousDetail = detailComponent;

                      if (
                        !detailComponent.children[0].url[1] ||
                        (detailComponent.children[0].url[1] &&
                          detailComponent.children[0].url[1].path !== 'vehicle-search')
                      ) {
                        actions.push(new fromSelectedActivity.ResetSelectedActivityState(null));
                      }
                      switch (detailComponent.url[0].path) {
                        case 'appointments':
                          actions.push(
                            new fromSalesplanner.LoadAppointmentDetail({
                              appointmentId: detailComponent.children[0].params.id,
                            })
                          );
                          break;
                        case 'tasks':
                          actions.push(
                            new fromSalesplanner.LoadTaskDetail({
                              taskId: detailComponent.children[0].params.id,
                            })
                          );
                          break;
                        case 'service-appointments':
                          actions.push(
                            new fromSalesplanner.LoadServiceAppointmentDetail({
                              appointmentId: detailComponent.children[0].params.id,
                            })
                          );
                          break;
                        case 'dealer-promotions':
                          actions.push(
                            new fromSalesplanner.LoadDealerPromotionDetail({
                              dealerPromotionId: detailComponent.children[0].params.id,
                              dealerPromotionSequence: detailComponent.children[0].params.sequence,
                            })
                          );
                          break;
                        case 'deliveries':
                          actions.push(
                            new fromSalesplanner.LoadDeliveryDetail({
                              deliveryId: detailComponent.children[0].params.id,
                            })
                          );
                          break;
                        case 'prospects':
                          actions.push(
                            new fromSalesplanner.LoadProspectDetail({
                              prospectId: detailComponent.children[0].params.id,
                            })
                          );
                          break;
                      }
                    }
                  }

                  return of(actions).pipe(mergeAll());
                })
              );
            }
          })
        );
      },
      onError: (a: ActivatedRouteSnapshot, e: any) => {
        console.log(e);
        // we can log and error here and return null
        // we can also navigate back
        return null;
      },
    })
  );

  logoutSuccess = createEffect(() =>
    this.dataPersistence.fetch(fromAuthentication.LOGOUT_SUCCESS, {
      run: (a: fromAuthentication.LogoutSuccess, state: any) => {
        this.uiStateService.toggleSidenav(false);
        return from([
          new fromAuthentication.AddAuthenticationStages([
            {
              id: 1,
              task: 'Authenticate User',
              authenticationStatus: AuthenticationStatus.pending,
            },
            {
              id: 2,
              task: 'Checking Versions',
              authenticationStatus: AuthenticationStatus.pending,
            },
            {
              id: 3,
              task: 'Pulling Newest Version',
              authenticationStatus: AuthenticationStatus.optional,
            },
            {
              id: 4,
              task: 'Retrieving User Profile',
              authenticationStatus: AuthenticationStatus.pending,
            },
            {
              id: 5,
              task: 'Configuring SalesPlanner',
              authenticationStatus: AuthenticationStatus.pending,
            },
          ]),
        ]);
      },
      onError: (a: fromAuthentication.LogoutSuccess, error) => {
        return new fromAuthentication.LogoutError(error);
      },
    })
  );

  loadAppointmentDetail = createEffect(() =>
    this.dataPersistence.fetch(fromSalesplanner.LOAD_APPOINTMENT_DETAIL, {
      run: (a: fromSalesplanner.LoadAppointmentDetail, state: any) => {
        let activityState: any;
        let actions: Action[] = [];

        activityState = state.salesplannerLegacy.activities.salesAppointments.entities[a.payload.appointmentId];
        if (activityState) {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServerSuccess({
              type: 'Appointment',
              activity: activityState,
            })
          );
        } else {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServer({
              id: a.payload.appointmentId,
              activityType: 'Appointment',
              embed: 'prospect,taskEmployeeAssociate,employeeAssociate',
            })
          );
        }

        if (activityState) {
          actions.push(
            new fromSelectedActivity.GetSelectedProspectFromServer(
              new ProspectQueryParameters({
                id: activityState.prospectId,
                embed: ['associate', 'primaryEmployeeAssociate'],
              })
            )
          );

          actions.push(...this.selectedProspectActions(activityState.prospectId));
          return of(actions).pipe(mergeAll());
        } else {
          return this.apiService
            .get(`v/1/crm/appointments/${a.payload.appointmentId}`, {
              embed: 'associate,primaryEmployeeAssociate',
            })
            .pipe(
              switchMap((appointment) => {
                actions.push(
                  new fromSelectedActivity.GetSelectedProspectFromServer(
                    new ProspectQueryParameters({
                      id: appointment.prospectId,
                      embed: ['associate', 'primaryEmployeeAssociate'],
                    })
                  )
                );

                actions.push(...this.selectedProspectActions(appointment.prospectId));
                return of(actions).pipe(mergeAll());
              })
            );
        }
        //might have to wrap all this in if prospect exists
      },
      onError: (a: fromSalesplanner.LoadAppointmentDetail, error) => {
        //return new fromAuthentication.LogoutError(error);
      },
    })
  );

  loadTaskDetail = createEffect(() =>
    this.dataPersistence.fetch(fromSalesplanner.LOAD_TASK_DETAIL, {
      run: (a: fromSalesplanner.LoadTaskDetail, state: any) => {
        let activityState: any;
        let actions: Action[] = [];

        activityState = state.salesplannerLegacy.activities.tasks.entities[a.payload.taskId];
        if (activityState) {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServerSuccess({
              type: 'Task',
              activity: activityState,
            })
          );
        } else {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServer({
              id: a.payload.taskId,
              activityType: 'Task',
            })
          );
        }

        if (activityState) {
          actions.push(
            new fromSelectedActivity.GetSelectedProspectFromServer(
              new ProspectQueryParameters({
                id: activityState.prospectId,
                embed: ['associate', 'primaryEmployeeAssociate'],
              })
            )
          );

          actions.push(...this.selectedProspectActions(activityState.prospectId));
          return of(actions).pipe(mergeAll());
        } else {
          return this.apiService
            .get(`v/1/crm/tasks/${a.payload.taskId}`, {
              embed: ['associate', 'primaryEmployeeAssociate'],
            })
            .pipe(
              switchMap((task) => {
                actions.push(
                  new fromSelectedActivity.GetSelectedProspectFromServer(
                    new ProspectQueryParameters({
                      id: task.prospectId,
                      embed: ['associate', 'primaryEmployeeAssociate'],
                    })
                  )
                );

                actions.push(...this.selectedProspectActions(task.prospectId));
                return of(actions).pipe(mergeAll());
              })
            );
        }
        //might have to wrap all this in if prospect exists
      },
      onError: (a: fromSalesplanner.LoadTaskDetail, error) => {
        //return new fromAuthentication.LogoutError(error);
      },
    })
  );

  loadServiceAppointmentDetail = createEffect(() =>
    this.dataPersistence.fetch(fromSalesplanner.LOAD_SERVICE_APPOINTMENT_DETAIL, {
      run: (a: fromSalesplanner.LoadServiceAppointmentDetail, state: any) => {
        let actions: Action[] = [];

        actions.push(
          new fromSelectedActivity.GetSelectedActivityFromServer({
            id: a.payload.appointmentId,
            activityType: 'Service',
            embed: 'customerAssociate,serviceAdvisorAssociate,vehicle,cases',
          })
        );

        return this.apiService.get(`v/1/fixed-operations/appointments/${a.payload.appointmentId}`, {}).pipe(
          switchMap((appointment) => {
            let query = new ProspectQueryParameters({
              associateId: appointment.customerId,
              vehicleId: appointment.vehicleId,
            });
            return this.apiService.get(`v/1/crm/prospects`, { params: query }).pipe(
              map((prospect) => prospect[0]),
              switchMap((prospect) => {
                if (prospect) {
                  actions.push(
                    new fromSelectedActivity.GetSelectedProspectFromServer(
                      new ProspectQueryParameters({
                        id: prospect.id,
                        embed: ['associate', 'primaryEmployeeAssociate'],
                      })
                    )
                  );
                  actions.push(...this.selectedProspectActions(prospect.id));
                } else {
                  actions.push(new fromSelectedActivity.GetSelectedProspectFromServerFailure({ error: '' }));
                  actions.push(
                    new fromSelectedProspectVehicles.GetSelectedProspectVehiclesFromServer({
                      associateId: appointment.customerId,
                    })
                  );
                }
                return of(actions).pipe(mergeAll());
              })
            );
          })
        );

        //might have to wrap all this in if prospect exists
      },
      onError: (a: fromSalesplanner.LoadServiceAppointmentDetail, error) => {
        //return new fromAuthentication.LogoutError(error);
      },
    })
  );

  loadDealerPromotionDetail = createEffect(() =>
    this.dataPersistence.fetch(fromSalesplanner.LOAD_DEALER_PROMOTION_DETAIL, {
      run: (a: fromSalesplanner.LoadDealerPromotionDetail, state: any) => {
        let activityState: any;
        let actions: Action[] = [];

        activityState =
          state.salesplannerLegacy.activities.dealerPromotions.entities[
            a.payload.dealerPromotionId + ':' + a.payload.dealerPromotionSequence
          ];
        if (activityState) {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServerSuccess({
              type: 'Promotion',
              activity: activityState,
            })
          );
        } else {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServer({
              id: a.payload.dealerPromotionId,
              sequence: a.payload.dealerPromotionSequence,
              activityType: 'Promotion',
            })
          );
        }

        if (activityState) {
          let query: any = {};

          if (activityState.prospectId) {
            query = new ProspectQueryParameters({
              id: activityState.prospectId,
              embed: ['associate', 'primaryEmployeeAssociate'],
            });
          } else {
            query = new ProspectQueryParameters({
              associateId: activityState.embedded.vehicle.embedded.associate.id,
              vehicleId: activityState.vehicleId,
              embed: ['associate', 'primaryEmployeeAssociate'],
            });
          }

          return this.apiService.get(`v/1/crm/prospects`, { params: query }).pipe(
            map((prospect) => prospect[0]),
            switchMap((prospect) => {
              if (prospect) {
                actions.push(
                  new fromSelectedActivity.GetSelectedProspectFromServer(
                    new ProspectQueryParameters({
                      id: prospect.id,
                      embed: ['associate', 'primaryEmployeeAssociate'],
                    })
                  )
                );
                actions.push(
                  new fromSelectedActivity.GetSelectedProspectAggregateFromServer({
                    prospectId: prospect.id,
                  })
                );

                actions.push(...this.selectedProspectActions(prospect.id));
              }
              return of(actions).pipe(mergeAll());
            })
          );
        } else {
          return this.apiService
            .get(`v/1/crm/dealer-promotion-tasks/${a.payload.dealerPromotionId}/${a.payload.dealerPromotionSequence}`, {
              params: {
                embed: 'vehicle,dealerPromotionHeader',
              },
            })
            .pipe(
              switchMap((dealerPromo) => {
                let query: any = {};

                if (dealerPromo.prospectId) {
                  query = new ProspectQueryParameters({
                    id: dealerPromo.prospectId,

                    embed: ['associate', 'primaryEmployeeAssociate'],
                  });
                } else {
                  query = new ProspectQueryParameters({
                    associateId: dealerPromo.embedded.vehicle.embedded.associate.id,
                    vehicleId: dealerPromo.vehicleId,
                    embed: ['associate', 'primaryEmployeeAssociate'],
                  });
                }

                return this.apiService.get(`v/1/crm/prospects`, { params: query }).pipe(
                  map((prospect) => prospect[0]),
                  switchMap((prospect) => {
                    if (prospect) {
                      actions.push(
                        new fromSelectedActivity.GetSelectedProspectFromServer(
                          new ProspectQueryParameters({
                            id: prospect.id,
                            embed: ['associate', 'primaryEmployeeAssociate'],
                          })
                        )
                      );
                      actions.push(...this.selectedProspectActions(prospect.id));
                    }
                    return of(actions).pipe(mergeAll());
                  })
                );
              })
            );
        }

        //might have to wrap all this in if prospect exists
      },
      onError: (a: fromSalesplanner.LoadDealerPromotionDetail, error) => {
        //return new fromAuthentication.LogoutError(error);
      },
    })
  );

  loadDeliveryDetail = createEffect(() =>
    this.dataPersistence.fetch(fromSalesplanner.LOAD_DELIVERY_DETAIL, {
      run: (a: fromSalesplanner.LoadDeliveryDetail, state: any) => {
        let activityState: Sale;
        let actions: Action[] = [];
        let prospectId: any;

        activityState = state.salesplannerLegacy.activities.deliveries.entities[a.payload.deliveryId];
        if (activityState) {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServerSuccess({
              type: 'Delivery',
              activity: activityState,
            })
          );
        } else {
          actions.push(
            new fromSelectedActivity.GetSelectedActivityFromServer({
              id: a.payload.deliveryId,
              activityType: 'Delivery',
            })
          );
        }

        if (activityState) {
          prospectId =
            activityState.statusId === 'S' || activityState.statusId === 'P'
              ? activityState.retentionProspectId
              : activityState.showroomProspectId;

          actions.push(
            new fromSelectedActivity.GetSelectedProspectFromServer(
              new ProspectQueryParameters({
                id: prospectId,
                embed: ['associate', 'primaryEmployeeAssociate'],
              })
            )
          );

          actions.push(...this.selectedProspectActions(prospectId));
          return of(actions).pipe(mergeAll());
        } else {
          return this.apiService
            .get(`v/1/vehicles/sales/${a.payload.deliveryId}`, {
              embed: 'associate,primaryEmployeeAssociate',
            })
            .pipe(
              switchMap((delivery: Sale) => {
                prospectId =
                  delivery.statusId === 'S' || delivery.statusId === 'P'
                    ? delivery.retentionProspectId
                    : delivery.showroomProspectId;

                actions.push(
                  new fromSelectedActivity.GetSelectedProspectFromServer(
                    new ProspectQueryParameters({
                      id: prospectId,
                      embed: ['associate', 'primaryEmployeeAssociate'],
                    })
                  )
                );

                actions.push(...this.selectedProspectActions(prospectId));
                return of(actions).pipe(mergeAll());
              })
            );
        }

        //might have to wrap all this in if prospect exists
      },
      onError: (a: fromSalesplanner.LoadDeliveryDetail, error) => {
        //return new fromAuthentication.LogoutError(error);
      },
    })
  );

  loadProspectDetail = createEffect(() =>
    this.dataPersistence.fetch(fromSalesplanner.LOAD_PROSPECT_DETAIL, {
      run: (a: fromSalesplanner.LoadProspectDetail, state: any) => {
        let actions: Action[] = [];

        actions.push(
          new fromSelectedActivity.GetSelectedProspectFromServer(
            new ProspectQueryParameters({
              id: a.payload.prospectId.toString(),
              embed: ['associate', 'primaryEmployeeAssociate', 'closedByAssociate'],
            })
          )
        );

        actions.push(...this.selectedProspectActions(a.payload.prospectId));
        return of(actions).pipe(mergeAll());
      },
      onError: (a: fromSalesplanner.LoadProspectDetail, error) => {},
    })
  );

  CreateProspectAndAssociateOnServerSuccess = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS, {
      run: (action: fromProspects.CreateProspectAndAssociateOnServerSuccess, state: any) => {
        const actions: Action[] = [];
        const settings = state.salesplannerLegacy.localSettings;
        if (
          settings.prospectFilter.status.includes(Number(action.payload.prospect.statusId)) &&
          settings.prospectFilter.typeId.includes(action.payload.prospect.typeId) &&
          (settings.prospectFilter.channel.length > 0
            ? settings.prospectFilter.channel.includes(action.payload.prospect.channelId)
            : true) &&
          (settings.prospectFilter.source.length > 0
            ? settings.prospectFilter.source.includes(action.payload.prospect.sourceId)
            : true)
        ) {
          actions.push(new fromProspects.AddProspectToState(action.payload.prospect));
        }
        if (action.payload.salespersonTakeoverMessage != null) {
          combineLatest(
            this.authenticationStateService.selectAuthenticatedUser(),
            this.authenticationStateService.selectAuthenticatedEmployee(),
            this.systemControlStateService
              .getSystemControlValues(['CL_COMMUNICATOR_ENABLE'], action.payload.prospect.rooftopId)
              .pipe(take(1))
          ).subscribe(([authenticatedUser, authenticatedEmployee, systemControls]) => {
            if (systemControls.CL_COMMUNICATOR_ENABLE.value === '1') {
              const members: Member[] = [];
              const event: Event = new Event();
              event.conversationId = null;
              event.memberId = authenticatedUser.user.id.toString();
              event.eventTypeId = EventTypes.Message;
              event.content = action.payload.salespersonTakeoverMessage;
              event.guid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
              event.sentDate = new Date();
              event.sent = false;
              event.sendToCustomer = false;
              event.id = Math.floor(Math.random() * (9999999 - 999999)) + 999999;

              const conversation: Conversation = new Conversation();
              conversation.dmsTransactionTypeId = 5;
              conversation.dmsTransactionId = action.payload.prospect.id.toString();
              conversation.dmsMessageTypeId = 4;

              const conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                transactionTypeId: 5,
                messageTypeId: 4,
                transactionId: action.payload.prospect.id.toString(),
              });

              const member: Member = new Member();
              member.userId = authenticatedUser.user.id.toString();
              member.avatarUrl = authenticatedUser.user.avatarUrl;
              member.firstName = authenticatedUser.user.firstName;
              member.lastName = authenticatedUser.user.lastName;
              member.nickname = authenticatedUser.user.firstName;
              member.isEmployee = true;
              member.isRead = true;
              member.isArchived = false;
              member.isExcluded = false;
              member.isPinnedToUi = false;
              members.push(member);

              const optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'true' });

              this.apiService
                .get<any>('v/1/common/transaction-types/5/workflow/communicatorUsernames', {
                  params: {
                    rooftopId: authenticatedEmployee.rooftopId,
                    transactionId: action.payload.prospect.id,
                    automatedMessageTypeId: 4,
                    transactionTypeId: 5,
                  },
                })
                .pipe(
                  switchMap((usernames: any) => {
                    const usernameList = usernames.map((u: any) => u.username).join(',');
                    const userParams: UserQueryParameters = new UserQueryParameters({ username: usernameList });

                    return this.apiService.get<any>('quorum/users', { params: userParams }).pipe(
                      map((users) => {
                        Object.keys(users).forEach((key) => {
                          const user = users[key];

                          Object.keys(usernames).forEach((key) => {
                            const username = usernames[key].username;
                            if (user.username.toLowerCase() == username.toLowerCase()) {
                              const member: Member = new Member();
                              member.userId = user.id.toString();
                              member.avatarUrl = user.avatarUrl;
                              member.firstName = user.firstName;
                              member.lastName = user.lastName;
                              member.nickname = user.firstName;
                              member.isEmployee = true;
                              member.isRead = true;
                              member.isArchived = false;
                              member.isExcluded = false;
                              member.isPinnedToUi = false;
                              members.push(member);
                            }
                          });
                        });
                      })
                    );
                  })
                )
                .subscribe((result) => {
                  this.communicatorFacade.sendCommunicatorMessage(
                    conversationQueryParameters,
                    conversation,
                    members,
                    event,
                    optionalHeader
                  );
                });
            }
          });
        }
        return of(actions).pipe(mergeAll());
      },
      onError: (action: fromProspects.CreateProspectAndAssociateOnServerSuccess, error) => {
        console.error('Error', error);
      },
    })
  );

  CreateProspectOnServerSuccess = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.CREATE_PROSPECT_ON_SERVER_SUCCESS, {
      run: (action: fromProspects.CreateProspectOnServerSuccess, state: any) => {
        const actions: Action[] = [];
        const settings = state.salesplannerLegacy.localSettings;
        if (
          settings.prospectFilter.status.includes(Number(action.payload.prospect.statusId)) &&
          settings.prospectFilter.typeId.includes(action.payload.prospect.typeId) &&
          (settings.prospectFilter.channel.length > 0
            ? settings.prospectFilter.channel.includes(action.payload.prospect.channelId)
            : true) &&
          (settings.prospectFilter.source.length > 0
            ? settings.prospectFilter.source.includes(action.payload.prospect.sourceId)
            : true)
        ) {
          actions.push(new fromProspects.AddProspectToState(action.payload.prospect));
        }
        if (action.payload.salespersonTakeoverMessage != null) {
          combineLatest(
            this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
            this.authenticationStateService.selectAuthenticatedEmployee().pipe(take(1)),
            this.systemControlStateService
              .getSystemControlValues(['CL_COMMUNICATOR_ENABLE'], state.authentication.employee.rooftopId)
              .pipe(take(1))
          ).subscribe(([authenticatedUser, authenticatedEmployee, systemControls]) => {
            if (systemControls.CL_COMMUNICATOR_ENABLE.value === '1') {
              const members: Member[] = [];

              const event: Event = new Event();
              event.conversationId = null;
              event.memberId = authenticatedUser.user.id.toString();
              event.eventTypeId = EventTypes.Message;
              event.content = action.payload.salespersonTakeoverMessage;
              event.guid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
              event.sentDate = new Date();
              event.sent = false;
              event.sendToCustomer = false;
              event.id = Math.floor(Math.random() * (9999999 - 999999)) + 999999;

              const conversation: Conversation = new Conversation();
              conversation.dmsTransactionTypeId = 5;
              conversation.dmsTransactionId = action.payload.prospect.id.toString();
              conversation.dmsMessageTypeId = 4;

              const conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                transactionTypeId: 5,
                messageTypeId: 4,
                transactionId: action.payload.prospect.id.toString(),
              });

              const member: Member = new Member();
              member.userId = authenticatedUser.user.id.toString();
              member.avatarUrl = authenticatedUser.user.avatarUrl;
              member.firstName = authenticatedUser.user.firstName;
              member.lastName = authenticatedUser.user.lastName;
              member.nickname = authenticatedUser.user.firstName;
              member.isEmployee = true;
              member.isRead = true;
              member.isArchived = false;
              member.isExcluded = false;
              member.isPinnedToUi = false;
              members.push(member);

              const optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'true' });

              this.apiService
                .get<any>('v/1/common/transaction-types/5/workflow/communicatorUsernames', {
                  params: {
                    rooftopId: authenticatedEmployee.rooftopId,
                    transactionId: action.payload.prospect.id,
                    automatedMessageTypeId: 4,
                    transactionTypeId: 5,
                  },
                })
                .pipe(
                  switchMap((usernames: any) => {
                    const usernameList = usernames.map((u: any) => u.username).join(',');
                    const userParams: UserQueryParameters = new UserQueryParameters({ username: usernameList });
                    return this.apiService.get<any>('quorum/users', { params: userParams }).pipe(
                      map((users) => {
                        Object.keys(users).forEach((key) => {
                          const user = users[key];

                          Object.keys(usernames).forEach((key) => {
                            const username = usernames[key].username;
                            if (user.username.toLowerCase() == username.toLowerCase()) {
                              const member: Member = new Member();
                              member.userId = user.id.toString();
                              member.avatarUrl = user.avatarUrl;
                              member.firstName = user.firstName;
                              member.lastName = user.lastName;
                              member.nickname = user.firstName;
                              member.isEmployee = true;
                              member.isRead = true;
                              member.isArchived = false;
                              member.isExcluded = false;
                              member.isPinnedToUi = false;
                              members.push(member);
                            }
                          });
                        });
                      })
                    );
                  })
                )
                .subscribe((result) => {
                  this.communicatorFacade.sendCommunicatorMessage(
                    conversationQueryParameters,
                    conversation,
                    members,
                    event,
                    optionalHeader
                  );
                });
            }
          });
        }
        return of(actions).pipe(mergeAll());
      },
      onError: (action: fromProspects.CreateProspectOnServerSuccess, error) => {
        console.error('Error', error);
      },
    })
  );

  selectedProspectActions(prospectId: number) {
    return [
      new fromSelectedProspectTasks.GetProspectTasksFromServer({
        prospectId: prospectId,
        embed: 'prospect,taskEmployeeAssociate,taskOutcome',
      }),
      new fromSelectedProspectSalesAppointments.GetProspectSalesAppointmentsFromServer({
        prospectId: prospectId,
        embed: ['prospect', 'taskEmployeeAssociate', 'employeeAssociate', 'taskOutcome'],
      }),
      new fromSelectedActivity.GetSelectedProspectAggregateFromServer({
        prospectId: prospectId.toString(),
      }),
      new fromSelectedProspectActions.GetSelectedProspectActionsFromServer({
        prospectId: prospectId,
        prospectActionQueryParams: new ProspectActionQueryParameters({
          embed: ['employeeAssociate', 'action'],
        }),
      }),
      new fromSelectedProspectNotes.GetSelectedProspectNotesFromServer({
        functionalAreaId: 'CP',
        sourceReference1Id: prospectId.toString(),
        sourceReference2Id: 'N',
      }),
      new fromSelectedProspectResponseLeads.GetSelectedProspectResponseLeadsFromServer({
        prospectId: prospectId.toString(),
      }),
    ];
  }

  updateProspectOnServerSuccess = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.UPDATE_PROSPECT_ON_SERVER_SUCCESS, {
      run: (action: fromProspects.UpdateProspectOnServerSuccess, state: any) => {
        let actions: Action[] = [];

        if (action.payload.displayToast) {
          let toastOptions: any = {
            message: `Prospect updated.`,
            actionText: null,
            options: { duration: 3000, horizontalPosition: 'left' },
          };

          this.salesplannerStateService.addToast(toastOptions);
        }

        let prospect: any = action.payload.prospect.changes;
        actions.push(
          new fromSelectedActivity.UpdateSelectedProspectInState({
            id: action.payload.prospect.id,
            changes: { ...action.payload.prospect.changes },
          })
        );
        if (action.payload.prospect.changes.statusId == '2') {
          actions.push(new fromSelectedActivity.GetSelectedProspectFromServerSuccess(prospect));
        }

        if (action.payload.salespersonTakeoverData != null) {
          actions.push(
            new fromSelectedProspectActions.CreateSelectedProspectActionOnServer({
              prospectAction: action.payload.salespersonTakeoverData.salespersonTakeoverProspectAction,
              prospect: prospect,
            })
          );
          combineLatest([
            this.authenticationStateService.selectAuthenticatedUser().pipe(take(1)),
            this.authenticationStateService.selectAuthenticatedEmployee().pipe(take(1)),
            this.systemControlStateService
              .getSystemControlValues(['CL_COMMUNICATOR_ENABLE'], state.authentication.employee.rooftopId)
              .pipe(take(1)),
          ]).subscribe(([authenticatedUser, authenticatedEmployee, systemControls]) => {
            if (systemControls.CL_COMMUNICATOR_ENABLE.value === '1') {
              const members: Member[] = [];

              const event: Event = new Event();
              event.conversationId = null;
              event.memberId = authenticatedUser.user.id.toString();
              event.eventTypeId = EventTypes.Message;
              event.content = action.payload.salespersonTakeoverData.salespersonTakeoverMessage;
              event.guid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
              event.sentDate = new Date();
              event.sent = false;
              event.sendToCustomer = false;
              event.id = Math.floor(Math.random() * (9999999 - 999999)) + 999999;

              const conversation: Conversation = new Conversation();
              conversation.dmsTransactionTypeId = 5;
              conversation.dmsTransactionId = action.payload.prospect.id.toString();
              conversation.dmsMessageTypeId = 4;

              let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                transactionTypeId: 5,
                messageTypeId: 4,
                transactionId: action.payload.prospect.id.toString(),
              });

              const member: Member = new Member();
              member.userId = authenticatedUser.user.id.toString();
              member.avatarUrl = authenticatedUser.user.avatarUrl;
              member.firstName = authenticatedUser.user.firstName;
              member.lastName = authenticatedUser.user.lastName;
              member.nickname = authenticatedUser.user.firstName;
              member.isEmployee = true;
              member.isRead = true;
              member.isArchived = false;
              member.isExcluded = false;
              member.isPinnedToUi = false;
              members.push(member);

              let optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'true' });

              this.apiService
                .get<any>('v/1/common/transaction-types/5/workflow/communicatorUsernames', {
                  params: {
                    rooftopId: authenticatedEmployee.rooftopId,
                    transactionId: action.payload.prospect.id,
                    automatedMessageTypeId: 4,
                    transactionTypeId: 5,
                  },
                })
                .pipe(
                  switchMap((usernames: any) => {
                    let usernameList = usernames.map((u: any) => u.username).join(',');
                    let userParams: UserQueryParameters = new UserQueryParameters({ username: usernameList });
                    return this.apiService.get<any>('quorum/users', { params: userParams }).pipe(
                      map((users) => {
                        Object.keys(users).forEach((key) => {
                          const user = users[key];

                          Object.keys(usernames).forEach((key) => {
                            const username = usernames[key].username;
                            if (user.username.toLowerCase() == username.toLowerCase()) {
                              const member: Member = new Member();
                              member.userId = user.id.toString();
                              member.avatarUrl = user.avatarUrl;
                              member.firstName = user.firstName;
                              member.lastName = user.lastName;
                              member.nickname = user.firstName;
                              member.isEmployee = true;
                              member.isRead = true;
                              member.isArchived = false;
                              member.isExcluded = false;
                              member.isPinnedToUi = false;
                              members.push(member);
                            }
                          });
                        });
                      })
                    );
                  })
                )
                .subscribe((result) => {
                  this.communicatorFacade.sendCommunicatorMessage(
                    conversationQueryParameters,
                    conversation,
                    members,
                    event,
                    optionalHeader
                  );
                });
            }
          });
        }
        actions.push(new fromUi.GetUnassignedProspectLeadsFromServer({}));
        actions.push(new fromUi.GetUnmatchedProspectLeadsFromServer({}));
        return of(actions).pipe(mergeAll());
      },

      onError: (action: fromProspects.UpdateProspectOnServerSuccess, error) => {
        console.error('Error', error);
      },
    })
  );

  getUnassignedProspectLeadsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromUi.GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER, {
      run: (action: fromUi.GetUnassignedProspectLeadsFromServer, state: any) => {
        let unassignedProspectLeadQueryParams = new ProspectReportQueryParameters({
          rooftopId: state.authentication.employee.rooftopId.toString(),
          assigned: 'false',
          prospectStatusId: ['1'],
          sort: '-prospectEnteredDate',
        });
        return this.apiService
          .getWithHeaders<ProspectReport[]>(`v/1/reporting/crm/prospects`, {
            params: unassignedProspectLeadQueryParams,
          })
          .pipe(
            map((data) => {
              return new fromUi.GetUnassignedProspectLeadsFromServerSuccess(
                data.headers.total ? data.headers.total : 0
              );
            })
          );
      },
      onError: (action: fromUi.GetUnassignedProspectLeadsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getUnmatchedProspectLeadsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromUi.GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER, {
      run: (action: fromUi.GetUnmatchedProspectLeadsFromServer, state: any) => {
        let unmatchedProspectLeadQueryParams = new ProspectReportQueryParameters({
          rooftopId: state.authentication.employee.rooftopId.toString(),
          salespersonId: state.salesplannerLegacy.localSettings.salesPeople,
          matched: 'false',
          prospectStatusId: ['1'],
          sort: '-prospectEnteredDate',
        });
        return this.apiService
          .getWithHeaders<ProspectReport[]>(`v/1/reporting/crm/prospects`, { params: unmatchedProspectLeadQueryParams })
          .pipe(
            map((data) => {
              return new fromUi.GetUnmatchedProspectLeadsFromServerSuccess(data.headers.total ? data.headers.total : 0);
            })
          );
      },
      onError: (action: fromUi.GetUnmatchedProspectLeadsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  constructor(
    private readonly actions$: Actions,
    private apiService: ApiService,
    private dataPersistence: DataPersistence<SalesplannerLegacyState>,
    private authenticationStateService: AuthenticationStateService,
    private router: Router,
    private localSettingsStateService: LocalSettingsStateService,
    private activatedRoute: ActivatedRoute,
    private uiStateService: UiStateService,
    private communicatorService: CommunicatorService,
    private systemControlStateService: SystemControlStateService,
    private dropdownsStateService: DropdownsStateService,
    private salesplannerStateService: SalesPlannerStateService,
    private communicatorFacade: CommunicatorFacade
  ) {}
}
