import { Action } from '@ngrx/store';
import { SourceQueryParameters } from '@quorum/models/xs-query';
import { Source } from '@quorum/models/xs-resource';

export const GET_SOURCES_FROM_SERVER = '[CRM] Get Sources From Server';
export class GetSourcesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SOURCES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SourceQueryParameters) {}
}

export const GET_SOURCES_FROM_SERVER_SUCCESS = '[CRM] Get Sources From Server Success';
export class GetSourcesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SOURCES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { sources: Source[] }) {}
}

export const GET_SOURCES_FROM_SERVER_FAILURE = '[CRM] Get Sources From Server Failure';
export class GetSourcesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SOURCES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type SourcesAction = GetSourcesFromServer | GetSourcesFromServerSuccess | GetSourcesFromServerFailure;
