import * as fromVehicleCategories from './vehicle-categories.actions';
import { vehicleCategoriesAdapter, VehicleCategoriesState, initialState } from './vehicle-categories.interfaces';

export function reducer(
  state: VehicleCategoriesState = initialState,
  action: fromVehicleCategories.ChannelsAction
): VehicleCategoriesState {
  switch (action.type) {
    case fromVehicleCategories.GET_VEHICLE_CATEGORIES_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleCategoriesAdapter.addMany(action.payload.vehicleCategories, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
