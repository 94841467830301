import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DirectMessageTemplate } from '@quorum/models/xs-resource';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'sha-text-message-dialog',
  templateUrl: './text-message-dialog.component.html',
  styleUrls: ['./text-message-dialog.component.css'],
})
export class TextMessageDialogComponent implements OnInit {
  textMessageForm = this.fb.group({
    textMessageTemplates: [''],
    textMessageBody: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { directMessageTemplates: DirectMessageTemplate[] },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<TextMessageDialogComponent>
  ) {}

  ngOnInit() {
    this.textMessageForm.patchValue({
      textMessageTemplates: this.data.directMessageTemplates,
    });
  }

  setMessageBody(messageTemplate: DirectMessageTemplate) {
    this.textMessageForm.patchValue({
      textMessageBody: messageTemplate.message,
    });
  }
}
