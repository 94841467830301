import { WorkOrder } from '@quorum/models/xs-resource';
import { WorkOrderQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER = '[CRM] Get Vehicle Service History From Server';
export class GetVehicleServiceHistoryFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: WorkOrderQueryParameters) {}
}

export const GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Service History From Server Success';
export class GetVehicleServiceHistoryFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleServiceHistory: WorkOrder[] }) {}
}

export const GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Service History From Server Failure';
export class GetVehicleServiceHistoryFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_VEHICLE_SERVICE_HISTORY_FROM_STATE = '[CRM] Clear Vehicle Service History From State';
export class ClearVehicleServiceHistoryFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_VEHICLE_SERVICE_HISTORY_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export type VehicleServiceHistoryAction =
  | GetVehicleServiceHistoryFromServer
  | GetVehicleServiceHistoryFromServerSuccess
  | GetVehicleServiceHistoryFromServerFailure
  | ClearVehicleServiceHistoryFromState;
