import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/system-control.reducer';
import { SystemControlEffects } from './+state/system-control.effects';
import { SystemControlStateService } from './system-control-state.service';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('systemControl', reducer),
    EffectsModule.forFeature([SystemControlEffects])
  ],
  providers: [SystemControlStateService]
})
export class XsrSystemControlModule {}
