import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { CrmSettingsState } from '@quorum/crm-settings/state';
import { Employee, OemRooftopLink } from '@quorum/models/xs-resource';
import { UiStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SettingsDialogComponent } from '../settings-dialog/settings-dialog.component';

@Component({
  selector: 'crm-equity-mining-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit, AfterViewInit {
  dropdowns$: Observable<any[]>;
  sidenavStatus$: Subscription;
  sidenav: boolean;
  previousReportQueryParams$: Observable<any>;
  settings: CrmSettingsState;
  displayUnsoldReport: boolean = false;
  constructor(
    private dialog: MatDialog,
    private dropdownsStateService: DropdownsStateService,
    private titleService: Title,
    private uiStateService: UiStateService,
    private equityMiningStateService: EquityMiningStateService,
    public router: Router,
    public snackBar: MatSnackBar,
    private settingsStateService: CrmSettingsStateService,
    private systemControlStateService: SystemControlStateService,
    private authenticationStateService: AuthenticationStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.settingsStateService.selectSettingsFromState().subscribe((settings) => (this.settings = settings));
    this.dropdowns$ = this.dropdownsStateService.selectRooftops().pipe(
      map((rooftops) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
      take(1)
    );

    this.titleService.setTitle('Lead Generator');
    this.sidenavStatus$ = this.uiStateService.selectSidenavVisible().subscribe((res) => (this.sidenav = res));

    this.previousReportQueryParams$ = this.equityMiningStateService.getPreviousReportParams();

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => {
          return employee != null;
        }),
        take(1)
      )
      .subscribe((authEmployee: Employee) => {
        this.systemControlStateService
          .getSystemControlValue('CL_DISPLAY_LEAD_GENERATOR_UNSOLD_REPORT', authEmployee.rooftopId)
          .pipe(take(1))
          .subscribe((sct) => {
            this.displayUnsoldReport = sct.value == '1' ? true : false;
            this.cdr.detectChanges();
          });
      });
  }

  ngAfterViewInit() {}

  toggleSidenav() {
    this.uiStateService.toggleSidenav(!this.sidenav);
  }

  openSettingsDialog(settings: CrmSettingsState, rooftops: OemRooftopLink[]) {
    const dialogRef = this.dialog.open(SettingsDialogComponent, {
      width: '400px',
      data: { rooftops: rooftops, settings: settings },
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse.updated) {
        this.snackBar.open(`Lead Generator settings updated`, null, {
          duration: 3000,
          horizontalPosition: 'start',
        });
      }
    });
  }
}
