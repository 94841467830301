import { Injectable } from '@angular/core';
import { SubCategory } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromVehicleSubcategories from './vehicle-subcategories.actions';
import { VehicleSubcategoriesState } from './vehicle-subcategories.interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleSubcategoriesEffects {
  
  getVehicleSubcategoriesFromServer = createEffect(() => this.dataPersistence.fetch(
    fromVehicleSubcategories.GET_VEHICLE_SUBCATEGORIES_FROM_SERVER,
    {
      run: (action: fromVehicleSubcategories.GetVehicleSubcategoriesFromServer, state: VehicleSubcategoriesState) => {
        return this.apiService
          .get<SubCategory[]>(`v/1/vehicles/vehicle-sub-categories`, {
            params: action.payload
          })
          .pipe(
            map(vehicleSubcategories => {
              return new fromVehicleSubcategories.GetVehicleSubcategoriesFromServerSuccess({
                vehicleSubcategories: vehicleSubcategories
              });
            })
          );
      },
      onError: (action: fromVehicleSubcategories.GetVehicleSubcategoriesFromServer, error) => {
        console.error('Error', error);
      }
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleSubcategoriesState>,
    private apiService: ApiService
  ) {}
}
