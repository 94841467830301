<div *ngIf="localSettings$ | async as localSettings">
  <ng-container *ngIf="activities">
    <mat-nav-list *ngIf="overdue; else grouped">
      <mat-nav-list>
        <h3
          mat-subheader
          fxLayout="row"
          fxLayoutAlign="space-between center"
          (click)="overdueList.hidden = !overdueList.hidden"
        >
          <span class="mat-body-1">Overdue</span>
          <span>{{ activities.length }}</span>
        </h3>
        <div #overdueList hidden="true">
          <ng-container *ngFor="let activity of activities | orderBy: '-activityDate'; let i = index">
            <crm-activity-list-item
              [activity]="activity"
              (click)="selectActivity(activity, localSettings)"
              [overdue]="true"
              [combinedData]="combinedData"
              [selectedActivity$]="selectedActivity$"
              [localSettings$]="localSettings$"
            ></crm-activity-list-item>
            <mat-divider inset *ngIf="i < activities.length"></mat-divider>
          </ng-container>
        </div>
      </mat-nav-list>
    </mat-nav-list>
    <ng-template #grouped>
      <mat-nav-list>
        <ng-container *ngFor="let activityGroup of groupedActivities | pairs; let i = index">
          <h3 mat-subheader fxLayout="row" fxLayoutAlign="space-between center" (click)="toggleGroup(activityGroup[0])">
            <ng-container [ngSwitch]="activityGroup[1][0].activityDueDate | moment: 'dddd, MMMM Do, YYYY'">
              <span class="mat-body-1" *ngSwitchCase="yesterday">Yesterday</span>
              <span class="mat-body-1" *ngSwitchCase="today">Today</span>
              <span class="mat-body-1" *ngSwitchCase="tomorrow">Tomorrow</span>
              <span class="mat-body-1" *ngSwitchDefault>{{
                activityGroup[1][0].activityDueDate | moment: 'dddd, MMMM Do, YYYY'
              }}</span>
            </ng-container>
            <span>{{ activityGroup[1].length }}</span>
          </h3>
          <div
            #activityItems
            *ngIf="
              activityGroupExpandedStatus.hasOwnProperty(activityGroup[0])
                ? activityGroupExpandedStatus[activityGroup[0]]
                : false
            "
          >
            <ng-container
              *ngFor="
                let activity of activityGroup[1]
                  | activitiesFilter: ['Appointment', 'Service', 'Delivery']
                  | orderBy: ['activityDueDate'];
                let i = index
              "
            >
              <crm-activity-list-item
                [activity]="activity"
                [grouped]="true"
                [combinedData]="combinedData"
                [selectedActivity$]="selectedActivity$"
                [localSettings$]="localSettings$"
                (click)="selectActivity(activity, localSettings)"
              ></crm-activity-list-item>
              <mat-divider inset *ngIf="i < activityGroup[1].length - 1"></mat-divider>
            </ng-container>
            <ng-container
              *ngFor="
                let activity of activityGroup[1]
                  | activitiesFilter: ['Task', 'Promotion']
                  | orderBy: ['activityDueDate'];
                let i = index
              "
            >
              <crm-activity-list-item
                [activity]="activity"
                [grouped]="true"
                [combinedData]="combinedData"
                [selectedActivity$]="selectedActivity$"
                [localSettings$]="localSettings$"
                (click)="selectActivity(activity, localSettings)"
              ></crm-activity-list-item>
              <mat-divider inset *ngIf="i < activityGroup[1].length - 1"></mat-divider>
            </ng-container>
          </div>
        </ng-container>
      </mat-nav-list>
    </ng-template>
  </ng-container>
</div>
