import { combineReducers } from '@ngrx/store';
import { activitiesInitialState } from './activities.init';
import { ActivitiesState } from './activities.interfaces';
import { reducer as dealerPromotionsReducer } from './dealer-promotions/dealer-promotions.reducer';
import { reducer as deliveriesReducer } from './deliveries/deliveries.reducer';
import { reducer as salesAppointmentsReducer } from './sales-appointments/sales-appointments.reducer';
import { reducer as serviceAppointmentsReducer } from './service-appointments/service-appointments.reducer';
import { reducer as tasksReducer } from './tasks/tasks.reducer';
export function activitiesReducer(state: ActivitiesState = activitiesInitialState, action: any): ActivitiesState {
  switch (action.type) {
    case 'DATA_LOADED': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

const myReducers = {
  dealerPromotions: dealerPromotionsReducer,
  salesAppointments: salesAppointmentsReducer,
  tasks: tasksReducer,
  serviceAppointments: serviceAppointmentsReducer,
  deliveries: deliveriesReducer
};

export function createReducer(asyncReducers = {}) {
  let allReducers = Object.assign(myReducers, asyncReducers);
  return combineReducers(allReducers);
}

export function appReducer(state: any, action: any) {
  return createReducer()(state, action);
}

export const reducer = combineReducers(myReducers);
