import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'crm-prospect-list-filter',
  templateUrl: './prospect-list-filter.component.html',
  styleUrls: ['./prospect-list-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectListFilterComponent implements OnInit {
  @Input() queryParams$: BehaviorSubject<ProspectQueryParameters>;
  @Output() filtersChanged: EventEmitter<ProspectQueryParameters> = new EventEmitter<ProspectQueryParameters>();

  displayFilters = false;
  sources$: Observable<any>;
  channels$: Observable<any>;
  queryParams: ProspectQueryParameters;
  channelSelectAll: boolean = false;
  sourceSelectAll: boolean = false;
  statusSelectAll: boolean = false;
  typeSelectAll: boolean = false;
  channels: any;
  sources: any;

  statuses = [
    { id: 1, description: 'Open' },
    { id: 2, description: 'Closed' },
  ];
  types = [
    { id: 3, description: 'Showroom' },
    { id: 4, description: 'Retention' },
    { id: 6, description: 'Lead' },
  ];

  newSearchValue: Subject<string> = new Subject<string>();

  prospectFilterForm: FormGroup = this.formBuilder.group({
    search: [null],
    statusId: [null],
    typeId: [null],
    channelId: [null],
    sourceId: [null],
    sort: '-enteredDate',
  });

  constructor(
    private dropdownsStateService: DropdownsStateService,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    combineLatest(
      this.queryParams$.pipe(map((params) => new DeepCopyPipe().transform(params))),
      this.dropdownsStateService
        .selectSources()
        .pipe(filter((sources) => !this.dropdownsStateService.isEmpty(sources))),
      this.dropdownsStateService
        .selectChannels()
        .pipe(filter((channels) => !this.dropdownsStateService.isEmpty(channels)))
    ).subscribe(([params, sources, channels]) => {
      this.queryParams = params;
      this.prospectFilterForm.get('channelId').setValue(this.queryParams.channelId);
      if (this.queryParams.channelId && this.queryParams.channelId.length === channels.length) {
        this.channelSelectAll = true;
      }

      this.prospectFilterForm.get('sourceId').setValue(this.queryParams.sourceId);
      if (this.queryParams.sourceId && this.queryParams.sourceId.length === sources.length) {
        this.sourceSelectAll = true;
      }

      if (this.queryParams.statusId.length === this.statuses.length) {
        this.statusSelectAll = true;
      }
      this.prospectFilterForm.get('statusId').setValue(this.queryParams.statusId);
      if (this.queryParams.typeId.length === this.types.length) {
        this.typeSelectAll = true;
      }
      this.prospectFilterForm.get('typeId').setValue(this.queryParams.typeId);
      this.changeDetectorRef.detectChanges();
    });
    this.sources$ = this.dropdownsStateService.selectSources();
    this.channels$ = this.dropdownsStateService.selectChannels();

    this.prospectFilterForm
      .get('search')
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((searchValue: string) => {
        this.filtersChanged.emit({ ...this.queryParams, search: searchValue });
      });
  }

  apply(queryParams: ProspectQueryParameters) {
    this.displayFilters = !this.displayFilters;
    this.queryParams = { ...this.queryParams, ...queryParams, page: 0 };
    for (var propName in this.queryParams) {
      if (this.queryParams[propName] === null || this.queryParams[propName] === undefined) {
        delete this.queryParams[propName];
      }
    }
    this.filtersChanged.emit(this.queryParams);
  }

  toggleAllSelection(selectAll: any, formFieldName: string, values: any): void {
    let ids: number[] = [];
    values.map((value: any) => ids.push(value.id));
    if (selectAll) {
      this.prospectFilterForm.get(formFieldName).setValue(ids);
      selectAll = true;
    } else {
      this.prospectFilterForm.get(formFieldName).setValue([]);
      selectAll = false;
    }
  }
}
