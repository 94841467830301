import * as fromRooftops from './rooftops.actions';
import { initialState, rooftopsAdapter, RooftopsState } from './rooftops.interfaces';

export function reducer(state: RooftopsState = initialState, action: fromRooftops.RooftopsAction): RooftopsState {
  switch (action.type) {
    case fromRooftops.GET_ROOFTOPS_FROM_SERVER_SUCCESS: {
      return {
        ...rooftopsAdapter.addMany(action.payload.rooftops, state),
        isLoading: false
      };
    }

    default: {
      return state;
    }
  }
}
