import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapSelections',
})
export class MapSelectionsPipe implements PipeTransform {
  transform(selections: any[], masterList: any[], field?: string): any {
    if (selections && selections.length === masterList.length) {
      return 'All';
    } else if (selections && masterList && selections.length < masterList.length && selections.length > 1) {
      return 'Multiple Selected';
    } else {
      if (selections && selections.length > 0) {
        if (field && field === 'categoryId') {
          return masterList.find((item: any) => item.categoryId == selections[0]).description;
        } else if (field && field === 'vendorId') {
          return masterList.find((item: any) => item.associateId == selections[0]).name;
        } else {
          return masterList.find((item: any) => item.id == selections[0]).description;
        }
      }
    }
  }
}
