<div fxFlexFill fxLayout="column" *ngIf="dropdowns">
  <mat-toolbar class="mat-elevation-z2" style="z-index:1">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="backClicked()"
        style="height:40px;width:40px;border-radius:50%;color:rgba(0, 0, 0, 0.54)" fxLayoutAlign="center center">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class="toolbar-title" fxFlex style="margin-left:-40px;">Add Vehicle</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div style="overflow:auto;">
    <div fxLayout="column" style="margin: 1.8rem 16px 0 16px;">
      <span style="cursor:pointer;color:rgb(25, 118, 210);margin-top:-20px;font-size:13px;"
        (click)="resetAddVehicleForm()">Reset</span>
    </div>
    <div style="margin:16px;background-color:#FFF;padding:16px;border:1px solid #dadce0;border-radius:8px;">
      <form id="vehicleForm" fxLayout="row wrap" fxLayoutAlign="start center" [formGroup]="addVehicleForm" novalidate>
        <div fxLayout="row wrap">
          <div class="form-field" fxLayout="row" fxFlex="50" fxFlex.xs="50">
            <mat-form-field>
              <input id="vin" matInput formControlName="vin" placeholder="VIN" required>
            </mat-form-field>
          </div>
          <div class="form-field" fxLayout="row" fxFlex="50" fxFlex.xs="50">
            <mat-form-field>
              <input id="mileage" matInput formControlName="mileage" placeholder="Mileage" required>
            </mat-form-field>
          </div>
          <div class="form-field" fxLayout="row" fxFlex="50" fxFlex.xs="50">
            <mat-form-field>
              <mat-select placeholder="Manufacturer" name="manufacturer" formControlName="manufacturer">
                <mat-option
                  *ngFor="let manufacturer of dropdowns.manufacturers | orderBy: ['embedded.associate.firstName']"
                  [value]="manufacturer.associateId">
                  {{manufacturer.embedded.associate.firstName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-field" fxLayout="row" fxFlex="50" fxFlex.xs="50">
            <mat-form-field>
              <mat-select placeholder="Color" name="color" formControlName="color" required>
                <mat-option *ngFor="let vehicleColour of dropdowns.vehicleColours | orderBy: 'colourDescription'"
                  [value]="vehicleColour.id">
                  {{vehicleColour.colourDescription}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <sub>* Required fields</sub>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>