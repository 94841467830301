import { Injectable } from '@angular/core';
import { Sale } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { of } from 'rxjs';
import { flatMap, mergeAll, switchMap } from 'rxjs/operators';
import * as fromProspects from '../../../../../../xsr-entities/src/lib/crm/prospects/prospects.actions';
import { SalesPlannerStateService } from '../../sales-planner.state.service';
import * as fromSelectedActivity from '../../selected-activity/selected-activity.actions';
import * as fromDeliveries from './deliveries.actions';
@Injectable()
export class DeliveriesEffects {

  getDeliveriesFromServer = createEffect(() => this.dataPersistence.fetch(fromDeliveries.GET_DELIVERIES_FROM_SERVER, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromDeliveries.GetDeliveriesFromServer, state: any) => {
      return this.apiService
        .get<Sale[]>(`/v/1/vehicles/sales`, {
          params: action.payload,
        })
        .pipe(
          flatMap((deliveries) => {
            let actions: any[] = [];

            actions.push(new fromDeliveries.ClearDeliveriesFromState());

            actions.push(
              new fromDeliveries.GetDeliveriesFromServerSuccess({
                deliveries: deliveries,
              })
            );
            return of(actions).pipe(mergeAll());
          })
        );
    },
    onError: (action: fromDeliveries.GetDeliveriesFromServer, error) => {
      console.error('Error', error);
    },
  }));


  updateDeliveryOnServer = createEffect(() => this.dataPersistence.fetch(fromDeliveries.UPDATE_DELIVERY_ON_SERVER, {
    run: (action: fromDeliveries.UpdateDeliveryOnServer, state: any) => {
      return this.apiService.put<Sale>(`v/1/vehicles/sales/${action.payload.delivery.id}`, action.payload.delivery, {
        params: action.payload.queryParameters
      }).pipe(
        switchMap((delivery) => {
          let actions: Action[] = [];
          let toastOptions: any = {
            message: `Delivery updated.`,
            actionText: null,
            options: { duration: 3000, horizontalPosition: 'left' },
          };

          this.salesplannerStateService.addToast(toastOptions);
          actions.push(
            new fromProspects.UpdateProspectOnServer({
              prospect: {
                ...state.salesplannerLegacy.selectedActivity.prospect,
                updatedDate: new Date(),
              },
              displayToast: false,
            })
          );
          actions.push(
            new fromSelectedActivity.UpdateSelectedActivityInState({
              id: delivery.id,
              changes: { ...delivery },
            })
          );
          actions.push(
            new fromDeliveries.UpdateDeliveryOnServerSuccess({
              id: delivery.id,
              changes: { ...delivery },
            })
          );
          return of(actions).pipe(mergeAll());
        })
      );
    },

    onError: (action: fromDeliveries.UpdateDeliveryOnServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<any>,
    private apiService: ApiService,
    private salesplannerStateService: SalesPlannerStateService
  ) {}
}
