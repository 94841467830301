import { SubType } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface VehicleSubTypesState extends EntityState<SubType> {
  loading: boolean;
}

export const vehicleSubTypesAdapter: EntityAdapter<SubType> = createEntityAdapter<SubType>({
  selectId: (subType: SubType) => subType.id
});

export const initialState: VehicleSubTypesState = vehicleSubTypesAdapter.getInitialState({ loading: true });
export const vehicleSubTypesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleSubTypes
);

export const vehicleSubTypesLoadingSelector = createSelector(
  vehicleSubTypesSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleSubTypesAdapter.getSelectors(
  vehicleSubTypesSelector
);
