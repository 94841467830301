import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Address, AssociateReport, Prospect, ResponseLead } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-prospect-choose-phone-email-dialog',
  templateUrl: './prospect-choose-phone-email-dialog.component.html',
  styleUrls: ['./prospect-choose-phone-email-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectChooseEmailPhoneDialogComponent implements OnInit {
  phoneEmailForm = this.fb.group({
    mainPhoneNumber: ['', Validators.required],
    cellPhoneNumber: ['', Validators.required],
    emailAddress: ['', Validators.required],
  });

  mainPhoneNumbers: any = {};
  cellPhoneNumbers: any = {};
  emailAddresses: any = {};

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProspectChooseEmailPhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      prospect: Prospect;
      associate: AssociateReport;
      address: Address;
      responseLead?: ResponseLead;
    }
  ) {}

  ngOnInit() {
    if (this.data.address.homePhoneNumber) {
      this.mainPhoneNumbers[`${this.data.address.homePhoneNumber}`] = 'from existing associate';
    }

    if (this.data.address.cellPhoneNumber) {
      this.cellPhoneNumbers[`${this.data.address.cellPhoneNumber}`] = 'from existing associate';
    }

    if (this.data.prospect.phoneNumber) {
      this.mainPhoneNumbers[`${this.data.prospect.phoneNumber}`] = 'from current prospect';
      this.cellPhoneNumbers[`${this.data.prospect.phoneNumber}`] = 'from current prospect';
    }

    if (this.data.responseLead && this.data.responseLead.cellPhoneNumber) {
      this.cellPhoneNumbers[`${this.data.responseLead.cellPhoneNumber}`] = 'from response lead';
    }

    this.data.associate.emailAddress
      ? (this.emailAddresses[`${this.data.associate.emailAddress}`] = 'from existing associate')
      : null;
    this.data.prospect.emailAddress
      ? (this.emailAddresses[`${this.data.prospect.emailAddress}`] = 'from current prospect')
      : null;

    this.phoneEmailForm.patchValue({
      mainPhoneNumber: this.data.prospect.phoneNumber
        ? Object.keys(this.mainPhoneNumbers).find((key) => key === this.data.prospect.phoneNumber)
        : Object.keys(this.mainPhoneNumbers)[0],
      cellPhoneNumber: this.data.address.cellPhoneNumber
        ? Object.keys(this.cellPhoneNumbers).find((key) => key === this.data.address.cellPhoneNumber)
        : this.data.responseLead && this.data.responseLead.cellPhoneNumber
        ? Object.keys(this.cellPhoneNumbers).find((key) => key === this.data.responseLead.cellPhoneNumber)
        : this.data.prospect.phoneNumber
        ? Object.keys(this.cellPhoneNumbers).find((key) => key === this.data.prospect.phoneNumber)
        : Object.keys(this.cellPhoneNumbers)[0],
      emailAddress: this.data.prospect.emailAddress
        ? Object.keys(this.emailAddresses).find((key) => key === this.data.prospect.emailAddress)
        : Object.keys(this.emailAddresses)[0],
    });
  }

  confirmPhoneEmail(phoneEmailFormValue: any, dialogRef: MatDialogRef<ProspectChooseEmailPhoneDialogComponent>) {
    dialogRef.close(phoneEmailFormValue);
  }
}
