import * as fromProspectActions from './prospect-actions.actions';
import { initialState, prospectActionsAdapter, ProspectActionsState } from './prospect-actions.interfaces';

export function reducer(
  state: ProspectActionsState = initialState,
  action: fromProspectActions.ProspectActionsAction
): ProspectActionsState {
  switch (action.type) {
    case fromProspectActions.GET_PROSPECT_ACTIONS_FROM_SERVER_SUCCESS: {
      return {
        ...prospectActionsAdapter.addMany(action.payload.actions, state)
      };
    }

    default: {
      return state;
    }
  }
}
