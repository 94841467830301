import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivityQueryParameters } from '@quorum/xsr-salesplanner-legacy';

@Component({
  selector: 'crm-activity-list-filter',
  templateUrl: './activity-list-filter.component.html',
  styleUrls: ['./activity-list-filter.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListFilterComponent implements OnInit, OnDestroy {
  @Input() queryParams: ActivityQueryParameters;
  @Output() filtersChanged: EventEmitter<ActivityQueryParameters> = new EventEmitter<ActivityQueryParameters>();
  @Output() toggleOverdueActivityList: EventEmitter<boolean> = new EventEmitter<boolean>();
  actTypeSelectAll: boolean = false;
  overdueActivities: boolean = false;
  filterChanges: Subject<ActivityQueryParameters> = new Subject();
  filterSub: Subscription;
  activityTypes: Array<any> = [
    { id: 'A', description: 'Appointment' },
    { id: 'P', description: 'Promotion' },
    { id: 'D', description: 'Delivery' },
    { id: 'S', description: 'Service' },
    { id: 'T', description: 'Task' },
  ];

  constructor() { }

  ngOnInit() {
    if (this.queryParams.activityTypes.length === this.activityTypes.length) {
      this.actTypeSelectAll = true;
    }

    this.filterSub = this.filterChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((filter) => {
      this.filtersChanged.emit(this.queryParams);
    });
  }

  ngOnDestroy() {
    this.filterSub.unsubscribe();
  }
  filterChanged(date: Date, queryParams: ActivityQueryParameters) {
    queryParams.startDate = date;
    this.filterChanges.next(queryParams);
  }

  toggleSelection(selectAll: boolean, select: NgModel, values: any): void {
    let ids: any[] = [];
    values.map((value: any) => ids.push(value.id));
    if (selectAll) {
      select.update.emit(ids);
    } else {
      select.update.emit([]);
    }
    this.filtersChanged.emit(this.queryParams);
  }

  toggleOverdueActivities() {
    this.overdueActivities = !this.overdueActivities;
    this.queryParams.overdueActivities = this.overdueActivities;
    this.filtersChanged.emit(this.queryParams);
  }
}
