export class UserQueryParameters {
  createdById?: string;
  department?: string;
  email?: string;
  embed?: string;
  id?: string;
  integrationPartnerId?: string;
  isActive?: boolean;
  isActiveDirectoryLogin?: boolean;
  isAdministrator?: boolean;
  isDealershipUser?: boolean;
  isQuorumUser?: boolean;
  firstName?: string;
  lastName?: string;
  nickName?: string;
  jobTitle?: string;
  page?: number;
  pageSize?: number;
  storeId?: string;
  username?: string;
  utcCreatedSearchEnd?: Date;
  utcCreatedSearchStart?: Date;
  utcLastModifiedSearchEnd?: Date;
  utcLastModifiedSearchStart?: Date;
  xselleratorEmployeeId?: string;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
