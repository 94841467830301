import { VehicleColour as Colour } from '@quorum/models/xs-resource';
import { VehicleColourQueryParameters as ColourQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_COLOURS_FROM_SERVER = '[CRM] Get Vehicle Colours From Server';
export class GetVehicleColoursFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_COLOURS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ColourQueryParameters) {}
}

export const GET_VEHICLE_COLOURS_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Colours From Server Success';
export class GetVehicleColoursFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_COLOURS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleColours: Colour[] }) {}
}

export const GET_VEHICLE_COLOURS_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Colours From Server Failure';
export class GetVehicleColoursFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_COLOURS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VehicleColoursAction =
  | GetVehicleColoursFromServer
  | GetVehicleColoursFromServerSuccess
  | GetVehicleColoursFromServerFailure;
