<div class="relative h-full w-full">
    <div *ngIf="loadVehicleOfInterest" class="absolute bg-black bg-opacity-25 w-full h-full z-50">
        <div class="flex flex-row justify-center items-center py-6 w-full h-full">
            <div style="border-top-color: transparent !important;"
                class="border-solid animate-spin rounded-full border-grey-200 border-8 h-12 w-12"></div>
        </div>
    </div>
    <div class="absolute h-full w-full">
        <div class="w-full relative flex justify-center">
            <h2 mat-dialog-title>{{data.vehicleOfInterest? "Edit ": "New "}}Vehicle of Interest</h2>
            <div class="absolute top-0 right-0">
                <button type="button" [mat-dialog-close]="false"
                    class="bg-white rounded-md text-grey-400 hover:text-grey-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-quorum-grey-comp">
                    <span class="sr-only">Close</span>
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </div>

        <img src="assets/bb-logo.png" class="h-10 absolute" style="bottom: 4px; left: 24px;" />

        <mat-horizontal-stepper [linear]="isLinear" #stepper labelPosition="bottom">
            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel>Vehicle</ng-template>
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2 mt-1" for="year">
                        Year <span class="text-red">*</span>
                    </label>
                    <select
                        class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                        formControlName="year">
                        <option disabled selected value> - select an option - </option>
                        <option [selected]="year === this.firstFormGroup.get('year')?.value"
                            *ngFor="let year of years | orderBy: ['year']">
                            {{year}}</option>
                    </select>
                    <br />
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2" for="make">
                        Make <span class="text-red">*</span>
                    </label>
                    <select
                        class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                        formControlName="make">
                        <option disabled selected value> - select an option - </option>
                        <option [selected]="make === this.firstFormGroup.get('make')?.value"
                            *ngFor="let make of makes | orderBy: ['make']">{{make}}</option>
                    </select>
                    <br />
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2" for="model">
                        Model <span class="text-red">*</span>
                    </label>
                    <select
                        class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                        formControlName="model">
                        <option disabled selected value> - select an option - </option>
                        <option [selected]="model === this.firstFormGroup.get('model')?.value"
                            *ngFor="let model of models | orderBy: ['model']">{{model}}</option>
                    </select>
                    <br />
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2" for="trim">
                        Trim
                    </label>
                    <select
                        class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                        formControlName="trim">
                        <option disabled selected value> - select an option - </option>
                        <option [selected]="trim === this.firstFormGroup.get('model')?.value"
                            *ngFor="let trim of trims | orderBy: ['trim']">{{trim}}</option>
                    </select>
                    <br />
                    <div fxLayout="row" fxLayoutAlign="end end">
                        <button mat-button matStepperNext [disabled]="firstFormGroup.invalid">Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
                <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel>Options</ng-template>
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2 mt-1" for="colour">
                        Colour
                    </label>
                    <input
                        class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                        formControlName="colour">
                    <br />

                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2" for="make">
                        Maximum Mileage
                    </label>
                    <div class="flex flex-row items-center">
                        <input type="number"
                            class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                            formControlName="maxMileage">
                        <span class="block uppercase text-grey-700 text-xs font-bold pl-2">{{mileageLabel}}</span>
                    </div>
                    <br />
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2" for="make">
                        Maximum Price
                    </label>
                    <div class="flex flex-row items-center">
                        <span class="block uppercase text-grey-700 text-xs font-bold pr-2">$</span>
                        <input type="number"
                            class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                            formControlName="maxPrice">
                    </div>
                    <br />
                    <label class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-2" for="make">
                        VIN
                    </label>
                    <input
                        class="block appearance-none w-full py-3 px-3 bg-grey-100 border border-grey-200 text-grey-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey-400"
                        formControlName="vin">
                    <br />
                    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Review</ng-template>
                <div fxLayout="row wrap" class='mt-8'>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Year</span>
                        <span>{{ firstFormGroup.value.year ? firstFormGroup.value.year : "—" }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Make</span>
                        <span>{{ firstFormGroup.value.make ? firstFormGroup.value.make : "—" }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Model</span>
                        <span>{{ firstFormGroup.value.model ? firstFormGroup.value.model : "—" }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Trim</span>
                        <span>{{ firstFormGroup.value.trim ? firstFormGroup.value.trim : "—" }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Colour</span>
                        <span>{{ secondFormGroup.value.colour ? secondFormGroup.value.colour : "—" }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Maximum
                            Mileage</span>
                        <span>{{ secondFormGroup.value.maxMileage ? (secondFormGroup.value.maxMileage | number |
                            unitOfMeasure | async) : "—"
                            }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">Maximum
                            Price</span>
                        <span>$ {{ secondFormGroup.value.maxPrice ? (secondFormGroup.value.maxPrice | number) : "—"
                            }}</span>
                    </div>
                    <div class='flex flex-col mb-9' fxFlex="50">
                        <span class="block uppercase tracking-wide text-grey-700 text-xs font-bold mb-1">VIN</span>
                        <span>{{ secondFormGroup.value.vin ? secondFormGroup.value.vin : "—" }}</span>
                    </div>
                </div>
                <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" class="mt-4">
                    <button *ngIf="!data.vehicleOfInterest" mat-button (click)="resetForms()">Reset</button>
                    <button mat-button matStepperPrevious>Back</button>
                    <button mat-button color="primary" (click)="save()">Save</button>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>