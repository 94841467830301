import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { CrmAppointmentQueryParameters as AppointmentQueryParameters } from '@quorum/models/xs-query';
import { CrmAppointment as Appointment } from '@quorum/models/xs-resource';

export const GET_SALES_APPOINTMENTS_FROM_SERVER = '[CRM] Get Sales Appointments From Server';
export class GetSalesAppointmentsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALES_APPOINTMENTS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: AppointmentQueryParameters) {}
}

export const GET_SALES_APPOINTMENTS_FROM_SERVER_SUCCESS = '[CRM] Get Sales Appointments  From Server Success';
export class GetSalesAppointmentsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALES_APPOINTMENTS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { salesAppointments: Appointment[] }) {}
}

export const GET_SALES_APPOINTMENTS_FROM_SERVER_FAILURE = '[CRM] Get Sales Appointments  From Server Failure';
export class GetSalesAppointmentsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALES_APPOINTMENTS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_SALES_APPOINTMENTS_FROM_STATE = '[CRM] Clear Sales Appointments From State';
export class ClearSalesAppointmentsFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_SALES_APPOINTMENTS_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export const CREATE_SALES_APPOINTMENT_ON_SERVER = '[CRM] Create Sales Appointment On Server';
export class CreateSalesAppointmentOnServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SALES_APPOINTMENT_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { appointment: Appointment; communicatorConfirmation: Boolean }) {}
}

export const CREATE_SALES_APPOINTMENT_ON_SERVER_SUCCESS = '[CRM] Create Sales Appointment On Server Success';
export class CreateSalesAppointmentOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SALES_APPOINTMENT_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Appointment) {}
}

export const CREATE_SALES_APPOINTMENT_ON_SERVER_FAILURE = '[CRM] Create Sales Appointment On Failure';
export class CreateSalesAppointmentOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SALES_APPOINTMENT_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_SALES_APPOINTMENT_ON_SERVER = '[CRM] Update Sales Appointment On Server';
export class UpdateSalesAppointmentOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SALES_APPOINTMENT_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { oldAppointment: Appointment; newAppointment: Appointment }) {}
}

export const UPDATE_SALES_APPOINTMENT_ON_SERVER_SUCCESS = '[CRM] Update Sales Appointment On Server Success';
export class UpdateSalesAppointmentOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SALES_APPOINTMENT_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Appointment>) {}
}

export const UPDATE_SALES_APPOINTMENT_ON_SERVER_FAILURE = '[CRM] Update Sales Appointment On Failure';
export class UpdateSalesAppointmentOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SALES_APPOINTMENT_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type SalesAppointmentsAction =
  | GetSalesAppointmentsFromServer
  | GetSalesAppointmentsFromServerSuccess
  | GetSalesAppointmentsFromServerFailure
  | ClearSalesAppointmentsFromState
  | CreateSalesAppointmentOnServer
  | CreateSalesAppointmentOnServerSuccess
  | CreateSalesAppointmentOnServerFailure
  | UpdateSalesAppointmentOnServer
  | UpdateSalesAppointmentOnServerSuccess
  | UpdateSalesAppointmentOnServerFailure;
