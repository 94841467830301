import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-prospect-close-dialog',
  templateUrl: './prospect-close-dialog.component.html',
  styleUrls: ['./prospect-close-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectCloseDialogComponent implements OnInit {
  prospectClosedActionsForm = this.fb.group({
    prospectClosedAction: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<ProspectCloseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { prospectClosedActions: Action[] },
    private fb: FormBuilder
  ) {}

  ngOnInit() {}
}
