import * as fromCustomerTypeActions from './customer-types.actions';
import { customerTypesAdapter, CustomerTypesState, initialState } from './customer-types.interfaces';

export function reducer(
  state: CustomerTypesState = initialState,
  action: fromCustomerTypeActions.AssociateCustomerTypesAction
): CustomerTypesState {
  switch (action.type) {
    case fromCustomerTypeActions.GET_CUSTOMER_TYPES_FROM_SERVER_SUCCESS: {
      return {
        ...customerTypesAdapter.addMany(action.payload.customerTypes, state)
      };
    }

    default: {
      return state;
    }
  }
}
