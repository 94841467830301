import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UiStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'crm-prospect-aggregate',
  templateUrl: './prospect-aggregate.component.html',
  styleUrls: ['./prospect-aggregate.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectAggregateComponent implements OnInit, OnDestroy {
  @Input() prospectAggregate: any;
  prospectAggregatePanel: boolean;
  prospectAggregatePanelStatus$: Subscription;
  constructor(private uiStateService: UiStateService) {}

  ngOnInit() {
    this.prospectAggregatePanelStatus$ = this.uiStateService
      .selectProspectAggregateVisible()
      .subscribe((res) => (this.prospectAggregatePanel = res));
  }

  toggleProspectAggregateData(prospectAggregatePanel: boolean) {
    this.uiStateService.toggleProspectAggregatePanel(!prospectAggregatePanel);
  }

  ngOnDestroy() {
    this.prospectAggregatePanelStatus$.unsubscribe();
  }
}
