import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { ReportingVehicleSearchQueryParameters } from '@quorum/models/xs-query';
import {
  Class,
  CrmVehicleSearch as ProspectVehicleSearch,
  Employee,
  OemRooftopLink,
  Prospect,
  ProvinceState,
  ReportingVehicleSearch,
  SubType,
  SystemControlValue,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import {
  LocalSettingsState,
  LocalSettingsStateService,
  SalesPlannerStateService,
  SelectedActivityStateService,
  VehicleSearchStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { BrowserControlCommunicationService } from 'libs/sha-services/src/lib/browserControlCommunication.service';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-vehicle-search-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit, OnDestroy {
  avEnabled: boolean;
  dropdowns$: Observable<any>;
  dropdowns: any;
  vsDropdowns: any = {};
  vehicleSearchResults$: Observable<ReportingVehicleSearch[]>;
  //needs to be vehiclesearchqueryparameters
  dank: number;
  defaultRooftopId: number;
  isLoading$: Observable<boolean>;
  authenticatedEmployee: Employee;
  routerState$: Subscription;
  queryParamSub$: BehaviorSubject<ReportingVehicleSearchQueryParameters> =
    new BehaviorSubject<ReportingVehicleSearchQueryParameters>({});
  defaultVehicleSearchFormModel: ReportingVehicleSearchQueryParameters = new ReportingVehicleSearchQueryParameters({
    categoryId: '',
    subCategoryId: '',
    subTypeId: '',
    make: '',
    description: '',
    id: '',
    genericModelId: '',
    colorId: '',
    rooftopId: '',
    modelCode: '',
    rpos: '',
    unitNumber: '',
    vehicleStatusId: '',
    minYear: '',
    maxYear: '',
    minPrice: '',
    maxPrice: '',
    vin: '',
    sort: '-days',
  });
  vsResults: ReportingVehicleSearch[] = [];
  displayedResults: ReportingVehicleSearch[] = [];
  page: number = 0;
  pageSize: number = 25;
  totalPages: number = 0;
  vehicleSearchForm = this.fb.group(this.defaultVehicleSearchFormModel);
  systemControls$: Observable<SystemControlValue[]>;
  defaultPricingSystemControl: any;
  authenticatedUser: AuthenticatedUser;
  crmSettings: any;
  sort: string;
  selectedProspect: Prospect;
  @ViewChild('searchButton') searchButton: any;
  prospectDropdowns$: Observable<any>;
  prospectDropdowns: any;
  postProspectProcessSubscription: Subscription;
  localSettings$: Observable<LocalSettingsState>;
  localSettings: LocalSettingsState;
  selectedProspect$: Observable<Prospect>;
  @ViewChild('resultsContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('searchResult', { read: TemplateRef }) template: TemplateRef<any>;
  deskButtonDisabled: boolean = false;
  createProspectProgressRef: MatDialogRef<any>;
  drilldownEnabled: boolean;
  constructor(
    private dropdownStateService: DropdownsStateService,
    private vehicleSearchStateService: VehicleSearchStateService,
    private route: ActivatedRoute,
    private authenticationStateService: AuthenticationStateService,
    private fb: FormBuilder,
    private routerStateService: RouterStateService,
    private dropdownsStateService: DropdownsStateService,
    private salesPlannerStateService: SalesPlannerStateService,
    private systemControlStateService: SystemControlStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private settingsStateService: CrmSettingsStateService,
    private apiService: ApiService,
    private crmEntityStateService: CrmEntityStateService,
    private localSettingsStateService: LocalSettingsStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.selectedProspect$ = this.selectedActivityStateService.selectSelectedProspect();
    this.settingsStateService
      .selectSettingsFromState()
      .pipe(take(1))
      .subscribe((settings) => {
        this.crmSettings = settings;
        this.cdr.detectChanges();
      });

    this.localSettings$ = this.localSettingsStateService.selectLocalSettingsFromState().pipe(shareReplay(1));

    this.localSettings$.subscribe((l) => {
      this.localSettings = l;
    });
    combineLatest([
      this.dropdownStateService.selectChannels().pipe(
        filter((channels) => !this.dropdownStateService.isEmpty(channels)),
        map((channels) => channels.filter((channel) => channel.description.trim().toLowerCase() !== 'lead generator'))
      ),
      this.dropdownStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownStateService.isEmpty(customerTypes))),
      this.dropdownStateService
        .selectMessageTypes()
        .pipe(filter((messageTypes) => !this.dropdownStateService.isEmpty(messageTypes))),
      this.dropdownStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0))
      ),
      this.dropdownStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownStateService.isEmpty(salespeople))),
      this.dropdownStateService.selectSources().pipe(
        filter((sources) => !this.dropdownStateService.isEmpty(sources)),
        map((sources) =>
          sources.filter(
            (source) =>
              !['available', 'over mileage', 'sub-prime', 'service appointments'].includes(
                source.description.trim().toLowerCase()
              )
          )
        )
      ),
      this.dropdownStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownStateService.isEmpty(associateClasses))),
    ])
      .pipe(
        map(([channels, customerTypes, messageTypes, rooftops, salespeople, sources, associateClasses]) => {
          return { channels, customerTypes, messageTypes, rooftops, salespeople, sources, associateClasses };
        }),
        take(1)
      )
      .subscribe((dropdowns) => {
        this.vsDropdowns = dropdowns;
        this.cdr.detectChanges();
      });

    console.log('HEY');
    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => {
          return employee != null;
        })
      )
      .subscribe((authEmployee: Employee) => {
        this.authenticatedEmployee = authEmployee;
        console.log('HERE AY');
        this.systemControls$ = this.systemControlStateService
          .getSystemControlValues(
            [
              'CL_AUTOVANCE_ENABLED',
              'CL_DEF_VEH_PRICE_FOR_VS_CHRGS',
              'CL_SALES_APPOINTMENT_CONFIRMATIONS',
              'CL_COMMUNICATOR_ENABLE',
              'CL_COMMUNICATOR_SALES_APPT_CONFIRMATION',
            ],
            authEmployee.rooftopId
          )
          .pipe(
            take(1),
            tap((systemControls) => {
              console.log(systemControls);
              this.defaultPricingSystemControl = systemControls['CL_DEF_VEH_PRICE_FOR_VS_CHRGS'];
              console.log('AY');
              console.log(this.defaultPricingSystemControl);
              this.avEnabled = systemControls['CL_AUTOVANCE_ENABLED'].value === '1';
            })
          );
      });

    this.vehicleSearchStateService.listenForToasts().subscribe((toastOptions) => {
      this.snackBar.open(toastOptions.message, toastOptions.actionText, toastOptions.options);
    });

    this.salesPlannerStateService.listenForToasts().subscribe((toastOptions) => {
      this.snackBar.open(toastOptions.message, toastOptions.actionText, toastOptions.options);
    });

    this.dropdowns$ = forkJoin(
      this.dropdownStateService.selectVehicleCategories().pipe(take(1)),
      this.dropdownStateService.selectVehicleColours().pipe(take(1)),
      this.dropdownStateService.selectVehicleGenericModels().pipe(take(1)),
      this.dropdownStateService.selectVehicleMakes().pipe(take(1)),
      this.dropdownStateService.selectVehicleStatuses().pipe(take(1)),
      this.dropdownStateService.selectVehicleSubcategories().pipe(take(1)),
      this.dropdownStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
        take(1)
      ),
      this.dropdownStateService.selectVehicleSubTypes().pipe(
        map((vehicleSubTypes: SubType[]) =>
          vehicleSubTypes.filter(
            (vehicleSubType: SubType) => vehicleSubType.showFlag == '1' && vehicleSubType.description !== 'All'
          )
        ),
        take(1)
      )
    ).pipe(
      map(
        ([
          vehicleCategories,
          vehicleColours,
          vehicleGenericModels,
          vehicleMakes,
          vehicleStatuses,
          vehicleSubcategories,
          rooftops,
          vehicleSubTypes,
        ]) => {
          return {
            vehicleCategories,
            vehicleColours,
            vehicleGenericModels,
            vehicleMakes,
            vehicleStatuses,
            vehicleSubcategories,
            rooftops,
            vehicleSubTypes,
          };
        }
      )
    );

    this.prospectDropdowns$ = combineLatest(
      this.dropdownsStateService.selectChannels().pipe(
        filter((channels) => !this.dropdownsStateService.isEmpty(channels)),
        map((channels) => channels.filter((channel) => channel.description.trim().toLowerCase() !== 'lead generator'))
      ),
      this.dropdownsStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes))),
      this.dropdownsStateService.selectMessageTypes().pipe(
        filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes)),
        map((messageTypes) => messageTypes.filter((mt) => mt.displayInXselleratorUIFlag))
      ),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => {
          return !this.dropdownsStateService.isEmpty(rooftops);
        }),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService.selectSources().pipe(
        filter((sources) => !this.dropdownsStateService.isEmpty(sources)),
        map((sources) =>
          sources.filter(
            (source) =>
              !['available', 'over mileage', 'sub-prime', 'service appointments'].includes(
                source.description.trim().toLowerCase()
              )
          )
        )
      ),
      this.dropdownsStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownsStateService.isEmpty(associateClasses))),
      this.dropdownsStateService
        .selectProspectActions()
        .pipe(filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions))),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(
        ([
          channels,
          customerTypes,
          messageTypes,
          rooftops,
          salespeople,
          sources,
          associateClasses,
          prospectActions,
          provStates,
        ]) => {
          return {
            channels,
            customerTypes,
            messageTypes,
            rooftops,
            salespeople,
            sources,
            associateClasses,
            prospectActions,
            provStates,
          };
        }
      )
    );

    this.prospectDropdowns$.subscribe((d) => {
      this.prospectDropdowns = d;
    });

    combineLatest(
      this.dropdowns$.pipe(take(1)),
      this.routerStateService.selectRouterFeatureState().pipe(take(1)),
      this.selectedActivityStateService.selectSelectedProspect().pipe(take(1))
    )
      .pipe(
        map(([dropdowns, routerState, selectedProspect]) => {
          return { dropdowns, routerState, selectedProspect };
        }),
        tap((data: any) => {
          this.dropdowns = data.dropdowns;

          this.vehicleSearchForm.patchValue(
            {
              rooftopId: this.authenticatedEmployee.rooftopId,
              vehicleStatusId: '2',
              sort: '-days',
            },
            {
              onlySelf: true,
              emitEvent: false,
            }
          );
        })
      )
      .subscribe((data) => {
        if (!data.routerState.state.url.includes('prospect')) {
          this.selectedActivityStateService.clearSelectedProspectFromState();
        } else {
          this.selectedProspect = data.selectedProspect;
        }

        this.sort = this.defaultVehicleSearchFormModel.sort;
        if (!this.dropdownsStateService.isEmpty(data.routerState.state.root.queryParams)) {
          if (data.routerState.state.root.queryParams.sort) {
            this.sort = data.routerState.state.root.queryParams.sort;
          }
          this.vehicleSearchForm.patchValue(
            new ReportingVehicleSearchQueryParameters(data.routerState.state.root.queryParams)
          );
          Object.keys(this.vehicleSearchForm.controls).forEach((key) => {
            if (
              key == 'subCategoryId' ||
              key == 'subTypeId' ||
              key == 'genericModelId' ||
              key == 'colorId' ||
              key == 'rooftopId'
            ) {
              let id =
                this.vehicleSearchForm.controls[key].value == ''
                  ? ''
                  : parseInt(this.vehicleSearchForm.controls[key].value);
              this.vehicleSearchForm.controls[key].setValue(id);
            }
          });
        }
        this.cdr.detectChanges();
      });

    this.isLoading$ = this.vehicleSearchStateService.selectIsLoading();
    this.vehicleSearchResults$ = this.vehicleSearchStateService.selectVehicleSearchResults();

    this.vehicleSearchResults$.subscribe((vsr: ReportingVehicleSearch[]) => {
      console.log('VS RESULTS');
      console.log(vsr);
      this.vsResults = vsr;
      this.page = 0;
      this.displayedResults = vsr.filter((r: ReportingVehicleSearch, i: number) => {
        return i >= this.pageSize * this.page && i < this.pageSize * this.page + this.pageSize;
      });
      // this.buildData(
      //   vsr,
      //   this.avEnabled,
      //   this.vsDropdowns,
      //   this.prospectDropdowns,
      //   this.defaultPricingSystemControl,
      //   this.crmSettings,
      //   this.localSettings,
      //   this.authenticatedEmployee,
      //   this.selectedProspect
      // );
    });
    this.vehicleSearchForm.valueChanges.pipe(debounceTime(1000)).subscribe((value: any) => {
      if (value) {
        this.sort = value.sort;
        this.updateRouter(value);
      }
    });

    this.dropdownsStateService
      .selectProspectActions()
      .pipe(
        filter((prospectActions) => prospectActions != null && prospectActions.length > 0),
        take(1)
      )
      .subscribe((prospectActions) => {
        this.postProspectProcessSubscription = this.crmEntityStateService
          .postProspectCreationProcess(prospectActions.find((ac) => ac.description === 'Opportunity').id)
          .pipe(take(1))
          .subscribe((prospect) => {
            let params = new DeepCopyPipe().transform(this.vehicleSearchForm.value);
            this.saveProspectVehicleSearchFilters(prospect, params);
            this.routerStateService.go([`sales-planner/prospects/${prospect.id}`], {
              relativeTo: this.route.parent,
            });
          });
      });
  }

  trackByFunction(index: number) {
    return index;
  }

  pageChanged(event: any) {
    this.page = event.pageIndex;
    this.displayedResults = this.vsResults.filter((r: ReportingVehicleSearch, i: number) => {
      return i >= this.pageSize * this.page && i < this.pageSize * this.page + this.pageSize;
    });
    this.cdr.detectChanges();
  }

  updateRouter(queryParams: ReportingVehicleSearchQueryParameters) {
    let navExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    };
    this.routerStateService.go('', navExtras);
  }

  resetVehicleSearchForm() {
    this.vehicleSearchForm.patchValue(
      new ReportingVehicleSearchQueryParameters({
        ...this.defaultVehicleSearchFormModel,
        rooftopId: this.authenticatedEmployee.rooftopId.toString(),
        vehicleStatusId: '2',
      })
    );
  }

  clearVehicleSearchResults() {
    this.vehicleSearchStateService.clearVehicleSearchResultsFromState();
  }

  sortChanged(sortValue: string, vehicleSearchForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.sort == sortValue;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.searchButton.nativeElement.click();
      });

    vehicleSearchForm.patchValue({ ...vehicleSearchForm.value, sort: sortValue });
  }

  getVehicleSearchResults(vehicleSearchForm: FormGroup, selectedProspect?: Prospect) {
    this.vehicleSearchStateService.clearVehicleSearchResultsFromState();
    let params = new DeepCopyPipe().transform(vehicleSearchForm);
    this.saveProspectVehicleSearchFilters(selectedProspect, params);
    this.vehicleSearchStateService.getVehicleSearchResults(params);
  }

  backClicked() {
    let queryParams: any = {};
    this.route.queryParams.pipe(take(1)).subscribe((qps) => {
      queryParams = { ...queryParams, tab: qps.tab };
      this.routerStateService.go(['..'], { queryParams, relativeTo: this.route });
    });
  }

  saveProspectVehicleSearchFilters(prospect: Prospect, vehicleSearchParams: any) {
    if (prospect) {
      Object.keys(vehicleSearchParams).forEach((key) => {
        if (vehicleSearchParams[key] === '') {
          delete vehicleSearchParams[key];
        }
      });

      this.apiService
        .put<ProspectVehicleSearch>(`v/1/crm/prospects/${prospect.id}/vehicle-search`, {
          ...vehicleSearchParams,
          prospectId: prospect.id,
        })
        .pipe(take(1))
        .subscribe();
    }
  }
  convertToCaps(formControlName:any, event:any){
    this.vehicleSearchForm.get(formControlName).setValue(event.target.value.toUpperCase())
  }

  ngOnDestroy() {}
}
