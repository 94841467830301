<h1 mat-dialog-title>Black Book Editor</h1>
<h4>
  {{ data.reportItem.vehicleYear + ' ' + data.reportItem.vehicleMake + ' ' + data.reportItem.vehicleDescription }}
</h4>
<div mat-dialog-content fxLayout="column">
  <div fxLayout="column" class="content-container" *ngIf="(combo$ | async) as combo; else loading">
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field fxFlex>
        <mat-select [(ngModel)]="selectedVehicle" (selectionChange)="
            calculateValues(selectedVehicle, blackbookSettings, blackbookValuesSetting, workflowMatchValue)
          " placeholder="Matched Vehicle" name="matchedVehicle">
          <mat-option [value]="vehicle" *ngFor="let vehicle of combo.vehicleList">
            {{ vehicle.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-form-field fxFlex>
        <mat-select [(ngModel)]="blackbookValuesSetting.blackbookPriceTypeId" (selectionChange)="
            calculateValues(selectedVehicle, blackbookSettings, blackbookValuesSetting, workflowMatchValue)
          " placeholder="Price Type" name="priceType">
          <mat-option [value]="priceType.id" *ngFor="let priceType of combo.blackBookPriceTypes">
            {{ priceType.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="selectedVehicle" fxLayout="row" fxLayoutAlign="space-between start">
      <div fxLayout="column" fxFlex="45">
        <span><b>Add/Deduct List</b></span>
        <div fxLayout="row wrap" class="deductions-container">
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="deduction-checkbox-container"
            *ngFor="let addDeduct of selectedVehicle.addDeducts">
            <mat-checkbox
              (change)="calculateValues(selectedVehicle, blackbookSettings, blackbookValuesSetting, workflowMatchValue)"
              [(ngModel)]="addDeduct.selected">{{ addDeduct.name }}</mat-checkbox>
            <span>{{ addDeduct.rough | currency }}</span>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="45">
        <span class="totals-text"><b>Totals</b></span>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total-container">
          <span>Black Book Value</span>
          <span>{{ totals.value | currency }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total-container">
          <span>Add/Deducts</span>
          <span>{{ totals.addDeducts | currency }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total-container">
          <span>Marketing Costs</span>
          <span>{{ blackbookSettings.marketingCost | currency }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total-container">
          <span>Reconditioning Costs</span>
          <span>{{ blackbookSettings.reconditioningCost | currency }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total-container">
          <span>Offset</span>
          <mat-form-field fxFlex="35">
            <input [textMask]="{ mask: currencyMask }" class="mask-input" matInput [(ngModel)]="tradeOffset"
              (change)="calculateValues(selectedVehicle, blackbookSettings, blackbookValuesSetting, workflowMatchValue)" />
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="total-container">
          <span>Trade Value</span>
          <mat-form-field fxFlex="35">
            <input [textMask]="{ mask: currencyMask }" class="mask-input" matInput [(ngModel)]="tradeValue" (change)="
                calculateValues(
                  selectedVehicle,
                  blackbookSettings,
                  blackbookValuesSetting,
                  workflowMatchValue,
                  tradeValue
                )
              " />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxLayoutAlign="center center">
      <img src="assets/bb-logo.png" style="height:54px;" />
    </div>
  </div>
  <ng-template #loading>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-template>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button color="primary" (click)="onNoClick()">Cancel</button>
  <button mat-button color="primary" (click)="save(workflowMatchValue)">Save</button>
</div>