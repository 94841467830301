import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Class } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromAssociateClasses from './associate-classes.actions';
import { AssociateClassesState } from './associate-classes.interfaces';
@Injectable()
export class AssociateClassesEffects {
  
  getAssociateClassesFromServer = createEffect(() => this.dataPersistence.fetch(fromAssociateClasses.GET_ASSOCIATE_CLASSES_FROM_SERVER, {
    run: (action: fromAssociateClasses.GetAssociateClassesFromServer, state: any) => {
      if (state.crmDropdowns.associateClasses.ids.length == 0) {
        return this.apiService
          .get<Class[]>(`v/1/associates/associate-classes`, {
            params: action.payload,
          })
          .pipe(
            map((associateClasses) => {
              return new fromAssociateClasses.GetAssociateClassesFromServerSuccess({
                associateClasses: associateClasses,
              });
            })
          );
      }
    },
    onError: (action: fromAssociateClasses.GetAssociateClassesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<AssociateClassesState>,
    private apiService: ApiService
  ) {}
}
