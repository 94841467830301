import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Vendor } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface VendorsState extends EntityState<Vendor> {
  loading: boolean;
}

export const vendorsAdapter: EntityAdapter<Vendor> = createEntityAdapter<Vendor>({
  selectId: (vendor: Vendor) => vendor.id,
});

export const initialState: VendorsState = vendorsAdapter.getInitialState({ loading: true });
export const vendorsSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.vendors);

export const vendorsLoadingSelector = createSelector(vendorsSelector, (state: any) => state.loading);

export const { selectIds, selectEntities, selectAll, selectTotal } = vendorsAdapter.getSelectors(vendorsSelector);
