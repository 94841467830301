import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import {
  AssociateQueryParameters,
  AssociateReportQueryParameters,
  LocationQueryParameters,
  ProspectReportQueryParameters,
} from '@quorum/models/xs-query';
import {
  Address,
  Associate,
  AssociateReport,
  Location,
  Prospect,
  ProspectAction,
  ProspectReport,
  ResponseLead,
  SystemControlValue,
} from '@quorum/models/xs-resource';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
@Component({
  selector: 'crm-lead-matching-dialog',
  templateUrl: './lead-matching-dialog.component.html',
  styleUrls: ['./lead-matching-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadMatchingDialogComponent implements OnInit {
  @ViewChild('associateSearch') associateSearch: ElementRef;
  leadMatchingForm = this.fb.group({
    createOrMatch: [1, Validators.required],
    search: [''],
  });
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoadingExistingAssociates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  queryParamSubExistingAssociates$: any;
  matchedAssociate$: Observable<AssociateReport[]>;
  associates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  existingAssociates$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  existingAssociateMatches$: Observable<AssociateReport[]> | BehaviorSubject<AssociateReport[]>;

  selectedAssociate: AssociateReport = null;
  selectedLocation: Location;
  existingLocations: Location[];

  existingProspectsForSelectedAssociateQueryParamSub$: any;
  isLoadingExistingProspectsForSelectedAssociate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  existingProspects$: Observable<ProspectReport[]>;

  systemControls$: Subscription;
  customerUsingSoftwareAssociate: Associate;

  associateSearchTypeLookup: { name: string[]; email: string[]; phone: string[] } = {
    name: [],
    email: [],
    phone: [],
  };

  othersExistingProspects$: Observable<ProspectReport[]>;
  ownExistingProspects$: Observable<ProspectReport[]>;

  salespersonTakeoverData: { salespersonTakeoverMessage: string; salespersonTakeoverProspectAction: ProspectAction };

  constructor(
    private authenticationStateService: AuthenticationStateService,
    public dialogRef: MatDialogRef<LeadMatchingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { prospect: Prospect; latestResponseLead: ResponseLead },
    private fb: FormBuilder,
    private apiService: ApiService,
    private systemControlStateService: SystemControlStateService,
    private dropdownsStateService: DropdownsStateService
  ) {}

  ngOnInit() {
    this.existingProspectsForSelectedAssociateQueryParamSub$ = new BehaviorSubject<any>(null).pipe(
      mergeMap((queryParams: ProspectReportQueryParameters) => {
        if (queryParams) {
          return this.apiService.get<ProspectReport[]>('v/2/reporting/crm/prospects', { params: queryParams }).pipe(
            map((prospects: ProspectReport[]) => {
              this.isLoadingExistingProspectsForSelectedAssociate$.next(false);
              return prospects;
            })
          );
        } else {
          return of(null);
        }
      }),
      shareReplay(1)
    );

    this.existingProspects$ = this.existingProspectsForSelectedAssociateQueryParamSub$;

    this.othersExistingProspects$ = combineLatest([
      this.existingProspects$.pipe(
        filter((prospects: ProspectReport[]) => prospects != null),
        map((prospects: ProspectReport[]) => prospects)
      ),
      this.dropdownsStateService.selectEmployees().pipe(
        filter((employees) => !this.dropdownsStateService.isEmpty(employees)),
        map((employees) => employees.find((employee) => employee.networkUserName === 'ORPHAN'))
      ),
      this.authenticationStateService.selectAuthenticatedUser(),
      this.dropdownsStateService.selectProspectActions().pipe(
        filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions)),
        map((prospectActions) =>
          prospectActions.find((action) => action.description.toLowerCase() === 'prospect takeover')
        )
      ),
    ]).pipe(
      map(([prospects, orphanEmployee, authenticatedUser, takeoverProspectAction]) => {
        const filteredProspects = prospects.filter(
          (prospect: any) =>
            prospect.salespersonId !== authenticatedUser.user.xselleratorEmployeeId.toString() &&
            prospect.salespersonId !== orphanEmployee.associateId
        );

        const othersExistingProspect = filteredProspects[0];

        if (othersExistingProspect) {
          const salespersonTakeoverProspectAction: ProspectAction = {
            ...new ProspectAction(),
            appointmentDateTime: new Date(),
            enteredDateTime: new Date(),
            taskActionId: takeoverProspectAction.id,
            associateEmployeeId: authenticatedUser.user.xselleratorEmployeeId,
            prospectId: othersExistingProspect.id,
            previousSalespersonId: othersExistingProspect.salespersonId,
            newSalespersonId: authenticatedUser.user.xselleratorEmployeeId,
          };

          this.salespersonTakeoverData = {
            salespersonTakeoverMessage: `${authenticatedUser.user.firstName} ${
              authenticatedUser.user.lastName
            } has taken over Customer Prospect ID ${othersExistingProspect.id}, ${
              othersExistingProspect.associateId
                ? [othersExistingProspect.prospectFirstName, othersExistingProspect.prospectLastName]
                    .filter(Boolean)
                    .join(' ')
                : othersExistingProspect.prospectFreeformName
            }, from ${othersExistingProspect.salespersonFirstName} ${othersExistingProspect.salespersonLastName}`,
            salespersonTakeoverProspectAction: salespersonTakeoverProspectAction,
          };
        } else {
          this.salespersonTakeoverData = null;
        }
        return filteredProspects;
      }),
      tap((prospects) => {
        this.scrollToProspectData();
      })
    );

    this.ownExistingProspects$ = combineLatest([
      this.existingProspects$.pipe(
        filter((prospects: ProspectReport[]) => prospects != null),
        map((prospects: ProspectReport[]) => prospects)
      ),
      this.authenticationStateService.selectAuthenticatedUser(),
    ]).pipe(
      map(([prospects, authenticatedUser]) =>
        prospects.filter(
          (prospect) => prospect.salespersonId == authenticatedUser.user.xselleratorEmployeeId.toString()
        )
      ),
      tap((prospects) => {
        this.scrollToProspectData();
      })
    );

    this.queryParamSubExistingAssociates$ = new BehaviorSubject<any>(null).pipe(
      switchMap((queryParams: AssociateReportQueryParameters) => {
        if (queryParams) {
          return this.apiService.get<AssociateReport[]>('v/2/reporting/associates', { params: queryParams }).pipe(
            tap(() => this.isLoadingExistingAssociates$.next(false)),
            map((associates) => {
              return { queryParams: queryParams, associates: associates };
            })
          );
        } else {
          return of({ queryParams: {}, associates: null });
        }
      }),
      shareReplay(1)
    );

    this.existingAssociateMatches$ = this.queryParamSubExistingAssociates$.pipe(
      map((associatesObject: any) => {
        this.existingAssociates$.next(associatesObject.associates);
        return associatesObject.associates;
      })
    );

    this.leadMatchingForm['controls'].search.valueChanges
      .pipe(
        filter((input: string) => input != null && input.length >= 3),
        debounceTime(800),
        distinctUntilChanged()
      )
      .subscribe((value: any) => {
        if (value && value.length > 0) {
          this.selectedAssociate = null;
          this.selectAssociate(null);
          this.isLoadingExistingAssociates$.next(true);
          const queryParams: AssociateQueryParameters = new AssociateQueryParameters({
            search: value,
            embed: ['associate', 'addresses'],
          });
          <BehaviorSubject<AssociateQueryParameters>>this.queryParamSubExistingAssociates$.next(queryParams);
        }
      });

    if (this.data.latestResponseLead && this.data.latestResponseLead.cityName) {
      const queryParams: LocationQueryParameters = new LocationQueryParameters({
        search: this.data.latestResponseLead.cityName,
        pageSize: 2000,
      });
      this.apiService
        .get<Location[]>('v/2/associates/locations', {
          params: queryParams,
        })
        .pipe(take(1))
        .subscribe((locations) => (this.existingLocations = locations));
    }

    this.matchedAssociate$ = combineLatest([
      this.data.prospect.freeformName
        ? this.apiService.get<AssociateReport[]>('v/2/reporting/associates', {
            params: new AssociateReportQueryParameters({
              search: this.data.prospect.freeformName,
              embed: 'associate,addresses',
            }),
          })
        : of([]),
      this.data.prospect.emailAddress
        ? this.apiService.get<AssociateReport[]>('v/2/reporting/associates', {
            params: new AssociateReportQueryParameters({
              search: this.data.prospect.emailAddress,
              embed: 'associate,addresses',
            }),
          })
        : of([]),
      this.data.prospect.phoneNumber
        ? this.apiService.get<AssociateReport[]>('v/2/reporting/associates', {
            params: new AssociateReportQueryParameters({
              search: this.data.prospect.phoneNumber,
              embed: 'associate,addresses',
            }),
          })
        : of([]),
    ]).pipe(
      map(([nameMatchedAssociates, emailMatchedAssociates, phoneMatchedAssociates]) => {
        this.associateSearchTypeLookup.name = nameMatchedAssociates.map(
          (nameAssociate: AssociateReport) => nameAssociate.id
        );

        this.associateSearchTypeLookup.email = emailMatchedAssociates
          .filter((emailAssociate: AssociateReport) => {
            return this.associateSearchTypeLookup.name.indexOf(emailAssociate.id) == -1;
          })
          .map((emailAssociate: AssociateReport) => emailAssociate.id);

        this.associateSearchTypeLookup.phone = phoneMatchedAssociates
          .filter((phoneAssociate: AssociateReport) => {
            return (
              this.associateSearchTypeLookup.name.indexOf(phoneAssociate.id) == -1 &&
              this.associateSearchTypeLookup.email.indexOf(phoneAssociate.id) == -1
            );
          })
          .map((phoneAssociate: AssociateReport) => phoneAssociate.id);

        let concatArray: AssociateReport[] = [];
        if (phoneMatchedAssociates != null) {
          concatArray = concatArray.concat(phoneMatchedAssociates);
        }
        if (emailMatchedAssociates != null) {
          concatArray = concatArray.concat(emailMatchedAssociates);
        }
        if (nameMatchedAssociates != null) {
          concatArray = concatArray.concat(nameMatchedAssociates);
        }

        const idArray: any[] = [];
        const finalArray: any[] = [];

        concatArray.forEach((el: AssociateReport) => {
          if (idArray.indexOf(el.id) === -1) {
            idArray.push(el.id);
            finalArray.push(el);
          } else {
            finalArray.splice(finalArray.indexOf(finalArray.find((associate) => associate.id === el.id)), 1);
            finalArray.splice(0, 0, el);
          }
        });
        return finalArray;
      }),
      tap((finalArray) => {
        this.associates$.next(finalArray);
        this.isLoading$.next(false);
      }),
      shareReplay(1)
    );

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        take(1),
        map((employee) => employee.rooftopId)
      )
      .subscribe((rooftopId) => {
        this.systemControls$ = this.systemControlStateService
          .getSystemControlValue('CL_CUSTOMER_USING_SOFTWARE', rooftopId)
          .pipe(take(1))
          .subscribe((sct: SystemControlValue) => {
            const params: AssociateQueryParameters = new AssociateQueryParameters({
              embed: ['addresses'],
            });

            this.apiService
              .get<Associate>(`v/2/associates/associates/${sct.value}`, {
                params: params,
              })
              .subscribe(
                (associate) => {
                  this.customerUsingSoftwareAssociate = associate;
                },
                (error) => {}
              );
          });
      });
  }

  createAssociate(
    prospect: Prospect,
    dialogRef: MatDialogRef<LeadMatchingDialogComponent>,
    customerUsingSoftwareAssociate: Associate,
    latestResponseLead: ResponseLead
  ) {
    let newAddress: Address = new Address();
    if (latestResponseLead) {
      const fallbackAddress = customerUsingSoftwareAssociate.embedded.addresses[0];
      const cellPhone =
        prospect.cellPhoneNumber ?? latestResponseLead?.cellPhoneNumber ?? '';
      const homePhone =
        prospect.phoneNumber ?? latestResponseLead?.customerPhone ?? '';

      newAddress = {
        ...newAddress,
        addressLine1: latestResponseLead.addressLine1 ?? 'Please Update',
        countryId:
          this.selectedLocation?.countryId ??
          latestResponseLead?.countryId ??
          fallbackAddress.countryId,
        postalZipCode:
          this.selectedLocation?.postalCode ??
          latestResponseLead?.postalZipCode ??
          fallbackAddress.postalZipCode,
        provStateId:
          this.selectedLocation?.provStateId ??
          latestResponseLead?.provStateId ??
          fallbackAddress.provStateId,
        homePhoneNumber: homePhone,
        cellPhoneNumber: cellPhone,
        locationId: this.selectedLocation?.id ?? fallbackAddress.locationId,
      };

      if (
        latestResponseLead.provStateId &&
        latestResponseLead.provStateId !== customerUsingSoftwareAssociate.embedded.addresses[0].provStateId
      ) {
        const newLocation: Location = {
          ...new Location(),
          countryId: latestResponseLead.countryId,
          description: latestResponseLead.cityName ? latestResponseLead.cityName : 'Please update',
          postalCode: latestResponseLead.postalZipCode,
          provStateId: latestResponseLead.provStateId,
        };

        const queryParams: LocationQueryParameters = new LocationQueryParameters({
          search: newLocation.description,
          provStateId: latestResponseLead.provStateId,
        });
        this.apiService
          .get<Location[]>('v/2/associates/locations', {
            params: queryParams,
          })
          .pipe(
            take(1),
            switchMap((locations: Location[]) => {
              if (locations.length > 0) {
                newAddress.locationId = locations[0].id;
              } else {
                return this.apiService.post<Location>('v/2/associates/locations', newLocation).pipe(
                  take(1),
                  switchMap((newlyCreatedLocation: Location) => {
                    newAddress.locationId = newlyCreatedLocation.id;
                    return locations;
                  })
                );
              }
              return locations;
            })
          )
          .subscribe();
      }
    } else {
      newAddress = customerUsingSoftwareAssociate.embedded.addresses[0];
      newAddress = {
        ...newAddress,
        addressLine1: 'Please Update',
        homePhoneNumber: prospect.phoneNumber,
      };
    }

    const newAssociate: Associate = {
      ...new Associate(),
      classId: 'INDIV',
      firstName: prospect.freeformName.includes(',')
        ? prospect.freeformName.split(',')[1].trim()
        : prospect.freeformName,
      lastName: prospect.freeformName.includes(',') ? prospect.freeformName.split(',')[0].trim() : '',
      initialContactMethod: 'C',
      emailAddress: prospect.emailAddress,
      contactPreferenceId: prospect.emailAddress ? 'E' : 'L',
      messagePreferenceId: prospect.emailAddress ? 1 : null,
    };

    dialogRef.close({
      newAddress: newAddress,
      newAssociate: newAssociate,
      prospect: { ...prospect, matchedDate: new Date() },
    });
  }

  matchExistingAssociate(
    prospect: Prospect,
    dialogRef: MatDialogRef<LeadMatchingDialogComponent>,
    associateReport: AssociateReport,
    salespersonTakeoverData: { salespersonTakeoverMessage: string; salespersonTakeoverProspectAction: ProspectAction }
  ) {
    prospect = { ...prospect, associateId: this.selectedAssociate.id, matchedDate: new Date() };
    dialogRef.close({
      prospect: prospect,
      associate: associateReport.embedded.associate,
      address: associateReport.embedded.addresses,
      salespersonTakeoverData: salespersonTakeoverData,
    });
  }

  selectAssociate(associate: AssociateReport, type?: string) {
    if (this.selectedAssociate === associate) {
      this.selectedAssociate = null;
      this.existingAssociateMatches$.subscribe((previousExistingAssociates) => {
        this.existingAssociates$.next(previousExistingAssociates);
      });

      this.matchedAssociate$.subscribe((previousExistingAssociates) => {
        this.associates$.next(previousExistingAssociates);
      });

      this.isLoadingExistingProspectsForSelectedAssociate$.next(true);
      this.existingProspectsForSelectedAssociateQueryParamSub$.next(null);
    } else {
      this.selectedAssociate = associate;
      if (type === 'existing') {
        this.existingAssociates$.next([associate]);
      } else if (type === 'matched') {
        this.associates$.next([associate]);
      }

      const queryParams: ProspectReportQueryParameters = new ProspectReportQueryParameters({
        prospectStatusId: ['1'],
        associateId: [associate.id],
        showRecentShowroom: 'true',
        showRecentRetention: 'true',
      });
      this.existingProspectsForSelectedAssociateQueryParamSub$.next(queryParams);
    }
  }

  selectLocation(location: Location) {
    this.selectedLocation = location;
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  autoFocusSearch(value: number) {
    this.selectedAssociate = null;
    this.selectAssociate(null);
    if (value === 2) {
      setTimeout(() => this.associateSearch.nativeElement.focus(), 250);
    }
  }

  scrollToProspectData() {
    const prospectData = document.getElementById('prospectData');

    setTimeout(() => {
      if (prospectData) {
        prospectData.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    }, 500);
  }
}
