import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DealerPromotion } from '@quorum/models/xs-resource';

export interface ProspectDealerPromotionsState extends EntityState<DealerPromotion> {}

export const prospectDealerPromotionsdapter: EntityAdapter<DealerPromotion> = createEntityAdapter<DealerPromotion>({
  selectId: (dealerPromotion: DealerPromotion) => dealerPromotion.id,
});

export const initialState: ProspectDealerPromotionsState = prospectDealerPromotionsdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const prospectDealerPromotionsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectDealerPromotions
);

export const { selectIds, selectEntities, selectAll, selectTotal } = prospectDealerPromotionsdapter.getSelectors(
  prospectDealerPromotionsSelector
);
