import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { CrmAppointment as Appointment} from '@quorum/models/xs-resource';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-create-appointment-dialog',
  templateUrl: './create-appointment-dialog.component.html',
  styleUrls: ['./create-appointment-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAppointmentDialogComponent implements OnInit {
  appointmentTime: string;
  systemControls$: Observable<any>;

  appointmentForm = this.fb.group({
    appointmentDate: [Validators.required],
    appointmentTime: ['', Validators.required],
    employeeId: [Validators.required],
    confirmationAssociateId: [null],
    objectives: ['', Validators.required],
    prospectId: [],
    taskEmployeeId: [],
    communicatorConfirmation: [],
  });
  confirmationEmployees$: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<CreateAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { dropdowns: { salespeople: any }; newAppointment: Appointment; associateId: number },
    private fb: FormBuilder,
    private systemControlStateService: SystemControlStateService,
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService
  ) {}

  ngOnInit() {
    this.appointmentForm.patchValue({
      appointmentDate: this.data.newAppointment.dueDate,
      employeeId: this.data.newAppointment.employeeId,
      prospectId: this.data.newAppointment.prospectId,
      taskEmployeeId: this.data.newAppointment.taskEmployeeId,
    });

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        map((employee) => employee.rooftopId),
        take(1)
      )
      .subscribe((rooftopId) => {
        this.systemControls$ = this.systemControlStateService
          .getSystemControlValues(
            ['CL_SALES_APPOINTMENT_CONFIRMATIONS', 'CL_COMMUNICATOR_ENABLE', 'CL_COMMUNICATOR_SALES_APPT_CONFIRMATION'],
            rooftopId
          )
          .pipe(
            tap((systemControls) => {
              if (systemControls.CL_COMMUNICATOR_SALES_APPT_CONFIRMATION.value === '1') {
                this.appointmentForm.patchValue({
                  communicatorConfirmation: true,
                });
              }
            })
          );

        this.confirmationEmployees$ = this.apiService
          .get<any[]>(`v/1/associates/legacy/confirmation-employees/${rooftopId}`)
          .pipe(
            map((confirmationsEmployees) => {
              return confirmationsEmployees;
            })
          );
      });
  }

  mapDateTimes(dueDate: Date, time: string) {
    let timeDate = moment(time, 'hh:mm').toDate();
    if (time) {
      this.appointmentForm.get('appointmentDate').value.setHours(timeDate.getHours());
      this.appointmentForm.get('appointmentDate').value.setMinutes(timeDate.getMinutes());
    }
  }

  onSubmit() {
    const { appointmentTime, ...noApptTime } = this.appointmentForm.value;
    let newAppointment = { ...noApptTime, dueDate: noApptTime.appointmentDate };
    this.dialogRef.close({
      appointment: newAppointment,
      communicatorConfirmation: this.appointmentForm.get('communicatorConfirmation').value,
      associateId: this.data.associateId,
    });
  }
}
