<div fxLayout="column" fxFlexFill>
  <h1 mat-dialog-title>New Appointment</h1>
  <div mat-dialog-content fxFlex.xs *ngIf="systemControls$ | async as systemControls">
    <form fxLayout="column" [formGroup]="appointmentForm">
      <mat-form-field>
        <input
          matInput
          autocomplete="off"
          name="appointmentDate"
          formControlName="appointmentDate"
          (ngModelChange)="mapDateTimes($event, appointmentForm.value.appointmentTime)"
          [matDatepicker]="picker"
          placeholder="Date"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="time"
          name="time"
          formControlName="appointmentTime"
          (ngModelChange)="mapDateTimes(data.newAppointment.dueDate, appointmentForm.value.appointmentTime)"
          placeholder="Time"
          required
        />
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Employee" name="taskEmployeeId" formControlName="taskEmployeeId" required>
          <mat-option [value]="data.dropdowns.salespeople.employeeId.EmployeeId">
            {{ data.dropdowns.salespeople.employeeId.EmployeeName }}</mat-option
          >
          <mat-optgroup *ngFor="let salesTeam of data.dropdowns.salespeople.teams" [label]="salesTeam.teamName">
            <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
              {{ salesperson.EmployeeName }}</mat-option
            >
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-form-field *ngIf="systemControls.CL_SALES_APPOINTMENT_CONFIRMATIONS.value === 'Y'">
          <mat-select
            placeholder="Confirmation Employee"
            name="confirmationEmployeeId"
            formControlName="confirmationAssociateId"
            *ngIf="confirmationEmployees$ | async as confirmationEmployees; else confirmationEmployeesLoading"
          >
            <mat-option
              *ngFor="let confirmationEmployee of confirmationEmployees"
              [value]="confirmationEmployee.employeeId"
              >{{ confirmationEmployee.employeeName }}</mat-option
            >
          </mat-select>
          <ng-template #confirmationEmployeesLoading>
            <mat-select placeholder="Confirmation Employee" [disabled]="true"></mat-select>
          </ng-template>
        </mat-form-field>
      </div>
      <mat-form-field>
        <textarea
          name="objectives"
          autocomplete="false"
          rows="5"
          matInput
          placeholder="Objectives"
          formControlName="objectives"
          required
        ></textarea>
      </mat-form-field>

      <div
        fxLayoutAlign="space-between center"
        *ngIf="
          systemControls.CL_COMMUNICATOR_ENABLE.value === '1' &&
          systemControls.CL_COMMUNICATOR_SALES_APPT_CONFIRMATION.value === '1' &&
          data.associateId != null
        "
      >
        <mat-slide-toggle color="primary" formControlName="communicatorConfirmation" labelPosition="before">
          Communicator Confirmation
        </mat-slide-toggle>
      </div>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="">Cancel</button>
    <button mat-button [disabled]="!appointmentForm.valid" (click)="onSubmit()" color="primary">Create</button>
  </div>
</div>
