import { Action } from '@ngrx/store';
import { VendorQueryParameters } from '@quorum/models/xs-query';
import { Vendor } from '@quorum/models/xs-resource';

export const GET_VENDORS_FROM_SERVER = '[CRM] Get Vendors From Server';
export class GetVendorsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VENDORS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VendorQueryParameters) {}
}

export const GET_VENDORS_FROM_SERVER_SUCCESS = '[CRM] Get Vendors From Server Success';
export class GetVendorsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VENDORS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vendors: Vendor[] }) {}
}

export const GET_VENDORS_FROM_SERVER_FAILURE = '[CRM] Get Vendors From Server Failure';
export class GetVendorsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VENDORS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VendorsAction = GetVendorsFromServer | GetVendorsFromServerSuccess | GetVendorsFromServerFailure;
