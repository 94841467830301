import { Associate } from '../associates/associate.model';
import { Employee } from '../associates/employee.model';
import { Prospect } from './prospect.model';
import { TaskOutcome } from './task-outcome.model';

export class Task {
  availableDate: Date = null;
  comments: string = null;
  communicatorConversationId: number = null;
  communicatorTaskSpecificTemplate: string = null;
  communicatorTemplate: string = null;
  completedDate: Date = null;
  contactPreferenceId: string = null;
  createdDate?: Date = null;
  dueDate: Date = null;
  embedded?: TaskEmbed = null;
  employeeId: string = null;
  escalateFromDate: Date = null;
  escalateToDate: Date = null;
  id: number = null;
  lastModifiedDate?: Date = null;
  letterNumber: string = null;
  mergeId: number = null;
  note: string = null;
  outcomeId: number = null;
  prepWork: string = null;
  previousTaskEmployeeId: number = null;
  prospectId: number = null;
  taskEmployeeId: string = null;
  telephoneContact: string = null;
  templateId: number = null;
  templateSequence: number = null;

  constructor(obj?: Task) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class TaskEmbed {
  employee?: Employee = null;
  employeeAssociate?: Associate = null;
  previousTaskEmployeeAssociate?: Associate = null;
  prospect?: Prospect = null;
  taskEmployeeAssociate?: Associate = null;
  taskOutcome?: TaskOutcome = null;
}
