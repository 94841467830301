import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import {
  ProspectChooseEmailPhoneDialogComponent,
  ProspectCreateDialogComponent,
  ProspectCreateProgressDialogComponent,
} from '@quorum/crm-prospects-ng-ui';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { CrmSettingsState } from '@quorum/crm-settings/state';
import { CreateAppointmentDialogComponent, CreateTaskDialogComponent } from '@quorum/crm/ui/ng/activities';
import { ProspectActionQueryParameters, TaskQueryParameters, SaleQueryParameters } from '@quorum/models/xs-query';
import {
  CrmAppointment as Appointment,
  Associate,
  AuthenticatedUser,
  Class,
  CrmVehicleSearch,
  Employee,
  OemRooftopLink,
  Prospect,
  ProvinceState,
  ResponseLead,
  SalesPerson,
  SalesTeamMaintenance,
  SystemControlValue,
  Task,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import {
  ActivitiesStateService,
  LocalSettingsState,
  LocalSettingsStateService,
  SalesPlannerStateService,
  SelectedActivityStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { BrowserControlCommunicationService } from 'libs/sha-services/src/lib/browserControlCommunication.service';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as CommunicatorActions from '../../../../communicator-ngrx/src/lib/+state/communicator.actions';
import { RooftopSelectionDialog } from '../rooftop-selection-dialog/rooftop-selection-dialog.component';

@Component({
  selector: 'crm-sales-planner-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('spinInOut', [
      state('in', style({ transform: 'rotate(0)', opacity: '1' })),
      transition(':enter', [style({ transform: 'rotate(-180deg)', opacity: '0' }), animate('150ms ease')]),
      transition(':leave', [animate('150ms ease', style({ transform: 'rotate(180deg)', opacity: '0' }))]),
    ]),
    trigger('preventInitialAnimation', [transition(':enter', [query(':enter', [], { optional: true })])]),
  ],
})
export class ShellComponent implements OnInit, OnDestroy {
  authenticatedEmployee: Employee;
  authenticatedUser: AuthenticatedUser;
  autovanceEnabled: boolean;
  createProspectProgressRef: MatDialogRef<any>;
  drilldownEnabled: boolean;
  dropdowns$: Observable<any>;
  localSettings$: Subscription;
  localSettings: LocalSettingsState;
  postProspectProcessSubscription: Subscription;
  postProspectUpdateProcessSubscription: Subscription;
  salespeople$: Observable<SalesPerson[]>;
  selectedProspect$: Observable<Prospect>;
  settings: CrmSettingsState;
  settingsSubscription$: Subscription;
  sidenav: boolean;
  sidenavStatus$: Subscription;
  view: string;
  teamLeads$: Subscription;
  teamLead: boolean;
  opportunityActionId: number;
  avDealCreateRooftop: string;
  latestResponseLead: ResponseLead;
  responseLeads$: Observable<ResponseLead[]>;
  choosePhoneEmailRef: MatDialogRef<ProspectChooseEmailPhoneDialogComponent>;

  @ViewChild('snav') public sidenavComponent: MatSidenav;
  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private browserControlCommunicationService: BrowserControlCommunicationService,
    private crmEntityStateService: CrmEntityStateService,
    private dropdownsStateService: DropdownsStateService,
    private localSettingsStateService: LocalSettingsStateService,
    public route: ActivatedRoute,
    private routerStateService: RouterStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    private settingsStateService: CrmSettingsStateService,
    private snackBar: MatSnackBar,
    private systemControlStateService: SystemControlStateService,
    public activitiesStateService: ActivitiesStateService,
    public dialog: MatDialog,
    public salesPlannerStateService: SalesPlannerStateService,
    private actions$: Actions
  ) {}

  ngOnInit() {
    this.localSettings$ = this.localSettingsStateService
      .selectLocalSettingsFromState()
      .subscribe((ls) => (this.localSettings = ls));
    this.salespeople$ = this.dropdownsStateService
      .selectSalespeople()
      .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)));

    this.selectedProspect$ = this.selectedActivityStateService.selectSelectedProspect();

    this.drilldownEnabled = this.browserControlCommunicationService.drilldownEnabled;
    this.routerStateService.selectRouterState().subscribe((routerState) => {
      this.view = routerState.state.url === '/crm/sales-planner' ? 'master' : 'detail';
    });
    this.settingsSubscription$ = this.settingsStateService
      .selectSettingsFromState()
      .subscribe((settings) => (this.settings = settings));

    this.dropdowns$ = combineLatest(
      this.dropdownsStateService.selectChannels().pipe(
        filter((channels) => !this.dropdownsStateService.isEmpty(channels)),
        map((channels) => channels.filter((channel) => channel.description.trim().toLowerCase() !== 'lead generator'))
      ),
      this.dropdownsStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes))),
      this.dropdownsStateService.selectMessageTypes().pipe(
        filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes)),
        map((messageTypes) => messageTypes.filter((mt) => mt.displayInXselleratorUIFlag))
      ),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => {
          return !this.dropdownsStateService.isEmpty(rooftops);
        }),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService.selectSources().pipe(
        filter((sources) => !this.dropdownsStateService.isEmpty(sources)),
        map((sources) =>
          sources.filter(
            (source) =>
              !['available', 'over mileage', 'sub-prime', 'service appointments'].includes(
                source.description.trim().toLowerCase()
              )
          )
        )
      ),
      this.dropdownsStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownsStateService.isEmpty(associateClasses))),
      this.dropdownsStateService
        .selectProspectActions()
        .pipe(filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions))),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(
        ([
          channels,
          customerTypes,
          messageTypes,
          rooftops,
          salespeople,
          sources,
          associateClasses,
          prospectActions,
          provStates,
        ]) => {
          return {
            channels,
            customerTypes,
            messageTypes,
            rooftops,
            salespeople,
            sources,
            associateClasses,
            prospectActions,
            provStates,
          };
        }
      )
    );

    this.dropdownsStateService
      .selectProspectActions()
      .pipe(
        filter((prospectActions) => prospectActions != null && prospectActions.length > 0),
        take(1)
      )
      .subscribe((prospectActions) => {
        this.opportunityActionId = prospectActions.find((ac) => ac.description === 'Opportunity').id;
        this.postProspectProcessSubscription = this.crmEntityStateService
          .postProspectCreationProcess(this.opportunityActionId)
          .subscribe((prospect) => {
            if (this.createProspectProgressRef) {
              this.createProspectProgressRef.close();
            }
            this.routerStateService.go([`sales-planner/prospects/${prospect.id}`], {
              relativeTo: this.route.parent,
            });
          });
      });

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => {
          return employee != null;
        }),
        take(1)
      )
      .subscribe((authEmployee: Employee) => {
        this.authenticatedEmployee = authEmployee;
        this.systemControlStateService
          .getSystemControlValues(
            [
              'CL_AUTOVANCE_ENABLED',
              'CL_COMMUNICATOR_ENABLE',
              'CL_CUSTOMER_USING_SOFTWARE',
              'CL_COMMUNICATOR_SALES_APPT_CONFIRMATION',
              'CL_AUTOVANCE_DEAL_CREATE_ROOFTOP',
              'CL_SALES_APPOINTMENT_CONFIRMATIONS',
              'CL_DEF_VEH_PRICE_FOR_VS_CHRGS',
            ],
            authEmployee.rooftopId
          )
          .pipe(take(1))
          .subscribe((results: any) => {
            this.autovanceEnabled = results['CL_AUTOVANCE_ENABLED'].value === '1' ? true : false;
            this.avDealCreateRooftop = results['CL_AUTOVANCE_DEAL_CREATE_ROOFTOP'].value;
          });
      });

    this.authenticationStateService.selectAuthenticatedUser().subscribe((user) => (this.authenticatedUser = user));

    this.salesPlannerStateService.listenForToasts().subscribe((toastOptions) => {
      this.snackBar.open(toastOptions.message, toastOptions.actionText, toastOptions.options);
    });

    this.teamLeads$ = this.dropdownsStateService
      .selectSalesTeams()
      .pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        map((teamLeads) => teamLeads.filter((teamLead: any) => teamLead.teamLead === true)),
        take(1)
      )
      .subscribe((teamLeads: SalesTeamMaintenance[]) => {
        if (
          teamLeads.find(
            (teamLead: SalesTeamMaintenance) =>
              teamLead.employeeId === this.authenticatedUser.user.xselleratorEmployeeId
          ) !== undefined
        ) {
          this.teamLead = true;
        }
      });

    this.responseLeads$ = this.selectedActivityStateService.selectSelectedProspectResponseLeads();
    this.responseLeads$.subscribe((responseLeads) => {
      this.latestResponseLead = responseLeads[responseLeads.length - 1];
    });
    this.postProspectUpdateProcessSubscription = this.crmEntityStateService
      .postProspectCreateAndUpdate()
      .subscribe((prospect: Prospect) => {
        if (this.createProspectProgressRef) {
          this.createProspectProgressRef.close();
        }
      });
  }

  ngOnDestroy() {
    this.postProspectProcessSubscription.unsubscribe();
    this.postProspectUpdateProcessSubscription.unsubscribe();
    this.localSettings$.unsubscribe();
    this.teamLeads$.unsubscribe();
  }
  openCreateProspectDialog(
    authenticatedUser: AuthenticatedUser,
    dialog: MatDialog,
    dropdowns: any,
    authenticatedEmployee: Employee,
    opportunityActionId: number
  ): void {
    this.systemControlStateService
      .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
      .pipe(take(1))
      .subscribe((emailAddressSystemControl: SystemControlValue) => {
        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (emailAddressSystemControl.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef = dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: dropdowns,
            newProspect: {
              ...new Prospect(),
              associateId: null,
              customerTypeId: '3',
              employeeCreatedId: authenticatedUser.user.xselleratorEmployeeId,
              rooftopId: authenticatedEmployee.rooftopId,
              primaryEmployeeId: authenticatedUser.user.xselleratorEmployeeId,
              statusId: '1',
              typeId: 3,
              actionId: opportunityActionId,
            },
            newAssociate: { ...new Associate(), classId: 'INDIV', initialContactMethod: 'C' },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields: {
              associate: ['classId', 'emailAddress'],
              prospect: [],
              contact: [],
            },
          },
          height: '100vh',
          panelClass: 'responsive-dialog',
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
          }
        });
      });
  }

  openCreateTaskDialog(
    authenticatedUser: AuthenticatedUser,
    dialog: MatDialog,
    dropdowns: any,
    selectedProspect: Prospect,
    activitiesStateService: ActivitiesStateService
  ): void {
    let newTask: Task = {
      ...new Task(),
      dueDate: new Date(),
      employeeId: authenticatedUser.user.xselleratorEmployeeId,
      prospectId: selectedProspect.id,
      taskEmployeeId: authenticatedUser.user.xselleratorEmployeeId,
    };
    const dialogRef = dialog.open(CreateTaskDialogComponent, {
      data: { dropdowns: dropdowns, newTask: newTask },
      panelClass: 'responsive-dialog',
    });

    dialogRef.afterClosed().subscribe((newTask) => {
      if (newTask) {
        activitiesStateService.createTask(newTask);
      }
    });
  }

  openCreateAppointmentDialog(
    authenticatedUser: AuthenticatedUser,
    dialog: MatDialog,
    dropdowns: any,
    selectedProspect: Prospect,
    activitiesStateService: ActivitiesStateService
  ): void {
    let newAppointment: Appointment = {
      ...new Appointment(),
      dueDate: new Date(),
      employeeId: authenticatedUser.user.xselleratorEmployeeId,
      prospectId: selectedProspect.id,
      taskEmployeeId: selectedProspect.primaryEmployeeId,
    };

    const dialogRef = dialog.open(CreateAppointmentDialogComponent, {
      data: { dropdowns: dropdowns, newAppointment: newAppointment, associateId: selectedProspect.associateId },
      panelClass: 'responsive-dialog',
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        activitiesStateService.createSalesAppointment(data.appointment, data.communicatorConfirmation);
        this.actions$
          .pipe(ofType(CommunicatorActions.sendMessageToConversationSuccess), take(1))
          .subscribe((success) => {
            this.selectedActivityStateService.createSentEmailTextProspectAction(
              selectedProspect,
              dropdowns,
              this.authenticatedUser.user
            );
          });
      }
    });
  }

  openVehicleSearch(prospectId: number, route: ActivatedRoute) {
    let queryParams: any = {};
    this.apiService
      .get<CrmVehicleSearch[]>(`v/1/crm/prospects/${prospectId}/vehicle-search`)
      .pipe(take(1))
      .subscribe((vehicleSearch) => {
        let rpos: string[] = [];
        let performVehicleSearch: boolean;
        Object.keys(vehicleSearch).forEach((key) => {
          if (vehicleSearch[key] && key !== 'prospectId' && key !== 'associateId') {
            performVehicleSearch = true;
            if (key.includes('rpo')) {
              rpos.push(vehicleSearch[key]);
            } else {
              queryParams[key] = vehicleSearch[key];
            }
          }
        });
        if (performVehicleSearch) {
          queryParams = { ...queryParams, rpos: rpos.join() };
        }

        this.routerStateService.go([`prospects/${prospectId}/vehicle-search`], {
          queryParams: queryParams,
          relativeTo: route,
          queryParamsHandling: 'merge',
        });
      });
  }

  openStandaloneVehicleSearch(prospectId: number) {
    this.routerStateService.go([`crm/vehicle-search`], {
      queryParams: {},
    });
  }

  openDealerPromo(localSettings: LocalSettingsState) {
    let params: string[] = localSettings.salesPeople;
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_dealer_promotions', params);
  }

  openProspectTemplate(prospect: Prospect) {
    let params: string[] = [prospect.id.toString(), prospect.rooftopId.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_prospect_template_veh_cust', params);
  }

  createVehicleSale(prospect: Prospect, drilldownEnabled: boolean) {
    let params: string[] = [
      'null',
      '1',
      prospect.associateId ? prospect.associateId.toString() : 'null',
      prospect.id.toString(),
      prospect.rooftopId.toString(),
      drilldownEnabled ? 'true' : 'false',
    ];
    this.browserControlCommunicationService.drilldown('createSale', 'gf_create_vehicle_sale', params);

    // if (!drilldownEnabled) {
    //   this.browserControlCommunicationService.receivePowerBuilderMessage().then((messageObject: any) => {
    //     if (messageObject.VehicleSaleId !== '') {
    //       let toastOptions: any = {
    //         message: `Sale ${messageObject.VehicleSaleId} created.`,
    //         actionText: null,
    //         options: { duration: 3000 }
    //       };

    //       this.salesPlannerStateService.addToast(toastOptions);
    //     }
    //   });
    // }
    let updatedProspect: Prospect = new DeepCopyPipe().transform(prospect);
    let queryParams: SaleQueryParameters;
    if (prospect.typeId === 3) {
      queryParams = new SaleQueryParameters({
        showroomProspectId: prospect.id.toString(),
        embed: 'vehicle',
      });
    } else if (prospect.typeId === 4) {
      queryParams = new SaleQueryParameters({
        retentionProspectId: prospect.id.toString(),
        embed: 'vehicle',
      });
    }

    this.selectedActivityStateService.getSelectedProspectOpenSalesFromServer(queryParams);
    this.selectedActivityStateService.getSelectedProspectActionsFromServer({
      prospectId: prospect.id,
      prospectActionQueryParams: new ProspectActionQueryParameters({
        embed: ['employeeAssociate', 'action'],
      }),
    });
    this.selectedActivityStateService.getSelectedProspectTasksFromServer(
      new TaskQueryParameters({ prospectId: prospect.id, embed: 'prospect,taskEmployeeAssociate,taskOutcome' })
    );
    this.crmEntityStateService.updateProspectOnServer({ ...updatedProspect, updatedDate: new Date() }, false);
  }

  createDeskedDeal(prospect: Prospect, avDealCreateRooftop: string, dropdowns: any) {
    let payload: any = {
      associateId: prospect.associateId,
      autovanceUserGuid: this.settings.autovanceUserGuid,
      currentRooftopId: prospect.rooftopId,
      openVsWindows: 0,
      prospectId: prospect.id,
      salespersonId: prospect.primaryEmployeeId,
      vehicleId: null,
      vehicleSearch: prospect.associateId ? true : false,
      vehicleStatus: null,
      rooftopOverride: false,
    };
    if (avDealCreateRooftop === '2') {
      const dialogRef = this.dialog.open(RooftopSelectionDialog, {
        width: '450px',
        height: '300px',
        data: { dropdowns: dropdowns },
        panelClass: 'responsive-dialog',
      });

      dialogRef.afterClosed().subscribe((rooftopId) => {
        if (rooftopId) {
          payload = { ...payload, currentrooftopId: rooftopId, rooftopOverride: true };
          this.postAutovanceSale(prospect, payload);
        }
      });
    } else {
      this.postAutovanceSale(prospect, payload);
    }
  }

  postAutovanceSale(prospect: Prospect, payload: any) {
    this.apiService
      .post<any>(`v/1/vehicles/sales/autovance`, payload)
      .pipe(take(1))
      .subscribe((sale) => {
        if (this.settings.autovanceDeskRole != null && this.settings.autovanceDeskRole != 0) {
          if (this.drilldownEnabled) {
            let params: string[] = [sale.dealUrl, sale.dealXsSaleId.toString()];
            this.browserControlCommunicationService.drilldown('autovanceCreateDeal', 'w_autovance_desk', params);
          } else {
            window.open(`${sale.dealUrl}`, '_blank');
          }
        } else {
          let toastOptions: any = {
            message: `Desk deal ${sale.dealXsSaleId} was created.`,
            actionText: null,
            options: { duration: 6000 },
          };

          this.salesPlannerStateService.addToast(toastOptions);
        }
        let queryParams: SaleQueryParameters;
        if (prospect.typeId === 3) {
          queryParams = new SaleQueryParameters({
            showroomProspectId: prospect.id.toString(),
            embed: 'vehicle',
          });
        } else if (prospect.typeId === 4) {
          queryParams = new SaleQueryParameters({
            retentionProspectId: prospect.id.toString(),
            embed: 'vehicle',
          });
        }
        this.selectedActivityStateService.getSelectedProspectOpenSalesFromServer(queryParams);
        this.selectedActivityStateService.getSelectedProspectActionsFromServer({
          prospectId: prospect.id,
          prospectActionQueryParams: new ProspectActionQueryParameters({
            embed: ['employeeAssociate', 'action'],
          }),
        });
        this.selectedActivityStateService.getSelectedProspectTasksFromServer(
          new TaskQueryParameters({ prospectId: prospect.id, embed: 'prospect,taskEmployeeAssociate,taskOutcome' })
        );
        this.crmEntityStateService.updateProspectOnServer({ ...prospect, updatedDate: new Date() }, false);
      });
  }
}
