import { Associate } from '../associates/associate.model';
import { Employee } from '../associates/employee.model';
import { Prospect } from '../crm/prospect.model';
import { TaskOutcome } from '../crm/task-outcome.model';

export class Appointment {
  appointmentDate: Date = null;
  attendanceFlag: string = null;
  availableDate: Date = null;
  comments: string = null;
  completedDate: Date = null;
  confirmationAssociateId: string = null;
  confirmationDate: Date = null;
  confirmationFlag: number = null;
  contactPreferenceId: string = null;
  createdDate?: Date = null;
  dueDate: Date = null;
  embedded?: AppointmentEmbed = null;
  employeeId: string = null;
  escalateFromDate: Date = null;
  escalateToDate: Date = null;
  id: number = null;
  lastModifiedDate?: Date = null;
  letterNumber: string = null;
  mergeId: number = null;
  objectives: string = null;
  outcomeId: number = null;
  prepWork: string = null;
  previousTaskEmployeeId: string = null;
  prospectId: number = null;
  taskEmployeeId: string = null;
  telephoneContact: string = null;
  templateId: number = null;
  templateSequence: number = null;

  constructor(obj?: Appointment) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class AppointmentEmbed {
  confirmationAssociate?: Associate = null;
  employee?: Employee = null;
  employeeAssociate?: Associate = null;
  previousTaskEmployee?: Associate = null;
  prospect?: Prospect = null;
  taskEmployee?: Employee = null;
  taskEmployeeAssociate?: Associate = null;
  taskOutcome?: TaskOutcome = null;
}
