import { HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventTypes } from '@models/server-common';
import { Actions, ofType } from '@ngrx/effects';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { CommunicatorActions, CommunicatorFacade } from '@quorum/communicator-ngrx';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
import {
  Address,
  Associate,
  Conversation,
  DirectMessageTemplate,
  Employee,
  Event,
  HtmlMappingTemplate,
  Member,
  Prospect,
  SystemControlValue,
  Task,
  TaskOutcome,
  Template,
  TokenUser,
  XselleratorConversation,
} from '@quorum/models/xs-resource';
import { EmailTemplateDialogComponent, TextMessageDialogComponent } from '@quorum/sha-components-ng-ui';
import {
  ActivitiesStateService,
  SalesPlannerStateService,
  SelectedActivityStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CommunicatorDialogEditorComponent } from '@quorum/xsr-template-editor-ng-ui';
import { Attachment } from 'libs/xsr-template-editor-ng-ui/src/lib/communicator-dialog-editor/communicator-dialog-editor.component';
import { combineLatest, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardComponent implements OnInit, OnChanges {
  @Input() task: Task;
  @Input() prospect: Prospect = new Prospect();
  @Input() associate: Associate = new Associate();
  @Input() address: Address = new Address();
  @Input() dropdowns: any;
  taskCopy: Task = new Task();
  taskOutcomes$: Observable<TaskOutcome[]>;
  outcomeAction: string = '';
  taskCardForm: FormGroup = this.fb.group({
    dueDate: [],
    outcomeId: [],
    note: [],
    prepWork: [],
    comments: [],
  });
  directMessageTemplates$: Observable<DirectMessageTemplate[]>;
  htmlMappingTemplates$: Observable<HtmlMappingTemplate[]>;
  authenticatedEmployee: Employee;
  communicatorEnabled: boolean;
  currentUser: TokenUser;
  @ViewChild('comments') comments: ElementRef;
  constructor(
    private activitiesStateService: ActivitiesStateService,
    private dropdownsStateService: DropdownsStateService,
    private fb: FormBuilder,
    private authenticationStateService: AuthenticationStateService,
    private systemControlStateService: SystemControlStateService,
    private apiService: ApiService,
    public dialog: MatDialog,
    public communicatorFacade: CommunicatorFacade,
    private salesPlannerStateService: SalesPlannerStateService,
    private actions$: Actions,
    private selectedActivityStateService: SelectedActivityStateService
  ) {}

  ngOnInit() {
    this.taskOutcomes$ = this.dropdownsStateService.selectTaskOutcomes().pipe(
      map((taskOutcomes) => {
        return taskOutcomes.filter((taskOutcome) => taskOutcome.description.toLowerCase() != 'auto closed');
      })
    );

    this.directMessageTemplates$ = this.dropdownsStateService.selectTextMessageTemplates().pipe(
      map((templates) => {
        return templates.filter((template) => ['NEW', 'USE', 'LEA', 'UNA', 'GEN'].includes(template.departmentId));
      })
    );
    this.htmlMappingTemplates$ = this.dropdownsStateService.selectHtmlMappingTemplates();

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        take(1),
        tap((employee) => (this.authenticatedEmployee = employee))
      )
      .subscribe((employee) => {
        this.systemControlStateService
          .getSystemControlValue('CL_COMMUNICATOR_ENABLE', this.authenticatedEmployee.rooftopId)
          .subscribe((systemControl: SystemControlValue) => {
            this.communicatorEnabled = systemControl.value === '1';
          });
      });

    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(
        take(1),
        tap((user) => (this.currentUser = user.user))
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.task?.currentValue) {
      this.taskCopy = new DeepCopyPipe().transform(changes.task.currentValue);
    }

    this.taskCardForm.patchValue({
      dueDate: this.taskCopy.dueDate,
      outcomeId: this.taskCopy.outcomeId,
      note: this.taskCopy.note,
      prepWork: this.taskCopy.prepWork,
      comments: this.taskCopy.comments,
    });

    if (!this.taskCardForm.get('outcomeId').value) {
      this.taskCardForm.get('comments').clearValidators();
      this.taskCardForm.get('comments').updateValueAndValidity();
    }
  }

  outcomeSelected(event: any) {
    this.taskOutcomes$
      .pipe(
        map((outcomes) => {
          return outcomes.find((outcome) => outcome.id == event.value);
        }),
        take(1)
      )
      .subscribe((outcome) => {
        this.taskCardForm.get('comments').setValidators(Validators.required);
        this.taskCardForm.get('comments').updateValueAndValidity();
        setTimeout(() => this.comments.nativeElement.focus(), 250);

        if (outcome.daysLater > 0) {
          this.outcomeAction = `Selecting this outcome will create a new task ${outcome.daysLater} days later`;
        } else {
          this.outcomeAction = '';
        }
      });
  }

  updateTask(task: Task): void {
    if (task.outcomeId) {
      task.completedDate = new Date();

      this.dropdownsStateService
        .selectTaskOutcomes()
        .pipe(
          map((outcomes) => {
            return outcomes.find((outcome) => outcome.id == task.outcomeId);
          }),
          take(1)
        )
        .subscribe((outcome: TaskOutcome) => {
          if (outcome && outcome.daysLater > 0) {
            let newTask: Task = JSON.parse(JSON.stringify(this.taskCopy));
            let newDueDate = new Date(newTask.dueDate);
            newDueDate.setDate(newDueDate.getDate() + outcome.daysLater);
            newTask.dueDate = newDueDate;
            this.activitiesStateService.createTask(newTask);
          }
        });
    }
    this.activitiesStateService.updateTaskOnServer({
      ...this.taskCopy,
      comments: task.comments,
      dueDate: task.dueDate,
      note: task.note,
      outcomeId: task.outcomeId,
      completedDate: task.completedDate ? task.completedDate : null,
      prepWork: task.prepWork,
    });
  }

  openCommunicatorTextDialog(
    directMessageTemplates: DirectMessageTemplate[],
    prospect: Prospect,
    currentUser: TokenUser,
    task: Task
  ) {
    let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
      messageTypeId: 18,
      transactionId: prospect.id,
      transactionTypeId: 5,
    });

    let conversation: Conversation = new Conversation({
      dmsMessageTypeId: 18,
      dmsTransactionTypeId: 5,
      dmsTransactionId: prospect.id,
      createdBy: currentUser.id,
    });

    let members: Member[] = [];
    members.push(
      new Member({
        userId: currentUser.id.toString(),
        avatarUrl: currentUser.avatarUrl,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        nickname: currentUser.firstName,
        isEmployee: true,
        isRead: true,
        isArchived: false,
        isExcluded: false,
        isPinnedToUi: false,
      })
    );

    members.push(
      new Member({
        userId: prospect.embedded.associate.id,
        firstName: prospect.embedded.associate.firstName,
        lastName: prospect.embedded.associate.lastName,
        nickname: prospect.embedded.associate.firstName,
        isEmployee: false,
        isRead: true,
        isArchived: false,
        isExcluded: false,
        isPinnedToUi: false,
      })
    );

    let event: Event = new Event({
      conversationId: null,
      memberId: currentUser.id.toString(),
      eventTypeId: EventTypes.Message,
      content: 'Generating message...',
      guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      sentDate: new Date(),
      sent: false,
      sendToCustomer: true,
      id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
    });

    let toastOptions: any = {
      message: `Communicator message sent.`,
      actionText: null,
      options: { duration: 3000 },
    };

    combineLatest([
      this.actions$.pipe(ofType(CommunicatorActions.sendMessageToConversationSuccess), take(1)),
      this.taskOutcomes$.pipe(take(1)),
    ])
      .pipe(
        map(([sendMessageToConversationSuccess, taskOutcomes]) => {
          return { sendMessageToConversationSuccess, taskOutcomes };
        })
      )
      .subscribe((data) => {
        let completedOutcome = data.taskOutcomes?.find(
          (outcome) => outcome.description?.trim().toLowerCase() == 'completed'
        );

        this.taskCardForm.patchValue({
          outcomeId: completedOutcome.id,
          comments: 'Text sent to the customer',
        });

        this.updateTask(this.taskCardForm.value);

        this.selectedActivityStateService.createSentEmailTextProspectAction(prospect, this.dropdowns, this.currentUser);
      });

    this.apiService.get(`/quorum/stores/${currentUser.storeId}`).subscribe((response) => {
      // QTemplates enabled
      if (response.useCommunicatorCloudServices) {
        combineLatest([
          task.communicatorTaskSpecificTemplate
            ? this.apiService.get(
                `v/1/quorum/templates/item-specific/${currentUser.destination}/${task.communicatorTaskSpecificTemplate}`
              )
            : task.communicatorTemplate
            ? this.apiService.get(
                `v/1/quorum/templates/store-gallery/${currentUser.destination}/${task.communicatorTemplate}`
              )
            : of(null),

          this.apiService.get(`v/1/quorum/templates/store-gallery/${currentUser.destination}`, {
            params: { messageTypeId: 18 },
          }),
        ])
          .pipe(
            map(([communicatorTaskSpecificTemplate, communicatorTemplates]) => {
              return {
                communicatorTaskSpecificTemplate: communicatorTaskSpecificTemplate,
                communicatorTemplates: communicatorTemplates,
              };
            })
          )
          .subscribe((data) => {
            const dialogRef = this.dialog.open(CommunicatorDialogEditorComponent, {
              data: {
                communicatorTaskSpecificTemplate: data.communicatorTaskSpecificTemplate,
                currentUser: currentUser,
                messageType: 'text',
                templates: data.communicatorTemplates,
              },
              height: '100%',
              width: '100%',
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult && dialogResult.template) {
                this.apiService
                  .post(`v/1/quorum/templates/item-specific/${currentUser.destination}`, dialogResult.template)
                  .subscribe((itemSpecificTemplate: Template) => {
                    /* if the task has a specificTemplate attached - use its messageTypeId for params and conversation obj */
                    conversationQueryParameters = {
                      ...conversationQueryParameters,
                      messageTypeId: itemSpecificTemplate.messageTypeId?.toString(),
                    };
                    conversation = { ...conversation, dmsMessageTypeId: itemSpecificTemplate.messageTypeId };
                    event = { ...event, content: 'Generating message...' };
                    let optionalHeader = new HttpHeaders({ 'template-id': itemSpecificTemplate.id });
                    this.communicatorFacade.sendCommunicatorMessage(
                      conversationQueryParameters,
                      conversation,
                      members,
                      event,
                      optionalHeader
                    );

                    this.salesPlannerStateService.addToast(toastOptions);
                  });
              } else if (dialogResult.messageContent) {
                event = { ...event, content: dialogResult.messageContent };
                let optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'false' });
                this.communicatorFacade.sendCommunicatorMessage(
                  conversationQueryParameters,
                  conversation,
                  members,
                  event,
                  optionalHeader
                );

                this.salesPlannerStateService.addToast(toastOptions);
              }
            });
          });
      } else {
        const dialogRef = this.dialog.open(TextMessageDialogComponent, {
          width: '500px',
          height: '350px',
          data: {
            directMessageTemplates: directMessageTemplates,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            event = { ...event, content: result.textMessageBody };
            let optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'false' });

            this.communicatorFacade.sendCommunicatorMessage(
              conversationQueryParameters,
              conversation,
              members,
              event,
              optionalHeader
            );

            this.salesPlannerStateService.addToast(toastOptions);
          }
        });
      }
    });
  }

  openCommunicatorEmailDialog(
    directMessageTemplates: DirectMessageTemplate[],
    htmlMappingTemplates: HtmlMappingTemplate[],
    associate: Associate,
    prospect: Prospect,
    currentUser: TokenUser,
    task: Task
  ) {
    this.apiService.get(`/quorum/stores/${currentUser.storeId}`).subscribe((response) => {
      if (response.useCommunicatorCloudServices) {
        combineLatest([
          task.communicatorTaskSpecificTemplate
            ? this.apiService.get(
                `v/1/quorum/templates/item-specific/${currentUser.destination}/${task.communicatorTaskSpecificTemplate}`
              )
            : task.communicatorTemplate
            ? this.apiService.get(
                `v/1/quorum/templates/store-gallery/${currentUser.destination}/${task.communicatorTemplate}`
              )
            : of(null),

          this.apiService.get(`v/1/quorum/templates/store-gallery/${currentUser.destination}`, {
            params: { messageTypeId: 18 },
          }),
        ])
          .pipe(
            map(([communicatorTaskSpecificTemplate, communicatorTemplates]) => {
              return {
                communicatorTaskSpecificTemplate: communicatorTaskSpecificTemplate,
                communicatorTemplates: communicatorTemplates,
              };
            })
          )
          .subscribe((data) => {
            const dialogRef = this.dialog.open(CommunicatorDialogEditorComponent, {
              data: {
                communicatorTaskSpecificTemplate: data.communicatorTaskSpecificTemplate,
                currentUser: currentUser,
                messageType: 'email',
                templates: data.communicatorTemplates,
              },
              height: '100%',
              width: '100%',
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
              if (dialogResult) {
                this.apiService
                  .post(`v/1/quorum/templates/item-specific/${currentUser.destination}`, dialogResult.template)
                  .subscribe((itemSpecificTemplate: Template) => {
                    let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                      transactionTypeId: 5,
                      messageTypeId: itemSpecificTemplate.messageTypeId
                        ? itemSpecificTemplate.messageTypeId.toString()
                        : 18,
                      transactionId: prospect.id,
                    });

                    let event: Event = new Event({
                      attachments: dialogResult.attachments
                        ? dialogResult.attachments.map((a: Attachment) => a.url).join(',')
                        : null,
                      conversationId: null,
                      memberId: currentUser.id.toString(),
                      eventTypeId: EventTypes.Message,
                      content: 'Generating email...',
                      guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                      sentDate: new Date(),
                      sent: false,
                      sendToCustomer: true,
                      id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
                    });

                    let conversation: Conversation = new Conversation({
                      dmsMessageTypeId: itemSpecificTemplate.messageTypeId
                        ? itemSpecificTemplate.messageTypeId.toString()
                        : 18,
                      dmsTransactionTypeId: 5,
                      dmsTransactionId: prospect.id,
                      createdBy: currentUser.id,
                    });

                    let members: Member[] = [];
                    members.push(
                      new Member({
                        userId: currentUser.id.toString(),
                        avatarUrl: currentUser.avatarUrl,
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        nickname: currentUser.firstName,
                        isEmployee: true,
                        isRead: true,
                        isArchived: false,
                        isExcluded: false,
                        isPinnedToUi: false,
                      })
                    );

                    members.push(
                      new Member({
                        userId: prospect.embedded.associate.id,
                        firstName: prospect.embedded.associate.firstName,
                        lastName: prospect.embedded.associate.lastName,
                        nickname: prospect.embedded.associate.firstName,
                        isEmployee: false,
                        isRead: true,
                        isArchived: false,
                        isExcluded: false,
                        isPinnedToUi: false,
                      })
                    );

                    let optionalHeader = new HttpHeaders({ 'template-id': itemSpecificTemplate.id });
                    this.communicatorFacade.sendCommunicatorMessage(
                      conversationQueryParameters,
                      conversation,
                      members,
                      event,
                      optionalHeader
                    );

                    let toastOptions: any = {
                      message: `Communicator email sent.`,
                      actionText: null,
                      options: { duration: 3000 },
                    };

                    this.salesPlannerStateService.addToast(toastOptions);
                  });
              }
            });
          });

        combineLatest([
          this.actions$.pipe(ofType(CommunicatorActions.sendMessageToConversationSuccess), take(1)),
          this.taskOutcomes$.pipe(take(1)),
        ])
          .pipe(
            map(([sendMessageToConversationSuccess, taskOutcomes]) => {
              return { sendMessageToConversationSuccess, taskOutcomes };
            })
          )
          .subscribe((data) => {
            let completedOutcome = data.taskOutcomes?.find(
              (outcome) => outcome.description?.trim().toLowerCase() == 'completed'
            );
            this.taskCardForm.get('outcomeId').patchValue(completedOutcome.id);
            this.taskCardForm.get('comments').patchValue('Email sent to the customer');

            this.updateTask(this.taskCardForm.value);

            this.selectedActivityStateService.createSentEmailTextProspectAction(
              prospect,
              this.dropdowns,
              this.currentUser
            );
          });
      } else {
        const dialogRef = this.dialog.open(EmailTemplateDialogComponent, {
          data: {
            directMessageTemplates: directMessageTemplates,
            htmlMappingTemplates: htmlMappingTemplates,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const xselleratorConversation: XselleratorConversation = new XselleratorConversation();
            xselleratorConversation.senderId = currentUser.xselleratorEmployeeId;
            xselleratorConversation.customerId = associate.id;
            xselleratorConversation.emailTemplateLanguageId = result.htmlLanguageTemplate;
            xselleratorConversation.message = result.messageBody;
            xselleratorConversation.automatedMessageTypeId = 18;
            xselleratorConversation.oemRooftopId = prospect.rooftopId;
            xselleratorConversation.subject = result.subject;
            xselleratorConversation.emailTemplateId = result.htmlMappingTemplate;
            xselleratorConversation.transactionId = prospect.id.toString();
            xselleratorConversation.transactionTypeId = 5;

            return this.apiService
              .post<any>(`v/1/communicator/conversations/xsellerator/transactions`, xselleratorConversation)
              .subscribe((result) => {
                let toastOptions: any = {
                  message: `Communicator message sent.`,
                  actionText: null,
                  options: { duration: 3000 },
                };

                this.salesPlannerStateService.addToast(toastOptions);
              });
          }
        });
      }
    });
  }
}
