import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Task } from '@quorum/models/xs-resource';

export interface ProspectTasksState extends EntityState<Task> {}

export const prospectTasksAdapter: EntityAdapter<Task> = createEntityAdapter<Task>({
  selectId: (task: Task) => task.id,
});

export const initialState: ProspectTasksState = prospectTasksAdapter.getInitialState();
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const prospectTasksSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectTasks
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  prospectTasksAdapter.getSelectors(prospectTasksSelector);
