import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { OemRooftopLink } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromRooftops from './rooftops.actions';
import { RooftopsState } from './rooftops.interfaces';
@Injectable()
export class RooftopsEffects {
  
  getRooftopsFromServer = createEffect(() => this.dataPersistence.fetch(fromRooftops.GET_ROOFTOPS_FROM_SERVER, {
    run: (action: fromRooftops.GetRooftopsFromServer, state: RooftopsState) => {
      return this.apiService
        .get<OemRooftopLink[]>(`v/1/system/oem-rooftop-links`, {
          params: action.payload,
        })
        .pipe(
          map((rooftops) => {
            return new fromRooftops.GetRooftopsFromServerSuccess({
              rooftops: rooftops,
            });
          })
        );
    },
    onError: (action: fromRooftops.GetRooftopsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<RooftopsState>,
    private apiService: ApiService
  ) {}
}
