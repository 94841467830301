import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import {
  BlackbookSettingQueryParameters,
  BlackbookValuesSettingQueryParameters,
  SelectedAddDeductQueryParameters,
} from '@quorum/models/xs-query';
import {
  AddDeductApi,
  BlackbookPriceType,
  BlackbookSetting,
  BlackbookValueApi,
  BlackbookValuesSetting,
  BlackbookWorkflowMatchValue,
  SelectedAddDeduct,
} from '@quorum/models/xs-resource';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'crm-add-deduct-dialog',
  templateUrl: './add-deduct-dialog.component.html',
  styleUrls: ['./add-deduct-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDeductDialogComponent implements OnInit {
  blackBookPriceTypes$: Observable<BlackbookPriceType[]>;
  blackbookPriceTypes: BlackbookPriceType[];
  blackbookSettings$: Observable<BlackbookSetting>;
  blackbookSettings: BlackbookSetting;
  blackbookValuesSetting$: Observable<BlackbookValuesSetting[]>;
  blackbookValuesSetting: BlackbookValuesSetting;
  combo$: Observable<any>;
  currencyMask = createNumberMask({
    allowDecimal: true,
    allowNegative: true,
    requiredDecimal: true,
  });
  selectedVehicle: BlackbookValueApi;
  selectedAddDeducts$: Observable<SelectedAddDeduct[]>;
  totals: any = {
    value: 0,
    addDeducts: 0,
    tradeValue: 0,
  };
  tradeOffset: string = '0.00';
  tradeValue: string = '0.00';
  vehicleList$: Observable<BlackbookValueApi[]>;
  workflowMatchValue: BlackbookWorkflowMatchValue = new BlackbookWorkflowMatchValue();

  constructor(
    public apiService: ApiService,
    public dialogRef: MatDialogRef<AddDeductDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      reportItem: any;
      rooftopId: number;
      countryCode: string;
    }
  ) {}

  ngOnInit() {
    this.blackBookPriceTypes$ = this.apiService.get<BlackbookPriceType[]>(
      '/v/1/crm/lead-generation/blackbook-price-types'
    );

    const blackbookSettingsParams = new BlackbookSettingQueryParameters({
      rooftopId: this.data.rooftopId.toString(),
    });
    this.blackbookSettings$ = this.apiService
      .get<any>(`/v/1/crm/lead-generation/settings`, {
        params: blackbookSettingsParams,
      })
      .pipe(map((response) => response[0]));

    const blackbookValuesSettingParams = new BlackbookValuesSettingQueryParameters({
      vehicleId: this.data.reportItem.vehicleId,
    });
    this.blackbookValuesSetting$ = this.apiService.get<BlackbookPriceType[]>(
      '/v/1/crm/lead-generation/blackbook-values-settings',
      {
        params: blackbookValuesSettingParams,
      }
    );

    const selectedAddDeductsQueryParams = new SelectedAddDeductQueryParameters({
      vehicleId: this.data.reportItem.vehicleId,
      uvc: this.data.reportItem.blackbookUvc,
    });
    this.selectedAddDeducts$ = this.apiService.get<any>(`/v/1/crm/lead-generation/selected-add-deducts`, {
      params: selectedAddDeductsQueryParams,
    });

    this.vehicleList$ = this.apiService.get<any>(
      `/v/1/crm/lead-generation/blackbook-used-vehicle-vin/${this.data.rooftopId}/${this.data.reportItem.vin}`,
      {
        params: {
          country: this.data.countryCode,
          mileage: this.data.reportItem.currentMileage ? this.data.reportItem.currentMileage : 0,
        },
      }
    );

    this.combo$ = combineLatest(
      this.blackBookPriceTypes$,
      this.blackbookSettings$,
      this.blackbookValuesSetting$,
      this.selectedAddDeducts$,
      this.vehicleList$
    ).pipe(
      map(([priceTypes, settings, blackbookValuesSetting, selectedAddDeducts, vehicleList]) => {
        this.blackbookPriceTypes = priceTypes;
        this.blackbookSettings = settings;

        const selectedAddDeductUocs = selectedAddDeducts.map((selectedAddDeduct) => selectedAddDeduct.uoc);

        this.selectedVehicle = vehicleList.find((vehicle) => vehicle.uvc === this.data.reportItem.blackbookUvc);

        this.selectedVehicle.addDeducts.forEach((addDeduct: AddDeductApi) => {
          addDeduct.selected = selectedAddDeductUocs.includes(addDeduct.uoc);
        });

        if (blackbookValuesSetting.length > 0) {
          this.blackbookValuesSetting = blackbookValuesSetting[0];
          this.tradeOffset =
            this.blackbookValuesSetting.tradeOffset.toString() == '0'
              ? '0.00'
              : this.blackbookValuesSetting.tradeOffset.toString();
        } else {
          this.blackbookValuesSetting = {
            ...new BlackbookValuesSetting(),
            blackbookPriceTypeId: this.blackbookSettings.blackbookPriceTypeId,
            tradeOffset: 0,
            vehicleId: this.data.reportItem.vehicleId,
          };
        }

        this.calculateValues(
          this.selectedVehicle,
          this.blackbookSettings,
          this.blackbookValuesSetting,
          this.workflowMatchValue
        );

        return { blackBookPriceTypes: priceTypes, vehicleList: vehicleList };
      })
    );
  }

  onNoClick(): void {
    this.dialogRef.close({ reload: false });
  }

  calculateValues(
    selectedVehicle: BlackbookValueApi,
    settings: BlackbookSetting,
    blackbookValuesSetting: BlackbookValuesSetting,
    workflowMatchValue: BlackbookWorkflowMatchValue,
    tradeValue?: string
  ) {
    const priceType: BlackbookPriceType = this.blackbookPriceTypes.filter(
      (priceType) => priceType.id === blackbookValuesSetting.blackbookPriceTypeId
    )[0];

    if (tradeValue) {
      this.totals.tradeValue = parseFloat(tradeValue.replace(/,/g, '').replace('$', ''));

      let tradeOffset =
        this.totals.value +
        this.totals.addDeducts -
        settings.marketingCost -
        settings.reconditioningCost -
        this.totals.tradeValue;

      this.tradeOffset = tradeOffset.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    if (!this.tradeOffset || this.tradeOffset == '') {
      this.tradeOffset = '0.00';
    }

    blackbookValuesSetting.tradeOffset = parseFloat(this.tradeOffset.replace(/,/g, '').replace('$', ''));

    const keysToIterateOver: (keyof BlackbookValueApi)[] = ['rough', 'avg', 'clean', 'xClean'];

    keysToIterateOver.forEach((key) => {
      if (priceType.blackbookDescription === key) {
        workflowMatchValue.amount = <number>selectedVehicle[key];
      }
    });

    this.totals.value = workflowMatchValue.amount;
    this.totals.addDeducts = 0;

    selectedVehicle.addDeducts.forEach((addDeduct: AddDeductApi) => {
      if (addDeduct.selected) {
        this.totals.addDeducts += addDeduct.rough;
      }
    });

    this.totals.tradeValue =
      this.totals.value +
      this.totals.addDeducts -
      blackbookValuesSetting.tradeOffset -
      settings.marketingCost -
      settings.reconditioningCost;
    this.tradeValue = this.totals.tradeValue;

    this.workflowMatchValue = {
      ...this.workflowMatchValue,
      addDeducts: selectedVehicle.addDeducts,
      blackbookValuesSetting: blackbookValuesSetting,
      publishDate: selectedVehicle.publishDate,
      previousUvc: this.data.reportItem.blackbookUvc,
      uvc: selectedVehicle.uvc,
    };
  }

  save(workflowMatchValue: BlackbookWorkflowMatchValue): void {
    this.apiService
      .put<any>(
        `v/1/crm/lead-generation/workflow/updateVehicleMatch/${this.data.reportItem.vehicleId}`,
        workflowMatchValue
      )
      .subscribe((response) => this.dialogRef.close({ vehicleId: this.data.reportItem.vehicleId, reload: true }));
  }
}
