import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromUi from './ui.actions';
import {
  closedActivitiesVisibleSelector,
  purchaseOrderDetailsVisibleSelector,
  actionsVisibleSelector,
  notesVisibleSelector,
  openActivitiesVisibleSelector,
  openSalesVisibleSelector,
  prospectAggregateVisibleSelector,
  prospectDetailsVisibleSelector,
  responseLeadsVisibleSelector,
  vehicleOfInterestVisibleSelector,
  serviceHistoryVisibleSelector,
  vehicleAggregateVisibleSelector,
  sidenavVisibleSelector,
  prospectLeadsSelector,
} from './ui.interfaces';

@Injectable()
export class UiStateService {
  constructor(private store: Store<any>) {}

  selectOpenActivitiesVisible() {
    return this.store.pipe(select(openActivitiesVisibleSelector));
  }

  selectClosedActivitiesVisible() {
    return this.store.pipe(select(closedActivitiesVisibleSelector));
  }

  selectPurchaseOrderDetailsVisible() {
    return this.store.pipe(select(purchaseOrderDetailsVisibleSelector));
  }

  selectResponseLeadsVisible() {
    return this.store.pipe(select(responseLeadsVisibleSelector));
  }

  selectVehicleOfInterestVisible() {
    return this.store.pipe(select(vehicleOfInterestVisibleSelector));
  }

  selectActionsVisible() {
    return this.store.pipe(select(actionsVisibleSelector));
  }

  selectOpenSalesVisible() {
    return this.store.pipe(select(openSalesVisibleSelector));
  }

  selectNotesVisible() {
    return this.store.pipe(select(notesVisibleSelector));
  }

  selectServiceHistoryVisible() {
    return this.store.pipe(select(serviceHistoryVisibleSelector));
  }

  selectVehicleAggregateVisible() {
    return this.store.pipe(select(vehicleAggregateVisibleSelector));
  }

  selectProspectDetailsVisible() {
    return this.store.pipe(select(prospectDetailsVisibleSelector));
  }

  selectProspectAggregateVisible() {
    return this.store.pipe(select(prospectAggregateVisibleSelector));
  }

  selectSidenavVisible() {
    return this.store.pipe(select(sidenavVisibleSelector));
  }

  toggleActionsPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleActionsPanelInState(toggle));
  }

  toggleOpenActivitiesPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleOpenActiviesPanelInState(toggle));
  }

  toggleClosedActivitiesPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleClosedActiviesPanelInState(toggle));
  }

  togglePurchaseOrderDetailsPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.TogglePurchaseOrderDetailsPanelInState(toggle));
  }

  toggleReponseLeadsPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleResponseLeadsPanelInState(toggle));
  }

  toggleVehicleOfInterestPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleVehicleOfInterestPanelInState(toggle));
  }

  toggleOpenSalesPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleOpenSalesPanelInState(toggle));
  }

  toggleNotesPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleNotesPanelInState(toggle));
  }

  toggleServiceHistoryPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleServiceHistoryPanelInState(toggle));
  }

  toggleVehicleAggregatePanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleVehicleAggregatePanelInState(toggle));
  }

  toggleProspectDetailsPanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleProspectDetailsPanelInState(toggle));
  }

  toggleProspectAggregatePanel(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleProspectAggregatePanelInState(toggle));
  }

  toggleSidenav(toggle: boolean) {
    this.store.dispatch(new fromUi.ToggleSidenavInState(toggle));
  }

  getProspectLeads() {
    this.store.dispatch(new fromUi.GetUnassignedProspectLeadsFromServer({}));
    this.store.dispatch(new fromUi.GetUnmatchedProspectLeadsFromServer({}));
  }

  selectProspectLeads() {
    return this.store.pipe(select(prospectLeadsSelector));
  }
}
