import { fromProspects, initialState, prospectsAdapter, ProspectsState } from '@quorum/xsr-entities/state';
import * as fromSalesPlannerProspects from './prospects.actions';

export function prospectsReducer(state = initialState, action: any): ProspectsState {
  // switch (action.type) {
  //   case fromProspects.GET_PROSPECTS_FROM_SERVER: {
  //     return { ...state, prospects: { ...state.prospects, loadingPage: true } };
  //   }
  //   case fromProspects.GET_PROSPECTS_FROM_SERVER_SUCCESS: {
  //     console.log('woo', action.payload);
  //     return {
  //       ...state,
  //       prospects: {
  //         ...prospectsAdapter.addMany(action.payload.prospects, state.prospects),
  //         total: action.payload.total,
  //         totalPages: action.payload.totalPages,
  //         activePage: action.payload.activePage,
  //         pageSize: action.payload.pageSize,
  //         loadingPage: false
  //       }
  //     };
  //   }
  //   case fromSalesPlannerProspects.UPDATE_PROSPECT_PAGE_DETAILS_IN_STATE: {
  //     return {
  //       ...state,
  //       prospects: {
  //         ...state.prospects,
  //         activePage: action.payload.page,
  //         pageSize: action.payload.pageSize
  //       }
  //     };
  //   }
  //   case fromSalesPlannerProspects.CLEAR_PROSPECTS_FROM_STATE: {
  //     return {
  //       ...state,
  //       prospects: {
  //         ...prospectsAdapter.removeAll(state.prospects)
  //       }
  //     };
  //   }
  //   case fromProspects.CREATE_PROSPECT_ON_SERVER_SUCCESS: {
  //     return {
  //       ...state,
  //       prospects: {
  //         ...prospectsAdapter.addOne(action.payload.prospect, state.prospects)
  //       }
  //     };
  //   }
  //   case fromProspects.UPDATE_PROSPECT_ON_SERVER_SUCCESS: {
  //     return {
  //       ...state,
  //       prospects: {
  //         ...prospectsAdapter.updateOne(action.payload, state.prospects)
  //       }
  //     };
  //   }
  //   default: {
  //     return state;
  //   }
  // }
  switch (action.type) {
    case fromProspects.GET_PROSPECTS_FROM_SERVER: {
      return { ...state, loadingPage: true };
    }
    case fromProspects.GET_PROSPECTS_FROM_SERVER_SUCCESS: {
      return {
        ...prospectsAdapter.addMany(action.payload.prospects, state),
        total: action.payload.total,
        totalPages: action.payload.totalPages,
        activePage: action.payload.activePage,
        pageSize: action.payload.pageSize,
        loadingPage: false,
      };
    }
    case fromSalesPlannerProspects.UPDATE_PROSPECT_PAGE_DETAILS_IN_STATE: {
      return {
        ...state,
        activePage: action.payload.page,
        pageSize: action.payload.pageSize,
      };
    }
    case fromSalesPlannerProspects.CLEAR_PROSPECTS_FROM_STATE: {
      return {
        ...prospectsAdapter.removeAll(state),
      };
    }
    case fromProspects.CREATE_PROSPECT_ON_SERVER_SUCCESS: {
      return {
        ...prospectsAdapter.upsertOne(action.payload.prospect, state),
      };
    }
    case fromProspects.UPDATE_PROSPECT_ON_SERVER_SUCCESS: {
      return {
        ...prospectsAdapter.updateOne(action.payload.prospect, state),
      };
    }
    case fromProspects.UPDATE_SELECTED_PROSPECT_ON_SERVER_SUCCESS: {
      return {
        ...prospectsAdapter.updateOne(action.payload.prospect, state),
      };
    }

    case fromProspects.CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS: {
      return {
        ...prospectsAdapter.upsertOne(action.payload.prospect, state),
      };
    }

    case fromProspects.UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER_SUCCESS: {
      return {
        ...prospectsAdapter.upsertOne(action.payload.prospect, state),
      };
    }

    case fromProspects.CLEAR_PROSPECT_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
