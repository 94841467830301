import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCommunicator from './+state/communicator.reducer';
import { CommunicatorEffects } from './+state/communicator.effects';
import { CommunicatorFacade } from './+state/communicator.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCommunicator.COMMUNICATOR_FEATURE_KEY, fromCommunicator.reducer),
    EffectsModule.forFeature([CommunicatorEffects]),
  ],
  providers: [CommunicatorFacade],
})
export class CommunicatorNgrxModule {}
