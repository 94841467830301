<div fxLayout="column" *ngIf="dropdowns$ | async as dropdowns">
  <div fxLayout="column" style="margin-top:1.8rem;">
    <h2>
      Filters
    </h2>
    <span style="cursor:pointer;color:rgb(25, 118, 210);margin-top:-20px;font-size:13px;"
      (click)="resetVehicleSearchForm(authenticatedEmployee)">Reset</span>
    <div style="padding:16px;border: 1px solid #dadce0;border-radius:8px;background-color:#FFF;">
      <form #vehicleSearchFormRef fxLayout="column" [formGroup]="vehicleSearchForm">
        <div fxLayout="row wrap">
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Rooftop" name="rooftop" formControlName="rooftopId">
              <mat-option *ngFor="let rooftop of dropdowns.rooftops" [value]="rooftop.id">{{rooftop.displayCode}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select multiple placeholder="Salesperson" name="salesPersonId" formControlName="salesPersonId">
              <mat-option [value]="dropdowns.salespeople.employeeId.EmployeeId">
                {{dropdowns.salespeople.employeeId.EmployeeName}}</mat-option>
              <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams">
                <label (click)="selectTeam(salesTeam, vehicleSearchForm)">{{salesTeam.teamName}}</label>
                <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                  {{salesperson.EmployeeName}}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select multiple placeholder="Prospect Status" name="prospectStatusId"
              formControlName="prospectStatusId">
              <mat-option [value]="'1'">Open</mat-option>
              <mat-option [value]="'2'">Closed</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Actioned" name="actioned" formControlName="actioned">
              <mat-option [value]="'1'">Yes</mat-option>
              <mat-option [value]="'0'">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Category" name="vehicleCategory" formControlName="categoryId">
              <mat-option [value]="'D'">Demo</mat-option>
              <mat-option [value]="'N'">New</mat-option>
              <mat-option [value]="'U'">Used</mat-option>
              <mat-option [value]="'O'">Other</mat-option>
              <mat-option [value]="'C'">Co. Vehicle</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Subcategory" name="vehicleSubcategory" formControlName="subCategoryId">
              <mat-option *ngFor="let vehicleSubcategory of dropdowns.vehicleSubcategories"
                [value]="vehicleSubcategory.id">{{vehicleSubcategory.description}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Sub Type" name="subType" formControlName="subTypeId">
              <mat-option *ngFor="let vehicleSubType of dropdowns.vehicleSubTypes" [value]="vehicleSubType.id">
                {{vehicleSubType.description}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Make" name="vehicleMake" formControlName="vehicleMake">
              <mat-option *ngFor="let vehicleMake of dropdowns.vehicleMakes" [value]="vehicleMake.description">
                {{vehicleMake.description}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <input matInput placeholder="Description" name="vehicleDescription" formControlName="vehicleDescription">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <input matInput placeholder="Vehicle Id" name="vehicleId" type="number" formControlName="vehicleId">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Generic Model" name="genericModel" formControlName="genericModelId">
              <mat-option *ngFor="let vehicleGenericModel of dropdowns.vehicleGenericModels"
                [value]="vehicleGenericModel.id">{{vehicleGenericModel.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <input matInput placeholder="Model" name="vehicleModelCode" formControlName="vehicleModelCode">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <mat-select placeholder="Last Action" name="lastAction" formControlName="lastAction">
              <mat-option *ngFor="let action of dropdowns.prospectActions" [value]="action.id">
                {{action.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <input matInput placeholder="Unit #" name="unitNumber" formControlName="unitNumber">
          </mat-form-field>

          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <input matInput placeholder="Min Year" name="year" formControlName="minYear">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50" style="padding:4px;">
            <input matInput placeholder="Max Year" name="year" formControlName="maxYear">
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button id="search-button" #searchButton type="submit" fxLayoutAlign="center center" class="mat-elevation-z2"
            fxLayout="row"
            style="cursor:pointer;outline:none;color:#FFF; background-color:#01488a;margin:0 0 0 16px;padding:8px 12px 8px 16px;width:124px;height:48px;border-radius:50px;"
            fxLayoutAlign="start center">
            <mat-icon style="color:#FFF;">search</mat-icon> <span
              style="font-size:14px;font-weight:500;margin:0 0 4px 8px;">Search</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div fxLayout="column" style="margin-top:1.8rem;overflow:auto;">
    <div fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="center start">
        <h2>
          Results
        </h2>
        <span style="cursor:pointer;color:rgb(25, 118, 210);margin-top:-20px;font-size:13px;"
          (click)="getReport()">Clear</span>
      </div>
      <mat-form-field>
        <mat-select placeholder="Sort" name="sort" (selectionChange)="sortChanged(sort, vehicleSearchForm)"
          [(ngModel)]="sort">
          <mat-option value="enteredDate">Entered Date ASC</mat-option>
          <mat-option value="-enteredDate">Entered Date DESC</mat-option>
          <mat-option value="lastActionDate">Last Action Date ASC</mat-option>
          <mat-option value="-lastActionDate">Last Action Date DESC</mat-option>
          <mat-option value="lastUpdatedDate">Last Updated Date ASC</mat-option>
          <mat-option value="-lastUpdatedDate">Last Updated Date DESC</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="column" style="padding:32px 8px 8px 8px;" *ngIf="searchResult">
      <div *ngIf="!(isLoading$ | async)" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div *ngFor="let item of searchResult.data;trackBy: trackByFunction" fxLayout="column" fxFlex="49"
          style="margin-bottom:12px;padding:16px;border: 1px solid #dadce0;border-radius:8px;background-color:rgb(253, 253, 253);">
          <div fxLayoutAlign="space-between center">
            <div fxLayout="row">
              <h3 style="font-weight:500;">{{item.customerFirstName + ' ' + item.customerLastName}}</h3>
              <h3>&nbsp;- Entered: {{item.enteredDate | moment: 'MMM Do, YYYY' }}</h3>
            </div>
            <div fxLayout="row">
              <button *ngIf="item.actioned" mat-icon-button type="button" (click)="openProspect(item.id)">
                <mat-icon style="color:#01488a">person</mat-icon>
              </button>
            </div>
          </div>
          <div fxLayout="row">
            <h3>Salesperson - {{item.salespersonFirstName + ' ' + item.salespersonLastName}}</h3>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column">
            <div fxLayoutAlign="start center">
              <span style="margin:6px 0 0 0;" class="mat-body-2">{{item.vehicleYear + ' ' + item.vehicleMake
                    + ' ' + item.vehicleDescription + ' ' }}(<a
                  [ngStyle]="{'color': browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)': '#000', 'cursor': browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''}"
                  (click)="browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [item.vehicleId.toString()])"
                  class="mat-body-1">ID:
                  {{item.vehicleId}}</a>)</span>
            </div>
            <div fxLayoutAlign="start center">
              <span style="font-size:32px;margin-right:4px;color:#42A5F5;">·</span>
              <span>Unit #: {{item.unitNumber}}</span>
            </div>
            <div fxLayoutAlign="start center">
              <span style="font-size:32px;margin-right:4px;color:#42A5F5;">·</span>
              <span>Model Code: {{item.vehicleModelCode}}</span>
            </div>
            <div fxLayoutAlign="start center">
              <span style="font-size:32px;margin-right:4px;color:#42A5F5;">·</span>
              <span>Vehicle last updated on {{ item.lastUpdatedDate | moment: 'MMM Do, YYYY'}}</span>
            </div>
            <div fxLayoutAlign="start center">
              <span style="font-size:32px;margin-right:4px;color:#42A5F5;">·</span>
              <span>Last action against this vehicle was {{ item.lastAction}} on
                {{ item.lastActionDate | moment: 'MMM Do, YYYY'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top:16px;">
        <mat-progress-spinner style="margin:0 auto;" diameter="50" *ngIf="(isLoading$ | async)" mode="indeterminate">
        </mat-progress-spinner>
      </div>
      <mat-paginator [length]="searchResult.headers?.total" [pageSize]="vehicleSearchForm.value.pageSize"
        [pageIndex]="vehicleSearchForm.value.page" (page)="pageChanged($event, vehicleSearchForm)" [pageSizeOptions]="">
      </mat-paginator>
    </div>
  </div>
</div>