export class ProspectActionQueryParameters {
  appointmentDateFrom?: Date;
  appointmentDateTo?: Date;
  associateEmployeeId?: string;
  embed?: Array<string>;
  enteredDateFrom?: Date;
  enteredDateTo?: Date;
  id?: number;
  newSalespersonId?: string;
  page?: number;
  pageSize?: number;
  previousSalespersonId?: number;
  prospectId?: number;
  taskActionId?: string;

  constructor(obj?: ProspectActionQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class ProspectTaskQueryParameters {
  actionId?: number;
  currentEmployeeId?: string;
  dealerDateTime?: Date;
  deleteTask?: string;
  prospectId?: number;
  rooftopId?: number;
  taskActionId?: string;
  templateId?: number;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
