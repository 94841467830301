import { ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromSystemControl from './system-control.actions';
import { initialState, systemControlAdapter, SystemControlState } from './system-control.interfaces';

export function reducer(
  state: SystemControlState = initialState,
  action: fromSystemControl.SystemControlAction
): SystemControlState {
  switch (action.type) {
    case fromSystemControl.GET_SYSTEM_CONTROL_VALUES_FROM_SERVER_SUCCESS: {
      return {
        ...systemControlAdapter.addMany(action.payload.systemControlValues, state)
      };
    }

    default: {
      return state;
    }
  }
}
