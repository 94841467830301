import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { Prospect } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { LocalSettingsStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { LocalSettingsState } from '@quorum/xsr-salesplanner-legacy/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'crm-prospect-list',
  templateUrl: './prospect-list.component.html',
  styleUrls: ['./prospect-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectListComponent implements OnInit, OnChanges {
  @Input() componentData: Observable<{ prospects: Array<Prospect>; pageDetails: any }>;
  @Input() queryParams$: BehaviorSubject<ProspectQueryParameters>;
  @Output() filtersChanged: EventEmitter<ProspectQueryParameters> = new EventEmitter<ProspectQueryParameters>();
  localSettings$: Observable<LocalSettingsState>;

  queryParams: ProspectQueryParameters;
  constructor(
    private routerStateService: RouterStateService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private localSettingsStateService: LocalSettingsStateService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.localSettings$ = this.localSettingsStateService.selectLocalSettingsFromState();
    if (changes.queryParams$) {
      this.queryParams$.pipe(map((params) => new DeepCopyPipe().transform(params))).subscribe((params) => {
        this.queryParams = params;
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  selectProspect(prospect: Prospect, localSettings: LocalSettingsState) {
    let queryParams: any;
    switch (localSettings.defaultProspectTab) {
      case 'A':
        queryParams = { tab: 'activity' };
        break;
      case 'P':
        queryParams = { tab: 'prospect' };
        break;
      case 'V':
        queryParams = { tab: 'vehicle' };
        break;
      default:
        queryParams = { tab: 'activity' };
        break;
    }
    this.routerStateService.go(['prospects', `${prospect.id}`], {
      queryParams: queryParams,
      relativeTo: this.route.parent,
    });
  }

  pageChanged(ev: PageEvent) {
    this.queryParams = { ...this.queryParams, page: ev.pageIndex, pageSize: ev.pageSize };
    this.filtersChanged.emit(this.queryParams);
  }
}
