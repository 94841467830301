import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import {
  Colour,
  Employee,
  Manufacturer,
  Prospect,
  Status,
  SubCategory,
  Vehicle
} from '@quorum/models/xs-resource';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { RouterStateService } from '@quorum/sha-router';
import { SelectedActivityStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-add-prospect-vehicle-shell',
  templateUrl: './add-prospect-vehicle-shell.component.html',
  styleUrls: ['./add-prospect-vehicle-shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProspectVehicleShellComponent implements OnInit {
  dropdowns$: Observable<any>;
  vehicle$: Observable<Vehicle>;
  selectedRooftopId: number;
  selectedProspect$: Observable<Prospect>;
  formData: any = {};
  manufacturers: Manufacturer[];
  vehicleColours: Colour[];
  vehicleManufacturerName: string;
  vehicleColour: string;
  mainManufacturer: string;
  vinTranslateError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  trims$: Observable<any>;
  formValid: boolean;

  trimForm = this.fb.group({
    trim: [],
  });
  trims: string[] = [];

  constructor(
    private dropdownsStateService: DropdownsStateService,
    private apiService: ApiService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    private authenticationStateService: AuthenticationStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    private routerStateService: RouterStateService,
    private route: ActivatedRoute,
    private crmEntityStateService: CrmEntityStateService,
    private systemControlStateService: SystemControlStateService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleColours().pipe(take(1)),
      this.apiService
        .get<Manufacturer[]>('v/1/associates/manufacturers', {
          params: {
            embed: 'associate',
          },
        })
        .pipe(take(1)),
      this.dropdownsStateService.selectVehicleStatuses().pipe(take(1)),
      this.dropdownsStateService.selectVehicleSubcategories().pipe(take(1))
    ).pipe(
      map(([vehicleColours, manufacturers, vehicleStatuses, vehicleSubcategories]) => {
        return {
          vehicleColours,
          manufacturers,
          vehicleStatuses,
          vehicleSubcategories,
        };
      })
    );

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        take(1)
      )
      .subscribe((authenticatedEmployee: Employee) => {
        this.selectedRooftopId = authenticatedEmployee.rooftopId;
        this.systemControlStateService
          .getSystemControlValue('CL_MAIN_MANUFACTURER', authenticatedEmployee.rooftopId)
          .subscribe((mainManufacturer) => {
            this.mainManufacturer = mainManufacturer.value;
          });
      });

    this.selectedProspect$ = this.selectedActivityStateService.selectSelectedProspect();
  }

  formChanged(formData: any, dropdowns: any) {
    this.vinTranslateError$.next(false);
    if (formData.value.vin && formData.value.vin.length > 0 && this.formData.vin != formData.value.vin) {
      this.trims = [];
      this.formData = formData;
      this.vehicle$ = this.apiService
        .get<Vehicle>('v/1/vehicles/vehicles', {
          params: {
            vin: formData.value.vin,
            embed: 'associate',
          },
        })
        .pipe(
          tap((vehicle) => {
            if (vehicle.length > 0) {
              this.vehicleManufacturerName = dropdowns.manufacturers.find(
                (manufacturer: Manufacturer) => manufacturer.associateId === vehicle[0].manufacturerId
              )
                ? dropdowns.manufacturers.find(
                    (manufacturer: Manufacturer) => manufacturer.associateId === vehicle[0].manufacturerId
                  ).embedded.associate.firstName
                : null;
              this.vehicleColour = dropdowns.vehicleColours.find(
                (colour: Colour) => colour.id === vehicle[0].prospectColourId
              )
                ? dropdowns.vehicleColours.find((colour: Colour) => colour.id === vehicle[0].prospectColourId)
                    .colourDescription
                : null;
            }
          })
        );
    }
    this.formData = formData.value;
    this.formValid = formData.valid;
  }

  reassignVehicle(vehicle: Vehicle, rooftopId: number, prospect: Prospect, formData: any, dropdowns: any) {
    let vehicleParams: Vehicle = {
      ...vehicle,
      associateId: prospect.associateId,
      rooftopId: rooftopId,
      manufacturerId: formData.manufacturer ? formData.manufacturer : vehicle.manufacturerId,
      currentMileage: formData.mileage ? formData.mileage : vehicle.currentMileage,
      prospectColourId: formData.color ? formData.color : vehicle.prospectColourId,
      subCategoryId: dropdowns.vehicleSubcategories.find((vsubCat: SubCategory) => vsubCat.description === 'Used').id,
    };
    this.crmEntityStateService.reassignVehicle(vehicleParams, prospect.id);
  }

  vinTranslate(formData: any, rooftopId: number) {
    this.apiService
      .get<any>(`v/1/vehicles/vin-translate/${formData.vin}/${rooftopId}/trims`)
      .pipe(
        catchError((error) => {
          this.vinTranslateError$.next(true);
          return of([]);
        }),
        take(1)
      )
      .subscribe((trims) => {
        this.trims = trims;
        this.formValid = false;
        this.cdr.detectChanges();
      });
  }

  selectTrimAndAddVehicle(trim: any, formData: any, rooftopId: number, prospect: Prospect, dropdowns: any) {
    let vehicle = new Vehicle();

    this.apiService
      .post<Vehicle>(
        `v/1/vehicles/vehicles`,
        new Vehicle({
          ...vehicle,
          vin: formData.vin,
          associateId: prospect.associateId,
          rooftopId: rooftopId,
          manufacturerId: formData.manufacturer ? formData.manufacturer : vehicle.manufacturerId,
          currentMileage: formData.mileage ? formData.mileage : vehicle.currentMileage,
          prospectColourId: formData.color ? formData.color : vehicle.prospectColourId,
          statusCode: dropdowns.vehicleStatuses.find((vstatus: Status) => vstatus.description === 'Externally Sold')
            .code,
          subCategoryId: dropdowns.vehicleSubcategories.find((vsubCat: SubCategory) => vsubCat.description === 'Used')
            .id,
          lastMileageDate: new Date(),
          passengerFlag: '1',
          trimLevel: trim.trim.toString(),
        }),
        { params: { translateRequired: true } }
      )
      .pipe(
        catchError((error) => {
          this.vinTranslateError$.next(true);
          return of('error');
        }),
        take(1)
      )
      .subscribe((vehicle) => {
        if (vehicle !== 'error') {
          this.selectedActivityStateService.getSelectedProspectVehicles(prospect);
          this.routerStateService.go(['prospects', `${prospect.id}`], {
            queryParams: { tab: 'vehicle' },
            relativeTo: this.route.parent.parent,
          });
        }
      });
  }
}
