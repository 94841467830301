<div fxFlexFill fxLayout="column" *ngIf="selectedProspect$ | async as selectedProspect">
  <div fxFlexFill fxLayout="column" *ngIf="dropdowns$ | async as dropdowns">
    <sha-add-vehicle [dropdowns]="dropdowns" [mainManufacturer]="mainManufacturer"
      (formChanged)="formChanged($event, dropdowns)">
    </sha-add-vehicle>

    <div fxFlexFill fxLayout="column" *ngIf="vehicle$ | async as vehicle">
      <div *ngIf="vehicle[0]; else noVehicleFound" fxLayout="column" class="padded-panel">
        <span class="mat-caption"
          *ngIf="selectedProspect.associateId !== vehicle[0].associateId; else vehicleAlreadyAdded">By adding this
          vehicle, it will be reassigned and all follow up tasks against the vehicle
          will be
          closed.</span>
        <ng-template #vehicleAlreadyAdded>
          <span class="mat-caption">This vehicle is already present and can not be added. Please try another VIN.</span>
        </ng-template>
        <div fxLayout="column" class="bordered-panel">
          <div fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="center center">
              <h3 class="slim-bold-h3">
                Vehicle ID:
                <a [ngStyle]="{color: browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)' : '#000',
                    cursor: browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''}"
                  (click)="browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [vehicle[0].id.toString()])"
                  class="mat-body-1">{{ vehicle[0].id }}</a>
              </h3>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" *ngIf="vehicle[0]?.embedded?.associate">
            <div fxLayoutAlign="start center">
              <span class="primary-bullet">· </span><span>Currently assigned to
                {{vehicle[0]?.embedded.associate.firstName}}
                {{vehicle[0]?.embedded.associate?.lastName ? vehicle[0]?.embedded.associate.lastName : ''}} </span>
            </div>
          </div>
          <div fxLayout="column">
            <div fxLayoutAlign="start center">
              <span class="primary-bullet">· </span><span>{{vehicle[0].year}} {{vehicle[0].make}}
                {{vehicle[0].model}}</span>
            </div>
          </div>
          <div fxLayout="column" *ngIf="vehicle[0].currentMileage">
            <div fxLayoutAlign="start center">
              <span class="primary-bullet">· </span>
              <span>Current mileage is {{vehicle[0].currentMileage + ' ' + vehicle[0].unitOfMeasureId}}</span>
            </div>
          </div>
          <div fxLayout="column" *ngIf="vehicleManufacturerName">
            <div fxLayoutAlign="start center">
              <span class="primary-bullet">· </span>
              <span>Manufactured by {{vehicleManufacturerName}}</span>
            </div>
          </div>
          <div fxLayout="column" *ngIf="vehicleColour">
            <div fxLayoutAlign="start center">
              <span class="primary-bullet">· </span>
              <span>{{vehicleColour}} in colour</span>
            </div>
          </div>
        </div>
        <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
          <button mat-button [disabled]="selectedProspect.associateId === vehicle[0].associateId || !formValid"
            (click)="reassignVehicle(vehicle[0], selectedRooftopId, selectedProspect, formData, dropdowns)"
            color="primary">Reassign
            Vehicle</button>
        </div>
      </div>
      <ng-template #noVehicleFound>
        <div fxFlexFill fxLayout="column" class="padded-panel">
          <div fxLayout="column" class="padded-panel" *ngIf="vinTranslateError$ | async; else noError">
            <span class="mat-caption">This vehicle could not be added. Please add it directly from the XSellerator
              Vehicle Menu or try another VIN.
            </span>
          </div>
          <ng-template #noError>
            <div fxLayout="column" class="padded-panel">
              <span class="mat-caption">This vehicle could not be found. You can add the vehicle with the button
                below.
              </span>
              <span class="mat-caption">
                A trim selection may be required before the vehicle can be added.
              </span>
            </div>
            <div *ngIf="trims.length > 0 && formData.vin.length > 0" class="form-field" fxLayout="column"
              class="padded-panel">
              <form id="trimForm" fxLayout="row wrap" fxLayoutAlign="start center" [formGroup]="trimForm" novalidate>
                <mat-form-field>
                  <mat-select placeholder="Trim" name="trim" formControlName="trim">
                    <mat-option *ngFor="let trim of trims; let i = index" [value]="trim"
                      (onSelectionChange)="selectTrimAndAddVehicle($event.source.value, formData, selectedRooftopId, selectedProspect, dropdowns)">
                      {{trim.trim}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
          </ng-template>

          <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
            <button mat-button [disabled]="!formValid" (click)="vinTranslate(formData, selectedRooftopId)"
              color="primary">Add
              Vehicle</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>