import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
// eslint-disable-next-line max-len
import {
  CreateAppointmentDialogComponent,
  CreateTaskDialogComponent,
  CrmUiNgActivitiesModule,
} from '@quorum/crm/ui/ng/activities';
import { AddDeductDialogComponent } from '@quorum/crm-equity-mining-ng-ui';
import { CrmProspectsNgUiModule, ProspectCreateDialogComponent } from '@quorum/crm-prospects-ng-ui';
import { ShaComponentsNgUiModule } from '@quorum/sha-components-ng-ui';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { NgPipesModule } from 'ngx-pipes';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { DetailComponent } from './detail/detail.component';
import { MasterComponent } from './master/master.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { ShellComponent } from './shell/shell.component';
import { RooftopSelectionDialog } from './rooftop-selection-dialog/rooftop-selection-dialog.component';
import { VehicleProfileDialogComponent } from './vehicle-profile-dialog/vehicle-profile-dialog.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        CommonPipesModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CommonMaterialComponentsModule,
        CrmUiNgActivitiesModule,
        CrmProspectsNgUiModule,
        SatPopoverModule,
        NgPipesModule,
        ShaComponentsNgUiModule,
    ],
    exports: [ShellComponent, DetailComponent, SettingsDialogComponent, RooftopSelectionDialog],
    declarations: [
        ShellComponent,
        MasterComponent,
        DetailComponent,
        SettingsDialogComponent,
        AboutDialogComponent,
        RooftopSelectionDialog,
        VehicleProfileDialogComponent,
    ],
    providers: [CrmEntityStateService]
})
export class CrmSalesPlannerNgUiModule {}
