<div *ngIf="!(isLoading$ | async)" class="flex justify-center">
    <div *ngIf="(creatingProspects$ | async)"
        class="bg-black bg-opacity-70 w-full h-screen flex justify-center items-center z-10 flex-col fixed">
        <span class="text-white uppercase tracking-wide">
            Creating Prospects...
        </span>
        <div class="flex flex-row justify-center items-center py-6">
            <div style="border-top-color: transparent !important;"
                class="border-solid animate-spin rounded-full border-grey-200 border-8 h-20 w-20"></div>
        </div>
    </div>

    <div class="search-result-card-container w-4/5 py-4" *ngIf="results">
        <div class="w-full flex justify-center mb-3">
            <button (click)="batchCreateProspects('available', results.data, authenticatedEmployee)"
                class="w-max px-3 py-2 bg-quorum-blue text-white rounded-xl">Batch Create
                Prospects</button>
            <button (click)="sendMessage()" class="w-max px-3 py-2 bg-quorum-blue text-white rounded-xl ml-2">Send
                Message</button>
        </div>
        <div *ngFor="let item of results.data" class="result-container relative">
            <button (click)="showDeleteModal=!showDeleteModal;deletedVehicleId=item.vehicleId">
                <svg class="h-4 w-4 absolute right-0 top-0 m-1" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke="red" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
            <div class="result-container-header">
                <div class="result-container-header-text">
                    <div class="flex">
                        <mat-icon *ngIf="item.actioned">check_circle_outline</mat-icon>
                        <h3>{{item.customerFirstName + ' ' + item.customerLastName}}</h3>
                    </div>
                    <span>Available {{item.availableDate | moment:
                        'MMM DD, YYYY'}}</span>
                </div>
                <div class="result-container-header-actions">
                    <button *ngIf="item.actioned" mat-icon-button type="button" (click)="openProspect(item.prospectId)">
                        <mat-icon color="primary">person</mat-icon>
                    </button>
                    <button *ngIf="!item.actioned" mat-icon-button type="button"
                        (click)="openCreateProspectDialog('available', item, authenticatedEmployee)">
                        <mat-icon color="primary">person_add</mat-icon>
                    </button>
                    <span class="result-header-divider">|</span>
                    <button mat-button [color]="item.equityValue > 0 ? 'black' : 'warn'"
                        [disabled]="!item.blackbookUvc">{{item.equityValue
                        | currency}}</button>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div class="result-container-content">
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <span>Service appointment ID: {{item.serviceAppointmentId }}</span>
                </div>
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <span>Service appointment date: {{item.appointmentDate | moment: 'hh:mm a MMM DD, YYYY'}}</span>
                </div>
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <span>Service advisor: {{item.serviceAdvisorFirstName}} {{item.serviceAdvisorLastName}}</span>
                </div>
                <div class="result-container-content-line">
                    <span class="mat-body-2">{{item.vehicleYear + ' ' + item.vehicleMake
                        + ' ' + item.vehicleDescription + ' ' }}(<a style="color: rgb(0,0,238);cursor:'pointer'"
                            class="mat-body-1">ID:
                            {{item.vehicleId}}</a>)
                    </span>
                </div>
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <span>{{item.term}}
                        month {{item.saleTypeDescription}} sold
                        by {{item.originalSalespersonFirstName + ' ' +
                        item.originalSalespersonLastName}} (<a>ID: {{item.saleId}}</a>)</span>
                </div>
                <div class="result-container-content-line" *ngIf="item.originalSalespersonId !== item.salespersonId">
                    <span class="primary-bullet">·</span>
                    <span>
                        Current salesperson is {{item.salespersonFirstName}}
                        {{item.salespersonLastName}}
                    </span>
                </div>
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <ng-container [ngSwitch]="item.paymentFrequencyId">
                        <span *ngSwitchCase="'A'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} annual
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                        <span *ngSwitchCase="'B'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} bi-weekly
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                        <span *ngSwitchCase="'M'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} monthly
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                        <span *ngSwitchCase="'N'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} semi-monthly
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                        <span *ngSwitchCase="'P'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} pre-paid lease
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                        <span *ngSwitchCase="'S'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} semi-annual
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                        <span *ngSwitchCase="'W'">{{item.remainingPayments
                            ? item.remainingPayments : 0}} weekly
                            payments remaining of {{item.payment | currency}} @
                            {{item.interestRate}}%</span>
                    </ng-container>
                </div>
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <span> Current mileage is
                        {{ item.currentMileage ? item.currentMileage + mileageLabel : 'unknown'
                        }}</span>
                </div>
                <div class="result-container-content-line">
                    <div class="result-container-content-line">
                        <span class="primary-bullet">·</span>
                        <span>{{item.remainingBalance
                            | currency}}
                            remaining</span>
                    </div>
                    <div *ngIf="item.saleTypeId === 'L'" class="result-container-content-line">
                        <span>, {{item.residualValue
                            | currency}}
                            residual</span>
                    </div>
                </div>
                <div class="result-container-content-line">
                    <span class="primary-bullet">·</span>
                    <span>{{item.tradeValue + item.addDeductsTotal | currency}}
                        trade value</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-title" *ngIf="(isLoading$ | async) && !(creatingProspects$ | async)"
    class="fixed w-full h-screen flex justify-center items-center z-10 flex-col">
    <span class="uppercase tracking-wide text-grey-400">
        Loading...
    </span>
    <div class="flex flex-row justify-center items-center py-6">
        <div style="border-top-color: transparent !important;"
            class="border-solid animate-spin rounded-full border-grey-400 border-8 h-20 w-20"></div>
    </div>
</div>

<div *ngIf="showDeleteModal" class="fixed z-10 inset-0 overflow-y-auto bg-opacity-50 bg-black"
    aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
        <div
            class="inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6">
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <button type="button" (click)="showDeleteModal=!showDeleteModal"
                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Close</span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="lightGrey" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            <div class="flex justify-center">
                <div class="mt-3 text-center">
                    <div class="mt-1">
                        <p class="text-base text-gray-500">
                            Are you sure you want to remove item from list?
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row-reverse justify-center">
                <div>
                    <button type="button" (click)="showDeleteModal=!showDeleteModal"
                        class="mt-3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Cancel
                    </button>
                    <button type="button" (click)="showDeleteModal=!showDeleteModal;deleteItem(deletedVehicleId)"
                        class="ml-6 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>