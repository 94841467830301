<div class="filters-container">
  <div class="filters-container-header">
    <h2>
      Filters
    </h2>
    <span class="reset-button" (click)="resetVehicleSearchForm(authenticatedEmployee)">Reset</span>
  </div>
  <div class="filters-container-form">
    <form #vehicleSearchFormRef class="filter-form" [formGroup]="vehicleSearchForm" autocomplete="off"
      (keydown.enter)="vehicleSearchForm.valid" *ngIf="dropdowns$ | async as dropdowns">
      <div class="form-filters-container">
        <div class="form-field">
          <mat-form-field>
            <mat-select placeholder="Rooftop" name="rooftop" formControlName="rooftopId">
              <mat-option *ngFor="let rooftop of dropdowns.rooftops" [value]="rooftop.id.toString()">
                {{ rooftop.displayCode }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select multiple placeholder="Salesperson" name="salespersonId" formControlName="salespersonId">
              <mat-option [value]="dropdowns.salespeople.employeeId.EmployeeId">
                {{ dropdowns.salespeople.employeeId.EmployeeName }}</mat-option>
              <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams">
                <label (click)="selectTeam(salesTeam, vehicleSearchForm)">{{ salesTeam.teamName }}</label>
                <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                  {{ salesperson.EmployeeName }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select multiple placeholder="Sale Type" name="saleTypeId" formControlName="saleTypeId">
              <mat-option value="C">Cash</mat-option>
              <mat-option value="F">Finance</mat-option>
              <mat-option value="L">Lease</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select placeholder="Actioned" name="actioned" formControlName="actioned">
              <mat-option value="1">Yes</mat-option>
              <mat-option value="0">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select multiple placeholder="Equity Type" name="inEquity" formControlName="inEquity">
              <mat-option value="1">Positive</mat-option>
              <mat-option value="0">Negative</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput autocomplete="off" placeholder="Equity Threshold" name="equityThreshold"
              formControlName="equityThreshold">
            <mat-error *ngIf="vehicleSearchForm.get('equityThreshold').hasError('pattern')">Must be a
              number.</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput autocomplete="off" placeholder="Term From" name="termFrom" formControlName="termFrom">
            <mat-error *ngIf="vehicleSearchForm.get('termFrom').hasError('pattern')">Must be a
              number.</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput autocomplete="off" placeholder="Term To" name="termTo" formControlName="termTo">
            <mat-error *ngIf="vehicleSearchForm.get('termTo').hasError('pattern')">Must be a
              number.</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput placeholder="Min Year" name="year" formControlName="vehicleYearFrom">
            <mat-error *ngIf="vehicleSearchForm.get('vehicleYearFrom').invalid">
              {{getErrorMessage('vehicleYearFrom')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput placeholder="Max Year" name="year" formControlName="vehicleYearTo">
            <mat-error *ngIf="vehicleSearchForm.get('vehicleYearTo').invalid">
              {{getErrorMessage('vehicleYearTo')}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput placeholder="Description" name="vehicleDescription" formControlName="vehicleDescription">
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select placeholder="Make" name="vehicleMake" formControlName="make">
              <mat-option *ngFor="let vehicleMake of dropdowns.vehicleMakes" [value]="vehicleMake.description">
                {{vehicleMake.description}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput placeholder="Model Code" name="modelCode" formControlName="modelCode">
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select placeholder="Sub Type" name="vehicleSubTypeId" formControlName="vehicleSubTypeId">
              <mat-option *ngFor="let vehicleSubType of dropdowns.vehicleSubTypes" [value]="vehicleSubType.id">
                {{vehicleSubType.description}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-field">
          <mat-form-field>
            <input matInput placeholder="PEG" name="pegCode" formControlName="pegCode">
          </mat-form-field>
        </div>
      </div>
      <div class="search-button-container">
        <button #searchButton type="submit" [ngClass]="{'search-button--disabled': vehicleSearchForm.invalid}"
          class="search-button mat-elevation-z2">
          <mat-icon [ngClass]="{'search-icon--disabled': vehicleSearchForm.invalid}" class="search-icon">
            search
          </mat-icon> <span class="search-button-text">Search</span>
        </button>
      </div>
    </form>
  </div>
</div>

<div class="content-container">
  <div class="content-container-header">
    <div class="content-container-header-text">
      <h2>
        Results
      </h2>
      <span class="clear-button" (click)="clearSearchResults()">Clear</span>
    </div>
    <div class="form-field">
      <mat-form-field>
        <mat-select placeholder="Sort" name="sort" (selectionChange)="sortChanged(sort, vehicleSearchForm)"
          [(ngModel)]="sort">
          <mat-option value="mileageCost">Mileage Cost ASC</mat-option>
          <mat-option value="-mileageCost">Mileage Cost DESC</mat-option>
          <mat-option value="availableDate">Available Date ASC</mat-option>
          <mat-option value="-availableDate">Available Date DESC</mat-option>
          <mat-option value="equityValue">Equity Value ASC</mat-option>
          <mat-option value="-equityValue">Equity Value DESC</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="search-results-container" *ngIf="searchResult">
    <div *ngIf="!(isLoading$ | async)" class="search-result-card-container">

      <div class="mb-5 w-full flex justify-start items-center">
        <input type="checkbox" [(ngModel)]="this.selectAll" (click)="selectAllCards(this.selectAll)"
          class=" select-all">
        <div *ngIf="this.selectAll;else deselectAll" class="ml-2 text-quorum-blue text-base">
          Deselect All
        </div>
        <ng-template #deselectAll>
          <div class="ml-2 text-quorum-blue text-base">Select All</div>
        </ng-template>
      </div>

      <div *ngFor="let item of searchResultData;trackBy: trackByFunction" class="result-container">
        <div class="relative">
          <input name="item.vehicleId" type="checkbox" class="result-container-input" [(ngModel)]="item.checked"
            (click)="addRemoveVehicleId(item.checked,item.vehicleId)">
          <label for="item.vehicleId" class="result-container-label">
            <div class="result-container-header">
              <div class="result-container-header-text">
                <div class="flex">
                  <mat-icon *ngIf="item.actioned">check_circle_outline</mat-icon>
                  <h3>{{ item.customerFirstName + ' ' + item.customerLastName }}</h3>
                </div>
                <span>Available {{ item.availableDate | moment: 'MMM DD, YYYY' }}</span>
              </div>
              <div class="result-container-header-actions">
                <button *ngIf="item.actioned" mat-icon-button type="button" (click)="openProspect(item.prospectId)">
                  <mat-icon color="primary">person</mat-icon>
                </button>
                <button *ngIf="!item.actioned" mat-icon-button type="button"
                  (click)="openCreateProspectDialog('vehicles', item, authenticatedEmployee)">
                  <mat-icon color="primary">person_add</mat-icon>
                </button>

                <span class="result-header-divider">|</span>
                <button mat-button [color]="item.equityValue > 0 ? 'black' : 'warn'"
                  (click)="openDetailsDialog(authenticatedEmployee, item, countryCode)"
                  [disabled]="!item.blackbookUvc">{{item.equityValue
                  | currency}}</button>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="result-container-content">
              <div class="result-container-content-line">
                <span class="mat-body-2">{{ item.vehicleYear + ' ' + item.vehicleMake + ' ' + item.vehicleDescription +
                  ' ' }}(<a *ngIf="browserControlCommunicationService.drilldownEnabled else noDrilldownEnabled"
                    style="color:rgb(0,0,238);cursor:pointer" (click)="browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [item.vehicleId.toString()])
                    " class="mat-body-1">ID: {{ item.vehicleId }}</a>)

                  <ng-template #noDrilldownEnabled>
                    <a style="color:rgb(0,0,238);cursor:pointer" (click)="openVehicleProfileInfo(item)"
                      class="mat-body-1">ID: {{ item.vehicleId }}</a>
                  </ng-template>
                </span>
              </div>
              <div class="result-container-content-line">
                <span class="primary-bullet">· </span><span>{{ item.term }}
                  month
                  {{ item.saleTypeDescription }} sold by
                  {{ item.originalSalespersonFirstName + ' ' + item.originalSalespersonLastName }} (<a [ngStyle]="{
                      color: browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)' : '#000',
                      cursor: browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''
                    }" (click)="
                      browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_sales_maintain', [
                        item.saleId
                      ])
                    ">ID: {{ item.saleId }}</a>)</span>
              </div>
              <div class="result-container-content-line" *ngIf="item.originalSalespersonId !== item.salespersonId">
                <span class="primary-bullet">·</span>
                <span>
                  Current salesperson is {{item.salespersonFirstName}} {{item.salespersonLastName}}
                </span>
              </div>
              <div class="result-container-content-line">
                <span class="primary-bullet">·</span>
                <ng-container [ngSwitch]="item.paymentFrequencyId">
                  <span *ngSwitchCase="'A'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} annual
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                  <span *ngSwitchCase="'B'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} bi-weekly
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                  <span *ngSwitchCase="'M'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} monthly
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                  <span *ngSwitchCase="'N'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} semi-monthly
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                  <span *ngSwitchCase="'P'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} pre-paid lease
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                  <span *ngSwitchCase="'S'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} semi-annual
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                  <span *ngSwitchCase="'W'">{{item.remainingPayments
                    ? item.remainingPayments : 0}} weekly
                    payments remaining of {{item.payment | currency}} @ {{item.interestRate}}%</span>
                </ng-container>
              </div>
              <div class="result-container-content-line">
                <span class="primary-bullet">·</span>
                <span> Current mileage is
                  {{ item.currentMileage ? item.currentMileage + mileageLabel : 'unknown' }}</span>
              </div>
              <div class="result-container-content-line">
                <span class="primary-bullet">· </span><span>Anticipated
                  mileage: {{ item.anticipatedMileage | number }}/{{ item.allowableMileage | number
                  }}{{ mileageLabel }}</span>
              </div>
              <div class="result-container-content-line" *ngIf="item.saleTypeId === 'L'">
                <span class="primary-bullet">·
                </span><span>{{ item.mileageCost | currency }} Mileage Cost Charged @ {{ item.mileageRate | currency
                  }}/{{
                  mileageLabel
                  }}</span>
              </div>
              <div class="result-container-content-line">
                <div class="result-container-content-line">
                  <span class="primary-bullet">·</span>
                  <span>{{ item.remainingBalance | currency }} remaining</span>
                </div>
                <div *ngIf="item.saleTypeId === 'L'" class="result-container-content-line">
                  <span>, {{ item.residualValue | currency }} residual</span>
                </div>
              </div>
              <div class="result-container-content-line">
                <span class="primary-bullet">·
                </span><span>{{ item.tradeValue + item.addDeductsTotal | currency }} trade
                  value</span>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="result-message-container">
      <span class="mat-title" *ngIf="(isLoading$ | async); else checkError">Loading...</span>
      <ng-template #checkError>
        <span class="mat-title mat-error" *ngIf="(hasError$ | async); else noData">Error retrieving results.</span>
        <ng-template #noData>
          <span class="mat-title" *ngIf="searchResult.data.length === 0 && searchPerformed">No results found.</span>
        </ng-template>
      </ng-template>
    </div>

    <div class="w-full flex justify-center mt-4 mb-1">
      <a [routerLink]="['/crm/lead-generator/list/vehicles']" target="_blank"
        [queryParams]="{vehicleIds: this.checkedVehicleIds.join(',')}">
        <button *ngIf="this.checkedVehicleIds.length > 0" class="bg-quorum-blue text-white px-5 py-3 rounded-3xl">Export
          Selected List</button>
      </a>
    </div>

    <mat-paginator [length]="searchResult.headers?.total" [pageSize]="vehicleSearchForm.value.pageSize"
      [pageIndex]="vehicleSearchForm.value.page" (page)="pageChanged($event, vehicleSearchForm)" [pageSizeOptions]="">
    </mat-paginator>
  </div>
</div>