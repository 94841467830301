import { Injectable } from '@angular/core';
import { Colour } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromVehicleColours from './vehicle-colours.actions';
import { VehicleColoursState } from './vehicle-colours.interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleColoursEffects {
  
  getVehicleColoursFromServer = createEffect(() => this.dataPersistence.fetch(fromVehicleColours.GET_VEHICLE_COLOURS_FROM_SERVER, {
    run: (action: fromVehicleColours.GetVehicleColoursFromServer, state: VehicleColoursState) => {
      return this.apiService
        .get<Colour[]>(`v/1/vehicles/vehicle-colours`, {
          params: action.payload
        })
        .pipe(
          map(vehicleColours => {
            return new fromVehicleColours.GetVehicleColoursFromServerSuccess({
              vehicleColours: vehicleColours
            });
          })
        );
    },
    onError: (action: fromVehicleColours.GetVehicleColoursFromServer, error) => {
      console.error('Error', error);
    }
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleColoursState>,
    private apiService: ApiService
  ) {}
}
