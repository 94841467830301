<mat-nav-list>
  <ng-container *ngFor="let sale of openSales | orderBy: '-id';let i = index;">
    <mat-list-item
      [disableRipple]="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q' ? true : browserControlCommunicationService.disableRippleEffect"
      (click)="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q' ? null : openSale(sale)">
      <div *ngIf="sale.deskId == null; else avDeal" class="icon-container" mat-list-icon
        [class.disabled]="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q'">
        <img src="assets/qlogo.png" style="height:35px">
      </div>
      <ng-template #avDeal>
        <img matListAvatar src="assets/autovance-emblem.png" alt="..."
          [class.disabled]="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q'">
      </ng-template>
      <h4 mat-line [class.disabled]="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q'">{{sale.id}} -
        {{sale.statusId == '1' ? 'Pending' : sale.statusId == '2' ?
        'Business Office' : sale.statusId == 'C' ? 'Closed' : sale.statusId == 'O' ? 'Open'
        :sale.statusId == 'P' ? 'Posted' : sale.statusId == 'Q' ? 'Quote' : sale.statusId == 'S' ?
        'Sold' : ''}}</h4>
      <p mat-line class="secondary-text"
        [class.disabled]="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q'">Created
        {{sale.enteredDate | moment: 'hh:mm A MMM DD, YYYY'}}</p>
      <p *ngIf="sale.embedded.vehicle" mat-line class="secondary-text"
        [class.disabled]="this.settings.autovanceDeskRole === 0 && sale.statusId == 'Q'">
        {{sale.embedded.vehicle?.year}} {{sale.embedded.vehicle?.make}}
        {{sale.embedded.vehicle?.model}} (ID: {{sale.embedded.vehicle?.id}} — Unit #: {{sale.embedded.vehicle?.stockNumber}})</p>
      <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation();">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openEdocs(sale.id);">
          <span>Open eDocs</span>
        </button>
      </mat-menu>
    </mat-list-item>
    <mat-divider inset *ngIf="i < openSales.length-1"></mat-divider>
  </ng-container>
</mat-nav-list>