<div id="prospectAggregateDiv" class="pointer" fxLayout="row" fxLayoutAlign="space-between center"
  (click)="toggleProspectAggregateData(prospectAggregatePanel)">
  <span class="mat-subheading-2 pointer">Aggregate Data</span>
  <div *ngIf="!prospectAggregatePanel; else showProspectAggregatePanel" class="show-hide-container"
    fxLayoutAlign="center center">
    <span class="mat-caption">Show</span>
    <mat-icon>keyboard_arrow_down</mat-icon>
  </div>
  <ng-template #showProspectAggregatePanel>
    <div fxLayoutAlign="center center" class="show-hide-container">
      <span class="mat-caption">Hide</span>
      <mat-icon>keyboard_arrow_up</mat-icon>
    </div>
  </ng-template>
</div>
<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="cards-container" *ngIf="prospectAggregatePanel">
  <div fxLayout="column" fxFlex="49" fxFlex.xs="99" class="card-container">
    <div fxLayoutAlign=" space-between center">

      <div fxLayout="row" fxLayoutAlign="center center">
        <h3>Customer</h3>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" class="card-content-container">
      <div fxLayout="column">
        <div fxLayoutAlign="start center"><span class="primary-bullet">·
          </span><span>{{prospectAggregate.YearsAsCustomer}} Years as a
            Customer</span></div>
      </div>
      <div fxLayout="column">
        <div fxLayoutAlign="start center"><span class="primary-bullet">·
          </span><span>{{prospectAggregate.AvgServVisitsYear}} Service Visits
            per Year @ {{prospectAggregate.AvgDollarsRO | currency}} per R/O</span></div>
        <div fxLayoutAlign="start center"><span class="primary-bullet">·
          </span><span> {{prospectAggregate.TotalSales | currency}} Total
            Sales</span></div>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxFlex="49" fxFlex.xs="99" class="card-container">
    <div fxLayoutAlign="space-between center">

      <div fxLayout="row" fxLayoutAlign="center center">
        <h3>Vehicles</h3>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" class="card-content-container">
      <div fxLayout="column">
        <div fxLayoutAlign="start center"><span class="primary-bullet">·
          </span><span>{{prospectAggregate.TotalPurchased}} Purchased</span></div>
      </div>
      <div fxLayout="column">
        <div fxLayoutAlign="start center"><span class="primary-bullet">·
          </span><span>{{prospectAggregate.CurrentVehicles}} Current</span></div>
        <div fxLayoutAlign="start center"><span class="primary-bullet">·
          </span><span>Last purchased {{prospectAggregate.LastPurchaseDate |
            moment: 'MM/DD/YYYY'}}</span></div>
      </div>
    </div>
  </div>
</div>