import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { VehicleOfInterestQueryParameters } from '@quorum/models/xs-query';
import { AuthenticatedUser, VehicleOfInterest } from '@quorum/models/xs-resource';
import { SalesPlannerStateService, SelectedActivityStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { Observable } from 'rxjs';
import { ProspectVehicleOfInterestDialogComponent } from '../prospect-vehicle-of-interest-dialog/prospect-vehicle-of-interest-dialog.component';

@Component({
  selector: 'crm-prospect-vehicle-of-interest',
  templateUrl: './prospect-vehicle-of-interest.component.html',
  styleUrls: ['./prospect-vehicle-of-interest.component.scss'],
})
export class ProspectVehicleOfInterestComponent implements OnInit {
  @Input() prospectVehicleOfInterest: VehicleOfInterest[];
  @Input() prospectId: number;

  currentUser$: Observable<any>;

  showDeleteModal: boolean = false;

  deleteVehicle: VehicleOfInterest;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    private salesPlannerStateService: SalesPlannerStateService
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.authenticationStateService.selectAuthenticatedUser();
  }

  deleteVehicleOfInterest(vehicle: VehicleOfInterest) {
    this.selectedActivityStateService.deleteSelectedProspectVehicleOfInterest(vehicle);
    this.salesPlannerStateService.addToast({
      message: `Vehicle of interest deleted.`,
      actionText: null,
      options: { duration: 3000 },
    });
  }

  openVehicleOfInterestDialog(dialog: MatDialog, prospectId: number, vehicle: VehicleOfInterest): void {
    const dialogRef = dialog.open(ProspectVehicleOfInterestDialogComponent, {
      data: { prospectId: prospectId, vehicleOfInterest: vehicle },
      width: '400px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe((vehicleOfInterest: VehicleOfInterest) => {
      if (vehicle && vehicleOfInterest) {
        vehicleOfInterest.prospectId = prospectId;
        vehicleOfInterest.id = vehicle.id;
        this.selectedActivityStateService.updateSelectedProspectVehicleOfInterest(vehicleOfInterest);
        this.salesPlannerStateService.addToast({
          message: `Vehicle of interest updated.`,
          actionText: null,
          options: { duration: 3000 },
        });
      } else if (vehicleOfInterest) {
        vehicleOfInterest.prospectId = prospectId;
        this.selectedActivityStateService.createSelectedProspectVehicleOfInterestFromServer(vehicleOfInterest);
        this.salesPlannerStateService.addToast({
          message: `Vehicle of interest created.`,
          actionText: null,
          options: { duration: 3000 },
        });
      }
    });
  }
}
