import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { Address, Associate, Prospect, ProspectAction } from '@quorum/models/xs-resource';

export const CLEAR_PROSPECT_STATE = '[CRM] Clear Prospect State';
export class ClearProspectState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_PROSPECT_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: {}) {}
}

export const GET_PROSPECTS_FROM_SERVER = '[CRM] Get Prospects From Server';
export class GetProspectsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECTS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectQueryParameters) {}
}

export const GET_PROSPECTS_FROM_SERVER_SUCCESS = '[CRM] Get Prospects From Server Success';
export class GetProspectsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECTS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(
    public payload: { prospects: Prospect[]; total: number; totalPages: number; activePage: number; pageSize: number }
  ) {}
}

export const GET_PROSPECTS_FROM_SERVER_FAILURE = '[CRM] Get Prospects From Server Failure';
export class GetProspectsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECTS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CREATE_PROSPECT_ON_SERVER = '[CRM] Create Prospect On Server';
export class CreateProspectOnServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_PROSPECT_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospect: Prospect; salespersonTakeoverMessage: string }) {}
}

export const CREATE_PROSPECT_ON_SERVER_SUCCESS = '[CRM] Create Prospect on Server Success';
export class CreateProspectOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_PROSPECT_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospect: Prospect; salespersonTakeoverMessage: string }) {}
}

export const CREATE_PROSPECT_ON_SERVER_FAILURE = '[CRM] Create Prospects On Server Failure';
export class CreateProspectOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_PROSPECT_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER = '[CRM] Create Prospect and Associate On Server';
export class CreateProspectAndAssociateOnServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(
    public payload: { address: Address; associate: Associate; prospect: Prospect; salespersonTakeoverMessage: string }
  ) {}
}

export const CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS = '[CRM] Create Prospect and Associate on Server Success';
export class CreateProspectAndAssociateOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospect: Prospect; salespersonTakeoverMessage: string }) {}
}

export const CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_FAILURE = '[CRM] Create Prospect and Associate On Server Failure';
export class CreateProspectAndAssociateOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER =
  '[CRM] Update Prospect and Create Associate and Address On Server';
export class UpdateProspectAndCreateAssociateAndAddressOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { address: Address; associate: Associate; prospect: Prospect }) {}
}

export const UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER_SUCCESS =
  '[CRM] Update Prospect and Create Associate and Address On Server Success';
export class UpdateProspectAndCreateAssociateAndAddressOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospect: Prospect }) {}
}

export const UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER_FAILURE =
  '[CRM] Update Prospect and Create Associate and Address On Server Failure';
export class UpdateProspectAndCreateAssociateAndAddressOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_PROSPECT_ON_SERVER = '[CRM] Update Prospect On Server';
export class UpdateProspectOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(
    public payload: {
      prospect: Prospect;
      displayToast: boolean;
      salespersonTakeoverData?: {
        salespersonTakeoverMessage: string;
        salespersonTakeoverProspectAction: ProspectAction;
      };
    }
  ) {}
}

export const UPDATE_PROSPECT_ON_SERVER_SUCCESS = '[CRM] Update Prospect on Server Success';
export class UpdateProspectOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(
    public payload: {
      prospect: Update<Prospect>;
      displayToast: boolean;
      salespersonTakeoverData?: {
        salespersonTakeoverMessage: string;
        salespersonTakeoverProspectAction: ProspectAction;
      };
    }
  ) {}
}

export const UPDATE_PROSPECT_ON_SERVER_FAILURE = '[CRM] Update Prospects On Server Failure';
export class UpdateProspectOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_SELECTED_PROSPECT_ON_SERVER = '[CRM] Update Selected Prospect On Server';
export class UpdateSelectedProspectOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(
    public payload: {
      prospect: Prospect;
      displayToast: boolean;
      salespersonTakeoverData?: {
        salespersonTakeoverMessage: string;
        salespersonTakeoverProspectAction: ProspectAction;
      };
    }
  ) {}
}

export const UPDATE_SELECTED_PROSPECT_ON_SERVER_SUCCESS = '[CRM] Update Selected Prospect on Server Success';
export class UpdateSelectedProspectOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(
    public payload: {
      prospect: Update<Prospect>;
      displayToast: boolean;
      salespersonTakeoverData?: {
        salespersonTakeoverMessage: string;
        salespersonTakeoverProspectAction: ProspectAction;
      };
    }
  ) {}
}

export const UPDATE_SELECTED_PROSPECT_ON_SERVER_FAILURE = '[CRM] Update Selected Prospect On Server Failure';
export class UpdateSelectedProspectOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_ASSOCIATE_ON_SERVER = '[CRM] Update Associate On Server';
export class UpdateAssociateOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_ASSOCIATE_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: Prospect) {}
}

export const UPDATE_ASSOCIATE_ON_SERVER_SUCCESS = '[CRM] Update Associate on Server Success';
export class UpdateAssociateOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_ASSOCIATE_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: Update<Prospect>) {}
}

export const UPDATE_ASSOCIATE_ON_SERVER_FAILURE = '[CRM] Update Associate On Server Failure';
export class UpdateAssociateOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_ASSOCIATE_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const ADD_PROSPECT_TO_STATE = '[CRM] Add Prospect to State';
export class AddProspectToState implements Action {
  readonly feature = 'crm';
  readonly type = ADD_PROSPECT_TO_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: Prospect) {}
}

export const UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER = '[CRM] Update Prospect Associate Address On Server';
export class UpdateProspectAssociateOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(
    public payload: {
      associate: Associate;
      prospect: Prospect;
      address: Address;
      salespersonTakeoverData?: {
        salespersonTakeoverMessage: string;
        salespersonTakeoverProspectAction: ProspectAction;
      };
    }
  ) {}
}

export const UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER_SUCCESS =
  '[CRM] Update Prospect And Associate Address on Server Success';
export class UpdateProspectandAssociateOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: Update<Address>) {}
}

export const UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER_FAILURE =
  '[CRM] Update Prospect And Associate Address On Server Failure';
export class UpdateProspectAndAssociateOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectsAction =
  | GetProspectsFromServer
  | GetProspectsFromServerSuccess
  | GetProspectsFromServerFailure
  | CreateProspectOnServer
  | CreateProspectOnServerSuccess
  | CreateProspectOnServerFailure
  | CreateProspectAndAssociateOnServer
  | CreateProspectAndAssociateOnServerSuccess
  | CreateProspectAndAssociateOnServerFailure
  | UpdateProspectOnServer
  | UpdateProspectOnServerSuccess
  | UpdateProspectOnServerFailure
  | UpdateSelectedProspectOnServer
  | UpdateSelectedProspectOnServerSuccess
  | UpdateSelectedProspectOnServerFailure
  | AddProspectToState
  | UpdateProspectAssociateOnServer
  | UpdateProspectandAssociateOnServerSuccess
  | UpdateProspectAndAssociateOnServerFailure
  | UpdateProspectAndCreateAssociateAndAddressOnServer
  | UpdateProspectAndCreateAssociateAndAddressOnServerSuccess
  | UpdateProspectAndCreateAssociateAndAddressOnServerFailure;
