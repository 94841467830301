import { VehicleMake } from '@quorum/models/xs-resource';
import { VehicleMakeQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_MAKES_FROM_SERVER = '[CRM] Get Vehicle Makes From Server';
export class GetVehicleMakesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_MAKES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleMakeQueryParameters) {}
}

export const GET_VEHICLE_MAKES_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Makes From Server Success';
export class GetVehicleMakesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_MAKES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleMakes: VehicleMake[] }) {}
}

export const GET_VEHICLE_MAKES_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Makes From Server Failure';
export class GetVehicleMakesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_MAKES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VehicleMakesAction =
  | GetVehicleMakesFromServer
  | GetVehicleMakesFromServerSuccess
  | GetVehicleMakesFromServerFailure;
