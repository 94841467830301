import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Prospect } from '@quorum/models/xs-resource';

export interface ProspectsState extends EntityState<Prospect> {
  total: number;
  totalPages: number;
  activePage: number;
  pageSize: number;
  loadingPage: boolean;
}

export const prospectsAdapter: EntityAdapter<Prospect> = createEntityAdapter<Prospect>({
  selectId: (prospect: Prospect) => prospect.id,
});

export const initialState: ProspectsState = prospectsAdapter.getInitialState({
  total: null,
  totalPages: null,
  activePage: null,
  pageSize: null,
  loadingPage: false,
});

export function getSelectors(featureSelector: any) {
  const prospectsSelector = createSelector(featureSelector, (state: any) => state.prospects);
  const prospectsPageDetailsSelector = createSelector(prospectsSelector, (state: any) => {
    return {
      total: state.total,
      totalPages: state.totalPages,
      activePage: state.activePage,
      pageSize: state.pageSize,
      loadingPage: state.loadingPage,
    };
  });
  const { selectIds, selectEntities, selectAll, selectTotal } = prospectsAdapter.getSelectors(prospectsSelector);
  return { selectIds, selectEntities, selectAll, selectTotal, prospectsSelector, prospectsPageDetailsSelector };
}
