import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { ShellComponent } from './shell/shell.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VehicleCardComponent } from './vehicle-card/vehicle-card.component';
import { CommonPipesModule } from '@quorum/common-pipes';

@NgModule({
  imports: [
    CommonMaterialComponentsModule,
    CommonModule,
    CommonPipesModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ShellComponent, VehicleCardComponent],
  declarations: [ShellComponent, VehicleCardComponent]
})
export class CrmVehicleSearchNgUiModule {}
