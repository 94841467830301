import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { MomentPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import {
  ServiceAppointmentQueryParameters,
  CrmAppointmentQueryParameters as SalesAppointmentQueryParameters,
  DealerPromotionQueryParameters,
  TaskQueryParameters,
  SaleQueryParameters,
} from '@quorum/models/xs-query';
import {
  ServiceAppointment,
  CrmAppointment as SalesAppointment,
  DealerPromotion,
  Employee,
  Task,
  Sale,
} from '@quorum/models/xs-resource';
import { Activity, ActivityQueryParameters } from '@quorum/xsr-salesplanner-legacy';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LocalSettingsState } from '../local-settings/local-settings.interfaces';
import { LocalSettingsStateService } from '../local-settings/local-settings.state.service';
import { DealerPromotionsStateService } from './dealer-promotions/dealer-promotions-state.service';
import * as fromDealerPromotions from './dealer-promotions/dealer-promotions.actions';
import { DeliveriesStateService } from './deliveries/deliveries-state.service';
import * as fromDeliveries from './deliveries/deliveries.actions';
import { SalesAppointmentsStateService } from './sales-appointments/sales-appointments-state.service';
import * as fromSalesAppointments from './sales-appointments/sales-appointments.actions';
import { ServiceAppointmentsStateService } from './service-appointments/service-appointments-state.service';
import { TasksStateService } from './tasks/tasks-state.service';
import * as fromTasks from './tasks/tasks.actions';

@Injectable()
export class ActivitiesStateService {
  constructor(
    private dealerPromotionsStateService: DealerPromotionsStateService,
    private salesAppointmentsStateService: SalesAppointmentsStateService,
    private serviceAppointmentsStateService: ServiceAppointmentsStateService,
    private tasksStateService: TasksStateService,
    private deliveriesStateService: DeliveriesStateService,
    private store: Store<any>,
    private localSettingsStateService: LocalSettingsStateService,
    private dropdownsStateService: DropdownsStateService
  ) {}

  selectActivitiesList(localSettings: LocalSettingsState) {
    return combineLatest(
      this.dealerPromotionsStateService.selectDealerPromotionsCombined(),
      this.salesAppointmentsStateService.selectSalesAppointmentsCombined(),
      this.serviceAppointmentsStateService.selectServiceAppointmentsCombined(),
      this.tasksStateService.selectTasksCombined(),
      this.deliveriesStateService.selectDeliveriesCombined(),
      this.dropdownsStateService
        .selectEmployees()
        .pipe(filter((employees) => !this.dropdownsStateService.isEmpty(employees)))
    ).pipe(
      filter(([dealerPromotions, salesAppointments, serviceAppointments, tasks, deliveries, employees]) => {
        return (
          dealerPromotions.isLoading == false &&
          salesAppointments.isLoading == false &&
          serviceAppointments.isLoading == false &&
          tasks.isLoading == false &&
          deliveries.isLoading == false &&
          employees != null
        );
      }),
      map(([dealerPromotions, salesAppointments, serviceAppointments, tasks, deliveries, employees]) => {
        // let salespeopleArray: any[] = [];
        // salespeople.teams.forEach((team: any) => {
        //   salespeopleArray = salespeopleArray.concat(team.children);
        // });
        // salespeopleArray.push(salespeople.employeeId);

        dealerPromotions = dealerPromotions.dealerPromotions.map((promo: DealerPromotion) => {
          let dealerPromoSalesPersonEmployee: Employee =
            promo.embedded && !promo.embedded.taskEmployeeAssociate && promo.taskEmployeeId
              ? employees.find((employee) => employee.associateId === promo.taskEmployeeId)
              : null;

          let promotion: Activity = {
            activityType: 'Promotion',
            activityId: promo.id,
            activitySequence: promo.sequence,
            activityDueDate: promo.dueDate,
            activityCompletedDate: promo.completedDate,
            activityDate: new MomentPipe().transform(promo.dueDate, 'YYYYMMDD'),
            activityCreatedDate: promo.createdDate,
            activityObjectives: promo.note,
            activityComments: promo.comments,
            activityOutcome: promo.embedded && promo.embedded.taskOutcome ? promo.embedded.taskOutcome.description : '',
            activityConfirmation: null,
            activityConfirmationEmployee: null,
            activityConfirmationDate: null,
            activityAttendance: null,
            activityStatus: null,
            activityDelivered: null,
            activityEmployeeAssociateId: promo.employeeId,
            associate:
              promo.embedded &&
              promo.embedded.vehicle &&
              promo.embedded.vehicle.embedded &&
              promo.embedded.vehicle.embedded.associate
                ? promo.embedded.vehicle.embedded.associate.id
                : null,
            customerName:
              promo.embedded &&
              promo.embedded.vehicle &&
              promo.embedded.vehicle.embedded &&
              promo.embedded.vehicle.embedded.associate
                ? promo.embedded.vehicle.embedded.associate.classId === 'RETAI'
                  ? promo.embedded.vehicle.embedded.associate.firstName
                  : [
                      promo.embedded.vehicle.embedded.associate.lastName,
                      promo.embedded.vehicle.embedded.associate.firstName,
                    ]
                      .filter(Boolean)
                      .join(', ')
                : promo.embedded && promo.embedded.prospect
                ? promo.embedded.prospect.freeformName
                : '',
            customerContactPref:
              promo.embedded &&
              promo.embedded.vehicle &&
              promo.embedded.vehicle.embedded &&
              promo.embedded.vehicle.embedded.associate
                ? promo.embedded.vehicle.embedded.associate.contactPreferenceId
                : null,
            prospectId: null,
            prospectType: null,
            vehicleId: promo.vehicleId,
            rooftopId: promo.rooftopId,
            open: promo.completedDate ? false : true,
            employeeId: promo.employeeId,
            promotionName:
              promo.embedded && promo.embedded.dealerPromotionHeader
                ? promo.embedded.dealerPromotionHeader.name
                : 'No promotion name found',
            activitySalesperson:
              promo.embedded && promo.embedded.taskEmployeeAssociate
                ? [promo.embedded.taskEmployeeAssociate.lastName, promo.embedded.taskEmployeeAssociate.firstName]
                    .filter(Boolean)
                    .join(', ')
                : dealerPromoSalesPersonEmployee
                ? [
                    dealerPromoSalesPersonEmployee.embedded.associate.lastName,
                    dealerPromoSalesPersonEmployee.embedded.associate.firstName,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : '',
          };
          return promotion;
        });

        salesAppointments = salesAppointments.salesAppointments.map((appointment: SalesAppointment) => {
          let salesAppointment: Activity = {
            activityType: 'Appointment',
            activityId: appointment.id,
            activitySequence: null,
            activityDueDate: appointment.appointmentDate,
            activityCompletedDate: appointment.completedDate,
            activityDate: new MomentPipe().transform(appointment.appointmentDate, 'YYYYMMDD'),
            activityCreatedDate: appointment.createdDate,
            activityObjectives: appointment.objectives,
            activityComments: appointment.comments,
            activityOutcome: appointment.attendanceFlag
              ? appointment.attendanceFlag === 'N'
                ? 'No Show'
                : 'Show'
              : '',
            activityConfirmation: appointment.confirmationFlag,
            activityConfirmationEmployee: appointment.confirmationAssociateId,
            activityConfirmationDate: appointment.confirmationDate,
            activityAttendance: appointment.attendanceFlag,
            activityStatus: null,
            activityDelivered: null,
            activityEmployeeAssociateId:
              appointment.embedded && appointment.embedded.taskEmployeeAssociate
                ? appointment.embedded.taskEmployeeAssociate.id
                : null,
            associate:
              appointment.embedded && appointment.embedded.prospect ? appointment.embedded.prospect.associateId : null,
            customerName:
              appointment.embedded &&
              appointment.embedded.prospect &&
              appointment.embedded.prospect.embedded &&
              appointment.embedded.prospect.embedded.associate
                ? appointment.embedded.prospect.embedded.associate.classId === 'RETAI'
                  ? appointment.embedded.prospect.embedded.associate.firstName
                  : [
                      appointment.embedded.prospect.embedded.associate.lastName,
                      appointment.embedded.prospect.embedded.associate.firstName,
                    ]
                      .filter(Boolean)
                      .join(', ')
                : appointment.embedded && appointment.embedded.prospect
                ? `${appointment.embedded.prospect.freeformName}`
                : 'No prospect name found',
            customerContactPref:
              appointment.embedded &&
              appointment.embedded.prospect &&
              appointment.embedded.prospect.embedded &&
              appointment.embedded.prospect.embedded.associate
                ? appointment.embedded.prospect.embedded.associate.contactPreferenceId
                : null,
            prospectId: appointment.prospectId,
            prospectType:
              appointment.embedded && appointment.embedded.prospect && appointment.embedded.prospect.typeId === 3
                ? 'S'
                : 'R',
            vehicleId:
              appointment.embedded && appointment.embedded.prospect ? appointment.embedded.prospect.vehicleId : null,
            rooftopId:
              appointment.embedded && appointment.embedded.prospect ? appointment.embedded.prospect.rooftopId : null,
            open: appointment.completedDate ? false : true,
            employeeId: appointment.employeeId,
            promotionName: null,
            activitySalesperson:
              localSettings.activityListDisplayEmployee === 'activityAssignee'
                ? appointment.embedded && appointment.embedded.taskEmployeeAssociate
                  ? [
                      appointment.embedded.taskEmployeeAssociate.lastName,
                      appointment.embedded.taskEmployeeAssociate.firstName,
                    ]
                      .filter(Boolean)
                      .join(', ')
                  : ''
                : appointment.embedded &&
                  appointment.embedded.prospect &&
                  appointment.embedded.prospect.embedded &&
                  appointment.embedded.prospect.embedded.primaryEmployeeAssociate
                ? [
                    appointment.embedded.prospect.embedded.primaryEmployeeAssociate.lastName,
                    appointment.embedded.prospect.embedded.primaryEmployeeAssociate.firstName,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : '',
          };
          return salesAppointment;
        });
        serviceAppointments = serviceAppointments.serviceAppointments
          .filter(
            (serviceAppointment: ServiceAppointment) =>
              new Date(serviceAppointment.dateTime) >= new Date() &&
              (serviceAppointment.statusId === 1 || serviceAppointment.statusId === 2)
          )
          .map((appointment: ServiceAppointment) => {
            let serviceSalespersonEmployee: Employee = appointment.embedded?.vehicle?.employeeId
              ? employees.find((employee) => employee.associateId === appointment.embedded.vehicle.employeeId)
              : null;
            let serviceAppointment: Activity = {
              activityType: 'Service',
              activityId: appointment.id,
              activitySequence: null,
              activityDueDate: appointment.dateTime,
              activityCompletedDate: null,
              activityDate: new MomentPipe().transform(appointment.dateTime, 'YYYYMMDD'),
              activityCreatedDate: appointment.dateIn,
              activityObjectives: `${appointment.embedded.vehicle.year} ${appointment.embedded.vehicle.make} ${
                appointment.embedded.vehicle.model
              } ${
                appointment.embedded.serviceAdvisorAssociate != null
                  ? ' - ' +
                    appointment.embedded.serviceAdvisorAssociate.lastName +
                    ', ' +
                    appointment.embedded.serviceAdvisorAssociate.firstName
                  : ''
              }`,
              activityComments: appointment.odometerIn,
              activityOutcome: null,
              activityConfirmation: null,
              activityConfirmationEmployee: null,
              activityConfirmationDate: null,
              activityAttendance: null,
              activityStatus: null,
              activityDelivered: null,
              activityEmployeeAssociateId: appointment.employeeId,
              associate: appointment.customerId,
              customerName:
                appointment.embedded && appointment.embedded.customerAssociate
                  ? appointment.embedded.customerAssociate.classId === 'RETAI'
                    ? appointment.embedded.customerAssociate.firstName
                    : [
                        appointment.embedded.customerAssociate.lastName,
                        appointment.embedded.customerAssociate.firstName,
                      ]
                        .filter(Boolean)
                        .join(', ')
                  : '',
              customerContactPref:
                appointment.embedded && appointment.embedded.customerAssociate
                  ? appointment.embedded.customerAssociate.contactPreferenceId
                  : null,
              prospectId: null,
              prospectType: null,
              vehicleId: appointment.vehicleId,
              rooftopId: appointment.rooftopId,
              open: true, //appointment.open
              employeeId: appointment.employeeId,
              promotionName: null,
              activitySalesperson: serviceSalespersonEmployee
                ? [
                    serviceSalespersonEmployee.embedded.associate.lastName,
                    serviceSalespersonEmployee.embedded.associate.firstName,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : '',
            };
            return serviceAppointment;
          });

        tasks = tasks.tasks.map((task: Task) => {
          let t: Activity = {
            activityType: 'Task',
            activityId: task.id,
            activitySequence: null,
            activityDueDate: task.dueDate,
            activityCompletedDate: task.completedDate,
            activityDate: new MomentPipe().transform(task.dueDate, 'YYYYMMDD'),
            activityCreatedDate: task.createdDate,
            activityObjectives: task.note,
            activityComments: task.comments,
            activityOutcome: task.embedded && task.embedded.taskOutcome ? task.embedded.taskOutcome.description : '',
            activityConfirmation: null,
            activityConfirmationEmployee: null,
            activityConfirmationDate: null,
            activityAttendance: null,
            activityStatus: null,
            activityDelivered: null,
            activityEmployeeAssociateId: task.taskEmployeeId,
            associate:
              task.embedded &&
              task.embedded.prospect &&
              task.embedded.prospect.embedded &&
              task.embedded.prospect.embedded.associate
                ? task.embedded.prospect.associateId
                : null,
            customerName:
              task.embedded &&
              task.embedded.prospect &&
              task.embedded.prospect.embedded &&
              task.embedded.prospect.embedded.associate
                ? task.embedded.prospect.embedded.associate.classId === 'RETAI'
                  ? task.embedded.prospect.embedded.associate.firstName
                  : [
                      task.embedded.prospect.embedded.associate.lastName,
                      task.embedded.prospect.embedded.associate.firstName,
                    ]
                      .filter(Boolean)
                      .join(', ')
                : task.embedded && task.embedded.prospect
                ? `${task.embedded.prospect.freeformName}`
                : 'No customer name found',
            customerContactPref:
              task.embedded &&
              task.embedded.prospect &&
              task.embedded.prospect.embedded &&
              task.embedded.prospect.embedded.associate
                ? task.embedded.prospect.embedded.associate.contactPreferenceId
                : null,
            prospectId: task.prospectId,
            prospectType: task.embedded && task.embedded.prospect && task.embedded.prospect.typeId === 3 ? 'S' : 'R',
            vehicleId: task.embedded && task.embedded.prospect && task.embedded.prospect.vehicleId,
            rooftopId: task.embedded && task.embedded.prospect && task.embedded.prospect.rooftopId,
            open: task.completedDate ? false : true,
            employeeId: task.employeeId,
            promotionName: null,
            activitySalesperson:
              localSettings.activityListDisplayEmployee === 'activityAssignee'
                ? task.embedded.taskEmployeeAssociate
                  ? [task.embedded.taskEmployeeAssociate.lastName, task.embedded.taskEmployeeAssociate.firstName]
                      .filter(Boolean)
                      .join(', ')
                  : ''
                : task.embedded &&
                  task.embedded.prospect &&
                  task.embedded.prospect.embedded &&
                  task.embedded.prospect.embedded.primaryEmployeeAssociate
                ? [
                    task.embedded.prospect.embedded.primaryEmployeeAssociate.lastName,
                    task.embedded.prospect.embedded.primaryEmployeeAssociate.firstName,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : '',
          };
          return t;
        });

        deliveries = deliveries.deliveries.map((delivery: Sale) => {
          let deliverySalespersonEmployee: Employee = delivery.salespersonId
            ? employees.find((employee) => employee.associateId === delivery.salespersonId)
            : null;
          let d: Activity = {
            activityType: 'Delivery',
            activityId: delivery.id,
            activitySequence: null,
            activityDueDate: delivery.deliveryDate,
            activityCompletedDate: null,
            activityDate: new MomentPipe().transform(delivery.deliveryDate, 'YYYYMMDD'),
            activityCreatedDate: delivery.enteredDate,
            activityObjectives: `${delivery.embedded.status.description} - ${
              delivery.embedded.vehicle ? delivery.embedded.vehicle.year : ''
            } ${delivery.embedded.vehicle ? delivery.embedded.vehicle.make : ''} ${
              delivery.embedded.vehicle ? delivery.embedded.vehicle.model : ''
            } `,
            activityComments: null,
            activityOutcome: delivery.embedded && delivery.embedded.status ? delivery.embedded.status.description : '',
            activityConfirmation: null,
            activityConfirmationEmployee: null,
            activityConfirmationDate: null,
            activityAttendance: null,
            activityStatus: delivery.embedded && delivery.embedded.status ? delivery.embedded.status.description : '',
            activityDelivered: null,
            activityEmployeeAssociateId: delivery.salespersonId,
            associate: delivery.customerId,
            customerName:
              delivery.embedded && delivery.embedded.customerAssociate
                ? [delivery.embedded.customerAssociate.lastName, delivery.embedded.customerAssociate.firstName]
                    .filter(Boolean)
                    .join(', ')
                : '',
            customerContactPref:
              delivery.embedded && delivery.embedded.customerAssociate
                ? delivery.embedded.customerAssociate.contactPreferenceId
                : null,
            prospectId:
              delivery.statusId === 'S' || delivery.statusId === 'P'
                ? delivery.retentionProspectId
                : delivery.showroomProspectId,
            prospectType: delivery.statusId === 'S' || delivery.statusId === 'P' ? 'R' : 'S',
            vehicleId: delivery.vehicleId,
            rooftopId: delivery.rooftopId,
            open: delivery.deliveryFlag === '1' ? false : true,
            employeeId: delivery.salespersonId,
            promotionName: null,
            activitySalesperson: deliverySalespersonEmployee
              ? [
                  deliverySalespersonEmployee.embedded.associate.lastName,
                  deliverySalespersonEmployee.embedded.associate.firstName,
                ]
                  .filter(Boolean)
                  .join(', ')
              : delivery.embedded.salespersonAssociate
              ? [delivery.embedded.salespersonAssociate.lastName, delivery.embedded.salespersonAssociate.firstName]
                  .filter(Boolean)
                  .join(', ')
              : '',
          };
          return d;
        });
        return [...dealerPromotions, ...salesAppointments, ...serviceAppointments, ...tasks, ...deliveries];
      })
    );
  }

  getActivitiesFromServer(queryParams: ActivityQueryParameters, localSettings: LocalSettingsState) {
    this.clearActivityState();

    let dateFrom = new Date(
      new Date(queryParams.startDate).getFullYear(),
      new Date(queryParams.startDate).getMonth(),
      new Date(queryParams.startDate).getDate() - localSettings.rangePast
    ).toISOString();

    let dateTo = new Date(
      new Date(queryParams.startDate).getFullYear(),
      new Date(queryParams.startDate).getMonth(),
      new Date(queryParams.startDate).getDate() + localSettings.range + 1
    ).toISOString();

    if (queryParams.activityTypes.includes('A')) {
      this.salesAppointmentsStateService.getSalesAppointmentsFromServer(
        new SalesAppointmentQueryParameters({
          appointmentDateFrom: dateFrom,
          appointmentDateTo: dateTo,
          embed: ['prospect', 'taskEmployeeAssociate', 'employeeAssociate'],
          taskEmployeeId: queryParams.employeeId,
        })
      );
    } else {
      this.salesAppointmentsStateService.clearSalesAppointmentsFromState();
    }
    if (queryParams.activityTypes.includes('P')) {
      this.dealerPromotionsStateService.getDealerPromotionsFromServer(
        new DealerPromotionQueryParameters({
          dueDateFrom: dateFrom,
          dueDateTo: dateTo,
          embed: ['vehicle', 'prospect', 'dealerPromotionHeader', 'taskEmployeeAssociate'],
          taskEmployeeId: queryParams.employeeId,
          open: '1',
        })
      );
    } else {
      this.dealerPromotionsStateService.clearDealerPromotionsFromState();
    }
    if (queryParams.activityTypes.includes('D')) {
      let deliveryDateTo = new Date(queryParams.startDate);
      deliveryDateTo.setDate(deliveryDateTo.getDate() + 1);
      this.deliveriesStateService.getDeliveriesFromServer(
        new SaleQueryParameters({
          embed:
            'vehicle,customerAssociate,status,salespersonAssociate,salesManagerAssociate, businessManagerAssociate',
          salespersonId: queryParams.employeeId,
          deliveryDateFrom: dateFrom,
          deliveryDateTo: dateTo,
          statusId: 'S,2',
        })
      );
    } else {
      this.deliveriesStateService.clearDeliveriesFromState();
    }
    if (queryParams.activityTypes.includes('S')) {
      this.serviceAppointmentsStateService.getServiceAppointmentsFromServer(
        new ServiceAppointmentQueryParameters({
          appointmentDateFrom: dateFrom,
          appointmentDateTo: dateTo,
          salespersonId: queryParams.employeeId,
          embed: 'customerAssociate,taskEmployeeAssociate,serviceAdvisorAssociate,vehicle',
        })
      );
    } else {
      this.serviceAppointmentsStateService.clearServiceAppointmentsFromState();
    }
    if (queryParams.activityTypes.includes('T')) {
      this.tasksStateService.getTasksFromServer(
        new TaskQueryParameters({
          dueDateFrom: dateFrom,
          dueDateTo: dateTo,
          embed: 'prospect,taskEmployeeAssociate,taskOutcome',
          taskEmployeeId: queryParams.employeeId,
          open: '1',
          pageSize: 2000,
        })
      );
    } else {
      this.tasksStateService.clearTasksFromState();
    }
  }

  getOverdueActivitiesFromServer(queryParams: ActivityQueryParameters, localSettings: LocalSettingsState) {
    this.clearActivityState();

    let dateTimeTo = new Date().toISOString();

    let dateFrom = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - localSettings.monthsOverdue,
      new Date().getDate()
    ).toISOString();

    let dateTo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1).toISOString();

    if (queryParams.activityTypes.includes('A')) {
      this.salesAppointmentsStateService.getSalesAppointmentsFromServer(
        new SalesAppointmentQueryParameters({
          appointmentDateFrom: dateFrom,
          appointmentDateTo: dateTimeTo,
          embed: ['prospect', 'taskEmployeeAssociate', 'employeeAssociate'],
          taskEmployeeId: queryParams.employeeId,
        })
      );
    } else {
      this.salesAppointmentsStateService.clearSalesAppointmentsFromState();
    }
    if (queryParams.activityTypes.includes('P')) {
      this.dealerPromotionsStateService.getDealerPromotionsFromServer(
        new DealerPromotionQueryParameters({
          dueDateFrom: dateFrom,
          dueDateTo: dateTo,
          embed: ['vehicle', 'prospect', 'dealerPromotionHeader', 'taskEmployeeAssociate'],
          taskEmployeeId: queryParams.employeeId,
          open: '1',
        })
      );
    } else {
      this.dealerPromotionsStateService.clearDealerPromotionsFromState();
    }
    if (queryParams.activityTypes.includes('D')) {
      let deliveryDateTo = new Date(queryParams.startDate);
      deliveryDateTo.setDate(deliveryDateTo.getDate() + 1);
      this.deliveriesStateService.getDeliveriesFromServer(
        new SaleQueryParameters({
          embed:
            'vehicle,customerAssociate,status,salespersonAssociate,salesManagerAssociate, businessManagerAssociate',
          salespersonId: queryParams.employeeId,
          deliveryDateFrom: dateFrom,
          deliveryDateTo: dateTimeTo,
          statusId: 'S,2',
        })
      );
    } else {
      this.deliveriesStateService.clearDeliveriesFromState();
    }
    if (queryParams.activityTypes.includes('S')) {
      this.serviceAppointmentsStateService.getServiceAppointmentsFromServer(
        new ServiceAppointmentQueryParameters({
          appointmentDateFrom: dateFrom,
          appointmentDateTo: dateTimeTo,
          salespersonId: queryParams.employeeId,
          embed: 'customerAssociate,taskEmployeeAssociate,serviceAdvisorAssociate,vehicle',
        })
      );
    } else {
      this.serviceAppointmentsStateService.clearServiceAppointmentsFromState();
    }
    if (queryParams.activityTypes.includes('T')) {
      this.tasksStateService.getTasksFromServer(
        new TaskQueryParameters({
          dueDateFrom: dateFrom,
          dueDateTo: dateTo,
          embed: 'prospect,taskEmployeeAssociate',
          taskEmployeeId: queryParams.employeeId,
          open: '1',
          pageSize: 2000,
        })
      );
    } else {
      this.tasksStateService.clearTasksFromState();
    }
  }

  clearActivityState() {
    this.salesAppointmentsStateService.clearSalesAppointmentsFromState();
    this.dealerPromotionsStateService.clearDealerPromotionsFromState();
    this.deliveriesStateService.clearDeliveriesFromState();
    this.serviceAppointmentsStateService.clearServiceAppointmentsFromState();
    this.tasksStateService.clearTasksFromState();
  }

  createTask(task: Task) {
    this.store.dispatch(new fromTasks.CreateTaskOnServer(task));
  }

  updateTaskOnServer(task: Task) {
    this.store.dispatch(new fromTasks.UpdateTaskOnServer({ task }));
  }

  updateDealerPromotionOnServer(promo: DealerPromotion) {
    this.store.dispatch(new fromDealerPromotions.UpdateDealerPromotionOnServer(promo));
  }

  createSalesAppointment(salesAppointment: SalesAppointment, communicatorConfirmation: Boolean) {
    this.store.dispatch(
      new fromSalesAppointments.CreateSalesAppointmentOnServer({
        appointment: salesAppointment,
        communicatorConfirmation: communicatorConfirmation,
      })
    );
  }

  updateAppointmentOnServer(oldAppointment: SalesAppointment, newAppointment: SalesAppointment) {
    this.store.dispatch(
      new fromSalesAppointments.UpdateSalesAppointmentOnServer({
        oldAppointment: oldAppointment,
        newAppointment: newAppointment,
      })
    );
  }

  updateDeliveryOnServer(delivery: Sale, queryParameters: SaleQueryParameters) {
    this.store.dispatch(
      new fromDeliveries.UpdateDeliveryOnServer({ delivery: delivery, queryParameters: queryParameters })
    );
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
