import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { ProspectCreateDialogComponent, ProspectCreateProgressDialogComponent } from '@quorum/crm-prospects-ng-ui';
import { LeadGeneratorVehicleSearchQueryParameters } from '@quorum/models/xs-query';
import {
  Action,
  Associate,
  Channel,
  Class,
  Employee,
  LeadGeneratorVehicleSearch,
  OemRooftopLink,
  Prospect,
  ProvinceState,
  Source,
  SubType,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { VehicleProfileDialogComponent } from 'libs/crm-sales-planner-ng-ui/src/lib/vehicle-profile-dialog/vehicle-profile-dialog.component';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, forkJoin, fromEvent, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, delay, filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AddDeductDialogComponent } from '../add-deduct-dialog/add-deduct-dialog.component';

@Component({
  selector: 'crm-vehicles-search-tab',
  templateUrl: './vehicles-search-tab.component.html',
  styleUrls: ['./vehicles-search-tab.component.css'],
})
export class VehiclesSearchTabComponent implements OnInit, AfterViewInit {
  // eslint-disable-next-line max-len
  defaultVehicleSearchFormModel: LeadGeneratorVehicleSearchQueryParameters =
    new LeadGeneratorVehicleSearchQueryParameters({
      actioned: null,
      inEquity: null,
      equityThreshold: null,
      vehicleYearTo: null,
      vehicleYearFrom: null,
      page: 0,
      pageSize: 25,
      pegCode: null,
      salespersonId: [],
      saleTypeId: ['F', 'L', 'C'],
      sort: '-availableDate',
      vehicleSubTypeId: null,
      vehicleDescription: null,
      make: null,
      modelCode: null,
      rooftopId: null,
      termFrom: null,
      termTo: null,
      userEmployeeId: null,
    });

  vehicleSearchForm = this.fb.group({
    actioned: null,
    inEquity: null,
    equityThreshold: [null, [Validators.pattern('^[0-9\\-\\.]*$')]],
    vehicleYearTo: [null, [Validators.pattern('^[0-9]+$'), Validators.minLength(4), Validators.maxLength(4)]],
    vehicleYearFrom: [null, [Validators.pattern('^[0-9]+$'), Validators.minLength(4), Validators.maxLength(4)]],
    page: 0,
    pageSize: 25,
    pegCode: null,
    sort: '-availableDate',
    salespersonId: [],
    saleTypeId: ['F'],
    vehicleSubTypeId: null,
    vehicleDescription: null,
    make: null,
    modelCode: null,
    rooftopId: null,
    termFrom: [null, [Validators.pattern('^[0-9]*$')]],
    termTo: [null, [Validators.pattern('^[0-9]*$')]],
    userEmployeeId: null,
  });
  dropdowns$: Observable<any>;
  routerState$: Subscription;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sort: string;
  searchResult: any = { data: [], headers: null };
  search$: Observable<any>;
  @ViewChild('searchButton') searchButton: any;
  private formInititialized = new ReplaySubject(1);
  public formRef: ElementRef;
  @ViewChild('vehicleSearchFormRef')
  set setTabSet(content: any) {
    if (!!content) {
      this.formRef = content;
      this.formInititialized.next(true);
    }
  }
  mileageLabel: string;
  createProspectProgressRef: MatDialogRef<any>;
  prospectDropdowns$: Observable<any>;
  crmDropdowns$: Observable<any>;
  leadGeneratorActionId: number;
  countryCode: string;
  authenticatedEmployee: Employee;
  searchPerformed: boolean = false;
  postProspectProcessSubscription: Subscription;
  checkedVehicleIds: any[] = [];
  searchResultData: any[];
  selectAll: boolean = false;

  constructor(
    public apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public crmEntityStateService: CrmEntityStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public routerStateService: RouterStateService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public equityMiningStateService: EquityMiningStateService,
    public systemControlStateService: SystemControlStateService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.formInititialized.pipe(take(1)).subscribe((d) => {
      this.search$ = fromEvent(this.formRef.nativeElement, 'submit');
      this.search$
        .pipe(
          switchMap((d) => this.route.queryParams.pipe(take(1))),
          switchMap((params) => {
            if (params) {
              this.equityMiningStateService.updateReportParameters('vehicles', params);
              this.isLoading$.next(true);
              this.hasError$.next(false);
              return this.apiService
                .getWithHeaders<LeadGeneratorVehicleSearch[]>(`v/1/reporting/crm/vehicles`, { params: params })
                .pipe(
                  map((response) => {
                    this.isLoading$.next(false);
                    this.searchPerformed = true;

                    return { data: response.body, headers: response.headers };
                  }),
                  catchError((error) => {
                    this.isLoading$.next(false);
                    this.hasError$.next(true);
                    return of(error);
                  })
                );
            } else {
              this.isLoading$.next(false);
              return of({ data: null, headers: null });
            }
          })
        )
        .subscribe((sresult: any) => {
          this.searchResult = sresult;
          this.searchResultData = this.searchResult.data.map((data: any) => ({ ...data, checked: false }));
          this.checkedVehicleIds.forEach((vehicleId: any) => {
            if (this.searchResultData.some((item) => item.vehicleId === vehicleId)) {
              this.searchResultData.find((item) => item.vehicleId === vehicleId).checked = true;
            }
          });

          this.checkAllChecked();
        });
    });
  }

  ngOnInit() {
    this.vehicleSearchForm.valueChanges.pipe(startWith(null), pairwise()).subscribe(([previousValue, currentValue]) => {
      if (previousValue && previousValue.page === currentValue.page) {
        this.sort = currentValue.sort;
        this.vehicleSearchForm.patchValue({ ...currentValue, page: 0 }, { emitEvent: false });
        this.updateRouter({ ...currentValue, page: 0 });
      } else {
        this.updateRouter(currentValue);
      }
    });

    this.routerState$ = forkJoin(
      this.routerStateService.selectRouterState().pipe(
        filter((routerState) => routerState.state.url.indexOf('lead-generator/vehicles') > -1),
        take(1)
      ),
      this.authenticationStateService.selectAuthenticatedEmployee().pipe(
        filter((employee) => employee != null),
        take(1),
        tap((authEmployee) => {
          this.systemControlStateService
            .getSystemControlValues(['CL_DEALER_COUNTRY_ID'], authEmployee.rooftopId)
            .pipe(take(1))
            .subscribe((sct) => {
              this.authenticatedEmployee = authEmployee;
              this.mileageLabel = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'mi.' : 'km';
              this.countryCode = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'U' : 'C';
            });
        })
      )
    ).subscribe(([routerState, authenticatedEmployee]) => {
      let queryParams: any = routerState.state.root.queryParams;
      if (this.dropdownsStateService.isEmpty(queryParams)) {
        this.vehicleSearchForm.patchValue(
          {
            ...this.defaultVehicleSearchFormModel,
            salespersonId: [authenticatedEmployee.associateId],
            rooftopId: authenticatedEmployee.rooftopId.toString(),
            userEmployeeId: authenticatedEmployee.associateId,
          },
          { emitEvent: true }
        );

        this.sort = '-availableDate';
      } else {
        if (queryParams.sort) {
          this.sort = queryParams.sort;
        }

        let formModel = {
          ...this.defaultVehicleSearchFormModel,
          ...queryParams,
          salespersonId: Array.isArray(queryParams.salespersonId)
            ? [...queryParams.salespersonId]
            : [queryParams.salespersonId],
          inEquity: queryParams.inEquity
            ? Array.isArray(queryParams.inEquity)
              ? [...queryParams.inEquity]
              : [queryParams.inEquity]
            : [],
          saleTypeId: queryParams.saleTypeId
            ? Array.isArray(queryParams.saleTypeId)
              ? [...queryParams.saleTypeId]
              : [queryParams.saleTypeId]
            : [],
        };
        this.vehicleSearchForm.patchValue(formModel, {
          emitEvent: false,
        });

        Object.keys(queryParams).forEach((key) => {
          if (key == 'vehicleSubTypeId') {
            let id =
              this.vehicleSearchForm.controls[key].value == ''
                ? ''
                : parseInt(this.vehicleSearchForm.controls[key].value);
            this.vehicleSearchForm.controls[key].setValue(id);
          }
        });

        this.formInititialized.pipe(delay(1000), take(1)).subscribe((d) => {
          this.renderer.selectRootElement('.search-button', true).click();
        });
      }
    });

    this.dropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleMakes().pipe(take(1)),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
        take(1)
      ),
      this.dropdownsStateService.selectVehicleSubTypes().pipe(
        map((vehicleSubTypes: SubType[]) =>
          vehicleSubTypes.filter((vehicleSubType: SubType) => vehicleSubType.showFlag == '1')
        ),
        take(1)
      ),
      this.dropdownsStateService.selectSalespeople().pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        take(1)
      ),
      this.dropdownsStateService.selectProspectActions().pipe(
        filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions)),
        take(1)
      )
    ).pipe(
      map(([vehicleMakes, rooftops, vehicleSubTypes, salespeople, prospectActions]) => {
        return {
          vehicleMakes,
          rooftops,
          vehicleSubTypes,
          salespeople,
          prospectActions,
        };
      })
    );

    this.prospectDropdowns$ = combineLatest(
      this.dropdownsStateService
        .selectChannels()
        .pipe(filter((channels) => !this.dropdownsStateService.isEmpty(channels))),
      this.dropdownsStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes))),
      this.dropdownsStateService
        .selectMessageTypes()
        .pipe(filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes))),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops) => rooftops.filter((rooftop: any) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService
        .selectSources()
        .pipe(filter((sources) => !this.dropdownsStateService.isEmpty(sources))),
      this.dropdownsStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownsStateService.isEmpty(associateClasses))),
      this.dropdownsStateService
        .selectProspectActions()
        .pipe(filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions))),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(
        ([
          channels,
          customerTypes,
          messageTypes,
          rooftops,
          salespeople,
          sources,
          associateClasses,
          prospectActions,
          provStates,
        ]) => {
          return {
            channels,
            customerTypes,
            messageTypes,
            rooftops,
            salespeople,
            sources,
            associateClasses,
            prospectActions,
            provStates,
          };
        }
      )
    );

    this.crmDropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleSubcategories().pipe(take(1)),
      this.dropdownsStateService.selectVehicleSubTypes().pipe(take(1)),
      this.dropdownsStateService.selectVehicleStatuses().pipe(take(1)),
      this.dropdownsStateService.selectVehicleColours().pipe(take(1)),
      this.dropdownsStateService.selectRooftops().pipe(take(1))
    ).pipe(
      map(([vehicleSubcategories, vehicleSubTypes, vehicleStatuses, vehicleColours, rooftops]) => {
        return {
          vehicleSubcategories,
          vehicleSubTypes,
          vehicleStatuses,
          vehicleColours,
          rooftops,
        };
      })
    );

    this.dropdownsStateService
      .selectProspectActions()
      .pipe(
        filter((prospectActions) => prospectActions != null && prospectActions.length > 0),
        take(1)
      )
      .subscribe((prospectActions) => {
        this.leadGeneratorActionId = prospectActions.find((ac: Action) => ac.description === 'Lead Generator').id;
        this.postProspectProcessSubscription = this.crmEntityStateService
          .postProspectCreationProcess(this.leadGeneratorActionId)
          .subscribe((prospect: Prospect) => {
            this.createProspectProgressRef.close();
            let snackBarRef = this.snackBar.open(`Prospect ${prospect.id} created.`, 'OPEN', {
              duration: 4000,
              horizontalPosition: 'start',
            });

            snackBarRef
              .onAction()
              .pipe(take(1))
              .subscribe((action) => {
                this.routerStateService.go([`sales-planner/prospects/${prospect.id}`], {
                  relativeTo: this.route.parent.parent,
                });
              });
          });
      });
  }

  updateRouter(queryParams: any) {
    let navExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    };

    this.routerStateService.go('', navExtras);
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['..', 'sales-planner', 'prospects', prospectId], {
      relativeTo: this.route.parent,
    });
  }

  sortChanged(sortValue: string, filterForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.sort == sortValue;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.renderer.selectRootElement('.search-button', true).click();
      });

    filterForm.patchValue({ ...filterForm.value, sort: sortValue });
  }

  pageChanged(event: any, filterForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.page == event.pageIndex;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.renderer.selectRootElement('.search-button', true).click();
      });

    filterForm.patchValue({ ...filterForm.value, page: event.pageIndex, pageSize: event.pageSize });
  }

  getReport() {
    this.renderer.selectRootElement('.search-button', true).click();
  }

  selectTeam(salesTeam: any, filterForm: FormGroup) {
    let selectedTeamSalespeople = salesTeam.children.map((salesperson: any) => salesperson.EmployeeId);
    let selectedSalespeople = filterForm.get('salespersonId').value;

    let existingCheck = selectedSalespeople.some((el: any) => selectedTeamSalespeople.includes(el));

    selectedTeamSalespeople.forEach((salespersonId: string) => {
      if (existingCheck) {
        if (selectedSalespeople.includes(salespersonId)) {
          selectedSalespeople.splice(selectedSalespeople.indexOf(salespersonId, 0), 1);
        }
      } else {
        if (!selectedSalespeople.includes(salespersonId)) {
          selectedSalespeople.push(salespersonId);
        }
      }
    });

    filterForm.get('salespersonId').patchValue(null);
    filterForm.get('salespersonId').patchValue(selectedSalespeople);
  }

  trackByFunction(index: number, item: LeadGeneratorVehicleSearch) {
    return item.vehicleId;
  }

  openCreateProspectDialog(reportType: string, reportItem: any, authenticatedEmployee: Employee): void {
    combineLatest(
      this.prospectDropdowns$.pipe(take(1)),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1))
    )
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe((combinedData) => {
        let leadGeneratorChannel = combinedData.dropdowns.channels.find(
          (channel: Channel) => channel.description == 'Lead Generator'
        );
        let reportTypeSource = combinedData.dropdowns.sources.find(
          (source: Source) => source.description.toLowerCase() == reportType
        );

        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (combinedData.sct.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef: MatDialogRef<ProspectCreateDialogComponent> = this.dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: combinedData.dropdowns,
            newProspect: {
              ...new Prospect(),
              actionId: this.leadGeneratorActionId,
              actionedVehicleId: reportItem.vehicleId,
              associateId: reportItem.associateId,
              channelId: leadGeneratorChannel.id,
              customerTypeId: '1',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: authenticatedEmployee.associateId,
              rooftopId: authenticatedEmployee.rooftopId,
              sourceId: reportTypeSource.id,
              statusId: '1',
              typeId: 3,
              vehicleId: reportItem.vehicleId,
            },
            newAssociate: {
              ...new Associate(),
              id: reportItem.associateId,
              firstName: reportItem.customerFirstName,
              lastName: reportItem.customerLastName,
            },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields: {
              associate: [],
              prospect: ['channelId', 'sourceId', 'actionId'],
              contact: [],
            },
          },
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = this.dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
          }
        });
      });
  }

  openDetailsDialog(authenticatedEmployee: Employee, reportItem: any, countryCode: string) {
    const dialogRef = this.dialog.open(AddDeductDialogComponent, {
      width: '600px',
      data: {
        reportItem: reportItem,
        rooftopId: authenticatedEmployee.rooftopId,
        countryCode: countryCode,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse.reload) {
        this.renderer.selectRootElement('.search-button', true).click();
        this.snackBar.open(`Vehicle ${dialogResponse.vehicleId} updated`, null, {
          duration: 3000,
          horizontalPosition: 'start',
        });
      }
    });
  }

  getErrorMessage(formControlName: string) {
    let errorMessage: string;
    switch (Object.keys(this.vehicleSearchForm.get(formControlName).errors)[0]) {
      case 'pattern':
        errorMessage = 'Must only contain numeric characters.';
        break;
      case 'minlength':
      case 'maxlength':
        errorMessage = 'Must be exactly 4 digits.';
        break;
      default:
        errorMessage = 'Invalid input';
    }
    return errorMessage;
  }

  clearSearchResults() {
    this.searchResult = { data: [], headers: null };
  }

  openVehicleProfileInfo(item: any) {
    this.apiService
      .get(`/v/1/vehicles/vehicles/${item.vehicleId}`, { params: { embed: 'sale' } })
      .subscribe((vehicle) => {
        const dialogRef = this.dialog.open(VehicleProfileDialogComponent, {
          disableClose: true,
          panelClass: 'custom-vehicle-profile-dialog',
          data: {
            vehicle: vehicle,
            associateFirstName: item.customerFirstName,
            associateLastName: item.customerLastName,
            crmDropdowns$: this.crmDropdowns$,
            salespersonFirstName: item.salespersonFirstName,
            salespersonLastName: item.salespersonLastName,
          },
        });
      });
  }

  resetVehicleSearchForm(authenticatedEmployee: Employee) {
    this.vehicleSearchForm.patchValue({
      ...this.defaultVehicleSearchFormModel,
      salespersonId: [authenticatedEmployee.associateId],
      rooftopId: authenticatedEmployee.rooftopId.toString(),
      userEmployeeId: authenticatedEmployee.associateId,
    });
  }

  addRemoveVehicleId(checked: boolean, vehicleId: string) {
    if (!checked) {
      if (!this.checkedVehicleIds.includes(vehicleId)) {
        this.checkedVehicleIds.push(vehicleId);
        this.searchResultData.find((item) => item.vehicleId === vehicleId).checked = true;
      }
    } else {
      if (this.checkedVehicleIds.includes(vehicleId)) {
        const id = this.checkedVehicleIds.indexOf(vehicleId);
        this.checkedVehicleIds.splice(id, 1);
        this.searchResultData.find((item) => item.vehicleId === vehicleId).checked = false;
      }
    }
  }

  selectAllCards(select: boolean) {
    for (let i = 0; i < this.searchResultData.length; i++) {
      this.addRemoveVehicleId(select, this.searchResultData[i].vehicleId);
    }
  }

  checkAllChecked() {
    for (let i = 0; i < this.searchResultData.length; i++) {
      if (this.searchResultData[i].checked === false) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    }
  }
}
