import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import {
  DirectMessageTemplate,
  HtmlMappingTemplate,
  OemRooftopLink,
  SalesTeamMaintenance,
} from '@quorum/models/xs-resource';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectAll as selectAssociateClassesCombined } from './associate-classes/associate-classes.interfaces';
import { selectAll as selectChannelsCombined } from './channels/channels.interfaces';
import { selectAll as selectCustomerTypesCombined } from './customer-types/customer-types.interfaces';
import * as fromDirectMessageTemplates from './direct-message-templates/direct-message-templates.actions';
// eslint-disable-next-line max-len
import { selectAll as selectDirectMessageTemplatesCombined } from './direct-message-templates/direct-message-templates.interfaces';
import { salespeopleSelector } from './dropdowns.interfaces';
import { selectAll as selectEmployeesCombined, serviceAdvisorsSelector } from './employees/employees.interfaces';
import * as fromHtmlMappingTemplates from './html-mapping-templates/html-mapping-templates.actions';
// eslint-disable-next-line max-len
import { selectAll as selectHtmlMappingTemplatesCombined } from './html-mapping-templates/html-mapping-templates.interfaces';
import { selectAll as selectMessageTypesCombined } from './message-types/message-types.interfaces';
import {
  selectAll as selectProspectActionsCombined,
  selectProspectClosedActions,
} from './prospect-actions/prospect-actions.interfaces';
import { rooftopsLoadingSelector, selectAll as selectRooftopsCombined } from './rooftops/rooftops.interfaces';
import { salesTeamsLoadingSelector, selectAll as selectSalesTeamsCombined } from './sales-teams/sales-teams.interfaces';
import { selectAll as selectSourcesCombined } from './sources/sources.interfaces';
import { selectAll as selectTaskOutcomesCombined } from './task-outcomes/task-outcomes.interfaces';
import {
  selectAll as selectVehicleCategoriesCombined,
  vehicleCategoriesLoadingSelector,
} from './vehicle-categories/vehicle-categories.interfaces';
import { GetVehicleColoursFromServer } from './vehicle-colours/vehicle-colours.actions';
import {
  selectAll as selectVehicleColoursCombined,
  vehicleColoursLoadingSelector,
} from './vehicle-colours/vehicle-colours.interfaces';
// eslint-disable-next-line max-len
import {
  selectAll as selectVehicleGenericModelsCombined,
  vehicleGenericModelsLoadingSelector,
} from './vehicle-generic-models/vehicle-generic-models.interfaces';
import {
  selectAll as selectVehicleMakesCombined,
  vehicleMakesLoadingSelector,
} from './vehicle-makes/vehicle-makes.interfaces';
import { GetVehicleStatusesFromServer } from './vehicle-statuses/vehicle-statuses.actions';
import {
  selectAll as selectVehicleStatusesCombined,
  vehicleStatusesLoadingSelector,
} from './vehicle-statuses/vehicle-statuses.interfaces';
import { GetVehicleSubcategoriesFromServer } from './vehicle-subcategories/vehicle-subcategories.actions';
// eslint-disable-next-line max-len
import {
  selectAll as selectVehicleSubcategoriesCombined,
  vehicleSubcategoriesLoadingSelector,
} from './vehicle-subcategories/vehicle-subcategories.interfaces';
import { GetVehicleSubTypesFromServer } from './vehicle-subtypes/vehicle-subtypes.actions';
import {
  selectAll as selectVehicleSubTypesCombined,
  vehicleSubTypesLoadingSelector,
} from './vehicle-subtypes/vehicle-subtypes.interfaces';
import { selectAll as selectVendorsCombined } from './vendors/vendors.interfaces';

@Injectable()
export class DropdownsStateService {
  constructor(private store: Store<any>) {}

  selectChannels() {
    return this.store.pipe(select(selectChannelsCombined));
  }

  selectCustomerTypes() {
    return this.store.pipe(select(selectCustomerTypesCombined));
  }

  selectMessageTypes() {
    return this.store.pipe(select(selectMessageTypesCombined));
  }

  selectSalespeople() {
    return this.store.pipe(select(salespeopleSelector));
  }

  selectSalesTeams(): Observable<SalesTeamMaintenance[]> {
    return this.store.pipe(select(selectSalesTeamsCombined));
  }

  selectSalesTeamsLoading(): Observable<boolean> {
    return this.store.pipe(select(salesTeamsLoadingSelector));
  }

  selectRooftops(): Observable<OemRooftopLink[]> {
    return combineLatest([this.store.pipe(select(selectRooftopsCombined)), this.selectRooftopsLoading()]).pipe(
      map(([rooftops, isLoading]) => {
        return { rooftops, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false && !this.isEmpty(result.rooftops);
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.rooftops);
      })
    );
  }

  selectRooftopsLoading() {
    return this.store.pipe(select(rooftopsLoadingSelector));
  }

  selectSources() {
    return this.store.pipe(select(selectSourcesCombined));
  }

  selectVehicleCategories() {
    return combineLatest(
      this.store.pipe(select(selectVehicleCategoriesCombined)),
      this.selectVehicleCategoriesLoading()
    ).pipe(
      map(([vehicleCategories, isLoading]) => {
        return { vehicleCategories, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleCategories);
      })
    );
  }

  selectVehicleCategoriesLoading() {
    return this.store.pipe(select(vehicleCategoriesLoadingSelector));
  }

  selectVehicleColours() {
    return combineLatest(
      this.store.pipe(select(selectVehicleColoursCombined)),
      this.selectVehicleColoursLoading()
    ).pipe(
      map(([vehicleColours, isLoading]) => {
        return { vehicleColours, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleColours);
      })
    );
  }

  selectVehicleColoursLoading() {
    return this.store.pipe(select(vehicleColoursLoadingSelector));
  }

  selectVehicleGenericModels() {
    return combineLatest(
      this.store.pipe(select(selectVehicleGenericModelsCombined)),
      this.selectVehicleGenericModelsLoading()
    ).pipe(
      map(([vehicleGenericModels, isLoading]) => {
        return { vehicleGenericModels, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleGenericModels);
      })
    );
  }

  selectVehicleGenericModelsLoading() {
    return this.store.pipe(select(vehicleGenericModelsLoadingSelector));
  }

  selectVehicleMakes() {
    return combineLatest(this.store.pipe(select(selectVehicleMakesCombined)), this.selectVehicleMakesLoading()).pipe(
      map(([vehicleMakes, isLoading]) => {
        return { vehicleMakes, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleMakes);
      })
    );
  }

  selectVehicleMakesLoading() {
    return this.store.pipe(select(vehicleMakesLoadingSelector));
  }

  selectVehicleStatuses() {
    return combineLatest(
      this.store.pipe(select(selectVehicleStatusesCombined)),
      this.selectVehicleStatusesLoading()
    ).pipe(
      map(([vehicleStatuses, isLoading]) => {
        return { vehicleStatuses, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleStatuses);
      })
    );
  }

  selectVehicleStatusesLoading() {
    return this.store.pipe(select(vehicleStatusesLoadingSelector));
  }

  selectVehicleSubcategories() {
    return combineLatest(
      this.store.pipe(select(selectVehicleSubcategoriesCombined)),
      this.selectVehicleSubcategoriesLoading()
    ).pipe(
      map(([vehicleSubcategories, isLoading]) => {
        return { vehicleSubcategories, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleSubcategories);
      })
    );
  }

  selectVehicleSubcategoriesLoading() {
    return this.store.pipe(select(vehicleSubcategoriesLoadingSelector));
  }

  selectVehicleSubTypes() {
    return combineLatest(
      this.store.pipe(select(selectVehicleSubTypesCombined)),
      this.selectVehicleSubTypesLoading()
    ).pipe(
      map(([vehicleSubTypes, isLoading]) => {
        return { vehicleSubTypes, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result.vehicleSubTypes);
      })
    );
  }

  selectVehicleSubTypesLoading() {
    return this.store.pipe(select(vehicleSubTypesLoadingSelector));
  }

  selectProspectActions() {
    return this.store.pipe(select(selectProspectActionsCombined));
  }

  selectProspectClosedActions() {
    return this.store.pipe(select(selectProspectClosedActions));
  }

  selectTaskOutcomes() {
    return this.store.pipe(select(selectTaskOutcomesCombined));
  }

  selectTextMessageTemplates() {
    this.getTextMessageTemplatesFromServer();
    return this.store.pipe(
      select(selectDirectMessageTemplatesCombined),
      filter((directMessageTemplates: DirectMessageTemplate[]) => {
        return !this.isEmpty(directMessageTemplates);
      })
    );
  }

  getTextMessageTemplatesFromServer() {
    return this.store.dispatch(new fromDirectMessageTemplates.GetDirectMessageTemplatesFromServer({}));
  }

  selectHtmlMappingTemplates() {
    this.getHtmlMappingTemplatesFromServer();
    return this.store.pipe(
      select(selectHtmlMappingTemplatesCombined),
      filter((htmlMappingTemplates: HtmlMappingTemplate[]) => {
        return !this.isEmpty(htmlMappingTemplates);
      })
    );
  }

  getHtmlMappingTemplatesFromServer() {
    return this.store.dispatch(new fromHtmlMappingTemplates.GetHtmlMappingTemplatesFromServer({}));
  }

  selectEmployees() {
    return this.store.pipe(select(selectEmployeesCombined));
  }

  selectServiceAdvisors() {
    return this.store.pipe(select(serviceAdvisorsSelector));
  }

  selectAssociateClasses() {
    return this.store.pipe(select(selectAssociateClassesCombined));
  }

  selectVendors() {
    return this.store.pipe(select(selectVendorsCombined));
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  getVehicleSubcategoriesFromServer() {
    this.store.dispatch(new GetVehicleSubcategoriesFromServer({}));
  }

  getVehicleColoursFromServer() {
    this.store.dispatch(new GetVehicleColoursFromServer({}));
  }

  getVehicleSubTypesFromServer() {
    this.store.dispatch(new GetVehicleSubTypesFromServer({}));
  }

  getVehicleStatusesFromServer() {
    this.store.dispatch(new GetVehicleStatusesFromServer({}));
  }
}
