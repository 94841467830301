import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';

export class CustomValidators extends Validators {
  static noWhitespaceValidator(control: FormControl) {
    if (control.value && control.value.length > 0) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    } else {
      return null;
    }
  }

  static dateRangeValidator(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let fromDateControl = group.controls[from];
      let toDateControl = group.controls[to];
      let fromDate = new Date(fromDateControl.value);
      let toDate = new Date(toDateControl.value);
      if (fromDateControl.valid && toDateControl.valid) {
        if (
          new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()) >
          new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate())
        ) {
          return {
            dates: 'From date cannot be later than To date.',
          };
        }
      }
      return {};
    };
  }

  static dropdownOptionSelectedValidator(dropdown: any[]) {
    return (control: AbstractControl): { [key: string]: any } => {
      if (dropdown?.indexOf(control.value) !== -1) {
        return null;
      }
      return { invalidOption: 'Please select an option' };
    };
  }
}
