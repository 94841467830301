import { Component, Input, OnInit } from '@angular/core';
import { Sale } from '@quorum/models/xs-resource';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from 'libs/api/src/lib/api.service';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { CrmSettingsState } from 'libs/crm-settings/src/lib/+state/settings.interfaces';

@Component({
  selector: 'crm-prospect-open-sales-list',
  templateUrl: './prospect-open-sales-list.component.html',
  styleUrls: ['./prospect-open-sales-list.component.css']
})
export class ProspectOpenSalesListComponent implements OnInit {
  @Input() openSales: Sale[];
  drilldownEnabled: boolean;
  settings: CrmSettingsState;
  constructor(
    public browserControlCommunicationService: BrowserControlCommunicationService,
    private apiService: ApiService,
    private settingsStateService: CrmSettingsStateService
  ) {}

  ngOnInit() {
    this.drilldownEnabled = this.browserControlCommunicationService.drilldownEnabled;
    this.settingsStateService.selectSettingsFromState().subscribe(settings => (this.settings = settings));
  }

  openSale(sale: Sale) {
    if (this.browserControlCommunicationService.drilldownEnabled) {
      let params: string[];
      if (sale.deskId && sale.statusId == 'Q') {
        params = ['existing deal', sale.id.toString()];
        this.browserControlCommunicationService.drilldown('salesPlanner', 'w_autovance_desk', params);
      } else {
        params = [sale.id.toString()];
        this.browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_sales_maintain', params);
      }
    } else {
      if (sale.deskId && sale.statusId == 'Q') {
        let payload: any = {
          autovanceUserGuid: this.settings.autovanceUserGuid,
          currentRooftopId: this.settings.rooftopId,
          vendorDealGuid: sale.deskId
        };

        var avWindow = window.open('', '_blank');
        avWindow.document.write('Redirecting to Autovance...');

        this.apiService
          .get<any>(`v/1/vehicles/sales/autovance`, {
            params: payload
          })
          .subscribe(
            sale => {
              avWindow.location.href = sale.url;
            },
            error => avWindow.close()
          );
      }
    }
  }

  openEdocs(saleId: number) {
    let params: string[] = [saleId.toString()];
    this.browserControlCommunicationService.drilldown(
      'salesPlanner',
      'w_document_transaction_list_vehicle_sale',
      params
    );
  }
}
