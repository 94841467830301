import { Associate } from '../associates/associate.model';
import { Prospect } from '../crm/prospect.model';
import { SaleStatus } from './sale-status.model';
import { Vehicle } from './vehicle.model';
import { VehicleInformation } from './vehicle-information.model';

export class Sale {
  businessManager2CommissionId: number = null;
  businessManager2CommissionPercent: number = null;
  businessManager2Id: string = null;
  businessManager3CommissionId: number = null;
  businessManager3CommissionPercent: number = null;
  businessManager3Id: string = null;
  businessManagerCommissionId: number = null;
  businessManagerCommissionPercent: number = null;
  businessManagerId: string = null;
  channelId: string = null;
  collisionDeductible: number = null;
  comprehensiveDeductible: number = null;
  contractDate: Date = null;
  coPurchaserAddress: string = null;
  coPurchaserCellPhoneNumber: string = null;
  coPurchaserCityId: string = null;
  coPurchaserCountryId: string = null;
  coPurchaserCountyId: number = null;
  coPurchaserDateOfBirth: Date = null;
  coPurchaserDriversLicenseExpiryDate: Date = null;
  coPurchaserDriversLicenseNumber: string = null;
  coPurchaserFirstName: string = null;
  coPurchaserHomePhoneNumber: string = null;
  coPurchaserLanguagePreferenceId: number = null;
  coPurchaserLastName: string = null;
  coPurchaserMainPhoneNumber: string = null;
  coPurchaserMaritalStatusId: string = null;
  coPurchaserMiddleName: string = null;
  coPurchaserPostalCode: string = null;
  coPurchaserProvinceStateId: string = null;
  coPurchaserSalutation: string = null;
  coPurchaserSin: number = null;
  countyTaxPercent: number = null;
  countyTaxUpfrontFlag: string = null;
  creditApplicationNumber: string = null;
  customerId: string = null;
  daysFirstPeriod: number = null;
  deferredInterestPaymentScenarioId: string = null;
  deferredInterestStartDate: Date = null;
  deskId?: string = null;
  deliveryDate: Date = null;
  deliveryFlag: string = null;
  driversLicenseExpiryDate: Date = null;
  driversLicenseNumber: string = null;
  embedded?: SaleEmbed = null;
  enteredDate: Date = null;
  extendedWarrantyTaxPercent: number = null;
  fiInsuranceCompanyId: string = null;
  financeFirstInterestPaymentPeriod: string = null;
  financePaymentStartDate: Date = null;
  fordCudbSent: boolean = null;
  gmDdsSent: boolean = null;
  gstHstTaxPercent: number = null;
  gstRegistrant: string = null;
  gstRegistration: string = null;
  id?: number = null;
  insuranceAgencyBrokerId: string = null;
  insuranceCertificateNumber: string = null;
  insuranceCompanyId: string = null;
  insurancePolicyExpiryDate: Date = null;
  insurancePolicyNumber: string = null;
  journalId: number = null;
  leaseContractId: string = null;
  leaseEndDate: Date = null;
  leaseInterestCalculationMethodId: number = null;
  leaseInterestCalculationMethod2Id: number = null;
  leaseInterestCalculationMethod3Id: number = null;
  leasePaymentDay: number = null;
  leasePropertyTaxFactor: number = null;
  leasePropertyTaxFlag: string = null;
  leasePropertyTaxMsrp: number = null;
  leaseTaxMethodId: string = null;
  lien1GstFactor: number = null;
  lien1PstFactor: number = null;
  lien2GstFactor: number = null;
  lien2PstFactor: number = null;
  localTaxPercent: number = null;
  localTaxUpfrontFlag: string = null;
  lockDeal: boolean = null;
  luxuryTaxFlag: boolean = null;
  maxTradeTaxCreditAmount: number = null;
  nissanLastModifiedDate: Date = null;
  nissanStatusId: number = null;
  nissanStatusModifiedDate: Date = null;
  other1TaxPercent: number = null;
  other1TaxUpfrontFlag: string = null;
  other2TaxPercent: number = null;
  other2TaxUpfrontFlag: string = null;
  otherPurchaserAddress: string = null;
  otherPurchaserCityId: string = null;
  otherPurchaserCountryId: string = null;
  otherPurchaserName: string = null;
  otherPurchaserPostalCode: string = null;
  otherPurchaserProvinceId: string = null;
  postedDate?: Date = null;
  processingFee: number = null;
  prepaidMileageRefundRate: number = null;
  primaryFinanceUseId: string = null;
  primaryLeaseUseId: string = null;
  pstOnGmpp: string = null;
  pstTaxPercent: number = null;
  purchaseOptionFee: number = null;
  residenceProvStateId: string = null;
  retentionProspectId: number = null;
  rooftopId: number = null;
  saleGstFactor: number = null;
  salePstFactor: number = null;
  salesManagerCommissionId: number = null;
  salesManagerId: string = null;
  salesperson2CommissionId: number = null;
  salesperson2CommissionPercent: number = null;
  salesperson2Id: string = null;
  salesperson3CommissionId: number = null;
  salesperson3CommissionPercent: number = null;
  salesperson3Id: string = null;
  salespersonCommissionId: number = null;
  salespersonCommissionPercent: number = null;
  salespersonId: string = null;
  scenarioUsed: number = null;
  securityDepositFlag: string = null;
  securityDepositFlag2: string = null;
  securityDepositFlag3: string = null;
  showroomProspectId: number = null;
  skipFirstLeasePayment: string = null;
  skipFirstLeasePayment2: string = null;
  skipFirstLeasePayment3: string = null;
  smartLease: string = null;
  sourceId: number = null;
  stateTaxPercent: number = null;
  stateTaxUpfrontFlag: string = null;
  statusId: string = null;
  subTypeId: string = null;
  taxMillRate: number = null;
  trade1GstFactor: number = null;
  trade1PstFactor: number = null;
  trade2GstFactor: number = null;
  trade2PstFactor: number = null;
  typeId: string = null;
  vehicleId: number = null;
  vehicleTurnInFee: number = null;
  vendorDealId: string = null;
  vendorId: number = null;
  vendorDealXml: string = null;

  constructor(obj?: Sale) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class ProspectSale {
  customerId: string = null;
  prospectId: number = null;
  rooftopId: number = null;
  openVsWindows: boolean = null;
  salespersonId: string = null;
  vehicleId: number = null;
  vehicleSearch: boolean = null;
  vehicleStatus: string = null;

  constructor(obj?: ProspectSale) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class AutovanceXml {
  address: string = null;
  cellPhone: string = null;
  city: string = null;
  country: string = null;
  county: string = null;
  customerId: string = null;
  emailAddress: string = null;
  firstName: string = null;
  homePhone: string = null;
  lastName: string = null;
  postal: string = null;
  province: string = null;
  salespersonName: string = null;
  stockNumber: string = null;
  taxExempt: number = null;
  taxProvinceId: string = null;
  tradeAcvAmount: number = null;
  tradeAmount: number = null;
  tradeColor: string = null;
  tradeLienAmount: number = null;
  tradeMake: string = null;
  tradeMileage: number = null;
  tradeModel: string = null;
  tradeOffLease: number = null;
  tradeTrim: string = null;
  tradeVin: string = null;
  tradeYear: number = null;
  vehicleApxCode: string = null;
  vehicleColor: string = null;
  vehicleCostAmount: number = null;
  vehicleFloorPlan: number = null;
  vehicleInternetPrice: number = null;
  vehicleMake: string = null;
  vehicleMileage: number = null;
  vehicleModel: string = null;
  vehicleModelCode: string = null;
  vehicleMsrp: number = null;
  vehicleNumber: number = null;
  vehiclePackageCode: string = null;
  vehicleRetailPrice: number = null;
  vehicleTrim: string = null;
  vehicleType: string = null;
  vehicleVin: string = null;
  vehicleYear: number = null;
  workPhone: string = null;
  vendorDealNumber: number = null;

  constructor(obj?: AutovanceXml) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class SaleEmbed {
  businessManagerAssociate?: Associate = null;
  customerAssociate?: Associate = null;
  retentionProspect?: Prospect = null;
  salesManagerAssociate?: Associate = null;
  salespersonAssociate?: Associate = null;
  showroomProspect?: Prospect = null;
  status?: SaleStatus = null;
  vehicle?: Vehicle = null;
  vehicleInformation?: VehicleInformation = null;
}
