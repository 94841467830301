<div>
  <div fxLayout="column">
    <div id="header" fxLayout="row" class="header-container" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div fxLayout="column">
          <span fxLayoutAlign="start center" class="mat-subheading-2">
            <mat-icon>event_note</mat-icon>Dealer Promotion {{ promo?.id }}
          </span>
          <span class="mat-caption" *ngIf="promo?.completedDate"
            >Completed {{ promo?.completedDate | moment: 'dddd, MMM
            Do, YYYY' }}</span
          >
        </div>
      </div>
    </div>
    <form fxLayout="row wrap" [formGroup]="promoCardForm">
      <mat-form-field fxFlex="50">
        <input
          matInput
          autocomplete="off"
          [matDatepicker]="picker"
          placeholder="Date"
          name="dueDate"
          formControlName="dueDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select
          placeholder="Outcome"
          name="outcome"
          *ngIf="taskOutcomes$ | async as taskOutcomes"
          formControlName="outcomeId"
        >
          <mat-option *ngFor="let taskOutcome of taskOutcomes" [value]="taskOutcome.id">
            {{ taskOutcome.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="50" fxFlex.xs="100">
        <textarea
          matInput
          placeholder="Objectives"
          name="note"
          formControlName="note"
          matTextareaAutosize
          matAutosizeMinRows="1"
          matAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
      <mat-form-field fxFlex="50" fxFlex.xs="100">
        <textarea
          matInput
          placeholder="Comments"
          name="comments"
          formControlName="comments"
          matTextareaAutosize
          matAutosizeMinRows="1"
          matAutosizeMaxRows="5"
        ></textarea>
      </mat-form-field>
    </form>
    <div fxLayout="row" class="button-container" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="updatePromo(promoCardForm.value)">Update</button>
    </div>
  </div>
</div>
