import { Action } from '@ngrx/store';
import { SystemControlValueQueryParameters } from '@quorum/models/xs-query';
import { SystemControlValue } from '@quorum/models/xs-resource';

export const GET_SYSTEM_CONTROL_VALUES_FROM_SERVER = '[SYSTEM] Get System Control Values From Server';
export class GetSystemControlValuesFromServer implements Action {
  readonly feature = 'system';
  readonly type = GET_SYSTEM_CONTROL_VALUES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SystemControlValueQueryParameters) {}
}

export const GET_SYSTEM_CONTROL_VALUES_FROM_SERVER_SUCCESS = '[SYSTEM] Get System Control Values From Server Success';
export class GetSystemControlValuesFromServerSuccess implements Action {
  readonly feature = 'system';
  readonly type = GET_SYSTEM_CONTROL_VALUES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: { systemControlValues: SystemControlValue[] }) {}
}

export const GET_SYSTEM_CONTROL_VALUES_FROM_SERVER_FAILURE = '[SYSTEM] Get System Control Values From Server Failure';
export class GetSystemControlValuesFromServerFailure implements Action {
  readonly feature = 'system';
  readonly type = GET_SYSTEM_CONTROL_VALUES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { systemControlValues: SystemControlValue[] }) {}
}
export type SystemControlAction =
  | GetSystemControlValuesFromServer
  | GetSystemControlValuesFromServerSuccess
  | GetSystemControlValuesFromServerFailure;
