import { Vehicle } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface VehiclesState extends EntityState<Vehicle> {}

export const vehiclesAdapter: EntityAdapter<Vehicle> = createEntityAdapter<Vehicle>({
  selectId: (vehicle: Vehicle) => vehicle.id
});

export const initialState: VehiclesState = vehiclesAdapter.getInitialState();
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const vehiclesSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.vehicles
);
export const vehicleAggregateSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.vehicleAggregate
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehiclesAdapter.getSelectors(vehiclesSelector);
