import { Action } from '@ngrx/store';
import { ProspectReportQueryParameters } from '@quorum/models/xs-query';

export const UPDATE_UI_SETTINGS_IN_STATE = '[CRM] Update UI Settings In State';
export class UpdateUISettingsInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_UI_SETTINGS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const TOGGLE_ACTIONS_PANEL_IN_STATE = '[CRM] Toggle Actions Panel in State';
export class ToggleActionsPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_ACTIONS_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_OPEN_ACTIVITES_PANEL_IN_STATE = '[CRM] Toggle Open Activities in State';
export class ToggleOpenActiviesPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_OPEN_ACTIVITES_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_CLOSED_ACTIVITES_PANEL_IN_STATE = '[CRM] Toggle Closed Activities in State';
export class ToggleClosedActiviesPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_CLOSED_ACTIVITES_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_PURCHASE_ORDER_DETAILS_PANEL_IN_STATE = '[CRM] Toggle Purchase Order Details in State';
export class TogglePurchaseOrderDetailsPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_PURCHASE_ORDER_DETAILS_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_RESPONSE_LEADS_PANEL_IN_STATE = '[CRM] Toggle Response Leads in State';
export class ToggleResponseLeadsPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_RESPONSE_LEADS_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_VEHICLE_OF_INTEREST_PANEL_IN_STATE = '[CRM] Toggle Vehicle of Interest in State';
export class ToggleVehicleOfInterestPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_VEHICLE_OF_INTEREST_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_OPEN_SALES_PANEL_IN_STATE = '[CRM] Toggle Open Sales in State';
export class ToggleOpenSalesPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_OPEN_SALES_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_NOTES_PANEL_IN_STATE = '[CRM] Toggle Notes in State';
export class ToggleNotesPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_NOTES_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_SERVICE_HISTORY_PANEL_IN_STATE = '[CRM] Toggle Service History in State';
export class ToggleServiceHistoryPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_SERVICE_HISTORY_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_VEHICLE_AGGREGATE_PANEL_IN_STATE = '[CRM] Toggle Vehicle Aggregate in State';
export class ToggleVehicleAggregatePanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_VEHICLE_AGGREGATE_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_PROSPECT_DETAILS_PANEL_IN_STATE = '[CRM] Toggle Prospect Details in State';
export class ToggleProspectDetailsPanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_PROSPECT_DETAILS_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_PROSPECT_AGGREGATE_PANEL_IN_STATE = '[CRM] Toggle Prospect Aggregate in State';
export class ToggleProspectAggregatePanelInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_PROSPECT_AGGREGATE_PANEL_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const TOGGLE_SIDENAV_IN_STATE = '[CRM] Toggle Sidenav in State';
export class ToggleSidenavInState implements Action {
  readonly feature = 'crm';
  readonly type = TOGGLE_SIDENAV_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const RESET_STATE = '[CRM] Reset UI State';
export class ResetState implements Action {
  readonly feature = 'crm';
  readonly type = RESET_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: boolean) {}
}

export const GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER = '[CRM] Get Unassigned Prospect Leads From Server';
export class GetUnassignedProspectLeadsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectReportQueryParameters) {}
}

export const GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER_SUCCESS =
  '[CRM] Get Unassigned Prospect Leads From Server Success';
export class GetUnassignedProspectLeadsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { unassigned: number; unmatched: number }) {}
}

export const GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER_FAILURE =
  '[CRM] Get Unassigned Prospect Leads From Server Failure';
export class GetUnassignedProspectLeadsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER = '[CRM] Get Unmatched Prospect Leads From Server';
export class GetUnmatchedProspectLeadsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectReportQueryParameters) {}
}

export const GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER_SUCCESS =
  '[CRM] Get Unmatched Prospect Leads From Server Success';
export class GetUnmatchedProspectLeadsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { unassigned: number; unmatched: number }) {}
}

export const GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER_FAILURE =
  '[CRM] Get Unmatched Prospect Leads From Server Failure';
export class GetUnmatchedProspectLeadsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type UISettingsAction =
  | UpdateUISettingsInState
  | ToggleActionsPanelInState
  | ToggleOpenActiviesPanelInState
  | ToggleClosedActiviesPanelInState
  | TogglePurchaseOrderDetailsPanelInState
  | ToggleResponseLeadsPanelInState
  | ToggleVehicleOfInterestPanelInState
  | ToggleOpenSalesPanelInState
  | ToggleNotesPanelInState
  | ToggleServiceHistoryPanelInState
  | ToggleVehicleAggregatePanelInState
  | ToggleProspectDetailsPanelInState
  | ToggleProspectAggregatePanelInState
  | ToggleSidenavInState
  | ResetState
  | GetUnassignedProspectLeadsFromServer
  | GetUnassignedProspectLeadsFromServerSuccess
  | GetUnassignedProspectLeadsFromServerFailure
  | GetUnmatchedProspectLeadsFromServer
  | GetUnmatchedProspectLeadsFromServerSuccess
  | GetUnmatchedProspectLeadsFromServerFailure;
