import { combineReducers } from '@ngrx/store';
import { reducer as associateClassesReducer } from './associate-classes/associate-classes.reducer';
import { reducer as channelsReducer } from './channels/channels.reducer';
import { reducer as customerTypesReducer } from './customer-types/customer-types.reducer';
import { reducer as directMessageTemplatesReducer } from './direct-message-templates/direct-message-templates.reducer';
import { dropdownsInitialState } from './dropdowns.init';
import { DropdownsState } from './dropdowns.interfaces';
import { reducer as employeesReducer } from './employees/employees.reducer';
import { reducer as htmlMappingTemplatesReducer } from './html-mapping-templates/html-mapping-templates.reducer';
import { reducer as messageTypesReducer } from './message-types/message-types.reducer';
import { reducer as prospectActionsReducer } from './prospect-actions/prospect-actions.reducer';
import { reducer as rooftopsReducer } from './rooftops/rooftops.reducer';
import { reducer as salesTeamsReducer } from './sales-teams/sales-teams.reducer';
import { reducer as salespeopleReducer } from './salespeople/salespeople.reducer';
import { reducer as sourcesReducer } from './sources/sources.reducer';
import { reducer as taskOutcomesReducer } from './task-outcomes/task-outcomes.reducer';
import { reducer as vehicleCategoriesReducer } from './vehicle-categories/vehicle-categories.reducer';
import { reducer as vehicleColoursReducer } from './vehicle-colours/vehicle-colours.reducer';
import { reducer as vehicleGenericModelsReducer } from './vehicle-generic-models/vehicle-generic-models.reducer';
import { reducer as vehicleMakesReducer } from './vehicle-makes/vehicle-makes.reducer';
import { reducer as vehicleStatusesReducer } from './vehicle-statuses/vehicle-statuses.reducer';
import { reducer as vehicleSubcategoriesReducer } from './vehicle-subcategories/vehicle-subcategories.reducer';
import { reducer as vehicleSubTypesReducer } from './vehicle-subtypes/vehicle-subtypes.reducer';
import { reducer as vendorsReducer } from './vendors/vendors.reducer';

export function dropdownsReducer(state: DropdownsState = dropdownsInitialState, action: any): DropdownsState {
  switch (action.type) {
    case 'DATA_LOADED': {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
}

const myReducers = {
  associateClasses: associateClassesReducer,
  channels: channelsReducer,
  customerTypes: customerTypesReducer,
  htmlMappingTemplates: htmlMappingTemplatesReducer,
  directMessageTemplates: directMessageTemplatesReducer,
  messageTypes: messageTypesReducer,
  prospectActions: prospectActionsReducer,
  rooftops: rooftopsReducer,
  salespeople: salespeopleReducer,
  salesTeams: salesTeamsReducer,
  sources: sourcesReducer,
  taskOutcomes: taskOutcomesReducer,
  vehicleCategories: vehicleCategoriesReducer,
  vehicleColours: vehicleColoursReducer,
  vehicleGenericModels: vehicleGenericModelsReducer,
  vehicleMakes: vehicleMakesReducer,
  vehicleStatuses: vehicleStatusesReducer,
  vehicleSubcategories: vehicleSubcategoriesReducer,
  vehicleSubTypes: vehicleSubTypesReducer,
  employees: employeesReducer,
  vendors: vendorsReducer,
};

export function createReducer(asyncReducers = {}) {
  let allReducers = Object.assign(myReducers, asyncReducers);
  return combineReducers(allReducers);
}

export function appReducer(state: any, action: any) {
  return createReducer()(state, action);
}

export const reducer = combineReducers(myReducers);

export function crmDropdownsReducer(state: any, action: any) {
  return reducer(state, action);
}
