import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { Action, ActionReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthenticationModule } from '@quorum/authentication';
import { environment } from '@quorum/environments';
import { ShaRouterModule } from '@quorum/sha-router';
import { XsrSalesplannerLegacyModule } from '@quorum/xsr-salesplanner-legacy';
import { XsrSystemControlModule } from '@quorum/xsr-system-control';
import { CrmDropdownsModule } from 'libs/crm-dropdowns/src/index.barrel';
import { CrmSettingsModule } from 'libs/crm-settings/src/lib/crm-settings.module';
import { AppEffects } from './app.effects';

export function freezer(reducer: ActionReducer<any>): ActionReducer<any> {
  const exclusions: RegExp[] = [/ROUTE/];

  return function(state: any, action: Action): any {
    const excluded = exclusions.reduce((current, next) => {
      const isExcluded = Boolean(action.type.match(next));
      return current || isExcluded;
    }, false);

    return reducer(state, action);
  };
}

@NgModule({
  imports: [
    EffectsModule.forRoot([AppEffects]),
    StoreModule.forRoot(
      { router: routerReducer },
      { runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false } }
    ),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 100 }) : [],
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    CommonModule,
    AuthenticationModule,
    ShaRouterModule,
    XsrSalesplannerLegacyModule,
    XsrSystemControlModule,
    CrmDropdownsModule,
    CrmSettingsModule
  ],
  providers: []
})
export class CrmAppStateModule {}
