import * as fromVehicleStatuses from './vehicle-statuses.actions';
import { initialState, vehicleStatusesAdapter, VehicleStatusesState } from './vehicle-statuses.interfaces';

export function reducer(
  state: VehicleStatusesState = initialState,
  action: fromVehicleStatuses.VehicleStatusesAction
): VehicleStatusesState {
  switch (action.type) {
    case fromVehicleStatuses.GET_VEHICLE_STATUSES_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleStatusesAdapter.addMany(action.payload.vehicleStatuses, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
