import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ConversationQueryParameters, LeadGeneratorVehicleSearchQueryParameters } from '@quorum/models/xs-query';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProspectCreateDialogComponent, ProspectCreateProgressDialogComponent } from '@quorum/crm-prospects-ng-ui';
import {
  Action,
  Associate,
  Available,
  Channel,
  Class,
  Contact,
  Employee,
  OemRooftopLink,
  Prospect,
  ProspectAction,
  ProvinceState,
  Source,
  LeadGeneratorVehicleSearch,
  Event,
  Conversation,
  Member,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { SelectedActivityStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { CommunicatorContactQueryParameters } from '@quorum/models/xs-query';
import { NewConversationDialogComponent } from '@quorum/communicator/ui/ng/conversations';
import { CommunicatorFacade } from '@quorum/communicator-ngrx';
import { EventTypes } from 'common/models/communicator/event-types.enum';
import { HttpHeaders } from '@angular/common/http';
@Component({
  selector: 'crm-vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss'],
})
export class VehiclesListComponent implements OnInit {
  queryParams: LeadGeneratorVehicleSearchQueryParameters;
  results: any = { data: [], headers: null };
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  //hasError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  countryCode: string;
  mileageLabel: string;
  showDeleteModal: boolean = false;
  deletedVehicleId: string = '';
  dropdowns$: Observable<any>;
  crmDropdowns$: Observable<any>;
  prospectDropdowns$: Observable<any>;
  createProspectProgressRef: MatDialogRef<any>;
  authenticatedEmployee: Employee;
  leadGeneratorActionId: number;
  postProspectProcessSubscription: Subscription;
  creatingProspects$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    private systemControlStateService: SystemControlStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public snackBar: MatSnackBar,
    public routerStateService: RouterStateService,
    public crmEntityStateService: CrmEntityStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    private communicatorFacade: CommunicatorFacade
  ) {}

  ngOnInit(): void {
    this.router.queryParams.pipe().subscribe((params) => {
      this.queryParams = { vehicleId: params.vehicleIds };
    });

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        take(1),
        tap((authenticaedEmployee) => {
          this.systemControlStateService
            .getSystemControlValues(['CL_DEALER_COUNTRY_ID'], authenticaedEmployee.rooftopId)
            .pipe(take(1))
            .subscribe((sct) => {
              this.mileageLabel = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'mi.' : 'km';
              this.countryCode = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'U' : 'C';
            });
        })
      )
      .subscribe((authenticatedEmployee) => {
        //this.queryParams.salespersonId = [...authenticatedEmployee.associateId];
        this.authenticatedEmployee = authenticatedEmployee;

        this.apiService
          .getWithHeaders<LeadGeneratorVehicleSearch[]>(`v/1/reporting/crm/vehicles`, { params: this.queryParams })
          .subscribe((response) => {
            this.isLoading$.next(false);
            this.results = { data: response.body, headers: response.headers };
          });
      });

    this.dropdowns$ = forkJoin(
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
        take(1)
      ),
      this.dropdownsStateService.selectSalespeople().pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        take(1)
      )
    ).pipe(
      map(([rooftops, salespeople]) => {
        return { rooftops, salespeople };
      })
    );

    this.prospectDropdowns$ = combineLatest(
      this.dropdownsStateService
        .selectChannels()
        .pipe(filter((channels) => !this.dropdownsStateService.isEmpty(channels))),
      this.dropdownsStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes))),
      this.dropdownsStateService
        .selectMessageTypes()
        .pipe(filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes))),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops) => rooftops.filter((rooftop: any) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService
        .selectSources()
        .pipe(filter((sources) => !this.dropdownsStateService.isEmpty(sources))),
      this.dropdownsStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownsStateService.isEmpty(associateClasses))),
      this.dropdownsStateService
        .selectProspectActions()
        .pipe(filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions))),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(
        ([
          channels,
          customerTypes,
          messageTypes,
          rooftops,
          salespeople,
          sources,
          associateClasses,
          prospectActions,
          provStates,
        ]) => {
          return {
            channels,
            customerTypes,
            messageTypes,
            rooftops,
            salespeople,
            sources,
            associateClasses,
            prospectActions,
            provStates,
          };
        }
      )
    );

    this.crmDropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleSubcategories().pipe(take(1)),
      this.dropdownsStateService.selectVehicleSubTypes().pipe(take(1)),
      this.dropdownsStateService.selectVehicleStatuses().pipe(take(1)),
      this.dropdownsStateService.selectVehicleColours().pipe(take(1)),
      this.dropdownsStateService.selectRooftops().pipe(take(1))
    ).pipe(
      map(([vehicleSubcategories, vehicleSubTypes, vehicleStatuses, vehicleColours, rooftops]) => {
        return {
          vehicleSubcategories,
          vehicleSubTypes,
          vehicleStatuses,
          vehicleColours,
          rooftops,
        };
      })
    );

    this.dropdownsStateService
      .selectProspectActions()
      .pipe(
        filter((prospectActions) => prospectActions != null && prospectActions.length > 0),
        take(1)
      )
      .subscribe((prospectActions) => {
        this.leadGeneratorActionId = prospectActions.find((ac: Action) => ac.description === 'Lead Generator').id;
        this.postProspectProcessSubscription = this.crmEntityStateService
          .postProspectCreationProcess(this.leadGeneratorActionId)
          .subscribe((prospect: Prospect) => {
            this.createProspectProgressRef.close();
            let snackBarRef = this.snackBar.open(`Prospect ${prospect.id} created.`, 'OPEN', {
              duration: 4000,
              horizontalPosition: 'start',
            });
            snackBarRef
              .onAction()
              .pipe(take(1))
              .subscribe((action) => {
                this.routerStateService.go([`sales-planner/prospects/${prospect.id}`], {
                  relativeTo: this.router.parent.parent,
                });
              });
          });
      });
  }

  ngOnDestroy() {
    this.postProspectProcessSubscription.unsubscribe();
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['..', 'crm', 'sales-planner', 'prospects', prospectId], {
      relativeTo: this.router.parent.parent,
    });
  }

  openCreateProspectDialog(reportType: string, reportItem: any, authenticatedEmployee: Employee): void {
    combineLatest(
      this.prospectDropdowns$.pipe(take(1)),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1))
    )
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe((combinedData) => {
        let leadGeneratorChannel = combinedData.dropdowns.channels.find(
          (channel: Channel) => channel.description == 'Lead Generator'
        );
        let reportTypeSource = combinedData.dropdowns.sources.find(
          (source: Source) => source.description.toLowerCase() == reportType
        );

        let leadGeneratorActionId = combinedData.dropdowns.prospectActions.find(
          (ac: Action) => ac.description === 'Lead Generator'
        ).id;

        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (combinedData.sct.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef = this.dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: combinedData.dropdowns,
            newProspect: {
              ...new Prospect(),
              actionId: leadGeneratorActionId,
              actionedVehicleId: reportItem.vehicleId,
              associateId: reportItem.associateId,
              channelId: leadGeneratorChannel.id,
              customerTypeId: '1',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: authenticatedEmployee.associateId,
              rooftopId: authenticatedEmployee.rooftopId,
              sourceId: reportTypeSource.id,
              statusId: '1',
              typeId: 3,
              vehicleId: reportItem.vehicleId,
            },
            newAssociate: {
              ...new Associate(),
              id: reportItem.associateId,
              firstName: reportItem.customerFirstName,
              lastName: reportItem.customerLastName,
            },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields: {
              associate: ['name'],
              prospect: ['channelId', 'sourceId', 'actionId'],
              contact: [],
            },
          },
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = this.dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
            reportItem.actioned = true;
          }
        });
      });
  }

  batchCreateProspects(reportType: string, reportItems: any, authenticatedEmployee: Employee) {
    this.creatingProspects$.next(true);
    combineLatest(
      this.prospectDropdowns$.pipe(take(1)),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1))
    )
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe(async (combinedData) => {
        let leadGeneratorChannel = combinedData.dropdowns.channels.find(
          (channel: Channel) => channel.description == 'Lead Generator'
        );
        let reportTypeSource = combinedData.dropdowns.sources.find(
          (source: Source) => source.description.toLowerCase() == reportType
        );

        let leadGeneratorActionId = combinedData.dropdowns.prospectActions.find(
          (ac: Action) => ac.description === 'Lead Generator'
        ).id;

        const createProspect = async (newProspect: any, index: number) => {
          await this.apiService
            .post<Prospect>(`v/1/crm/prospects`, newProspect)
            .pipe(
              tap((prospect) => {
                let newProspectAction: ProspectAction = {
                  ...new ProspectAction(),
                  appointmentDateTime: new Date(),
                  enteredDateTime: new Date(),
                  taskActionId: leadGeneratorActionId,
                  associateEmployeeId: authenticatedEmployee.associateId,
                  prospectId: prospect.id,
                };
                this.selectedActivityStateService.createSelectedProspectAction(newProspectAction, prospect);
                reportItems[index].prospectId = newProspectAction.prospectId;
              })
            )
            .toPromise();
        };

        for (let index = 0; index < reportItems.length; index++) {
          if (!reportItems[index].actioned) {
            let newProspect: Prospect = {
              ...new Prospect(),
              actionId: leadGeneratorActionId,
              actionedVehicleId: reportItems[index].vehicleId,
              associateId: reportItems[index].associateId,
              channelId: leadGeneratorChannel.id,
              customerTypeId: '1',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: reportItems[index].salespersonId,
              rooftopId: authenticatedEmployee.rooftopId,
              sourceId: reportTypeSource.id,
              statusId: '1',
              typeId: 3,
              vehicleId: reportItems[index].vehicleId,
            };
            await createProspect(newProspect, index);
            reportItems[index].actioned = true;
          }
        }
        this.creatingProspects$.next(false);
      });
  }

  deleteItem(vehicleId: string) {
    let vehicleIds = this.queryParams.vehicleId.split(',');
    const index = vehicleIds.indexOf(vehicleId.toString());
    const id = this.results.data.indexOf(this.results.data.find((item: any) => item.vehicleId === vehicleId));

    this.results.data.splice(id, 1);
    if (index >= 0) vehicleIds.splice(index, 1);

    this.route.navigate([], {
      relativeTo: this.router,
      queryParams: { vehicleIds: vehicleIds.join(',') },
      queryParamsHandling: 'merge',
    });
  }

  sendMessage() {
    let message = location.href;
    this.authenticationStateService
      .selectAuthenticatedUser()
      .pipe(take(1))
      .subscribe((authUser) => {
        let queryParams = new CommunicatorContactQueryParameters({
          storeId: authUser.user.storeId,
          employeeId: authUser.user.xselleratorEmployeeId,
          embed: 'Store,Employee',
          isActive: true,
          isDealershipUser: true,
        });
        this.apiService.get<Contact[]>('v/1/quorum/contacts', { params: queryParams }).subscribe((results) => {
          let dialogRef = this.dialog.open(NewConversationDialogComponent, {
            data: { currentUser: authUser, contacts: results, message: message },
            width: '800px',
            height: '400px',
          });

          dialogRef.afterClosed().subscribe((dialog: any) => {
            let event: Event = new Event({
              conversationId: null,
              memberId: authUser.user.id.toString(),
              eventTypeId: EventTypes.Message,
              content: dialog.message,
              guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
              sentDate: new Date(),
              sent: false,
              sendToCustomer: false,
              id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
            });

            let conversation: Conversation = new Conversation({
              createdBy: authUser.user.id,
            });

            if (dialog.sendAsGroupEnabled) {
              let members: Member[] = [];
              members.push(
                new Member({
                  userId: authUser.user.id.toString(),
                  avatarUrl: authUser.user.avatarUrl,
                  firstName: authUser.user.firstName,
                  lastName: authUser.user.lastName,
                  nickname: authUser.user.firstName,
                  isEmployee: true,
                  isRead: true,
                  isArchived: false,
                  isExcluded: false,
                  isPinnedToUi: false,
                })
              );
              let userIds: string[] = [];
              dialog.contacts.forEach((contact: any) => {
                members.push(
                  new Member({
                    userId: contact.id,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    nickname: contact.nickName,
                    isEmployee: true,
                    isRead: false,
                    isArchived: false,
                    isExcluded: false,
                    isPinnedToUi: false,
                  })
                );

                userIds.push(contact.id);
              });

              let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                messageTypeId: 0,
                employeeId: userIds.join(','),
              });

              this.communicatorFacade.sendCommunicatorMessage(
                conversationQueryParameters,
                conversation,
                members,
                event,
                new HttpHeaders()
              );
            } else {
              dialog.contacts.forEach((contact: any) => {
                let members: Member[] = [];
                members.push(
                  new Member({
                    userId: authUser.user.id.toString(),
                    avatarUrl: authUser.user.avatarUrl,
                    firstName: authUser.user.firstName,
                    lastName: authUser.user.lastName,
                    nickname: authUser.user.firstName,
                    isEmployee: true,
                    isRead: true,
                    isArchived: false,
                    isExcluded: false,
                    isPinnedToUi: false,
                  })
                );

                let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                  messageTypeId: 0,
                  employeeId: contact.id,
                });

                members.push(
                  new Member({
                    userId: contact.id,
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    nickname: contact.nickName,
                    isEmployee: true,
                    isRead: false,
                    isArchived: false,
                    isExcluded: false,
                    isPinnedToUi: false,
                  })
                );

                this.communicatorFacade.sendCommunicatorMessage(
                  conversationQueryParameters,
                  conversation,
                  members,
                  event,
                  new HttpHeaders()
                );
              });
            }
          });
        });
      });
  }
}
