import * as fromSalespeople from './salespeople.actions';

export function reducer(state: any = {}, action: fromSalespeople.SalespeopleAction): any {
  switch (action.type) {
    case fromSalespeople.GET_SALESPEOPLE_FROM_SERVER_SUCCESS: {
      return { ...state, ...action.payload.salespeople };
    }

    default: {
      return state;
    }
  }
}
