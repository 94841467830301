import { Action } from '@ngrx/store';
import { NoteQueryParameters } from '@quorum/models/xs-query';
import { Note } from '@quorum/models/xs-resource';

export const GET_SELECTED_PROSPECT_NOTES_FROM_SERVER = '[CRM] Get Selected Prospect Notes From Server';
export class GetSelectedProspectNotesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_NOTES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: NoteQueryParameters) {}
}

export const GET_SELECTED_PROSPECT_NOTES_FROM_SERVER_SUCCESS = '[CRM] Get Selected Prospect Notes From Server Success';
export class GetSelectedProspectNotesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_NOTES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_SELECTED_PROSPECT_NOTES_FROM_SERVER_FAILURE = '[CRM] Get Selected Prospect Notes From Server Failure';
export class GetSelectedProspectNotesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_NOTES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER = '[CRM] Create Selected Prospect Note On Server';
export class CreateSelectedProspectNoteOnServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: Note) {}
}

export const CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER_SUCCESS = '[CRM] Create Selected Prospect Note On Server Success';
export class CreateSelectedProspectNoteOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Note) {}
}

export const CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER_FAILURE = '[CRM] Create Selected Prospect Note On Server Failure';
export class CreateSelectedProspectNoteOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER = '[CRM] Update Selected Prospect Note On Server';
export class UpdateSelectedProspectNoteOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: Note) {}
}

export const UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER_SUCCESS = '[CRM] Update Selected Prospect Note On Server Success';
export class UpdateSelectedProspectNoteOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Note) {}
}

export const UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER_FAILURE = '[CRM] Update Selected Prospect Note On Server Failure';
export class UpdateSelectedProspectNoteOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectNotesAction =
  | GetSelectedProspectNotesFromServer
  | GetSelectedProspectNotesFromServerSuccess
  | GetSelectedProspectNotesFromServerFailure
  | CreateSelectedProspectNoteOnServer
  | CreateSelectedProspectNoteOnServerSuccess
  | CreateSelectedProspectNoteOnServerFailure
  | UpdateSelectedProspectNoteOnServer
  | UpdateSelectedProspectNoteOnServerSuccess
  | UpdateSelectedProspectNoteOnServerFailure;
