<div class="card-header-container" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center" class="title-container">
    <div class="header-icon" [ngClass]="prospect.prospectTypeId == '3' ? 'showroom' : 'retention'" fxLayout="row"
      fxLayoutAlign="center center">
      <mat-icon>person</mat-icon>
    </div>
    <div fxLayout="column">
      <span class="title mat-body-2" *ngIf="prospect.prospectFirstName; else othersFreeformName">{{[prospect.prospectLastName,
                          prospect?.prospectFirstName] | nameSafety}}</span>
      <ng-template #othersFreeformName>
        <span class="title mat-body-2">{{prospect.prospectFreeformName}}</span>
      </ng-template>
      <span class="mat-body-2 secondary-text">
        {{prospect.salespersonFirstName && prospect.salespersonLastName ? prospect.salespersonFirstName + ' ' + prospect.salespersonLastName : prospect.salespersonFirstName}}</span>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="card-content-container" fxLayout="column">
  <div fxLayout="column">
    <ng-container *ngIf="prospect.prospectTypeId == '3';else retentionDates">
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">event</mat-icon>
        <span class="secondary-text"> Updated on
          {{prospect.prospectUpdatedDate | moment: 'dddd, MMM Do, YYYY'}}</span>
      </div>
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">event</mat-icon>
        <span class="secondary-text"> Entered on
          {{prospect.prospectEnteredDate | moment: 'dddd, MMM Do, YYYY'}}</span>
      </div>
    </ng-container>
    <ng-template #retentionDates>
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">event</mat-icon>
        <span class="secondary-text"> Available on
          {{prospect.prospectAvailableDate | moment: 'dddd, MMM Do, YYYY'}}</span>
      </div>
      <div fxLayoutAlign="start center" class="card-line">
        <mat-icon class="card-icon secondary-text">event</mat-icon>
        <span class="secondary-text"> Sold on
          {{prospect.contractDate | moment: 'dddd, MMM Do, YYYY'}}
          (ID: {{prospect.saleId}})</span>
      </div>
    </ng-template>
    <div fxLayoutAlign="start center" class="card-line">
      <mat-icon class="card-icon secondary-text">location_on</mat-icon>
      <span
        class="secondary-text">{{prospect.addressLine1 && prospect.locationName && prospect.provStateId ? prospect.addressLine1 + ', ' + prospect.locationName + ', ' + prospect.provStateId : 'No address provided'}}</span>
    </div>
    <div fxLayoutAlign="start center" class="card-line">
      <mat-icon class="card-icon secondary-text">email</mat-icon>
      <span
        class="secondary-text">{{prospect.prospectEmailAddress ? prospect.prospectEmailAddress : 'No email provided'}}</span>
    </div>
    <div fxLayoutAlign="start center" class="card-line">
      <mat-icon class="card-icon secondary-text">phone</mat-icon>
      <span class="secondary-text" *ngIf="prospect.associateCellPhoneNumber; else noCellPhone">
        C: {{prospect.associateCellPhoneNumber| phoneNumber}}
      </span>
      <ng-template #noCellPhone>
        <span class="secondary-text">No cell phone provided</span>
      </ng-template>
    </div>
    <div fxLayoutAlign="start center" class="card-line">
      <mat-icon class="card-icon secondary-text">phone</mat-icon>
      <span class="secondary-text" *ngIf="prospect.associateHomePhoneNumber; else noHomePhone">
        M: {{prospect.associateHomePhoneNumber | phoneNumber}}
      </span>
      <ng-template #noHomePhone>
        <span class="secondary-text">No main phone provided</span>
      </ng-template>
    </div>
  </div>
</div>