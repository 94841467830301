import { VehicleCategory } from '@quorum/models/xs-resource';
import { VehicleCategoryQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_CATEGORIES_FROM_SERVER = '[CRM] Get Vehicle Categories From Server';
export class GetVehicleCategoriesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_CATEGORIES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleCategoryQueryParameters) {}
}

export const GET_VEHICLE_CATEGORIES_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Categories From Server Success';
export class GetVehicleCategoriesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_CATEGORIES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleCategories: VehicleCategory[] }) {}
}

export const GET_VEHICLE_CATEGORIES_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Categories From Server Failure';
export class GetVehicleCategoriesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_CATEGORIES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ChannelsAction =
  | GetVehicleCategoriesFromServer
  | GetVehicleCategoriesFromServerSuccess
  | GetVehicleCategoriesFromServerFailure;
