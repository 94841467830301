import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ReportingVehicleSearchQueryParameters } from '@quorum/models/xs-query';
import { Subject } from 'rxjs';
import * as fromVehicleSearch from './vehicle-search.actions';
import {
  selectAll as selectVehicleSearchResultsCombined,
  vehicleSearchResultsIsLoadingSelector,
} from './vehicle-search.interfaces';

@Injectable()
export class VehicleSearchStateService {
  private toastSubject = new Subject<any>();
  constructor(private store: Store<any>) {}

  getVehicleSearchResults(queryParams: ReportingVehicleSearchQueryParameters) {
    this.store.dispatch(new fromVehicleSearch.GetVehicleSearchResultsFromServer(queryParams));
  }

  selectVehicleSearchResults() {
    return this.store.pipe(select(selectVehicleSearchResultsCombined));
  }

  clearVehicleSearchResultsFromState() {
    this.store.dispatch(new fromVehicleSearch.ClearVehicleSearchResultsFromState());
  }

  selectIsLoading() {
    return this.store.pipe(select(vehicleSearchResultsIsLoadingSelector));
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  addToast(toastObject: any) {
    this.toastSubject.next(toastObject);
  }

  listenForToasts() {
    return this.toastSubject;
  }
}
