import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ResponseLead } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-prospect-response-leads',
  templateUrl: './prospect-response-leads.component.html',
  styleUrls: ['./prospect-response-leads.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectResponseLeadsComponent implements OnInit {
  @Input() responseLeads: ResponseLead[];
  constructor(public browserControlCommunicationService: BrowserControlCommunicationService) {}

  ngOnInit() {}

  openLead(lead: ResponseLead) {
    var avWindow = window.open('', '_blank');
    avWindow.document.write('Opening Lead...');
    avWindow.location.href = lead.url;
  }
}
