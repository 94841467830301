import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Employee } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface EmployeesState extends EntityState<Employee> {}

export const employeesAdapter: EntityAdapter<Employee> = createEntityAdapter<Employee>({
  selectId: (employee: Employee) => employee.associateId,
});

export const initialState: EmployeesState = employeesAdapter.getInitialState({ loading: true });

export const employees = createSelector(crmDropdownsFeatureSelector, (state: any) => state.employees);

const employeesSelector = employeesAdapter.getSelectors(employees);

export const serviceAdvisorsSelector = createSelector(employeesSelector.selectAll, (state: Employee[]) => {
  return state.filter((emp) => {
    return emp.jobTitleId == 'SAD';
  });
});

export const { selectIds, selectEntities, selectAll, selectTotal } = employeesAdapter.getSelectors(employees);
