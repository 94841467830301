import * as fromVendors from './vendors.actions';
import { initialState, vendorsAdapter, VendorsState } from './vendors.interfaces';

export function reducer(state: VendorsState = initialState, action: fromVendors.VendorsAction): VendorsState {
  switch (action.type) {
    case fromVendors.GET_VENDORS_FROM_SERVER_SUCCESS: {
      return {
        ...vendorsAdapter.addMany(action.payload.vendors, state),
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
