import { Action } from '@ngrx/store';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { Prospect, Task } from '@quorum/models/xs-resource';

export const GET_SELECTED_ACTIVITY_FROM_SERVER = '[CRM] Get Selected Activity From Server';
export class GetSelectedActivityFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_ACTIVITY_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { id: number; activityType: string; embed?: string; sequence?: number }) {}
}

export const GET_SELECTED_ACTIVITY_FROM_SERVER_SUCCESS = '[CRM] Get Selected Activity From Server Success';
export class GetSelectedActivityFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_ACTIVITY_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_SELECTED_ACTIVITY_FROM_SERVER_FAILURE = '[CRM] Get Selected Activity From Server Failure';
export class GetSelectedActivityFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_ACTIVITY_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_SELECTED_PROSPECT_FROM_SERVER = '[CRM] Get Selected Prospect From Server';
export class GetSelectedProspectFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectQueryParameters) {}
}

export const GET_SELECTED_PROSPECT_FROM_SERVER_SUCCESS = '[CRM] Get Selected Prospect From Server Success';
export class GetSelectedProspectFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: Prospect) {}
}

export const GET_SELECTED_PROSPECT_FROM_SERVER_FAILURE = '[CRM] Get Selected Prospect From Server Failure';
export class GetSelectedProspectFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_SELECTED_PROSPECT_IN_STATE = '[CRM] Update Selected Prospect in State';
export class UpdateSelectedProspectInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER = '[CRM] Get Selected Prospect Aggregate From Server';
export class GetSelectedProspectAggregateFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospectId: string }) {}
}

export const GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Aggregate From Server Success';
export class GetSelectedProspectAggregateFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Aggregate From Server Failure';
export class GetSelectedProspectAggregateFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_SELECTED_PROSPECT_VEHICLE_AGGREGATE_FROM_SERVER =
  '[CRM] Get Selected Prospect Vehicle Aggregate From Server';
export class GetSelectedProspectVehicleAggregateFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLE_AGGREGATE_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { associate: string }) {}
}

export const GET_SELECTED_PROSPECT_VEHICLE_AGGREGATE_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Vehicle Aggregate From Server Success';
export class GetSelectedProspectVehicleAggregateFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLE_AGGREGATE_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_SELECTED_PROSPECT_VEHICLE_AGGREGATE_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Vehicle Aggregate From Server Failure';
export class GetSelectedProspectVehicleAggregateFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLE_AGGREGATE_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const RESET_SELECTED_ACTIVITY_STATE = '[CRM] Reset Selected Activity State';
export class ResetSelectedActivityState implements Action {
  readonly feature = 'crm';
  readonly type = RESET_SELECTED_ACTIVITY_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: {}) {}
}

export const REASSIGN_SELECTED_PROSPECT_ACTIVITIES = '[CRM] Reassign Selected Prospect Activities';
export class ReassignSelectedProspectActivities implements Action {
  readonly feature = 'crm';
  readonly type = REASSIGN_SELECTED_PROSPECT_ACTIVITIES;
  readonly triggerStorageSync = false;
  constructor(public payload: { oldProspect: Prospect; prospect: Prospect }) {}
}

export const REASSIGN_SELECTED_PROSPECT_ACTIVITIES_SUCCESS = '[CRM] Reassign Selected Prospect Activities Success';
export class ReassignSelectedProspectActivitiesSuccess implements Action {
  readonly feature = 'crm';
  readonly type = REASSIGN_SELECTED_PROSPECT_ACTIVITIES_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const UPDATE_SELETED_PROSPECT_SALES_APPOINTMENT_IN_STATE =
  '[CRM] Update Selected Prospect Sales Appointment In State';
export class UpdateSelectedProspectSalesAppointmentInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELETED_PROSPECT_SALES_APPOINTMENT_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const UPDATE_SELECTED_ACTIVITY_IN_STATE = '[CRM] Update Selected Activity in State';
export class UpdateSelectedActivityInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_ACTIVITY_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const CLEAR_SELECTED_PROSPECT_FROM_STATE = '[CRM] Clear Selected Prospect From State';
export class ClearSelectedProspectFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_SELECTED_PROSPECT_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export const CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER =
  '[CRM] Create Tasks Mapped to Prospect Action On Server';
export class CreateTasksMappedToAction implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { queryParams: any }) {}
}

export const CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER_SUCCESS =
  '[CRM] Create Tasks Mapped to Prospect Action On Server Success';
export class CreateTasksMappedToActionSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: { tasks: Task[] }) {}
}

export const CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER_FAILURE =
  '[CRM] Create Tasks Mapped to Prospect Action On Server Failure';
export class CreateTasksMappedToActionFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type SelectedActivityAction =
  | GetSelectedProspectFromServer
  | GetSelectedProspectFromServerSuccess
  | GetSelectedProspectFromServerFailure
  | GetSelectedProspectAggregateFromServer
  | GetSelectedProspectAggregateFromServerSuccess
  | GetSelectedProspectAggregateFromServerFailure
  | GetSelectedProspectVehicleAggregateFromServer
  | GetSelectedProspectVehicleAggregateFromServerSuccess
  | GetSelectedProspectVehicleAggregateFromServerFailure
  | GetSelectedActivityFromServer
  | GetSelectedActivityFromServerSuccess
  | GetSelectedActivityFromServerFailure
  | ResetSelectedActivityState
  | UpdateSelectedProspectInState
  | ReassignSelectedProspectActivities
  | ReassignSelectedProspectActivitiesSuccess
  | UpdateSelectedProspectSalesAppointmentInState
  | UpdateSelectedActivityInState
  | ClearSelectedProspectFromState
  | CreateTasksMappedToAction
  | CreateTasksMappedToActionSuccess
  | CreateTasksMappedToActionFailure;
