import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { CrmProspectsNgUiModule, ProspectCreateDialogComponent } from '@quorum/crm-prospects-ng-ui';
import { TextMaskModule } from 'angular2-text-mask';
import { AddDeductDialogComponent } from './add-deduct-dialog/add-deduct-dialog.component';
import { AvailableTabComponent } from './available-tab/available-tab.component';
import { InEquityTabComponent } from './in-equity-tab/in-equity-tab.component';
import { OverMileageLeaseTabComponent } from './over-mileage-tab/over-mileage-tab.component';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { ShellComponent } from './shell/shell.component';
import { SubPrimeTabComponent } from './sub-prime-tab/sub-prime-tab.component';
import { UnsoldVehiclesSearchTabComponent } from './unsold-vehicles-search-tab/unsold-vehicles-search-tab.component';
import { ServiceAppointmentsTabComponent } from './service-appointments-tab/service-appointments-tab.component';
import { VehiclesSearchTabComponent } from './vehicles-search-tab/vehicles-search-tab.component';
import { CustomersTabComponent } from './customers-tab/customers-tab.component';
import { AvailableListComponent } from './available-list/available-list.component';
import { SubPrimeListComponent } from './sub-prime-list/sub-prime-list.component';
import { VehiclesListComponent } from './vehicles-list/vehicles-list.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { OverMileageListComponent } from './over-mileage-list/over-mileage-list.component';
import { ServiceAppointmentsListComponent } from './service-appointments-list/service-appointments-list.component';
import { ListShellComponent } from './list-shell/list-shell.component';

@NgModule({
    imports: [
        CommonModule,
        CommonPipesModule,
        CommonMaterialComponentsModule,
        CrmProspectsNgUiModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TextMaskModule,
    ],
    exports: [ShellComponent, AvailableTabComponent, AddDeductDialogComponent],
    declarations: [
        ShellComponent,
        AvailableTabComponent,
        InEquityTabComponent,
        SubPrimeTabComponent,
        OverMileageLeaseTabComponent,
        AddDeductDialogComponent,
        SettingsDialogComponent,
        UnsoldVehiclesSearchTabComponent,
        ServiceAppointmentsTabComponent,
        VehiclesSearchTabComponent,
        CustomersTabComponent,
        AvailableListComponent,
        SubPrimeListComponent,
        VehiclesListComponent,
        CustomersListComponent,
        OverMileageListComponent,
        ServiceAppointmentsListComponent,
        ListShellComponent,
    ]
})
export class CrmEquityMiningNgUiModule {}
