import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProspectsEffects } from '@quorum/xsr-entities/state';
import { ActivitiesStateService } from './+state/activities/activities-state.services';
import { DealerPromotionsStateService } from './+state/activities/dealer-promotions/dealer-promotions-state.service';
import { DealerPromotionsEffects } from './+state/activities/dealer-promotions/dealer-promotions.effects';
import { DeliveriesStateService } from './+state/activities/deliveries/deliveries-state.service';
import { DeliveriesEffects } from './+state/activities/deliveries/deliveries.effects';
import { SalesAppointmentsStateService } from './+state/activities/sales-appointments/sales-appointments-state.service';
import { SalesAppointmentsEffects } from './+state/activities/sales-appointments/sales-appointments.effects';
// eslint-disable-next-line max-len
import { ServiceAppointmentsStateService } from './+state/activities/service-appointments/service-appointments-state.service';
import { ServiceAppointmentsEffects } from './+state/activities/service-appointments/service-appointments.effects';
import { TasksStateService } from './+state/activities/tasks/tasks-state.service';
import { TasksEffects } from './+state/activities/tasks/tasks.effects';
import { LocalSettingsStateService } from './+state/local-settings/local-settings.state.service';
import { SalesPlannerProspectsEffects } from './+state/prospects/prospects.effects';
import { SalesPlannerStateService } from './+state/sales-planner.state.service';
import { SalesplannerLegacyEffects } from './+state/salesplanner-legacy.effects';
import { reducerA } from './+state/salesplanner-legacy.reducer';
import { SelectedActivityStateService } from './+state/selected-activity/selected-activity-state.service';
import { SelectedActivityEffects } from './+state/selected-activity/selected-activity.effects';
// eslint-disable-next-line max-len
import { SelectedVehicleServiceHistoryEffects } from './+state/selected-activity/service-history/service-history.effects';
// eslint-disable-next-line max-len
import { SelectedProspectDeliveriesEffects } from './+state/selected-activity/prospect-deliveries/prospect-deliveries.effects';
import { SelectedProspectVehiclesEffects } from './+state/selected-activity/vehicles/vehicles.effects';
import { UiStateService } from './+state/ui/ui-state-service';
import { VehicleSearchStateService } from './+state/vehicle-search/crm-vehicle-search-state.service';
import { VehicleSearchEffects } from './+state/vehicle-search/vehicle-search.effects';
import { CommunicatorEffects } from 'libs/communicator-ngrx/src/lib/+state/communicator.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('salesplannerLegacy', reducerA),
    EffectsModule.forFeature([
      SalesplannerLegacyEffects,
      SalesPlannerProspectsEffects,
      ProspectsEffects,
      DealerPromotionsEffects,
      SalesAppointmentsEffects,
      TasksEffects,
      DeliveriesEffects,
      ServiceAppointmentsEffects,
      SelectedActivityEffects,
      SelectedProspectVehiclesEffects,
      SelectedVehicleServiceHistoryEffects,
      VehicleSearchEffects,
      SelectedProspectDeliveriesEffects,
      CommunicatorEffects
    ])
  ],
  providers: [
    SalesplannerLegacyEffects,
    SalesPlannerStateService,
    DealerPromotionsStateService,
    SalesAppointmentsStateService,
    ServiceAppointmentsStateService,
    TasksStateService,
    ActivitiesStateService,
    SelectedActivityStateService,
    DeliveriesStateService,
    LocalSettingsStateService,
    UiStateService,
    VehicleSearchStateService
  ]
})
export class XsrSalesplannerLegacyModule {}
