import { Action } from '@ngrx/store';
import { Sale } from '@quorum/models/xs-resource';
import { SaleQueryParameters } from '@quorum/models/xs-query';

export const GET_PROSPECT_DELIVERIES_FROM_SERVER = '[CRM] Get Prospect Deliveries From Server';
export class GetProspectDeliveriesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_DELIVERIES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SaleQueryParameters) {}
}

export const GET_PROSPECT_DELIVERIES_FROM_SERVER_SUCCESS = '[CRM] Get Prospect Deliveries From Server Success';
export class GetProspectDeliveriesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_DELIVERIES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { deliveries: Sale[] }) {}
}

export const GET_PROSPECT_DELIVERIES_FROM_SERVER_FAILURE = '[CRM] Get Prospect Deliveries From Server Failure';
export class GetProspectDeliveriesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_DELIVERIES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectDeliveriesAction =
  | GetProspectDeliveriesFromServer
  | GetProspectDeliveriesFromServerSuccess
  | GetProspectDeliveriesFromServerFailure;
