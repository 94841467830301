import { Action } from '@ngrx/store';

export const LOAD_APPOINTMENT_DETAIL = '[SALESPLANNER] Load Appointment Detail';
export class LoadAppointmentDetail implements Action {
  readonly feature = 'crm';
  readonly type = LOAD_APPOINTMENT_DETAIL;
  readonly triggerStorageSync = false;
  constructor(public payload: { appointmentId: number }) {}
}

export const LOAD_TASK_DETAIL = '[SALESPLANNER] Load Task Detail';
export class LoadTaskDetail implements Action {
  readonly feature = 'crm';
  readonly type = LOAD_TASK_DETAIL;
  readonly triggerStorageSync = false;
  constructor(public payload: { taskId: number }) {}
}

export const LOAD_SERVICE_APPOINTMENT_DETAIL = '[SALESPLANNER] Load Service Appointment Detail';
export class LoadServiceAppointmentDetail implements Action {
  readonly feature = 'crm';
  readonly type = LOAD_SERVICE_APPOINTMENT_DETAIL;
  readonly triggerStorageSync = false;
  constructor(public payload: { appointmentId: number }) {}
}

export const LOAD_DEALER_PROMOTION_DETAIL = '[SALESPLANNER] Load Dealer Promotion Detail';
export class LoadDealerPromotionDetail implements Action {
  readonly feature = 'crm';
  readonly type = LOAD_DEALER_PROMOTION_DETAIL;
  readonly triggerStorageSync = false;
  constructor(public payload: { dealerPromotionId: number; dealerPromotionSequence: number }) {}
}

export const LOAD_DELIVERY_DETAIL = '[SALESPLANNER] Load Delivery Detail';
export class LoadDeliveryDetail implements Action {
  readonly feature = 'crm';
  readonly type = LOAD_DELIVERY_DETAIL;
  readonly triggerStorageSync = false;
  constructor(public payload: { deliveryId: number }) {}
}

export const LOAD_PROSPECT_DETAIL = '[SALESPLANNER] Load Prospect Detail';
export class LoadProspectDetail implements Action {
  readonly feature = 'crm';
  readonly type = LOAD_PROSPECT_DETAIL;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospectId: number }) {}
}

export const UPDATE_FEATURE_LOADING_STATE = '[SALESPLANNER] Update Feature Loading in State';
export class UpdateFeatureLoadingInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_FEATURE_LOADING_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: { loaded: boolean }) {}
}

export const RESET_STATE = '[SALESPLANNER] Reset State';
export class ResetState implements Action {
  readonly feature = 'crm';
  readonly type = RESET_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: {}) {}
}

export type SalesplannerLegacyAction =
  | LoadAppointmentDetail
  | LoadTaskDetail
  | LoadServiceAppointmentDetail
  | LoadProspectDetail
  | UpdateFeatureLoadingInState
  | ResetState;
