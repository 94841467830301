import * as fromServiceAppointments from './service-appointments.actions';
import { initialState, serviceAppointmentsAdapter, ServiceAppointmentsState } from './service-appointments.interfaces';

export function reducer(
  state: ServiceAppointmentsState = initialState,
  action: fromServiceAppointments.ServiceAppointmentsAction
): ServiceAppointmentsState {
  switch (action.type) {
    case fromServiceAppointments.GET_SERVICE_APPOINTMENTS_FROM_SERVER: {
      return {
        ...state,
        loading: true
      };
    }
    case fromServiceAppointments.GET_SERVICE_APPOINTMENTS_FROM_SERVER_SUCCESS: {
      return {
        ...serviceAppointmentsAdapter.addMany(action.payload.serviceAppointments, state),
        loading: false
      };
    }

    case fromServiceAppointments.CLEAR_SERVICE_APPOINTMENTS_FROM_STATE: {
      return {
        ...serviceAppointmentsAdapter.removeAll({ ...state, loading: false })
      };
    }

    default: {
      return state;
    }
  }
}
