import { initialState as openSalesState } from './open-sales/open-sales.interfaces';
import { initialState as prospectActionsInitialState } from './prospect-actions/prospect-actions.interfaces';
// eslint-disable-next-line max-len
import { initialState as prospectDealerPromotionsInitialState } from './prospect-dealer-promotions/prospect-dealer-promotions.interfaces';
// eslint-disable-next-line max-len
import { initialState as prospectSalesAppointmentInitialState } from './prospect-sales-appointments/prospect-sales-appointments.interfaces';
// eslint-disable-next-line max-len
import { initialState as prospectServiceAppointmentsInitialState } from './prospect-service-appointments/prospect-service-appointments.interfaces';
import { initialState as prospectTasksInitialState } from './prospect-tasks/prospect-tasks.interfaces';
import { initialState as prospectDeliveriesInitialState } from './prospect-deliveries/prospect-deliveries.interfaces';
import { initialState as responseLeadsInitialState } from './response-leads/response-leads.interfaces';
import { SelectedActivityState } from './selected-activity.interfaces';
import { initialState as vehicleServiceHistoryInitialState } from './service-history/service-history.interfaces';
import { initialState as vehiclesInitialStates } from './vehicles/vehicles.interfaces';
import { initialState as activityPurchaseOrderDetailsInitialState } from './purchase-order-details/purchase-order-details.interfaces';
import { initialState as vehicleOfInterestInitialState } from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.interfaces';

export const selectedActivityInitialState: SelectedActivityState = {
  type: null,
  activity: null,
  openSales: openSalesState,
  prospectVehicleOfInterest: vehicleOfInterestInitialState,
  prospect: null,
  prospectAggregate: null,
  vehicles: vehiclesInitialStates,
  vehicleAggregate: null,
  vehicleAggregateIsLoading: false,
  vehicleServiceHistory: vehicleServiceHistoryInitialState,
  prospectTasks: prospectTasksInitialState,
  prospectSalesAppointments: prospectSalesAppointmentInitialState,
  prospectDealerPromotions: prospectDealerPromotionsInitialState,
  prospectServiceAppointments: prospectServiceAppointmentsInitialState,
  prospectDeliveries: prospectDeliveriesInitialState,
  prospectActions: prospectActionsInitialState,
  prospectNotes: null,
  responseLeads: responseLeadsInitialState,
  selectedProspectIsLoading: true,
  activityPurchaseOrderDetails: activityPurchaseOrderDetailsInitialState,
};
