import { WorkOrder } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ServiceHistoryState extends EntityState<WorkOrder> {}

export const vehicleServiceHistoryAdapter: EntityAdapter<WorkOrder> = createEntityAdapter<WorkOrder>({
  selectId: (workOrder: WorkOrder) => workOrder.id
});

export const initialState: ServiceHistoryState = vehicleServiceHistoryAdapter.getInitialState();

export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');

export const vehicleServiceHistorySelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.vehicleServiceHistory
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleServiceHistoryAdapter.getSelectors(
  vehicleServiceHistorySelector
);
