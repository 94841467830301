import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { Address, Associate, Customer, Prospect } from '@quorum/models/xs-resource';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
// eslint-disable-next-line max-len
import { SelectedActivityStateService } from 'libs/xsr-salesplanner-legacy/src/lib/+state/selected-activity/selected-activity-state.service';
import { of } from 'rxjs';
import { map, mergeAll, switchMap, take } from 'rxjs/operators';
// eslint-disable-next-line max-len
import * as fromSelectedActivity from '../../../../../xsr-salesplanner-legacy/src/lib/+state/selected-activity/selected-activity.actions';
import * as fromProspects from './prospects.actions';
import { ProspectsState } from './prospects.interfaces';

@Injectable()
export class ProspectsEffects {
  getProspectsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.GET_PROSPECTS_FROM_SERVER, {
      run: (action: fromProspects.GetProspectsFromServer, state: ProspectsState) => {
        return this.apiService.getWithHeaders<Prospect[]>(`v/1/crm/prospects`, { params: action.payload }).pipe(
          map((prospects) => {
            return new fromProspects.GetProspectsFromServerSuccess({
              prospects: prospects.body,
              total: prospects.headers.total,
              totalPages: prospects.headers.totalPages,
              activePage: action.payload.page,
              pageSize: action.payload.pageSize,
            });
          })
        );
      },
      onError: (action: fromProspects.GetProspectsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  createProspectOnServer = createEffect(() =>
    this.dataPersistence.pessimisticUpdate(fromProspects.CREATE_PROSPECT_ON_SERVER, {
      run: (action: fromProspects.CreateProspectOnServer, state: ProspectsState) => {
        return this.apiService
          .post<Prospect>(`v/1/crm/prospects`, action.payload.prospect, {
            params: { embed: 'associate,vehicle,primaryEmployeeAssociate' },
          })
          .pipe(
            switchMap((prospect: Prospect) => {
              let actions: Action[] = [];
              actions.push(
                new fromProspects.CreateProspectOnServerSuccess({
                  prospect: prospect,
                  salespersonTakeoverMessage: action.payload.salespersonTakeoverMessage,
                })
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromProspects.CreateProspectOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  createProspectAndAssociateOnServer = createEffect(() =>
    this.dataPersistence.pessimisticUpdate(fromProspects.CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER, {
      run: (action: fromProspects.CreateProspectAndAssociateOnServer, state: any) => {
        return this.apiService.post<Associate>(`v/1/associates/associates`, action.payload.associate).pipe(
          switchMap((associate) => {
            let address: Address = new Address({ ...action.payload.address, associateId: associate.id });
            return this.apiService.post<Address>(`v/1/associates/associates/${associate.id}/addresses`, address).pipe(
              map((address) => {
                return associate;
              })
            );
          }),
          switchMap((associate) => {
            return this.systemControlStateService
              .getSystemControlValue('CL_ACCEPT_CHECK_FLAG', state.authentication.employee.rooftopId)
              .pipe(
                take(1),
                switchMap((sct) => {
                  let customer: Customer = {
                    ...new Customer(),
                    associateId: associate.id,
                    shopFlag: '1',
                    acceptCheque: sct.value,
                  };
                  return this.apiService.post<Customer>(`v/1/associates/customers`, customer).pipe(
                    map((customer) => {
                      return associate;
                    })
                  );
                })
              );
          }),
          switchMap((associate) => {
            let prospect = new Prospect();
            let params: ProspectQueryParameters = new ProspectQueryParameters({
              embed: ['associate', 'vehicle'],
            });

            Object.assign(prospect, action.payload.prospect);
            prospect.associateId = associate.id;

            return this.apiService.post<Prospect>(`v/1/crm/prospects`, prospect, { params: params }).pipe(
              map((prospect) => {
                return prospect;
              })
            );
          }),
          switchMap((prospect) => {
            let actions: Action[] = [];
            actions.push(
              new fromProspects.CreateProspectAndAssociateOnServerSuccess({
                prospect: prospect,
                salespersonTakeoverMessage: action.payload.salespersonTakeoverMessage,
              })
            );

            return of(actions).pipe(mergeAll());
          })
        );
      },
      onError: (action: fromProspects.CreateProspectAndAssociateOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  updateProspectAndCreateAssociateAndAddressOnServer = createEffect(() =>
    this.dataPersistence.pessimisticUpdate(fromProspects.UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER, {
      run: (action: fromProspects.UpdateProspectAndCreateAssociateAndAddressOnServer, state: any) => {
        return this.apiService.post<Associate>(`v/1/associates/associates`, action.payload.associate).pipe(
          switchMap((associate) => {
            let address: Address = new Address({ ...action.payload.address, associateId: associate.id });
            return this.apiService.post<Address>(`v/1/associates/associates/${associate.id}/addresses`, address).pipe(
              map((address) => {
                return associate;
              })
            );
          }),
          switchMap((associate) => {
            return this.systemControlStateService
              .getSystemControlValue('CL_ACCEPT_CHECK_FLAG', state.authentication.employee.rooftopId)
              .pipe(
                take(1),
                switchMap((sct) => {
                  let customer: Customer = {
                    ...new Customer(),
                    associateId: associate.id,
                    shopFlag: '1',
                    acceptCheque: sct.value,
                  };
                  return this.apiService.post<Customer>(`v/1/associates/customers`, customer).pipe(
                    map((customer) => {
                      return associate;
                    })
                  );
                })
              );
          }),
          map((associate) => {
            Object.values(state.salesplannerLegacy.selectedActivity.openSales.entities).forEach((openSale: any) => {
              openSale = { ...openSale, customerId: associate.id };
              this.selectedActivityStateService.updateSelectedProspectOpenSaleOnServer(openSale);
            });

            return associate;
          }),
          switchMap((associate) => {
            let prospect = new Prospect();

            Object.assign(prospect, action.payload.prospect);
            prospect.associateId = associate.id;

            return this.apiService
              .put<Prospect>(`v/1/crm/prospects/${action.payload.prospect.id}`, prospect, {
                params: {
                  embed: 'associate,primaryEmployeeAssociate,vehicle',
                  closeActivities: action.payload.prospect.statusId == '2' ? true : false,
                },
              })
              .pipe(
                map((prospect) => {
                  return prospect;
                })
              );
          }),
          switchMap((prospect) => {
            let actions: Action[] = [];
            actions.push(
              new fromProspects.UpdateProspectAndCreateAssociateAndAddressOnServerSuccess({
                prospect: prospect,
              })
            );
            actions.push(new fromSelectedActivity.GetSelectedProspectFromServerSuccess(prospect));
            return of(actions).pipe(mergeAll());
          })
        );
      },
      onError: (action: fromProspects.UpdateProspectAndCreateAssociateAndAddressOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  updateProspectOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.UPDATE_PROSPECT_ON_SERVER, {
      run: (action: fromProspects.UpdateProspectOnServer, state: ProspectsState) => {
        return this.apiService
          .put<Prospect>(`v/1/crm/prospects/${action.payload.prospect.id}`, action.payload.prospect, {
            params: {
              embed: 'associate,primaryEmployeeAssociate,vehicle,closedByAssociate',
              closeActivities: action.payload.prospect.statusId == '2' ? true : false,
            },
          })
          .pipe(
            switchMap((prospect) => {
              let actions: Action[] = [];
              actions.push(
                new fromProspects.UpdateProspectOnServerSuccess({
                  prospect: { id: prospect.id, changes: { ...prospect } },
                  displayToast: action.payload.displayToast,
                  salespersonTakeoverData: action.payload.salespersonTakeoverData,
                })
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },

      onError: (action: fromProspects.UpdateProspectOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  updateAssociateOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.UPDATE_ASSOCIATE_ON_SERVER, {
      run: (action: fromProspects.UpdateAssociateOnServer, state: ProspectsState) => {
        return this.apiService.put<Prospect>(`v/1/associates/associates/${action.payload.id}`, action.payload).pipe(
          switchMap((associate) => {
            let actions: Action[] = [];
            actions.push(
              new fromProspects.UpdateAssociateOnServerSuccess({ id: associate.id, changes: { ...associate } })
            );
            return of(actions).pipe(mergeAll());
          })
        );
      },

      onError: (action: fromProspects.UpdateAssociateOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  updateProspectAndAssociateOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromProspects.UPDATE_PROSPECT_AND_ASSOCIATE_ADDRESS_ON_SERVER, {
      run: (action: fromProspects.UpdateProspectAssociateOnServer, state: ProspectsState) => {
        console.log(action.payload);
        return this.apiService
          .put<Address>(
            `v/1/associates/associates/${action.payload.associate.id}/addresses/${action.payload.address.sequence}`,
            action.payload.address
          )
          .pipe(
            switchMap((address) => {
              return this.apiService
                .put<Associate>(`v/1/associates/associates/${action.payload.associate.id}`, action.payload.associate)
                .pipe(
                  switchMap((associate) => {
                    return this.apiService
                      .put<Prospect>(`v/1/crm/prospects/${action.payload.prospect.id}`, action.payload.prospect, {
                        params: {
                          embed: 'associate,primaryEmployeeAssociate,vehicle,closedByAssociate',
                          closeActivities: action.payload.prospect.statusId == '2' ? true : false,
                        },
                      })
                      .pipe(
                        switchMap((prospect) => {
                          let actions: Action[] = [];
                          actions.push(
                            // eslint-disable-next-line max-len
                            new fromProspects.UpdateProspectOnServerSuccess({
                              prospect: { id: prospect.id, changes: { ...prospect } },
                              displayToast: true,
                              salespersonTakeoverData: action.payload.salespersonTakeoverData,
                            })
                          );
                          return of(actions).pipe(mergeAll());
                        })
                      );
                  })
                );
            })
          );
      },

      onError: (action: fromProspects.UpdateProspectAssociateOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<ProspectsState>,
    private apiService: ApiService,
    private selectedActivityStateService: SelectedActivityStateService,
    private systemControlStateService: SystemControlStateService
  ) {}
}
