import { ServiceAppointment as Appointment } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ProspectServiceAppointmentsState extends EntityState<Appointment> {}

export const prospectServiceAppointmentsAdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: (serviceAppointment: Appointment) => serviceAppointment.id
});

export const initialState: ProspectServiceAppointmentsState = prospectServiceAppointmentsAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const prospectServiceAppointmentsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectServiceAppointments
);

export const { selectIds, selectEntities, selectAll, selectTotal } = prospectServiceAppointmentsAdapter.getSelectors(
  prospectServiceAppointmentsSelector
);
