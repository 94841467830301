export class TaskQueryParameters {
  communicatorConversationId?: string;
  completedDateFrom?: Date;
  completedDateTo?: Date;
  contactPreferenceId?: string;
  dueDateFrom?: string;
  dueDateTo?: string;
  embed?: any;
  id?: string;
  open?: string;
  page?: number;
  pageSize?: number;
  prospectId?: number;
  taskEmployeeId?: string[];
  templateId?: string;

  constructor(obj?: TaskQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
