import * as fromVehicleColours from './vehicle-colours.actions';
import { vehicleColoursAdapter, VehicleColoursState, initialState } from './vehicle-colours.interfaces';

export function reducer(
  state: VehicleColoursState = initialState,
  action: fromVehicleColours.VehicleColoursAction
): VehicleColoursState {
  switch (action.type) {
    case fromVehicleColours.GET_VEHICLE_COLOURS_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleColoursAdapter.addMany(action.payload.vehicleColours, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
