import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskQueryParameters } from '@quorum/models/xs-query';
import { Task } from '@quorum/models/xs-resource';

export const GET_TASKS_FROM_SERVER = '[CRM] Get Tasks From Server';
export class GetDealerTasksFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_TASKS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: TaskQueryParameters) {}
}

export const GET_TASKS_FROM_SERVER_SUCCESS = '[CRM] Get Tasks  From Server Success';
export class GetDealerTasksFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_TASKS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { tasks: Task[] }) {}
}

export const GET_TASKS_FROM_SERVER_FAILURE = '[CRM] Get Tasks  From Server Failure';
export class GetDealerTasksFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_TASKS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_TEMPLATE_CREATED_TASKS_FROM_SERVER = '[CRM] Get Template Created Tasks From Server';
export class GetTemplateCreatedTasksFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_TEMPLATE_CREATED_TASKS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: TaskQueryParameters) {}
}

export const GET_TEMPLATE_CREATED_TASKS_FROM_SERVER_SUCCESS = '[CRM] Get Template Created Tasks From Server Success';
export class GetTemplateCreatedTasksFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_TEMPLATE_CREATED_TASKS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { tasks: Task[] }) {}
}

export const GET_TEMPLATE_CREATED_TASKS_FROM_SERVER_FAILURE = '[CRM] Get Template Created Tasks From Server Failure';
export class GetTemplateCreatedTasksFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_TEMPLATE_CREATED_TASKS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_TASKS_FROM_STATE = '[CRM] Clear Tasks From State';
export class ClearTasksFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_TASKS_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export const CREATE_TASK_ON_SERVER = '[CRM] Create Task On Server';
export class CreateTaskOnServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_TASK_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: Task) {}
}

export const CREATE_TASK_ON_SERVER_SUCCESS = '[CRM] Create Task On Server Success';
export class CreateTaskOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_TASK_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Task) {}
}

export const CREATE_TASK_ON_SERVER_FAILURE = '[CRM] Create Task On Failure';
export class CreateTaskOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_TASK_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_TASK_ON_SERVER = '[CRM] Update Task On Server';
export class UpdateTaskOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_TASK_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { task: Task }) {}
}

export const UPDATE_TASK_ON_SERVER_SUCCESS = '[CRM] Update Task On Server Success';
export class UpdateTaskOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_TASK_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Task>) {}
}

export const UPDATE_TASK_ON_SERVER_FAILURE = '[CRM] Update Task On Failure';
export class UpdateTaskOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_TASK_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type TasksAction =
  | GetDealerTasksFromServer
  | GetDealerTasksFromServerSuccess
  | GetDealerTasksFromServerFailure
  | ClearTasksFromState
  | CreateTaskOnServer
  | CreateTaskOnServerSuccess
  | CreateTaskOnServerFailure
  | UpdateTaskOnServer
  | UpdateTaskOnServerSuccess
  | UpdateTaskOnServerFailure
  | GetTemplateCreatedTasksFromServer
  | GetTemplateCreatedTasksFromServerSuccess
  | GetTemplateCreatedTasksFromServerFailure;
