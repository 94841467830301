import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { DealerPromotionQueryParameters } from '@quorum/models/xs-query';
import { DealerPromotion } from '@quorum/models/xs-resource';

export const GET_DEALER_PROMOTIONS_FROM_SERVER = '[CRM] Get Dealer Promotions From Server';
export class GetDealerPromotionsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_DEALER_PROMOTIONS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: DealerPromotionQueryParameters) {}
}

export const GET_DEALER_PROMOTIONS_FROM_SERVER_SUCCESS = '[CRM] Get Dealer Promotions  From Server Success';
export class GetDealerPromotionsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_DEALER_PROMOTIONS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { dealerPromotions: DealerPromotion[] }) {}
}

export const GET_DEALER_PROMOTIONS_FROM_SERVER_FAILURE = '[CRM] Get Dealer Promotions  From Server Failure';
export class GetDealerPromotionsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_DEALER_PROMOTIONS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_DEALER_PROMOTIONS_ON_SERVER = '[CRM] Update Dealer Promotion On Server';
export class UpdateDealerPromotionOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_DEALER_PROMOTIONS_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: DealerPromotion) {}
}

export const UPDATE_DEALER_PROMOTIONS_ON_SERVER_SUCCESS = '[CRM] Update Dealer Promotion On Server Success';
export class UpdateDealerPromotionOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_DEALER_PROMOTIONS_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Update<DealerPromotion>) {}
}

export const UPDATE_DEALER_PROMOTIONS_ON_SERVER_FAILURE = '[CRM] Update Dealer Promotion On Failure';
export class UpdateDealerPromotionOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_DEALER_PROMOTIONS_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_DEALER_PROMOTIONS_FROM_STATE = '[CRM] Clear Dealer Promos From State';
export class ClearDealerPromotionsFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_DEALER_PROMOTIONS_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export type DealerPromotionsAction =
  | GetDealerPromotionsFromServer
  | GetDealerPromotionsFromServerSuccess
  | GetDealerPromotionsFromServerFailure
  | UpdateDealerPromotionOnServer
  | UpdateDealerPromotionOnServerSuccess
  | UpdateDealerPromotionOnServerFailure
  | ClearDealerPromotionsFromState;
