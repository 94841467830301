export class BlackbookSettingQueryParameters {
  blackbookPriceTypeId?: string;
  id?: string;
  rooftopId?: string;

  constructor(obj?: BlackbookSettingQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
