import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AssociateCustomerType as CustomerType } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface CustomerTypesState extends EntityState<CustomerType> {}

export const customerTypesAdapter: EntityAdapter<CustomerType> = createEntityAdapter<CustomerType>({
  selectId: (customerType: CustomerType) => customerType.id,
});

export const initialState: CustomerTypesState = customerTypesAdapter.getInitialState({});

export const messageTypesSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.customerTypes);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  customerTypesAdapter.getSelectors(messageTypesSelector);
