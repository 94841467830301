import * as fromVehicleSearch from './vehicle-search.actions';
import { initialState, vehicleSearchResultsAdapter, VehicleSearchState } from './vehicle-search.interfaces';

export function vehicleSearchReducer(state = initialState, action: any): VehicleSearchState {
  switch (action.type) {
    default: {
      return state;
    }

    case fromVehicleSearch.GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER: {
      return {
        ...state,
        isLoading: true
      };
    }

    case fromVehicleSearch.GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleSearchResultsAdapter.addMany(action.payload.vehicleSearchResults, state),
        isLoading: false
      };
    }

    case fromVehicleSearch.CLEAR_VEHICLE_SEARCH_RESULTS_FROM_STATE: {
      return {
        ...vehicleSearchResultsAdapter.removeAll({ ...state })
      };
    }
  }
}
