import * as fromHtmlMappingTemplates from './html-mapping-templates.actions';
import {
  htmlMappingTemplatesAdapter,
  HtmlMappingTemplatesState,
  initialState
} from './html-mapping-templates.interfaces';

export function reducer(
  state: HtmlMappingTemplatesState = initialState,
  action: fromHtmlMappingTemplates.HtmlMappingTemplatesAction
): HtmlMappingTemplatesState {
  switch (action.type) {
    case fromHtmlMappingTemplates.GET_HTML_MAPPING_TEMPLATES_FROM_SERVER_SUCCESS: {
      return {
        ...htmlMappingTemplatesAdapter.addMany(action.payload.htmlMappingTemplates, state)
      };
    }

    default: {
      return state;
    }
  }
}
