import * as fromSettings from './settings.actions';
import { CrmSettingsInitialState } from './settings.init';
import { CrmSettingsState } from './settings.interfaces';

export function reducer(state = CrmSettingsInitialState, action: fromSettings.CrmSettingsAction): CrmSettingsState {
  switch (action.type) {
    case fromSettings.GET_CRM_SETTINGS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
}
