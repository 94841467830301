import { Sale } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface OpenSalesState extends EntityState<Sale> {}

export const openSalesAdapter: EntityAdapter<Sale> = createEntityAdapter<Sale>({
  selectId: (sale: Sale) => sale.id
});

export const initialState: OpenSalesState = openSalesAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const selectedProspectOpenSalesSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.openSales
);

export const { selectIds, selectEntities, selectAll, selectTotal } = openSalesAdapter.getSelectors(
  selectedProspectOpenSalesSelector
);
