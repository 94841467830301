import { createSelector } from '@ngrx/store';
import { AssociateClassesState } from './associate-classes/associate-classes.interfaces';
import { ChannelsState } from './channels/channels.interfaces';
import { crmDropdownsFeatureSelector } from './crm-dropdowns-feature.selector';
import { CustomerTypesState } from './customer-types/customer-types.interfaces';
import { DirectMessageTemplatesState } from './direct-message-templates/direct-message-templates.interfaces';
import { EmployeesState } from './employees/employees.interfaces';
import { HtmlMappingTemplatesState } from './html-mapping-templates/html-mapping-templates.interfaces';
import { MessageTypesState } from './message-types/message-types.interfaces';
import { ProspectActionsState } from './prospect-actions/prospect-actions.interfaces';
import { RooftopsState } from './rooftops/rooftops.interfaces';
import { SalesTeamsState } from './sales-teams/sales-teams.interfaces';
import { SourcesState } from './sources/sources.interfaces';
import { TaskOutcomesState } from './task-outcomes/task-outcomes.interfaces';
import { VehicleCategoriesState } from './vehicle-categories/vehicle-categories.interfaces';
import { VehicleColoursState } from './vehicle-colours/vehicle-colours.interfaces';
import { VehicleGenericModelsState } from './vehicle-generic-models/vehicle-generic-models.interfaces';
import { VehicleMakesState } from './vehicle-makes/vehicle-makes.interfaces';
import { VehicleStatusesState } from './vehicle-statuses/vehicle-statuses.interfaces';
import { VehicleSubcategoriesState } from './vehicle-subcategories/vehicle-subcategories.interfaces';
import { VehicleSubTypesState } from './vehicle-subtypes/vehicle-subtypes.interfaces';
import { VendorsState } from './vendors/vendors.interfaces';

export interface DropdownsState {
  readonly associateClasses: AssociateClassesState;
  readonly channels: ChannelsState;
  readonly customerTypes: CustomerTypesState;
  readonly htmlMappingTemplates: HtmlMappingTemplatesState;
  readonly directMessageTemplates: DirectMessageTemplatesState;
  readonly messageTypes: MessageTypesState;
  readonly rooftops: RooftopsState;
  readonly salespeople: any;
  readonly salesTeams: SalesTeamsState;
  readonly sources: SourcesState;
  readonly taskOutcomes: TaskOutcomesState;
  readonly prospectActions: ProspectActionsState;
  readonly vehicleCategories: VehicleCategoriesState;
  readonly vehicleColours: VehicleColoursState;
  readonly vehicleGenericModels: VehicleGenericModelsState;
  readonly vehicleMakes: VehicleMakesState;
  readonly vehicleStatuses: VehicleStatusesState;
  readonly vehicleSubcategories: VehicleSubcategoriesState;
  readonly vehicleSubTypes: VehicleSubTypesState;
  readonly employees: EmployeesState;
  readonly vendors: VendorsState;
}

export const salespeopleSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.salespeople);
