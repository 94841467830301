<div fxLayout.gt-xs="row" fxLayout.xs="column" class="container">
  <div fxHide.xs class="selection">
    <mat-list role="list">
      <mat-list-item [disableRipple]="browserControlCommunicationService.disableRippleEffect" role="listitem"
        *ngFor="let action of actions | orderBy: ['sortOrder']">
        <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <div fxFlex>
            {{action.description}}
          </div>
          <div>
            <button mat-icon-button color="primary" (click)="createAction(action, actionCreated)"
              [disabled]="prospect.statusId === '2'">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="mobile-selection" fxHide fxShow.xs>
    <mat-form-field fxFlex>
      <mat-select placeholder="Select an action" #actionSelect>
        <mat-option *ngFor="let action of actions;" [value]="action">{{action.description}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button color="primary" class="add-button"
      (click)="createAction(actionSelect.value, actionCreated);actionSelect.value = null"
      [disabled]="prospect.statusId === '2'">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div fxFlex>
    <div class="action-list-container" *ngIf="prospectActions$ | async as prospectActions">
      <mat-list>
        <ng-container *ngFor="let prospectAction of prospectActions | orderBy: '-enteredDateTime'">
          <mat-list-item [disableRipple]="browserControlCommunicationService.disableRippleEffect">
            <h3 matLine> {{prospectAction.embedded.action?.description}} </h3>
            <p matLine class="secondary-text">
              <span> {{prospectAction.embedded.employeeAssociate?.firstName}}
                {{prospectAction.embedded.employeeAssociate?.lastName}}
              </span>
            </p>
            <span matLine class="secondary-text">{{prospectAction.enteredDateTime | moment: 'hh:mm A MMM DD, YYYY'}}
            </span>
            <div fxLayout="column" *ngIf="prospectAction?.embedded?.action?.log">
              <button mat-icon-button (click)="deleteAction(prospectAction, actionDeleted)"
                [disabled]="prospect.statusId === '2'">
                <mat-icon>remove</mat-icon>
              </button>
            </div>
          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </div>
  </div>
</div>