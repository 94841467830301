import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResponseLead } from '@quorum/models/xs-resource';

export interface ResponseLeadsState extends EntityState<ResponseLead> {}

export const responseLeadsAdapter: EntityAdapter<ResponseLead> = createEntityAdapter<ResponseLead>({
  selectId: (responseLead: ResponseLead) => responseLead.id,
});

export const initialState: ResponseLeadsState = responseLeadsAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const selectedProspectActionsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.responseLeads
);

export const { selectIds, selectEntities, selectAll, selectTotal } = responseLeadsAdapter.getSelectors(
  selectedProspectActionsSelector
);
