import { Injectable } from '@angular/core';
import { ServiceAppointment as Appointment } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { from, of } from 'rxjs';
import * as fromServiceAppointments from './service-appointments.actions';
import { ServiceAppointmentsState } from './service-appointments.interfaces';
import { mergeAll, flatMap } from 'rxjs/operators';

@Injectable()
export class ServiceAppointmentsEffects {
  
  getServiceAppointmentsFromServer = createEffect(() => this.dataPersistence.fetch(
    fromServiceAppointments.GET_SERVICE_APPOINTMENTS_FROM_SERVER,
    {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromServiceAppointments.GetServiceAppointmentsFromServer, state: ServiceAppointmentsState) => {
        return this.apiService
          .get<Appointment[]>(`v/1/fixed-operations/appointments`, {
            params: action.payload
          })
          .pipe(
            flatMap(serviceAppointments => {
              let actions: any[] = [];

              actions.push(new fromServiceAppointments.ClearServiceAppointmentsFromState());

              actions.push(
                new fromServiceAppointments.GetServiceAppointmentsFromServerSuccess({
                  serviceAppointments: serviceAppointments
                })
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromServiceAppointments.GetServiceAppointmentsFromServer, error) => {
        console.error('Error', error);
      }
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<ServiceAppointmentsState>,
    private apiService: ApiService
  ) {}
}
