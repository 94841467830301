import * as fromSalesAppointments from './sales-appointments.actions';
import { initialState, salesAppointmentsdapter, SalesAppointmentsState } from './sales-appointments.interfaces';

export function reducer(
  state: SalesAppointmentsState = initialState,
  action: fromSalesAppointments.SalesAppointmentsAction
): SalesAppointmentsState {
  switch (action.type) {
    case fromSalesAppointments.GET_SALES_APPOINTMENTS_FROM_SERVER: {
      return {
        ...state,
        loading: true
      };
    }
    case fromSalesAppointments.GET_SALES_APPOINTMENTS_FROM_SERVER_SUCCESS: {
      return {
        ...salesAppointmentsdapter.addMany(action.payload.salesAppointments, state),
        loading: false
      };
    }
    case fromSalesAppointments.CREATE_SALES_APPOINTMENT_ON_SERVER_SUCCESS: {
      return {
        ...salesAppointmentsdapter.addOne(action.payload, state)
      };
    }

    case fromSalesAppointments.CLEAR_SALES_APPOINTMENTS_FROM_STATE: {
      return {
        ...salesAppointmentsdapter.removeAll({ ...state, loading: false })
      };
    }

    case fromSalesAppointments.UPDATE_SALES_APPOINTMENT_ON_SERVER_SUCCESS: {
      return {
        ...salesAppointmentsdapter.updateOne(action.payload, state)
      };
    }

    default: {
      return state;
    }
  }
}
