import { AddDeductApi } from './add-deduct.model';
import { BlackbookValuesSetting } from './blackbook-values-setting.model';

export class BlackbookValue {
  amount: number = null;
  id?: number = null;
  publishDate: Date = null;
  uvc: string = null;
  vehicleId: number = null;

  constructor(obj?: BlackbookValue) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class BlackbookValueApi {
  addDeducts: AddDeductApi[] = null;
  avg: number = null;
  clean: number = null;
  rough: number = null;
  xClean: number = null;
  description: string = null;
  publishDate: Date = null;
  uvc: string = null;

  constructor(obj?: BlackbookValueApi) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

export class BlackbookWorkflowMatchValue {
  addDeducts: AddDeductApi[] = null;
  amount: number = null;
  blackbookValuesSetting?: BlackbookValuesSetting = null;
  publishDate: Date = null;
  previousUvc: number = null;
  uvc: string = null;

  constructor(obj?: BlackbookWorkflowMatchValue) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
