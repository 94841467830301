import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DealerPromotionQueryParameters } from '@quorum/models/xs-query';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromDealerPromotions from './dealer-promotions.actions';
import {
  isDealerPromotionsLoadingSelector,
  selectAll as selectDealerPromotionsCombined,
} from './dealer-promotions.interfaces';

@Injectable()
export class DealerPromotionsStateService {
  constructor(private store: Store<any>) {}

  selectDealerPromotionsLoading() {
    return this.store.pipe(select(isDealerPromotionsLoadingSelector));
  }

  selectDealerPromotionsCombined() {
    return combineLatest(
      this.store.pipe(select(selectDealerPromotionsCombined)),
      this.selectDealerPromotionsLoading()
    ).pipe(
      map(([dealerPromotions, isLoading]) => {
        return { dealerPromotions, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result);
      })
    );
  }

  getDealerPromotionsFromServer(queryParams: DealerPromotionQueryParameters) {
    this.store.dispatch(new fromDealerPromotions.GetDealerPromotionsFromServer(queryParams));
  }

  clearDealerPromotionsFromState() {
    this.store.dispatch(new fromDealerPromotions.ClearDealerPromotionsFromState());
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
