import * as fromLocalSettings from './local-settings.actions';
import { initialLocalSettingsState } from './local-settings.init';
import { LocalSettingsState } from './local-settings.interfaces';
export function localSettingsReducer(
  state = initialLocalSettingsState,
  action: fromLocalSettings.LocalSettingsAction
): LocalSettingsState {
  switch (action.type) {
    case fromLocalSettings.UPDATE_LOCAL_SETTINGS_IN_STATE: {
      return { ...action.payload };
    }
    case fromLocalSettings.RESET_STATE: {
      return initialLocalSettingsState;
    }
    default: {
      return state;
    }
  }
}

export function reducer(state: LocalSettingsState, action: fromLocalSettings.LocalSettingsAction): LocalSettingsState {
  let newState: LocalSettingsState;
  if (state) {
    newState = localSettingsReducer(state, action);
    localStorage.setItem('salesplannerLocalSettings', JSON.stringify(newState));
  } else {
    let localState = localStorage.getItem('salesplannerLocalSettings');
    let initialState: LocalSettingsState;

    if (localState) {
      initialState = JSON.parse(localState);
      initialState.startDate = new Date();
      Object.keys(initialLocalSettingsState).forEach((setting: string) => {
        initialState[setting] = initialState[setting] || initialLocalSettingsState[setting];
      });
    } else {
      initialState = initialLocalSettingsState;
    }
    newState = localSettingsReducer(initialState, action);
    localStorage.setItem('salesplannerLocalSettings', JSON.stringify(newState));
  }
  return newState;
}
