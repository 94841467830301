import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { LocalSettingsState } from '@quorum/xsr-salesplanner-legacy/state';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-activity-list-item',
  templateUrl: './activity-list-item.component.html',
  styleUrls: ['./activity-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activity: any;
  @Input() grouped: boolean;
  @Input() overdue: boolean;
  @Input() combinedData: any;
  @Input() selectedActivity$: Observable<any>;
  @Input() localSettings$: Observable<LocalSettingsState>;
  activityBackgroundStyle: string = null;
  salespersonName: string = '';
  appointmentAttendence: string = '';
  activityComments: string = '';
  selectedActivitySub$: Subscription;
  constructor(
    public browserControlCommunicationService: BrowserControlCommunicationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activity && changes.activity.currentValue) {
      this.salespersonName = changes.activity.currentValue.activitySalesperson
        ? ' - ' + changes.activity.currentValue.activitySalesperson
        : '';
      this.activityComments = changes.activity.currentValue.activityComments
        ? changes.activity.currentValue.activityComments
        : 'N/A';
      this.appointmentAttendence =
        changes.activity.currentValue.activityType == 'Appointment'
          ? changes.activity.currentValue.activityAttendance == 'N'
            ? ' - No Show'
            : ' - Show'
          : '';
      this.selectedActivitySub$ = this.selectedActivity$
        .pipe(
          tap((selectedActivity) => {
            if (this.activityBackgroundStyle != null && selectedActivity && (this.grouped || this.overdue)) {
              if (
                selectedActivity.id != changes.activity.currentValue.activityId ||
                (selectedActivity.id == changes.activity.currentValue.activityId &&
                  selectedActivity.sequence != changes.activity.currentValue.activitySequence)
              ) {
                this.activityBackgroundStyle = null;
                this.cdr.detectChanges();
              }
            }
          }),
          filter((selectedActivity) => {
            return (
              selectedActivity &&
              selectedActivity.id == changes.activity.currentValue.activityId &&
              selectedActivity.sequence == changes.activity.currentValue.activitySequence &&
              (this.grouped || this.overdue)
            );
          })
        )
        .subscribe((selectedActivity) => {
          this.activityBackgroundStyle = 'rgba(158, 158, 158, 0.2)';
          this.cdr.detectChanges();
        });
      this.cdr.detach();
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.selectedActivitySub$.unsubscribe();
  }
}
