export class VehicleOfInterestQueryParameters {
  associateId?: string;
  colour?: string;
  id?: string;
  make?: string;
  maxMileage?: number;
  maxPrice?: number;
  minMileage?: number;
  minPrice?: number;
  model?: string;
  modelCode?: string;
  page?: number;
  pageSize?: number;
  prospectId?: string;
  saleId?: string;
  salespersonId?: string;
  sourceId?: string;
  statusId?: string;
  stockNumber?: string;
  subCategoryId?: string;
  subTypeId?: string;
  trim?: string;
  vehicleId?: string;
  vin?: string;
  yearFrom?: number;
  yearTo?: number;

  constructor(obj?: VehicleOfInterestQueryParameters) {
    if (obj) Object.assign(this, obj);
  }
}
