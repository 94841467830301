import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DeepCopyPipe, MomentPipe } from '@quorum/common-pipes';
import { CrmAppointment as Appointment, Employee } from '@quorum/models/xs-resource';
import { ActivitiesStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { createFormGroupFromObject } from 'libs/sha-services/src/lib/createFormGroupFromObject';
import { SystemControlState } from 'libs/xsr-system-control/src/lib/+state/system-control.interfaces';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentCardComponent implements OnInit, OnChanges {
  @Input() appointment: Appointment;
  @Output()
  appointmentUpdated: EventEmitter<{
    appointmentCopy: Appointment;
    updatedAppointment: Appointment;
  }> = new EventEmitter<{
    appointmentCopy: Appointment;
    updatedAppointment: Appointment;
  }>();
  appointmentCopy: Appointment = new Appointment();
  appointmentForm: FormGroup;
  newTime: Date;
  systemControl$: Observable<SystemControlState>;
  currentUser$: Observable<Employee>;

  constructor(
    private activitiesStateService: ActivitiesStateService,
    private authenticationStateService: AuthenticationStateService,
    private systemControlStateService: SystemControlStateService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.currentUser$ = this.authenticationStateService.selectAuthenticatedEmployee().pipe(
      take(1),
      tap((currentUser) => {
        this.systemControl$ = this.systemControlStateService
          .getSystemControlValue('CL_SALES_APPOINTMENT_CONFIRMATIONS', currentUser.rooftopId)
          .pipe(take(1));
      })
    );

    if (changes.appointment.currentValue) {
      this.appointmentForm = createFormGroupFromObject(changes.appointment.currentValue);
      this.appointmentForm.addControl(
        'appointmentTime',
        new FormControl(new Date(this.appointmentForm.get('appointmentDate').value).toTimeString().substring(0, 5))
      );
      this.appointmentCopy = new DeepCopyPipe().transform(changes.appointment.currentValue);
    }
  }

  updateAppointment(currentUser: Employee): void {
    if (
      (this.appointmentForm.get('attendanceFlag').value == 'N' ||
        this.appointmentForm.get('attendanceFlag').value == 'S') &&
      this.appointmentForm.get('completedDate').value == null
    ) {
      this.appointmentForm.get('completedDate').setValue(new Date().toISOString());
    } else if (this.appointmentForm.get('attendanceFlag').value == 'null') {
      this.appointmentForm.get('attendanceFlag').setValue(null);
      this.appointmentForm.get('completedDate').setValue(null);
    }

    if (this.appointmentForm.get('confirmationFlag').value) {
      this.appointmentForm.get('confirmationDate').setValue(new Date());
      this.appointmentForm.get('confirmationAssociateId').setValue(currentUser.associateId);
    } else {
      this.appointmentForm.get('confirmationDate').setValue(null);
      this.appointmentForm.get('confirmationAssociateId').setValue(null);
    }

    const timeDate = new MomentPipe().timeDate(this.appointmentForm.get('appointmentTime').value, 'hh:mm');
    this.newTime = new Date(this.appointmentForm.get('appointmentDate').value);
    this.newTime.setHours(timeDate.getHours());
    this.newTime.setMinutes(timeDate.getMinutes());
    this.appointmentForm.get('appointmentDate').setValue(this.newTime.toISOString());
    this.activitiesStateService.updateAppointmentOnServer(this.appointmentCopy, this.appointmentForm.value);
    this.appointmentUpdated.emit({
      appointmentCopy: this.appointmentCopy,
      updatedAppointment: this.appointmentForm.value,
    });
  }
}
