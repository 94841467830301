import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
//import {MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';

@Component({
  selector: 'com-new-conversation-dialog',
  templateUrl: './new-conversation-dialog.component.html',
  styleUrls: ['./new-conversation-dialog.component.scss'],
})
export class NewConversationDialogComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  nameCtrl = new FormControl();
  filteredNames: Observable<any[]>;
  names: any[] = [];
  sendAsGroupEnabled = false;
  data: { contacts: any[]; message: string; sendAsGroupEnabled: boolean };
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;
  @ViewChild('nameInput') nameInput: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<NewConversationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: { currentUser: any; contacts: any; message: any }
  ) {
    this.filteredNames = this.nameCtrl.valueChanges.pipe(
      startWith(null),
      map((customer: any | null) => (customer ? this._filter(customer) : this.dialogData.contacts.slice()))
    );
  }

  ngOnInit(): void {}

  add(event: MatChipInputEvent): void {
    const input = event.input;

    if (input) {
      input.value = '';
    }

    this.nameCtrl.setValue(null);
  }

  remove(id: string): void {
    let customer = this.names.find((name) => name.id === id);
    let index = this.names.indexOf(customer);

    if (index >= 0) {
      this.names.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.names.includes(event.option.value)) {
      this.names.push(event.option.value);
    }
    this.nameInput.nativeElement.value = '';
    this.nameCtrl.setValue(null);
  }

  private _filter(value: any): string[] {
    if (!value.firstName || !value.lastName) {
      const filterValue = value.toLowerCase();

      return this.dialogData.contacts.filter(
        (name: any) =>
          name.firstName.toLowerCase().indexOf(filterValue) === 0 ||
          name.lastName.toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      const filterValueFirstName = value.firstName.toLowerCase();
      const filterValueLatName = value.lastName.toLowerCase();

      return this.dialogData.contacts.filter(
        (name: any) =>
          name.firstName.toLowerCase().indexOf(filterValueFirstName) === 0 ||
          name.lastName.toLowerCase().indexOf(filterValueLatName) === 0
      );
    }
  }

  openPanel(): void {
    const self = this;
    requestAnimationFrame(function () {
      self.autoTrigger.openPanel();
    });
  }

  sendData() {
    this.data = {
      contacts: this.names,
      message: (<HTMLInputElement>document.getElementById('message')).value,
      sendAsGroupEnabled: this.sendAsGroupEnabled,
    };

    return this.data;
  }
}
