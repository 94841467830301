import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { TaskOutcome } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface TaskOutcomesState extends EntityState<TaskOutcome> {}

export const taskOutcomesAdapter: EntityAdapter<TaskOutcome> = createEntityAdapter<TaskOutcome>({
  selectId: (taskOutcome: TaskOutcome) => taskOutcome.id,
});

export const initialState: TaskOutcomesState = taskOutcomesAdapter.getInitialState({});
export const taskOutcomesSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.taskOutcomes);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  taskOutcomesAdapter.getSelectors(taskOutcomesSelector);
