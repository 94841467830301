<mat-list-item
  [disableRipple]="browserControlCommunicationService.disableRippleEffect"
  [style.background-color]="activityBackgroundStyle"
>
  <div
    mat-list-icon
    *ngIf="activity.activityType == 'Promotion'"
    class="dealerPromo type-icon"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ activity.activityType.substring(0, 1) }}
  </div>
  <div
    mat-list-icon
    *ngIf="activity.activityType == 'Appointment'"
    class="salesAppt type-icon"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ activity.activityType.substring(0, 1) }}
    <ng-container [ngSwitch]="activity.customerContactPref">
      <mat-icon *ngSwitchCase="'E'" class="inline-icon" mat-list-icon>email</mat-icon>
      <mat-icon *ngSwitchCase="'F'" class="inline-icon" mat-list-icon>print</mat-icon>
      <mat-icon *ngSwitchCase="'H'" class="inline-icon" mat-list-icon>phone</mat-icon>
      <mat-icon *ngSwitchCase="'L'" class="inline-icon" mat-list-icon>email</mat-icon>
    </ng-container>
  </div>
  <div
    mat-list-icon
    *ngIf="activity.activityType == 'Service'"
    class="serviceAppt type-icon"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ activity.activityType.substring(0, 1) }}
  </div>
  <div
    mat-list-icon
    *ngIf="activity.activityType == 'Delivery'"
    class="delivery type-icon"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ activity.activityType.substring(0, 1) }}
  </div>
  <div
    mat-list-icon
    *ngIf="activity.activityType == 'Task'"
    class="task type-icon"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ activity.activityType.substring(0, 1) }}
  </div>
  <span mat-line fxLayoutAlign="space-between center">
    <div fxLayoutAlign="start center">
      <span>{{ activity.customerName }}&nbsp;</span>
      <span *ngIf="combinedData.salesPlannerView === 1 || combinedData.teamLead === true; else notTeamLeadOrManager">{{
        salespersonName
      }}</span>
      <ng-template #notTeamLeadOrManager>
        <span
          class="space secondary-text"
          *ngIf="activity.activitySalesperson && (localSettings$ | async)?.salesPeople.length > 1"
        >
          {{ salespersonName }}</span
        >
      </ng-template>
    </div>
    <span class="secondary-text" *ngIf="grouped || overdue">{{ activity.prospectType }}</span>
  </span>
  <div mat-line *ngIf="!grouped">
    <div *ngIf="activity.activityCompletedDate; else dueDate">
      {{ activity.activityOutcome }} on {{ activity.activityCompletedDate | moment: 'dddd, MMM Do, YYYY' }}
      {{ appointmentAttendence }}
    </div>
    <ng-template #dueDate>
      <span *ngIf="activity.activityType == 'Service'; else nonService">
        {{ activity.activityDueDate | moment: 'hh:mm a dddd, MMM Do, YYYY' }} - ODO In:
        {{ activityComments }}
      </span>
      <ng-template #nonService>
        <span>
          {{ activity.activityDueDate | moment: 'dddd, MMM Do, YYYY' }}
        </span>
      </ng-template>
    </ng-template>
  </div>

  <div mat-line fxLayoutAlign="start start">
    <span
      *ngIf="grouped && (activity.activityType == 'Appointment' || activity.activityType == 'Delivery')"
      class="space"
      >{{ activity.activityDueDate | moment: 'h:mm a' }}</span
    >
    <span *ngIf="grouped && activity.activityType == 'Service'" class="space">
      {{ activity.activityDueDate | moment: 'h:mm a' }} - ODO In:
      {{ activityComments }}
    </span>
  </div>
  <div mat-line>
    <span *ngIf="activity.open; else closed" class="secondary-text">{{ activity.activityObjectives }}</span>
    <ng-template #closed>
      <span class="secondary-text">{{ activity.activityComments }}</span>
    </ng-template>
  </div>
</mat-list-item>
