import * as fromChannels from './channels.actions';
import { channelsAdapter, ChannelsState, initialState } from './channels.interfaces';

export function reducer(state: ChannelsState = initialState, action: fromChannels.ChannelsAction): ChannelsState {
  switch (action.type) {
    case fromChannels.GET_CHANNELS_FROM_SERVER_SUCCESS: {
      return {
        ...channelsAdapter.addMany(action.payload.channels, state)
      };
    }

    default: {
      return state;
    }
  }
}
