import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface LocalSettingsState {
  [key: string]: any;
  accessibility: { boldText: boolean; increaseContrast: boolean; largeText: boolean; theme: string };
  defaultActivityTab: string;
  defaultProspectTab: string;
  activitiesFilter: Array<string>;
  range: number;
  rangePast: number;
  monthsOverdue: number;
  salesPeople: Array<string>;
  startDate: Date;
  prospectFilter: ProspectFilter;
  activityListDisplayEmployee: string;
  prospectSortBy: string;
  settingsChanged: Array<string>;
}

export interface ProspectFilter {
  typeId: Array<number>;
  channel: Array<string>;
  source: Array<string>;
  status: Array<number>;
  sort: string;
}

export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const localSettingsStateSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.localSettings
);
