import { Injectable } from '@angular/core';
import { SaleQueryParameters } from '@quorum/models/xs-query';
import { Store, select } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import * as fromDeliveries from './deliveries.actions';
import { isDeliveriesLoadingSelector, selectAll as selectProspectsCombined } from './deliveries.interfaces';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class DeliveriesStateService {
  constructor(private store: Store<any>) {}

  selectDeliveriesCombined() {
    return combineLatest(this.store.pipe(select(selectProspectsCombined)), this.selectDeliveriesLoading()).pipe(
      map(([deliveries, isLoading]) => {
        return { deliveries, isLoading };
      }),
      filter(result => {
        return result.isLoading == false;
      }),
      map(result => {
        return new DeepCopyPipe().transform(result);
      })
    );
  }

  selectDeliveriesLoading() {
    return this.store.pipe(select(isDeliveriesLoadingSelector));
  }

  getDeliveriesFromServer(queryParams: SaleQueryParameters) {
    this.store.dispatch(new fromDeliveries.GetDeliveriesFromServer(queryParams));
  }

  clearDeliveriesFromState() {
    this.store.dispatch(new fromDeliveries.ClearDeliveriesFromState());
  }
}
