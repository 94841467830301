import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CompareObjectsPipe } from '@quorum/common-pipes';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { Prospect } from '@quorum/models/xs-resource';
import { getSelectors } from '@quorum/xsr-entities/state';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { localSettingsStateSelector } from './local-settings/local-settings.interfaces';
import * as fromProspects from './prospects/prospects.actions';
import { salesplannerLegacyFeatureSelector } from './salesplanner-legacy.interfaces';
import { selectedProspectSelector } from './selected-activity/selected-activity.interfaces';
@Injectable()
export class SalesPlannerStateService {
  private toastSubject = new Subject<any>();
  constructor(private store: Store<any>) {}

  selectProspectsArray() {
    const { selectAll } = getSelectors(salesplannerLegacyFeatureSelector);
    return this.store.pipe(select(selectAll));
  }

  selectProspectsEntity() {
    const { selectEntities } = getSelectors(salesplannerLegacyFeatureSelector);
    return this.store.pipe(
      select(selectEntities),
      filter((prospects: any) => {
        return !this.isEmpty(prospects);
      })
    );
  }

  selectProspectsPageDetails() {
    const { prospectsPageDetailsSelector } = getSelectors(salesplannerLegacyFeatureSelector);
    return this.store.pipe(select(prospectsPageDetailsSelector));
  }

  selectLocalSettings() {
    return this.store.pipe(select(localSettingsStateSelector));
  }

  selectPageOfProspects() {
    return combineLatest(
      this.selectProspectsArray(),
      this.selectProspectsPageDetails(),
      this.selectLocalSettings()
    ).pipe(
      map(([prospects, pageDetails, localSettings]) => {
        const lowerBound = pageDetails.activePage * pageDetails.pageSize;
        const upperBound = pageDetails.activePage * pageDetails.pageSize + pageDetails.pageSize;
        return prospects
          .sort((a, b) => this.sortFunction(a, b, localSettings.prospectFilter.sort))
          .filter((prospects, index) => {
            return index >= lowerBound && index <= upperBound;
          });
      })
    );
  }

  sortFunction(a: Prospect, b: Prospect, sort: string) {
    if (sort === '-enteredDate') {
      return +new Date(b.enteredDate) - +new Date(a.enteredDate);
    } else {
      if (a.updatedDate === null && b.updatedDate === null) {
        return 0;
      }
      if (a.updatedDate === null) {
        return 1;
      }
      if (b.updatedDate === null) {
        return -1;
      }
      return +new Date(b.updatedDate) - +new Date(a.updatedDate);
    }
  }

  selectProspectAfterUpdated(prospect: Prospect) {
    return this.store.pipe(
      select(selectedProspectSelector),
      filter((pros) => new CompareObjectsPipe().transform(pros, prospect) == true),
      take(1)
    );
  }

  checkIfProspectPageExistsInState(queryParams: ProspectQueryParameters) {
    this.store.dispatch(new fromProspects.CheckIfProspectPageExistsInState(queryParams));
  }

  clearProspectsFromState() {
    this.store.dispatch(new fromProspects.ClearProspectsFromState());
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  addToast(toastObject: any) {
    this.toastSubject.next(toastObject);
  }

  listenForToasts() {
    return this.toastSubject;
  }
}
