import { Action } from '@ngrx/store';
import { ProspectQueryParameters } from '@quorum/models/xs-query';

export const CHECK_IF_PROSPECT_PAGE_EXISTS_IN_STATE = '[CRM] Check If Prospect Page Exists In State';
export class CheckIfProspectPageExistsInState implements Action {
  readonly feature = 'crm';
  readonly type = CHECK_IF_PROSPECT_PAGE_EXISTS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectQueryParameters) {}
}

export const UPDATE_PROSPECT_PAGE_DETAILS_IN_STATE = '[CRM] Update Prospect Page Details In State';
export class UpdateProspectPageDetailsInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_PAGE_DETAILS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectQueryParameters) {}
}

export const CLEAR_PROSPECTS_FROM_STATE = '[CRM] Clear Prospects From State';
export class ClearProspectsFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_PROSPECTS_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export type ProspectsAction =
  | CheckIfProspectPageExistsInState
  | ClearProspectsFromState
  | UpdateProspectPageDetailsInState;
