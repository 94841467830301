import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { NgPipesModule } from 'ngx-pipes';
import { ActivityListFilterComponent } from './activity-list-filter/activity-list-filter.component';
import { ActivityListItemComponent } from './activity-list-item/activity-list-item.component';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { CreateAppointmentDialogComponent } from './create-appointment-dialog/create-appointment-dialog.component';
import { CreateTaskDialogComponent } from './create-task-dialog/create-task-dialog.component';
import { DeliveryCardComponent } from './delivery-card/delivery-card.component';
import { ServiceCardComponent } from './service-card/service-card.component';
import { TaskCardComponent } from './task-card/task-card.component';
import { PromoCardComponent } from './promo-card/promo-card.component';
import { ActivitiesFilterPipe } from './activities-filter.pipe';
import { ActivityPurchaseOrderDetailsComponent } from './activity-purchase-order-details/activity-purchase-order-details.component';

@NgModule({
  imports: [
    CommonModule,
    CommonPipesModule,
    CommonMaterialComponentsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
  ],
  exports: [
    ActivityListComponent,
    ActivityListItemComponent,
    ActivityListFilterComponent,
    AppointmentCardComponent,
    TaskCardComponent,
    ServiceCardComponent,
    DeliveryCardComponent,
    PromoCardComponent,
    ActivitiesFilterPipe,
    ActivityPurchaseOrderDetailsComponent,
  ],
  declarations: [
    ActivityListComponent,
    ActivityListItemComponent,
    ActivityListFilterComponent,
    CreateTaskDialogComponent,
    CreateAppointmentDialogComponent,
    TaskCardComponent,
    AppointmentCardComponent,
    DeliveryCardComponent,
    ServiceCardComponent,
    PromoCardComponent,
    ActivitiesFilterPipe,
    ActivityPurchaseOrderDetailsComponent,
  ],
})
export class CrmUiNgActivitiesModule {}
