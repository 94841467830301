import { combineReducers } from '@ngrx/store';
import { reducer as activitiesReducer } from './activities/activities.reducer';
import { reducer as localSettingsReducer } from './local-settings/local-settings.reducer';
import { prospectsReducer } from './prospects/prospects.reducer';
import { initialState, SalesplannerLegacyState } from './salesplanner-legacy.interfaces';
import { selectedActivityReducer } from './selected-activity/selected-activity.reducer';
import { reducer as uiSettingsReducer } from './ui/ui.reducer';
import { vehicleSearchReducer } from './vehicle-search/vehicle-search.reducer';
import * as fromSalesPlannerLegacy from './salesplanner-legacy.actions';
import { salesplannerLegacyInitialState } from './salesplanner-legacy.init';

export function salesplannerLegacyReducer(state: any = false, action: any): any {
  switch (action.type) {
    case fromSalesPlannerLegacy.UPDATE_FEATURE_LOADING_STATE: {
      return action.payload.loaded;
      break;
    }
    case fromSalesPlannerLegacy.RESET_STATE: {
      return false;
      break;
    }
    default: {
      return state;
      break;
    }
  }
}

const myReducers = {
  featureLoaded: salesplannerLegacyReducer,
  prospects: prospectsReducer,
  activities: activitiesReducer,
  selectedActivity: selectedActivityReducer,
  localSettings: localSettingsReducer,
  ui: uiSettingsReducer,
  vehicleSearch: vehicleSearchReducer
};

export function createReducer(asyncReducers = {}) {
  let allReducers = Object.assign(myReducers, asyncReducers);
  return combineReducers(allReducers);
}

export function appReducer(state: any, action: any) {
  return createReducer()(state, action);
}

export const reducer = combineReducers(myReducers);

export function reducerA(state: any, action: any) {
  return reducer(state, action);
}
