export class CustomerSearchQueryParameters {
  actioned?: string;
  associateId?: string;
  emailAddress?: string;
  equityThreshold?: number;
  inEquity?: string;
  name?: string;
  page?: number;
  pageSize?: number;
  rooftopId?: string;
  salesperson?: string;
  salespersonId?: string[];
  saleTypeId?: string[];
  sort?: string;
  termFrom?: number;
  termTo?: number;
  userEmployeeId?: string;

  constructor(obj?: any) {
    if (obj) Object.assign(this, obj);
  }
}
