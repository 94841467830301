export class ProspectQueryParameters {
  actionId?: number[];
  actionWhenLost?: number;
  associateId?: string;
  cellPhoneNumber?: string; 
  channelId?: string[];
  closeActivities?: boolean;
  closedById?: string;
  conversionRecordId?: string;
  customerTypeId?: string;
  embed?: Array<string>;
  emailAddress?: string;
  employeeCreatedId?: string;
  employeeId?: string[];
  enteredDateFrom?: Date;
  enteredDateTo?: Date;
  id?: string;
  leadOutcomeId?: number;
  matchedDateFrom?: Date;
  matchedDateTo?: Date;
  newSaleId?: number;
  rooftopId?: number[];
  osNotesFlag?: string;
  page?: number;
  pageSize?: number;
  phoneNumber?: string;
  previousEmployeeId?: string;
  probabilityFlag?: string;
  programName?: string;
  saleId?: number;
  search?: string;
  sort?: string;
  sourceId?: string[];
  statusId?: number[];
  typeId?: number[];
  updatedDateFrom?: Date;
  updatedDateTo?: Date;
  usedSearchStockFlag?: string;
  vehicleDescription?: string;
  vehicleId?: number;
  vendorId?: string[];
  vendorLeadId?: string[];
  [key: string]: string | number | boolean | string[] | Date | number[];

  constructor(obj?: ProspectQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
