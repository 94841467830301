<div fxLayout="column" fxFlexFill>
  <h2 mat-dialog-title>Create A Prospect</h2>

  <div mat-dialog-content fxLayout="column">
    <mat-vertical-stepper #stepper linear (selectionChange)="stepperScroll($event)">
      <mat-step label="Prospect Type">
        <div fxLayout="column" fxLayoutGap="8px">
          <span>Is this a freeform prospect?</span>
          <mat-radio-group fxLayout="column" fxLayoutGap="12px" [(ngModel)]="freeform"
            (change)="reInitFormFields(freeform,emailRequired);stepper.next();">
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </mat-step>

      <mat-step *ngIf="!freeform" [completed]="autoCompleteSelected">
        <form fxLayout="row wrap" fxLayoutAlign="start start" [formGroup]="associateForm">
          <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start">
            <ng-template matStepLabel>Associate Information</ng-template>
            <span class="mat-caption" fxFlex="100">Please enter a name and select an existing associate, or Add New,
              from the Name field
              dropdown to continue.</span>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <div class="flex" *ngIf="filteredAssociates$ | async as filteredAssociates">
                <input #associateName matInput autocomplete="off" placeholder="Name (Last, First)"
                  aria-label="Associate" [matAutocomplete]="auto" formControlName="name" [required]="assocRequired">
                <div class="flex justify-end items-center ml-2 mr-1" *ngIf="associateLookupComplete === false">
                  <div style="border-top-color: transparent !important; border-width: 3px;"
                    class="border-solid animate-spin rounded-full border-grey-300 h-4 w-4"></div>
                </div>
                <mat-autocomplete #auto="matAutocomplete"
                  (optionSelected)="queryProspects('associateId', $event.option.value, queryParamSub$, filteredAssociates, data.dropdowns.provStates); autocompleteSelected(true);"
                  [displayWith]="displayAssociateName.bind(auto,filteredAssociates, associateForm.value.name)">
                  <div *ngIf="associateLookupComplete">
                    <mat-option value='0' *ngIf="associateForm.value.name && associateForm.value.name.length > 0">
                      -- Add New --
                    </mat-option>
                    <mat-option *ngFor="let associate of filteredAssociates | orderBy: ['id']" [value]="associate.id">
                      <span>{{associate.nameAddress}}</span>
                    </mat-option>
                  </div>
                </mat-autocomplete>
              </div>
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <mat-select placeholder="Class" name="classId" formControlName="classId">
                <mat-option *ngFor="let associateClass of data.dropdowns.associateClasses | orderBy: 'name'"
                  [value]="associateClass.id">
                  {{associateClass.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input matInput autocomplete="off" name="email" placeholder="Email" formControlName="emailAddress"
                [required]="emailRequired">
            </mat-form-field>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button color="primary" matStepperNext [disabled]="!autoCompleteSelected">Next</button>
          </div>
        </form>
      </mat-step>

      <mat-step *ngIf="!freeform" [stepControl]="contactForm">
        <form fxLayout="row wrap" fxLayoutAlign="start start" [formGroup]="contactForm">
          <div fxFlex fxLayout="row wrap" fxLayoutAlign="start start">
            <ng-template matStepLabel>Contact Information</ng-template>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input matInput autocomplete="off" [textMask]="{mask: phoneNumberMask, guide: false}"
                name="cellPhoneNumber" placeholder="Cell Phone" formControlName="cellPhoneNumber"
                [required]="cellPhoneRequired">
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input matInput autocomplete="off" [textMask]="{mask: phoneNumberMask, guide: false}"
                name="homePhoneNumber" placeholder="Main Phone" formControlName="homePhoneNumber" required>
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input matInput autocomplete="off" name="addressLine1" placeholder="Address"
                formControlName="addressLine1" required>
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input formControlName="provStateId" type="text" placeholder="Province/State" matInput required
                [matAutocomplete]="autoProvinceState">
              <mat-autocomplete #autoProvinceState="matAutocomplete" [displayWith]="displayProvStateName">
                <mat-option *ngFor="let provinceState of filteredProvStates$ | async | orderBy:'name'"
                  [value]="provinceState.name" (click)="provinceStateSelected(provinceState)">
                  {{provinceState.name}}</mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="contactForm.get('provStateId').errors">
                <div> {{contactForm.get('provStateId').errors?.invalidOption}} </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input #location formControlName="locationId" type="text" placeholder="Location" matInput required
                [matAutocomplete]="autoLocation">
              <mat-autocomplete #autoLocation="matAutocomplete" [displayWith]="displayLocation" required>
                <mat-option *ngFor="let location of filteredLocations"
                  (click)="setPostalCode(location.postalCode);locationSelected(location)" [value]="location">
                  {{location.description}} {{location.provStateId}}, {{location.countryId}}</mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="contactForm.get('locationId').errors">
                <div> {{contactForm.get('locationId').errors?.invalidOption}} </div>
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="30" fxFlex.xs="100">
              <input matInput autocomplete="off" name="postalZipCode" placeholder="Postal/Zip Code"
                formControlName="postalZipCode" required>
            </mat-form-field>
          </div>
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button color="primary" matStepperNext [disabled]="contactForm.invalid">Next</button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="prospectForm">
        <ng-template matStepLabel>Prospect Information</ng-template>
        <form fxLayout="row wrap" fxLayoutAlign="start center" [formGroup]="prospectForm">
          <mat-form-field fxFlex="30" fxFlex.xs="100" *ngIf="freeform">
            <input matInput autocomplete="off" #freeformName name="freeformName" formControlName="freeformName"
              placeholder="Name (Last, First)" required>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="Salesperson" name="primaryEmployeeId" formControlName="primaryEmployeeId" required>
              <mat-option [value]="data.dropdowns.salespeople.employeeId.EmployeeId">
                {{data.dropdowns.salespeople.employeeId.EmployeeName}}</mat-option>
              <mat-optgroup *ngFor="let salesTeam of data.dropdowns.salespeople.teams" [label]="salesTeam.teamName">
                <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                  {{salesperson.EmployeeName}}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="Secondary Salesperson" name="secondaryEmployeeId"
              formControlName="secondaryEmployeeId">
              <mat-option [value]="data.dropdowns.salespeople.employeeId.EmployeeId">
                {{data.dropdowns.salespeople.employeeId.EmployeeName}}</mat-option>
              <mat-optgroup *ngFor="let salesTeam of data.dropdowns.salespeople.teams" [label]="salesTeam.teamName">
                <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                  {{salesperson.EmployeeName}}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="Rooftop" name="rooftopId" formControlName="rooftopId" required>
              <mat-option *ngFor="let rooftop of data.dropdowns.rooftops" [value]="rooftop.id">{{rooftop.displayCode}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100" *ngIf="freeform">
            <input matInput autocomplete="off" [textMask]="{mask: phoneNumberMask, guide: false}" name="phoneNumber"
              placeholder="Main Phone" formControlName="phoneNumber" required>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100" *ngIf="freeform">
            <input matInput autocomplete="off" name="emailAddress" placeholder="Email" formControlName="emailAddress"
              [required]="emailRequired">
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="C/Type" name="customerTypeId" formControlName="customerTypeId">
              <mat-option value="3">Existing</mat-option>
              <mat-option value="1">New</mat-option>
              <mat-option value="2">Return</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="Channel" name="channelId" formControlName="channelId" required>
              <mat-option *ngFor="let channel of data.dropdowns.channels | orderBy: 'description'" [value]="channel.id">
                {{channel.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="Source" name="sourceId" formControlName="sourceId" required>
              <mat-optgroup>
                <label fxFlex>
                  Dealer Sources
                </label>
                <mat-option
                  *ngFor="let source of data.dropdowns.sources | orderBy: ['description'] | filterBy: ['dealerAdded']: true"
                  [value]="source.id">
                  {{source.description}}
                </mat-option>
              </mat-optgroup>

              <mat-optgroup disabled>
                <label fxFlex>
                  Vendor Sources
                </label>
                <mat-option
                  *ngFor="let source of data.dropdowns.sources | orderBy: ['description'] | filterBy: ['dealerAdded']: false"
                  [value]="source.id">
                  {{source.description}}
                </mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30" fxFlex.xs="100">
            <mat-select placeholder="Action" name="actionId" formControlName="actionId">
              <mat-option *ngFor="let prospectAction of data.dropdowns.prospectActions | orderBy: 'description'"
                [value]="prospectAction.id">
                {{prospectAction.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <div *ngIf="localSettings$ | async as localSettings">
          <div fxLayout="column" *ngIf="leads$ | async as leads">
            <ng-container *ngIf="leads.length > 0">
              <h3 style="font-weight:500;margin-bottom:2px;margin-top:8px;" fxLayoutAlign="start center">
                Existing Leads
              </h3>
              <span class="mat-caption">Select an existing lead to open it.</span>

              <div fxLayout="row wrap" id="leadsList">
                <div *ngFor="let prospect of leads | orderBy: [ '-prospectEnteredDate']" fxFlex="32" fxFlex.xs="100"
                  (click)="selectProspect(prospect, localSettings)" matRipple matRippleColor="#e8f0f970"
                  fxLayout="column" [ngClass]="{'card-container': true}">
                  <div class="card-header-container" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" class="title-container">
                      <div class="header-icon" [ngClass]="'lead'" fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon
                          *ngIf="prospectSearchTypeLookup.associate.indexOf(prospect.id) > -1 || prospectSearchTypeLookup.name.indexOf(prospect.id) > -1">
                          person</mat-icon>
                        <mat-icon *ngIf="prospectSearchTypeLookup.email.indexOf(prospect.id) > -1">email</mat-icon>
                        <mat-icon *ngIf="prospectSearchTypeLookup.phone.indexOf(prospect.id) > -1">call</mat-icon>
                      </div>
                      <div fxLayout="column">
                        <span class="title mat-body-2"
                          *ngIf="prospect.prospectFirstName; else ownFreeformName">{{[prospect.prospectLastName,
                          prospect?.prospectFirstName] | nameSafety}}</span>
                        <ng-template #ownFreeformName>
                          <span class="title mat-body-2">{{prospect.prospectFreeformName}}</span>
                        </ng-template>
                        <span class="mat-body-2 secondary-text"
                          *ngIf="prospect.vendorName !== null">{{prospect.vendorName}} lead - {{prospect.matched ==
                          false ?
                          'Unmatched' : 'Matched'}}</span>
                        <span class="mat-body-2 secondary-text" *ngIf="prospect.salespersonFirstName; else unassigned">
                          {{prospect.salespersonFirstName && prospect.salespersonLastName ?
                          prospect.salespersonFirstName + ' ' + prospect.salespersonLastName :
                          prospect.salespersonFirstName}}</span>
                        <ng-template #unassigned>
                          <span class="mat-body-2 secondary-text">
                            Unassigned</span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="card-content-container" fxLayout="column">
                    <div fxLayout="column">
                      <ng-container *ngIf="prospect.prospectTypeId == 3;else retentionDates">
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Updated on
                            {{prospect.prospectUpdatedDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Entered on
                            {{prospect.prospectEnteredDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                      </ng-container>
                      <ng-template #retentionDates>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Available on
                            {{prospect.prospectAvailableDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Sold on {{prospect.contractDate | moment: 'dddd, MMM Do, YYYY'}}
                            (ID: {{prospect.saleId}})</span>
                        </div>
                      </ng-template>
                      <div fxLayoutAlign="start center" class="card-line">
                        <mat-icon class="card-icon secondary-text">location_on</mat-icon>
                        <span class="secondary-text">{{prospect.addressLine1 && prospect.locationName &&
                          prospect.provStateId ? prospect.addressLine1 + ', ' + prospect.locationName + ', ' +
                          prospect.provStateId : 'No address provided'}}</span>
                      </div>
                      <div fxLayoutAlign="start center" class="card-line">
                        <mat-icon class="card-icon secondary-text">email</mat-icon>
                        <span class="secondary-text">{{prospect.prospectEmailAddress ? prospect.prospectEmailAddress :
                          'No email provided'}}</span>
                      </div>

                      <div *ngIf="prospect.associateId; else freeformProspect">
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.associateCellPhoneNumber; else noCellPhone">
                            C: {{prospect.associateCellPhoneNumber| phoneNumber}}
                          </span>
                          <ng-template #noCellPhone>
                            <span class="secondary-text">No cell phone provided</span>
                          </ng-template>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.associateHomePhoneNumber; else noHomePhone">
                            M: {{prospect.associateHomePhoneNumber | phoneNumber}}
                          </span>
                          <ng-template #noHomePhone>
                            <span class="secondary-text">No main phone provided</span>
                          </ng-template>
                        </div>
                      </div>
                      <ng-template #freeformProspect>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.prospectPhoneNumber; else noHomePhone">
                            {{prospect.prospectPhoneNumber| phoneNumber}}
                          </span>
                          <ng-template #noHomePhone>
                            <span class="secondary-text">No main phone provided</span>
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>


          <div fxLayout="column" *ngIf="ownProspects$ | async as ownProspects">
            <ng-container *ngIf="ownProspects.length > 0">
              <h3 style="font-weight:500;margin-bottom:2px;margin-top:8px;" fxLayoutAlign="start center">
                Your Existing Prospects
              </h3>
              <span class="mat-caption">Select an existing prospect to open it.</span>

              <div fxLayout="row wrap" id="ownProspectsList">
                <div *ngFor="let prospect of ownProspects | orderBy: [ '-prospectEnteredDate']" fxFlex="32"
                  fxFlex.xs="100" (click)="selectProspect(prospect, localSettings)" matRipple matRippleColor="#e8f0f970"
                  fxLayout="column" [ngClass]="{'card-container': true}">
                  <div class="card-header-container" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" class="title-container">
                      <div class="header-icon" [ngClass]="prospect.prospectTypeId == 3 ? 'showroom' : 'retention'"
                        fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon *ngIf="prospectSearchTypeLookup.associate.indexOf(prospect.id) > -1">person</mat-icon>
                        <mat-icon *ngIf="prospectSearchTypeLookup.email.indexOf(prospect.id) > -1">email</mat-icon>
                        <mat-icon *ngIf="prospectSearchTypeLookup.phone.indexOf(prospect.id) > -1">call</mat-icon>
                      </div>
                      <div fxLayout="column">
                        <span class="title mat-body-2"
                          *ngIf="prospect.prospectFirstName; else ownFreeformName">{{[prospect.prospectLastName,
                          prospect?.prospectFirstName] | nameSafety}}</span>
                        <ng-template #ownFreeformName>
                          <span class="title mat-body-2">{{prospect.prospectFreeformName}}</span>
                        </ng-template>
                        <span class="mat-body-2 secondary-text"
                          *ngIf="prospect.vendorName !== null">{{prospect.vendorName}} lead - {{prospect.matched ==
                          false ?
                          'Unmatched' : 'Matched'}}</span>
                        <span class="mat-body-2 secondary-text">
                          {{prospect.upcomingAppointmentId ? 'Upcoming Appointment': 'No Upcoming Appointments'
                          }}</span>
                      </div>

                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="card-content-container" fxLayout="column">
                    <div fxLayout="column">
                      <ng-container *ngIf="prospect.prospectTypeId == 3;else retentionDates">
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Updated on
                            {{prospect.prospectUpdatedDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Entered on
                            {{prospect.prospectEnteredDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                      </ng-container>
                      <ng-template #retentionDates>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Available on
                            {{prospect.prospectAvailableDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Sold on {{prospect.contractDate | moment: 'dddd, MMM Do, YYYY'}}
                            (ID: {{prospect.saleId}})</span>
                        </div>
                      </ng-template>
                      <div fxLayoutAlign="start center" class="card-line">
                        <mat-icon class="card-icon secondary-text">location_on</mat-icon>
                        <span class="secondary-text">{{prospect.addressLine1 && prospect.locationName &&
                          prospect.provStateId ? prospect.addressLine1 + ', ' + prospect.locationName + ', ' +
                          prospect.provStateId : 'No address provided'}}</span>
                      </div>
                      <div fxLayoutAlign="start center" class="card-line">
                        <mat-icon class="card-icon secondary-text">email</mat-icon>
                        <span class="secondary-text">{{prospect.prospectEmailAddress ? prospect.prospectEmailAddress :
                          'No email provided'}}</span>
                      </div>
                      <div *ngIf="prospect.associateId; else freeformProspect">
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.associateCellPhoneNumber; else noCellPhone">
                            C: {{prospect.associateCellPhoneNumber| phoneNumber}}
                          </span>
                          <ng-template #noCellPhone>
                            <span class="secondary-text">No cell phone provided</span>
                          </ng-template>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.associateHomePhoneNumber; else noHomePhone">
                            M: {{prospect.associateHomePhoneNumber | phoneNumber}}
                          </span>
                          <ng-template #noHomePhone>
                            <span class="secondary-text">No main phone provided</span>
                          </ng-template>
                        </div>
                      </div>
                      <ng-template #freeformProspect>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.prospectPhoneNumber; else noHomePhone">
                            {{prospect.prospectPhoneNumber| phoneNumber}}
                          </span>
                          <ng-template #noHomePhone>
                            <span class="secondary-text">No main phone provided</span>
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <div fxLayout="column" *ngIf="othersProspects$ | async as othersProspects">
            <ng-container *ngIf="othersProspects.length > 0">
              <div fxLayout="row" fxLayoutAlign="start center">
                <h3 style="color:#E1322F;font-weight:500;margin-bottom:2px;margin-top:8px;">Other Salesperson's Existing
                  Prospects - See Manager
                </h3>
              </div>
              <span fxHide.xs class="mat-caption">The following prospects are
                already linked to other salespeople. If
                you create a new prospect that belongs to another salesperson, a notification will be sent to alert your
                management team.</span>

              <div id="othersExistingProspectsList" fxLayout="row wrap">
                <div
                  *ngFor="let prospect of othersProspects | orderBy: ['-prospectUpdatedDate', '-prospectEnteredDate']"
                  fxFlex="32" fxFlex.xs="100" (click)="selectProspect(prospect, localSettings)" matRipple
                  matRippleColor="#e8f0f970" fxLayout="column" [ngClass]="{'card-container': true}">
                  <div class="card-header-container" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center" class="title-container">
                      <div class="header-icon"
                        [ngClass]="prospect.prospectTypeId == 3 ? prospect.vendorId !== null ? 'lead' : 'showroom' : 'retention'"
                        fxLayout="row" fxLayoutAlign="center center">
                        <mat-icon *ngIf="prospectSearchTypeLookup.associate.indexOf(prospect.id) > -1">person</mat-icon>
                        <mat-icon *ngIf="prospectSearchTypeLookup.email.indexOf(prospect.id) > -1">email</mat-icon>
                        <mat-icon *ngIf="prospectSearchTypeLookup.phone.indexOf(prospect.id) > -1">call</mat-icon>
                      </div>
                      <div fxLayout="column">
                        <span class="title mat-body-2"
                          *ngIf="prospect.prospectFirstName; else othersFreeformName">{{[prospect.prospectLastName,
                          prospect?.prospectFirstName] | nameSafety}}</span>
                        <ng-template #othersFreeformName>
                          <span class="title mat-body-2">{{prospect.prospectFreeformName}}</span>
                        </ng-template>
                        <span class="mat-body-2 secondary-text"
                          *ngIf="prospect.vendorName !== null">{{prospect.vendorName}} lead - {{prospect.matched ==
                          false ?
                          'Unmatched' : 'Matched'}}</span>
                        <span class="mat-body-2 secondary-text">
                          {{prospect.salespersonFirstName && prospect.salespersonLastName ?
                          prospect.salespersonFirstName + ' ' + prospect.salespersonLastName :
                          prospect.salespersonFirstName}}</span>
                      </div>
                    </div>
                  </div>
                  <mat-divider></mat-divider>
                  <div class="card-content-container" fxLayout="column">
                    <div fxLayout="column">
                      <ng-container *ngIf="prospect.prospectTypeId == 3;else retentionDates">
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Updated on
                            {{prospect.prospectUpdatedDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Entered on
                            {{prospect.prospectEnteredDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                      </ng-container>
                      <ng-template #retentionDates>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Available on
                            {{prospect.prospectAvailableDate | moment: 'dddd, MMM Do, YYYY'}}</span>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">event</mat-icon>
                          <span class="secondary-text"> Sold on {{prospect.contractDate | moment: 'dddd, MMM Do, YYYY'}}
                            (ID: {{prospect.saleId}})</span>
                        </div>
                      </ng-template>
                      <div fxLayoutAlign="start center" class="card-line">
                        <mat-icon class="card-icon secondary-text">location_on</mat-icon>
                        <span class="secondary-text">{{prospect.addressLine1 && prospect.locationName &&
                          prospect.provStateId ? prospect.addressLine1 + ', ' + prospect.locationName + ', ' +
                          prospect.provStateId : 'No address provided'}}</span>
                      </div>
                      <div fxLayoutAlign="start center" class="card-line">
                        <mat-icon class="card-icon secondary-text">email</mat-icon>
                        <span class="secondary-text">{{prospect.prospectEmailAddress ? prospect.prospectEmailAddress :
                          'No email provided'}}</span>
                      </div>
                      <div *ngIf="prospect.associateId; else freeformProspect">
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.associateCellPhoneNumber; else noCellPhone">
                            C: {{prospect.associateCellPhoneNumber| phoneNumber}}
                          </span>
                          <ng-template #noCellPhone>
                            <span class="secondary-text">No cell phone provided</span>
                          </ng-template>
                        </div>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.associateHomePhoneNumber; else noHomePhone">
                            M: {{prospect.associateHomePhoneNumber | phoneNumber}}
                          </span>
                          <ng-template #noHomePhone>
                            <span class="secondary-text">No main phone provided</span>
                          </ng-template>
                        </div>
                      </div>
                      <ng-template #freeformProspect>
                        <div fxLayoutAlign="start center" class="card-line">
                          <mat-icon class="card-icon secondary-text">phone</mat-icon>
                          <span class="secondary-text" *ngIf="prospect.prospectPhoneNumber; else noHomePhone">
                            {{prospect.prospectPhoneNumber| phoneNumber}}
                          </span>
                          <ng-template #noHomePhone>
                            <span class="secondary-text">No main phone provided</span>
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <button mat-button matStepperPrevious>Back</button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button (click)="cancel()">Cancel</button>

    <button mat-button
      (click)="emitData(data, freeform, othersExistingProspect, authenticatedUser, dialogRef, ownExistingProspect)"
      [disabled]="freeform ? prospectForm.invalid : associateForm.invalid || contactForm.invalid || prospectForm.invalid"
      color="primary">Create</button>
  </div>
</div>