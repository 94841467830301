import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import * as fromSettings from './+state/settings.actions';
import { settingsStateSelector } from './+state/settings.interfaces';
import { map } from 'rxjs/operators';
@Injectable()
export class CrmSettingsStateService {
  constructor(private store: Store<any>) {}

  selectSettingsFromState() {
    return this.store.pipe(
      select(settingsStateSelector),
      map(localSettings => {
        return new DeepCopyPipe().transform(localSettings);
      })
    );
  }

  updateSalesplannerSettingsInState(settings: any) {
    this.store.dispatch(new fromSettings.UpdateCrmSettingsInState(settings));
  }
  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
