import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { CommonPipesModule } from '@quorum/common-pipes';
import { ProspectActionsComponent } from './prospect-actions/prospect-actions.component';
import { ProspectAggregateComponent } from './prospect-aggregate/prospect-aggregate.component';
import { ProspectCreateDialogComponent } from './prospect-create-dialog/prospect-create-dialog.component';
import { ProspectLeadsComponent } from './prospect-leads/prospect-leads.component';
import { ProspectListFilterComponent } from './prospect-list-filter/prospect-list-filter.component';
import { ProspectListItemComponent } from './prospect-list-item/prospect-list-item.component';
import { ProspectListComponent } from './prospect-list/prospect-list.component';
import { ProspectNotesDialogComponent } from './prospect-notes-dialog/prospect-notes-dialog.component';
import { ProspectNotesComponent } from './prospect-notes/prospect-notes.component';
import { ProspectOpenSalesListComponent } from './prospect-open-sales-list/prospect-open-sales-list.component';
import { ProspectResponseLeadsComponent } from './prospect-response-leads/prospect-response-leads.component';
import { ProspectComponent } from './prospect/prospect.component';
import { VehicleAggregateComponent } from './vehicle-aggregate/vehicle-aggregate.component';
import { VehicleServiceHistoryComponent } from './vehicle-service-history/vehicle-service-history.component';
import { ProspectVehicleOfInterestComponent } from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.component';
import { ProspectVehicleOfInterestDialogComponent } from './prospect-vehicle-of-interest-dialog/prospect-vehicle-of-interest-dialog.component';
import { NgPipesModule } from 'ngx-pipes';
import { TextMaskModule } from 'angular2-text-mask';
import {
  TextMessageDialogComponent,
  ShaComponentsNgUiModule,
  EmailTemplateDialogComponent,
} from '@quorum/sha-components-ng-ui';
import { ProspectCloseDialogComponent } from './prospect-close-dialog/prospect-close-dialog.component';
// eslint-disable-next-line max-len
import { ProspectCreateProgressDialogComponent } from './prospect-create-progress-dialog/prospect-create-progress-dialog.component';
import { LeadMatchingDialogComponent } from './lead-matching-dialog/lead-matching-dialog.component';
// eslint-disable-next-line max-len
import { ProspectChooseEmailPhoneDialogComponent } from './prospect-choose-phone-email-dialog/prospect-choose-phone-email-dialog.component';
import { AddProspectVehicleShellComponent } from './add-prospect-vehicle-shell/add-prospect-vehicle-shell.component';
import { ShaVehiclesNgUiModule } from '@quorum/sha-vehicles-ng-ui';
import { AssociateCardComponent } from './associate-card/associate-card.component';
import { ProspectCardComponent } from './prospect-card/prospect-card.component';
import { CrmAssociateCardNgUiModule } from './associate-card.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { CommunicatorNgrxModule } from '@quorum/communicator-ngrx';

@NgModule({
    imports: [
        CommonModule,
        CommonPipesModule,
        FormsModule,
        RouterModule,
        CommonMaterialComponentsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatPaginatorModule,
        NgPipesModule,
        TextMaskModule,
        ShaComponentsNgUiModule,
        MatRippleModule,
        ShaVehiclesNgUiModule,
        MatDividerModule,
        CrmAssociateCardNgUiModule,
        CommunicatorNgrxModule,
    ],
    exports: [
        ProspectListComponent,
        ProspectListItemComponent,
        ProspectListFilterComponent,
        ProspectComponent,
        ProspectAggregateComponent,
        ProspectCreateDialogComponent,
        ProspectActionsComponent,
        ProspectNotesComponent,
        ProspectLeadsComponent,
        ProspectResponseLeadsComponent,
        VehicleAggregateComponent,
        VehicleServiceHistoryComponent,
        ProspectOpenSalesListComponent,
        TextMessageDialogComponent,
        ProspectCloseDialogComponent,
        EmailTemplateDialogComponent,
        ProspectCreateProgressDialogComponent,
        LeadMatchingDialogComponent,
        ProspectChooseEmailPhoneDialogComponent,
        AddProspectVehicleShellComponent,
        ProspectCardComponent,
        ProspectVehicleOfInterestComponent,
        ProspectVehicleOfInterestDialogComponent,
    ],
    declarations: [
        ProspectListComponent,
        ProspectListItemComponent,
        ProspectListFilterComponent,
        ProspectComponent,
        ProspectAggregateComponent,
        ProspectCreateDialogComponent,
        ProspectActionsComponent,
        ProspectNotesComponent,
        ProspectLeadsComponent,
        ProspectResponseLeadsComponent,
        VehicleAggregateComponent,
        ProspectNotesDialogComponent,
        VehicleServiceHistoryComponent,
        ProspectOpenSalesListComponent,
        ProspectCloseDialogComponent,
        ProspectCreateProgressDialogComponent,
        LeadMatchingDialogComponent,
        ProspectChooseEmailPhoneDialogComponent,
        AddProspectVehicleShellComponent,
        ProspectCardComponent,
        ProspectVehicleOfInterestComponent,
        ProspectVehicleOfInterestDialogComponent,
    ]
})
export class CrmProspectsNgUiModule {}
