import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { fromSalesTeams } from '@quorum/crm-dropdowns/state';
import {
  Action as ProspectAction,
  Channel,
  AssociateCustomerType as CustomerType,
  MessageType,
  SalesTeamMaintenance,
  Source,
  TaskOutcome,
} from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromChannels from './channels/channels.actions';
import { ChannelsState } from './channels/channels.interfaces';
import * as fromCustomerTypes from './customer-types/customer-types.actions';
import { CustomerTypesState } from './customer-types/customer-types.interfaces';
import * as fromMessageTypes from './message-types/message-types.actions';
import { MessageTypesState } from './message-types/message-types.interfaces';
import * as fromProspectActions from './prospect-actions/prospect-actions.actions';
import { ProspectActionsState } from './prospect-actions/prospect-actions.interfaces';
import * as fromSalespeople from './salespeople/salespeople.actions';
import * as fromSources from './sources/sources.actions';
import { SourcesState } from './sources/sources.interfaces';
import * as fromTaskOutcomes from './task-outcomes/task-outcomes.actions';

@Injectable()
export class DropdownEffects {
  
  getChannelsFromServer = createEffect(() => this.dataPersistence.fetch(fromChannels.GET_CHANNELS_FROM_SERVER, {
    run: (action: fromChannels.GetChannelsFromServer, state: ChannelsState) => {
      return this.apiService
        .get<Channel[]>(`v/1/crm/prospect-channels`, {
          params: action.payload,
        })
        .pipe(
          map((channels) => {
            return new fromChannels.GetChannelsFromServerSuccess({
              channels: channels,
            });
          })
        );
    },
    onError: (action: fromChannels.GetChannelsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getCustomerTypesFromServer = createEffect(() => this.dataPersistence.fetch(fromCustomerTypes.GET_CUSTOMER_TYPES_FROM_SERVER, {
    run: (action: fromCustomerTypes.GetAssociateCustomerTypesFromServer, state: CustomerTypesState) => {
      return this.apiService
        .get<CustomerType[]>(`v/1/associates/customer-types`, {
          params: action.payload,
        })
        .pipe(
          map((customerTypes) => {
            return new fromCustomerTypes.GetAssociateCustomerTypesFromServerSuccess({
              customerTypes: customerTypes,
            });
          })
        );
    },
    onError: (action: fromCustomerTypes.GetAssociateCustomerTypesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getMessageTypesFromServer = createEffect(() => this.dataPersistence.fetch(fromMessageTypes.GET_MESSAGE_TYPES_FROM_SERVER, {
    run: (action: fromMessageTypes.GetAssociateMessageTypesFromServer, state: MessageTypesState) => {
      return this.apiService
        .get<MessageType[]>(`v/1/associates/message-types`, {
          params: action.payload,
        })
        .pipe(
          map((messageTypes) => {
            return new fromMessageTypes.GetAssociateMessageTypesFromServerSuccess({
              messageTypes: messageTypes,
            });
          })
        );
    },
    onError: (action: fromMessageTypes.GetAssociateMessageTypesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getSalespeopleFromServer = createEffect(() => this.dataPersistence.fetch(fromSalespeople.GET_SALESPEOPLE_FROM_SERVER, {
    run: (action: fromSalespeople.GetSalespeopleFromServer, state: any) => {
      return this.apiService
        .get<ProspectAction[]>(`v/1/crm/legacy/sales-people`, {
          params: action.payload,
        })
        .pipe(
          map((salespeople) => {
            return new fromSalespeople.GetSalespeopleFromServerSuccess({
              salespeople: salespeople,
            });
          })
        );
    },
    onError: (action: fromSalespeople.GetSalespeopleFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getSalesTeamsFromServer = createEffect(() => this.dataPersistence.fetch(fromSalesTeams.GET_SALES_TEAMS_FROM_SERVER, {
    run: (action: fromSalesTeams.GetSalesTeamsFromServer, state: any) => {
      return this.apiService
        .get<SalesTeamMaintenance[]>(`v/1/associates/legacy/sales-team-maintenances`, {
          params: action.payload,
        })
        .pipe(
          map((salesTeams) => {
            return new fromSalesTeams.GetSalesTeamsFromServerSuccess({
              salesTeams: salesTeams,
            });
          })
        );
    },
    onError: (action: fromSalesTeams.GetSalesTeamsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getSourcesFromServer = createEffect(() => this.dataPersistence.fetch(fromSources.GET_SOURCES_FROM_SERVER, {
    run: (action: fromSources.GetSourcesFromServer, state: SourcesState) => {
      return this.apiService
        .get<Source[]>(`v/1/crm/prospect-sources`, {
          params: action.payload,
        })
        .pipe(
          map((sources) => {
            return new fromSources.GetSourcesFromServerSuccess({
              sources: sources,
            });
          })
        );
    },
    onError: (action: fromSources.GetSourcesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getProspectActions = createEffect(() => this.dataPersistence.fetch(fromProspectActions.GET_PROSPECT_ACTIONS_FROM_SERVER, {
    run: (action: fromProspectActions.GetProspectActionsFromServer, state: ProspectActionsState) => {
      return this.apiService
        .get<ProspectAction[]>(`v/1/crm/actions`, {
          params: action.payload,
        })
        .pipe(
          map((actions) => {
            return new fromProspectActions.GetProspectActionsFromServerSuccess({
              actions: actions,
            });
          })
        );
    },
    onError: (action: fromProspectActions.GetProspectActionsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getTaskOutcomes = createEffect(() => this.dataPersistence.fetch(fromTaskOutcomes.GET_TASK_OUTCOMES_FROM_SERVER, {
    run: (action: fromTaskOutcomes.GetTaskOutcomesFromServer, state: ProspectActionsState) => {
      return this.apiService
        .get<TaskOutcome[]>(`v/1/crm/task-outcomes`, {
          params: action.payload,
        })
        .pipe(
          map((taskOutcomes) => {
            return new fromTaskOutcomes.GetTaskOutcomesFromServerSuccess({
              taskOutcomes: taskOutcomes,
            });
          })
        );
    },
    onError: (action: fromTaskOutcomes.GetTaskOutcomesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<any>,
    private apiService: ApiService
  ) {}
}
