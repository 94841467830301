<mat-nav-list>
  <ng-container *ngFor="let workOrder of vehicleServiceHistory | orderBy: ['-dateIn'];let i = index;">
    <mat-list-item [disableRipple]="browserControlCommunicationService.disableRippleEffect" (click)="openVehicleServiceHistory(workOrder.id)">
      <div class="icon-container" mat-list-icon>
        <mat-icon>build</mat-icon>
      </div>
      <h4 mat-line>{{workOrder.id}}</h4>
      <p mat-line class="secondary-text">{{workOrder.dateIn | moment: 'MM/DD/YYYY'}} - Odometer:
        {{workOrder.odometerIn}}</p>
      <p mat-line class="secondary-text">Customer Amount: {{workOrder.totalCharges | currency}}</p>
    </mat-list-item>
    <mat-divider inset *ngIf="i < vehicleServiceHistory.length"></mat-divider>
  </ng-container>
</mat-nav-list>