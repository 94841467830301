<div fxLayout="column" *ngIf="prospectNotes$ | async as notes; else newNote">
  <p class="mat-body-1">{{notes?.notes}}</p>
  <div fxLayout="row" fxLayoutAlign="end center">
    <ng-container *ngIf="currentUser$ | async as currentUser">
      <button mat-raised-button color="primary" (click)="openNotesDialog(dialog, currentUser, prospectId, notes)">Add</button>
    </ng-container>
  </div>
</div>
<ng-template #newNote>
  <div fxLayout="row" fxLayoutAlign="end center">
    <ng-container *ngIf="currentUser$ | async as currentUser">
      <button [disabled]="disableAddAction" mat-raised-button color="primary" (click)="openNotesDialog(dialog, currentUser, prospectId, null)">Add</button>
    </ng-container>
  </div>
</ng-template>