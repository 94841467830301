export class SystemControlValueQueryParameters {
  constantReference?: string;
  id?: string;
  page?: number;
  pageSize?: number;
  rooftopId?: string;

  constructor(obj?: SystemControlValueQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
