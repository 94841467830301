import { Action } from '@ngrx/store';
import { SalesPeopleQueryParameters } from '@quorum/models/xs-query';

export const GET_SALESPEOPLE_FROM_SERVER = '[CRM] Get Salespeople From Server';
export class GetSalespeopleFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALESPEOPLE_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SalesPeopleQueryParameters) {}
}

export const GET_SALESPEOPLE_FROM_SERVER_SUCCESS = '[CRM] Get Salespeople From Server Success';
export class GetSalespeopleFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALESPEOPLE_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { salespeople: any }) {}
}

export const GET_SALESPEOPLE_FROM_SERVER_FAILURE = '[CRM] Get Salespeople From Server Failure';
export class GetSalespeopleFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALESPEOPLE_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type SalespeopleAction =
  | GetSalespeopleFromServer
  | GetSalespeopleFromServerSuccess
  | GetSalespeopleFromServerFailure;
