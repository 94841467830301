import * as fromVehicleGenericModels from './vehicle-generic-models.actions';
import {
  vehicleGenericModelsAdapter,
  VehicleGenericModelsState,
  initialState
} from './vehicle-generic-models.interfaces';

export function reducer(
  state: VehicleGenericModelsState = initialState,
  action: fromVehicleGenericModels.VehicleGenericModelsAction
): VehicleGenericModelsState {
  switch (action.type) {
    case fromVehicleGenericModels.GET_VEHICLE_GENERIC_MODELS_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleGenericModelsAdapter.addMany(action.payload.vehicleGenericModels, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
