<div fxLayout="column" fxFlexFill>
  <h1 mat-dialog-title>New Task</h1>
  <div mat-dialog-content fxFlex.xs>
    <form fxLayout="column" fxLayoutAlign="center center" [formGroup]="taskForm">
      <mat-form-field>
        <input
          matInput
          autocomplete="off"
          formControlName="dueDate"
          name="due date"
          [matDatepicker]="picker"
          placeholder="Date"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-select placeholder="Employee" name="employeeId" formControlName="taskEmployeeId" required>
          <mat-option [value]="data.dropdowns.salespeople.employeeId.EmployeeId">
            {{ data.dropdowns.salespeople.employeeId.EmployeeName }}</mat-option
          >
          <mat-optgroup *ngFor="let salesTeam of data.dropdowns.salespeople.teams" [label]="salesTeam.teamName">
            <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
              {{ salesperson.EmployeeName }}</mat-option
            >
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <textarea
          id="task-objectives-text-area"
          name="objectives"
          autocomplete="false"
          rows="7"
          matInput
          placeholder="Objectives"
          formControlName="note"
          required
        ></textarea>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button [mat-dialog-close]="">Cancel</button>
    <button mat-button color="primary" (click)="onSubmit()" [disabled]="!taskForm.valid">Create</button>
  </div>
</div>
