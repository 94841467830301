import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Sale } from '@quorum/models/xs-resource';

export interface ProspectDeliveriesState extends EntityState<Sale> {}

export const prospectDeliveriesAdapter: EntityAdapter<Sale> = createEntityAdapter<Sale>({
  selectId: (delivery: Sale) => delivery.id
});

export const initialState: ProspectDeliveriesState = prospectDeliveriesAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const prospectDeliveriesSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectDeliveries
);

export const { selectIds, selectEntities, selectAll, selectTotal } = prospectDeliveriesAdapter.getSelectors(
  prospectDeliveriesSelector
);
