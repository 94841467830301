import { PurchaseOrderDetail } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ActivityPurchaseOrderDetailsState extends EntityState<PurchaseOrderDetail> {}

export const activityPurchaseOrderDetailsAdapter: EntityAdapter<PurchaseOrderDetail> =
  createEntityAdapter<PurchaseOrderDetail>({
    selectId: (activityPurchaseOrderDetails: PurchaseOrderDetail) => activityPurchaseOrderDetails.id,
  });

export const initialState: ActivityPurchaseOrderDetailsState = activityPurchaseOrderDetailsAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const selectedActivityPurchaseOrderDetailsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.activityPurchaseOrderDetails
);

export const { selectIds, selectEntities, selectAll, selectTotal } = activityPurchaseOrderDetailsAdapter.getSelectors(
  selectedActivityPurchaseOrderDetailsSelector
);
