import { Sale } from '@quorum/models/xs-resource';
import { SaleQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER = '[CRM] Get Selected Prospect Open Sales From Server';
export class GetSelectedProspectOpenSalesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SaleQueryParameters) {}
}

export const GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Open Sales From Server Success';
export class GetSelectedProspectOpenSalesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: Sale[]) {}
}

export const GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Open Sales From Server Failure';
export class GetSelectedProspectOpenSalesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER = '[CRM] Update Selected Prospect Open Sale On Server';
export class UpdateSelectedProspectOpenSaleOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: Sale) {}
}

export const UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER_SUCCESS =
  '[CRM] Update Selected Prospect Open Sale On Server Success';
export class UpdateSelectedProspectOpenSaleOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Sale>) {}
}

export const UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER_FAILURE =
  '[CRM] Update Selected Prospect Open Sale On Server Failure';
export class UpdateSelectedProspectOpenSaleOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type OpenSalesActions =
  | GetSelectedProspectOpenSalesFromServer
  | GetSelectedProspectOpenSalesFromServerSuccess
  | GetSelectedProspectOpenSalesFromServerFailure
  | UpdateSelectedProspectOpenSaleOnServer
  | UpdateSelectedProspectOpenSaleOnServerSuccess
  | UpdateSelectedProspectOpenSaleOnServerFailure;
