import { createAction, props } from '@ngrx/store';
import { CommunicatorEntity } from './communicator.models';
import { Conversation, Member, Event } from '@quorum/models/xs-resource';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
export const loadCommunicator = createAction('[Communicator] Load Communicator');

export const loadCommunicatorSuccess = createAction(
  '[Communicator] Load Communicator Success',
  props<{ communicator: CommunicatorEntity[] }>()
);

export const loadCommunicatorFailure = createAction(
  '[Communicator] Load Communicator Failure',
  props<{ error: any }>()
);

export const sendCommunicatorMessage = createAction(
  '[Communicator] Send Communicator message',
  props<{
    conversationQueryParameters: ConversationQueryParameters;
    conversation: Conversation;
    members: Member[];
    event: Event;
    optionalHeader?: any;
  }>()
);

export const checkIfConversationExists = createAction(
  '[Communicator] Check if conversation exists',
  props<{
    conversationQueryParameters: ConversationQueryParameters;
    conversation: Conversation;
    members: Member[];
    event: Event;
    optionalHeader?: any;
  }>()
);

export const checkIfConversationExistsFailure = createAction(
  '[Communicator] Check if conversation exists failure',
  props<{ error: any }>()
);

export const checkIfConversationExistsSuccess = createAction(
  '[Communicator] Check if conversation exists success',
  props<{
    conversation: Conversation;
    members: any;
    event: Event;
    optionalHeader?: any;
  }>()
);

export const sendMessageToConversation = createAction(
  '[Communicator] Send Message to conversation',
  props<{ event: Event; optionalHeader?: any }>()
);
export const sendMessageToConversationSuccess = createAction(
  '[Communicator] Send Message to conversation success',
  props<{ event: Event; optionalHeader?: any }>()
);
export const sendMessageToConversationFailure = createAction(
  '[Communicator] Send Message to conversation failure',
  props<{ error: any }>()
);

export const createConversation = createAction(
  '[Communicator] Create conversation',
  props<{ conversation: Conversation; members: any; event: Event; optionalHeader?: any }>()
);

export const createConversationSuccess = createAction(
  '[Communicator] Create conversation success',
  props<{ conversation: Conversation; members: any[]; event: Event; optionalHeader?: any }>()
);

export const createConversationFailure = createAction(
  '[Communicator] Create conversation failure',
  props<{ error: any }>()
);

export const addMembersToConversation = createAction(
  '[Communicator] Add members to conversation',
  props<{ members: any[]; event: Event; optionalHeader?: any }>()
);

export const addMembersToConversationSuccess = createAction(
  '[Communicator] Add members to conversation success',
  props<{ members: any[]; event: Event; optionalHeader?: any }>()
);

export const addMembersToConversationFailure = createAction(
  '[Communicator] Add members to conversation failure',
  props<{ error: any }>()
);
