import { VehicleStatus as Status } from '@quorum/models/xs-resource';
import { VehicleStatusQueryParameters as StatusQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_STATUSES_FROM_SERVER = '[CRM] Get Vehicle Statuses From Server';
export class GetVehicleStatusesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_STATUSES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: StatusQueryParameters) {}
}

export const GET_VEHICLE_STATUSES_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Statuses From Server Success';
export class GetVehicleStatusesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_STATUSES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleStatuses: Status[] }) {}
}

export const GET_VEHICLE_STATUSES_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Statuses From Server Failure';
export class GetVehicleStatusesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_STATUSES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VehicleStatusesAction =
  | GetVehicleStatusesFromServer
  | GetVehicleStatusesFromServerSuccess
  | GetVehicleStatusesFromServerFailure;
