import { combineReducers } from '@ngrx/store';
import * as fromSelectedProspectOpenSales from './open-sales/open-sales.actions';
import { openSalesAdapter } from './open-sales/open-sales.interfaces';
import * as fromSelectedProspectVehicleOfInterest from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.actions';
import { prospectVehicleOfInterestAdapter } from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.interfaces';
import * as fromSelectedProspectActions from './prospect-actions/prospect-actions.actions';
import { prospectActionsAdapter } from './prospect-actions/prospect-actions.interfaces';
import * as fromSelectedProspectDealerPromotions from './prospect-dealer-promotions/prospect-dealer-promotions.actions';
import { prospectDealerPromotionsdapter } from './prospect-dealer-promotions/prospect-dealer-promotions.interfaces';
import * as fromSelectedProspectNotes from './prospect-notes/prospect-notes.actions';
import { prospectNotesAdapter } from './prospect-notes/prospect-notes.interfaces';
// eslint-disable-next-line max-len
import * as fromSelectedProspectSalesAppointments from './prospect-sales-appointments/prospect-sales-appointments.actions';
import { prospectSalesAppointmentsAdapter } from './prospect-sales-appointments/prospect-sales-appointments.interfaces';
// eslint-disable-next-line max-len
import * as fromSelectedProspectServiceAppointments from './prospect-service-appointments/prospect-service-appointments.actions';
// eslint-disable-next-line max-len
import { prospectServiceAppointmentsAdapter } from './prospect-service-appointments/prospect-service-appointments.interfaces';
import * as fromSelectedProspectTasks from './prospect-tasks/prospect-tasks.actions';
import { prospectTasksAdapter } from './prospect-tasks/prospect-tasks.interfaces';
import * as fromSelectedProspectResponseLeads from './response-leads/response-leads.actions';
import { responseLeadsAdapter } from './response-leads/response-leads.interfaces';
import * as fromSelectedActivity from './selected-activity.actions';
import { selectedActivityInitialState } from './selected-activity.init';
import { SelectedActivityState } from './selected-activity.interfaces';
import * as fromSelectedVehicleServiceHistory from './service-history/service-history.actions';
import { vehicleServiceHistoryAdapter } from './service-history/service-history.interfaces';
import * as fromSelectedProspectVehicles from './vehicles/vehicles.actions';
import { vehiclesAdapter } from './vehicles/vehicles.interfaces';
import { prospectDeliveriesAdapter } from './prospect-deliveries/prospect-deliveries.interfaces';
import * as fromSelectedProspectDeliveries from './prospect-deliveries/prospect-deliveries.actions';
import { initialState as prospectTasksInitialState } from './prospect-tasks/prospect-tasks.interfaces';
import * as fromSelectedActivityPurchaseOrderDetails from './purchase-order-details/purchase-order-details.actions';
import { activityPurchaseOrderDetailsAdapter } from './purchase-order-details/purchase-order-details.interfaces';

export function selectedActivityReducer(
  state: SelectedActivityState = selectedActivityInitialState,
  action: any
): SelectedActivityState {
  switch (action.type) {
    case fromSelectedActivity.GET_SELECTED_ACTIVITY_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        type: action.payload.type,
        activity: action.payload.activity,
      };
    }
    case fromSelectedProspectResponseLeads.GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        responseLeads: responseLeadsAdapter.upsertMany(action.payload.responseLeads, state.responseLeads),
      };
    }
    case fromSelectedProspectActions.GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectActions: prospectActionsAdapter.upsertMany(action.payload.actions, state.prospectActions),
      };
    }

    case fromSelectedProspectActions.CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER_SUCCESS: {
      return {
        ...state,
        prospectActions: prospectActionsAdapter.addOne(action.payload, state.prospectActions),
      };
    }

    case fromSelectedProspectOpenSales.GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        openSales: openSalesAdapter.upsertMany(action.payload, state.openSales),
      };
    }

    case fromSelectedProspectVehicleOfInterest.CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectVehicleOfInterest: prospectVehicleOfInterestAdapter.addOne(
          action.payload,
          state.prospectVehicleOfInterest
        ),
      };
    }
    case fromSelectedProspectVehicleOfInterest.GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectVehicleOfInterest: prospectVehicleOfInterestAdapter.upsertMany(
          action.payload,
          state.prospectVehicleOfInterest
        ),
      };
    }
    case fromSelectedProspectVehicleOfInterest.DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectVehicleOfInterest: prospectVehicleOfInterestAdapter.removeOne(
          action.payload,
          state.prospectVehicleOfInterest
        ),
      };
    }
    case fromSelectedProspectVehicleOfInterest.UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectVehicleOfInterest: prospectVehicleOfInterestAdapter.upsertOne(
          action.payload,
          state.prospectVehicleOfInterest
        ),
      };
    }
    case fromSelectedProspectActions.DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectActions: prospectActionsAdapter.removeOne(action.payload, state.prospectActions),
      };
    }
    case fromSelectedActivity.GET_SELECTED_PROSPECT_FROM_SERVER: {
      return { ...state, selectedProspectIsLoading: true };
    }
    case fromSelectedActivity.GET_SELECTED_PROSPECT_FROM_SERVER_SUCCESS: {
      return { ...state, prospect: action.payload, selectedProspectIsLoading: false };
    }
    case fromSelectedActivity.GET_SELECTED_PROSPECT_FROM_SERVER_FAILURE: {
      return { ...state, selectedProspectIsLoading: false };
    }
    case fromSelectedActivity.GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER_SUCCESS: {
      return { ...state, prospectAggregate: action.payload };
    }
    case fromSelectedProspectVehicles.GET_SELECTED_PROSPECT_VEHICLES_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        vehicles: vehiclesAdapter.upsertMany(action.payload.vehicles, state.vehicles),
      };
    }
    case fromSelectedProspectVehicles.GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER: {
      return { ...state, vehicleAggregateIsLoading: true };
    }
    case fromSelectedProspectVehicles.GET_VEHICLE_AGGREGATE_DATA_FROM_SERVER_SUCCESS: {
      return { ...state, vehicleAggregate: action.payload, vehicleAggregateIsLoading: false };
    }
    case fromSelectedVehicleServiceHistory.GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        vehicleServiceHistory: vehicleServiceHistoryAdapter.upsertMany(
          action.payload.vehicleServiceHistory,
          state.vehicleServiceHistory
        ),
      };
    }
    case fromSelectedVehicleServiceHistory.CLEAR_VEHICLE_SERVICE_HISTORY_FROM_STATE: {
      return {
        ...state,
        vehicleServiceHistory: vehicleServiceHistoryAdapter.removeAll({ ...state.vehicleServiceHistory }),
      };
    }
    case fromSelectedProspectTasks.GET_PROSPECT_TASKS_FROM_SERVER: {
      return {
        ...state,
        prospectTasks: prospectTasksInitialState,
      };
    }
    case fromSelectedProspectTasks.GET_PROSPECT_TASKS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectTasks: prospectTasksAdapter.upsertMany(action.payload.tasks, state.prospectTasks),
      };
    }
    case fromSelectedProspectSalesAppointments.GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectSalesAppointments: prospectSalesAppointmentsAdapter.upsertMany(
          action.payload.salesAppointments,
          state.prospectSalesAppointments
        ),
      };
    }
    case fromSelectedProspectDealerPromotions.GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectDealerPromotions: prospectDealerPromotionsdapter.addMany(
          action.payload.dealerPromotions,
          state.prospectDealerPromotions
        ),
      };
    }
    case fromSelectedProspectServiceAppointments.GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectServiceAppointments: prospectServiceAppointmentsAdapter.upsertMany(
          action.payload.serviceAppointments,
          state.prospectServiceAppointments
        ),
      };
    }

    case fromSelectedProspectDeliveries.GET_PROSPECT_DELIVERIES_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectDeliveries: prospectDeliveriesAdapter.upsertMany(action.payload.deliveries, state.prospectDeliveries),
      };
    }

    case fromSelectedProspectTasks.ADD_PROSPECT_TASK_TO_STATE: {
      return {
        ...state,
        prospectTasks: prospectTasksAdapter.addOne(action.payload, state.prospectTasks),
      };
    }
    case fromSelectedActivity.UPDATE_SELECTED_PROSPECT_IN_STATE: {
      return {
        ...state,
        prospect: action.payload.changes,
      };
    }
    case fromSelectedProspectSalesAppointments.ADD_PROSPECT_SALES_APPOINTMENT_TO_STATE: {
      return {
        ...state,
        prospectSalesAppointments: prospectSalesAppointmentsAdapter.addOne(
          action.payload,
          state.prospectSalesAppointments
        ),
      };
    }
    case fromSelectedProspectNotes.GET_SELECTED_PROSPECT_NOTES_FROM_SERVER_SUCCESS:
    case fromSelectedProspectNotes.CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER_SUCCESS:
    case fromSelectedProspectNotes.UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER_SUCCESS: {
      return { ...state, prospectNotes: action.payload };
    }

    case fromSelectedActivity.RESET_SELECTED_ACTIVITY_STATE: {
      return selectedActivityInitialState;
    }
    case fromSelectedProspectTasks.UPDATE_PROSPECT_TASK_IN_STATE: {
      return {
        ...state,
        prospectTasks: prospectTasksAdapter.updateOne(action.payload, state.prospectTasks),
      };
    }
    case fromSelectedActivity.UPDATE_SELETED_PROSPECT_SALES_APPOINTMENT_IN_STATE: {
      return {
        ...state,
        prospectSalesAppointments: prospectSalesAppointmentsAdapter.updateOne(
          action.payload,
          state.prospectSalesAppointments
        ),
      };
    }
    case fromSelectedActivity.UPDATE_SELECTED_ACTIVITY_IN_STATE: {
      return {
        ...state,
        activity: action.payload.changes,
      };
    }

    case fromSelectedActivity.CLEAR_SELECTED_PROSPECT_FROM_STATE: {
      return {
        ...state,
        prospect: null,
      };
    }

    case fromSelectedProspectOpenSales.UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER_SUCCESS: {
      return {
        ...state,
        openSales: openSalesAdapter.updateOne({ id: action.payload.id, changes: action.payload }, state.openSales),
      };
    }
    case fromSelectedActivityPurchaseOrderDetails.GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        activityPurchaseOrderDetails: activityPurchaseOrderDetailsAdapter.upsertMany(
          action.payload,
          state.activityPurchaseOrderDetails
        ),
      };
    }

    default: {
      return state;
    }
  }
}

const myReducers = {
  selectedActivityReducer,
};

export function createReducer(asyncReducers = {}) {
  let allReducers = Object.assign(myReducers, asyncReducers);
  return combineReducers(allReducers);
}

export function appReducer(state: any, action: any) {
  return createReducer()(state, action);
}

export const reducer = combineReducers(myReducers);
