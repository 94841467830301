import * as fromMessageTypeActions from './message-types.actions';
import { initialState, messageTypesAdapter, MessageTypesState } from './message-types.interfaces';

export function reducer(
  state: MessageTypesState = initialState,
  action: fromMessageTypeActions.AssociateMessageTypesAction
): MessageTypesState {
  switch (action.type) {
    case fromMessageTypeActions.GET_MESSAGE_TYPES_FROM_SERVER_SUCCESS: {
      return {
        ...messageTypesAdapter.addMany(action.payload.messageTypes, state)
      };
    }

    default: {
      return state;
    }
  }
}
