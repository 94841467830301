import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { Action, Prospect, ProspectAction } from '@quorum/models/xs-resource';
import { Observable } from 'rxjs';
@Component({
  selector: 'crm-prospect-actions',
  templateUrl: './prospect-actions.component.html',
  styleUrls: ['./prospect-actions.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectActionsComponent implements OnInit {
  @Input() actions: Action[];
  @Input() prospectActions$: Observable<any>;
  @Input() prospect: Prospect;
  @Output() actionCreated: EventEmitter<Action> = new EventEmitter<Action>();
  @Output() actionDeleted: EventEmitter<ProspectAction> = new EventEmitter<ProspectAction>();

  constructor(public browserControlCommunicationService: BrowserControlCommunicationService) {}

  ngOnInit() {}

  createAction(action: Action, actionCreated: EventEmitter<Action>) {
    actionCreated.emit(action);
  }

  deleteAction(prospectAction: ProspectAction, actionDeleted: EventEmitter<ProspectAction>) {
    actionDeleted.emit(prospectAction);
  }
}
