import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import * as fromEquityMining from './+state/equity-mining.actions';
import { equityMiningFeatureSelector } from './+state/equity-mining.interfaces';

@Injectable()
export class EquityMiningStateService {
  constructor(private store: Store<any>) {}

  getPreviousReportParams(type: string = '') {
    return this.store.pipe(
      select(equityMiningFeatureSelector),
      map(queryParams => {
        return type == '' ? queryParams : queryParams[type];
      })
    );
  }

  updateReportParameters(type: string, params: any) {
    switch (type) {
      case 'available':
        this.store.dispatch(new fromEquityMining.SetAvailableParamsInState({ params }));
        break;
      case 'in-equity':
        this.store.dispatch(new fromEquityMining.SetInEquityParamsInState({ params }));
        break;
      case 'over-mileage-lease':
        this.store.dispatch(new fromEquityMining.SetOverMileageParamsInState({ params }));
        break;
      case 'sub-prime':
        this.store.dispatch(new fromEquityMining.SetSubPrimeParamsInState({ params }));
        break;
      case 'service-appointment':
        this.store.dispatch(new fromEquityMining.SetServiceAppointmentsParamsInState({ params }));
        break;
      case 'customer-search':
        this.store.dispatch(new fromEquityMining.SetCustomersParamsInState({ params }));
        break;
      case 'unsold-vehicles':
        this.store.dispatch(new fromEquityMining.SetUnsoldVehiclesParamsInState({ params }));
        break;
      case 'vehicles':
        this.store.dispatch(new fromEquityMining.SetVehiclesParamsInState({ params }));
        break;
    }
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
