import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { CrmSettingsState } from '@quorum/crm-settings/state';
import { SalesPerson } from '@quorum/models/xs-resource';
import { LocalSettingsState } from '@quorum/xsr-salesplanner-legacy/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'crm-sales-planner-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsDialogComponent implements OnInit {
  settingsChanged: string[] = [];
  crmSettings$: Observable<CrmSettingsState>;

  settingsForm = this.fb.group({
    accessibility: this.fb.group({ theme: '', increaseContrast: false, boldText: false, largeText: false }),
    activitiesFilter: [''],
    defaultActivityTab: [''],
    defaultProspectTab: [''],
    prospectFilter: [''],
    prospectSortBy: [''],
    range: [''],
    rangePast: [''],
    monthsOverdue: [''],
    salesPeople: [''],
    startDate: [''],
    activityListDisplayEmployee: [''],
  });

  constructor(
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { dropdowns: { salespeople$: Observable<SalesPerson[]> }; localSettings: LocalSettingsState },
    private fb: FormBuilder,
    private settingsStateService: CrmSettingsStateService
  ) {}

  ngOnInit() {
    this.settingsForm.patchValue({
      activitiesFilter: this.data.localSettings.activitiesFilter,
      defaultActivityTab: this.data.localSettings.defaultActivityTab,
      defaultProspectTab: this.data.localSettings.defaultProspectTab,
      prospectFilter: this.data.localSettings.prospectFilter,
      prospectSortBy: this.data.localSettings.prospectFilter.sort,
      range: this.data.localSettings.range,
      rangePast: this.data.localSettings.rangePast,
      monthsOverdue: this.data.localSettings.monthsOverdue,
      salesPeople: this.data.localSettings.salesPeople,
      startDate: this.data.localSettings.startDate,
      activityListDisplayEmployee: this.data.localSettings.activityListDisplayEmployee,
    });

    this.settingsForm.controls['accessibility'].patchValue({
      theme: this.data.localSettings.accessibility.theme,
      increaseContrast: this.data.localSettings.accessibility.increaseContrast,
      boldText: this.data.localSettings.accessibility.boldText,
      largeText: this.data.localSettings.accessibility.largeText,
    });

    this.crmSettings$ = this.settingsStateService.selectSettingsFromState();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  update(data: any) {
    data = { ...data, settingsChanged: this.settingsChanged };
    this.dialogRef.close(data);
  }

  selectTeam(salesTeam: any, settingsForm: FormGroup) {
    let selectedTeamSalespeople = salesTeam.children.map((salesperson: any) => salesperson.EmployeeId);
    let selectedSalespeople = settingsForm.get('salesPeople').value;

    let existingCheck = selectedSalespeople.some((el: any) => selectedTeamSalespeople.includes(el));

    selectedTeamSalespeople.forEach((salesPersonId: string) => {
      if (existingCheck) {
        if (selectedSalespeople.includes(salesPersonId)) {
          selectedSalespeople.splice(selectedSalespeople.indexOf(salesPersonId, 0), 1);
        }
      } else {
        if (!selectedSalespeople.includes(salesPersonId)) {
          selectedSalespeople.push(salesPersonId);
        }
      }
    });

    settingsForm.get('salesPeople').patchValue(null);
    settingsForm.patchValue({
      salesPeople: selectedSalespeople,
    });
  }

  settingChanged(settingName: string) {
    switch (settingName) {
      case 'range':
        if (this.settingsChanged.indexOf('range') === -1) {
          this.settingsChanged.push('range');
        }
        break;
      case 'salespeople':
        if (this.settingsChanged.indexOf('salespeople') === -1) {
          this.settingsChanged.push('salespeople');
        }
        break;
      case 'monthsOverdue':
        if (this.settingsChanged.indexOf('monthsOverdue') === -1) {
          this.settingsChanged.push('monthsOverdue');
        }
        break;
      case 'activityListDisplayEmployee':
        if (this.settingsChanged.indexOf('activityListDisplayEmployee') === -1) {
          this.settingsChanged.push('activityListDisplayEmployee');
        }
        break;
    }
  }
}
