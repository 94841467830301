import { Associate } from '../associates/associate.model';
import { DealerPromotionHeader } from './dealer-promotion-header.model';
import { DealerPromotionTransaction } from './dealer-promotion-transaction.model';
import { Employee } from '../associates/employee.model';
import { TaskOutcome } from './task-outcome.model';
import { Vehicle } from '../vehicle/vehicle.model';
import { Prospect } from '../crm/prospect.model';

export class DealerPromotion {
  communicatorConversationId: number = null;
  communicatorTaskSpecificTemplate: string = null;
  communicatorTemplate: string = null;
  comments: string = null;
  completedDate: Date = null;
  contactPreferenceId: string = null;
  createdDate: Date = null;
  dueDate: Date = null;
  embedded?: DealerPromotionEmbed = null;
  employeeId: string = null;
  escalateFlag: string = null;
  escalateFromDate: Date = null;
  escalateToDate: Date = null;
  id: number = null;
  letterNumber: string = null;
  mergeId: number = null;
  note: string = null;
  rooftopId: number = null;
  outcomeId: number = null;
  prepWork: string = null;
  previousTaskEmployeeId: number = null;
  prospectId: number = null;
  sequence: number = null;
  taskEmployeeId: string = null;
  telephoneContact: string = null;
  templateId: number = null;
  vehicleId: number = null;

  constructor(obj?: DealerPromotion) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class DealerPromotionEmbed {
  dealerPromotionHeader?: DealerPromotionHeader = null;
  dealerPromotionTransactions?: DealerPromotionTransaction[] = null;
  employee?: Employee = null;
  employeeAssociate?: Associate = null;
  previousTaskEmployee?: Associate = null;
  prospect?: Prospect = null;
  taskEmployeeAssociate?: Associate = null;
  taskOutcome?: TaskOutcome = null;
  vehicle?: Vehicle = null;
}
