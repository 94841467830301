import { Action } from '@ngrx/store';
import { SalesTeamMaintenanceQueryParameters } from '@quorum/models/xs-query';
import { SalesTeamMaintenance } from '@quorum/models/xs-resource';

export const GET_SALES_TEAMS_FROM_SERVER = '[CRM] Get Sales Teams From Server';
export class GetSalesTeamsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALES_TEAMS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SalesTeamMaintenanceQueryParameters) {}
}

export const GET_SALES_TEAMS_FROM_SERVER_SUCCESS = '[CRM] Get Sales Teams From Server Success';
export class GetSalesTeamsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALES_TEAMS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { salesTeams: SalesTeamMaintenance[] }) {}
}

export const GET_SALES_TEAMS_FROM_SERVER_FAILURE = '[CRM] Get Sales Teams From Server Failure';
export class GetSalesTeamsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SALES_TEAMS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type SalesTeamsAction =
  | GetSalesTeamsFromServer
  | GetSalesTeamsFromServerSuccess
  | GetSalesTeamsFromServerFailure;
