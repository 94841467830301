import { Action } from '@ngrx/store';
import { HtmlMappingTemplateQueryParameters } from '@quorum/models/xs-query';
import { HtmlMappingTemplate } from '@quorum/models/xs-resource';

export const GET_HTML_MAPPING_TEMPLATES_FROM_SERVER = '[CRM] Get HTML Mapping Templates From Server';
export class GetHtmlMappingTemplatesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_HTML_MAPPING_TEMPLATES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: HtmlMappingTemplateQueryParameters) {}
}

export const GET_HTML_MAPPING_TEMPLATES_FROM_SERVER_SUCCESS = '[CRM] Get HTML Mapping Templates From Server Success';
export class GetHtmlMappingTemplatesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_HTML_MAPPING_TEMPLATES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { htmlMappingTemplates: HtmlMappingTemplate[] }) {}
}

export const GET_HTML_MAPPING_TEMPLATES_FROM_SERVER_FAILURE = '[CRM] Get HTML Mapping Templates From Server Failure';
export class GetHtmlMappingTemplatesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_HTML_MAPPING_TEMPLATES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type HtmlMappingTemplatesAction =
  | GetHtmlMappingTemplatesFromServer
  | GetHtmlMappingTemplatesFromServerSuccess
  | GetHtmlMappingTemplatesFromServerFailure;
