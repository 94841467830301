import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Source } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromSources from './sources.actions';
import { SourcesState } from './sources.interfaces';
@Injectable()
export class SourcesEffects {
  
  getTasksFromServer = createEffect(() => this.dataPersistence.fetch(fromSources.GET_SOURCES_FROM_SERVER, {
    run: (action: fromSources.GetSourcesFromServer, state: SourcesState) => {
      return this.apiService
        .get<Source[]>(`v/1/crm/prospect-sources`, {
          params: action.payload,
        })
        .pipe(
          map((sources) => {
            return new fromSources.GetSourcesFromServerSuccess({
              sources: sources,
            });
          })
        );
    },
    onError: (action: fromSources.GetSourcesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<SourcesState>,
    private apiService: ApiService
  ) {}
}
