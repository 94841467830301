import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { CrmSettingsState } from '@quorum/crm-settings/state';
import { BlackbookSettingQueryParameters } from '@quorum/models/xs-query';
import { BlackbookPriceType, BlackbookSetting, OemRooftopLink } from '@quorum/models/xs-resource';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'crm-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsDialogComponent implements OnInit {
  blackbookSetting: BlackbookSetting;
  blackBookPriceTypes$: Observable<BlackbookPriceType[]>;
  selectedRooftopId: number;
  currencyMask = createNumberMask({
    allowDecimal: true,
    allowNegative: true,
  });

  constructor(
    private apiService: ApiService,
    private authenticationStateService: AuthenticationStateService,
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      rooftops: OemRooftopLink[];
      settings: CrmSettingsState;
    }
  ) {}

  ngOnInit() {
    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(take(1))
      .subscribe((authenticatedUser) => {
        this.selectedRooftopId = authenticatedUser.rooftopId;

        this.getBlackbookSetting(this.selectedRooftopId);

        this.blackBookPriceTypes$ = this.apiService
          .get<BlackbookPriceType[]>('/v/1/crm/lead-generation/blackbook-price-types')
          .pipe(map((response) => response));
      });
  }

  getBlackbookSetting(rooftopId: number) {
    let params: BlackbookSettingQueryParameters = new BlackbookSettingQueryParameters({
      rooftopId: rooftopId.toString(),
    });

    this.apiService
      .get<BlackbookSetting>(`/v/1/crm/lead-generation/settings`, {
        params: params,
      })
      .pipe(
        map((response) => response[0]),
        take(1)
      )
      .subscribe((blackbookSetting) => (this.blackbookSetting = blackbookSetting));
  }

  onCancel() {
    this.dialogRef.close({ updated: false });
  }

  onSave(blackbookSetting: BlackbookSetting) {
    const marketingCost = parseFloat(blackbookSetting.marketingCost.toString().replace(/,/g, '').replace('$', ''));
    const reconditioningCost = parseFloat(
      blackbookSetting.reconditioningCost.toString().replace(/,/g, '').replace('$', '')
    );

    blackbookSetting.marketingCost = marketingCost;
    blackbookSetting.reconditioningCost = reconditioningCost;

    this.apiService
      .put<BlackbookSetting>(`v/1/crm/lead-generation/settings/${blackbookSetting.id}`, blackbookSetting)
      .subscribe((response) => this.dialogRef.close({ updated: true }));
  }
}
