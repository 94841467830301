<ng-container *ngIf="authenticatedUser$ | async as authenticatedUser">
  <div fxLayout="column" fxFlexFill>
    <mat-toolbar>
      <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center" class="my-4">
        <button mat-icon-button class="secondary-text" (click)="toggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
        <div fxLayoutAlign="center center">
          <span fxFlex class="toolbar-title">Sales Planner</span>
        </div>
        <div id="noticeable-icon" class="w-3"></div>
        <button mat-icon-button class="secondary-text" (click)="refreshPage()">
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="my-4"
      *ngIf="(salesTeamsData$ | async)?.salesTeams.length === 0">
      <span class="text-red-700 text-sm w-auto px-3">{{noSalesTeamMessage}}</span>
    </div>
    <div fxLayout="column" fxFlex>
      <div id="masterTabContainer" fxFlexFill>
        <mat-tab-group>
          <mat-tab label="Activities">
            <div class="tab-body" fxLayout="column">
              <crm-activity-list-filter [queryParams]="activityQueryParams"
                (filtersChanged)="activityFiltersChanged($event)"></crm-activity-list-filter>
              <mat-divider></mat-divider>
              <div class="list-container" fxFlex *ngIf="activities$ | async as activities">
                <ng-container *ngIf="combinedObs$ | async as combinedData">
                  <crm-activity-list [activities]="activities" [overdue]="overdueActivities"
                    [combinedData]="combinedData">
                  </crm-activity-list>
                </ng-container>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Prospects">
            <div class="tab-body" fxLayout="column">
              <span class="prospects-moved-message">Manage your prospects. Click <a
                  [routerLink]="['/', 'crm', 'prospects']">here</a> to navigate to Prospect Management.</span>
              <crm-prospect-list-filter [queryParams$]="prospectQueryParams$"
                (filtersChanged)="prospectFiltersChanged($event)"></crm-prospect-list-filter>
              <mat-divider></mat-divider>
              <div fxFlex>
                <crm-prospect-list [queryParams$]="prospectQueryParams$" [componentData]="prospectListComponentData$"
                  (filtersChanged)="prospectPageChanged($event)"></crm-prospect-list>
              </div>

            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</ng-container>