import { Injectable } from '@angular/core';
import { VehicleCategory } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromVehicleCategories from './vehicle-categories.actions';
import { VehicleCategoriesState } from './vehicle-categories.interfaces';
import { map } from 'rxjs/operators';
@Injectable()
export class VehicleCategoriesEffects {
  
  getVehicleCategoriesFromServer = createEffect(() => this.dataPersistence.fetch(
    fromVehicleCategories.GET_VEHICLE_CATEGORIES_FROM_SERVER,
    {
      run: (action: fromVehicleCategories.GetVehicleCategoriesFromServer, state: VehicleCategoriesState) => {
        return this.apiService
          .get<VehicleCategory[]>(`v/1/vehicles/vehicle-categories`, {
            params: action.payload
          })
          .pipe(
            map(vehicleCategories => {
              return new fromVehicleCategories.GetVehicleCategoriesFromServerSuccess({
                vehicleCategories: vehicleCategories
              });
            })
          );
      },
      onError: (action: fromVehicleCategories.GetVehicleCategoriesFromServer, error) => {
        console.error('Error', error);
      }
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleCategoriesState>,
    private apiService: ApiService
  ) {}
}
