import { Action } from '@ngrx/store';
import { ReportingVehicleSearchQueryParameters } from '@quorum/models/xs-query';
import { ReportingVehicleSearch } from '@quorum/models/xs-resource';

export const GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER = '[CRM] Get Vehicle Search Results From Server';
export class GetVehicleSearchResultsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ReportingVehicleSearchQueryParameters) {}
}

export const GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Search Results From Server Success';
export class GetVehicleSearchResultsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleSearchResults: ReportingVehicleSearch[] }) {}
}

export const GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Search Results From Server Failure';
export class GetVehicleSearchResultsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_VEHICLE_SEARCH_RESULTS_FROM_STATE = '[CRM] Clear Vehicle Search Results From State';
export class ClearVehicleSearchResultsFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_VEHICLE_SEARCH_RESULTS_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export type VehicleSearchAction =
  | GetVehicleSearchResultsFromServer
  | GetVehicleSearchResultsFromServerSuccess
  | GetVehicleSearchResultsFromServerFailure
  | ClearVehicleSearchResultsFromState;
