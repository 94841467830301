import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommunicatorService } from '@applications/sha-services';
import { EventTypes } from '@models/server-common';
import { Actions, createEffect } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { CommunicatorFacade } from '@quorum/communicator-ngrx';
import {
  ServiceAppointmentQueryParameters,
  CrmAppointmentQueryParameters as AppointmentQueryParameters,
  ConversationQueryParameters,
  DealerPromotionQueryParameters,
  ProspectQueryParameters,
  SaleQueryParameters,
  TaskQueryParameters,
  UserQueryParameters,
  PurchaseOrderHeaderQueryParameters,
  VehicleOfInterestQueryParameters,
} from '@quorum/models/xs-query';
import {
  ServiceAppointment,
  CrmAppointment as Appointment,
  Conversation,
  DealerPromotion,
  Event,
  Member,
  Note,
  Prospect,
  ProspectAction,
  ResponseLead,
  Task,
  Sale,
  PurchaseOrderHeader,
  VehicleOfInterest,
} from '@quorum/models/xs-resource';
import { LocalSettingsStateService, SalesPlannerStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { selectEntities as employees } from 'libs/crm-dropdowns/src/lib/+state/employees/employees.interfaces';
import { combineLatest, forkJoin, of } from 'rxjs';
import { flatMap, map, mergeAll, switchMap, take } from 'rxjs/operators';
import * as fromProspects from '../../../../../xsr-entities/src/lib/crm/prospects/prospects.actions';
import * as fromTasks from '../activities/tasks/tasks.actions';
import * as fromSelectedProspectOpenSales from './open-sales/open-sales.actions';
import * as fromSelectedProspectVehicleOfInterest from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.actions';
import * as fromSelectedProspectActions from './prospect-actions/prospect-actions.actions';
import * as fromSelectedProspectDealerPromotions from './prospect-dealer-promotions/prospect-dealer-promotions.actions';
import * as fromSelectedProspectDeliveries from './prospect-deliveries/prospect-deliveries.actions';
import * as fromSelectedProspectNotes from './prospect-notes/prospect-notes.actions';
// eslint-disable-next-line max-len
import * as fromSelectedProspectSalesAppointments from './prospect-sales-appointments/prospect-sales-appointments.actions';
// eslint-disable-next-line max-len
import * as fromSelectedProspectServiceAppointments from './prospect-service-appointments/prospect-service-appointments.actions';
import * as fromSelectedProspectTasks from './prospect-tasks/prospect-tasks.actions';
import * as fromSelectedProspectResponseLeads from './response-leads/response-leads.actions';
import { SelectedActivityStateService } from './selected-activity-state.service';
import * as fromSelectedActivity from './selected-activity.actions';
import { SelectedActivityState } from './selected-activity.interfaces';
import * as fromSelectedProspectVehicles from './vehicles/vehicles.actions';
import * as fromSelectedActivityPurchaseOrderDetails from './purchase-order-details/purchase-order-details.actions';

@Injectable()
export class SelectedActivityEffects {
  getSelectedActivityFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.GET_SELECTED_ACTIVITY_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedActivity.GetSelectedActivityFromServer, state: any) => {
        let activityType: string;
        let activityQueryParams:
          | TaskQueryParameters
          | DealerPromotionQueryParameters
          | AppointmentQueryParameters
          | SaleQueryParameters
          | ServiceAppointmentQueryParameters;
        switch (action.payload.activityType) {
          case 'Task':
            activityType = 'v/1/crm/tasks';
            activityQueryParams = new TaskQueryParameters({ id: action.payload.id.toString() });
            break;
          case 'Promotion':
            activityType = 'v/1/crm/dealer-promotion-tasks';
            activityQueryParams = new DealerPromotionQueryParameters({
              embed: [action.payload.embed],
              id: action.payload.id.toString(),
              sequence: action.payload.sequence.toString(),
            });
            break;
          case 'Delivery':
            activityType = 'v/1/vehicles/sales';
            activityQueryParams = new SaleQueryParameters({
              id: action.payload.id.toString(),
              embed: ['salesManagerAssociate, businessManagerAssociate'],
            });
            break;
          case 'Appointment':
            activityType = 'v/1/crm/appointments';
            activityQueryParams = new AppointmentQueryParameters({ id: action.payload.id });
            break;
          case 'Service':
            activityType = 'v/1/fixed-operations/appointments';
            activityQueryParams = new ServiceAppointmentQueryParameters({ id: action.payload.id.toString() });
            break;
        }

        if (action.payload.embed) {
          activityQueryParams.embed = action.payload.embed;
        }

        return this.apiService
          .get<any[]>(activityType, {
            params: activityQueryParams,
          })
          .pipe(
            map((activities) => {
              return new fromSelectedActivity.GetSelectedActivityFromServerSuccess({
                type: action.payload.activityType,
                activity: activities[0],
              });
            })
          );
      },
      onError: (action: fromSelectedActivity.GetSelectedActivityFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedActivityFromServerSuccess = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.GET_SELECTED_ACTIVITY_FROM_SERVER_SUCCESS, {
      run: (action: fromSelectedActivity.GetSelectedActivityFromServerSuccess, state: any) => {
        let actions: Action[] = [];
        if (action.payload) {
          if (action.payload.type == 'Delivery') {
            let purchaseOrderHeaderQueryParameters = new PurchaseOrderHeaderQueryParameters({
              vehicleId: action.payload.activity.vehicleId.toString(),
            });
            actions.push(
              new fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServer(
                purchaseOrderHeaderQueryParameters
              )
            );
          }
        }
        return of(actions).pipe(mergeAll());
      },
      onError: (action: fromSelectedActivity.GetSelectedActivityFromServerSuccess, error) => {
        console.error('Error', error);
      },
    })
  );

  getActivityPurchaseOrderHeadersFromServer = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedActivityPurchaseOrderDetails.GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER,
      {
        id: (a: any, state: any) => {
          return 1;
        },
        run: (
          action: fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServer,
          state: any
        ) => {
          return this.apiService
            .get<PurchaseOrderHeader[]>(`v/1/accounting/purchase-orders`, {
              params: action.payload,
            })
            .pipe(
              map((purchaseOrderHeaders: PurchaseOrderHeader[]) => {
                return new fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServerSuccess(
                  purchaseOrderHeaders
                );
              })
            );
        },
        onError: (
          action: fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServer,
          error
        ) => {
          console.error('Error', error);
          return new fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServerFailure(error);
        },
      }
    )
  );

  getActivityPurchaseOrderHeadersFromServerSuccess = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedActivityPurchaseOrderDetails.GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER_SUCCESS,
      {
        run: (
          action: fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServerSuccess,
          state: any
        ) => {
          let actions: Action[] = [];
          if (action.payload) {
            actions.push(
              new fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderDetailsFromServer(action.payload)
            );
          }

          return of(actions).pipe(mergeAll());
        },
        onError: (
          action: fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderHeadersFromServerSuccess,
          error
        ) => {
          console.error('Error', error);
        },
      }
    )
  );

  getActivityPurchaseOrderDetailsFromServer = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedActivityPurchaseOrderDetails.GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER,
      {
        id: (a: any, state: any) => {
          return 1;
        },
        run: (
          action: fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderDetailsFromServer,
          state: any
        ) => {
          return forkJoin(
            action.payload.map((purchaseOrderHeader) =>
              this.apiService.get(`v/1/accounting/purchase-orders/${purchaseOrderHeader.id}/details`).pipe(take(1))
            )
          ).pipe(
            map((response) => {
              return new fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderDetailsFromServerSuccess(
                [].concat(...response)
              );
            })
          );
        },
        onError: (
          action: fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderDetailsFromServer,
          error
        ) => {
          console.error('Error', error);
          return new fromSelectedActivityPurchaseOrderDetails.GetActivityPurchaseOrderDetailsFromServerFailure(error);
        },
      }
    )
  );

  getSelectedProspectFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.GET_SELECTED_PROSPECT_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedActivity.GetSelectedProspectFromServer, state: any) => {
        return this.apiService
          .get<Prospect>(`v/2/crm/prospects/${action.payload.id}`, {
            params: action.payload,
          })
          .pipe(
            map((prospect) => {
              return new fromSelectedActivity.GetSelectedProspectFromServerSuccess(prospect);
            })
          );
      },
      onError: (action: fromSelectedActivity.GetSelectedProspectFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getResponseLeadsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectResponseLeads.GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectResponseLeads.GetSelectedProspectResponseLeadsFromServer, state: any) => {
        return this.apiService
          .get<ResponseLead[]>(`v/2/crm/prospects/${action.payload.prospectId}/response-leads`, {
            params: action.payload,
          })
          .pipe(
            map((responseLeads) => {
              return new fromSelectedProspectResponseLeads.GetSelectedProspectResponseLeadsFromServerSuccess({
                responseLeads,
              });
            })
          );
      },
      onError: (action: fromSelectedProspectResponseLeads.GetSelectedProspectResponseLeadsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectNotesFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectNotes.GET_SELECTED_PROSPECT_NOTES_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectNotes.GetSelectedProspectNotesFromServer, state: any) => {
        return this.apiService
          .get<Note[]>(`v/1/common/legacy-notes`, {
            params: action.payload,
          })
          .pipe(
            map((notes) => {
              return new fromSelectedProspectNotes.GetSelectedProspectNotesFromServerSuccess(notes[0]);
            })
          );
      },
      onError: (action: fromSelectedProspectNotes.GetSelectedProspectNotesFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  createSelectedProspectNoteOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectNotes.CREATE_SELECTED_PROSPECT_NOTES_ON_SERVER, {
      run: (action: fromSelectedProspectNotes.CreateSelectedProspectNoteOnServer, state: any) => {
        let actions: Action[] = [];
        return this.apiService.post<Note>(`v/1/common/legacy-notes`, action.payload).pipe(
          flatMap((note) => {
            actions.push(
              new fromProspects.UpdateProspectOnServer({
                prospect: {
                  ...state.salesplannerLegacy.selectedActivity.prospect,
                  updatedDate: new Date(),
                },
                displayToast: false,
              })
            );
            actions.push(new fromSelectedProspectNotes.CreateSelectedProspectNoteOnServerSuccess(note));
            return of(actions).pipe(mergeAll());
          })
        );
      },
      onError: (action: fromSelectedProspectNotes.CreateSelectedProspectNoteOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  updateSelectedProspectNoteOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectNotes.UPDATE_SELECTED_PROSPECT_NOTES_ON_SERVER, {
      run: (action: fromSelectedProspectNotes.UpdateSelectedProspectNoteOnServer, state: any) => {
        let actions: Action[] = [];
        return this.apiService.put<Note>(`v/1/common/legacy-notes/${action.payload.id}`, action.payload).pipe(
          flatMap((note) => {
            actions.push(
              new fromProspects.UpdateProspectOnServer({
                prospect: {
                  ...state.salesplannerLegacy.selectedActivity.prospect,
                  updatedDate: new Date(),
                },
                displayToast: false,
              })
            );
            actions.push(new fromSelectedProspectNotes.UpdateSelectedProspectNoteOnServerSuccess(note));
            return of(actions).pipe(mergeAll());
          })
        );
      },
      onError: (action: fromSelectedProspectNotes.UpdateSelectedProspectNoteOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectActionsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectActions.GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectActions.GetSelectedProspectActionsFromServer, state: any) => {
        return this.apiService
          .get<ProspectAction[]>(`v/1/crm/prospects/${action.payload.prospectId}/actions`, {
            params: action.payload.prospectActionQueryParams,
          })
          .pipe(
            map((prospectActions) => {
              return new fromSelectedProspectActions.GetSelectedProspectActionsFromServerSuccess({
                actions: prospectActions,
              });
            })
          );
      },
      onError: (action: fromSelectedProspectActions.GetSelectedProspectActionsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  createSelectedProspectActionOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectActions.CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER, {
      run: (action: fromSelectedProspectActions.CreateSelectedProspectActionOnServer, state: any) => {
        let actions: Action[] = [];
        return this.apiService
          .post<ProspectAction>(
            `v/1/crm/prospects/${action.payload.prospectAction.prospectId}/actions`,
            action.payload.prospectAction,
            {
              params: { embed: 'employeeAssociate,action' },
            }
          )
          .pipe(
            flatMap((prospectAction: ProspectAction) => {
              let createTasksMappedToActionQueryParams = {
                currentEmployeeId: state.authentication.authenticatedUser.user.xselleratorEmployeeId,
                prospectId: action.payload.prospectAction.prospectId,
                rooftopId: state.authentication.employee.rooftopId,
                actionId: prospectAction.taskActionId,
              };

              actions.push(
                new fromSelectedActivity.CreateTasksMappedToAction({
                  queryParams: createTasksMappedToActionQueryParams,
                })
              );

              actions.push(
                new fromProspects.UpdateSelectedProspectOnServer({
                  prospect: {
                    ...action.payload.prospect,
                    actionId: prospectAction.taskActionId,
                    updatedDate: new Date(),
                  },
                  displayToast: false,
                })
              );
              actions.push(new fromSelectedProspectActions.CreateSelectedProspectActionOnServerSuccess(prospectAction));

              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSelectedProspectActions.CreateSelectedProspectActionOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  createTasksMappedToAction = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.CREATE_TASKS_MAPPED_TO_PROSPECT_ACTION_ON_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedActivity.CreateTasksMappedToAction, state: any) => {
        let actions: Action[] = [];
        return this.apiService
          .post<any>(
            `v/1/crm/prospects/${action.payload.queryParams.prospectId}/actions/workflow/apply-mapping`,
            action.payload.queryParams
          )
          .pipe(
            flatMap((tasks) => {
              actions.push(
                new fromSelectedProspectTasks.GetProspectTasksFromServer({
                  prospectId: action.payload.queryParams.prospectId,
                  embed: 'prospect,taskEmployeeAssociate,taskOutcome',
                })
              );
              let dateFrom = new Date(
                new Date(state.salesplannerLegacy.localSettings.startDate).getFullYear(),
                new Date(state.salesplannerLegacy.localSettings.startDate).getMonth(),
                new Date(state.salesplannerLegacy.localSettings.startDate).getDate() -
                  state.salesplannerLegacy.localSettings.rangePast
              ).toISOString();

              let dateTo = new Date(
                new Date(state.salesplannerLegacy.localSettings.startDate).getFullYear(),
                new Date(state.salesplannerLegacy.localSettings.startDate).getMonth(),
                new Date(state.salesplannerLegacy.localSettings.startDate).getDate() +
                  state.salesplannerLegacy.localSettings.range +
                  1
              ).toISOString();
              actions.push(
                new fromTasks.GetDealerTasksFromServer(
                  new TaskQueryParameters({
                    dueDateFrom: dateFrom,
                    dueDateTo: dateTo,
                    taskEmployeeId: state.salesplannerLegacy.localSettings.salesPeople
                      ? state.salesplannerLegacy.localSettings.salesPeople
                      : state.authentication.authenticatedUser.user.xselleratorEmployeeId,
                    embed: 'prospect,taskEmployeeAssociate',
                    open: '1',
                    pageSize: 2000,
                  })
                )
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSelectedActivity.CreateTasksMappedToAction, error) => {
        console.error('Error', error);
      },
    })
  );

  deleteSelectedProspectActionFromServer = createEffect(() =>
    this.dataPersistence.pessimisticUpdate(fromSelectedProspectActions.DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER, {
      run: (action: fromSelectedProspectActions.DeleteSelectedProspectActionFromServer, state: any) => {
        let actions: Action[] = [];
        return this.apiService
          .delete<ProspectAction>(`v/1/crm/prospects/${action.payload.prospectId}/actions/${action.payload.id}`)
          .pipe(
            flatMap((prospectAction) => {
              actions.push(
                new fromProspects.UpdateProspectOnServer({
                  prospect: {
                    ...state.salesplannerLegacy.selectedActivity.prospect,
                    updatedDate: new Date(),
                  },
                  displayToast: false,
                })
              );
              actions.push(
                new fromSelectedProspectActions.DeleteSelectedProspectActionFromServerSuccess(action.payload.id)
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSelectedProspectActions.DeleteSelectedProspectActionFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectAggregateFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.GET_SELECTED_PROSPECT_AGGREGATE_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedActivity.GetSelectedProspectAggregateFromServer, state: any) => {
        return this.apiService
          .get<any>(`v/1/crm/legacy/prospect-details`, { params: { prospectId: action.payload.prospectId } })
          .pipe(
            map((prospectAggregate) => {
              return new fromSelectedActivity.GetSelectedProspectAggregateFromServerSuccess(prospectAggregate);
            })
          );
      },
      onError: (action: fromSelectedActivity.GetSelectedProspectAggregateFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectFromServerSuccess = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.GET_SELECTED_PROSPECT_FROM_SERVER_SUCCESS, {
      run: (action: fromSelectedActivity.GetSelectedProspectFromServerSuccess, state: any) => {
        let actions: Action[] = [];
        if (action.payload) {
          let localSettings: any;
          this.localSettingsStateService
            .selectLocalSettingsFromState()
            .subscribe((settings) => (localSettings = settings));

          actions.push(
            new fromSelectedProspectVehicles.GetSelectedProspectVehiclesFromServer({
              associateId: action.payload.associateId,
              embed: ['prospectType'],
            })
          );

          let openSaleQueryParams: SaleQueryParameters;
          if (action.payload.typeId === 3) {
            openSaleQueryParams = new SaleQueryParameters({
              showroomProspectId: action.payload.id.toString(),
              embed: 'vehicle',
              statusId: 'O,Q,1,2',
            });
          } else if (action.payload.typeId === 4) {
            openSaleQueryParams = new SaleQueryParameters({
              retentionProspectId: action.payload.id.toString(),
              embed: 'vehicle',
              statusId: 'O,Q,1,2',
            });
          }

          actions.push(new fromSelectedProspectOpenSales.GetSelectedProspectOpenSalesFromServer(openSaleQueryParams));

          let vehicleOfInterestQueryParams: VehicleOfInterestQueryParameters;
          if (action.payload.typeId === 3) {
            vehicleOfInterestQueryParams = new VehicleOfInterestQueryParameters({
              prospectId: action.payload.id.toString(),
            });
          }
          actions.push(
            new fromSelectedProspectVehicleOfInterest.GetSelectedProspectVehicleOfInterestFromServer(
              vehicleOfInterestQueryParams
            )
          );

          let deliveryQueryParams: SaleQueryParameters;
          if (action.payload.typeId === 3) {
            deliveryQueryParams = new SaleQueryParameters({
              showroomProspectId: action.payload.id.toString(),
              embed: 'vehicle,customerAssociate,status',
              statusId: 'S,1,2',
            });
          } else if (action.payload.typeId === 4) {
            deliveryQueryParams = new SaleQueryParameters({
              retentionProspectId: action.payload.id.toString(),
              embed: 'vehicle,customerAssociate,status',
              statusId: 'S,1,2',
            });
          }

          actions.push(new fromSelectedProspectDeliveries.GetProspectDeliveriesFromServer(deliveryQueryParams));
          if (action.payload.associateId != null) {
            actions.push(
              new fromSelectedProspectServiceAppointments.GetProspectServiceAppointmentsFromServer({
                customerId: action.payload.associateId,
                statusId: '1,2',
                embed: 'customerAssociate,serviceAdvisorAssociate,vehicle',
              })
            );
          }

          // actions.push(
          //   new fromSelectedProspectDealerPromotions.GetProspectDealerPromotionsFromServer(
          //     new DealerPromotionQueryParameters({
          //       embed: 'vehicle,header'
          //     })
          //   )
          // );
        }

        return of(actions).pipe(mergeAll());
      },
      onError: (action: fromSelectedActivity.GetSelectedProspectFromServerSuccess, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectTasksFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectTasks.GET_PROSPECT_TASKS_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectTasks.GetProspectTasksFromServer, state: any) => {
        return this.apiService.get<Task[]>(`v/1/crm/tasks`, { params: action.payload }).pipe(
          map((tasks) => {
            return new fromSelectedProspectTasks.GetProspectTasksFromServerSuccess({ tasks });
          })
        );
      },
      onError: (action: fromSelectedProspectTasks.GetProspectTasksFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectSalesAppointmentsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectSalesAppointments.GET_PROSPECT_SALES_APPOINTMENTS_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectSalesAppointments.GetProspectSalesAppointmentsFromServer, state: any) => {
        return this.apiService.get<Appointment[]>(`v/1/crm/appointments`, { params: action.payload }).pipe(
          map((salesAppointments) => {
            return new fromSelectedProspectSalesAppointments.GetProspectSalesAppointmentsFromServerSuccess({
              salesAppointments,
            });
          })
        );
      },
      onError: (action: fromSelectedProspectSalesAppointments.GetProspectSalesAppointmentsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectDealerPromotionsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectDealerPromotions.GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectDealerPromotions.GetProspectDealerPromotionsFromServer, state: any) => {
        return this.apiService
          .get<DealerPromotion[]>(`v/1/crm/dealer-promotion-tasks`, { params: action.payload })
          .pipe(
            map((dealerPromotions) => {
              return new fromSelectedProspectDealerPromotions.GetProspectDealerPromotionsFromServerSuccess({
                dealerPromotions,
              });
            })
          );
      },
      onError: (action: fromSelectedProspectDealerPromotions.GetProspectDealerPromotionsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectServiceAppointmentsFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectServiceAppointments.GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectServiceAppointments.GetProspectServiceAppointmentsFromServer, state: any) => {
        return this.apiService
          .get<ServiceAppointment[]>(`v/1/fixed-operations/appointments`, { params: action.payload })
          .pipe(
            map((serviceAppointments) => {
              return new fromSelectedProspectServiceAppointments.GetProspectServiceAppointmentsFromServerSuccess({
                serviceAppointments,
              });
            })
          );
      },
      onError: (action: fromSelectedProspectServiceAppointments.GetProspectServiceAppointmentsFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  getSelectedProspectOpenSalesFromServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectOpenSales.GET_SELECTED_PROSPECT_OPEN_SALES_FROM_SERVER, {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSelectedProspectOpenSales.GetSelectedProspectOpenSalesFromServer, state: any) => {
        return this.apiService
          .get<Sale[]>(`v/1/vehicles/sales`, {
            params: action.payload,
          })
          .pipe(
            map((openSales) => {
              return new fromSelectedProspectOpenSales.GetSelectedProspectOpenSalesFromServerSuccess(openSales);
            })
          );
      },
      onError: (action: fromSelectedProspectOpenSales.GetSelectedProspectOpenSalesFromServer, error) => {
        console.error('Error', error);
      },
    })
  );

  updateSelectedProspectOpenSaleOnServer = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedProspectOpenSales.UPDATE_SELECTED_PROSPECT_OPEN_SALE_ON_SERVER, {
      run: (action: fromSelectedProspectOpenSales.UpdateSelectedProspectOpenSaleOnServer, state: any) => {
        let actions: Action[] = [];
        return this.apiService.put<Sale>(`v/1/vehicles/sales/${action.payload.id}`, action.payload).pipe(
          flatMap((sale) => {
            actions.push(new fromSelectedProspectOpenSales.UpdateSelectedProspectOpenSaleOnServerSuccess(sale));
            return of(actions).pipe(mergeAll());
          })
        );
      },
      onError: (action: fromSelectedProspectOpenSales.UpdateSelectedProspectOpenSaleOnServer, error) => {
        console.error('Error', error);
      },
    })
  );

  createSelectedProspectVehicleOfInterestFromServer = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedProspectVehicleOfInterest.CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER,
      {
        run: (
          action: fromSelectedProspectVehicleOfInterest.CreateSelectedProspectVehicleOfInterestFromServer,
          state: any
        ) => {
          return this.apiService.post<VehicleOfInterest>(`v/1/crm/vehicle-of-interests`, action.payload).pipe(
            map((vehicleOfInterest) => {
              return new fromSelectedProspectVehicleOfInterest.CreateSelectedProspectVehicleOfInterestFromServerSuccess(
                vehicleOfInterest
              );
            })
          );
        },
        onError: (
          action: fromSelectedProspectVehicleOfInterest.CreateSelectedProspectVehicleOfInterestFromServer,
          error
        ) => {
          console.error('Error', error);
        },
      }
    )
  );

  getSelectedProspectVehicleOfInterestFromServer = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedProspectVehicleOfInterest.GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER,
      {
        id: (a: any, state: any) => {
          return 1;
        },
        run: (
          action: fromSelectedProspectVehicleOfInterest.GetSelectedProspectVehicleOfInterestFromServer,
          state: any
        ) => {
          return this.apiService
            .get<VehicleOfInterest[]>(`v/1/crm/vehicle-of-interests`, {
              params: action.payload,
            })
            .pipe(
              map((vehicleOfInterest) => {
                return new fromSelectedProspectVehicleOfInterest.GetSelectedProspectVehicleOfInterestFromServerSuccess(
                  vehicleOfInterest
                );
              })
            );
        },
        onError: (
          action: fromSelectedProspectVehicleOfInterest.GetSelectedProspectVehicleOfInterestFromServer,
          error
        ) => {
          console.error('Error', error);
          return new fromSelectedProspectVehicleOfInterest.GetSelectedProspectVehicleOfInterestFromServerFailure(error);
        },
      }
    )
  );

  deleteSelectedProspectVehicleOfInterestFromServer = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedProspectVehicleOfInterest.DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER,
      {
        run: (
          action: fromSelectedProspectVehicleOfInterest.DeleteSelectedProspectVehicleOfInterestFromServer,
          state: any
        ) => {
          return this.apiService
            .delete<VehicleOfInterest>(`v/1/crm/vehicle-of-interests/${action.payload.id}`, action.payload)
            .pipe(
              map((vehicleOfInterest) => {
                return new fromSelectedProspectVehicleOfInterest.DeleteSelectedProspectVehicleOfInterestFromServerSuccess(
                  action.payload.id
                );
              })
            );
        },
        onError: (
          action: fromSelectedProspectVehicleOfInterest.DeleteSelectedProspectVehicleOfInterestFromServer,
          error
        ) => {
          console.error('Error', error);
        },
      }
    )
  );

  updateSelectedProspectVehicleOfInterestFromServer = createEffect(() =>
    this.dataPersistence.fetch(
      fromSelectedProspectVehicleOfInterest.UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER,
      {
        run: (
          action: fromSelectedProspectVehicleOfInterest.UpdateSelectedProspectVehicleOfInterestFromServer,
          state: any
        ) => {
          return this.apiService
            .put<VehicleOfInterest>(`v/1/crm/vehicle-of-interests/${action.payload.id}`, action.payload)
            .pipe(
              map((vehicleOfInterest) => {
                return new fromSelectedProspectVehicleOfInterest.UpdateSelectedProspectVehicleOfInterestFromServerSuccess(
                  vehicleOfInterest
                );
              })
            );
        },
        onError: (
          action: fromSelectedProspectVehicleOfInterest.UpdateSelectedProspectVehicleOfInterestFromServer,
          error
        ) => {
          console.error('Error', error);
        },
      }
    )
  );

  reassignSelectedProspectActivities = createEffect(() =>
    this.dataPersistence.fetch(fromSelectedActivity.REASSIGN_SELECTED_PROSPECT_ACTIVITIES, {
      run: (action: fromSelectedActivity.ReassignSelectedProspectActivities, state: any) => {
        let totalActivitiesReassigned: number = 0;
        return this.apiService
          .put<any>(
            `v/1/crm/prospects/${action.payload.prospect.id}/workflow/reassignTasks/${action.payload.prospect.primaryEmployeeId}`,
            action.payload.oldProspect
          )
          .pipe(
            flatMap((result) => {
              Object.keys(result).forEach((key) => {
                totalActivitiesReassigned += result[key];
              });

              let toastOptions: any = {
                message: `${totalActivitiesReassigned} actitivies reassigned.`,
                actionText: null,
                options: { duration: 3000, horizontalPosition: 'left' },
              };

              this.salesplannerStateService.addToast(toastOptions);

              combineLatest([
                this.authenticationStateService.selectAuthenticatedUser(),
                this.authenticationStateService.selectAuthenticatedEmployee(),
                this.store.pipe(select(employees)),
              ]).subscribe(([authenticatedUser, authenticatedEmployee, employees]) => {
                const members: Member[] = [];

                let previousSalesperson = Object.values(employees).find(
                  (employee) => employee.associateId === action.payload.prospect.previousEmployeeId
                );

                let currentSalesperson = Object.values(employees).find(
                  (employee) => employee.associateId === action.payload.prospect.primaryEmployeeId
                );

                const event: Event = new Event();
                event.conversationId = null;
                event.memberId = authenticatedUser.user.id.toString();
                event.eventTypeId = EventTypes.Message;
                event.content = `Prospect ${action.payload.prospect.embedded.associate.firstName} ${action.payload.prospect.embedded.associate.lastName} was just reassigned from ${previousSalesperson.embedded.associate.firstName} ${previousSalesperson.embedded.associate.lastName} to ${currentSalesperson.embedded.associate.firstName} ${currentSalesperson.embedded.associate.lastName}`;
                event.guid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                event.sentDate = new Date();
                event.sent = false;
                event.sendToCustomer = false;
                event.id = Math.floor(Math.random() * (9999999 - 999999)) + 999999;

                const conversation: Conversation = new Conversation();
                conversation.dmsTransactionTypeId = 5;
                conversation.dmsTransactionId = action.payload.prospect.id.toString();
                conversation.dmsMessageTypeId = 4;

                let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                  transactionTypeId: 5,
                  messageTypeId: 4,
                  transactionId: action.payload.prospect.id.toString(),
                });

                const member: Member = new Member();
                member.userId = authenticatedUser.user.id.toString();
                member.avatarUrl = authenticatedUser.user.avatarUrl;
                member.firstName = authenticatedUser.user.firstName;
                member.lastName = authenticatedUser.user.lastName;
                member.nickname = authenticatedUser.user.firstName;
                member.isEmployee = true;
                member.isRead = true;
                member.isArchived = false;
                member.isExcluded = false;
                member.isPinnedToUi = false;
                members.push(member);

                let optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'true' });

                this.apiService
                  .get<any>('v/1/common/transaction-types/5/workflow/communicatorUsernames', {
                    params: {
                      rooftopId: authenticatedEmployee.rooftopId,
                      transactionId: action.payload.prospect.id,
                      automatedMessageTypeId: 4,
                      transactionTypeId: 5,
                    },
                  })
                  .pipe(
                    switchMap((usernames: any) => {
                      let usernameList = usernames.map((u: any) => u.username).join(',');

                      let userParams: UserQueryParameters = new UserQueryParameters({ username: usernameList });
                      return this.apiService.get<any>('quorum/users', { params: userParams }).pipe(
                        map((users) => {
                          Object.keys(users).forEach((key) => {
                            const user = users[key];

                            Object.keys(usernames).forEach((key) => {
                              const username = usernames[key].username;
                              if (user.username == username) {
                                const member: Member = new Member();
                                member.userId = user.id.toString();
                                member.avatarUrl = user.avatarUrl;
                                member.firstName = user.firstName;
                                member.lastName = user.lastName;
                                member.nickname = user.firstName;
                                member.isEmployee = true;
                                member.isRead = true;
                                member.isArchived = false;
                                member.isExcluded = false;
                                member.isPinnedToUi = false;
                                members.push(member);
                              }
                            });
                          });
                        })
                      );
                    })
                  )
                  .subscribe((result) => {
                    this.communicatorFacade.sendCommunicatorMessage(
                      conversationQueryParameters,
                      conversation,
                      members,
                      event,
                      optionalHeader
                    );
                  });
              });

              let actions: Action[] = [];
              actions.push(
                new fromSelectedActivity.GetSelectedProspectFromServer(
                  new ProspectQueryParameters({
                    id: action.payload.prospect.id.toString(),
                    embed: ['associate', 'primaryEmployeeAssociate'],
                  })
                )
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSelectedActivity.ReassignSelectedProspectActivities, error) => {
        console.error('Error', error);
      },
    })
  );

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<SelectedActivityState>,
    private apiService: ApiService,
    private localSettingsStateService: LocalSettingsStateService,
    private salesplannerStateService: SalesPlannerStateService,
    private communicatorService: CommunicatorService,
    private authenticationStateService: AuthenticationStateService,
    private selectedActivityStateService: SelectedActivityStateService,
    private communicatorFacade: CommunicatorFacade,
    private store: Store<any>
  ) {}
}
