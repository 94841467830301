import * as fromSources from './sources.actions';
import { initialState, sourcesAdapter, SourcesState } from './sources.interfaces';

export function reducer(state: SourcesState = initialState, action: fromSources.SourcesAction): SourcesState {
  switch (action.type) {
    case fromSources.GET_SOURCES_FROM_SERVER_SUCCESS: {
      return {
        ...sourcesAdapter.addMany(action.payload.sources, state)
      };
    }

    default: {
      return state;
    }
  }
}
