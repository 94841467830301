import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface CrmSettingsState {
  autovanceDeskRole: number;
  autovanceUserGuid: string;
  salesPlannerView: number;
  teamLead: boolean;
  rooftopId: number;
}

export const crmSettingsFeatureSelector = createFeatureSelector<any>('crmSettings');
export const settingsStateSelector = createSelector(
  crmSettingsFeatureSelector,
  (state: any) => state
);
