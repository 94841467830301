import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface SalesPlannerUIState {
  [key: string]: any;
  actionsVisible: boolean;
  notesVisible: boolean;
  openActivitiesVisible: boolean;
  closedActivitiesVisible: boolean;
  purchaseOrderDetailsVisible: boolean;
  openSalesVisible: boolean;
  prospectAggregateVisible: boolean;
  prospectDetailsVisible: boolean;
  responseLeadsVisible: boolean;
  vehicleOfInterestVisible: boolean;
  vehicleAggregateVisible: boolean;
  serviceHistoryVisible: boolean;
  sidenavVisible: boolean;
  prospectLeads: any;
}

export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const uiSelector = createSelector(salesplannerLegacyFeatureSelector, (state: any) => state.ui);
export const openActivitiesVisibleSelector = createSelector(uiSelector, (state: any) => state.openActivitiesVisible);
export const closedActivitiesVisibleSelector = createSelector(
  uiSelector,
  (state: any) => state.closedActivitiesVisible
);
export const purchaseOrderDetailsVisibleSelector = createSelector(
  uiSelector,
  (state: any) => state.purchaseOrderDetailsVisible
);
export const responseLeadsVisibleSelector = createSelector(uiSelector, (state: any) => state.responseLeadsVisible);
export const vehicleOfInterestVisibleSelector = createSelector(
  uiSelector,
  (state: any) => state.vehicleOfInterestVisible
);
export const actionsVisibleSelector = createSelector(uiSelector, (state: any) => state.actionsVisible);
export const openSalesVisibleSelector = createSelector(uiSelector, (state: any) => state.openSalesVisible);
export const notesVisibleSelector = createSelector(uiSelector, (state: any) => state.notesVisible);
export const serviceHistoryVisibleSelector = createSelector(uiSelector, (state: any) => state.serviceHistoryVisible);
export const vehicleAggregateVisibleSelector = createSelector(
  uiSelector,
  (state: any) => state.vehicleAggregateVisible
);
export const prospectDetailsVisibleSelector = createSelector(uiSelector, (state: any) => state.prospectDetailsVisible);
export const prospectAggregateVisibleSelector = createSelector(
  uiSelector,
  (state: any) => state.prospectAggregateVisible
);
export const sidenavVisibleSelector = createSelector(uiSelector, (state: any) => state.sidenavVisible);
export const localSettingsState = createSelector(salesplannerLegacyFeatureSelector, (state: any) => state.uiSettings);
export const prospectLeadsSelector = createSelector(uiSelector, (state: any) => state.prospectLeads);
