import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { DealerPromotionQueryParameters } from '@quorum/models/xs-query';
import { DealerPromotion } from '@quorum/models/xs-resource';

export const GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER = '[CRM] Get Prospect Dealer Promotions From Server';
export class GetProspectDealerPromotionsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: DealerPromotionQueryParameters) {}
}

export const GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER_SUCCESS =
  '[CRM] Get Prospect Dealer Promotions  From Server Success';
export class GetProspectDealerPromotionsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { dealerPromotions: DealerPromotion[] }) {}
}

export const GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER_FAILURE =
  '[CRM] Prospect Get Dealer Promotions  From Server Failure';
export class GetProspectDealerPromotionsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_DEALER_PROMOTIONS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_PROSPECT_DEALER_PROMOTION_IN_STATE = '[CRM] Update Prospect Dealer Promotion in State';
export class UpdateProspectDealerPromotionInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_DEALER_PROMOTION_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: Update<DealerPromotion>) {}
}

export type ProspectDealerPromotionsAction =
  | GetProspectDealerPromotionsFromServer
  | GetProspectDealerPromotionsFromServerSuccess
  | GetProspectDealerPromotionsFromServerFailure;
