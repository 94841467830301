import { Action } from '@ngrx/store';
import { MessageTypeQueryParameters } from '@quorum/models/xs-query';
import { MessageType } from '@quorum/models/xs-resource';

export const GET_MESSAGE_TYPES_FROM_SERVER = '[CRM] Get Associate Message Types From Server';
export class GetAssociateMessageTypesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_MESSAGE_TYPES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: MessageTypeQueryParameters) {}
}

export const GET_MESSAGE_TYPES_FROM_SERVER_SUCCESS = '[CRM] Get Associate Message Types From Server Success';
export class GetAssociateMessageTypesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_MESSAGE_TYPES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { messageTypes: MessageType[] }) {}
}

export const GET_MESSAGE_TYPES_FROM_SERVER_FAILURE = '[CRM] Get Associate Message Types From Server Failure';
export class GetAssociateMessageTypesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_MESSAGE_TYPES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type AssociateMessageTypesAction =
  | GetAssociateMessageTypesFromServer
  | GetAssociateMessageTypesFromServerSuccess
  | GetAssociateMessageTypesFromServerFailure;
