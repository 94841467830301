export class BlackbookValuesSettingQueryParameters {
  blackbookPriceTypeId?: string;
  id?: string;
  vehicleId?: string;

  constructor(obj?: BlackbookValuesSettingQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
