import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COMMUNICATOR_FEATURE_KEY, State, CommunicatorPartialState, communicatorAdapter } from './communicator.reducer';

// Lookup the 'Communicator' feature state managed by NgRx
export const getCommunicatorState = createFeatureSelector< State>(COMMUNICATOR_FEATURE_KEY);

const { selectAll, selectEntities } = communicatorAdapter.getSelectors();

export const getCommunicatorLoaded = createSelector(getCommunicatorState, (state: State) => state.loaded);

export const getCommunicatorError = createSelector(getCommunicatorState, (state: State) => state.error);

export const getAllCommunicator = createSelector(getCommunicatorState, (state: State) => selectAll(state));

export const getCommunicatorEntities = createSelector(getCommunicatorState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getCommunicatorState, (state: State) => state.selectedId);

export const getSelected = createSelector(
  getCommunicatorEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
