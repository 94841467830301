import { Injectable } from '@angular/core';
import { Vehicle } from '@quorum/models/xs-resource';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { Address, Associate, Prospect, ProspectAction } from '@quorum/models/xs-resource';
import { fromProspectActions, fromVehicles } from '@quorum/xsr-salesplanner-legacy/state';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as fromProspects from './prospects/prospects.actions';

@Injectable()
export class CrmEntityStateService {
  constructor(
    private store: Store<any>,
    private actions: ActionsSubject,
    private authStateService: AuthenticationStateService
  ) {}

  clearProspectState() {
    this.store.dispatch(new fromProspects.ClearProspectState({}));
  }

  getProspectsFromServer(queryParams: ProspectQueryParameters) {
    this.store.dispatch(new fromProspects.GetProspectsFromServer(queryParams));
  }

  createProspectOnServer(newProspect: Prospect, salespersonTakeoverMessage: string) {
    this.store.dispatch(
      new fromProspects.CreateProspectOnServer({
        prospect: newProspect,
        salespersonTakeoverMessage: salespersonTakeoverMessage,
      })
    );
  }

  createProspectAndAssociateOnServer(
    newAddress: Address,
    newAssociate: Associate,
    newProspect: Prospect,
    salespersonTakeoverMessage: string
  ) {
    this.store.dispatch(
      new fromProspects.CreateProspectAndAssociateOnServer({
        address: newAddress,
        associate: newAssociate,
        prospect: newProspect,
        salespersonTakeoverMessage: salespersonTakeoverMessage,
      })
    );
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  updateProspectOnServer(
    prospect: Prospect,
    displayToast: boolean,
    salespersonTakeoverData?: { salespersonTakeoverMessage: string; salespersonTakeoverProspectAction: ProspectAction }
  ) {
    this.store.dispatch(new fromProspects.UpdateProspectOnServer({ prospect, displayToast, salespersonTakeoverData }));
  }

  updateAssociateOnServer(associate: any) {
    this.store.dispatch(new fromProspects.UpdateAssociateOnServer(associate));
  }

  updateProspectAndAssociateOnServer(
    associate: Associate,
    prospect: Prospect,
    address: Address,
    salespersonTakeoverData?: { salespersonTakeoverMessage: string; salespersonTakeoverProspectAction: ProspectAction }
  ) {
    this.store.dispatch(
      new fromProspects.UpdateProspectAssociateOnServer({
        associate: associate,
        prospect: prospect,
        address: address,
        salespersonTakeoverData: salespersonTakeoverData,
      })
    );
  }

  updateProspectAndCreateAssociateAndAddressOnServer(newAddress: Address, newAssociate: Associate, prospect: Prospect) {
    this.store.dispatch(
      new fromProspects.UpdateProspectAndCreateAssociateAndAddressOnServer({
        address: newAddress,
        associate: newAssociate,
        prospect: prospect,
      })
    );
  }

  postProspectCreationProcess(actionId: number = null) {
    return this.actions.pipe(
      ofType(
        fromProspects.CREATE_PROSPECT_ON_SERVER_SUCCESS,
        fromProspects.CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS
      ),
      switchMap((a: any) => {
        return this.authStateService.selectAuthenticatedUser().pipe(
          map((authenticatedUser) => {
            if (a.payload.prospect.actionId != null) {
              let newProspectAction: ProspectAction = {
                ...new ProspectAction(),
                appointmentDateTime: new Date(),
                enteredDateTime: new Date(),
                taskActionId: a.payload.prospect.actionId,
                associateEmployeeId: authenticatedUser.user.xselleratorEmployeeId,
                prospectId: a.payload.prospect.id,
              };
              this.store.dispatch(
                new fromProspectActions.CreateSelectedProspectActionOnServer({
                  prospectAction: newProspectAction,
                  prospect: a.payload.prospect,
                })
              );
            }
          }),
          switchMap((b) => {
            if (a.payload.prospect.actionId != null) {
              return this.actions.pipe(
                ofType(fromProspectActions.CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER_SUCCESS),
                map((action) => {
                  return a.payload.prospect;
                  // if (nagivateToProspect) {
                  //   this.store.dispatch(
                  //     new fromRouter.Go({ path: [`crm/sales-planner/prospects/${a.payload.prospect.id}`] })
                  //   );
                  // }
                })
              );
            } else {
              // if (nagivateToProspect) {
              //   this.store.dispatch(
              //     new fromRouter.Go({ path: [`crm/sales-planner/prospects/${a.payload.prospect.id}`] })
              //   );
              // }
              return from([a.payload.prospect]);
            }
          })
        );
      })
    );
  }

  postProspectCreateAndUpdate() {
    return this.actions.pipe(
      ofType(
        fromProspects.CREATE_PROSPECT_ON_SERVER_SUCCESS,
        fromProspects.CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS,
        fromProspects.UPDATE_PROSPECT_ON_SERVER_SUCCESS,
        fromProspects.UPDATE_PROSPECT_AND_CREATE_ASSOCIATE_AND_ADDRESS_ON_SERVER_SUCCESS
      )
    );
  }

  reassignVehicle(vehicleParams: Vehicle, prospectId: number) {
    this.store.dispatch(new fromVehicles.ReassignVehicle({ vehicleParams: vehicleParams, prospectId: prospectId }));
  }

  addNewVehicle(vehicleParams: Vehicle, translateRequired: boolean) {
    this.store.dispatch(
      new fromVehicles.AddNewVehicle({ vehicleParams: vehicleParams, translateRequired: translateRequired })
    );
  }
}
