<div fxLayout="column" class="card-container">
  <div class="card-header-container" [ngStyle]="{
      'background-color': vehicleSearchResult.vehicleCodeColorDescription
        ? vehicleSearchResult.vehicleCodeColorDescription
        : 'rgb(253, 253, 253)'
    }" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center center" class="title-container">
      <h3>
        {{
        vehicleSearchResult.vehicleYear +
        ' ' +
        vehicleSearchResult.make +
        '
        ' +
        vehicleSearchResult.description
        }}
      </h3>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="card-content-container" fxLayout="column">
    <div fxLayout="column">
      <div fxLayoutAlign="start center">
        <span class="mat-body-1 card-line-1">ID
          <a [ngStyle]="{
              color: browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)' : '#000',
              cursor: browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''
            }" (click)="
              browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [
                vehicleSearchResult.vehicleId.toString()
              ])
            " class="mat-body-1">{{ vehicleSearchResult.vehicleId }}</a>, Unit # {{ vehicleSearchResult.unitNumber ?
          vehicleSearchResult.unitNumber : 'unknown' }},
          {{ vehicleSearchResult.vehicleStatusDescription }}
          {{ vehicleSearchResult.subCategoryDescription }}</span>
      </div>
      <div fxLayoutAlign="start center">
        <span class="mat-body-1 card-line-2">
          P1 {{ vehicleSearchResult.price1 | currency }}, P2 {{ vehicleSearchResult.price2 | currency }}, P3
          {{ vehicleSearchResult.price3 | currency }}</span>
      </div>
      <div fxLayoutAlign="start center">
        <span class="primary-bullet">· </span><span class="secondary-text">{{
          vehicleSearchResult.vehicleDetailColorDescription
          ? vehicleSearchResult.vehicleDetailColorDescription
          : vehicleSearchResult.vehicleProspectColorDescription
          }}{{ vehicleSearchResult.vehicleInteriorColorDescription ? ', ' +
          vehicleSearchResult.vehicleInteriorColorDescription : ''}}
        </span>
      </div>
      <div fxLayoutAlign="start center">
        <span class="primary-bullet">· </span><span class="secondary-text">Last mileage on {{
          vehicleSearchResult.lastMileageDate | moment: 'MM/DD/YYYY' }} @
          {{ vehicleSearchResult.currentMileage | number: '.0' }}</span>
      </div>

      <div fxLayoutAlign="start center">
        <span class="primary-bullet">·</span>
        <span class="secondary-text"> {{ vehicleSearchResult.days }} days in stock</span>
      </div>
      <div fxLayoutAlign="start center">
        <div fxLayoutAlign="start center">
          <span class="primary-bullet">·</span>
          <span class="secondary-text">Model {{ vehicleSearchResult.modelCode ? vehicleSearchResult.modelCode :
            'unknown'}}, PEG {{ vehicleSearchResult.pegCode ? vehicleSearchResult.pegCode : 'unknown'
            }}</span>
        </div>
      </div>
      <div fxLayoutAlign="start center">
        <span class="primary-bullet">· </span><span class="secondary-text">{{ vehicleSearchResult.rooftopDescription
          }}</span>
        <ng-container [ngSwitch]="vehicleSearchResult.saleStatusId">
          <span class="secondary-text" *ngSwitchCase="'B'">, Business Office</span>
          <span class="secondary-text" *ngSwitchCase="'P'">, Pending</span>
          <span class="secondary-text" *ngSwitchDefault></span>
        </ng-container>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="card-actions-container">
        <div fxFlex>
          <ng-container *ngIf="selectedProspect; else standaloneVehicleSearch">
            <button mat-button *ngIf="autovanceEnabled != null && autovanceEnabled == true; else createSale"
              color="primary" (click)="createDeskedDeal(selectedProspect, vehicleSearchResult, localSettings)"
              [disabled]="deskButtonDisabled">
              Desk
            </button>
            <ng-template #createSale>
              <button *ngIf="drilldownEnabled" mat-button color="primary"
                (click)="createVehicleSale(selectedProspect, vehicleSearchResult, drilldownEnabled, localSettings)">
                Create Sale
              </button>
            </ng-template>
          </ng-container>
          <ng-template #standaloneVehicleSearch>
            <div>
              <div>
                <button mat-button color="primary"
                  (click)="openCreateProspectDialog(vehicleSearchResult, authenticatedEmployee, prospectDropdowns)">
                  New Prospect
                </button>
              </div>
            </div>
          </ng-template>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="mat-caption secondary-text menu-button"
          *ngIf="drilldownEnabled">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [disabled]="vehicleSearchResult.saleCount == null || vehicleSearchResult.saleCount == 0"
            (click)="openVehicleSales(vehicleSearchResult)">
            <span>Open Sales</span>
          </button>
          <button mat-menu-item
            [disabled]="vehicleSearchResult.invoiceNumber == null || vehicleSearchResult.invoiceNumber.length == 0"
            (click)="openVehicleInvoice(vehicleSearchResult)">
            <span>Open Invoice</span>
          </button>
          <div *ngIf="selectedProspect">
            <button mat-menu-item *ngIf="autovanceEnabled != null && autovanceEnabled == true"
              (click)="createVehicleSale(selectedProspect, vehicleSearchResult, drilldownEnabled, localSettings)">
              <span>Create Sale</span>
            </button>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
</div>