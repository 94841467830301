import * as fromVehicleSubcategories from './vehicle-subcategories.actions';
import {
  initialState,
  vehicleSubcategoriesAdapter,
  VehicleSubcategoriesState
} from './vehicle-subcategories.interfaces';

export function reducer(
  state: VehicleSubcategoriesState = initialState,
  action: fromVehicleSubcategories.VehicleSubcategoriesAction
): VehicleSubcategoriesState {
  switch (action.type) {
    case fromVehicleSubcategories.GET_VEHICLE_SUBCATEGORIES_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleSubcategoriesAdapter.addMany(action.payload.vehicleSubcategories, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
