import { Injectable } from '@angular/core';
import { ServiceAppointmentQueryParameters as AppointmentQueryParameters } from '@quorum/models/xs-query';
import { Store, select } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromServiceAppointments from './service-appointments.actions';
import {
  isServiceAppointmentsLoadingSelector,
  selectAll as selectServiceAppointmentsCombined
} from './service-appointments.interfaces';

@Injectable()
export class ServiceAppointmentsStateService {
  constructor(private store: Store<any>) {}

  selectServiceAppointmentsLoading() {
    return this.store.pipe(select(isServiceAppointmentsLoadingSelector));
  }

  selectServiceAppointmentsCombined() {
    return combineLatest(
      this.store.pipe(select(selectServiceAppointmentsCombined)),
      this.selectServiceAppointmentsLoading()
    ).pipe(
      map(([serviceAppointments, isLoading]) => {
        return { serviceAppointments, isLoading };
      }),
      filter(result => {
        return result.isLoading == false;
      }),
      map(result => {
        return new DeepCopyPipe().transform(result);
      })
    );
  }

  getServiceAppointmentsFromServer(queryParams: AppointmentQueryParameters) {
    this.store.dispatch(new fromServiceAppointments.GetServiceAppointmentsFromServer(queryParams));
  }

  clearServiceAppointmentsFromState() {
    this.store.dispatch(new fromServiceAppointments.ClearServiceAppointmentsFromState());
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
