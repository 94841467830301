export class VehicleSearchQueryParameters {
  categoryId?: string;
  currentUserId?: string;
  description?: string;
  genericModelId?: string;
  id?: string;
  make?: string;
  maxPrice?: string;
  maxYear?: string;
  minPrice?: string;
  minYear?: string;
  modelCode?: string;
  page?: string;
  pageSize?: string;
  colorId?: string;
  rooftopId?: string;
  rpos?: string;
  sort?: string;
  subCategoryId?: string;
  subTypeId?: string;
  unitNumber?: string;
  vehicleColourDescription?: string;
  vehicleStatusId?: string;
  vin?: string;

  constructor(obj?: VehicleSearchQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
