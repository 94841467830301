export class LocationQueryParameters {
  areaCode?: string;
  countryId?: string;
  id?: string;
  page?: number;
  pageSize?: number;
  postalCode?: string;
  provStateId?: string;
  search?: string;

  constructor(obj?: LocationQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
