<div>
  <div fxLayout="column">
    <div id="header" fxLayout="row" class="header-container" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div fxLayout="column">
          <span fxLayoutAlign="start center" class="mat-subheading-2">
            <mat-icon>event_note</mat-icon>Task {{ task?.id }}
          </span>
          <span class="mat-caption" *ngIf="task?.completedDate">Completed {{ task?.completedDate | moment: 'dddd, MMM
            Do, YYYY' }}</span>
        </div>
      </div>
    </div>
    <form fxLayout="row wrap" [formGroup]="taskCardForm">
      <mat-form-field fxFlex="50">
        <input matInput autocomplete="off" [matDatepicker]="picker" placeholder="Date" name="dueDate"
          formControlName="dueDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select (selectionChange)="outcomeSelected($event)" placeholder="Outcome" name="outcome"
          *ngIf="taskOutcomes$ | async as taskOutcomes" formControlName="outcomeId">
          <mat-option *ngFor="let taskOutcome of taskOutcomes" [value]="taskOutcome.id">
            {{ taskOutcome.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="50" fxFlex.xs="100">
        <textarea matInput placeholder="Objectives" name="note" formControlName="note" matTextareaAutosize
          matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <mat-form-field fxFlex="50" fxFlex.xs="100">
        <textarea #comments matInput placeholder="Comments" name="comments" formControlName="comments"
          [required]="taskCardForm.get('outcomeId').value" matTextareaAutosize matAutosizeMinRows="1"
          matAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <mat-form-field fxFlex="100" fxFlex.xs="100">
        <textarea matInput placeholder="Preparation Work" name="prepWork" formControlName="prepWork" matTextareaAutosize
          matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <!--
        <mat-form-field fxFlex="50">
          <mat-select multiiple placeholder="Employee">
            <mat-option value="dropdowns.salespeople.currentUser.EmployeeId">{{dropdowns.salespeople.currentUser.EmployeeName}}</mat-option>
            <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams" [label]="salesTeam.teamName">
              <mat-option *ngFor="let salesperson of salesTeam.children" value="salesperson.EmployeeId">{{salesperson.EmployeeName}}</mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field> -->
    </form>
    <div fxLayout="row" fxLayoutAlign="center center">
      <div fxLayout="row">
        <span class="mat-caption">{{ outcomeAction }}</span>
        <div fxLayout="row" fxLayoutAlign="center center" class="button-container">
          <button mat-raised-button color="primary" [disabled]="taskCardForm.invalid || task.completedDate"
            (click)="updateTask(taskCardForm.value)">
            Update
          </button>
        </div>
        <div *ngIf="associate?.messagePreferenceId == 2; else emailTemplate">
          <div *ngIf="directMessageTemplates$ | async as directMessageTemplates" fxLayout="row"
            fxLayoutAlign="center center" class="button-container">
            <button mat-raised-button color="primary" [disabled]="task.completedDate"
              *ngIf="prospect.associateId && associate.messagePreferenceId == 2 && communicatorEnabled"
              (click)="openCommunicatorTextDialog(directMessageTemplates, prospect, currentUser, task)">
              Send Text
            </button>
          </div>
        </div>
        <ng-template #emailTemplate>
          <div *ngIf="directMessageTemplates$ | async as directMessageTemplates">
            <div *ngIf="htmlMappingTemplates$ | async as htmlMappingTemplates" fxLayout="row"
              fxLayoutAlign="center center" class="button-container">
              <button mat-raised-button color="primary" [disabled]="task.completedDate"
                *ngIf="prospect.associateId && associate.messagePreferenceId == 1 && communicatorEnabled" (click)="
                  openCommunicatorEmailDialog(
                    directMessageTemplates,
                    htmlMappingTemplates,
                    associate,
                    prospect,
                    currentUser,
                    task
                  )
                ">
                Send Email
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
