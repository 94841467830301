export class Note {
  functionalAreaId: string = null;
  id?: number = null;
  lastModifiedAssociateName: string = null;
  lastModifiedDate: string = null;
  notes: string = null;
  rooftopId: number = null;
  sequence: number = null;
  sourceReference1Id: string = null;
  sourceReference2Id: string = null;

  constructor(obj?: Note) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
