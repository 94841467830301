<div class="h-full flex flex-col">
    <h2 mat-dialog-title>Send Communicator Message</h2>
    <div mat-dialog-content class="flex flex-col">
        <mat-form-field class="w-full">
            <mat-chip-list #chipList>
                <mat-chip *ngFor="let name of names" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(name.id);this.autoTrigger.closePanel()"
                    class="h-9 bg-grey-300 rounded-3xl text-grey-800" (click)="this.autoTrigger.closePanel()">
                    <div class="flex items-center">
                        <div *ngIf="name.avatarUrl;else displayInitials">
                            <sha-avatar [avatarUrl]=name.avatarUrl [displayPresence]=false size='small'></sha-avatar>
                        </div>
                        <ng-template #displayInitials>
                            <sha-avatar [initials]=[name.firstName[0]+name.lastName[0]] [displayPresence]=false
                                size='small'></sha-avatar>
                        </ng-template>
                        <div class="ml-3">
                            <span class="text-sm font-medium text-grey-800 group-hover:text-grey-900">
                                {{name.firstName}} {{name.lastName}}
                            </span>
                        </div>
                    </div>
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="To..." #nameInput [formControl]="nameCtrl" [matAutocomplete]="auto"
                    [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event);openPanel()">
                <mat-option *ngFor="let name of filteredNames | async | orderBy: ['firstName']" [value]="name">
                    <div class="flex items-center">
                        <div *ngIf="name.avatarUrl;else displayInitials">
                            <sha-avatar [avatarUrl]=name.avatarUrl [displayPresence]=false size='medium'></sha-avatar>
                        </div>
                        <ng-template #displayInitials>
                            <sha-avatar [initials]=[name.firstName[0]+name.lastName[0]] [displayPresence]=false
                                size='medium'></sha-avatar>
                        </ng-template>
                        <div class="ml-3">
                            <span class="text-sm font-medium text-grey-800 group-hover:text-grey-900">
                                {{name.firstName}} {{name.lastName}}
                            </span>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div>
            <label for="message" class="block text-sm font-medium text-warm-grey-900">Message</label>
            <div class="mt-1">
                <textarea id="message" name="message" rows="6"
                    class="resize-none py-3 px-4 block w-full shadow-sm text-warm-grey-900 focus:ring-offset-quorum-blue focus:border-quorum-blue border border-grey-400 rounded-md">{{this.dialogData.message}}</textarea>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class='flex justify-between'>
        <div class="flex flex-row items-center">
            <div class="flex items-center">
                <div *ngIf="sendAsGroupEnabled;else sendAsGroupDisabled" class="flex items-center">
                    <button type="button" (click)="sendAsGroupEnabled=!sendAsGroupEnabled"
                        class="bg-quorum-blue relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-quorum-blue"
                        role="switch" aria-checked="false">
                        <span class="sr-only">Use setting</span>
                        <span
                            class="translate-x-5 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
                            <span
                                class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                aria-hidden="true">
                                <svg class="h-3 w-3 text-grey-400" fill="none" viewBox="0 0 12 12">
                                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <span
                                class="opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                aria-hidden="true">
                                <svg class="h-3 w-3 text-quorum-blue" fill="currentColor" viewBox="0 0 12 12">
                                    <path
                                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                </svg>
                            </span>
                        </span>
                    </button>
                    <span class="text-quorum-blue text-xs ml-3">Group message</span>
                </div>

                <ng-template #sendAsGroupDisabled class="flex items-center">
                    <button type="button" (click)="sendAsGroupEnabled=!sendAsGroupEnabled"
                        class="bg-grey-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-quorum-blue"
                        role="switch" aria-checked="false">
                        <span class="sr-only">Use setting</span>
                        <span
                            class="translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
                            <span
                                class="opacity-100 ease-in duration-200 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                aria-hidden="true">
                                <svg class="h-3 w-3 text-grey-400" fill="none" viewBox="0 0 12 12">
                                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </span>
                            <span
                                class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                                aria-hidden="true">
                                <svg class="h-3 w-3 text-quorum-blue" fill="currentColor" viewBox="0 0 12 12">
                                    <path
                                        d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                </svg>
                            </span>
                        </span>
                    </button>
                    <span class="text-grey-500 text-xs ml-3">Group message</span>
                </ng-template>
            </div>

        </div>

        <div>
            <button mat-button mat-dialog-close>Cancel</button>
            <button color="primary" mat-button [mat-dialog-close]="sendData()" cdkFocusInitial>Send</button>
        </div>
    </div>
</div>