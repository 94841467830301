<div fxLayout="column" *ngIf="activityPurchaseOrderDetails" class="w-full">
    <mat-nav-list class="purchase-order-container">
        <mat-list-item
            *ngFor="let activityPurchaseOrderDetail of (activityPurchaseOrderDetails | orderBy:'-id');let i = index">
            <div mat-list-icon class="type-icon icon-circle-green" *ngIf="activityPurchaseOrderDetail.statusId == '1'"
                fxLayout="row" fxLayoutAlign="center center">
                {{i+1}}
            </div>
            <div mat-list-icon class="type-icon icon-circle-red" *ngIf="activityPurchaseOrderDetail.statusId == '2'"
                fxLayout="row" fxLayoutAlign="center center">
                {{i+1}}
            </div>
            <span mat-line fxLayoutAlign="space-between center">
                <div fxLayoutAlign="start center">
                    <span>Purchase Order {{activityPurchaseOrderDetail.id}}&nbsp;-&nbsp;</span>
                    <div class="uppercase tracking-wider text-sm font-medium">
                        <span class="text-green-600" *ngIf="activityPurchaseOrderDetail.statusId == '1'">Open</span>
                        <span class="text-red" *ngIf="activityPurchaseOrderDetail.statusId == '2'">Closed</span>
                    </div>
                </div>
            </span>
            <div mat-line class="description">
                <div>{{activityPurchaseOrderDetail.description}}</div>
            </div>
            <div mat-line>
                <span *ngIf="activityPurchaseOrderDetail.workOrderId" class="secondary-text">Work Order
                    {{activityPurchaseOrderDetail.workOrderId}}</span>
            </div>
            <mat-divider inset *ngIf="i < activityPurchaseOrderDetails.length-1"></mat-divider>
        </mat-list-item>
    </mat-nav-list>
</div>