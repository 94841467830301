import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Action, ActionsSubject } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { RouterStateService } from '@quorum/sha-router';
import { fromProspects, ProspectsState } from '@quorum/xsr-entities/state';
import { SalesPlannerStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { of, combineLatest } from 'rxjs';
import { mergeAll, take, switchMap, map } from 'rxjs/operators';
import * as fromSelectedActivity from '../selected-activity/selected-activity.actions';
import * as fromSalesPlannerProspects from './prospects.actions';

@Injectable()
export class SalesPlannerProspectsEffects {
  
  checkIfProspectPageExistsInState = createEffect(() => this.dataPersistence.fetch(
    fromSalesPlannerProspects.CHECK_IF_PROSPECT_PAGE_EXISTS_IN_STATE,
    {
      run: (action: fromSalesPlannerProspects.CheckIfProspectPageExistsInState, state: any) => {
        const lowerBound = action.payload.page * action.payload.pageSize;
        const upperBound = action.payload.page * action.payload.pageSize + action.payload.pageSize;
        if (
          state.salesplannerLegacy.prospects.ids[lowerBound] != null &&
          state.salesplannerLegacy.prospects.ids[upperBound - 1] != null
        ) {
          return new fromSalesPlannerProspects.UpdateProspectPageDetailsInState(action.payload);
        } else {
          return new fromProspects.GetProspectsFromServer(action.payload);
        }
      },
      onError: (action: fromSalesPlannerProspects.CheckIfProspectPageExistsInState, error) => {
        console.error('Error', error);
      },
    }
  ));

  // @Effect()
  // createProspectAndAssociateOnServerSuccess = this.dataPersistence.fetch(
  //   fromProspects.CREATE_PROSPECT_AND_ASSOCIATE_ON_SERVER_SUCCESS,
  //   {
  //     run: (action: fromProspects.CreateProspectAndAssociateOnServerSuccess, state: any) => {
  //       return new fromProspects.CreateProspectOnServerSuccess({
  //         prospect: action.payload.prospect,
  //         salespersonTakeoverMessage: null
  //       });
  //     },

  //     onError: (action: fromProspects.CreateProspectAndAssociateOnServerSuccess, error) => {
  //       console.error('Error', error);
  //     }
  //   }
  // );

  // @Effect()
  // updateProspectOnServerSuccess = this.dataPersistence.fetch(fromProspects.UPDATE_PROSPECT_ON_SERVER_SUCCESS, {
  //   run: (action: fromProspects.UpdateProspectOnServerSuccess, state: ProspectsState) => {
  //     let actions: Action[] = [];

  //     if (action.payload.displayToast) {
  //       let toastOptions: any = {
  //         message: `Prospect updated.`,
  //         actionText: null,
  //         options: { duration: 3000, horizontalPosition: 'left' },
  //       };

  //       this.salesplannerStateService.addToast(toastOptions);
  //     }

  //     let prospect: any = action.payload.prospect.changes;
  //     actions.push(
  //       new fromSelectedActivity.UpdateSelectedProspectInState({
  //         id: action.payload.prospect.id,
  //         changes: { ...action.payload.prospect.changes },
  //       })
  //     );
  //     if (action.payload.prospect.changes.statusId == '2') {
  //       actions.push(new fromSelectedActivity.GetSelectedProspectFromServerSuccess(prospect));
  //     }
  //     return of(actions).pipe(mergeAll());
  //   },

  //   onError: (action: fromProspects.UpdateProspectOnServerSuccess, error) => {
  //     console.error('Error', error);
  //   },
  // });

  // @Effect()
  // createProspectOnServerSuccess = this.dataPersistence.fetch(fromProspects.CREATE_PROSPECT_ON_SERVER_SUCCESS, {
  //   run: (action: fromProspects.CreateProspectOnServerSuccess, state: any) => {
  //     let actionId;
  //     Object.keys(state.crmDropdowns.prospectActions.entities).forEach(key => {
  //       if (state.crmDropdowns.prospectActions.entities[key].description === 'Opportunity') {
  //         actionId = state.crmDropdowns.prospectActions.entities[key].id;
  //       }
  //     });

  //     let newProspectAction: ProspectAction = new ProspectAction({
  //       appointmentDateTime: new Date(),
  //       enteredDateTime: new Date(),
  //       taskActionId: actionId,
  //       associateEmployeeId: state.authentication.authenticatedUser.user.xselleratorEmployeeId,
  //       prospectId: action.payload.prospect.id
  //     });

  //     return concat(
  //       from([new fromProspectActions.CreateSelectedProspectActionOnServer(newProspectAction)]),
  //       this.actionsSub.pipe(
  //         filter(a => a.type === '[CRM] Create Tasks Mapped to Prospect Action On Server Success'),
  //         map(aa => {
  //           return new fromRouter.Go({ path: [`crm/sales-planner/prospects/${action.payload.prospect.id}`] });
  //         })
  //       )
  //     );
  //   },

  //   onError: (action: fromProspects.CreateProspectOnServerSuccess, error) => {
  //     console.error('Error', error);
  //   }
  // });

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<ProspectsState>,
    private routerStateService: RouterStateService,
    private salesplannerStateService: SalesPlannerStateService,
    private actionsSub: ActionsSubject
  ) {}
}
