export class SystemControlValue {
  constantReference: string = null;
  displayValue: string = null;
  helpComments: string = null;
  id: string = null;
  rooftopId: number = null;
  typeId: number = null;
  value: string = null;

  constructor(obj?: SystemControlValue) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
