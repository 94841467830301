import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser, Note } from '@quorum/models/xs-resource';
import { SelectedActivityStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { Observable } from 'rxjs';
import { ProspectNotesDialogComponent } from '../prospect-notes-dialog/prospect-notes-dialog.component';

@Component({
  selector: 'crm-prospect-notes',
  templateUrl: './prospect-notes.component.html',
  styleUrls: ['./prospect-notes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectNotesComponent implements OnInit {
  @Input() prospectNotes$: Observable<any>;
  @Input() prospectId: number;
  @Input() disableAddAction: boolean;
  notes$: Observable<any>;

  currentUser$: Observable<any>;
  constructor(
    public dialog: MatDialog,
    private authenticationStateService: AuthenticationStateService,
    private selectedActivityStateService: SelectedActivityStateService
  ) {}

  ngOnInit() {
    this.currentUser$ = this.authenticationStateService.selectAuthenticatedUser();
  }

  openNotesDialog(dialog: MatDialog, currentUser: AuthenticatedUser, prospectId: number, notes: any): void {
    const dialogRef = dialog.open(ProspectNotesDialogComponent, {
      data: { prospectId: prospectId, currentUser: currentUser, notes: notes },
    });

    dialogRef.afterClosed().subscribe((note: Note) => {
      if (note) {
        if (note.id) {
          this.selectedActivityStateService.updateSelectedProspectNoteOnServer(note);
        } else {
          this.selectedActivityStateService.createSelectedProspectNoteOnServer(note);
        }
      }
    });
  }
}
