import { Action } from '@ngrx/store';
import { TaskOutcomeQueryParameters } from '@quorum/models/xs-query';
import { TaskOutcome } from '@quorum/models/xs-resource';

export const GET_TASK_OUTCOMES_FROM_SERVER = '[CRM] Get Task Outcomes From Server';
export class GetTaskOutcomesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_TASK_OUTCOMES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: TaskOutcomeQueryParameters) {}
}

export const GET_TASK_OUTCOMES_FROM_SERVER_SUCCESS = '[CRM] Get Task Outcomes From Server Success';
export class GetTaskOutcomesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_TASK_OUTCOMES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { taskOutcomes: TaskOutcome[] }) {}
}

export const GET_TASK_OUTCOMES_FROM_SERVER_FAILURE = '[CRM] Get Task Outcomes From Server Failure';
export class GetTaskOutcomesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_TASK_OUTCOMES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type TaskOutcomesAction =
  | GetTaskOutcomesFromServer
  | GetTaskOutcomesFromServerSuccess
  | GetTaskOutcomesFromServerFailure;
