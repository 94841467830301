import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Employee } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromEmployees from './employees.actions';
import { EmployeesState } from './employees.interfaces';
@Injectable()
export class EmployeesEffects {
  
  getEmployeesFromServer = createEffect(() => this.dataPersistence.fetch(fromEmployees.GET_EMPLOYEES_FROM_SERVER, {
    run: (action: fromEmployees.GetEmployeesFromServer, state: any) => {
      return this.apiService
        .get<Employee[]>(`v/1/associates/employees`, {
          params: { ...action.payload, embed: 'associate' },
        })
        .pipe(
          map((employees) => {
            return new fromEmployees.GetEmployeesFromServerSuccess({ employees: employees });
          })
        );
    },
    onError: (action: fromEmployees.GetEmployeesFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<EmployeesState>,
    private apiService: ApiService
  ) {}
}
