import { ServiceAppointment as Appointment } from '@quorum/models/xs-resource';
// eslint-disable-next-line max-len
import { ServiceAppointmentQueryParameters as AppointmentQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_SERVICE_APPOINTMENTS_FROM_SERVER = '[CRM] Get Service Appointments From Server';
export class GetServiceAppointmentsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SERVICE_APPOINTMENTS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: AppointmentQueryParameters) {}
}

export const GET_SERVICE_APPOINTMENTS_FROM_SERVER_SUCCESS = '[CRM] Get Service Appointments From Server Success';
export class GetServiceAppointmentsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SERVICE_APPOINTMENTS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { serviceAppointments: Appointment[] }) {}
}

export const GET_SERVICE_APPOINTMENTS_FROM_SERVER_FAILURE = '[CRM] Get Service Appointments From Server Failure';
export class GetServiceAppointmentsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SERVICE_APPOINTMENTS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_SERVICE_APPOINTMENTS_FROM_STATE = '[CRM] Clear Service Appointments From State';
export class ClearServiceAppointmentsFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_SERVICE_APPOINTMENTS_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export type ServiceAppointmentsAction =
  | GetServiceAppointmentsFromServer
  | GetServiceAppointmentsFromServerSuccess
  | GetServiceAppointmentsFromServerFailure
  | ClearServiceAppointmentsFromState;
