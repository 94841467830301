import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { DirectMessageTemplate, HtmlLanguageTemplate, HtmlMappingTemplate } from '@quorum/models/xs-resource';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'sha-email-template-dialog',
  templateUrl: './email-template-dialog.component.html',
  styleUrls: ['./email-template-dialog.component.css'],
})
export class EmailTemplateDialogComponent implements OnInit {
  htmlLanguageTemplates$: Observable<HtmlLanguageTemplate[]>;
  htmlLanguageTemplates: HtmlLanguageTemplate[];

  emailTemplateForm = this.fb.group({
    subject: [''],
    htmlMappingTemplate: [''],
    htmlLanguageTemplate: [''],
    messageTemplate: [''],
    messageBody: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { directMessageTemplates: DirectMessageTemplate[]; htmlMappingTemplates: HtmlMappingTemplate[] },
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EmailTemplateDialogComponent>,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    let defaultHtmlMappingTemplate = this.data.htmlMappingTemplates.find(
      (emailTemplate: HtmlMappingTemplate) => emailTemplate.templateName === 'Default'
    );
    this.emailTemplateForm.patchValue({
      htmlMappingTemplate: defaultHtmlMappingTemplate.id,
    });
    this.getLanguageTemplates(defaultHtmlMappingTemplate.id);
  }

  setMessageBody(messageTemplate: DirectMessageTemplate) {
    this.emailTemplateForm.patchValue({
      messageBody: messageTemplate.message,
    });
  }

  getLanguageTemplates(htmlMappingTemplateId: number) {
    this.htmlLanguageTemplates$ = this.apiService.get<HtmlLanguageTemplate[]>(
      `v/1/common/html-templates/mapping-templates/${htmlMappingTemplateId}/language-templates`
    );
    this.htmlLanguageTemplates$.pipe(take(1)).subscribe((htmlLanguageTemplates) => {
      this.emailTemplateForm.patchValue({
        htmlLanguageTemplate: htmlLanguageTemplates.find(
          (htmlLanguageTemplate) => htmlLanguageTemplate.language === 'EN'
        ).language,
      });
    });
  }
}
