import { Action } from '@ngrx/store';
import { ResponseLeadQueryParameters } from '@quorum/models/xs-query';
import { ResponseLead } from '@quorum/models/xs-resource';

export const GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER =
  '[CRM] Get Selected Prospect Response Leads From Server';
export class GetSelectedProspectResponseLeadsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ResponseLeadQueryParameters) {}
}

export const GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Response Leads From Server Success';
export class GetSelectedProspectResponseLeadsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { responseLeads: ResponseLead[] }) {}
}

export const GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Response Leads From Server Failure';
export class GetSelectedProspectResponseLeadsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_RESPONSE_LEADS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectActionsAction =
  | GetSelectedProspectResponseLeadsFromServer
  | GetSelectedProspectResponseLeadsFromServerSuccess
  | GetSelectedProspectResponseLeadsFromServerFailure;
