import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MomentPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import {
  ProspectQueryParameters,
  SaleQueryParameters,
  VehicleOfInterestQueryParameters,
  WorkOrderQueryParameters,
} from '@quorum/models/xs-query';
import {
  Action,
  ServiceAppointment,
  CrmAppointment as SalesAppointment,
  DealerPromotion,
  Employee,
  MessageType,
  Note,
  Prospect,
  ProspectAction,
  Task,
  TokenUser,
  Sale,
  VehicleOfInterest,
} from '@quorum/models/xs-resource';
import { Activity } from '@quorum/xsr-salesplanner-legacy';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromSelectedProspectOpenSales from './open-sales/open-sales.actions';
import { selectAll as selectAllProspectOpenSales } from './open-sales/open-sales.interfaces';
import * as fromSelectedProspectVehicleOfInterest from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.actions';
import { selectAll as selectAllProspectVehicleOfInterest } from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.interfaces';
import * as fromProspectActions from './prospect-actions/prospect-actions.actions';
import { selectAll as selectAllProspectActions } from './prospect-actions/prospect-actions.interfaces';
// eslint-disable-next-line max-len
import { selectAll as selectAllDealerPromotions } from './prospect-dealer-promotions/prospect-dealer-promotions.interfaces';
import { selectAll as selectAllProspectDeliveries } from './prospect-deliveries/prospect-deliveries.interfaces';
import * as fromProspectNotes from './prospect-notes/prospect-notes.actions';
// eslint-disable-next-line max-len
import { selectAll as selectAllProspectSalesAppointments } from './prospect-sales-appointments/prospect-sales-appointments.interfaces';
// eslint-disable-next-line max-len
import { selectAll as selectAllProspectServiceAppointments } from './prospect-service-appointments/prospect-service-appointments.interfaces';
import * as fromSelectedProspectTasks from './prospect-tasks/prospect-tasks.actions';
import { selectAll as selectAllProspectTasks } from './prospect-tasks/prospect-tasks.interfaces';
import { selectAll as selectAllResponseLeads } from './response-leads/response-leads.interfaces';
import * as fromSelectedActivity from './selected-activity.actions';
import {
  selectedActivitySelector,
  selectedActivityTypeSelector,
  selectedProspectAggregateSelector,
  selectedProspectIsLoadingSelector,
  selectedProspectNotesSelector,
  selectedProspectSelector,
  selectedProspectVehicleAggregateIsLoadingSelector,
} from './selected-activity.interfaces';
import * as fromSelectedVehicleServiceHistory from './service-history/service-history.actions';
import { selectAll as selectAllVehicleServiceHistory } from './service-history/service-history.interfaces';
import * as fromVehicles from './vehicles/vehicles.actions';
import { selectAll as selectAllProspectVehicles, vehicleAggregateSelector } from './vehicles/vehicles.interfaces';
import * as fromSelectedActivityPurchaseOrderDetails from './purchase-order-details/purchase-order-details.actions';
import { selectAll as selectAllActivityPurchaseOrderDetails } from './purchase-order-details/purchase-order-details.interfaces';

@Injectable()
export class SelectedActivityStateService {
  constructor(private store: Store<any>, private dropdownsStateService: DropdownsStateService) {}

  selectSelectedProspectActivities() {
    return combineLatest(
      this.selectSelectedProspectDealerPromotions(),
      this.selectSelectedProspectSalesAppointments(),
      this.selectSelectedProspectServiceAppointments(),
      this.selectSelectedProspectTasks(),
      this.selectSelectedProspectDeliveries(),
      this.dropdownsStateService
        .selectEmployees()
        .pipe(filter((employees) => !this.dropdownsStateService.isEmpty(employees)))
    ).pipe(
      map(([dealerPromotions, salesAppointments, serviceAppointments, tasks, deliveries, employees]) => {
        let activities: Activity[] = [];

        dealerPromotions = dealerPromotions.map((promo: DealerPromotion) => {
          let promotion: Activity = {
            activityType: 'Promotion',
            activityId: promo.id,
            activitySequence: promo.sequence,
            activityDueDate: promo.dueDate,
            activityCompletedDate: promo.completedDate,
            activityDate: new MomentPipe().transform(promo.dueDate, 'YYYYMMDD'),
            activityCreatedDate: promo.createdDate,
            activityObjectives: promo.note,
            activityComments: promo.comments,
            activityOutcome: promo.embedded && promo.embedded.taskOutcome ? promo.embedded.taskOutcome.description : '',
            activityConfirmation: null,
            activityConfirmationEmployee: null,
            activityConfirmationDate: null,
            activityAttendance: null,
            activityStatus: null,
            activityDelivered: null,
            activityEmployeeAssociateId: promo.employeeId,
            associate:
              promo.embedded &&
              promo.embedded.vehicle &&
              promo.embedded.vehicle.embedded &&
              promo.embedded.vehicle.embedded.associate
                ? promo.embedded.vehicle.embedded.associate.id
                : null,
            customerName:
              promo.embedded &&
              promo.embedded.vehicle &&
              promo.embedded.vehicle.embedded &&
              promo.embedded.vehicle.embedded.associate
                ? promo.embedded.vehicle.embedded.associate.classId === 'RETAI'
                  ? promo.embedded.vehicle.embedded.associate.firstName
                  : [
                      promo.embedded.vehicle.embedded.associate.lastName,
                      promo.embedded.vehicle.embedded.associate.firstName,
                    ]
                      .filter(Boolean)
                      .join(', ')
                : '',
            customerContactPref:
              promo.embedded &&
              promo.embedded.vehicle &&
              promo.embedded.vehicle.embedded &&
              promo.embedded.vehicle.embedded.associate
                ? promo.embedded.vehicle.embedded.associate.contactPreferenceId
                : null,
            prospectId: null,
            prospectType: null,
            vehicleId: promo.vehicleId,
            rooftopId: promo.rooftopId,
            open: promo.completedDate ? false : true,
            employeeId: promo.employeeId,
            promotionName:
              promo.embedded && promo.embedded.dealerPromotionHeader ? promo.embedded.dealerPromotionHeader.name : '',
            activitySalesperson:
              promo.embedded && promo.embedded.taskEmployeeAssociate
                ? [promo.embedded.taskEmployeeAssociate.lastName, promo.embedded.taskEmployeeAssociate.firstName]
                    .filter(Boolean)
                    .join(', ')
                : '',
          };
          activities.push(promotion);
          return promo;
        });

        salesAppointments = salesAppointments.map((appointment: SalesAppointment) => {
          let salesAppointment: Activity = {
            activityType: 'Appointment',
            activityId: appointment.id,
            activitySequence: null,
            activityDueDate: appointment.appointmentDate,
            activityCompletedDate: appointment.completedDate,
            activityDate: new MomentPipe().transform(appointment.appointmentDate, 'YYYYMMDD'),
            activityCreatedDate: appointment.createdDate,
            activityObjectives: appointment.objectives,
            activityComments: appointment.comments,
            activityOutcome: appointment.attendanceFlag
              ? appointment.attendanceFlag === 'N'
                ? 'No Show'
                : 'Show'
              : '',
            activityConfirmation: appointment.confirmationFlag,
            activityConfirmationEmployee: appointment.confirmationAssociateId,
            activityConfirmationDate: appointment.confirmationDate,
            activityAttendance: appointment.attendanceFlag,
            activityStatus: null,
            activityDelivered: null,
            activityEmployeeAssociateId:
              appointment.embedded && appointment.embedded.taskEmployeeAssociate
                ? appointment.embedded.taskEmployeeAssociate.id
                : null,
            associate:
              appointment.embedded && appointment.embedded.prospect ? appointment.embedded.prospect.associateId : null,
            customerName:
              appointment.embedded &&
              appointment.embedded.prospect &&
              appointment.embedded.prospect.embedded &&
              appointment.embedded.prospect.embedded.associate
                ? appointment.embedded.prospect.embedded.associate.classId === 'RETAI'
                  ? appointment.embedded.prospect.embedded.associate.firstName
                  : [
                      appointment.embedded.prospect.embedded.associate.lastName,
                      appointment.embedded.prospect.embedded.associate.firstName,
                    ]
                      .filter(Boolean)
                      .join(', ')
                : appointment.embedded && appointment.embedded.prospect
                ? `${appointment.embedded.prospect.freeformName}`
                : 'No customer name found',
            customerContactPref:
              appointment.embedded &&
              appointment.embedded.prospect &&
              appointment.embedded.prospect.embedded &&
              appointment.embedded.prospect.embedded.associate
                ? appointment.embedded.prospect.embedded.associate.contactPreferenceId
                : null,
            prospectId: appointment.prospectId,
            prospectType:
              appointment.embedded && appointment.embedded.prospect && appointment.embedded.prospect.typeId === 3
                ? 'S'
                : 'R',
            vehicleId:
              appointment.embedded && appointment.embedded.prospect ? appointment.embedded.prospect.vehicleId : null,
            rooftopId:
              appointment.embedded && appointment.embedded.prospect ? appointment.embedded.prospect.rooftopId : null,
            open: appointment.completedDate ? false : true,
            employeeId: appointment.employeeId,
            promotionName: null,
            activitySalesperson:
              appointment.embedded && appointment.embedded.taskEmployeeAssociate
                ? [
                    appointment.embedded.taskEmployeeAssociate.lastName,
                    appointment.embedded.taskEmployeeAssociate.firstName,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : '',
          };
          activities.push(salesAppointment);
          return appointment;
        });

        serviceAppointments = serviceAppointments
          .filter(
            (serviceAppointment: ServiceAppointment) =>
              serviceAppointment.statusId === 1 || serviceAppointment.statusId === 2
          )
          .map((appointment: ServiceAppointment) => {
            let serviceSalespersonEmployee: Employee =
              appointment.embedded.vehicle && appointment.embedded.vehicle.employeeId
                ? employees.find((employee) => employee.associateId === appointment.embedded.vehicle.employeeId)
                : null;
            let serviceAppointment: Activity = {
              activityType: 'Service',
              activityId: appointment.id,
              activitySequence: null,
              activityDueDate: appointment.dateTime,
              activityCompletedDate: null,
              activityDate: new MomentPipe().transform(appointment.dateTime, 'YYYYMMDD'),
              activityCreatedDate: appointment.dateIn,
              activityObjectives: `${
                appointment.embedded && appointment.embedded.vehicle
                  ? appointment.embedded.vehicle.year
                  : appointment.vehicleYear
              } ${
                appointment.embedded && appointment.embedded.vehicle
                  ? appointment.embedded.vehicle.make
                  : appointment.vehicleMake
              } ${
                appointment.embedded && appointment.embedded.vehicle
                  ? appointment.embedded.vehicle.model
                  : appointment.vehicleDescription
              } ${
                appointment.embedded && appointment.embedded.serviceAdvisorAssociate != null
                  ? ' - ' +
                    appointment.embedded.serviceAdvisorAssociate.lastName +
                    ', ' +
                    appointment.embedded.serviceAdvisorAssociate.firstName
                  : ''
              }`,
              activityComments: appointment.odometerIn,
              activityOutcome: null,
              activityConfirmation: null,
              activityConfirmationEmployee: null,
              activityConfirmationDate: null,
              activityAttendance: null,
              activityStatus: null,
              activityDelivered: null,
              activityEmployeeAssociateId: appointment.employeeId,
              associate: appointment.customerId,
              customerName:
                appointment.embedded && appointment.embedded.customerAssociate
                  ? appointment.embedded.customerAssociate.classId === 'RETAI'
                    ? appointment.embedded.customerAssociate.firstName
                    : [
                        appointment.embedded.customerAssociate.lastName,
                        appointment.embedded.customerAssociate.firstName,
                      ]
                        .filter(Boolean)
                        .join(', ')
                  : '',
              customerContactPref:
                appointment.embedded && appointment.embedded.customerAssociate
                  ? appointment.embedded.customerAssociate.contactPreferenceId
                  : null,
              prospectId: null,
              prospectType: null,
              vehicleId: appointment.vehicleId,
              rooftopId: appointment.rooftopId,
              open: true, //appointment.open
              employeeId: appointment.employeeId,
              promotionName: null,
              activitySalesperson: serviceSalespersonEmployee
                ? [
                    serviceSalespersonEmployee.embedded.associate.lastName,
                    serviceSalespersonEmployee.embedded.associate.firstName,
                  ]
                    .filter(Boolean)
                    .join(', ')
                : '',
            };
            activities.push(serviceAppointment);
            return appointment;
          });

        tasks = tasks
          .filter((task) => task.dueDate != null)
          .map((task: Task) => {
            let t: Activity = {
              activityType: 'Task',
              activityId: task.id,
              activitySequence: null,
              activityDueDate: task.dueDate,
              activityCompletedDate: task.completedDate,
              activityDate: new MomentPipe().transform(task.dueDate, 'YYYYMMDD'),
              activityCreatedDate: task.createdDate,
              activityObjectives: task.note,
              activityComments: task.comments,
              activityOutcome: task.embedded && task.embedded.taskOutcome ? task.embedded.taskOutcome.description : '',
              activityConfirmation: null,
              activityConfirmationEmployee: null,
              activityConfirmationDate: null,
              activityAttendance: null,
              activityStatus: null,
              activityDelivered: null,
              activityEmployeeAssociateId: task.taskEmployeeId,
              associate:
                task.embedded &&
                task.embedded.prospect &&
                task.embedded.prospect.embedded &&
                task.embedded.prospect.embedded.associate
                  ? task.embedded.prospect.embedded.associate.id
                  : null,
              customerName:
                task.embedded &&
                task.embedded.prospect &&
                task.embedded.prospect.embedded &&
                task.embedded.prospect.embedded.associate
                  ? task.embedded.prospect.embedded.associate.classId === 'RETAI'
                    ? task.embedded.prospect.embedded.associate.firstName
                    : [
                        task.embedded.prospect.embedded.associate.lastName,
                        task.embedded.prospect.embedded.associate.firstName,
                      ]
                        .filter(Boolean)
                        .join(', ')
                  : task.embedded && task.embedded.prospect
                  ? `${task.embedded.prospect.freeformName}`
                  : 'No customer name found',
              customerContactPref:
                task.embedded &&
                task.embedded.prospect &&
                task.embedded.prospect.embedded &&
                task.embedded.prospect.embedded.associate
                  ? task.embedded.prospect.embedded.associate.contactPreferenceId
                  : null,
              prospectId: task.prospectId,
              prospectType: task.embedded && task.embedded.prospect && task.embedded.prospect.typeId === 3 ? 'S' : 'R',
              vehicleId:
                task.embedded &&
                task.embedded.prospect &&
                task.embedded.prospect.embedded &&
                task.embedded.prospect.embedded.vehicle
                  ? task.embedded.prospect.embedded.vehicle.id
                  : null,
              rooftopId:
                task.embedded &&
                task.embedded.prospect &&
                task.embedded.prospect.embedded &&
                task.embedded.prospect.embedded.rooftop
                  ? task.embedded.prospect.embedded.rooftop.id
                  : null,
              open: task.completedDate ? false : true,
              employeeId: task.employeeId,
              promotionName: null,
              activitySalesperson:
                task.embedded && task.embedded.taskEmployeeAssociate
                  ? [task.embedded.taskEmployeeAssociate.lastName, task.embedded.taskEmployeeAssociate.firstName]
                      .filter(Boolean)
                      .join(', ')
                  : '',
            };
            activities.push(t);
            return task;
          });

        deliveries = deliveries.map((delivery: Sale) => {
          let deliverySalespersonEmployee: Employee = delivery.salespersonId
            ? employees.find((employee) => employee.associateId === delivery.salespersonId)
            : null;
          let d: Activity = {
            activityType: 'Delivery',
            activityId: delivery.id,
            activitySequence: null,
            activityDueDate: delivery.deliveryDate,
            activityCompletedDate: null,
            activityDate: new MomentPipe().transform(delivery.deliveryDate, 'YYYYMMDD'),
            activityCreatedDate: delivery.enteredDate,
            activityObjectives:
              delivery.embedded && delivery.embedded.vehicle && delivery.embedded.status
                ? `${delivery.embedded.status.description} - ${delivery.embedded.vehicle.year} ${delivery.embedded.vehicle.make} ${delivery.embedded.vehicle.model}`
                : `${delivery.embedded.status.description}`,
            activityComments: null,
            activityOutcome: delivery.embedded && delivery.embedded.status ? delivery.embedded.status.description : '',
            activityConfirmation: null,
            activityConfirmationEmployee: null,
            activityConfirmationDate: null,
            activityAttendance: null,
            activityStatus: delivery.embedded && delivery.embedded.status ? delivery.embedded.status.description : '',
            activityDelivered: null,
            activityEmployeeAssociateId: delivery.salespersonId,
            associate: delivery.customerId,
            customerName:
              delivery.embedded && delivery.embedded.customerAssociate
                ? [delivery.embedded.customerAssociate.lastName, delivery.embedded.customerAssociate.firstName]
                    .filter(Boolean)
                    .join(', ')
                : '',
            customerContactPref:
              delivery.embedded && delivery.embedded.customerAssociate
                ? delivery.embedded.customerAssociate.contactPreferenceId
                : null,
            prospectId:
              delivery.statusId === 'S' || delivery.statusId === 'P'
                ? delivery.retentionProspectId
                : delivery.showroomProspectId,
            prospectType: delivery.statusId === 'S' || delivery.statusId === 'P' ? 'R' : 'S',
            vehicleId: delivery.vehicleId,
            rooftopId: delivery.rooftopId,
            open: delivery.deliveryFlag === '1' ? false : true,
            employeeId: delivery.salespersonId,
            promotionName: null,
            activitySalesperson: deliverySalespersonEmployee
              ? [
                  deliverySalespersonEmployee.embedded.associate.lastName,
                  deliverySalespersonEmployee.embedded.associate.firstName,
                ]
                  .filter(Boolean)
                  .join(', ')
              : delivery.embedded.salespersonAssociate
              ? [delivery.embedded.salespersonAssociate.lastName, delivery.embedded.salespersonAssociate.firstName]
                  .filter(Boolean)
                  .join(', ')
              : '',
          };
          activities.push(d);
          return delivery;
        });
        return activities;
      })
    );
  }
  selectSelectedActivity() {
    return this.store.pipe(select(selectedActivitySelector));
  }

  getSelectedActivityFromServer(id: number, activityType: string) {
    this.store.dispatch(
      new fromSelectedActivity.GetSelectedActivityFromServer({
        id: id,
        activityType: activityType,
      })
    );
  }

  selectSelectedActivityType() {
    return this.store.pipe(select(selectedActivityTypeSelector));
  }

  selectSelectedProspectDealerPromotions() {
    return this.store.pipe(select(selectAllDealerPromotions));
  }
  selectSelectedProspectTasks() {
    return this.store.pipe(select(selectAllProspectTasks));
  }
  selectSelectedProspectSalesAppointments() {
    return this.store.pipe(select(selectAllProspectSalesAppointments));
  }
  selectSelectedProspectServiceAppointments() {
    return this.store.pipe(select(selectAllProspectServiceAppointments));
  }
  selectSelectedProspectDeliveries() {
    return this.store.pipe(
      select(selectAllProspectDeliveries),
      map((deliveries) => deliveries.filter((delivery) => delivery.deliveryDate != null))
    );
  }

  selectSelectedProspect() {
    return this.store.pipe(select(selectedProspectSelector));
  }

  getSelectedProspectVehicles(prospect: Prospect) {
    this.store.dispatch(
      new fromVehicles.GetSelectedProspectVehiclesFromServer({
        associateId: prospect.associateId,
        embed: ['prospectType'],
      })
    );
  }

  selectSelectedProspectVehicles() {
    return this.store.pipe(select(selectAllProspectVehicles));
  }

  selectSelectedProspectIsLoading() {
    return this.store.pipe(select(selectedProspectIsLoadingSelector));
  }

  selectVehicleAggregateIsLoading() {
    return this.store.pipe(select(selectedProspectVehicleAggregateIsLoadingSelector));
  }

  selectSelectedProspectAggregate() {
    return this.store.pipe(
      select(selectedProspectAggregateSelector),
      filter((aggregate) => {
        return !this.isEmpty(aggregate);
      })
    );
  }

  selectSelectedProspectActions() {
    return this.store.pipe(select(selectAllProspectActions));
  }

  selectSelectedProspectOpenSales() {
    return this.store.pipe(select(selectAllProspectOpenSales));
  }

  selectSelectedProspectVehicleOfInterest() {
    return this.store.pipe(select(selectAllProspectVehicleOfInterest));
  }

  deleteSelectedProspectAction(prospectAction: ProspectAction) {
    this.store.dispatch(new fromProspectActions.DeleteSelectedProspectActionFromServer(prospectAction));
  }

  createSelectedProspectAction(prospectAction: ProspectAction, prospect: Prospect) {
    this.store.dispatch(
      new fromProspectActions.CreateSelectedProspectActionOnServer({
        prospectAction: prospectAction,
        prospect: prospect,
      })
    );
  }

  createSentEmailTextProspectAction(prospect: Prospect, dropdowns: any, currentUser: TokenUser) {
    let messagePreference: MessageType = dropdowns.messageTypes.find(
      (messageType: MessageType) => messageType.id === prospect.embedded.associate.messagePreferenceId
    );
    let actionId: number;

    if (messagePreference.description.toLowerCase() === 'email') {
      actionId = dropdowns.prospectActions.find((ac: Action) => ac.description.toLowerCase() === 'outbound email').id;
    } else if (messagePreference.description.toLowerCase() === 'text') {
      actionId = dropdowns.prospectActions.find((ac: Action) => ac.description.toLowerCase() === 'outbound text').id;
    }

    let newProspectAction: ProspectAction = {
      ...new ProspectAction(),
      enteredDateTime: new Date(),
      taskActionId: actionId,
      associateEmployeeId: currentUser.xselleratorEmployeeId,
      prospectId: prospect.id,
    };
    this.createSelectedProspectAction(newProspectAction, {
      ...prospect,
      actionId: actionId,
    });
  }

  getSelectedProspectActionsFromServer(queryParams: any) {
    this.store.dispatch(new fromProspectActions.GetSelectedProspectActionsFromServer(queryParams));
  }

  getSelectedProspectTasksFromServer(queryParams: any) {
    this.store.dispatch(new fromSelectedProspectTasks.GetProspectTasksFromServer(queryParams));
  }

  selectSelectedProspectNotes() {
    return this.store.pipe(select(selectedProspectNotesSelector));
  }

  selectSelectedProspectResponseLeads() {
    return this.store.pipe(
      select(selectAllResponseLeads),
      filter((responseLeads) => {
        return !this.isEmpty(responseLeads);
      })
    );
  }

  getVehicleAggregateData(vehicleId: number) {
    this.store.dispatch(new fromVehicles.GetVehicleAggregateDataFromServer(vehicleId));
  }

  selectSelectedVehicleAggregateData() {
    return this.store.pipe(select(vehicleAggregateSelector));
  }

  getVehicleServiceHistory(vehicleId: number, associateId: string) {
    this.store.dispatch(
      new fromSelectedVehicleServiceHistory.GetVehicleServiceHistoryFromServer(
        new WorkOrderQueryParameters({
          customerId: associateId,
          vehicleId: vehicleId.toString(),
        })
      )
    );
  }

  selectSelectedVehicleServiceHistory() {
    return this.store.pipe(select(selectAllVehicleServiceHistory));
  }

  selectSelectedActivityPurchaseOrderDetails() {
    return this.store.pipe(select(selectAllActivityPurchaseOrderDetails));
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  createSelectedProspectNoteOnServer(note: Note) {
    this.store.dispatch(new fromProspectNotes.CreateSelectedProspectNoteOnServer(note));
  }

  updateSelectedProspectNoteOnServer(note: Note) {
    this.store.dispatch(new fromProspectNotes.UpdateSelectedProspectNoteOnServer(note));
  }

  updateSelectedProspectOpenSaleOnServer(openSale: Sale) {
    this.store.dispatch(new fromSelectedProspectOpenSales.UpdateSelectedProspectOpenSaleOnServer(openSale));
  }

  reassignSelectedProspectActivities(oldProspect: Prospect, prospect: Prospect) {
    this.store.dispatch(
      new fromSelectedActivity.ReassignSelectedProspectActivities({ oldProspect: oldProspect, prospect: prospect })
    );
  }

  getSelectedProspectOpenSalesFromServer(queryParams: SaleQueryParameters) {
    this.store.dispatch(new fromSelectedProspectOpenSales.GetSelectedProspectOpenSalesFromServer(queryParams));
  }

  createSelectedProspectVehicleOfInterestFromServer(vehicle: VehicleOfInterest) {
    this.store.dispatch(
      new fromSelectedProspectVehicleOfInterest.CreateSelectedProspectVehicleOfInterestFromServer(vehicle)
    );
  }

  getSelectedProspectVehicleOfInterestFromServer(queryParams: VehicleOfInterestQueryParameters) {
    this.store.dispatch(
      new fromSelectedProspectVehicleOfInterest.GetSelectedProspectVehicleOfInterestFromServer(queryParams)
    );
  }

  deleteSelectedProspectVehicleOfInterest(vehicle: VehicleOfInterest) {
    this.store.dispatch(
      new fromSelectedProspectVehicleOfInterest.DeleteSelectedProspectVehicleOfInterestFromServer(vehicle)
    );
  }

  updateSelectedProspectVehicleOfInterest(vehicle: VehicleOfInterest) {
    this.store.dispatch(
      new fromSelectedProspectVehicleOfInterest.UpdateSelectedProspectVehicleOfInterestFromServer(vehicle)
    );
  }

  updateSelectedProspectInState(prospect: Prospect) {
    this.store.dispatch(
      new fromSelectedActivity.UpdateSelectedProspectInState({
        id: prospect.id,
        changes: { ...prospect },
      })
    );
  }

  getSelectedProspectFromServer(prospect: Prospect) {
    this.store.dispatch(
      new fromSelectedActivity.GetSelectedProspectFromServer(
        new ProspectQueryParameters({
          id: prospect.id.toString(),
          embed: ['associate', 'primaryEmployeeAssociate', 'vehicle', 'closedByAssociate'],
        })
      )
    );
  }

  clearSelectedProspectFromState() {
    this.store.dispatch(new fromSelectedActivity.ClearSelectedProspectFromState());
  }
}
