import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSafety',
})
export class NameSafetyPipe implements PipeTransform {
  transform(value: string[], nameFormat?: string): any {
    switch (nameFormat) {
      case 'firstLast':
        return value.filter(Boolean).join(' ');
      case 'lastFirst':
        return value.filter(Boolean).join(', ');
      default:
        return value.filter(Boolean).join(', ');
    }
  }
}
