import { Injectable } from '@angular/core';
import { SubType } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromVehicleSubTypes from './vehicle-subtypes.actions';
import { VehicleSubTypesState } from './vehicle-subtypes.interfaces';
import { map } from 'rxjs/operators';

@Injectable()
export class VehicleSubTypesEffects {
  
  getVehicleSubTypesFromServer = createEffect(() => this.dataPersistence.fetch(fromVehicleSubTypes.GET_VEHICLE_SUBTYPES_FROM_SERVER, {
    run: (action: fromVehicleSubTypes.GetVehicleSubTypesFromServer, state: VehicleSubTypesState) => {
      return this.apiService
        .get<SubType[]>(`v/1/vehicles/vehicle-sub-types`, {
          params: action.payload
        })
        .pipe(
          map(vehicleSubTypes => {
            return new fromVehicleSubTypes.GetVehicleSubTypesFromServerSuccess({
              vehicleSubTypes: vehicleSubTypes
            });
          })
        );
    },
    onError: (action: fromVehicleSubTypes.GetVehicleSubTypesFromServer, error) => {
      console.error('Error', error);
    }
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleSubTypesState>,
    private apiService: ApiService
  ) {}
}
