import { GenericModel } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface VehicleGenericModelsState extends EntityState<GenericModel> {
  loading: boolean;
}

export const vehicleGenericModelsAdapter: EntityAdapter<GenericModel> = createEntityAdapter<GenericModel>({
  selectId: (vehicleGenericModel: GenericModel) => vehicleGenericModel.id
});

export const initialState: VehicleGenericModelsState = vehicleGenericModelsAdapter.getInitialState({ loading: true });

export const vehicleGenericModelsSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleGenericModels
);

export const vehicleGenericModelsLoadingSelector = createSelector(
  vehicleGenericModelsSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleGenericModelsAdapter.getSelectors(
  vehicleGenericModelsSelector
);
