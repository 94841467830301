import { SubCategory } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface VehicleSubcategoriesState extends EntityState<SubCategory> {
  loading: boolean;
}

export const vehicleSubcategoriesAdapter: EntityAdapter<SubCategory> = createEntityAdapter<SubCategory>({
  selectId: (subcategory: SubCategory) => subcategory.id
});

export const initialState: VehicleSubcategoriesState = vehicleSubcategoriesAdapter.getInitialState({ loading: true });
export const vehicleSubcategoriesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleSubcategories
);

export const vehicleSubcategoriesLoadingSelector = createSelector(
  vehicleSubcategoriesSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleSubcategoriesAdapter.getSelectors(
  vehicleSubcategoriesSelector
);
