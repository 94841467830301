import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Source } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface SourcesState extends EntityState<Source> {}

export const sourcesAdapter: EntityAdapter<Source> = createEntityAdapter<Source>({
  selectId: (source: Source) => source.id,
});

export const initialState: SourcesState = sourcesAdapter.getInitialState({ loading: true });

export const sourcesSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.sources);

export const { selectIds, selectEntities, selectAll, selectTotal } = sourcesAdapter.getSelectors(sourcesSelector);
