import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticationStatus } from '@quorum/authentication/state';
export function checkAuthentication(authenticationStateService: AuthenticationStateService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      authenticationStateService.checkCookieForAuthentication();
      authenticationStateService.isAuthenticated().subscribe(value => {
        if (value != null) {
          resolve({});
        }
      });
    });
  };
}

export function authenticationStagesSetup(authenticationStateService: AuthenticationStateService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise((resolve, reject) => {
      authenticationStateService.addAuthenticationStages([
        {
          id: 1,
          task: 'Authenticate User',
          authenticationStatus: AuthenticationStatus.pending
        },
        {
          id: 2,
          task: 'Checking Versions',
          authenticationStatus: AuthenticationStatus.pending
        },
        {
          id: 3,
          task: 'Pulling Newest Version',
          authenticationStatus: AuthenticationStatus.optional
        },
        {
          id: 4,
          task: 'Retrieving User Profile',
          authenticationStatus: AuthenticationStatus.pending
        },
        {
          id: 5,
          task: 'Configuring SalesPlanner',
          authenticationStatus: AuthenticationStatus.pending
        }
      ]);
      resolve(true);
    });
  };
}
