import { Update } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { TaskQueryParameters } from '@quorum/models/xs-query';
import { Task } from '@quorum/models/xs-resource';

export const GET_PROSPECT_TASKS_FROM_SERVER = '[CRM] Get Prospect Tasks From Server';
export class GetProspectTasksFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_TASKS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: TaskQueryParameters) {}
}

export const GET_PROSPECT_TASKS_FROM_SERVER_SUCCESS = '[CRM] Get Prospect Tasks From Server Success';
export class GetProspectTasksFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_TASKS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { tasks: Task[] }) {}
}

export const GET_PROSPECT_TASKS_FROM_SERVER_FAILURE = '[CRM] Get Prospect Tasks  From Server Failure';
export class GetProspectTasksFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_TASKS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const ADD_PROSPECT_TASK_TO_STATE = '[CRM] Add Prospect Task to State';
export class AddProspectTaskToState implements Action {
  readonly feature = 'crm';
  readonly type = ADD_PROSPECT_TASK_TO_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: Task) {}
}

export const UPDATE_PROSPECT_TASK_IN_STATE = '[CRM] Update Prospect Task in State';
export class UpdateProspectTaskInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_PROSPECT_TASK_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: Update<Task>) {}
}

export type ProspectTasksAction =
  | GetProspectTasksFromServer
  | GetProspectTasksFromServerSuccess
  | GetProspectTasksFromServerFailure
  | AddProspectTaskToState
  | UpdateProspectTaskInState;
