import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { environment } from '@quorum/environments';
import { AuthenticatedUser } from '@quorum/models/xs-resource';
import { LocalSettingsState, LocalSettingsStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  authenticatedUser: AuthenticatedUser;
  localSettings: LocalSettingsState;

  constructor(
    private authenticationService: AuthenticationStateService,
    private localSettingsStateService: LocalSettingsStateService,
    private overlayContainer: OverlayContainer,
    private router: Router
  ) {
    this.authenticationService
      .selectAuthenticatedUser()
      .pipe(
        filter((authenticatedUser) => authenticatedUser != null),
        take(1)
      )
      .subscribe((authenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
        if (environment.environment !== 'development') {
          datadogRum.init({
            applicationId: '6efe314e-f24e-49ef-acae-30f67bfd9461',
            clientToken: 'pub2505e36b09ac2f953a693507eaedc970',
            site: 'datadoghq.com',
            service: 'crm',
            env: `${environment.environment}`,
            version: `${environment.version}`,
            sampleRate: 100,
            trackInteractions: true,
            allowedTracingOrigins: [`${environment.apiBase}`],
          });

          datadogRum.setUser({
            id: authenticatedUser.user.id.toString(),
            name: `${authenticatedUser.user.firstName} ${authenticatedUser.user.lastName}`,
            xselleratorAssociateId: authenticatedUser.user.xselleratorEmployeeId,
            destinationId: authenticatedUser.user.destination,
            storeId: authenticatedUser.user.storeId,
            storeName: authenticatedUser.user.storeName,
          });
        }
      });

    this.localSettingsStateService
      .selectLocalSettingsFromState()
      .pipe(filter((localSettings) => localSettings !== null))
      .subscribe((localSettings) => {
        this.localSettings = localSettings;

        switch (localSettings.accessibility.theme) {
          case 'dark':
            overlayContainer.getContainerElement().classList.add('dark-theme');
            overlayContainer.getContainerElement().classList.remove('light-theme');
            break;
          default:
            overlayContainer.getContainerElement().classList.add('light-theme');
            overlayContainer.getContainerElement().classList.remove('dark-theme');
        }
      });
  }

  ngOnInit() {}
}
