import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { HtmlMappingTemplate } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface HtmlMappingTemplatesState extends EntityState<HtmlMappingTemplate> {}

export const htmlMappingTemplatesAdapter: EntityAdapter<HtmlMappingTemplate> = createEntityAdapter<HtmlMappingTemplate>(
  {
    selectId: (directMessageTemplate: HtmlMappingTemplate) => directMessageTemplate.id,
  }
);

export const initialState: HtmlMappingTemplatesState = htmlMappingTemplatesAdapter.getInitialState();
export const htmlMappingTemplatesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.htmlMappingTemplates
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  htmlMappingTemplatesAdapter.getSelectors(htmlMappingTemplatesSelector);
