import { SalesPlannerUIState } from './ui.interfaces';

export const initialUIState: SalesPlannerUIState = {
  actionsVisible: true,
  notesVisible: true,
  openActivitiesVisible: true,
  closedActivitiesVisible: true,
  purchaseOrderDetailsVisible: true,
  openSalesVisible: true,
  prospectAggregateVisible: true,
  prospectDetailsVisible: true,
  responseLeadsVisible: true,
  vehicleOfInterestVisible: true,
  vehicleAggregateVisible: false,
  serviceHistoryVisible: true,
  sidenavVisible: false,
  prospectLeads: {},
};
