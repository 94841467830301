import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { ProspectReportQueryParameters } from '@quorum/models/xs-query';
import { Employee, OemRooftopLink, ProspectReport, VehicleCategory, Vendor } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import {
  LocalSettingsState,
  LocalSettingsStateService,
  UiStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, filter, map, shareReplay, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-prospect-leads',
  templateUrl: './prospect-leads.component.html',
  styleUrls: ['./prospect-leads.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectLeadsComponent implements OnInit, OnDestroy {
  sort: string;
  dropdowns$: Observable<any>;
  authenticatedEmployee: Employee;
  localSettings: LocalSettingsState;
  search$: Observable<any>;
  searchResult: any = { data: [], headers: null };
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchPerformed: boolean = false;
  routerState$: Subscription;
  leadGeneratorActionId: number;
  createProspectProgressRef: MatDialogRef<any>;
  countryCode: string;
  mileageLabel: string;

  defaultProspectLeadFormModel: ProspectReportQueryParameters = {
    ...new ProspectReportQueryParameters(),
    rooftopId: null,
    salespersonId: null,
    prospectStatusId: null,
    prospectTypeId: null,
    prospectChannelId: null,
    prospectSourceId: null,
    actionId: null,
    customerTypeId: null,
    vendorId: null,
    assigned: null,
    matched: null,
    categoryId: null,
    prospectEnteredDateFrom: null,
    prospectEnteredDateTo: null,
    search: null,
    sort: '-prospectEnteredDate',
    pageSize: 25,
    page: 0,
    openTasks: null,
  };

  prospectLeadForm = this.fb.group({
    rooftopId: null,
    salespersonId: null,
    prospectStatusId: null,
    prospectTypeId: null,
    prospectChannelId: null,
    prospectSourceId: null,
    actionId: null,
    customerTypeId: null,
    vendorId: null,
    assigned: null,
    matched: null,
    categoryId: null,
    prospectEnteredDateFrom: null,
    prospectEnteredDateTo: null,
    search: null,
    sort: '-prospectEnteredDate',
    pageSize: 25,
    page: 0,
    openTasks: null,
  });

  private formInititialized = new ReplaySubject(1);
  public formRef: ElementRef;
  @ViewChild('prospectLeadFormRef')
  set setTabSet(content: any) {
    if (!!content) {
      this.formRef = content;
      this.formInititialized.next(true);
    }
  }
  sidenavStatus$: Subscription;
  sidenav: boolean;
  prospectStatuses = [
    { id: '1', description: 'Open' },
    { id: '2', description: 'Closed' },
  ];

  openTasksOptions = [
    { id: '1', description: 'Yes' },
    { id: '2', description: 'No' },
  ];

  prospectTypes = [
    { id: '3', description: 'Showroom' },
    { id: '4', description: 'Retention' },
    { id: '6', description: 'Lead' },
  ];

  prospectCustomerTypes = [
    { id: '1', description: 'New' },
    { id: '2', description: 'Existing' },
    { id: '3', description: 'Return' },
  ];

  statusSelectAll: boolean = false;
  typeSelectAll: boolean = false;
  channelSelectAll: boolean = false;
  sourceSelectAll: boolean = false;
  actionsSelectAll: boolean = false;
  customerTypesSelectAll: boolean = false;
  vendorSelectAll: boolean = false;
  categorySelectAll: boolean = false;
  openTasksSelectAll: boolean = false;

  vendors: any = [
    { id: 1, description: 'Vendor 1' },
    { id: 2, description: 'Vendor 2' },
    { id: 3, description: 'Vendor 3' },
    { id: 4, description: 'Vendor 4' },
  ];

  vehicleCategories: VehicleCategory[];
  prospectQuerySub$: Subscription;
  localSettingsSub$: Subscription;

  constructor(
    public apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public crmEntityStateService: CrmEntityStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public routerStateService: RouterStateService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public equityMiningStateService: EquityMiningStateService,
    public systemControlStateService: SystemControlStateService,
    private uiStateService: UiStateService,
    private localSettingsStateService: LocalSettingsStateService
  ) {}

  ngOnInit() {
    this.sort = '-prospectEnteredDate';
    this.sidenavStatus$ = this.uiStateService.selectSidenavVisible().subscribe((res) => (this.sidenav = res));

    this.dropdowns$ = combineLatest([
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
        take(1)
      ),
      this.dropdownsStateService.selectSalespeople().pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        take(1)
      ),
      this.dropdownsStateService.selectChannels().pipe(
        filter((channels) => !this.dropdownsStateService.isEmpty(channels)),
        take(1)
      ),
      this.dropdownsStateService.selectSources().pipe(
        filter((sources) => !this.dropdownsStateService.isEmpty(sources)),
        take(1)
      ),
      this.dropdownsStateService.selectProspectActions().pipe(
        filter((actions) => !this.dropdownsStateService.isEmpty(actions)),
        take(1)
      ),
      this.dropdownsStateService.selectCustomerTypes().pipe(
        filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes)),
        take(1)
      ),
      this.dropdownsStateService.selectVehicleCategories().pipe(
        filter((categories) => !this.dropdownsStateService.isEmpty(categories)),
        take(1),
        tap((categories) => (this.vehicleCategories = categories))
      ),
      this.dropdownsStateService.selectVendors().pipe(
        filter((vendors) => !this.dropdownsStateService.isEmpty(vendors)),
        map((vendors) => vendors.filter((vendor: Vendor) => vendor.leads)),
        take(1)
      ),
    ]).pipe(
      map(([rooftops, salespeople, channels, sources, actions, customerTypes, categories, vendors]) => {
        return { rooftops, salespeople, channels, sources, actions, customerTypes, categories, vendors };
      }),
      shareReplay(1)
    );

    this.routerState$ = combineLatest([
      this.routerStateService
        .selectRouterState()
        .pipe(filter((routerState) => routerState.state.url.indexOf('prospects') > -1)),
      this.authenticationStateService.selectAuthenticatedEmployee().pipe(
        filter((employee) => employee != null),
        take(1),
        tap((authEmployee) => {
          this.authenticatedEmployee = authEmployee;
        })
      ),
      this.localSettingsStateService.selectLocalSettingsFromState().pipe(
        filter((settings) => settings != null),
        take(1),
        tap((localSettings) => (this.localSettings = localSettings))
      ),
    ]).subscribe(([routerState, authenticatedEmployee, localSettings]) => {
      let queryParams: any = routerState.state.root.queryParams;
      if (this.dropdownsStateService.isEmpty(queryParams)) {
        this.prospectLeadForm.patchValue(
          {
            ...this.defaultProspectLeadFormModel,
            salespersonId: localSettings.salesPeople,
            rooftopId: authenticatedEmployee.rooftopId.toString(),
          },
          { emitEvent: true }
        );
        queryParams = {
          rooftopId: authenticatedEmployee.rooftopId.toString(),
          salespersonId: localSettings.salesPeople,
          sort: '-prospectEnteredDate',
          pageSize: '25',
          page: '0',
        };
        this.sort = '-prospectEnteredDate';
      } else {
        if (queryParams.sort) {
          this.sort = queryParams.sort;
        }

        let formModel = {
          ...this.defaultProspectLeadFormModel,
          ...queryParams,
          salespersonId: this.multiSelectFormField('salespersonId', queryParams),
          prospectStatusId: this.multiSelectFormField('prospectStatusId', queryParams),
          prospectTypeId: this.multiSelectFormField('prospectTypeId', queryParams),
          prospectChannelId: this.multiSelectFormField('prospectChannelId', queryParams),
          prospectSourceId: this.multiSelectFormField('prospectSourceId', queryParams),
          actionId: this.multiSelectFormField('actionId', queryParams),
          customerTypeId: this.multiSelectFormField('customerTypeId', queryParams),
          vendorId: this.multiSelectFormField('vendorId', queryParams),
          categoryId: this.multiSelectFormField('categoryId', queryParams),
          prospectEnteredDateFrom: queryParams.prospectEnteredDateFrom
            ? new Date(queryParams.prospectEnteredDateFrom)
            : null,
          prospectEnteredDateTo: queryParams.prospectEnteredDateTo ? new Date(queryParams.prospectEnteredDateTo) : null,
        };

        this.prospectLeadForm.patchValue(formModel, {
          emitEvent: false,
        });
      }

      this.isLoading$.next(true);
      this.hasError$.next(false);
      this.prospectQuerySub$ = this.apiService
        .getWithHeaders<ProspectReport[]>(`v/1/reporting/crm/prospects`, { params: queryParams })
        .pipe(
          map((response) => {
            this.isLoading$.next(false);
            this.searchPerformed = true;
            return { data: response.body, headers: response.headers };
          }),
          catchError((error) => {
            this.isLoading$.next(false);
            this.hasError$.next(true);
            return of(error);
          })
        )
        .subscribe((sresult: any) => {
          this.searchResult = sresult;
        });
    });

    this.localSettingsSub$ = this.localSettingsStateService
      .selectLocalSettingsFromState()
      .pipe(
        filter((settings) => settings != null),
        map((localSettings) => localSettings.salesPeople)
      )
      .subscribe((salespeople) => {
        this.prospectLeadForm.patchValue({
          ...this.prospectLeadForm,
          salespersonId: salespeople,
        });
      });
  }

  ngOnDestroy() {
    this.routerState$.unsubscribe();
    this.prospectQuerySub$.unsubscribe();
    this.sidenavStatus$.unsubscribe();
    this.localSettingsSub$.unsubscribe();
  }

  selectTeam(salesTeam: any, prospectLeadForm: FormGroup) {
    let selectedTeamSalespeople = salesTeam.children.map((salesperson: any) => salesperson.EmployeeId);
    let selectedSalespeople = prospectLeadForm.get('salespersonId').value
      ? prospectLeadForm.get('salespersonId').value
      : [];

    let existingCheck = selectedSalespeople
      ? selectedSalespeople.some((el: any) => selectedTeamSalespeople.includes(el))
      : false;

    selectedTeamSalespeople.forEach((salespersonId: string) => {
      if (existingCheck) {
        if (selectedSalespeople.includes(salespersonId)) {
          selectedSalespeople.splice(selectedSalespeople.indexOf(salespersonId, 0), 1);
        }
      } else {
        if (selectedSalespeople && !selectedSalespeople.includes(salespersonId)) {
          selectedSalespeople.push(salespersonId);
        }
      }
    });

    this.prospectLeadForm.get('salespersonId').patchValue(null);
    this.prospectLeadForm
      .get('salespersonId')
      .patchValue(selectedSalespeople.length !== 0 ? selectedSalespeople : null);
  }

  updateRouter(queryParams: any) {
    let navExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    };

    this.routerStateService.go('', navExtras);
  }

  sortChanged(sortValue: string, prospectLeadForm: FormGroup) {
    this.updateRouter({ ...prospectLeadForm.value, sort: sortValue });
  }

  getProspectLeads() {
    this.updateRouter(this.prospectLeadForm.value);
  }

  getErrorMessage(formField: string) {
    return this.prospectLeadForm.get(formField).hasError('required')
      ? 'You must enter a name.'
      : this.prospectLeadForm.get(formField).hasError('email')
      ? 'Not a valid email.'
      : '';
  }

  clearSearchResults() {
    this.searchResult = { data: [], headers: null };
  }

  resetProspectLeadForm(authenticatedEmployee: Employee, localSettings: LocalSettingsState) {
    this.prospectLeadForm.patchValue({
      ...this.defaultProspectLeadFormModel,
      salespersonId: localSettings.salesPeople,
      rooftopId: authenticatedEmployee.rooftopId.toString(),
    });
  }

  pageChanged(event: any, prospectLeadForm: FormGroup) {
    this.updateRouter({ ...prospectLeadForm.value, page: event.pageIndex, pageSize: event.pageSize });
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['sales-planner', 'prospects', prospectId], {
      queryParams: { tab: 'prospect' },
      relativeTo: this.route.parent,
    });
  }

  trackByFunction(index: number, item: ProspectReport) {
    return item.id;
  }

  toggleSidenav() {
    this.uiStateService.toggleSidenav(!this.sidenav);
  }

  toggleAllSelection(selectAll: any, formFieldName: string, values: any): void {
    let ids: string[] = [];
    if (formFieldName === 'categoryId') {
      values.map((value: any) => ids.push(value.categoryId.toString()));
    } else if (formFieldName === 'vendorId') {
      values.map((value: any) => ids.push(value.associateId.toString()));
    } else {
      values.map((value: any) => ids.push(value.id.toString()));
    }
    if (selectAll) {
      this.prospectLeadForm.get(formFieldName).setValue(ids);
      selectAll = true;
    } else {
      this.prospectLeadForm.get(formFieldName).setValue(null);
      selectAll = false;
    }
  }

  multiSelectFormField(formFieldName: string, queryParams: any) {
    if (queryParams[formFieldName]) {
      if (Array.isArray(queryParams[formFieldName])) {
        return [...queryParams[formFieldName]];
      } else {
        return [queryParams[formFieldName]];
      }
    } else {
      return null;
    }
  }
}
