import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Service, ServiceAppointment } from '@quorum/models/xs-resource';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { createFormGroupFromObject } from 'libs/sha-services/src/lib/createFormGroupFromObject';

@Component({
  selector: 'crm-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCardComponent implements OnInit, OnChanges {
  @Input() serviceAppointment: ServiceAppointment;

  appointmentForm: FormGroup;
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.appointmentForm = createFormGroupFromObject(this.serviceAppointment);
    this.appointmentForm.get('dateTime').disable();
    this.appointmentForm.get('odometerIn').disable();
    let apptTime = new Date(this.appointmentForm.get('dateTime').value).toTimeString().substring(0, 5);
    this.appointmentForm.addControl('appointmentTime', new FormControl(apptTime));
    this.appointmentForm.get('appointmentTime').disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serviceAppointment.currentValue) {
      this.appointmentForm = createFormGroupFromObject(changes.serviceAppointment.currentValue);
      let apptTime = new Date(this.appointmentForm.get('dateTime').value).toTimeString().substring(0, 5);
      this.appointmentForm.addControl('appointmentTime', new FormControl(apptTime));
      this.appointmentForm.get('dateTime').disable();
      this.appointmentForm.get('odometerIn').disable();
      this.appointmentForm.get('appointmentTime').disable();
    }
  }
}
