<h1 mat-dialog-title>Settings</h1>
<div mat-dialog-content>
  <div>
    <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="settingsForm">
      <div fxFlexFill fxLayout="column" fxLayoutAlign="start center">
        <span class="mat-body-1 primary-color max-width">General</span>
        <div fxLayoutAlign="space-between center" class="max-width">
          <span fxFlex>Salespeople</span>
          <mat-form-field fxFlex="50" *ngIf="data.dropdowns.salespeople$ | async as salespeople">
            <mat-select placeholder="Salesperson" name="salespeople" multiple
              (selectionChange)="settingChanged('salespeople')" required formControlName="salesPeople">
              <mat-option [value]="salespeople.employeeId.EmployeeId">{{salespeople.employeeId.EmployeeName}}
              </mat-option>
              <mat-optgroup *ngFor="let salesTeam of salespeople.teams">
                <label
                  (click)="selectTeam(salesTeam, settingsForm); settingChanged('salespeople')">{{salesTeam.teamName}}</label>
                <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                  {{salesperson.EmployeeName}}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
        </div>

        <span class="mat-body-1 primary-color max-width">Activities</span>
        <div fxLayoutAlign="space-between center" class="max-width">
          <span fxFlex>Range (Future)</span>
          <mat-form-field fxFlex="50">
            <mat-select placeholder="Range (Future)" (selectionChange)="settingChanged('range')"
              formControlName="range">
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="space-between center" class="max-width">
          <span fxFlex>Range (Past)</span>
          <mat-form-field fxFlex="50">
            <mat-select placeholder="Range (Past)" (selectionChange)="settingChanged('range')"
              formControlName="rangePast">
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="space-between center" class="max-width">
          <span fxFlex>Months Overdue</span>
          <mat-form-field fxFlex="50">
            <mat-select placeholder="Months Overdue" (selectionChange)="settingChanged('monthsOverdue')"
              formControlName="monthsOverdue">
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">12</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <span class="mat-body-1 primary-color max-width">Behavior</span>
        <div fxLayoutAlign="space-between center" class="max-width">
          <span fxFlex>Default Activity Tab</span>
          <mat-form-field fxFlex="50">
            <mat-select placeholder="Default Activity Tab" formControlName="defaultActivityTab">
              <mat-option [value]="'A'">Activity</mat-option>
              <mat-option [value]="'P'">Prospect</mat-option>
              <mat-option [value]="'V'">Vehicle</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="space-between center" class="max-width">
          <span fxFlex>Default Prospect Tab</span>
          <mat-form-field fxFlex="50">
            <mat-select placeholder="Default Prospect Tab" formControlName="defaultProspectTab">
              <mat-option [value]="'A'">Activity</mat-option>
              <mat-option [value]="'P'">Prospect</mat-option>
              <mat-option [value]="'V'">Vehicle</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="(crmSettings$ | async)?.salesPlannerView === 1" class="max-width">
          <span class="mat-body-1 primary-color max-width">Manager Settings</span>
          <div fxLayoutAlign="space-between center" class="max-width">
            <span fxFlex>Activity List Employee Display</span>
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Employee Display" formControlName="activityListDisplayEmployee"
                (selectionChange)="settingChanged('activityListDisplayEmployee')">
                <mat-option [value]="'activitySalesperson'">Salesperson</mat-option>
                <mat-option [value]="'activityAssignee'">Assignee</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div formGroupName="accessibility" class="max-width">
          <span class="mat-body-1 primary-color max-width">Accessibility (Beta)</span>
          <div fxLayoutAlign="space-between center" class="max-width">
            <span fxFlex>Theme</span>
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Select Theme" formControlName="theme">
                <mat-option [value]="'light'">Light</mat-option>
                <mat-option [value]="'dark'">Dark</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="space-between center" class="max-width">
            <span fxFlex>Bold Text</span>
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Enable Bold Text" formControlName="boldText">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="space-between center" class="max-width">
            <span fxFlex>Large Text</span>
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Enable Large Text" formControlName="largeText">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayoutAlign="space-between center" class="max-width">
            <span fxFlex>Increase Contrast</span>
            <mat-form-field fxFlex="50">
              <mat-select placeholder="Enable Increased Contrast" formControlName="increaseContrast">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="update(settingsForm.value)" cdkFocusInitial color="primary"
    [disabled]="!settingsForm.valid">Update</button>
</div>