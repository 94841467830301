import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { ProspectCreateDialogComponent, ProspectCreateProgressDialogComponent } from '@quorum/crm-prospects-ng-ui';
import { SubPrimeQueryParameters } from '@quorum/models/xs-query';
import {
  Action,
  Associate,
  Channel,
  Class,
  Employee,
  OemRooftopLink,
  Prospect,
  ProvinceState,
  Source,
  SubPrime,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { VehicleProfileDialogComponent } from 'libs/crm-sales-planner-ng-ui/src/lib/vehicle-profile-dialog/vehicle-profile-dialog.component';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, forkJoin, fromEvent, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, delay, filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AddDeductDialogComponent } from '../add-deduct-dialog/add-deduct-dialog.component';

@Component({
  selector: 'crm-sub-prime-tab',
  templateUrl: './sub-prime-tab.component.html',
  styleUrls: ['./sub-prime-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubPrimeTabComponent implements OnInit, OnDestroy, AfterViewInit {
  sort: string;
  dropdowns$: Observable<any>;
  prospectDropdowns$: Observable<any>;
  crmDropdowns$: Observable<any>;
  authenticatedEmployee: Employee;
  search$: Observable<any>;
  searchResult: any = { data: [], headers: null };
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchPerformed: boolean = false;
  routerState$: Subscription;
  leadGeneratorActionId: number;
  createProspectProgressRef: MatDialogRef<any>;
  postProspectProcessSubscription: Subscription;
  countryCode: string;
  mileageLabel: string;
  checkedVehicleIds: any[] = [];
  searchResultData: any[];
  selectAll: boolean = false;

  defaultSubPrimeFormModel: SubPrimeQueryParameters = {
    ...new SubPrimeQueryParameters(),
    actioned: null,
    availableDateFrom: new Date().toISOString(),
    availableDateTo: moment(new Date()).add(1, 'M').toISOString(),
    inEquity: null,
    equityThreshold: null,
    interestRateFrom: 0,
    interestRateTo: 15,
    paymentsMadeFrom: null,
    paymentsMadeTo: null,
    rooftopId: null,
    saleTypeId: ['F'],
    salespersonId: [],
    sort: '-availableDate',
    termFrom: null,
    termTo: null,
    pageSize: 25,
    page: 0,
    userEmployeeId: null,
  };

  subPrimeForm = this.fb.group({
    actioned: null,
    availableDateFrom: new Date().toISOString(),
    availableDateTo: new Date().toISOString(),
    inEquity: null,
    equityThreshold: [null, [Validators.pattern('^[0-9\\-\\.]*$')]],
    interestRateFrom: [0, [Validators.pattern('^[0-9\\.]*$')]],
    interestRateTo: [15, [Validators.pattern('^[0-9\\.]*$')]],
    paymentsMadeFrom: null,
    paymentsMadeTo: null,
    rooftopId: null,
    saleTypeId: ['F'],
    salespersonId: [],
    sort: '-availableDate',
    termFrom: [null, [Validators.pattern('^[0-9]*$')]],
    termTo: [null, [Validators.pattern('^[0-9]*$')]],
    pageSize: 25,
    page: 0,
    userEmployeeId: null,
  });

  private formInititialized = new ReplaySubject(1);
  public formRef: ElementRef;
  @ViewChild('subPrimeFormRef')
  set setTabSet(content: any) {
    if (!!content) {
      this.formRef = content;
      this.formInititialized.next(true);
    }
  }

  paymentsMadeSub: Subscription;

  constructor(
    public apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public crmEntityStateService: CrmEntityStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public routerStateService: RouterStateService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public equityMiningStateService: EquityMiningStateService,
    public systemControlStateService: SystemControlStateService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    this.formInititialized.pipe(take(1)).subscribe((d) => {
      this.search$ = fromEvent(this.formRef.nativeElement, 'submit');
      this.search$
        .pipe(
          switchMap((d) => this.route.queryParams.pipe(take(1))),
          switchMap((params) => {
            if (params) {
              let queryParams: any = params;
              if (
                (Object.keys(params).includes('paymentsMadeFrom') &&
                  params.paymentsMadeFrom !== null &&
                  params.paymentsMadeFrom !== '') ||
                (Object.keys(params).includes('paymentsMadeTo') &&
                  params.paymentsMadeTo !== null &&
                  params.paymentsMadeTo !== '')
              ) {
                this.subPrimeForm.get('availableDateFrom').disable({ emitEvent: false });
                this.subPrimeForm.get('availableDateTo').disable({ emitEvent: false });
                let { availableDateFrom, availableDateTo, ...restOfParams } = params;
                queryParams = restOfParams;
              }
              this.equityMiningStateService.updateReportParameters('sub-prime', params);
              this.isLoading$.next(true);
              this.hasError$.next(false);
              return this.apiService
                .getWithHeaders<SubPrime[]>(`v/1/reporting/crm/sub-prime`, { params: queryParams })
                .pipe(
                  map((response) => {
                    this.isLoading$.next(false);
                    this.searchPerformed = true;

                    return { data: response.body, headers: response.headers };
                  }),
                  catchError((error) => {
                    this.isLoading$.next(false);
                    this.hasError$.next(true);
                    return of(error);
                  })
                );
            } else {
              this.isLoading$.next(false);
              return of({ data: null, headers: null });
            }
          })
        )
        .subscribe((sresult: any) => {
          this.searchResult = sresult;
          this.searchResultData = this.searchResult.data.map((data: any) => ({ ...data, checked: false }));
          this.checkedVehicleIds.forEach((vehicleId: any) => {
            if (this.searchResultData.some((item) => item.vehicleId === vehicleId)) {
              this.searchResultData.find((item) => item.vehicleId === vehicleId).checked = true;
            }
          });

          this.checkAllChecked();
        });
    });
  }

  ngOnInit() {
    this.sort = '-availableDate';

    this.subPrimeForm.valueChanges.pipe(startWith(null), pairwise()).subscribe(([previousValue, currentValue]) => {
      currentValue = {
        ...currentValue,
        availableDateTo: new Date(this.subPrimeForm.get('availableDateTo').value).toISOString(),
        availableDateFrom: new Date(this.subPrimeForm.get('availableDateFrom').value).toISOString(),
      };

      if (previousValue && previousValue.page === currentValue.page) {
        this.sort = currentValue.sort;
        this.subPrimeForm.patchValue({ ...currentValue, page: 0 }, { emitEvent: false });
        this.updateRouter({ ...currentValue, page: 0 });
      } else {
        this.updateRouter(currentValue);
      }
    });

    if (this.subPrimeForm.get('paymentsMadeFrom')) {
      this.paymentsMadeSub = combineLatest(
        this.subPrimeForm.get('paymentsMadeFrom').valueChanges.pipe(startWith(null)),
        this.subPrimeForm.get('paymentsMadeTo').valueChanges.pipe(startWith(null))
      )
        .pipe(
          map(([paymentsMadeFrom, paymentsMadeTo]) => {
            return { paymentsMadeFrom, paymentsMadeTo };
          })
        )
        .subscribe((data) => {
          if (
            data.paymentsMadeFrom ||
            data.paymentsMadeFrom === 0 ||
            data.paymentsMadeTo ||
            data.paymentsMadeTo === 0
          ) {
            this.subPrimeForm.get('availableDateFrom').disable({ emitEvent: false });
            this.subPrimeForm.get('availableDateTo').disable({ emitEvent: false });
          } else if (!data.paymentsMadeFrom && !data.paymentsMadeTo) {
            this.subPrimeForm.get('availableDateFrom').enable({ emitEvent: false });
            this.subPrimeForm.get('availableDateTo').enable({ emitEvent: false });
          }
        });
    }

    this.dropdowns$ = forkJoin(
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
        take(1)
      ),
      this.dropdownsStateService.selectSalespeople().pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        take(1)
      )
    ).pipe(
      map(([rooftops, salespeople]) => {
        return { rooftops, salespeople };
      })
    );

    this.prospectDropdowns$ = combineLatest(
      this.dropdownsStateService
        .selectChannels()
        .pipe(filter((channels) => !this.dropdownsStateService.isEmpty(channels))),
      this.dropdownsStateService
        .selectCustomerTypes()
        .pipe(filter((customerTypes) => !this.dropdownsStateService.isEmpty(customerTypes))),
      this.dropdownsStateService
        .selectMessageTypes()
        .pipe(filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes))),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops) => rooftops.filter((rooftop: any) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService
        .selectSources()
        .pipe(filter((sources) => !this.dropdownsStateService.isEmpty(sources))),
      this.dropdownsStateService
        .selectAssociateClasses()
        .pipe(filter((associateClasses: Class[]) => !this.dropdownsStateService.isEmpty(associateClasses))),
      this.dropdownsStateService
        .selectProspectActions()
        .pipe(filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions))),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(
        ([
          channels,
          customerTypes,
          messageTypes,
          rooftops,
          salespeople,
          sources,
          associateClasses,
          prospectActions,
          provStates,
        ]) => {
          return {
            channels,
            customerTypes,
            messageTypes,
            rooftops,
            salespeople,
            sources,
            associateClasses,
            prospectActions,
            provStates,
          };
        }
      )
    );

    this.crmDropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleSubcategories().pipe(take(1)),
      this.dropdownsStateService.selectVehicleSubTypes().pipe(take(1)),
      this.dropdownsStateService.selectVehicleStatuses().pipe(take(1)),
      this.dropdownsStateService.selectVehicleColours().pipe(take(1)),
      this.dropdownsStateService.selectRooftops().pipe(take(1))
    ).pipe(
      map(([vehicleSubcategories, vehicleSubTypes, vehicleStatuses, vehicleColours, rooftops]) => {
        return {
          vehicleSubcategories,
          vehicleSubTypes,
          vehicleStatuses,
          vehicleColours,
          rooftops,
        };
      })
    );

    this.routerState$ = forkJoin(
      this.routerStateService.selectRouterState().pipe(
        filter((routerState) => routerState.state.url.indexOf('lead-generator/sub-prime') > -1),
        take(1)
      ),
      this.authenticationStateService.selectAuthenticatedEmployee().pipe(
        filter((employee) => employee != null),
        take(1),
        tap((authEmployee) => {
          this.systemControlStateService
            .getSystemControlValues(['CL_DEALER_COUNTRY_ID'], authEmployee.rooftopId)
            .pipe(take(1))
            .subscribe((sct) => {
              this.authenticatedEmployee = authEmployee;
              this.mileageLabel = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'mi.' : 'km';
              this.countryCode = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'U' : 'C';
            });
        })
      )
    ).subscribe(([routerState, authenticatedEmployee]) => {
      let queryParams: any = routerState.state.root.queryParams;
      if (this.dropdownsStateService.isEmpty(queryParams)) {
        this.subPrimeForm.patchValue(
          {
            ...this.defaultSubPrimeFormModel,
            salespersonId: [authenticatedEmployee.associateId],
            rooftopId: authenticatedEmployee.rooftopId.toString(),
            userEmployeeId: authenticatedEmployee.associateId,
          },
          { emitEvent: true }
        );
        this.sort = '-availableDate';
      } else {
        if (queryParams.sort) {
          this.sort = queryParams.sort;
        }

        let formModel = {
          ...this.defaultSubPrimeFormModel,
          ...queryParams,
          salespersonId: Array.isArray(queryParams.salespersonId)
            ? [...queryParams.salespersonId]
            : [queryParams.salespersonId],
          inEquity: queryParams.inEquity
            ? Array.isArray(queryParams.inEquity)
              ? [...queryParams.inEquity]
              : [queryParams.inEquity]
            : [],
          saleTypeId: queryParams.saleTypeId
            ? Array.isArray(queryParams.saleTypeId)
              ? [...queryParams.saleTypeId]
              : [queryParams.saleTypeId]
            : [],
        };

        this.subPrimeForm.patchValue(formModel, {
          emitEvent: false,
        });

        this.formInititialized.pipe(delay(1000), take(1)).subscribe((d) => {
          if (this.subPrimeForm.valid) {
            this.renderer.selectRootElement('.search-button', true).click();
          }
        });
      }
    });

    this.dropdownsStateService
      .selectProspectActions()
      .pipe(
        filter((prospectActions) => prospectActions != null && prospectActions.length > 0),
        take(1)
      )
      .subscribe((prospectActions) => {
        this.leadGeneratorActionId = prospectActions.find((ac: Action) => ac.description === 'Lead Generator').id;
        this.postProspectProcessSubscription = this.crmEntityStateService
          .postProspectCreationProcess(this.leadGeneratorActionId)
          .subscribe((prospect: Prospect) => {
            this.createProspectProgressRef.close();
            let snackBarRef = this.snackBar.open(`Prospect ${prospect.id} created.`, 'OPEN', {
              duration: 4000,
              horizontalPosition: 'start',
            });

            snackBarRef
              .onAction()
              .pipe(take(1))
              .subscribe((action) => {
                this.routerStateService.go([`sales-planner/prospects/${prospect.id}`], {
                  relativeTo: this.route.parent.parent,
                });
              });
          });
      });
  }

  ngOnDestroy() {
    this.routerState$.unsubscribe();
    this.postProspectProcessSubscription.unsubscribe();
  }

  selectTeam(salesTeam: any, subPrimeForm: FormGroup) {
    let selectedTeamSalespeople = salesTeam.children.map((salesperson: any) => salesperson.EmployeeId);
    let selectedSalespeople = subPrimeForm.get('salespersonId').value;

    let existingCheck = selectedSalespeople.some((el: any) => selectedTeamSalespeople.includes(el));

    selectedTeamSalespeople.forEach((salespersonId: string) => {
      if (existingCheck) {
        if (selectedSalespeople.includes(salespersonId)) {
          selectedSalespeople.splice(selectedSalespeople.indexOf(salespersonId, 0), 1);
        }
      } else {
        if (!selectedSalespeople.includes(salespersonId)) {
          selectedSalespeople.push(salespersonId);
        }
      }
    });

    subPrimeForm.get('salespersonId').patchValue(null);
    subPrimeForm.get('salespersonId').patchValue(selectedSalespeople);
  }

  updateRouter(queryParams: any) {
    let navExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    };

    this.routerStateService.go('', navExtras);
  }

  sortChanged(sortValue: string, subPrimeForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.sort == sortValue;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.renderer.selectRootElement('.search-button', true).click();
      });

    subPrimeForm.patchValue({ ...subPrimeForm.value, sort: sortValue });
  }

  getSubPrimeReport() {
    this.renderer.selectRootElement('.search-button', true).click();
  }

  getErrorMessage(formField: string) {
    return this.subPrimeForm.get(formField).hasError('required')
      ? 'You must enter a name.'
      : this.subPrimeForm.get(formField).hasError('email')
      ? 'Not a valid email.'
      : '';
  }

  clearSearchResults() {
    this.searchResult = { data: [], headers: null };
  }

  resetSubPrimeForm(authenticatedEmployee: Employee) {
    this.subPrimeForm.patchValue({
      ...this.defaultSubPrimeFormModel,
      salespersonId: [authenticatedEmployee.associateId],
      rooftopId: authenticatedEmployee.rooftopId.toString(),
      userEmployeeId: authenticatedEmployee.associateId,
    });
  }

  pageChanged(event: any, subPrimeForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.page == event.pageIndex;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.renderer.selectRootElement('.search-button', true).click();
      });

    subPrimeForm.patchValue({ ...subPrimeForm.value, page: event.pageIndex, pageSize: event.pageSize });
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['..', 'sales-planner', 'prospects', prospectId], {
      relativeTo: this.route.parent,
    });
  }

  openCreateProspectDialog(reportType: string, reportItem: any, authenticatedEmployee: Employee): void {
    combineLatest(
      this.prospectDropdowns$.pipe(take(1)),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1))
    )
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe((combinedData) => {
        let leadGeneratorChannel = combinedData.dropdowns.channels.find(
          (channel: Channel) => channel.description == 'Lead Generator'
        );
        let reportTypeSource = combinedData.dropdowns.sources.find(
          (source: Source) => source.description.toLowerCase() == reportType
        );

        let leadGeneratorActionId = combinedData.dropdowns.prospectActions.find(
          (ac: Action) => ac.description === 'Lead Generator'
        ).id;

        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (combinedData.sct.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef = this.dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: combinedData.dropdowns,
            newProspect: {
              ...new Prospect(),
              actionId: leadGeneratorActionId,
              actionedVehicleId: reportItem.vehicleId,
              associateId: reportItem.associateId,
              channelId: leadGeneratorChannel.id,
              customerTypeId: '1',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: authenticatedEmployee.associateId,
              rooftopId: authenticatedEmployee.rooftopId,
              sourceId: reportTypeSource.id,
              statusId: '1',
              typeId: 3,
              vehicleId: reportItem.vehicleId,
            },
            newAssociate: {
              ...new Associate(),
              id: reportItem.associateId,
              firstName: reportItem.customerFirstName,
              lastName: reportItem.customerLastName,
            },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields: {
              associate: ['name'],
              prospect: ['channelId', 'sourceId', 'actionId'],
              contact: [],
            },
          },
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = this.dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
          }
        });
      });
  }

  openDetailsDialog(authenticatedEmployee: Employee, reportItem: any, countryCode: string) {
    const dialogRef = this.dialog.open(AddDeductDialogComponent, {
      width: '600px',
      data: {
        reportItem: reportItem,
        rooftopId: authenticatedEmployee.rooftopId,
        countryCode: countryCode,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse.reload) {
        this.renderer.selectRootElement('.search-button', true).click();
        this.snackBar.open(`Vehicle ${dialogResponse.vehicleId} updated`, null, {
          duration: 3000,
          horizontalPosition: 'start',
        });
      }
    });
  }

  openVehicleProfileInfo(item: any) {
    this.apiService
      .get(`/v/1/vehicles/vehicles/${item.vehicleId}`, { params: { embed: 'sale' } })
      .subscribe((vehicle) => {
        const dialogRef = this.dialog.open(VehicleProfileDialogComponent, {
          disableClose: true,
          panelClass: 'custom-vehicle-profile-dialog',
          data: {
            vehicle: vehicle,
            associateFirstName: item.customerFirstName,
            associateLastName: item.customerLastName,
            crmDropdowns$: this.crmDropdowns$,
            salespersonFirstName: item.salespersonFirstName,
            salespersonLastName: item.salespersonLastName,
          },
        });
      });
  }

  trackByFunction(index: number, item: SubPrime) {
    return item.associateId;
  }

  addRemoveVehicleId(checked: boolean, vehicleId: string) {
    if (!checked) {
      if (!this.checkedVehicleIds.includes(vehicleId)) {
        this.checkedVehicleIds.push(vehicleId);
        this.searchResultData.find((item) => item.vehicleId === vehicleId).checked = true;
      }
    } else {
      if (this.checkedVehicleIds.includes(vehicleId)) {
        const id = this.checkedVehicleIds.indexOf(vehicleId);
        this.checkedVehicleIds.splice(id, 1);
        this.searchResultData.find((item) => item.vehicleId === vehicleId).checked = false;
      }
    }
  }

  selectAllCards(select: boolean) {
    for (let i = 0; i < this.searchResultData.length; i++) {
      this.addRemoveVehicleId(select, this.searchResultData[i].vehicleId);
    }
  }

  checkAllChecked() {
    for (let i = 0; i < this.searchResultData.length; i++) {
      if (this.searchResultData[i].checked === false) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    }
  }
}
