import { GenericModel } from '@quorum/models/xs-resource';
import { GenericModelQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_GENERIC_MODELS_FROM_SERVER = '[CRM] Get Vehicle Generic Models From Server';
export class GetVehicleGenericModelsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_GENERIC_MODELS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: GenericModelQueryParameters) {}
}

export const GET_VEHICLE_GENERIC_MODELS_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Generic Models From Server Success';
export class GetVehicleGenericModelsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_GENERIC_MODELS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleGenericModels: GenericModel[] }) {}
}

export const GET_VEHICLE_GENERIC_MODELS_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Generic Models From Server Failure';
export class GetVehicleGenericModelsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_GENERIC_MODELS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VehicleGenericModelsAction =
  | GetVehicleGenericModelsFromServer
  | GetVehicleGenericModelsFromServerSuccess
  | GetVehicleGenericModelsFromServerFailure;
