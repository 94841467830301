export class AppointmentQueryParameters {
  appointmentDateFrom?: string;
  appointmentDateTo?: string;
  attendanceFlag?: string;
  completedDateFrom?: Date;
  completedDateTo?: Date;
  embed?: Array<string>;
  id?: number;
  page?: number;
  pageSize?: number;
  prospectId?: number;
  taskEmployeeId?: string[];

  constructor(obj?: AppointmentQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
