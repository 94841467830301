import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Channel } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
import * as fromChannels from './channels.actions';
import { ChannelsState } from './channels.interfaces';
@Injectable()
export class ChannelsEffects {
  
  getTasksFromServer = createEffect(() => this.dataPersistence.fetch(fromChannels.GET_CHANNELS_FROM_SERVER, {
    run: (action: fromChannels.GetChannelsFromServer, state: ChannelsState) => {
      return this.apiService
        .get<Channel[]>(`v/1/crm/prospect-channels`, {
          params: action.payload,
        })
        .pipe(
          map((channels) => {
            return new fromChannels.GetChannelsFromServerSuccess({
              channels: channels,
            });
          })
        );
    },
    onError: (action: fromChannels.GetChannelsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<ChannelsState>,
    private apiService: ApiService
  ) {}
}
