import * as fromVehicleSubTypes from './vehicle-subtypes.actions';
import { initialState, vehicleSubTypesAdapter, VehicleSubTypesState } from './vehicle-subtypes.interfaces';

export function reducer(
  state: VehicleSubTypesState = initialState,
  action: fromVehicleSubTypes.VehicleSubTypesAction
): VehicleSubTypesState {
  switch (action.type) {
    case fromVehicleSubTypes.GET_VEHICLE_SUBTYPES_FROM_SERVER_SUCCESS: {
      return {
        ...vehicleSubTypesAdapter.addMany(action.payload.vehicleSubTypes, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
