<h1>Select a Closing Action</h1>
<div mat-dialog-content>
  <form fxLayout="column" fxLayoutAlign="start start" [formGroup]="prospectClosedActionsForm">
    <mat-radio-group fxLayout="column" fxLayoutGap="12px" formControlName="prospectClosedAction" required>
      <mat-radio-button *ngFor="let action of data.prospectClosedActions" [value]="action.id">
        {{action.description}}
      </mat-radio-button>
    </mat-radio-group>
  </form>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="prospectClosedActionsForm.value" cdkFocusInitial color="primary" [disabled]="!prospectClosedActionsForm.valid">Close</button>
</div>