import * as fromDirectMessageTemplates from './direct-message-templates.actions';
import {
  directMessageTemplatesAdapter,
  DirectMessageTemplatesState,
  initialState
} from './direct-message-templates.interfaces';

export function reducer(
  state: DirectMessageTemplatesState = initialState,
  action: fromDirectMessageTemplates.DirectMessageTemplatesAction
): DirectMessageTemplatesState {
  switch (action.type) {
    case fromDirectMessageTemplates.GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_SUCCESS: {
      return {
        ...directMessageTemplatesAdapter.addMany(action.payload.directMessageTemplates, state)
      };
    }

    default: {
      return state;
    }
  }
}
