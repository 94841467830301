import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { ShaComponentsNgUiModule } from '@quorum/sha-components-ng-ui';
import { ShellComponent } from './shell/shell.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    CommonMaterialComponentsModule,
    MatSidenavModule,
    ShaComponentsNgUiModule,
  ],
  exports: [ShellComponent],
  declarations: [ShellComponent],
})
export class CrmNgUiModule {}
