import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@quorum/api';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { ProspectCreateDialogComponent, ProspectCreateProgressDialogComponent } from '@quorum/crm-prospects-ng-ui';
import { ProspectActionQueryParameters, TaskQueryParameters, SaleQueryParameters } from '@quorum/models/xs-query';
import { Action, Associate, ReportingVehicleSearch, Employee, Prospect } from '@quorum/models/xs-resource';
import {
  LocalSettingsState,
  SalesPlannerStateService,
  SelectedActivityStateService,
  TasksStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { AuthenticatedUser } from 'libs/authentication/src/lib/+state/authentication.interfaces';
import { BrowserControlCommunicationService } from 'libs/sha-services/src/lib/browserControlCommunication.service';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'crm-vehicle-search-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleCardComponent implements OnInit {
  @Input() vehicleSearchResult: ReportingVehicleSearch;
  @Input() autovanceEnabled: boolean;
  @Input() dropdowns: any;
  @Input() defaultPricingSct: any;
  @Input() authenticatedEmployee: Employee;
  @Input() settings: any;
  @Input() prospectDropdowns: any;
  @Input() localSettings: LocalSettingsState;
  @Input() selectedProspect: Prospect;
  drilldownEnabled: boolean;
  authenticatedUser$: Observable<AuthenticatedUser>;
  dropdowns$: Observable<any>;
  defaultVehiclePricing: { description: string; value: number } = { description: '', value: 0 };
  deskButtonDisabled: boolean = false;
  createProspectProgressRef: MatDialogRef<any>;
  postProspectProcessSubscription: Subscription;

  constructor(
    public browserControlCommunicationService: BrowserControlCommunicationService,
    private selectedActivityStateService: SelectedActivityStateService,
    public dialog: MatDialog,
    public salesPlannerStateService: SalesPlannerStateService,
    private apiService: ApiService,
    private crmEntityStateService: CrmEntityStateService,
    private systemControlStateService: SystemControlStateService,
    private tasksStateService: TasksStateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.drilldownEnabled = this.browserControlCommunicationService.drilldownEnabled;

    switch (this.defaultPricingSct.value) {
      case 'P1':
        this.defaultVehiclePricing.description = this.vehicleSearchResult.price1Description;
        this.defaultVehiclePricing.value = this.vehicleSearchResult.price1;
        break;
      case 'P2':
        this.defaultVehiclePricing.description = this.vehicleSearchResult.price2Description;
        this.defaultVehiclePricing.value = this.vehicleSearchResult.price3;
        break;
      case 'P3':
        this.defaultVehiclePricing.description = this.vehicleSearchResult.price3Description;
        this.defaultVehiclePricing.value = this.vehicleSearchResult.price3;
        break;
    }

    this.postProspectProcessSubscription = this.crmEntityStateService
      .postProspectCreateAndUpdate()
      .pipe(take(1))
      .subscribe((prospect: Prospect) => {
        if (this.createProspectProgressRef) {
          this.createProspectProgressRef.close();
        }
      });
    this.cdr.detectChanges();
  }

  openVehicleSales(vehicle: ReportingVehicleSearch) {
    let params: string[] = [vehicle.vehicleId.toString()];
    this.browserControlCommunicationService.drilldown('vehicleSearch', 'w_vehicle_sales_display_name', params);
  }

  openVehicleInvoice(vehicle: ReportingVehicleSearch) {
    let params: string[] = [
      vehicle.orderNumber.toString(),
      vehicle.invoiceNumber.toString(),
      vehicle.rooftopId.toString(),
    ];
    this.browserControlCommunicationService.drilldown('vehicleSearch', 'w_vehicle_invoicing_invoice', params);
  }

  createVehicleSale(
    selectedProspect: Prospect,
    vehicleSearchResult: ReportingVehicleSearch,
    drilldownEnabled: boolean,
    localSettings: LocalSettingsState
  ) {
    let params: string[] = [
      vehicleSearchResult.vehicleId.toString(),
      '1',
      selectedProspect.associateId ? selectedProspect.associateId.toString() : 'null',
      selectedProspect.id.toString(),
      selectedProspect.rooftopId.toString(),
      drilldownEnabled ? 'true' : 'false',
    ];

    this.browserControlCommunicationService.drilldown('createSale', 'gf_create_vehicle_sale', params);

    // if (!drilldownEnabled) {
    //   this.browserControlCommunicationService.receivePowerBuilderMessage().then((messageObject: any) => {
    //     if (messageObject.VehicleSaleId !== '') {
    //       let toastOptions: any = {
    //         message: `Sale ${messageObject.VehicleSaleId} created.`,
    //         actionText: null,
    //         options: { duration: 3000 }
    //       };

    //       this.salesPlannerStateService.addToast(toastOptions);
    //     }
    //   });
    // }
    let updatedProspect: Prospect = new DeepCopyPipe().transform(selectedProspect);
    let queryParams: SaleQueryParameters;
    if (selectedProspect.typeId === 3) {
      queryParams = new SaleQueryParameters({
        showroomProspectId: selectedProspect.id.toString(),
        embed: 'vehicle',
      });
    } else if (selectedProspect.typeId === 4) {
      queryParams = new SaleQueryParameters({
        retentionProspectId: selectedProspect.id.toString(),
        embed: 'vehicle',
      });
    }
    this.selectedActivityStateService.getSelectedProspectOpenSalesFromServer(queryParams);
    this.selectedActivityStateService.getSelectedProspectActionsFromServer({
      prospectId: selectedProspect.id,
      prospectActionQueryParams: new ProspectActionQueryParameters({
        embed: ['employeeAssociate', 'action'],
      }),
    });
    this.selectedActivityStateService.getSelectedProspectTasksFromServer(
      new TaskQueryParameters({ prospectId: selectedProspect.id, embed: 'prospect,taskEmployeeAssociate,taskOutcome' })
    );

    this.getTasksFromServer(localSettings);
    this.crmEntityStateService.updateProspectOnServer({ ...updatedProspect, updatedDate: new Date() }, false);
  }

  createDeskedDeal(prospect: Prospect, vehicle: ReportingVehicleSearch, localSettings: LocalSettingsState) {
    this.deskButtonDisabled = true;
    let payload: any = {
      associateId: prospect.associateId,
      autovanceUserGuid: this.settings.autovanceUserGuid,
      currentRooftopId: prospect.rooftopId,
      openVsWindows: 0,
      prospectId: prospect.id,
      salespersonId: prospect.primaryEmployeeId,
      vehicleId: vehicle.vehicleId,
      vehicleSearch: prospect.associateId ? true : false,
      vehicleStatus: vehicle.vehicleStatusId,
    };
    this.apiService.post<any>(`v/1/vehicles/sales/autovance`, payload).subscribe((sale) => {
      if (this.settings.autovanceDeskRole != null && this.settings.autovanceDeskRole != 0) {
        if (this.drilldownEnabled) {
          let params: string[] = [sale.dealUrl, sale.dealXsSaleId.toString()];
          this.browserControlCommunicationService.drilldown('autovanceCreateDeal', 'w_autovance_desk', params);
        } else {
          window.open(`${sale.dealUrl}`, '_blank');
        }
      } else {
        let toastOptions: any = {
          message: `Desk deal ${sale.dealXsSaleId} was created.`,
          actionText: null,
          options: { duration: 6000, horizontalPosition: 'left' },
        };

        this.salesPlannerStateService.addToast(toastOptions);
      }
      let queryParams: SaleQueryParameters;
      if (prospect.typeId === 3) {
        queryParams = new SaleQueryParameters({
          showroomProspectId: prospect.id.toString(),
          embed: 'vehicle',
        });
      } else if (prospect.typeId === 4) {
        queryParams = new SaleQueryParameters({
          retentionProspectId: prospect.id.toString(),
          embed: 'vehicle',
        });
      }
      this.selectedActivityStateService.getSelectedProspectOpenSalesFromServer(queryParams);
      this.selectedActivityStateService.getSelectedProspectActionsFromServer({
        prospectId: prospect.id,
        prospectActionQueryParams: new ProspectActionQueryParameters({
          embed: ['employeeAssociate', 'action'],
        }),
      });
      this.selectedActivityStateService.getSelectedProspectTasksFromServer(
        new TaskQueryParameters({ prospectId: prospect.id, embed: 'prospect,taskEmployeeAssociate,taskOutcome' })
      );
      this.getTasksFromServer(localSettings);
      this.selectedActivityStateService.getSelectedProspectFromServer(prospect);
      this.deskButtonDisabled = false;
    });
  }

  getTasksFromServer(localSettings: LocalSettingsState) {
    let dateFrom = new Date(
      new Date(localSettings.startDate).getFullYear(),
      new Date(localSettings.startDate).getMonth(),
      new Date(localSettings.startDate).getDate() - (localSettings.rangePast - 1)
    ).toISOString();

    let dateTo = new Date(
      new Date(localSettings.startDate).getFullYear(),
      new Date(localSettings.startDate).getMonth(),
      new Date(localSettings.startDate).getDate() + localSettings.range
    ).toISOString();

    this.tasksStateService.getTasksFromServer(
      new TaskQueryParameters({
        dueDateFrom: dateFrom,
        dueDateTo: dateTo,
        embed: 'prospect,taskEmployeeAssociate',
        taskEmployeeId: localSettings.salesPeople,
        open: '1',
        pageSize: 2000,
      })
    );
  }

  openCreateProspectDialog(
    selectedVehicle: ReportingVehicleSearch,
    authenticatedEmployee: Employee,
    prospectDropdowns: any
  ): void {
    combineLatest(
      of(prospectDropdowns),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1))
    )
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe((combinedData) => {
        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (combinedData.sct.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef = this.dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: combinedData.dropdowns,
            newProspect: {
              ...new Prospect(),
              actionId: combinedData.dropdowns.prospectActions.find((ac: Action) => ac.description === 'Opportunity')
                .id,
              actionedVehicleId: selectedVehicle.vehicleId,
              associateId: null,
              customerTypeId: '3',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: authenticatedEmployee.associateId,
              rooftopId: authenticatedEmployee.rooftopId,
              statusId: '1',
              typeId: 3,
              vehicleId: selectedVehicle.vehicleId,
            },
            newAssociate: { ...new Associate(), classId: 'INDIV', initialContactMethod: 'C' },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields: {
              associate: ['classId', 'emailAddress'],
              prospect: [],
              contact: [],
            },
          },
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = this.dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
          }
        });
      });
  }
}
