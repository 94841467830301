import * as fromTaskOutcomesActions from './task-outcomes.actions';
import { initialState, taskOutcomesAdapter, TaskOutcomesState } from './task-outcomes.interfaces';

export function reducer(
  state: TaskOutcomesState = initialState,
  action: fromTaskOutcomesActions.TaskOutcomesAction
): TaskOutcomesState {
  switch (action.type) {
    case fromTaskOutcomesActions.GET_TASK_OUTCOMES_FROM_SERVER_SUCCESS: {
      return {
        ...taskOutcomesAdapter.addMany(action.payload.taskOutcomes, state)
      };
    }

    default: {
      return state;
    }
  }
}
