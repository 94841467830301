import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Class } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface AssociateClassesState extends EntityState<Class> {}

export const associateClassesAdapter: EntityAdapter<Class> = createEntityAdapter<Class>({
  selectId: (associateClass: Class) => associateClass.id,
});

export const initialState: AssociateClassesState = associateClassesAdapter.getInitialState();

export const associateClassesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.associateClasses
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  associateClassesAdapter.getSelectors(associateClassesSelector);
