import * as fromTasks from './tasks.actions';
import { initialState, tasksAdapter, TasksState } from './tasks.interfaces';

export function reducer(state: TasksState = initialState, action: fromTasks.TasksAction): TasksState {
  switch (action.type) {
    case fromTasks.GET_TASKS_FROM_SERVER: {
      return {
        ...state,
        loading: true
      };
    }
    case fromTasks.GET_TASKS_FROM_SERVER_SUCCESS: {
      return {
        ...tasksAdapter.addMany(action.payload.tasks, state),
        loading: false
      };
    }
    case fromTasks.CREATE_TASK_ON_SERVER_SUCCESS: {
      return {
        ...tasksAdapter.addOne(action.payload, state)
      };
    }
    case fromTasks.UPDATE_TASK_ON_SERVER_SUCCESS: {
      return {
        ...tasksAdapter.updateOne(action.payload, state)
      };
    }
    case fromTasks.CLEAR_TASKS_FROM_STATE: {
      return {
        ...tasksAdapter.removeAll({ ...state, loading: false })
      };
    }
    case fromTasks.GET_TEMPLATE_CREATED_TASKS_FROM_SERVER_SUCCESS: {
      return {
        ...tasksAdapter.addMany(action.payload.tasks, state),
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
