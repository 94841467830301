import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WorkOrder } from '@quorum/models/xs-resource';
// eslint-disable-next-line max-len
import { BrowserControlCommunicationService } from '../../../../sha-services/src/lib/browserControlCommunication.service';

@Component({
  selector: 'crm-vehicle-service-history',
  templateUrl: './vehicle-service-history.component.html',
  styleUrls: ['./vehicle-service-history.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleServiceHistoryComponent implements OnInit {
  @Input() vehicleServiceHistory: WorkOrder[];
  constructor(public browserControlCommunicationService: BrowserControlCommunicationService) {}

  ngOnInit() {}

  openVehicleServiceHistory(workOrderId: number) {
    if (this.browserControlCommunicationService.drilldownEnabled) {
      let params: string[] = [workOrderId.toString()];
      this.browserControlCommunicationService.drilldown('salesPlanner', 'w_work_order_warranty_window', params);
    }
  }
}
