import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { ReportingVehicleSearch } from '@quorum/models/xs-resource';
import { of } from 'rxjs';
import { mergeAll, mergeMap } from 'rxjs/operators';
import { VehicleSearchStateService } from './crm-vehicle-search-state.service';
import * as fromVehicleSearch from './vehicle-search.actions';
import { VehicleSearchState } from './vehicle-search.interfaces';

@Injectable()
export class VehicleSearchEffects {
  
  getVehicleSearchResultsFromServer = createEffect(() => this.dataPersistence.fetch(
    fromVehicleSearch.GET_VEHICLE_SEARCH_RESULTS_FROM_SERVER,
    {
      run: (action: fromVehicleSearch.GetVehicleSearchResultsFromServer, state: VehicleSearchState) => {
        return this.apiService
          .get<ReportingVehicleSearch[]>(`v/1/reporting/sales/vehicle-search`, {
            params: action.payload,
          })
          .pipe(
            mergeMap((vehicleSearchResults: ReportingVehicleSearch[]) => {
              let actions: any[] = [];
              let filteredVehicleSearchResults: ReportingVehicleSearch[] = [];

              vehicleSearchResults.forEach((vSearch) => {
                let findIndexResult = filteredVehicleSearchResults.findIndex(
                  (result) => vSearch.vehicleId === result.vehicleId
                );
                if (findIndexResult > -1) {
                  if (filteredVehicleSearchResults[findIndexResult].saleStatusId === 'B') {
                    // nothing
                  } else if (filteredVehicleSearchResults[findIndexResult].saleStatusId === 'P') {
                    if (vSearch.saleStatusId === 'B') {
                      filteredVehicleSearchResults.splice(findIndexResult, 1, vSearch);
                    }
                  } else {
                    if (vSearch.saleStatusId === 'B' || vSearch.saleStatusId === 'P') {
                      filteredVehicleSearchResults.splice(findIndexResult, 1, vSearch);
                    }
                  }
                } else {
                  filteredVehicleSearchResults.push(vSearch);
                }
              });

              let toastOptions: any = {
                message: `${filteredVehicleSearchResults.length} results found.`,
                actionText: null,
                options: { duration: 3000 },
              };

              this.vehicleSearchStateService.addToast(toastOptions);
              actions.push(
                new fromVehicleSearch.GetVehicleSearchResultsFromServerSuccess({
                  vehicleSearchResults: filteredVehicleSearchResults,
                })
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromVehicleSearch.GetVehicleSearchResultsFromServer, error) => {
        console.error('Error', error);
      },
    }
  ));
  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<VehicleSearchState>,
    private apiService: ApiService,
    private vehicleSearchStateService: VehicleSearchStateService
  ) {}
}
