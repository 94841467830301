import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { MomentPipe } from '@quorum/common-pipes';
import { Task } from '@quorum/models/xs-resource';
import { LocalSettingsStateService, SalesPlannerStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { of } from 'rxjs';
import { flatMap, mergeAll, switchMap } from 'rxjs/operators';
import * as fromProspects from '../../../../../../xsr-entities/src/lib/crm/prospects/prospects.actions';
import * as fromProspectTasks from '../../selected-activity/prospect-tasks/prospect-tasks.actions';
import * as fromSelectedActivity from '../../selected-activity/selected-activity.actions';
import * as fromTasks from './tasks.actions';
import { TasksState } from './tasks.interfaces';

@Injectable()
export class TasksEffects {
  
  getTasksFromServer = createEffect(() => this.dataPersistence.fetch(fromTasks.GET_TASKS_FROM_SERVER, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromTasks.GetDealerTasksFromServer, state: any) => {
      return this.apiService
        .get<Task[]>(`v/1/crm/tasks`, {
          params: action.payload,
        })
        .pipe(
          flatMap((tasks) => {
            let actions: any[] = [];

            actions.push(new fromTasks.ClearTasksFromState());

            actions.push(
              new fromTasks.GetDealerTasksFromServerSuccess({
                tasks: tasks,
              })
            );
            return of(actions).pipe(mergeAll());
          })
        );
    },
    onError: (action: fromTasks.GetDealerTasksFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  getTemplateCreatedTasksFromServer = createEffect(() => this.dataPersistence.fetch(fromTasks.GET_TEMPLATE_CREATED_TASKS_FROM_SERVER, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromTasks.GetTemplateCreatedTasksFromServer, state: any) => {
      return this.apiService
        .get<Task[]>(`v/1/crm/tasks`, {
          params: action.payload,
        })
        .pipe(
          flatMap((tasks) => {
            let actions: any[] = [];
            actions.push(
              new fromTasks.GetTemplateCreatedTasksFromServerSuccess({
                tasks: tasks,
              })
            );
            return of(actions).pipe(mergeAll());
          })
        );
    },
    onError: (action: fromTasks.GetTemplateCreatedTasksFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  createTaskOnServer = createEffect(() => this.dataPersistence.fetch(fromTasks.CREATE_TASK_ON_SERVER, {
    run: (action: fromTasks.CreateTaskOnServer, state: any) => {
      return this.apiService
        .post<Task>(`v/1/crm/tasks`, action.payload, {
          params: { embed: 'prospect, taskEmployeeAssociate' },
        })
        .pipe(
          switchMap((task: Task) => {
            let actions: Action[] = [];
            let localSettings: any;

            let toastOptions: any = {
              message: `Task created.`,
              actionText: null,
              options: { duration: 3000, horizontalPosition: 'left' },
            };

            this.salesplannerStateService.addToast(toastOptions);
            this.localSettingsStateService
              .selectLocalSettingsFromState()
              .subscribe((settings) => (localSettings = settings));

            if (
              localSettings.activitiesFilter.includes('T') &&
              new MomentPipe().isBetween(
                new Date().setDate(new Date().getDate() - localSettings.rangePast),
                new Date().setDate(new Date().getDate() + localSettings.range),
                task.dueDate
              ) &&
              localSettings.salesPeople.includes(task.taskEmployeeId)
            ) {
              actions.push(new fromTasks.CreateTaskOnServerSuccess(task));
            }

            actions.push(
              new fromProspects.UpdateProspectOnServer({
                prospect: {
                  ...state.salesplannerLegacy.selectedActivity.prospect,
                  updatedDate: new Date(),
                },
                displayToast: false,
              })
            );
            actions.push(new fromProspectTasks.AddProspectTaskToState(task));
            return of(actions).pipe(mergeAll());
          })
        );
    },

    onError: (action: fromTasks.CreateTaskOnServer, error) => {
      console.error('Error', error);
    },
  }));

  
  updateTaskOnServer = createEffect(() => this.dataPersistence.fetch(fromTasks.UPDATE_TASK_ON_SERVER, {
    run: (action: fromTasks.UpdateTaskOnServer, state: any) => {
      const options = { params: { embed: 'taskOutcome,prospect,taskEmployeeAssociate' } };
      return this.apiService.put<Task>(`v/1/crm/tasks/${action.payload.task.id}`, action.payload.task, options).pipe(
        flatMap((task) => {
          let actions: Action[] = [];
          let toastOptions: any = {
            message: `Task updated.`,
            actionText: null,
            options: { duration: 3000, horizontalPosition: 'left' },
          };

          this.salesplannerStateService.addToast(toastOptions);

          actions.push(
            new fromSelectedActivity.UpdateSelectedActivityInState({
              id: task.id,
              changes: { ...task },
            })
          );
          actions.push(
            new fromProspects.UpdateProspectOnServer({
              prospect: {
                ...state.salesplannerLegacy.selectedActivity.prospect,
                updatedDate: new Date(),
              },
              displayToast: false,
            })
          );
          actions.push(new fromTasks.UpdateTaskOnServerSuccess({ id: task.id, changes: { ...task } }));
          actions.push(new fromProspectTasks.UpdateProspectTaskInState({ id: task.id, changes: { ...task } }));
          return of(actions).pipe(mergeAll());
        })
      );
    },

    onError: (action: fromTasks.UpdateTaskOnServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<TasksState>,
    private apiService: ApiService,
    private localSettingsStateService: LocalSettingsStateService,
    private salesplannerStateService: SalesPlannerStateService
  ) {}
}
