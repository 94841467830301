<div fxLayout="column">
  <div fxLayout="column" class="filters-container">
    <h2>
      Filters
    </h2>
    <span class="reset-button" (click)="filterForm.setValue(defaultFormModel)">Reset</span>
    <div class="filter-form-container">
      <form #filterFormRef fxLayout="column" [formGroup]="filterForm" (ngSubmit)="getReport(filterForm.value)"
        *ngIf="(dropdowns$ | async) as dropdowns">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <mat-form-field fxFlex="30">
            <mat-select placeholder="Rooftop" name="rooftop" formControlName="rooftopId">
              <mat-option *ngFor="let rooftop of dropdowns.rooftops" [value]="rooftop.id.toString()">
                {{ rooftop.displayCode }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30">
            <mat-select multiple placeholder="Salesperson" name="salespersonId" formControlName="salespersonId">
              <mat-option [value]="dropdowns.salespeople.employeeId.EmployeeId">
                {{ dropdowns.salespeople.employeeId.EmployeeName }}</mat-option>
              <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams">
                <label (click)="selectTeam(salesTeam, filterForm)">{{ salesTeam.teamName }}</label>
                <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                  {{ salesperson.EmployeeName }}</mat-option>
              </mat-optgroup>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30">
            <mat-select multiple placeholder="Sale Type" name="saleTypeId" formControlName="saleTypeId">
              <mat-option value="C">Cash</mat-option>
              <mat-option value="F">Finance</mat-option>
              <mat-option value="L">Lease</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="30">
            <mat-select placeholder="Actioned" name="actioned" formControlName="actioned">
              <mat-option value="1">Yes</mat-option>
              <mat-option value="0">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayoutAlign="center center" class="search-button-container">
          <button #searchButton type="submit" fxLayoutAlign="center center" class="mat-elevation-z2" fxLayout="row"
            fxLayoutAlign="start center">
            <mat-icon>search</mat-icon>
            <span class="search-button-text">Search</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div fxLayout="column" class="content-container">
    <div fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutAlign="center start">
        <h2>
          Results
        </h2>
        <span class="reset-button" (click)="getReport()">Clear</span>
      </div>
      <mat-form-field>
        <mat-select placeholder="Sort" name="sort" (selectionChange)="sortChanged(sort, filterForm)" [(ngModel)]="sort">
          <mat-option value="availableDate">Available Date ASC</mat-option>
          <mat-option value="-availableDate">Available Date DESC</mat-option>
          <mat-option value="equityValue">Equity Value ASC</mat-option>
          <mat-option value="-equityValue">Equity Value DESC</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="column" class="search-results-container" *ngIf="searchResult">
      <div *ngIf="!(isLoading$ | async)" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div *ngFor="let item of searchResult.data; trackBy: trackByFunction" fxLayout="column" fxFlex="49">
          <div fxLayoutAlign="space-between center" class="result-header-container">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-icon *ngIf="item.actioned">check_circle_outline</mat-icon>
              <h3>{{ item.customerFirstName + ' ' + item.customerLastName }}</h3>
              <span>- Available {{ item.availableDate | moment: 'MMM DD, YYYY' }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button *ngIf="item.actioned" mat-icon-button type="button" (click)="openProspect(item.prospectId)">
                <mat-icon color="primary">person</mat-icon>
              </button>
              <button *ngIf="!item.actioned" mat-icon-button type="button"
                (click)="openCreateProspectDialog('in equity', item, authenticatedEmployee)">
                <mat-icon color="primary">person_add</mat-icon>
              </button>
              <span class="result-header-divider">|</span>

              <button mat-button [color]="item.equityValue > 0 ? 'accent' : 'black'"
                (click)="openDetailsDialog(authenticatedEmployee, item, countryCode)">{{item.equityValue | currency}}</button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column">
            <div fxLayout="column">
              <div fxLayoutAlign="start center">
                <span
                  class="mat-body-2">{{ item.vehicleYear + ' ' + item.vehicleMake + ' ' + item.vehicleDescription + ' ' }}(<a
                    [ngStyle]="{
                      color: browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)' : '#000',
                      cursor: browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''
                    }" (click)="
                      browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', [
                        item.vehicleId.toString()
                      ])
                    " class="mat-body-1">ID: {{ item.vehicleId }}</a>)</span>
              </div>
              <div fxLayoutAlign="start center">
                <span class="primary-bullet">· </span><span>{{ item.term }}
                  {{ item.paymentFrequencyId }}
                  {{ item.saleTypeDescription }} Sold by
                  {{ item.originalSalespersonFirstName + ' ' + item.originalSalespersonLastName }} (<a [ngStyle]="{
                      color: browserControlCommunicationService.drilldownEnabled ? 'rgb(0,0,238)' : '#000',
                      cursor: browserControlCommunicationService.drilldownEnabled ? 'pointer' : ''
                    }" (click)="
                      browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_sales_maintain', [
                        item.saleId
                      ])
                    ">ID: {{ item.saleId }}</a>)</span>
              </div>
            </div>
            <div fxLayout="column">
              <div fxLayoutAlign="start center">
                <span class="primary-bullet">·
                </span><span>{{ item.remainingPayments ? item.remainingPayments : 0 }}
                  Remaining Payments of
                  {{ item.payment | currency }} @ {{ item.interestRate }}%</span>
              </div>

              <div fxLayoutAlign="start center">
                <span class="primary-bullet">·</span>
                <span>Current mileage is
                  {{ item.currentMileage + mileageLabel }}</span>
              </div>
              <div fxLayoutAlign="start center">
                <div fxLayoutAlign="start center">
                  <span class="primary-bullet">·</span>
                  <span>{{ item.remainingBalance | currency }} Remaining</span>
                </div>
                <div *ngIf="item.saleTypeId === 'L'" fxLayoutAlign="start center">
                  <span>, {{ item.residualValue | currency }} Residual</span>
                </div>
              </div>
              <div fxLayoutAlign="start center">
                <span class="primary-bullet">·
                </span><span>{{ item.tradeValue + item.addDeductsTotal | currency }} Trade
                  Value</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div fxLayoutAlign="center">
        <span class="mat-title" *ngIf="(isLoading$ | async)">Loading...</span>
      </div>
      <mat-paginator [length]="searchResult.headers?.total" [pageSize]="filterForm.value.pageSize"
        [pageIndex]="filterForm.value.page" (page)="pageChanged($event, filterForm)" [pageSizeOptions]="">
      </mat-paginator>
    </div>
  </div>
</div>