import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { Prospect } from '@quorum/models/xs-resource';
import {
  LocalSettingsState,
  LocalSettingsStateService,
  SelectedActivityStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'crm-prospect-list-item',
  templateUrl: './prospect-list-item.component.html',
  styleUrls: ['./prospect-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectListItemComponent implements OnInit {
  @Input() prospect: Prospect;
  enteredDate: number;
  updatedDate: number;
  selectedProspect$: Observable<Prospect>;
  localSettings: LocalSettingsState;
  constructor(
    public selectedActivityStateService: SelectedActivityStateService,
    private localSettingsStateService: LocalSettingsStateService,
    public bcs: BrowserControlCommunicationService
  ) {}

  ngOnInit() {
    this.localSettingsStateService.selectLocalSettingsFromState().subscribe((localSettings) => {
      this.localSettings = localSettings;
    });
    this.selectedProspect$ = this.selectedActivityStateService.selectSelectedProspect();
    this.updatedDate = new Date(this.prospect.updatedDate).getTime();
    this.enteredDate = new Date(this.prospect.enteredDate).getTime();
  }
}
