import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CommunicatorService } from '@applications/sha-services';
import { EventTypes } from '@models/server-common';
import { Actions, createEffect } from '@ngrx/effects';
import { Action as NgrxAction } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { MomentPipe } from '@quorum/common-pipes';
import { sendCommunicatorMessage } from '@quorum/communicator-ngrx';
import { ConversationQueryParameters } from '@quorum/models/xs-query';
import { Action, CrmAppointment as Appointment, Conversation, Event, Member, ProspectAction } from '@quorum/models/xs-resource';
import { LocalSettingsStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { of } from 'rxjs';
import { flatMap, map, mergeAll, switchMap, take } from 'rxjs/operators';
import * as fromProspects from '../../../../../../xsr-entities/src/lib/crm/prospects/prospects.actions';
import { SalesPlannerStateService } from '../../sales-planner.state.service';
import * as fromProspectActions from '../../selected-activity/prospect-actions/prospect-actions.actions';
// eslint-disable-next-line max-len
import * as fromProspectSalesAppointments from '../../selected-activity/prospect-sales-appointments/prospect-sales-appointments.actions';
import * as fromSelectedActivity from '../../selected-activity/selected-activity.actions';
import * as fromSalesAppointments from './sales-appointments.actions';
import { SalesAppointmentsState } from './sales-appointments.interfaces';

@Injectable()
export class SalesAppointmentsEffects {
  
  getSalesAppointmentsFromServer = createEffect(() => this.dataPersistence.fetch(
    fromSalesAppointments.GET_SALES_APPOINTMENTS_FROM_SERVER,
    {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (action: fromSalesAppointments.GetSalesAppointmentsFromServer, state: SalesAppointmentsState) => {
        return this.apiService
          .get<Appointment[]>(`v/1/crm/appointments`, {
            params: action.payload,
          })
          .pipe(
            flatMap((salesAppointments) => {
              let actions: any[] = [];

              actions.push(new fromSalesAppointments.ClearSalesAppointmentsFromState());

              actions.push(
                new fromSalesAppointments.GetSalesAppointmentsFromServerSuccess({
                  salesAppointments: salesAppointments,
                })
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSalesAppointments.GetSalesAppointmentsFromServer, error) => {
        console.error('Error', error);
      },
    }
  ));

  
  createSalesAppointmentOnServer = createEffect(() => this.dataPersistence.fetch(
    fromSalesAppointments.CREATE_SALES_APPOINTMENT_ON_SERVER,
    {
      run: (action: fromSalesAppointments.CreateSalesAppointmentOnServer, state: any) => {
        return this.apiService
          .post<Appointment>(`v/1/crm/appointments`, action.payload.appointment, {
            params: { embed: 'prospect,employeeAssociate,taskEmployeeAssociate' },
          })
          .pipe(
            switchMap((appointment: Appointment) => {
              let actions: NgrxAction[] = [];
              let localSettings: any;

              let toastOptions: any = {
                message: `Appointment created.`,
                actionText: null,
                options: { duration: 3000, horizontalPosition: 'left' },
              };

              this.salesplannerStateService.addToast(toastOptions);

              this.localSettingsStateService
                .selectLocalSettingsFromState()
                .subscribe((settings) => (localSettings = settings));

              if (
                localSettings.activitiesFilter.includes('A') &&
                new MomentPipe().isBetween(
                  new Date().setDate(new Date().getDate() - localSettings.rangePast),
                  new Date().setDate(new Date().getDate() + localSettings.range),
                  appointment.appointmentDate
                )
              ) {
                actions.push(new fromSalesAppointments.CreateSalesAppointmentOnServerSuccess(appointment));
              }

              let newProspectAction: ProspectAction = {
                ...new ProspectAction(),
                appointmentDateTime: appointment.appointmentDate,
                enteredDateTime: new Date(),
                taskActionId: Object.values<Action>(state.crmDropdowns.prospectActions.entities).find(
                  (prospectAction: Action) => prospectAction.description.toLowerCase() === 'appointment'
                ).id,
                associateEmployeeId: state.authentication.authenticatedUser.user.xselleratorEmployeeId,
                prospectId: state.salesplannerLegacy.selectedActivity.prospect.id,
              };

              actions.push(
                new fromProspectActions.CreateSelectedProspectActionOnServer({
                  prospectAction: newProspectAction,
                  prospect: state.salesplannerLegacy.selectedActivity.prospect,
                })
              );
              actions.push(
                new fromProspects.UpdateProspectOnServer({
                  prospect: {
                    ...state.salesplannerLegacy.selectedActivity.prospect,
                    updatedDate: new Date(),
                  },
                  displayToast: false,
                })
              );

              // if (action.payload.communicatorConfirmation === true && appointment.embedded.prospect.associateId) {
              //   this.systemControlStateService
              //     .getSystemControlValues(
              //       ['CL_CUSTOMER_USING_SOFTWARE'],
              //       state.salesplannerLegacy.selectedActivity.prospect.rooftopId
              //     )
              //     .pipe(take(1))
              //     .subscribe((systemControls) => {
              //       const xselleratorConversation: XselleratorConversation = new XselleratorConversation();
              //       xselleratorConversation.automatedMessageTypeId = 6; // Sales Appointment Confirmation
              //       xselleratorConversation.customerId = appointment.embedded.prospect.associateId.toString();
              // eslint-disable-next-line 
              //       xselleratorConversation.message = `You have booked a sales appointment at ${
              //         systemControls.CL_CUSTOMER_USING_SOFTWARE.displayValue
              //       } for ${new DatePipe(this.locale).transform(
              //         new Date(appointment.appointmentDate),
              //         'EEEE, LLLL d'
              //       )} at ${new DatePipe(this.locale).transform(
              //         new Date(appointment.appointmentDate),
              //         'shortTime'
              //       )} with ${appointment.embedded.employeeAssociate.firstName} ${
              //         appointment.embedded.employeeAssociate.lastName
              //       }. Thank-You!`;
              //       xselleratorConversation.oemRooftopId = state.authentication.employee.rooftopId;
              //       xselleratorConversation.senderId = appointment.employeeId;
              // eslint-disable-next-line 
              //       xselleratorConversation.transactionId = state.salesplannerLegacy.selectedActivity.prospect.id.toString();
              //       xselleratorConversation.transactionTypeId = 5; // Customer Prospect

              //       this.apiService
              //         .post<any>(`v/1/communicator/conversations/xsellerator/transactions`, xselleratorConversation)
              //         .subscribe((result) => {
              //           // do something?
              //         });
              //     });
              // }

              if (action.payload.communicatorConfirmation === true && appointment.embedded.prospect.associateId) {
                this.systemControlStateService
                  .getSystemControlValues(
                    ['CL_CUSTOMER_USING_SOFTWARE'],
                    state.salesplannerLegacy.selectedActivity.prospect.rooftopId
                  )
                  .pipe(
                    take(1),
                    map((systemControl) => {
                      let authenticatedUser = state.authentication.authenticatedUser.user;
                      let prospect = state.salesplannerLegacy.selectedActivity.prospect;

                      let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                        transactionTypeId: 5,
                        messageTypeId: 6,
                        transactionId: prospect.id,
                      });

                      let event: Event = new Event({
                        conversationId: null,
                        memberId: authenticatedUser.id.toString(),
                        eventTypeId: EventTypes.Message,
                        content: `You have booked a sales appointment at ${
                          systemControl.CL_CUSTOMER_USING_SOFTWARE.displayValue
                        } for ${new DatePipe(this.locale).transform(
                          new Date(appointment.appointmentDate),
                          'EEEE, LLLL d'
                        )} at ${new DatePipe(this.locale).transform(
                          new Date(appointment.appointmentDate),
                          'shortTime'
                        )} with ${appointment.embedded.employeeAssociate.firstName} ${
                          appointment.embedded.employeeAssociate.lastName
                        }. Thank-You!`,
                        guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                        sentDate: new Date(),
                        sent: false,
                        sendToCustomer: true,
                        id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
                      });

                      let conversation: Conversation = new Conversation({
                        dmsMessageTypeId: 6,
                        dmsTransactionTypeId: 5,
                        dmsTransactionId: state.salesplannerLegacy.selectedActivity.prospect.id,
                        createdBy: authenticatedUser.id,
                      });

                      let members: Member[] = [];
                      members.push(
                        new Member({
                          userId: authenticatedUser.id.toString(),
                          avatarUrl: authenticatedUser.avatarUrl,
                          firstName: authenticatedUser.firstName,
                          lastName: authenticatedUser.lastName,
                          nickname: authenticatedUser.firstName,
                          isEmployee: true,
                          isRead: true,
                          isArchived: false,
                          isExcluded: false,
                          isPinnedToUi: false,
                        })
                      );

                      members.push(
                        new Member({
                          userId: prospect.embedded.associate.id,
                          firstName: prospect.embedded.associate.firstName,
                          lastName: prospect.embedded.associate.lastName,
                          nickname: prospect.embedded.associate.firstName,
                          isEmployee: false,
                          isRead: true,
                          isArchived: false,
                          isExcluded: false,
                          isPinnedToUi: false,
                        })
                      );

                      let optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'true' });

                      actions.push(
                        sendCommunicatorMessage({
                          conversationQueryParameters: conversationQueryParameters,
                          conversation: conversation,
                          members: members,
                          event: event,
                          optionalHeader: optionalHeader,
                        })
                      );
                    }),
                    take(1)
                  )
                  .subscribe();
              }

              actions.push(new fromProspectSalesAppointments.AddProspectSalesAppointmentToState(appointment));
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSalesAppointments.CreateSalesAppointmentOnServer, error) => {
        console.error('Error', error);
      },
    }
  ));

  
  updateAppointmentOnServer = createEffect(() => this.dataPersistence.fetch(fromSalesAppointments.UPDATE_SALES_APPOINTMENT_ON_SERVER, {
    run: (action: fromSalesAppointments.UpdateSalesAppointmentOnServer, state: any) => {
      return this.apiService
        .put<Appointment>(`v/1/crm/appointments/${action.payload.newAppointment.id}`, action.payload.newAppointment, {
          params: {
            embed: 'prospect,taskEmployeeAssociate',
          },
        })
        .pipe(
          switchMap((appointment) => {
            let actions: NgrxAction[] = [];

            let toastOptions: any = {
              message: `Appointment updated.`,
              actionText: null,
              options: { duration: 3000, horizontalPosition: 'left' },
            };

            this.salesplannerStateService.addToast(toastOptions);

            // if (action.payload.oldAppointment.appointmentDate != action.payload.newAppointment.appointmentDate) {
            //   this.systemControlStateService
            //     .getSystemControlValues(
            //       ['CL_CUSTOMER_USING_SOFTWARE', 'CL_COMMUNICATOR_ENABLE', 'CL_COMMUNICATOR_SALES_APPT_CONFIRMATION'],
            //       state.salesplannerLegacy.selectedActivity.prospect.rooftopId
            //     )
            //     .pipe(take(1))
            //     .subscribe((systemControls) => {
            //       const xselleratorConversation: XselleratorConversation = new XselleratorConversation();
            //       xselleratorConversation.automatedMessageTypeId = 6; // Sales Appointment Confirmation
            // eslint-disable-next-line 
            //       xselleratorConversation.customerId = action.payload.newAppointment.embedded.prospect.associateId.toString();
            // eslint-disable-next-line
            //       xselleratorConversation.message = `You have booked a sales appointment at ${
            //         systemControls.CL_CUSTOMER_USING_SOFTWARE.displayValue
            //       } for ${new DatePipe(this.locale).transform(
            //         new Date(appointment.appointmentDate),
            //         'EEEE, LLLL d'
            //       )} at ${new DatePipe(this.locale).transform(
            //         new Date(appointment.appointmentDate),
            //         'shortTime'
            //       )} with ${appointment.embedded.taskEmployeeAssociate.firstName} ${
            //         appointment.embedded.taskEmployeeAssociate.lastName
            //       }. Thank-You!`;
            //       xselleratorConversation.oemRooftopId = state.authentication.employee.rooftopId;
            //       xselleratorConversation.senderId = state.authentication.authenticatedUser.user.xselleratorEmployeeId;
            // eslint-disable-next-line max-len
            //       xselleratorConversation.transactionId = state.salesplannerLegacy.selectedActivity.prospect.id.toString();
            //       xselleratorConversation.transactionTypeId = 5; // Customer Prospect

            //       if (
            //         systemControls.CL_COMMUNICATOR_ENABLE.value === '1' &&
            //         systemControls.CL_COMMUNICATOR_SALES_APPT_CONFIRMATION.value === '1'
            //       ) {
            //         this.apiService
            //           .post<any>(`v/1/communicator/conversations/xsellerator/transactions`, xselleratorConversation)
            //           .subscribe((result) => {
            //             // do something?
            //           });
            //       }
            //     });
            // }

            if (action.payload.oldAppointment.appointmentDate != action.payload.newAppointment.appointmentDate) {
              this.systemControlStateService
                .getSystemControlValues(
                  ['CL_CUSTOMER_USING_SOFTWARE', 'CL_COMMUNICATOR_ENABLE', 'CL_COMMUNICATOR_SALES_APPT_CONFIRMATION'],
                  state.salesplannerLegacy.selectedActivity.prospect.rooftopId
                )
                .pipe(
                  take(1),
                  map((systemControls) => {
                    let authenticatedUser = state.authentication.authenticatedUser.user;
                    let prospect = state.salesplannerLegacy.selectedActivity.prospect;

                    if (
                      systemControls.CL_COMMUNICATOR_ENABLE.value === '1' &&
                      systemControls.CL_COMMUNICATOR_SALES_APPT_CONFIRMATION.value === '1'
                    ) {
                      let conversationQueryParameters: ConversationQueryParameters = new ConversationQueryParameters({
                        transactionTypeId: 5,
                        messageTypeId: 6,
                        transactionId: prospect.id,
                      });

                      let conversation: Conversation = new Conversation({
                        dmsMessageTypeId: 6,
                        dmsTransactionTypeId: 5,
                        dmsTransactionId: prospect.id,
                        createdBy: authenticatedUser.id,
                      });

                      let members: Member[] = [];
                      members.push(
                        new Member({
                          userId: authenticatedUser.id.toString(),
                          avatarUrl: authenticatedUser.avatarUrl,
                          firstName: authenticatedUser.firstName,
                          lastName: authenticatedUser.lastName,
                          nickname: authenticatedUser.firstName,
                          isEmployee: true,
                          isRead: true,
                          isArchived: false,
                          isExcluded: false,
                          isPinnedToUi: false,
                        })
                      );

                      members.push(
                        new Member({
                          userId: prospect.embedded.associate.id,
                          firstName: prospect.embedded.associate.firstName,
                          lastName: prospect.embedded.associate.lastName,
                          nickname: prospect.embedded.associate.firstName,
                          isEmployee: false,
                          isRead: true,
                          isArchived: false,
                          isExcluded: false,
                          isPinnedToUi: false,
                        })
                      );

                      let event: Event = new Event({
                        conversationId: null,
                        memberId: authenticatedUser.id.toString(),
                        eventTypeId: EventTypes.Message,
                        content: `You have booked a sales appointment at ${
                          systemControls.CL_CUSTOMER_USING_SOFTWARE.displayValue
                        } for ${new DatePipe(this.locale).transform(
                          new Date(appointment.appointmentDate),
                          'EEEE, LLLL d'
                        )} at ${new DatePipe(this.locale).transform(
                          new Date(appointment.appointmentDate),
                          'shortTime'
                        )} with ${appointment.embedded.taskEmployeeAssociate.firstName} ${
                          appointment.embedded.taskEmployeeAssociate.lastName
                        }. Thank-You!`,
                        guid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
                        sentDate: new Date(),
                        sent: false,
                        sendToCustomer: true,
                        id: Math.floor(Math.random() * (9999999 - 999999)) + 999999,
                      });

                      const optionalHeader = new HttpHeaders({ 'Use-Default-Template': 'true' });

                      actions.push(
                        sendCommunicatorMessage({
                          conversationQueryParameters: conversationQueryParameters,
                          conversation: conversation,
                          members: members,
                          event: event,
                          optionalHeader: optionalHeader,
                        })
                      );
                    }
                  }),
                  take(1)
                )
                .subscribe();
            }
            actions.push(
              new fromProspects.UpdateProspectOnServer({
                prospect: {
                  ...state.salesplannerLegacy.selectedActivity.prospect,
                  updatedDate: new Date(),
                },
                displayToast: false,
              })
            );
            actions.push(
              new fromSalesAppointments.UpdateSalesAppointmentOnServerSuccess({
                id: appointment.id,
                changes: { ...appointment },
              })
            );
            actions.push(
              new fromSelectedActivity.UpdateSelectedActivityInState({
                id: appointment.id,
                changes: { ...appointment },
              })
            );
            actions.push(
              new fromSelectedActivity.UpdateSelectedProspectSalesAppointmentInState({
                id: appointment.id,
                changes: { ...appointment },
              })
            );
            return of(actions).pipe(mergeAll());
          })
        );
    },

    onError: (action: fromSalesAppointments.UpdateSalesAppointmentOnServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<SalesAppointmentsState>,
    private apiService: ApiService,
    private localSettingsStateService: LocalSettingsStateService,
    private salesplannerStateService: SalesPlannerStateService,
    private systemControlStateService: SystemControlStateService,
    private authenticationStateService: AuthenticationStateService,
    private communicatorService: CommunicatorService,
    @Inject(LOCALE_ID) private locale: string
  ) {}
}
