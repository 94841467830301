import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticatedUser, Note } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-prospect-notes-dialog',
  templateUrl: './prospect-notes-dialog.component.html',
  styleUrls: ['./prospect-notes-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectNotesDialogComponent implements OnInit {
  noteForm = this.fb.group({
    note: ['', [Validators.required, Validators.pattern(/[\S]/)]],
  });

  constructor(
    public dialogRef: MatDialogRef<ProspectNotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { prospectId: number; currentUser: AuthenticatedUser; notes: Note },
    private fb: FormBuilder,
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.renderer.selectRootElement('#note').focus();
    }, 10);
  }

  onNoClick() {
    this.dialogRef.close();
  }

  onAddClick(note: string, prospectId: number, currentUser: AuthenticatedUser, notes: Note): void {
    if (!this.noteForm.invalid) {
      let currentDate = new Date();
      let newNote: Note;
      let formattedCurrentDateTime = `${[
        currentDate.getMonth() + 1,
        currentDate.getDate(),
        currentDate.getFullYear(),
      ].join('/')} ${[currentDate.getHours(), ('0' + currentDate.getMinutes()).slice(-2)].join(':')}`;
      if (notes) {
        newNote = {
          ...notes,
          notes: `${currentUser.user.firstName} ${currentUser.user.lastName} ${formattedCurrentDateTime} ${note}\r\n${notes.notes}`,
        };
      } else {
        newNote = {
          ...new Note(),
          functionalAreaId: 'CP',
          notes: `${currentUser.user.firstName} ${currentUser.user.lastName} ${formattedCurrentDateTime} ${note}`,
          sourceReference1Id: prospectId.toString(),
          sourceReference2Id: 'N',
        };
      }
      this.dialogRef.close(newNote);
    }
  }
}
