export class PurchaseOrderHeaderQueryParameters {
  deliveryDateFrom?: Date;
  deliveryDateTo?: Date;
  employeeId?: string;
  enteredDateFrom?: Date;
  enteredDateTo?: Date;
  id?: string;
  rooftopId?: string;
  statusId?: number;
  supplierId?: string;
  typeId?: number;
  vehicleId?: string;

  constructor(obj?: PurchaseOrderHeaderQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
