<div class="shell-container">
  <button class="fab" mat-fab color="accent" [@preventInitialAnimation] [satPopoverAnchorFor]="dial"
    (click)="dial.toggle()">
    <mat-icon [@spinInOut]="'in'" *ngIf="dial.isOpen()">close</mat-icon>
    <mat-icon [@spinInOut]="'in'" *ngIf="!dial.isOpen()">menu</mat-icon>
  </button>

  <sat-popover #dial verticalAlign="above">
    <div class="dial" *ngIf="(dropdowns$ | async) as dropdowns">
      <ng-container *ngIf="(selectedProspect$ | async) as selectedProspect; else noSelectedProspect">
        <ng-container *ngIf="!selectedProspect.associateId && selectedProspect.vendorId; else notAnUnmatchedLead">
          <ng-container *ngIf="selectedProspect.statusId != 2">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="taskTooltip"
                (mouseenter)="taskTooltip.open()" (mouseleave)="taskTooltip.close()" (click)="
                          openCreateTaskDialog(authenticatedUser, dialog, dropdowns, selectedProspect, activitiesStateService);
                          dial.close()
                        ">
                <mat-icon>create</mat-icon>
              </button>
              <sat-popover #taskTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  New Task
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="
                          openCreateTaskDialog(authenticatedUser, dialog, dropdowns, selectedProspect, activitiesStateService);
                          dial.close()
                        ">
                <mat-icon>create</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="
          drilldownEnabled &&
          selectedProspect.statusId != 2 &&
          (selectedProspect.primaryEmployeeId === authenticatedUser.user.xselleratorEmployeeId ||
            teamLead ||
            settings.salesPlannerView === 1)
        ">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="applyTemplateTooltip"
                (mouseenter)="applyTemplateTooltip.open()" (mouseleave)="applyTemplateTooltip.close()"
                (click)="openProspectTemplate(selectedProspect); dial.close()">
                <mat-icon>assignment</mat-icon>
              </button>
              <sat-popover #applyTemplateTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Apply Template
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="openProspectTemplate(selectedProspect); dial.close()">
                <mat-icon>assignment</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container>
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="prospectTooltip"
                (mouseenter)="prospectTooltip.open()" (mouseleave)="prospectTooltip.close()" (click)="
                      openCreateProspectDialog(authenticatedUser, dialog, dropdowns, authenticatedEmployee, opportunityActionId); dial.close()
                    ">
                <mat-icon>person_add</mat-icon>
              </button>
              <sat-popover #prospectTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  New Prospect
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="
                      openCreateProspectDialog(authenticatedUser, dialog, dropdowns, authenticatedEmployee, opportunityActionId); dial.close()
                    ">
                <mat-icon>person_add</mat-icon>
              </button>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #notAnUnmatchedLead>
          <ng-container *ngIf="selectedProspect.typeId == 3 && selectedProspect.statusId != 2">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="vehicleSearchTooltip"
                (mouseenter)="vehicleSearchTooltip.open()" (mouseleave)="vehicleSearchTooltip.close()"
                (click)="openVehicleSearch(selectedProspect.id, route); dial.close()">
                <mat-icon>search</mat-icon>
              </button>
              <sat-popover #vehicleSearchTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Vehicle Search
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab"
                (click)="openVehicleSearch(selectedProspect.id, route); dial.close()">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="
          selectedProspect.typeId == 4 &&
          selectedProspect.primaryEmployeeId == authenticatedUser?.user.xselleratorEmployeeId
        ">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="vehicleSearchTooltip"
                (mouseenter)="vehicleSearchTooltip.open()" (mouseleave)="vehicleSearchTooltip.close()"
                (click)="openStandaloneVehicleSearch(selectedProspect.id); dial.close()">
                <mat-icon>search</mat-icon>
              </button>
              <sat-popover #vehicleSearchTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Vehicle Search
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab"
                (click)="openStandaloneVehicleSearch(selectedProspect.id); dial.close()">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </ng-container>

          <ng-container *ngIf="
          selectedProspect.typeId === 3 &&
          selectedProspect.statusId !== '2' &&
          autovanceEnabled
          ">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="deskTooltip"
                (mouseenter)="deskTooltip.open()" (mouseleave)="deskTooltip.close()"
                (click)="createDeskedDeal(selectedProspect, avDealCreateRooftop, dropdowns); dial.close()">
                <img matListAvatar class="autovance-logo" src="assets/autovance-emblem.png" alt="...">
              </button>
              <sat-popover #deskTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Desk
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab"
                (click)="createDeskedDeal(selectedProspect, avDealCreateRooftop, dropdowns); dial.close()">
                <img matListAvatar class="autovance-logo" src="assets/autovance-emblem.png" alt="...">
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProspect.typeId == 3 && drilldownEnabled && selectedProspect.statusId != 2">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="vehicleSaleTooltip"
                (mouseenter)="vehicleSaleTooltip.open()" (mouseleave)="vehicleSaleTooltip.close()"
                (click)="createVehicleSale(selectedProspect, drilldownEnabled); dial.close()">
                <mat-icon>attach_money</mat-icon>
              </button>
              <sat-popover #vehicleSaleTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Create Vehicle Sale
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab"
                (click)="createVehicleSale(selectedProspect, drilldownEnabled); dial.close()">
                <mat-icon>attach_money</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProspect.typeId == 3 && selectedProspect.statusId != 2">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="appointmentTooltip"
                (mouseenter)="appointmentTooltip.open()" (mouseleave)="appointmentTooltip.close()" (click)="
                openCreateAppointmentDialog(
                  authenticatedUser,
                  dialog,
                  dropdowns,
                  selectedProspect,
                  activitiesStateService
                );
                dial.close()
              ">
                <mat-icon>today</mat-icon>
              </button>
              <sat-popover #appointmentTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  New Appointment
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="
                openCreateAppointmentDialog(
                  authenticatedUser,
                  dialog,
                  dropdowns,
                  selectedProspect,
                  activitiesStateService
                );
                dial.close()
              ">
                <mat-icon>today</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedProspect.statusId != 2">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="taskTooltip"
                (mouseenter)="taskTooltip.open()" (mouseleave)="taskTooltip.close()" (click)="
                openCreateTaskDialog(authenticatedUser, dialog, dropdowns, selectedProspect, activitiesStateService);
                dial.close()
              ">
                <mat-icon>create</mat-icon>
              </button>
              <sat-popover #taskTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  New Task
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="
                openCreateTaskDialog(authenticatedUser, dialog, dropdowns, selectedProspect, activitiesStateService);
                dial.close()
              ">
                <mat-icon>create</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="
          drilldownEnabled &&
          selectedProspect.statusId != 2 &&
          (selectedProspect.primaryEmployeeId === authenticatedUser.user.xselleratorEmployeeId ||
            teamLead ||
            settings.salesPlannerView === 1)
        ">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="applyTemplateTooltip"
                (mouseenter)="applyTemplateTooltip.open()" (mouseleave)="applyTemplateTooltip.close()"
                (click)="openProspectTemplate(selectedProspect); dial.close()">
                <mat-icon>assignment</mat-icon>
              </button>
              <sat-popover #applyTemplateTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Apply Template
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="openProspectTemplate(selectedProspect); dial.close()">
                <mat-icon>assignment</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="
          drilldownEnabled &&
          (selectedProspect.primaryEmployeeId === authenticatedUser.user.xselleratorEmployeeId ||
            teamLead ||
            settings.salesPlannerView === 1)
        ">
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="dealerPromoTooltip"
                (mouseenter)="dealerPromoTooltip.open()" (mouseleave)="dealerPromoTooltip.close()"
                (click)="openDealerPromo(localSettings); dial.close()">
                <mat-icon>add</mat-icon>
              </button>
              <sat-popover #dealerPromoTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  Create Dealer Promo
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="openDealerPromo(localSettings); dial.close()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </ng-container>
          <ng-container>
            <div fxHide fxShow.gt-sm="true">
              <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="prospectTooltip"
                (mouseenter)="prospectTooltip.open()" (mouseleave)="prospectTooltip.close()" (click)="
                openCreateProspectDialog(authenticatedUser, dialog, dropdowns, authenticatedEmployee, opportunityActionId); dial.close()
              ">
                <mat-icon>person_add</mat-icon>
              </button>
              <sat-popover #prospectTooltip horizontalAlign="before">
                <div class="tooltip mat-body-1">
                  New Prospect
                </div>
              </sat-popover>
            </div>
            <div fxHide fxShow.lt-md="true">
              <button mat-mini-fab class="white-fab" (click)="
                openCreateProspectDialog(authenticatedUser, dialog, dropdowns, authenticatedEmployee, opportunityActionId); dial.close()
              ">
                <mat-icon>person_add</mat-icon>
              </button>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #noSelectedProspect>
        <ng-container>
          <div fxHide fxShow.gt-sm="true">
            <button mat-mini-fab class="white-fab" [satPopoverAnchorFor]="prospectTooltip"
              (mouseenter)="prospectTooltip.open()" (mouseleave)="prospectTooltip.close()" (click)="
                  openCreateProspectDialog(authenticatedUser, dialog, dropdowns, authenticatedEmployee, opportunityActionId); dial.close()
                ">
              <mat-icon>person_add</mat-icon>
            </button>
            <sat-popover #prospectTooltip horizontalAlign="before">
              <div class="tooltip mat-body-1">
                New Prospect
              </div>
            </sat-popover>
          </div>
          <div fxHide fxShow.lt-md="true">
            <button mat-mini-fab class="white-fab" (click)="
                  openCreateProspectDialog(authenticatedUser, dialog, dropdowns, authenticatedEmployee, opportunityActionId); dial.close()
                ">
              <mat-icon>person_add</mat-icon>
            </button>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </sat-popover>
  <div fxFlex="33" fxFlex.xs="100" [fxHide.xs]="view === 'detail'" class="master-container">
    <router-outlet name="master"></router-outlet>
  </div>
  <div fxFlex="67" fxFlex.xs="100" [fxHide.xs]="view === 'master'">
    <router-outlet></router-outlet>
  </div>
</div>