import { Injectable } from '@angular/core';
import { WorkOrder } from '@quorum/models/xs-resource';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromSelectedVehicleServiceHistory from './service-history.actions';
import { ServiceHistoryState } from './service-history.interfaces';
import { from } from 'rxjs';
import { of } from 'rxjs';
import { mergeAll, flatMap } from 'rxjs/operators';

@Injectable()
export class SelectedVehicleServiceHistoryEffects {
  
  getVehicleServiceHistory = createEffect(() => this.dataPersistence.fetch(
    fromSelectedVehicleServiceHistory.GET_VEHICLE_SERVICE_HISTORY_FROM_SERVER,
    {
      id: (a: any, state: any) => {
        return 1;
      },
      run: (
        action: fromSelectedVehicleServiceHistory.GetVehicleServiceHistoryFromServer,
        state: ServiceHistoryState
      ) => {
        return this.apiService
          .get<WorkOrder[]>(`v/1/fixed-operations/work-orders`, {
            params: action.payload
          })
          .pipe(
            flatMap(vehicleServiceHistory => {
              let actions: any[] = [];
              actions.push(new fromSelectedVehicleServiceHistory.ClearVehicleServiceHistoryFromState());
              actions.push(
                new fromSelectedVehicleServiceHistory.GetVehicleServiceHistoryFromServerSuccess({
                  vehicleServiceHistory: vehicleServiceHistory
                })
              );
              return of(actions).pipe(mergeAll());
            })
          );
      },
      onError: (action: fromSelectedVehicleServiceHistory.GetVehicleServiceHistoryFromServer, error) => {
        console.error('Error', error);
      }
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<ServiceHistoryState>,
    private apiService: ApiService
  ) {}
}
