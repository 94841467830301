import { Sale } from '@quorum/models/xs-resource';
// eslint-disable-next-line max-len
import { SaleQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const GET_DELIVERIES_FROM_SERVER = '[CRM] Get Deliveries From Server';
export class GetDeliveriesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_DELIVERIES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SaleQueryParameters) {}
}

export const GET_DELIVERIES_FROM_SERVER_SUCCESS = '[CRM] Get Deliveries From Server Success';
export class GetDeliveriesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_DELIVERIES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { deliveries: Sale[] }) {}
}

export const GET_DELIVERIES_FROM_SERVER_FAILURE = '[CRM] Get Get Deliveries From Server Failure';
export class GetDeliveriesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_DELIVERIES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CLEAR_DELIVERIES_FROM_STATE = '[CRM] Clear Deliveries From State';
export class ClearDeliveriesFromState implements Action {
  readonly feature = 'crm';
  readonly type = CLEAR_DELIVERIES_FROM_STATE;
  readonly triggerStorageSync = false;
  constructor() {}
}

export const UPDATE_DELIVERY_ON_SERVER = '[CRM] Update Delivery On Server';
export class UpdateDeliveryOnServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_DELIVERY_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { delivery: Sale, queryParameters: SaleQueryParameters }) {}
}

export const UPDATE_DELIVERY_ON_SERVER_SUCCESS = '[CRM] Update Delivery On Server Success';
export class UpdateDeliveryOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_DELIVERY_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: Update<Sale>) {}
}

export const UPDATE_DELIVERY_ON_SERVER_FAILURE = '[CRM] Update Delivery On Failure';
export class UpdateDeliveryOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_DELIVERY_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type DeliveriesAction =
  | GetDeliveriesFromServer
  | GetDeliveriesFromServerSuccess
  | GetDeliveriesFromServerFailure
  | ClearDeliveriesFromState
  | UpdateDeliveryOnServer
  | UpdateDeliveryOnServerSuccess
  | UpdateDeliveryOnServerFailure;
