import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MessageType, Vehicle } from '@quorum/models/xs-resource';
import { Actions, ofType } from '@ngrx/effects';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { AuthenticatedUser } from '@quorum/authentication/state';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { AddDeductDialogComponent } from '@quorum/crm-equity-mining-ng-ui';
import { ProspectCreateDialogComponent, ProspectCreateProgressDialogComponent } from '@quorum/crm-prospects-ng-ui';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { AssociateQueryParameters } from '@quorum/models/xs-query';
import {
  Action,
  Associate,
  AssociateReport,
  Channel,
  Employee,
  Prospect,
  ProspectAction,
  ProvinceState,
  SalesTeamMaintenance,
  Source,
} from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import {
  LocalSettingsState,
  LocalSettingsStateService,
  SalesPlannerStateService,
  SelectedActivityStateService,
  UiStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { BrowserControlCommunicationService } from 'libs/sha-services/src/lib/browserControlCommunication.service';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay, take, tap } from 'rxjs/operators';
import * as CommunicatorActions from '../../../../communicator-ngrx/src/lib/+state/communicator.actions';
import { VehicleProfileDialogComponent } from '../vehicle-profile-dialog/vehicle-profile-dialog.component';
@Component({
  selector: 'crm-sales-planner-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailComponent implements OnInit, OnDestroy {
  authenticatedEmployee: Employee;
  currentUser$: Observable<any>;
  prospect$: Observable<Prospect>;
  prospect: Prospect;
  prospectAggregate$: Observable<any>;
  vehicleAggregate$: Observable<any>;
  vehicleServiceHistory$: Observable<any>;
  prospectNotes$: Observable<any>;
  selectedProspectTasks$: Observable<any>;
  dropdowns$: Observable<any>;
  crmDropdowns$: Observable<any>;
  prospectActions$: Observable<any>;
  selectedProspectActions$: Observable<any>;
  responseLeads$: Observable<any>;
  prospectVehicleOfInterest$: Observable<any>;
  prospectVehicles$: Observable<any>;
  prospectOpenSales$: Observable<any>;
  componentData$: Observable<any>;
  selectedProspectOpenActivities$: Observable<any>;
  selectedProspectClosedActivities$: Observable<any>;
  activityPurchaseOrderDetails$: Observable<any>;
  selectedVehicle: Vehicle;
  route$: BehaviorSubject<any>;
  openActivitiesPanel: boolean;
  openActivitiesPanelStatus$: Subscription;
  closedActivitiesPanel: boolean;
  closedActivitiesPanelStatus$: Subscription;
  purchaseOrderDetailsPanel: boolean;
  purchaseOrderDetailsPanelStatus$: Subscription;
  responseLeadsPanel: boolean;
  responseLeadsPanelStatus$: Subscription;
  vehicleOfInterestPanel: boolean;
  vehicleOfInterestPanelStatus$: Subscription;
  openSalesPanel: boolean;
  openSalesPanelStatus$: Subscription;
  actionsPanelStatus$: Subscription;
  actionsPanel: boolean;
  notesPanel: boolean;
  notesPanelStatus$: Subscription;
  serviceHistoryPanel: boolean;
  serviceHistoryPanelStatus$: Subscription;
  selectedActivityType: string;
  selectedActivity: any;
  selectedTask: any;
  selectedAppointment: any;
  selectedService: any;
  selectedDelivery: any;
  authenticatedUser$: Subscription;
  authenticatedUser: AuthenticatedUser;
  selectedTabIndex: number;
  drillDownEnabled: boolean;
  vehicleTabEnabled: boolean;
  loadDetail$: Observable<any>;
  isProspectLoading: boolean = true;
  vehicleSelectionForm: FormGroup = this.fb.group({
    vehicleId: [],
  });
  activity$: Observable<any>;
  leadGeneratorSct$: Observable<string>;
  mileageLabel: string;
  countryCode: string;
  localSettings$: Observable<LocalSettingsState>;
  combinedObs$: Observable<any>;
  selectedVehicleSalespersonName: string;
  selectedVehicleRemainingPayments: number;
  selectedVehicleConversionRemainingBalance: number;
  estimatedConversion: boolean = false;
  isVehicleAggregateLoading$: Observable<boolean>;
  leadGeneratorActionId: number;
  opportunityActionId: number;
  postProspectProcessSubscription: Subscription;
  createProspectProgressRef: MatDialogRef<any>;
  selectedActivity$: Observable<any>;
  constructor(
    private selectedActivityStateService: SelectedActivityStateService,
    private dropdownsStateService: DropdownsStateService,
    private routerStateService: RouterStateService,
    private uiStateService: UiStateService,
    private salesPlannerStateService: SalesPlannerStateService,
    private authenticationStateService: AuthenticationStateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private crmEntityStateService: CrmEntityStateService,
    private router: Router,
    private fb: FormBuilder,
    private systemControlStateService: SystemControlStateService,
    private localSettingsStateService: LocalSettingsStateService,
    private settingsStateService: CrmSettingsStateService,
    private apiService: ApiService,
    private actions$: Actions
  ) {}

  ngOnInit() {
    console.log('Detail init!');
    this.selectedActivity$ = this.selectedActivityStateService.selectSelectedActivity().pipe(shareReplay(1));
    this.drillDownEnabled = this.browserControlCommunicationService.drilldownEnabled;
    this.selectedActivityStateService.selectSelectedActivityType().subscribe((type: string) => {
      this.selectedActivityType = type;
    });

    let activityType: string;
    if (this.router.url.indexOf('tasks') > -1) activityType = 'Task';
    if (this.router.url.indexOf('appointments') > -1) activityType = 'Appointment';
    if (this.router.url.indexOf('service-appointments') > -1) activityType = 'Service';
    if (this.router.url.indexOf('dealer-promotions') > -1) activityType = 'Promotion';
    if (this.router.url.indexOf('deliveries') > -1) activityType = 'Delivery';
    if (this.router.url.indexOf('prospects') > -1) activityType = 'Prospect';
    this.route$ = new BehaviorSubject(activityType);

    this.dropdownsStateService.getVehicleSubcategoriesFromServer();
    this.dropdownsStateService.getVehicleColoursFromServer();
    this.dropdownsStateService.getVehicleSubTypesFromServer();
    this.dropdownsStateService.getVehicleStatusesFromServer();

    this.selectedProspectOpenActivities$ = combineLatest(
      this.selectedActivityStateService.selectSelectedProspectActivities(),
      this.localSettingsStateService.selectLocalSettingsFromState()
    ).pipe(
      map(([openActivities, localSettings]) => {
        return openActivities.filter(
          (openActivity: any) =>
            openActivity.open == true && localSettings.salesPeople.includes(openActivity.activityEmployeeAssociateId)
        );
      }),
      shareReplay(1)
    );

    this.selectedProspectClosedActivities$ = this.selectedActivityStateService.selectSelectedProspectActivities().pipe(
      map((closedActivities: any) =>
        closedActivities.filter((closedActivity: any) => {
          return (
            closedActivity.open === false &&
            closedActivity.activityOutcome.toLowerCase() !== 'auto closed' &&
            closedActivity.activityOutcome.toLowerCase() !== '' &&
            closedActivity.activityOutcome.toLowerCase() !== null
          );
        })
      ),
      shareReplay(1)
    );

    this.activityPurchaseOrderDetails$ = this.selectedActivityStateService
      .selectSelectedActivityPurchaseOrderDetails()
      .pipe(shareReplay(1));

    this.prospect$ = combineLatest(
      this.selectedActivityStateService.selectSelectedProspect().pipe(filter((prospect) => prospect != null)),
      this.selectedActivityStateService.selectSelectedProspectVehicles()
    ).pipe(
      tap(([prospect, prospectVehicles]) => {
        if (prospect.associateId) {
          this.vehicleTabEnabled = true;
        } else {
          this.vehicleTabEnabled = false;
        }
      }),
      map(([prospect, prospectVehicles]) => {
        if (prospectVehicles && prospectVehicles.length > 0) {
          if (prospect.vehicleId && prospect.vehicleId !== null) {
            this.selectedVehicle = prospectVehicles.find((v: Vehicle) => v.id == prospect.vehicleId);
            this.vehicleSelectionForm.patchValue({ vehicleId: this.selectedVehicle ? this.selectedVehicle : null });
            this.selectVehicle(this.selectedVehicle, prospectVehicles, prospect);
          } else {
            this.vehicleSelectionForm.patchValue({ vehicleId: null });
          }
        } else {
          this.vehicleSelectionForm.patchValue({ vehicleId: null });
        }
        return prospect;
      }),
      shareReplay(1)
    );

    this.activity$ = combineLatest(
      this.selectedActivityStateService.selectSelectedActivity().pipe(filter((activity) => activity != null)),
      this.selectedActivityStateService.selectSelectedProspectVehicles()
    ).pipe(
      map(([activity, vehicles]) => {
        if (vehicles && vehicles.length > 0) {
          this.vehicleTabEnabled = true;
          if (activity.vehicleId && activity.vehicleId !== null) {
            this.selectedVehicle = vehicles.find((v: Vehicle) => v.id == activity.vehicleId);
            this.vehicleSelectionForm.patchValue({ vehicleId: this.selectedVehicle ? this.selectedVehicle : null });
            this.selectVehicle(this.selectedVehicle, vehicles);
          } else {
            this.vehicleSelectionForm.patchValue({ vehicleId: null });
          }
        } else {
          this.vehicleTabEnabled = false;
        }
        return activity;
      }),
      shareReplay(1)
    );

    this.loadDetail$ = combineLatest(
      this.selectedActivityStateService.selectSelectedProspect(),
      this.selectedActivityStateService.selectSelectedProspectIsLoading(),
      this.routerStateService.selectRouterState()
    ).pipe(
      tap(([prospect, isProspectLoading, routerState]) => {
        this.prospect = prospect;
        this.isProspectLoading = isProspectLoading;

        switch (routerState.state.root.queryParams.tab) {
          case 'activity':
            this.selectedTabIndex = 0;
            break;
          case 'prospect':
            this.selectedTabIndex = 1;
            break;
          case 'vehicle':
            this.selectedTabIndex = 2;
            break;
          default:
            this.selectedTabIndex = this.selectedTabIndex;
            break;
        }

        this.cdr.detectChanges();
      })
    );

    this.prospectAggregate$ = this.selectedActivityStateService.selectSelectedProspectAggregate();

    this.vehicleAggregate$ = this.selectedActivityStateService.selectSelectedVehicleAggregateData();
    this.vehicleServiceHistory$ = this.selectedActivityStateService
      .selectSelectedVehicleServiceHistory()
      .pipe(shareReplay(1));
    this.prospectNotes$ = this.selectedActivityStateService.selectSelectedProspectNotes().pipe(shareReplay(1));

    this.prospectActions$ = this.dropdownsStateService.selectProspectActions().pipe(
      filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions)),
      map((prospectActions) =>
        prospectActions.filter((action) => action.activeFlag === '1' && action.categoryId <= 2 && action.log === true)
      ),
      shareReplay(1)
    );

    this.selectedProspectActions$ = this.selectedActivityStateService
      .selectSelectedProspectActions()
      .pipe(shareReplay(1));

    this.responseLeads$ = this.selectedActivityStateService
      .selectSelectedProspectResponseLeads()
      .pipe(map((responseLeads) => responseLeads.filter((responseLead) => responseLead.url != null)));

    this.dropdowns$ = combineLatest(
      this.dropdownsStateService
        .selectChannels()
        .pipe(filter((channels) => !this.dropdownsStateService.isEmpty(channels))),
      this.dropdownsStateService.selectMessageTypes().pipe(
        filter((messageTypes) => !this.dropdownsStateService.isEmpty(messageTypes)),
        map((messageTypes) => messageTypes.filter((mt) => mt.displayInXselleratorUIFlag))
      ),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops) => rooftops.filter((rooftop: any) => rooftop.id > 0))
      ),
      this.dropdownsStateService
        .selectSalespeople()
        .pipe(filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople))),
      this.dropdownsStateService
        .selectSources()
        .pipe(filter((sources) => !this.dropdownsStateService.isEmpty(sources))),
      this.dropdownsStateService.selectProspectActions().pipe(
        filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions)),
        tap((prospectActions) => {
          this.opportunityActionId = prospectActions.find((ac) => ac.description === 'Opportunity').id;
          this.leadGeneratorActionId = prospectActions.find((ac) => ac.description === 'Lead Generator').id;
        })
      ),
      this.apiService.get<ProvinceState[]>('v/1/associates/province-states').pipe(take(1))
    ).pipe(
      map(([channels, messageTypes, rooftops, salespeople, sources, prospectActions, provStates]) => {
        return { channels, messageTypes, rooftops, salespeople, sources, prospectActions, provStates };
      }),
      shareReplay(1)
    );

    this.componentData$ = combineLatest(
      this.prospect$.pipe(filter((prospect) => !this.dropdownsStateService.isEmpty(prospect))),
      this.dropdowns$
    ).pipe(
      map(([prospect, dropdowns]) => {
        let dropdownsCopy = new DeepCopyPipe().transform(dropdowns);
        if (prospect.channelId != null) {
          if (
            dropdowns.channels.find((channel: Channel) => channel.id === prospect.channelId).description !==
            'Lead Generator'
          ) {
            if (dropdowns.channels.findIndex((channel: Channel) => channel.description === 'Lead Generator') > 0) {
              let channels = new DeepCopyPipe().transform(dropdowns.channels);
              channels.splice(
                channels.findIndex((channel: Channel) => channel.description === 'Lead Generator'),
                1
              );
              dropdownsCopy = { ...dropdownsCopy, channels: channels };
            }
          }
        }

        if (prospect.sourceId != null) {
          let selectedSourceDescription = dropdowns.sources
            .find((source: Source) => source.id === prospect.sourceId)
            .description.trim()
            .toLowerCase();

          if (!['available', 'over mileage', 'sub-prime', 'service appointments'].includes(selectedSourceDescription)) {
            let sources = new DeepCopyPipe().transform(dropdowns.sources);
            if (dropdowns.sources.findIndex((source: Source) => source.description === 'Available') > 0) {
              sources.splice(
                sources.findIndex((source: Source) => source.description === 'Available'),
                1
              );
            }
            if (dropdowns.sources.findIndex((source: Source) => source.description === 'Over Mileage') > 0) {
              sources.splice(
                sources.findIndex((source: Source) => source.description === 'Over Mileage'),
                1
              );
            }
            if (dropdowns.sources.findIndex((source: Source) => source.description === 'Sub-Prime') > 0) {
              sources.splice(
                sources.findIndex((source: Source) => source.description === 'Sub-Prime'),
                1
              );
            }
            if (dropdowns.sources.findIndex((source: Source) => source.description === 'Service Appointments') > 0) {
              sources.splice(
                sources.findIndex((source: Source) => source.description === 'Service Appointments'),
                1
              );
            }

            dropdownsCopy = { ...dropdownsCopy, sources: sources };
          }
        }

        let associatePreferences = { messagePreference: '', contactPreference: '' };
        if (prospect.embedded?.associate?.messagePreferenceId) {
          associatePreferences.messagePreference = dropdownsCopy.messageTypes.find(
            (messageType: MessageType) => messageType.id === prospect.embedded?.associate?.messagePreferenceId
          )?.description;
        }

        if (prospect.embedded?.associate?.contactPreferenceId) {
          switch (prospect.embedded.associate.contactPreferenceId) {
            case 'E':
              associatePreferences.contactPreference = 'Email';
              break;
            case 'F':
              associatePreferences.contactPreference = 'Fax';
              break;
            case 'L':
              associatePreferences.contactPreference = 'Letter';
              break;
            case 'H':
              associatePreferences.contactPreference = 'Phone';
              break;
            default:
              break;
          }
        }
        return { prospect, dropdownsCopy, associatePreferences };
      }),
      shareReplay(1)
    );

    this.prospectVehicles$ = this.selectedActivityStateService.selectSelectedProspectVehicles().pipe(shareReplay(1));
    this.prospectOpenSales$ = this.selectedActivityStateService.selectSelectedProspectOpenSales().pipe(shareReplay(1));
    this.prospectVehicleOfInterest$ = this.selectedActivityStateService
      .selectSelectedProspectVehicleOfInterest()
      .pipe(shareReplay(1));

    this.actionsPanelStatus$ = this.uiStateService.selectActionsVisible().subscribe((res) => (this.actionsPanel = res));
    this.openActivitiesPanelStatus$ = this.uiStateService
      .selectOpenActivitiesVisible()
      .subscribe((res) => (this.openActivitiesPanel = res));
    this.closedActivitiesPanelStatus$ = this.uiStateService
      .selectClosedActivitiesVisible()
      .subscribe((res) => (this.closedActivitiesPanel = res));
    this.purchaseOrderDetailsPanelStatus$ = this.uiStateService
      .selectPurchaseOrderDetailsVisible()
      .subscribe((res) => (this.purchaseOrderDetailsPanel = res));
    this.responseLeadsPanelStatus$ = this.uiStateService
      .selectResponseLeadsVisible()
      .subscribe((res) => (this.responseLeadsPanel = res));
    this.vehicleOfInterestPanelStatus$ = this.uiStateService
      .selectVehicleOfInterestVisible()
      .subscribe((res) => (this.vehicleOfInterestPanel = res));
    this.openSalesPanelStatus$ = this.uiStateService
      .selectOpenSalesVisible()
      .subscribe((res) => (this.openSalesPanel = res));
    this.notesPanelStatus$ = this.uiStateService.selectNotesVisible().subscribe((res) => (this.notesPanel = res));
    this.serviceHistoryPanelStatus$ = this.uiStateService
      .selectServiceHistoryVisible()
      .subscribe((res) => (this.serviceHistoryPanel = res));

    this.authenticatedUser$ = this.authenticationStateService
      .selectAuthenticatedUser()
      .subscribe((authenticatedUser: AuthenticatedUser) => {
        this.authenticatedUser = authenticatedUser;
      });

    this.authenticationStateService
      .selectAuthenticatedEmployee()
      .pipe(
        filter((employee) => employee != null),
        take(1)
      )
      .subscribe((employee) => {
        this.authenticatedEmployee = employee;
        this.leadGeneratorSct$ = this.systemControlStateService
          .getSystemControlValue('CL_LEAD_GENERATOR_ENABLED', employee.rooftopId)
          .pipe(
            map((sct) => {
              return sct.value;
            }),
            shareReplay(1)
          );

        this.systemControlStateService
          .getSystemControlValue('CL_DEALER_COUNTRY_ID', employee.rooftopId)
          .pipe(take(1))
          .subscribe((sct) => {
            this.mileageLabel = sct.value == 'USA' ? 'mi.' : 'km';
            this.countryCode = sct.value == 'USA' ? 'U' : 'C';
          });
      });
    this.salesPlannerStateService.listenForToasts().subscribe((toastOptions) => {
      this.snackBar.open(toastOptions.message, toastOptions.actionText, toastOptions.options);
    });

    this.combinedObs$ = forkJoin(
      this.dropdownsStateService.selectSalesTeams().pipe(
        filter((salesTeams) => !this.dropdownsStateService.isEmpty(salesTeams)),
        map((salesTeams) => salesTeams.filter((salesTeam: SalesTeamMaintenance) => salesTeam.teamLead === true)),
        take(1)
      ),
      this.authenticationStateService.selectAuthenticatedUser().pipe(
        filter((authenticatedUser: AuthenticatedUser) => authenticatedUser != null),
        take(1)
      ),
      this.settingsStateService.selectSettingsFromState().pipe(
        filter((settings) => settings.salesPlannerView !== null),
        take(1)
      )
    ).pipe(
      map(([teamLeads, authenticatedUser, crmSettings]) => {
        return {
          salesPlannerView: crmSettings.salesPlannerView,
          teamLead:
            teamLeads.find((teamLead: any) => teamLead.employeeId === authenticatedUser.user.xselleratorEmployeeId) !==
            undefined,
        };
      }),
      shareReplay(1)
    );

    this.isVehicleAggregateLoading$ = this.selectedActivityStateService
      .selectVehicleAggregateIsLoading()
      .pipe(shareReplay(1));
    this.postProspectProcessSubscription = this.crmEntityStateService
      .postProspectCreateAndUpdate()
      .pipe(take(1))
      .subscribe((prospect: Prospect) => {
        if (this.createProspectProgressRef) {
          this.createProspectProgressRef.close();
        }
      });

    //this.cdr.detach();
    //this.cdr.detectChanges();
    this.crmDropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleSubcategories().pipe(take(1)),
      this.dropdownsStateService.selectVehicleSubTypes().pipe(take(1)),
      this.dropdownsStateService.selectVehicleStatuses().pipe(take(1)),
      this.dropdownsStateService.selectVehicleColours().pipe(take(1)),
      this.dropdownsStateService.selectRooftops().pipe(take(1))
    ).pipe(
      map(([vehicleSubcategories, vehicleSubTypes, vehicleStatuses, vehicleColours, rooftops]) => {
        return {
          vehicleSubcategories,
          vehicleSubTypes,
          vehicleStatuses,
          vehicleColours,
          rooftops,
        };
      })
    );
  }

  ngOnDestroy() {
    this.actionsPanelStatus$.unsubscribe();
    this.openActivitiesPanelStatus$.unsubscribe();
    this.closedActivitiesPanelStatus$.unsubscribe();
    this.purchaseOrderDetailsPanelStatus$.unsubscribe();
    this.responseLeadsPanelStatus$.unsubscribe();
    this.vehicleOfInterestPanelStatus$.unsubscribe();
    this.openSalesPanelStatus$.unsubscribe();
    this.notesPanelStatus$.unsubscribe();
    this.serviceHistoryPanelStatus$.unsubscribe();
    this.postProspectProcessSubscription.unsubscribe();
  }

  prospectUpdated(updatedData: any, dropdowns: any): void {
    this.salesPlannerStateService
      .selectProspectAfterUpdated(updatedData.updatedProspect)
      .subscribe((updatedProspect) => {
        if (updatedProspect.primaryEmployeeId != updatedData.prospectCopy.primaryEmployeeId) {
          if (updatedData.prospectCopy.primaryEmployeeId !== null) {
            let prospectReassignActionId: number = dropdowns.prospectActions.find(
              (ac: Action) => ac.description.toLowerCase() === 'prospect reassign'
            ).id;
            updatedData.updatedProspect = { ...updatedData.updatedProspect, actionId: prospectReassignActionId };

            let newProspectAction: ProspectAction = {
              ...new ProspectAction(),
              appointmentDateTime: new Date(),
              enteredDateTime: new Date(),
              taskActionId: prospectReassignActionId,
              associateEmployeeId: this.authenticatedUser.user.xselleratorEmployeeId,
              prospectId: updatedProspect.id,
              previousSalespersonId: updatedData.prospectCopy.primaryEmployeeId,
              newSalespersonId: updatedData.updatedProspect.primaryEmployeeId,
            };
            this.selectedActivityStateService.createSelectedProspectAction(
              newProspectAction,
              updatedData.updatedProspect
            );
          }
          this.selectedActivityStateService.reassignSelectedProspectActivities(
            updatedData.prospectCopy,
            updatedProspect
          );
        }
      });

    if (updatedData.updatedAssociate.id != null) {
      //updatedData.updatedAssociate.emailAddress = updatedData.updatedProspect.emailAddress;
      this.crmEntityStateService.updateProspectAndAssociateOnServer(
        updatedData.updatedAssociate,
        updatedData.updatedProspect,
        updatedData.updatedAddress
      );
    } else {
      this.crmEntityStateService.updateProspectOnServer(updatedData.updatedProspect, true);
    }
  }

  selectVehicle(
    selectedVehicle: Vehicle,
    prospectVehicles: Vehicle[],
    prospect?: Prospect,
    dropdownSelection?: boolean
  ) {
    if (selectedVehicle) {
      if (selectedVehicle.id != null && prospectVehicles.length > 0) {
        this.selectedVehicle = prospectVehicles.find((vehicle: Vehicle) => vehicle.id === selectedVehicle.id);
        this.apiService
          .get<AssociateReport[]>('v/1/reporting/associates', {
            params: new AssociateQueryParameters({
              id: this.selectedVehicle.salespersonId,
            }),
          })
          .subscribe((associate) => {
            if (associate.length !== 0) {
              this.selectedVehicleSalespersonName = `${associate[0].firstName} ${associate[0].lastName}`;
            } else {
              if (prospect && prospect.embedded.primaryEmployeeAssociate) {
                this.selectedVehicleSalespersonName =
                  prospect.embedded.primaryEmployeeAssociate.firstName +
                  ' ' +
                  prospect.embedded.primaryEmployeeAssociate.lastName;
              } else {
                this.selectedVehicleSalespersonName = null;
              }
            }
          });
        if (
          this.selectedVehicle.soldDate != null &&
          this.selectedVehicle.paymentFrequency != null &&
          this.selectedVehicle.totalNumberOfPayments &&
          this.selectedVehicle.saleId == null
        ) {
          let numberOfPayments: number;
          let startDate = new Date(this.selectedVehicle.soldDate);

          let startYear = startDate.getFullYear();
          let startMonth = startDate.getMonth();
          let startDay = startDate.getDate();
          let endDate: Date;

          switch (this.selectedVehicle.paymentFrequency) {
            case 'A':
              numberOfPayments = 1;
              endDate = new Date(startYear + 1, startMonth, startDay);
              break;
            case 'B':
              numberOfPayments = 26;
              endDate = new Date(startYear, startMonth, startDay + 14);
              break;
            case 'M':
              numberOfPayments = 12;
              endDate = new Date(startYear, startMonth + 1, startDay);
              break;
            case 'N':
              numberOfPayments = 24;
              endDate = new Date(startYear, startMonth, startDay + 15);
              break;
            case 'P':
              numberOfPayments = 1;
              endDate = new Date(startYear + 1, startMonth, startDay);
              break;
            case 'S':
              numberOfPayments = 2;
              endDate = new Date(startYear, startMonth + 6, startDay);
              break;
            case 'W':
              numberOfPayments = 52;
              endDate = new Date(startYear, startMonth, startDay + 7);
              break;
            default:
              numberOfPayments = 26;
              endDate = new Date(startYear, startMonth, startDay + 14);
              break;
          }
          this.selectedVehicleRemainingPayments =
            this.selectedVehicle.totalNumberOfPayments -
            Math.floor((this.monthDiff(endDate, new Date()) / 12) * numberOfPayments);

          if (this.selectedVehicle.lastPaymentAmount) {
            this.selectedVehicleConversionRemainingBalance =
              (this.selectedVehicle.totalNumberOfPayments -
                Math.floor((this.monthDiff(endDate, new Date()) / 12) * numberOfPayments)) *
              this.selectedVehicle.lastPaymentAmount;
          }
        } else if (
          this.selectedVehicle.soldDate != null &&
          this.selectedVehicle.lastSaleTerm != null &&
          this.selectedVehicle.lastSaleTerm != 0 &&
          this.selectedVehicle.price1Amount != null &&
          this.selectedVehicle.price1Amount != 0
        ) {
          let monthlyPayment: number;
          let monthsPaid: number;
          let amountPaid: number;
          this.estimatedConversion = true;

          if (this.selectedVehicle.lastPaymentAmount) {
            monthlyPayment = this.selectedVehicle.lastPaymentAmount;
          } else {
            monthlyPayment = this.selectedVehicle.price1Amount / this.selectedVehicle.lastSaleTerm;
          }

          monthsPaid = this.monthDiff(new Date(this.selectedVehicle.soldDate), new Date());
          amountPaid = monthsPaid * monthlyPayment;

          if (amountPaid >= this.selectedVehicle.price1Amount) {
            this.selectedVehicleConversionRemainingBalance = 0;
          } else {
            this.selectedVehicleConversionRemainingBalance = this.selectedVehicle.price1Amount - amountPaid;
          }
          if (monthsPaid >= this.selectedVehicle.lastSaleTerm) {
            this.selectedVehicleRemainingPayments = 0;
          } else {
            this.selectedVehicleRemainingPayments = this.selectedVehicle.lastSaleTerm - monthsPaid;
          }
        } else {
          this.estimatedConversion = false;
          this.selectedVehicleConversionRemainingBalance = null;
          this.selectedVehicleRemainingPayments = null;
        }
        this.selectedActivityStateService.getVehicleAggregateData(selectedVehicle.id);
        this.selectedActivityStateService.getVehicleServiceHistory(selectedVehicle.id, '');
      }
    } else {
      if (dropdownSelection) {
        this.addVehicle(prospect);
      }
    }
  }

  monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  createProspectAction(action: Action, prospect: Prospect) {
    let newProspectAction: ProspectAction = {
      ...new ProspectAction(),
      appointmentDateTime: new Date(),
      enteredDateTime: new Date(),
      taskActionId: action.id,
      associateEmployeeId: this.authenticatedUser.user.xselleratorEmployeeId,
      prospectId: prospect.id,
    };
    this.selectedActivityStateService.createSelectedProspectAction(newProspectAction, prospect);
  }

  deleteProspectAction(prospectAction: ProspectAction) {
    this.selectedActivityStateService.deleteSelectedProspectAction(prospectAction);
  }

  togglePanel(panelName: string) {
    switch (panelName) {
      case 'openActivities': {
        this.uiStateService.toggleOpenActivitiesPanel(!this.openActivitiesPanel);
        break;
      }
      case 'closedActivities': {
        this.uiStateService.toggleClosedActivitiesPanel(!this.closedActivitiesPanel);
        break;
      }
      case 'purchaseOrderDetails': {
        this.uiStateService.togglePurchaseOrderDetailsPanel(!this.purchaseOrderDetailsPanel);
        break;
      }
      case 'responseLeads': {
        this.uiStateService.toggleReponseLeadsPanel(!this.responseLeadsPanel);
        break;
      }
      case 'openSales': {
        this.uiStateService.toggleOpenSalesPanel(!this.openSalesPanel);
        break;
      }
      case 'notes': {
        this.uiStateService.toggleNotesPanel(!this.notesPanel);
        break;
      }
      case 'serviceHistory': {
        this.uiStateService.toggleServiceHistoryPanel(!this.serviceHistoryPanel);
        break;
      }
      case 'actions': {
        this.uiStateService.toggleActionsPanel(!this.actionsPanel);
        break;
      }
      case 'vehicleOfInterest': {
        this.uiStateService.toggleVehicleOfInterestPanel(!this.vehicleOfInterestPanel);
        break;
      }
      default: {
        break;
      }
    }
  }

  selectActivity(activity: any) {
    switch (activity.activityType) {
      case 'Appointment':
        this.routerStateService.go([`appointments`, `${activity.activityId}`], {
          queryParams: { tab: 'activity' },
          relativeTo: this.route.parent.parent,
        });
        break;
      case 'Task':
        this.routerStateService.go([`tasks`, `${activity.activityId}`], {
          queryParams: { tab: 'activity' },
          relativeTo: this.route.parent.parent,
        });
        break;
      case 'Service':
        this.routerStateService.go([`service-appointments`, `${activity.activityId}`], {
          queryParams: { tab: 'activity' },
          relativeTo: this.route.parent.parent,
        });
        break;
      case 'Promotion':
        this.routerStateService.go([`dealer-promotions`, `${activity.activityId}`], {
          queryParams: { tab: 'activity' },
          relativeTo: this.route.parent.parent,
        });
        break;
      case 'Delivery':
        this.routerStateService.go([`deliveries`, `${activity.activityId}`], {
          queryParams: { tab: 'activity' },
          relativeTo: this.route.parent.parent,
        });
        break;
    }
  }

  openVehicle(vehicleId: number) {
    let params: string[] = [vehicleId.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_maintain', params);
  }

  openVehicleSale(vehicleSaleId: number) {
    let params: string[] = [vehicleSaleId.toString()];
    this.browserControlCommunicationService.drilldown('salesPlanner', 'w_vehicle_sales_maintain', params);
  }

  openTradeValuation(vehicleId: number) {
    let params: string[] = [vehicleId.toString()];
    this.browserControlCommunicationService.drilldown('tradeValuation', 'w_vehicle_trade_valuation', params);
  }

  openBlackBookEditorDialog(reportItem: any, countryCode: string, authenticatedEmployee: Employee, prospect: Prospect) {
    const dialogRef = this.dialog.open(AddDeductDialogComponent, {
      width: '600px',
      data: {
        reportItem: reportItem,
        rooftopId: authenticatedEmployee.rooftopId,
        countryCode: countryCode,
      },
    });

    dialogRef.afterClosed().subscribe((dialogResponse: any) => {
      if (dialogResponse.reload) {
        let toastOptions: any = {
          message: `Vehicle ${dialogResponse.vehicleId} updated`,
          actionText: null,
          options: { duration: 3000, horizontalPosition: 'left' },
        };
        this.selectedActivityStateService.getSelectedProspectVehicles(prospect);
        this.salesPlannerStateService.addToast(toastOptions);
      }
    });
  }

  backClicked() {
    this.routerStateService.go(['/crm/sales-planner'], { relativeTo: this.route });
  }

  selectTab(event: any) {
    let navExtras: NavigationExtras = {
      relativeTo: this.route,
      queryParams: { tab: event.tab.textLabel.toLowerCase() },
      queryParamsHandling: 'merge',
    };
    this.routerStateService.go('', navExtras);
  }

  openCreateProspectDialog(
    selectedVehicle: Vehicle,
    authenticatedEmployee: Employee,
    prospect: Prospect,
    leadGeneratorEnabled: string
  ): void {
    combineLatest([
      this.dropdowns$.pipe(take(1)),
      this.systemControlStateService
        .getSystemControlValue('CL_CUSTOMER_PROSPECT_EMAIL', authenticatedEmployee.rooftopId)
        .pipe(take(1)),
    ])
      .pipe(
        map(([dropdowns, sct]) => {
          return { dropdowns, sct };
        })
      )
      .subscribe((combinedData) => {
        let channel: Channel;
        let source: Source;
        if (leadGeneratorEnabled === '1') {
          channel = combinedData.dropdowns.channels.find(
            (channel: Channel) => channel.description.toLowerCase() == 'lead generator'
          );
          source = combinedData.dropdowns.sources.find(
            (source: Source) => source.description.toLowerCase() == 'sales planner'
          );
        } else {
          channel = combinedData.dropdowns.channels.find((channel: Channel) => channel.id == prospect.channelId);
          let index = combinedData.dropdowns.channels.indexOf(
            combinedData.dropdowns.channels.find(
              (channel: Channel) => channel.description.toLowerCase() == 'lead generator'
            )
          );
          combinedData.dropdowns.channels.splice(index, 1);
        }
        let associateRequired: string[] = [];
        let contactRequired: string[] = [];
        let prospectRequired: string[] = [];
        associateRequired.push('name');

        if (combinedData.sct.value === '1') {
          associateRequired.push('emailAddress');
          prospectRequired.push('emailAddress');
        }

        contactRequired.push('homePhoneNumber');
        contactRequired.push('addressLine1');
        contactRequired.push('locationId');
        contactRequired.push('provStateId');
        contactRequired.push('countryId');
        contactRequired.push('postalZipCode');

        prospectRequired.push('freeformName');
        prospectRequired.push('primaryEmployeeId');
        prospectRequired.push('rooftopId');
        prospectRequired.push('phoneNumber');
        prospectRequired.push('channelId');
        prospectRequired.push('sourceId');

        const dialogRef = this.dialog.open(ProspectCreateDialogComponent, {
          data: {
            dropdowns: combinedData.dropdowns,
            newProspect: {
              ...new Prospect(),
              actionId: leadGeneratorEnabled === '1' ? this.leadGeneratorActionId : this.opportunityActionId,
              actionedVehicleId: selectedVehicle.id,
              associateId: prospect.associateId,
              channelId: channel.id,
              customerTypeId: '1',
              employeeCreatedId: authenticatedEmployee.associateId,
              primaryEmployeeId: authenticatedEmployee.associateId,
              rooftopId: authenticatedEmployee.rooftopId,
              sourceId: source ? source.id : null,
              statusId: '1',
              typeId: 3,
              vehicleId: selectedVehicle.id,
            },
            newAssociate: {
              ...new Associate(),
              id: prospect.associateId,
              firstName: prospect.embedded.associate.firstName,
              lastName: prospect.embedded.associate.lastName,
            },
            requiredFields: {
              associate: associateRequired,
              prospect: prospectRequired,
              contact: contactRequired,
            },
            disabledFields:
              leadGeneratorEnabled === '1'
                ? {
                    associate: ['classId', 'emailAddress'],
                    prospect: ['channelId', 'sourceId'],
                    contact: [],
                  }
                : {
                    associate: ['classId', 'emailAddress'],
                    prospect: [],
                    contact: [],
                  },
          },
        });

        dialogRef.afterClosed().subscribe((object) => {
          if (object) {
            this.createProspectProgressRef = this.dialog.open(ProspectCreateProgressDialogComponent, {
              data: { message: 'Creating prospect and associated records...' },
            });
            if (object.newProspect.associateId === null && !object.freeform) {
              this.crmEntityStateService.createProspectAndAssociateOnServer(
                object.newAddress,
                object.newAssociate,
                object.newProspect,
                object.salespersonTakeoverMessage
              );
            } else {
              this.crmEntityStateService.createProspectOnServer(object.newProspect, object.salespersonTakeoverMessage);
            }
          }
        });
      });
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['..', 'prospects', prospectId], {
      queryParams: { tab: 'prospect' },
      relativeTo: this.route.parent,
    });
  }

  addVehicle(prospect: Prospect) {
    this.routerStateService.go([`prospects/${prospect.id}/add-prospect-vehicle`], {
      queryParams: null,
      relativeTo: this.route.parent.parent,
    });
  }

  appointmentUpdated(event: any) {
    console.log('appointment updated');
    console.log(event);
    combineLatest([
      this.actions$.pipe(ofType(CommunicatorActions.sendMessageToConversationSuccess), take(1)),
      this.dropdowns$.pipe(take(1)),
    ])
      .pipe(
        map(([sendMessageSuccessAction, dropdowns]) => {
          return { sendMessageSuccessAction, dropdowns };
        }),
        take(1)
      )
      .subscribe((data) => {
        console.log('data');
        console.log(data);
        this.selectedActivityStateService.createSentEmailTextProspectAction(
          this.prospect,
          data.dropdowns,
          this.authenticatedUser.user
        );
      });
  }

  openVehicleProfileInfo() {
    this.apiService
      .get(`/v/1/vehicles/vehicles/${this.selectedVehicle.id}`, { params: { embed: 'sale' } })
      .subscribe((vehicle) => {
        const dialogRef = this.dialog.open(VehicleProfileDialogComponent, {
          disableClose: true,
          panelClass: 'custom-vehicle-profile-dialog',
          data: {
            vehicle: vehicle,
            freeformFirstName:
              this.prospect && this.prospect.freeformName ? this.prospect.freeformName?.split(',')[1].trim() : '',

            freeformLastName:
              this.prospect && this.prospect.freeformName ? this.prospect.freeformName?.split(',')[0].trim() : '',

            associateFirstName:
              this.prospect && this.prospect.embedded.associate
                ? this.prospect.embedded.associate.firstName
                : this.prospect && this.prospect.freeformName
                ? this.prospect.freeformName?.split(',')[1].trim()
                : '',

            associateLastName:
              this.prospect && this.prospect.embedded.associate
                ? this.prospect.embedded.associate.lastName
                : this.prospect && this.prospect.freeformName
                ? this.prospect.freeformName?.split(',')[0].trim()
                : '',
            crmDropdowns$: this.crmDropdowns$,
            salespersonFirstName: this.selectedVehicleSalespersonName
              ? this.selectedVehicleSalespersonName.split(' ')[0].trim()
              : '',
            salespersonLastName: this.selectedVehicleSalespersonName
              ? this.selectedVehicleSalespersonName.split(' ')[1].trim()
              : '',
          },
        });
      });
  }
}
