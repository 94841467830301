import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { MessageType } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface MessageTypesState extends EntityState<MessageType> {}

export const messageTypesAdapter: EntityAdapter<MessageType> = createEntityAdapter<MessageType>({
  selectId: (messageType: MessageType) => messageType.id,
});

export const initialState: MessageTypesState = messageTypesAdapter.getInitialState({});
export const messageTypesSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.messageTypes);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  messageTypesAdapter.getSelectors(messageTypesSelector);
