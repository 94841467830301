import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleSmallComponent } from './vehicle-small/vehicle-small.component';
import { VehicleMediumComponent } from './vehicle-medium/vehicle-medium.component';
import { VehicleLargeComponent } from './vehicle-large/vehicle-large.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonMaterialComponentsModule } from '@quorum/common-material-components';
import { FlexLayoutModule } from '@angular/flex-layout';
import { VehicleSmallLoadingComponent } from './vehicle-small-loading/vehicle-small-loading.component';
import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { NgPipesModule } from 'ngx-pipes';
// eslint-disable-next-line max-len
import { CreateVehicleWithAssociateComponent } from './create-vehicle-with-associate/create-vehicle-with-associate.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CommonPipesModule } from '@quorum/common-pipes';
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonMaterialComponentsModule,
    FlexLayoutModule,
    NgPipesModule,
    TextMaskModule,
    CommonPipesModule
  ],
  declarations: [
    VehicleSmallComponent,
    VehicleMediumComponent,
    VehicleLargeComponent,
    VehicleSmallLoadingComponent,
    AddVehicleComponent,
    CreateVehicleWithAssociateComponent,
    CreateVehicleComponent
  ],
  exports: [
    VehicleSmallComponent,
    VehicleMediumComponent,
    VehicleLargeComponent,
    VehicleSmallLoadingComponent,
    AddVehicleComponent,
    CreateVehicleWithAssociateComponent,
    CreateVehicleComponent
  ]
})
export class ShaVehiclesNgUiModule {}
