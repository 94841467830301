import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'activitiesFilter',
})
export class ActivitiesFilterPipe implements PipeTransform {
  transform(activities: any[], activityTypes: string[]): any {
    if (!activities || !activityTypes) {
      return activities;
    }

    return activities.filter((activity) => {
      return activityTypes.indexOf(activity.activityType) > -1;
    });
  }
}
