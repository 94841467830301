export class InEquityQueryParameters {
  actioned?: string;
  inEquity?: string;
  originalSalespersonId?: string;
  page?: number;
  pageSize?: number;
  rooftopId?: string;
  salespersonId?: string[];
  saleTypeId?: string[];
  sort?: string;
  userEmployeeId?: string;
  vehicleId?: string;

  constructor(obj?: InEquityQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
