import * as fromDealerPromotions from './dealer-promotions.actions';
import { dealerPromotionsAdapter, DealerPromotionsState, initialState } from './dealer-promotions.interfaces';

export function reducer(
  state: DealerPromotionsState = initialState,
  action: fromDealerPromotions.DealerPromotionsAction
): DealerPromotionsState {
  switch (action.type) {
    case fromDealerPromotions.GET_DEALER_PROMOTIONS_FROM_SERVER: {
      return {
        ...state,
        loading: true
      };
    }
    case fromDealerPromotions.GET_DEALER_PROMOTIONS_FROM_SERVER_SUCCESS: {
      return {
        ...dealerPromotionsAdapter.addMany(action.payload.dealerPromotions, state),
        loading: false
      };
    }

    case fromDealerPromotions.UPDATE_DEALER_PROMOTIONS_ON_SERVER_SUCCESS: {
      return {
        ...dealerPromotionsAdapter.updateOne(action.payload, state)
      };
    }

    case fromDealerPromotions.CLEAR_DEALER_PROMOTIONS_FROM_STATE: {
      return {
        ...dealerPromotionsAdapter.removeAll({ ...state, loading: false })
      };
    }

    default: {
      return state;
    }
  }
}
