import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: any, format: string): any {
    if (Date.parse(value)) {
      return moment
        .utc(value)
        .local()
        .format(format ? format : 'MMM DD, YYYY');
    } else {
      return 'N/A';
    }
  }

  isBetween(firstDate: any, secondDate: any, dueDate: any) {
    return moment(dueDate).isBetween(firstDate, secondDate);
  }

  timeDate(time: any, format: any) {
    return moment(time, format).toDate();
  }
}
