import { SubCategoryQueryParameters } from '@quorum/models/xs-query';
import { SubCategory } from '@quorum/models/xs-resource';
import { Action } from '@ngrx/store';

export const GET_VEHICLE_SUBCATEGORIES_FROM_SERVER = '[CRM] Get Vehicle Subcategories From Server';
export class GetVehicleSubcategoriesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SUBCATEGORIES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: SubCategoryQueryParameters) {}
}

export const GET_VEHICLE_SUBCATEGORIES_FROM_SERVER_SUCCESS = '[CRM] Get Vehicle Subcategories From Server Success';
export class GetVehicleSubcategoriesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SUBCATEGORIES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { vehicleSubcategories: SubCategory[] }) {}
}

export const GET_VEHICLE_SUBCATEGORIES_FROM_SERVER_FAILURE = '[CRM] Get Vehicle Subcategories From Server Failure';
export class GetVehicleSubcategoriesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_VEHICLE_SUBCATEGORIES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type VehicleSubcategoriesAction =
  | GetVehicleSubcategoriesFromServer
  | GetVehicleSubcategoriesFromServerSuccess
  | GetVehicleSubcategoriesFromServerFailure;
