import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { BrowserControlCommunicationService } from '@applications/sha-services';
import { ApiService } from '@quorum/api';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { EquityMiningStateService } from '@quorum/crm-equity-mining/services';
import { UnsoldQueryParameters } from '@quorum/models/xs-query';
import { Employee, OemRooftopLink, SubType, Unsold } from '@quorum/models/xs-resource';
import { RouterStateService } from '@quorum/sha-router';
import { SystemControlStateService } from '@quorum/xsr-system-control/services';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, forkJoin, fromEvent, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { delay, filter, map, pairwise, startWith, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'crm-unsold-vehicles-search-tab',
  templateUrl: './unsold-vehicles-search-tab.component.html',
  styleUrls: ['./unsold-vehicles-search-tab.component.css'],
})
export class UnsoldVehiclesSearchTabComponent implements OnInit, AfterViewInit {
  defaultFormModel: UnsoldQueryParameters = new UnsoldQueryParameters({
    actioned: '',
    categoryId: '',
    genericModelId: '',
    id: '',
    lastAction: '',
    maxYear: null,
    minYear: null,
    page: 0,
    pageSize: 25,
    prospectStatusId: [''],
    rooftopId: '',
    salesPersonId: [''],
    search: '',
    sort: '-enteredDate',
    subCategoryId: '',
    subTypeId: '',
    unitNumber: '',
    vehicleDescription: '',
    vehicleId: '',
    vehicleMake: '',
    vehicleModelCode: '',
    userEmployeeId: null,
  });

  authenticatedEmployee: Employee;
  countryCode: string;
  mileageLabel: string;
  vehicleSearchForm = this.fb.group(this.defaultFormModel);
  dropdowns$: Observable<any>;
  routerState$: Subscription;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  sort: string;
  searchResult: any = { data: [], headers: null };
  search$: Observable<any>;
  @ViewChild('searchButton', { static: true }) searchButton: any;
  private formInititialized = new ReplaySubject(1);
  public formRef: ElementRef;
  @ViewChild('vehicleSearchFormRef')
  set setTabSet(content: any) {
    if (!!content) {
      this.formRef = content;
      this.formInititialized.next(true);
    }
  }

  constructor(
    public apiService: ApiService,
    public authenticationStateService: AuthenticationStateService,
    public browserControlCommunicationService: BrowserControlCommunicationService,
    public crmEntityStateService: CrmEntityStateService,
    public dialog: MatDialog,
    public dropdownsStateService: DropdownsStateService,
    public routerStateService: RouterStateService,
    public fb: FormBuilder,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public equityMiningStateService: EquityMiningStateService,
    public systemControlStateService: SystemControlStateService
  ) {}

  ngAfterViewInit() {
    this.formInititialized.pipe(take(1)).subscribe((d) => {
      this.search$ = fromEvent(this.formRef.nativeElement, 'submit');
      this.search$
        .pipe(
          switchMap((d) => this.route.queryParams.pipe(take(1))),
          switchMap((params) => {
            if (params) {
              this.equityMiningStateService.updateReportParameters('unsold-vehicles', params);
              this.isLoading$.next(true);
              return this.apiService.getWithHeaders<Unsold[]>(`v/1/reporting/crm/unsold`, { params: params }).pipe(
                map((response) => {
                  this.isLoading$.next(false);

                  return { data: response.body, headers: response.headers };
                })
              );
            } else {
              this.isLoading$.next(false);
              return of({ data: null, headers: null });
            }
          })
        )
        .subscribe((sresult: any) => {
          this.searchResult = sresult;
          this.cdr.detectChanges();
        });
    });
  }

  ngOnInit() {
    this.vehicleSearchForm.valueChanges.pipe(startWith(null), pairwise()).subscribe(([previousValue, currentValue]) => {
      if (previousValue && previousValue.page === currentValue.page) {
        this.sort = currentValue.sort;
        this.vehicleSearchForm.patchValue({ ...currentValue, page: 0 }, { emitEvent: false });
        this.updateRouter({ ...currentValue, page: 0 });
      } else {
        this.updateRouter(currentValue);
      }
    });

    this.routerState$ = forkJoin(
      this.routerStateService.selectRouterState().pipe(
        filter((routerState) => routerState.state.url.indexOf('lead-generator/unsold-vehicles') > -1),
        take(1)
      ),
      this.authenticationStateService.selectAuthenticatedEmployee().pipe(
        filter((employee) => employee != null),
        take(1),
        tap((authEmployee) => {
          this.systemControlStateService
            .getSystemControlValues(['CL_DEALER_COUNTRY_ID'], authEmployee.rooftopId)
            .pipe(take(1))
            .subscribe((sct) => {
              this.authenticatedEmployee = authEmployee;
              this.mileageLabel = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'mi.' : 'km';
              this.countryCode = sct['CL_DEALER_COUNTRY_ID'].value == 'USA' ? 'U' : 'C';
            });
        })
      )
    ).subscribe(([routerState, authenticatedEmployee]) => {
      let queryParams: any = routerState.state.root.queryParams;
      if (this.dropdownsStateService.isEmpty(queryParams)) {
        this.vehicleSearchForm.patchValue(
          {
            ...this.defaultFormModel,
            salespersonId: [authenticatedEmployee.associateId],
            rooftopId: authenticatedEmployee.rooftopId.toString(),
            userEmployeeId: authenticatedEmployee.associateId,
          },
          { emitEvent: true }
        );
        this.sort = '-enteredDate';
      } else {
        if (queryParams.sort) {
          this.sort = queryParams.sort;
        }

        let formModel = {
          ...this.defaultFormModel,
          ...queryParams,
          salesPersonId: Array.isArray(queryParams.salesPersonId)
            ? [...queryParams.salesPersonId]
            : [queryParams.salesPersonId],
          prospectStatusId: Array.isArray(queryParams.prospectStatusId)
            ? [...queryParams.prospectStatusId]
            : [queryParams.prospectStatusId],
        };
        this.vehicleSearchForm.patchValue(formModel, {
          emitEvent: false,
        });

        Object.keys(queryParams).forEach((key) => {
          if (
            key == 'subCategoryId' ||
            key == 'subTypeId' ||
            key == 'genericModelId' ||
            key == 'rooftopId' ||
            key == 'lastAction'
          ) {
            let id =
              this.vehicleSearchForm.controls[key].value == ''
                ? ''
                : parseInt(this.vehicleSearchForm.controls[key].value);
            this.vehicleSearchForm.controls[key].setValue(id);
          }
        });

        this.formInititialized.pipe(delay(1000), take(1)).subscribe((d) => {
          document.getElementById('search-button').click();
        });
      }
    });

    this.dropdowns$ = forkJoin(
      this.dropdownsStateService.selectVehicleCategories().pipe(take(1)),
      this.dropdownsStateService.selectVehicleColours().pipe(take(1)),
      this.dropdownsStateService.selectVehicleGenericModels().pipe(take(1)),
      this.dropdownsStateService.selectVehicleMakes().pipe(take(1)),
      this.dropdownsStateService.selectVehicleSubcategories().pipe(take(1)),
      this.dropdownsStateService.selectRooftops().pipe(
        filter((rooftops: OemRooftopLink[]) => !this.dropdownsStateService.isEmpty(rooftops)),
        map((rooftops: OemRooftopLink[]) => rooftops.filter((rooftop: OemRooftopLink) => rooftop.id > 0)),
        take(1)
      ),
      this.dropdownsStateService.selectVehicleSubTypes().pipe(
        map((vehicleSubTypes: SubType[]) =>
          vehicleSubTypes.filter((vehicleSubType: SubType) => vehicleSubType.showFlag == '1')
        ),
        take(1)
      ),
      this.dropdownsStateService.selectSalespeople().pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        take(1)
      ),
      this.dropdownsStateService.selectProspectActions().pipe(
        filter((prospectActions) => !this.dropdownsStateService.isEmpty(prospectActions)),
        take(1)
      )
    ).pipe(
      map(
        ([
          vehicleCategories,
          vehicleColours,
          vehicleGenericModels,
          vehicleMakes,
          vehicleSubcategories,
          rooftops,
          vehicleSubTypes,
          salespeople,
          prospectActions,
        ]) => {
          return {
            vehicleCategories,
            vehicleColours,
            vehicleGenericModels,
            vehicleMakes,
            vehicleSubcategories,
            rooftops,
            vehicleSubTypes,
            salespeople,
            prospectActions,
          };
        }
      )
    );
  }

  updateRouter(queryParams: any) {
    let navExtras: NavigationExtras = {
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    };

    this.routerStateService.go('', navExtras);
  }

  openProspect(prospectId: string) {
    this.routerStateService.go(['..', 'sales-planner', 'prospects', prospectId], {
      relativeTo: this.route.parent,
    });
  }

  sortChanged(sortValue: string, filterForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.sort == sortValue;
        }),
        take(1)
      )
      .subscribe((value) => {
        document.getElementById('search-button').click();
      });

    filterForm.patchValue({ ...filterForm.value, sort: sortValue });
  }

  pageChanged(event: any, filterForm: FormGroup) {
    this.route.queryParams
      .pipe(
        filter((value: any) => {
          return value.page == event.pageIndex;
        }),
        take(1)
      )
      .subscribe((value) => {
        this.searchButton.nativeElement.click();
      });

    filterForm.patchValue({ ...filterForm.value, page: event.pageIndex, pageSize: event.pageSize });
  }

  getReport(queryParams: UnsoldQueryParameters = null) {
    this.searchButton.nativeElement.click();
  }

  resetVehicleSearchForm(authenticatedEmployee: Employee) {
    this.vehicleSearchForm.patchValue({
      ...this.defaultFormModel,
      salespersonId: [authenticatedEmployee.associateId],
      rooftopId: authenticatedEmployee.rooftopId.toString(),
      userEmployeeId: authenticatedEmployee.associateId,
    });
  }

  selectTeam(salesTeam: any, filterForm: FormGroup) {
    let selectedTeamSalespeople = salesTeam.children.map((salesperson: any) => salesperson.EmployeeId);
    let selectedSalespeople = filterForm.get('salesPersonId').value;

    let existingCheck = selectedSalespeople.some((el: any) => selectedTeamSalespeople.includes(el));

    selectedTeamSalespeople.forEach((salesPersonId: string) => {
      if (existingCheck) {
        if (selectedSalespeople.includes(salesPersonId)) {
          selectedSalespeople.splice(selectedSalespeople.indexOf(salesPersonId, 0), 1);
        }
      } else {
        if (!selectedSalespeople.includes(salesPersonId)) {
          selectedSalespeople.push(salesPersonId);
        }
      }
    });

    filterForm.patchValue({
      salesPersonId: selectedSalespeople,
    });
  }

  trackByFunction(index: number, item: Unsold) {
    return item.id;
  }
}
