<div>
  <div fxLayout="column">
    <div id="header" fxLayout="row" class="header-container" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div fxLayout="column">
          <span class="mat-subheading-2">Appointment {{ serviceAppointment?.id }}</span>
        </div>
      </div>
    </div>
    <form fxLayout="row wrap" [formGroup]="appointmentForm">
      <mat-form-field fxFlex="50">
        <input matInput autocomplete="off" formControlName="dateTime" [matDatepicker]="picker" placeholder="Date" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input matInput type="time" name="time" formControlName="appointmentTime" placeholder="Time" />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input matInput type="number" name="odometer" formControlName="odometerIn" placeholder="Odometer" />
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="serviceAppointment.embedded?.cases?.length > 0" class="result-container" fxLayout="column">
    <div>
      <div>
        <h2>Cases</h2>
      </div>
      <div class="flex flex-row w-full place-content-between"
        *ngFor="let case of serviceAppointment.embedded.cases | orderBy:['sequence']">
        <div data-cy="case-sequence-number">
          <span class="test-container">{{ case.sequence }}</span>
        </div>

        <div class="flex flex-col w-full place-content-between">
          <div class="flex flex-col">
            <div class="flex flex-row w-full place-content-between" data-cy="case-heading">
              <span style="color: #202124" class="mat-subheading-2 font-medium">
                {{ case.opCode ? case.opCode + ' - ' : '' }}{{ case.requestDescription }}
              </span>
            </div>
          </div>

          <div class="flex flex-col">
            <div class="flex flex-row place-content-between" style="color: rgb(82, 82, 82)">
              <div class="mb-1 flex flex-row place-items-center">
                <span class="primary-bullet">·</span>
                <div>
                  <span data-cy="technician-group-type">{{ case.technicianGroupName }}</span>
                </div>
              </div>

              <div class="flex flex-col place-content-center">
                <span *ngIf="case.rate; else needsAssement" class="ml-0.5">{{ case.rate | currency }}</span>
                <ng-template #needsAssement>
                  <span data-cy="need-assessment-span">Needs Assessment</span>
                </ng-template>
              </div>
            </div>
            <div class="flex flex-row place-items-center" style="color: rgb(82, 82, 82)" *ngIf="case.reservedParts">
              <div>
                <span class="primary-bullet">·</span>
                <div>
                  <span class="font-medium">Parts reserved on this case.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
