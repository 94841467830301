export class Location {
  areaCode: string = null;
  countryId: string = null;
  description: string = null;
  id?: string = null;
  phonePrefix: string = null;
  postalCode: string = null;
  provStateId: string = null;

  constructor(obj?: Location) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
