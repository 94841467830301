import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Associate, PurchaseOrderDetail, PurchaseOrderHeader, Sale } from '@quorum/models/xs-resource';
import { DeepCopyPipe, MomentPipe } from '@quorum/common-pipes';
import { ActivitiesStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { createFormGroupFromObject } from 'libs/sha-services/src/lib/createFormGroupFromObject';
import { ApiService } from '@quorum/api';
import { ObserversModule } from '@angular/cdk/observers';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PurchaseOrderHeaderQueryParameters, SaleQueryParameters } from '@quorum/models/xs-query';

@Component({
  selector: 'crm-delivery-card',
  templateUrl: './delivery-card.component.html',
  styleUrls: ['./delivery-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryCardComponent implements OnInit, OnChanges {
  @Input() delivery: Sale;
  deliveryCopy: Sale = new Sale();
  newTime: Date;
  deliveryForm: FormGroup;

  constructor(private activitiesStateService: ActivitiesStateService, private fb: FormBuilder) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.delivery) {
      this.deliveryCopy = new DeepCopyPipe().transform(changes.delivery.currentValue);
      this.deliveryForm = createFormGroupFromObject(this.deliveryCopy);
      let apptTime = new Date(this.deliveryForm.get('deliveryDate').value).toTimeString().substring(0, 5);
      this.deliveryForm.addControl('deliveryTime', new FormControl(apptTime));
    }
  }

  updateDelivery(delivery: Sale): void {
    let timeDate = new MomentPipe().timeDate(this.deliveryForm.get('deliveryTime').value, 'hh:mm');
    this.newTime = new Date(this.deliveryForm.get('deliveryDate').value);
    this.newTime.setHours(timeDate.getHours());
    this.newTime.setMinutes(timeDate.getMinutes());
    this.deliveryForm.get('deliveryDate').setValue(this.newTime.toISOString());
    let queryParameters = new SaleQueryParameters({
      embed:'vehicle,customerAssociate,status,salespersonAssociate,salesManagerAssociate,businessManagerAssociate'
    })
    this.activitiesStateService.updateDeliveryOnServer(this.deliveryForm.value, queryParameters);
  }
}
