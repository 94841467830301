export class SubPrimeQueryParameters {
  actioned?: string;
  availableDateFrom?: string;
  availableDateTo?: string;
  equityThreshold?: number;
  inEquity?: string;
  interestRateFrom?: number;
  interestRateTo?: number;
  originalSalespersonId?: string;
  page?: number;
  pageSize?: number;
  paymentsMadeFrom?: number;
  paymentsMadeTo?: number;
  rooftopId?: string;
  salespersonId?: string[];
  saleTypeId?: string[];
  sort?: string;
  termFrom?: number;
  termTo?: number;
  userEmployeeId?: string;
  vehicleId?: string;

  constructor(obj?: SubPrimeQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
