import { Action } from '@ngrx/store';
import { PurchaseOrderHeaderQueryParameters } from '@quorum/models/xs-query';
import { PurchaseOrderDetail, PurchaseOrderHeader } from '@quorum/models/xs-resource';

export const GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER = '[CRM] Get Activity Purchase Order Details From Server';
export class GetActivityPurchaseOrderDetailsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: PurchaseOrderHeader[]) {}
}

export const GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER_SUCCESS =
  '[CRM] Get Activity Purchase Order Details From Server Success';
export class GetActivityPurchaseOrderDetailsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: PurchaseOrderDetail[]) {}
}

export const GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER_FAILURE =
  '[CRM] Get Activity Purchase Order Details From Server Failure';
export class GetActivityPurchaseOrderDetailsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_ACTIVITY_PURCHASE_ORDER_DETAILS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER = '[CRM] Get Activity Purchase Order Headers From Server';
export class GetActivityPurchaseOrderHeadersFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: PurchaseOrderHeaderQueryParameters) {}
}

export const GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER_SUCCESS =
  '[CRM] Get Activity Purchase Order Headers From Server Success';
export class GetActivityPurchaseOrderHeadersFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: PurchaseOrderHeader[]) {}
}

export const GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER_FAILURE =
  '[CRM] Get Activity Purchase Order Headers From Server Failure';
export class GetActivityPurchaseOrderHeadersFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_ACTIVITY_PURCHASE_ORDER_HEADERS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectVehicleOfInterestAction =
  | GetActivityPurchaseOrderDetailsFromServer
  | GetActivityPurchaseOrderDetailsFromServerSuccess
  | GetActivityPurchaseOrderDetailsFromServerFailure
  | GetActivityPurchaseOrderHeadersFromServer
  | GetActivityPurchaseOrderHeadersFromServerSuccess
  | GetActivityPurchaseOrderHeadersFromServerFailure;
