import { ServiceAppointment as Appointment } from '@quorum/models/xs-resource';
// eslint-disable-next-line max-len
import { ServiceAppointmentQueryParameters as AppointmentQueryParameters } from '@quorum/models/xs-query';
import { Action } from '@ngrx/store';

export const GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER = '[CRM] Get Prospect Service Appointments From Server';
export class GetProspectServiceAppointmentsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: AppointmentQueryParameters) {}
}

export const GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER_SUCCESS =
  '[CRM] Get Prospect Service Appointments From Server Success';
export class GetProspectServiceAppointmentsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { serviceAppointments: Appointment[] }) {}
}

export const GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER_FAILURE =
  '[CRM] Get Prospect Service Appointments From Server Failure';
export class GetProspectServiceAppointmentsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_PROSPECT_SERVICE_APPOINTMENTS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ServiceAppointmentsAction =
  | GetProspectServiceAppointmentsFromServer
  | GetProspectServiceAppointmentsFromServerSuccess
  | GetProspectServiceAppointmentsFromServerFailure;
