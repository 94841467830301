import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CrmAppointment as SalesAppointment,
  ServiceAppointment,
  DealerPromotion,
  Prospect,
  Task,
} from '@quorum/models/xs-resource';
import { OpenSalesState } from './open-sales/open-sales.interfaces';
import { ProspectActionsState } from './prospect-actions/prospect-actions.interfaces';
import { ProspectDealerPromotionsState } from './prospect-dealer-promotions/prospect-dealer-promotions.interfaces';
import { ProspectDeliveriesState } from './prospect-deliveries/prospect-deliveries.interfaces';
import { ProspectSalesAppointmentsState } from './prospect-sales-appointments/prospect-sales-appointments.interfaces';
// eslint-disable-next-line max-len
import { ProspectServiceAppointmentsState } from './prospect-service-appointments/prospect-service-appointments.interfaces';
import { ProspectTasksState } from './prospect-tasks/prospect-tasks.interfaces';
import { ActivityPurchaseOrderDetailsState } from './purchase-order-details/purchase-order-details.interfaces';
import { ProspectVehicleOfInterestState } from './prospect-vehicle-of-interest/prospect-vehicle-of-interest.interfaces';
import { ResponseLeadsState } from './response-leads/response-leads.interfaces';
import { ServiceHistoryState } from './service-history/service-history.interfaces';
import { VehiclesState } from './vehicles/vehicles.interfaces';

export interface SelectedActivityState {
  readonly type: string;
  readonly activity: DealerPromotion | SalesAppointment | ServiceAppointment | Task;
  readonly prospect: Prospect;
  readonly prospectAggregate: any;
  readonly vehicles: VehiclesState;
  readonly vehicleAggregate: any;
  readonly vehicleAggregateIsLoading: boolean;
  readonly vehicleServiceHistory: ServiceHistoryState;
  readonly openSales: OpenSalesState;
  readonly prospectVehicleOfInterest: ProspectVehicleOfInterestState;
  readonly prospectTasks: ProspectTasksState;
  readonly prospectSalesAppointments: ProspectSalesAppointmentsState;
  readonly prospectDealerPromotions: ProspectDealerPromotionsState;
  readonly prospectServiceAppointments: ProspectServiceAppointmentsState;
  readonly prospectDeliveries: ProspectDeliveriesState;
  readonly prospectActions: ProspectActionsState;
  readonly prospectNotes: any;
  readonly responseLeads: ResponseLeadsState;
  readonly selectedProspectIsLoading: boolean;
  readonly activityPurchaseOrderDetails: ActivityPurchaseOrderDetailsState;
}

export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const selectedProspectSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospect
);

export const selectedActivitySelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.activity
);

export const selectedActivityTypeSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.type
);

export const selectedProspectAggregateSelector = createSelector(salesplannerLegacyFeatureSelector, (state: any) => {
  return state.selectedActivity.prospectAggregate;
});

export const selectedProspectNotesSelector = createSelector(salesplannerLegacyFeatureSelector, (state: any) => {
  return state.selectedActivity.prospectNotes;
});

export const selectedProspectIsLoadingSelector = createSelector(salesplannerLegacyFeatureSelector, (state: any) => {
  return state.selectedActivity.selectedProspectIsLoading;
});

export const selectedProspectVehicleAggregateIsLoadingSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => {
    return state.selectedActivity.vehicleAggregateIsLoading;
  }
);
