import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import * as fromDirectMessageTemplates from './direct-message-templates.actions';
import { DirectMessageTemplatesState } from './direct-message-templates.interfaces';
import { DirectMessageTemplate } from '@quorum/models/xs-resource';
import { map } from 'rxjs/operators';
@Injectable()
export class DirectMessageTemplatesEffects {
  
  getDirectMessageTemplatesFromServer = createEffect(() => this.dataPersistence.fetch(
    fromDirectMessageTemplates.GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER,
    {
      run: (action: fromDirectMessageTemplates.GetDirectMessageTemplatesFromServer, state: any) => {
        if (state.crmDropdowns.directMessageTemplates.ids.length == 0) {
          return this.apiService
            .get<DirectMessageTemplate[]>(`v/1/communicator/message-templates/direct`, {
              params: action.payload,
            })
            .pipe(
              map((directMessageTemplates) => {
                return new fromDirectMessageTemplates.GetDirectMessageTemplatesFromServerSuccess({
                  directMessageTemplates: directMessageTemplates,
                });
              })
            );
        }
      },
      onError: (action: fromDirectMessageTemplates.GetDirectMessageTemplatesFromServer, error) => {
        console.error('Error', error);
      },
    }
  ));

  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<DirectMessageTemplatesState>,
    private apiService: ApiService
  ) {}
}
