export class UnsoldQueryParameters {
  actioned?: string;
  categoryId?: string;
  genericModelId?: string;
  id?: string;
  lastAction?: string;
  maxYear?: number;
  minYear?: number;
  page?: number;
  pageSize?: number;
  prospectStatusId?: string[];
  rooftopId?: string;
  salesPersonId?: string[];
  search?: string;
  sort?: string;
  subCategoryId?: string;
  subTypeId?: string;
  unitNumber?: string;
  userEmployeeId?: string;
  vehicleDescription?: string;
  vehicleId?: string;
  vehicleMake?: string;
  vehicleModelCode?: string;

  constructor(obj?: UnsoldQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
