import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { OemRooftopLink } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';
export interface RooftopsState extends EntityState<OemRooftopLink> {
  isLoading: boolean;
}

export const rooftopsAdapter: EntityAdapter<OemRooftopLink> = createEntityAdapter<OemRooftopLink>({
  selectId: (rooftop: OemRooftopLink) => rooftop.id,
});

export const initialState: RooftopsState = rooftopsAdapter.getInitialState({ isLoading: true });
export const rooftopsSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.rooftops);

export const rooftopsLoadingSelector = createSelector(rooftopsSelector, (state: any) => state.isLoading);

export const { selectIds, selectEntities, selectAll, selectTotal } = rooftopsAdapter.getSelectors(rooftopsSelector);
