import { ServiceAppointment as Appointment } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface ServiceAppointmentsState extends EntityState<Appointment> {
  loading: boolean;
}

export const serviceAppointmentsAdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: (serviceAppointment: Appointment) => serviceAppointment.id
});

export const initialState: ServiceAppointmentsState = serviceAppointmentsAdapter.getInitialState({ loading: null });
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const serviceAppointmentsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.activities.serviceAppointments
);

export const isServiceAppointmentsLoadingSelector = createSelector(
  serviceAppointmentsSelector,
  (state: any) => state.loading
);
export const { selectIds, selectEntities, selectAll, selectTotal } = serviceAppointmentsAdapter.getSelectors(
  serviceAppointmentsSelector
);
