import { VehicleColour as Colour } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface VehicleColoursState extends EntityState<Colour> {
  loading: boolean;
}

export const vehicleColoursAdapter: EntityAdapter<Colour> = createEntityAdapter<Colour>({
  selectId: (colour: Colour) => colour.id
});

export const initialState: VehicleColoursState = vehicleColoursAdapter.getInitialState({ loading: true });

export const vehicleColoursSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleColours
);

export const vehicleColoursLoadingSelector = createSelector(
  vehicleColoursSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleColoursAdapter.getSelectors(
  vehicleColoursSelector
);
