import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CrmAppointment as Appointment } from '@quorum/models/xs-resource';

export interface SalesAppointmentsState extends EntityState<Appointment> {
  loading: boolean;
}

export const salesAppointmentsdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: (salesAppointment: Appointment) => salesAppointment.id,
});

export const initialState: SalesAppointmentsState = salesAppointmentsdapter.getInitialState({ loading: null });
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const salesAppointmentsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.activities.salesAppointments
);

export const isSalesAppointmentsLoadingSelector = createSelector(
  salesAppointmentsSelector,
  (state: any) => state.loading
);
export const { selectIds, selectEntities, selectAll, selectTotal } =
  salesAppointmentsdapter.getSelectors(salesAppointmentsSelector);
