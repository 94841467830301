import * as fromUISettings from './ui.actions';
import { initialUIState } from './ui.init';
import { SalesPlannerUIState } from './ui.interfaces';

export function uiSettingsReducer(
  state = initialUIState,
  action: fromUISettings.UISettingsAction
): SalesPlannerUIState {
  switch (action.type) {
    case fromUISettings.TOGGLE_OPEN_ACTIVITES_PANEL_IN_STATE: {
      return {
        ...state,
        openActivitiesVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_ACTIONS_PANEL_IN_STATE: {
      return {
        ...state,
        actionsVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_CLOSED_ACTIVITES_PANEL_IN_STATE: {
      return {
        ...state,
        closedActivitiesVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_PURCHASE_ORDER_DETAILS_PANEL_IN_STATE: {
      return {
        ...state,
        purchaseOrderDetailsVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_RESPONSE_LEADS_PANEL_IN_STATE: {
      return {
        ...state,
        responseLeadsVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_VEHICLE_OF_INTEREST_PANEL_IN_STATE: {
      return {
        ...state,
        vehicleOfInterestVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_OPEN_SALES_PANEL_IN_STATE: {
      return {
        ...state,
        openSalesVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_NOTES_PANEL_IN_STATE: {
      return {
        ...state,
        notesVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_SERVICE_HISTORY_PANEL_IN_STATE: {
      return {
        ...state,
        serviceHistoryVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_VEHICLE_AGGREGATE_PANEL_IN_STATE: {
      return {
        ...state,
        vehicleAggregateVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_PROSPECT_DETAILS_PANEL_IN_STATE: {
      return {
        ...state,
        prospectDetailsVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_PROSPECT_AGGREGATE_PANEL_IN_STATE: {
      return {
        ...state,
        prospectAggregateVisible: action.payload,
      };
    }
    case fromUISettings.TOGGLE_SIDENAV_IN_STATE: {
      return {
        ...state,
        sidenavVisible: action.payload,
      };
    }
    case fromUISettings.GET_UNASSIGNED_PROSPECT_LEADS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectLeads: { unassigned: action.payload, unmatched: state.prospectLeads.unmatched },
      };
    }

    case fromUISettings.GET_UNMATCHED_PROSPECT_LEADS_FROM_SERVER_SUCCESS: {
      return {
        ...state,
        prospectLeads: { unassigned: state.prospectLeads.unassigned, unmatched: action.payload },
      };
    }
    case fromUISettings.RESET_STATE: {
      return initialUIState;
    }
    default: {
      return state;
    }
  }
}

export function reducer(state: SalesPlannerUIState, action: fromUISettings.UISettingsAction): SalesPlannerUIState {
  let newState: SalesPlannerUIState;
  if (state) {
    newState = uiSettingsReducer(state, action);
    localStorage.setItem('salesplannerUI', JSON.stringify(newState));
  } else {
    let localState = localStorage.getItem('salesplannerUI');
    let initialState: SalesPlannerUIState;
    if (localState) {
      initialState = JSON.parse(localState);
      Object.keys(initialUIState).forEach((setting: string) => {
        initialState[setting] = initialState[setting] || initialUIState[setting];
      });
    } else {
      initialState = initialUIState;
    }
    newState = uiSettingsReducer(initialState, action);
    localStorage.setItem('salesplannerUI', JSON.stringify(newState));
  }
  return newState;
}
