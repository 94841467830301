export class ProspectReportQueryParameters {
  actionId?: string[];
  assigned?: string;
  associateEmailAddress?: string;
  associateId?: string[];
  categoryId?: string[];
  contractDateFrom?: string;
  contractDateTo?: string;
  customerTypeId?: string[];
  excludeFreeform?: string;
  id?: string;
  matched?: string;
  matchedDateFrom?: string;
  matchedDateTo?: string;
  newSaleId?: string;
  openTasks?: string;
  page?: number;
  pageSize?: number;
  prospectAvailableDateFrom?: string;
  prospectAvailableDateTo?: string;
  prospectChannelId?: string[];
  prospectEmailAddress?: string;
  prospectEnteredDateFrom?: string;
  prospectEnteredDateTo?: string;
  prospectPhoneNumber?: string;
  prospectSourceId?: string[];
  prospectStatusId?: string[];
  prospectTypeId?: string[];
  prospectUpdatedDateFrom?: string;
  prospectUpdatedDateTo?: string;
  provStateId?: string;
  rooftopId?: string;
  saleId?: string;
  salespersonId?: string[];
  search?: string;
  showRecentRetention?: string;
  showRecentShowroom?: string;
  sort?: string;
  userEmployeeId?: string;
  vendorAssociateId?: string[];
  vendorId?: string[];

  constructor(obj?: ProspectReportQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
