import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { TaskQueryParameters } from '@quorum/models/xs-query';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as fromTasks from './tasks.actions';
import { isTasksLoadingSelector, selectAll as selectProspectsCombined } from './tasks.interfaces';

@Injectable()
export class TasksStateService {
  constructor(private store: Store<any>) {}

  selectTasksLoading() {
    return this.store.pipe(select(isTasksLoadingSelector));
  }

  selectTasksCombined() {
    return combineLatest(this.store.pipe(select(selectProspectsCombined)), this.selectTasksLoading()).pipe(
      map(([tasks, isLoading]) => {
        return { tasks, isLoading };
      }),
      filter((result) => {
        return result.isLoading == false;
      }),
      map((result) => {
        return new DeepCopyPipe().transform(result);
      })
    );
  }

  getTasksFromServer(queryParams: TaskQueryParameters) {
    this.store.dispatch(new fromTasks.GetDealerTasksFromServer(queryParams));
  }

  clearTasksFromState() {
    this.store.dispatch(new fromTasks.ClearTasksFromState());
  }

  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
}
