export class ActivityQueryParameters {
  activityTypes?: string[];
  employeeId: string[];
  startDate?: Date;
  overdueActivities?: boolean;

  constructor(obj?: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
