import { VehicleMake } from '@quorum/models/xs-resource';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface VehicleMakesState extends EntityState<VehicleMake> {
  loading: boolean;
}

export const vehicleMakesAdapter: EntityAdapter<VehicleMake> = createEntityAdapter<VehicleMake>({
  selectId: (vehicleMake: VehicleMake) => vehicleMake.id
});

export const initialState: VehicleMakesState = vehicleMakesAdapter.getInitialState({ loading: true });
export const vehicleMakesSelector = createSelector(
  crmDropdownsFeatureSelector,
  (state: any) => state.vehicleMakes
);

export const vehicleMakesLoadingSelector = createSelector(
  vehicleMakesSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } = vehicleMakesAdapter.getSelectors(
  vehicleMakesSelector
);
