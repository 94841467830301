import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';
import { ApiService } from '@quorum/api';

import * as CommunicatorActions from './communicator.actions';
import { map, withLatestFrom } from 'rxjs/operators';
import { EventTypes } from '@models/server-common';
import { Store } from '@ngrx/store';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { Conversation, Member, Event } from '@quorum/models/xs-resource';

@Injectable()
export class CommunicatorEffects {
  sendCommunicatorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.sendCommunicatorMessage),
      fetch({
        run: (action: any) => {
          return CommunicatorActions.checkIfConversationExists({
            conversationQueryParameters: action.conversationQueryParameters,
            conversation: action.conversation,
            members: action.members,
            event: action.event,
            optionalHeader: action.optionalHeader,
          });
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.checkIfConversationExistsFailure({ error });
        },
      })
    )
  );

  checkIfConversationExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.checkIfConversationExists),
      fetch({
        run: (action) => {
          return this.apiService
            .get<Conversation[]>('communicator/conversations', {
              params: action.conversationQueryParameters,
            })
            .pipe(
              map((conversations: Conversation[]) => {
                return CommunicatorActions.checkIfConversationExistsSuccess({
                  conversation: conversations.length > 0 ? conversations[0] : action.conversation,
                  members: action.members,
                  event: action.event,
                  optionalHeader: action.optionalHeader,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.checkIfConversationExistsFailure({ error });
        },
      })
    )
  );

  checkIfConversationExistsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.checkIfConversationExistsSuccess),
      withLatestFrom(this.store$),
      fetch({
        run: (action: any, store: any) => {
          if (action.conversation.hasOwnProperty('id')) {
            return CommunicatorActions.sendMessageToConversation({
              event: { ...action.event, conversationId: action.conversation.id },
              optionalHeader: action.optionalHeader,
            });
          } else {
            return CommunicatorActions.createConversation({
              conversation: action.conversation,
              members: action.members,
              event: action.event,
              optionalHeader: action.optionalHeader,
            });
          }
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.checkIfConversationExistsFailure({ error });
        },
      })
    )
  );

  sendMessageToConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.sendMessageToConversation),
      fetch({
        run: (action) => {
          return this.apiService
            .post<Event>(`communicator/conversations/${action.event.conversationId}/events`, action.event, {
              headers: action.optionalHeader,
            })
            .pipe(
              map((event: Event) => {
                return CommunicatorActions.sendMessageToConversationSuccess({
                  event: event,
                  optionalHeader: action.optionalHeader,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.checkIfConversationExistsFailure({ error });
        },
      })
    )
  );

  createConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.createConversation),
      fetch({
        run: (action) => {
          return this.apiService.post<Conversation>(`communicator/conversations`, action.conversation).pipe(
            map((conversation: Conversation) => {
              let members: any[] = [];

              action.members.forEach((member: Member, index: number) => {
                members.push({ id: index, body: { ...member, conversationId: conversation.id } });
              });

              return CommunicatorActions.createConversationSuccess({
                conversation: conversation,
                members: members,
                event: { ...action.event, conversationId: conversation.id },
                optionalHeader: action.optionalHeader,
              });
            })
          );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.createConversationFailure({ error });
        },
      })
    )
  );

  createConversationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.createConversationSuccess),
      fetch({
        run: (action) => {
          return CommunicatorActions.addMembersToConversation({
            members: action.members,
            event: action.event,
            optionalHeader: action.optionalHeader,
          });
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.createConversationFailure({ error });
        },
      })
    )
  );

  addMembersToConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.addMembersToConversation),
      fetch({
        run: (action) => {
          return this.apiService
            .post<Member[]>(
              `communicator/conversations/${action.members[0].body.conversationId.toString()}/members`,
              action.members
            )
            .pipe(
              map((members: Member[]) => {
                return CommunicatorActions.addMembersToConversationSuccess({
                  members: members,
                  event: action.event,
                  optionalHeader: action.optionalHeader,
                });
              })
            );
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.addMembersToConversationFailure({ error });
        },
      })
    )
  );

  addMembersToConversationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicatorActions.addMembersToConversationSuccess),
      fetch({
        run: (action) => {
          return CommunicatorActions.sendMessageToConversation({
            event: action.event,
            optionalHeader: action.optionalHeader,
          });
        },

        onError: (action, error) => {
          console.error('Error', error);
          return CommunicatorActions.addMembersToConversationFailure({ error });
        },
      })
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store$: Store<any>,
    @Inject(LOCALE_ID) private locale: string
  ) {}
}
