import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RouterStateService } from '@quorum/sha-router';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'sha-add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styleUrls: ['./add-vehicle.component.scss']
})
export class AddVehicleComponent implements OnInit {
  @Input() dropdowns: any;
  @Input() mainManufacturer: string;
  @Output() formChanged: EventEmitter<any> = new EventEmitter<any>();
  vehicleForm: FormGroup;
  addVehicleForm: any = this.fb.group({
    vin: ['', [Validators.required]],
    manufacturer: [''],
    mileage: ['', [Validators.required]],
    color: ['', [Validators.required]]
  });
  newVINValue: Subject<string> = new Subject<string>();

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private routerStateService: RouterStateService) {}

  ngOnInit() {
    this.addVehicleForm.patchValue({ manufacturer: this.mainManufacturer });
    this.addVehicleForm.valueChanges.pipe(debounceTime(1000)).subscribe((formData: string) => {
      this.formChanged.emit({
        value: this.addVehicleForm.value,
        valid: this.addVehicleForm.status === 'VALID' ? true : false
      });
    });
  }

  backClicked() {
    this.routerStateService.go(['..'], { queryParams: { tab: 'vehicle' }, relativeTo: this.route });
  }

  resetAddVehicleForm() {
    this.addVehicleForm.patchValue({
      vin: '',
      manufacturer: '',
      mileage: '',
      color: ''
    });
  }
}
