import { Action } from '@ngrx/store';

export const GET_CRM_SETTINGS_FROM_SERVER = '[CRM] Get CRM Settings From Server';
export class GetCrmSettingsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_CRM_SETTINGS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_CRM_SETTINGS_FROM_SERVER_SUCCESS = '[CRM] Get CRM Settings From Server Success';
export class GetCrmSettingsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_CRM_SETTINGS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const GET_CRM_SETTINGS_FROM_SERVER_FAILURE = '[CRM] Get CRM Settings From Server Failure';
export class GetCrmSettingsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_CRM_SETTINGS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_CRM_SETTINGS_IN_STATE = '[CRM] Update Crm Settings In State';
export class UpdateCrmSettingsInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_CRM_SETTINGS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export type CrmSettingsAction =
  | GetCrmSettingsFromServer
  | GetCrmSettingsFromServerSuccess
  | GetCrmSettingsFromServerFailure
  | UpdateCrmSettingsInState;
