<div>
  <mat-toolbar class="mat-elevation-z3">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button class="toolbar-button" (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <span fxFlex class="toolbar-title">Lead Generator</span>
      <button *ngIf="dropdowns$ | async as dropdowns" mat-icon-button (click)="openSettingsDialog(settings, dropdowns)"
        class="toolbar-button">
        <mat-icon>settings</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content-container">
    <div class="main-container" *ngIf="previousReportQueryParams$ | async as previousReportParams">
      <nav mat-tab-nav-bar>
        <a mat-tab-link routerLink="available" [queryParams]="previousReportParams.available"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','available']), false)">Available</a>
        <!-- <a mat-tab-link routerLink="in-equity" [queryParams]="previousReportParams['in-equity']" [active]="router.isActive(router.createUrlTree(['crm','lead-generator','in-equity']), false)">In
          Equity</a> -->
        <a mat-tab-link routerLink="over-mileage-lease" [queryParams]="previousReportParams['over-mileage-lease']"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','over-mileage-lease']), false)">Over
          Mileage</a>
        <a mat-tab-link routerLink="sub-prime" [queryParams]="previousReportParams['sub-prime']"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','sub-prime']), false)">Sub-Prime</a>
        <a mat-tab-link routerLink="service-appointment" [queryParams]="previousReportParams['service-appointment']"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','service-appointment']), false)">Service
          Appointments</a>
        <a mat-tab-link routerLink="customers" [queryParams]="previousReportParams['customers']"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','customers']), false)">Customers</a>
        <a *ngIf="displayUnsoldReport" mat-tab-link routerLink="unsold-vehicles"
          [queryParams]="previousReportParams['unsold-vehicles']"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','unsold-vehicles']), false)">Unsold
        </a>
        <a mat-tab-link routerLink="vehicles" [queryParams]="previousReportParams['vehicles']"
          [active]="router.isActive(router.createUrlTree(['crm','lead-generator','vehicles']), false)">Vehicles
        </a>
      </nav>
      <router-outlet></router-outlet>

      <div fxLayoutAlign="center center"
        *ngIf="!router.isActive(router.createUrlTree(['crm','lead-generator','unsold-vehicles']), false)">
        <img src=" assets/bb-logo.png" style="height:64px;margin-right:8px;">
      </div>
    </div>
  </div>
</div>