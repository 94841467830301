import { Action } from '@ngrx/store';
import { ProspectActionQueryParameters } from '@quorum/models/xs-query';
import { Prospect, ProspectAction } from '@quorum/models/xs-resource';

export const GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER = '[CRM] Get Selected Prospect Actions From Server';
export class GetSelectedProspectActionsFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospectId: number; prospectActionQueryParams: ProspectActionQueryParameters }) {}
}

export const GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Actions From Server Success';
export class GetSelectedProspectActionsFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { actions: ProspectAction[] }) {}
}

export const GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Actions From Server Failure';
export class GetSelectedProspectActionsFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_ACTIONS_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER = '[CRM] Create Selected Prospect Action On Server';
export class CreateSelectedProspectActionOnServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospectAction: ProspectAction; prospect?: Prospect }) {}
}

export const CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER_SUCCESS =
  '[CRM] Create Selected Prospect Action On Server Success';
export class CreateSelectedProspectActionOnServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: ProspectAction) {}
}

export const CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER_FAILURE =
  '[CRM] Create Selected Prospect Action On Server Failure';
export class CreateSelectedProspectActionOnServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_ACTION_ON_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER = '[CRM] Delete Selected Prospect Action From Server';
export class DeleteSelectedProspectActionFromServer implements Action {
  readonly feature = 'crm';
  readonly type = DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ProspectAction) {}
}

export const DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER_SUCCESS =
  '[CRM] Delete Selected Prospect Action From Server Success';
export class DeleteSelectedProspectActionFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: number) {}
}

export const DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER_FAILURE =
  '[CRM] Delete Selected Prospect Action From Server Failure';
export class DeleteSelectedProspectActionFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = DELETE_SELECTED_PROSPECT_ACTION_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectActionsAction =
  | GetSelectedProspectActionsFromServer
  | GetSelectedProspectActionsFromServerSuccess
  | GetSelectedProspectActionsFromServerFailure
  | CreateSelectedProspectActionOnServer
  | CreateSelectedProspectActionOnServerSuccess
  | CreateSelectedProspectActionOnServerFailure
  | DeleteSelectedProspectActionFromServer
  | DeleteSelectedProspectActionFromServerSuccess
  | DeleteSelectedProspectActionFromServerFailure;
