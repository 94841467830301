import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Channel } from '@quorum/models/xs-resource';
import { crmDropdownsFeatureSelector } from '../crm-dropdowns-feature.selector';

export interface ChannelsState extends EntityState<Channel> {}

export const channelsAdapter: EntityAdapter<Channel> = createEntityAdapter<Channel>({
  selectId: (channel: Channel) => channel.id,
});

export const initialState: ChannelsState = channelsAdapter.getInitialState({ loading: true });

export const channelsSelector = createSelector(crmDropdownsFeatureSelector, (state: any) => state.channels);

export const { selectIds, selectEntities, selectAll, selectTotal } = channelsAdapter.getSelectors(channelsSelector);
