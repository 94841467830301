<div fxLayout="column" *ngIf="{crmDropdowns: vehicleData.crmDropdowns$ | async, vehicle: vehicleData.vehicle } as data">
  <h2 class="pl-6 pt-6 m-0" mat-dialog-title>
    Vehicle ID: {{data.vehicle.id}}
  </h2>
  <div class="p-6">
    <mat-dialog-content>
      <form fxLayout="column" fxLayoutAlign="start">
        <h3>Vehicle Details</h3>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="Rooftop" name="rooftopId" [disabled]='true' [value]="data.vehicle.rooftopId">
              <mat-option *ngFor="let rooftop of data.crmDropdowns.rooftops" [value]="rooftop.id">
                {{ rooftop.displayCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Vehicle ID" [value]="data.vehicle.id" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="V/S" name="saleStays" [disabled]='true'
              [value]="data.vehicle.embedded?.sale?.statusId">
              <mat-option *ngFor="let saleStay of saleStays" [value]="saleStay.id">
                {{ saleStay.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="Vehicle Status" name="statusCode" [disabled]='true'
              [value]="data.vehicle.statusCode">
              <mat-option *ngFor="let status of data.crmDropdowns.vehicleStatuses" [value]="status.code">
                {{ status.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always" floatLabel="always">
            <mat-select placeholder="Vehicle Sub Category" name="subCategory" [disabled]='true'
              [value]="data.vehicle.subCategoryId">
              <mat-option *ngFor="let subcategory of data.crmDropdowns.vehicleSubcategories" [value]="subcategory.id">
                {{ subcategory.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="Vehicle Sub Type" name="subType" [disabled]='true'
              [value]="data.vehicle.subTypeId">
              <mat-option *ngFor="let subtype of data.crmDropdowns.vehicleSubTypes" [value]="subtype.id">
                {{ subtype.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="py-4"></div>
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Manufacturer" [value]="data.vehicle.manufacturerId" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Year" [value]="data.vehicle.year" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Make" [value]="data.vehicle.make" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Model Code" [value]="data.vehicle.modelCode" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Description" [value]="data.vehicle.model" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="VIN" [value]="data.vehicle.vin" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="PEG" [value]="data.vehicle.pegCode" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Engine" [value]="data.vehicle.engine" />
          </mat-form-field>
        </div>
        <div class="py-6"></div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Detail Color" [value]="data.vehicle.exteriorColour" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Interior Color"
              [value]="data.vehicle.interiorColourDescription" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="Prospect Colour" name="prospectColour" [disabled]='true'
              [value]="data.vehicle.prospectColourId">
              <mat-option *ngFor="let colour of data.crmDropdowns.vehicleColours" [value]="colour.id">
                {{ colour.colourDescription }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Unit #" [value]="data.vehicle.stockNumber" />
          </mat-form-field>
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Trim" [value]="data.vehicle.trimLevel" />
          </mat-form-field>
        </div>

        <div class="py-4"></div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Owner"
              [value]="vehicleData.associateFirstName? vehicleData.associateFirstName +', '+ vehicleData.associateLastName : vehicleData.freeformFirstName +', '+ vehicleData.freeformLastName" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Employee Role" value="Salesperson" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Employee Name"
              [value]="vehicleData.salespersonFirstName ? vehicleData.salespersonLastName + ', ' + vehicleData.salespersonFirstName :  null" />
          </mat-form-field>
        </div>
        <div class="py-4">
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="In-Service Mileage" [value]="data.vehicle.deliveryMileage" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="In-Service Date"
              [value]="data.vehicle.deliveryDate | date: 'mediumDate'" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Current Mileage" [value]="data.vehicle.currentMileage" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Last Mileage Date"
              [value]="data.vehicle.lastMileageDate | date: 'mediumDate'" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Available Date"
              [value]="data.vehicle.availableDate | date: 'mediumDate'" />
          </mat-form-field>

        </div>

        <div class="py-4"></div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="payTypes">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="P1 Price" name="payType1" [disabled]='true' [value]="data.vehicle.payType1">
              <mat-option *ngFor="let payType of payTypes" [value]="payType.id">
                {{ payType.description }}

              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="P1 Amount" [value]="data.vehicle.price1Amount | currency" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="P2 Price" name="payType2" [disabled]='true' [value]="data.vehicle.payType2">
              <mat-option *ngFor="let payType of payTypes" [value]="payType.id">
                {{ payType.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="P2 Amount" [value]="data.vehicle.price2Amount | currency" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <mat-select placeholder="P3 Price" name="payType3" [disabled]='true' [value]="data.vehicle.payType3">
              <mat-option *ngFor="let payType of payTypes" [value]="payType.id">
                {{ payType.description }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="P3 Amount" [value]="data.vehicle.price3Amount | currency" />
          </mat-form-field>
        </div>

        <div class="py-4"></div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Received Date"
              [value]="data.vehicle.receivedDate | date: 'mediumDate'" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Days in Stock" [value]="data.vehicle.daysInStock" />
          </mat-form-field>

          <mat-form-field fxFlex.sm="50" fxFlex.gt-sm="25" fxFlex.lt-sm="100" floatLabel="always">
            <input [disabled]='true' matInput placeholder="Sold Date"
              [value]="data.vehicle.soldDate | date: 'mediumDate'" />
          </mat-form-field>
        </div>

      </form>
    </mat-dialog-content>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
      <div fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="center center" class="button-container">
          <button mat-button [mat-dialog-close]="null" class="m-8">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
