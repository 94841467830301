<div fxFlexFill fxLayout="column" *ngIf="dropdowns != null">
  <mat-toolbar class="mat-elevation-z2">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="backClicked()" class="back-button" fxLayoutAlign="center center">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <span class="toolbar-title" fxFlex>Vehicle Search</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content-container"
    *ngIf="{prospectDropdowns: prospectDropdowns$ | async, systemControls: systemControls$ | async, localSettings: localSettings$ | async, selectedProspect: selectedProspect$ | async} as data">
    <div fxLayout="column" class="filters-header-container">
      <h2>
        Filters
      </h2>
      <span class="primary pointer" (click)="resetVehicleSearchForm()">Reset</span>
    </div>
    <div class="search-form-container">
      <form fxLayout="column" [formGroup]="vehicleSearchForm">
        <div fxLayout="row wrap">
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Category" name="vehicleCategory" formControlName="categoryId">
              <mat-option [value]="'D'">Demo</mat-option>
              <mat-option [value]="'N'">New</mat-option>
              <mat-option [value]="'U'">Used</mat-option>
              <mat-option [value]="'O'">Other</mat-option>
              <mat-option [value]="'C'">Co. Vehicle</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Subcategory" name="vehicleSubcategory" formControlName="subCategoryId">
              <mat-option *ngFor="let vehicleSubcategory of dropdowns.vehicleSubcategories"
                [value]="vehicleSubcategory.id">{{vehicleSubcategory.description}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Sub Type" name="subType" formControlName="subTypeId">
              <mat-option [value]="''">All</mat-option>
              <mat-option *ngFor="let vehicleSubType of dropdowns.vehicleSubTypes" [value]="vehicleSubType.id">
                {{vehicleSubType.description}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Make" name="make" formControlName="make">
              <mat-option *ngFor="let vehicleMake of dropdowns.vehicleMakes" [value]="vehicleMake.description">
                {{vehicleMake.description}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Description" name="description" formControlName="description">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Id" name="id" type="number" formControlName="id">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Generic Model" name="genericModel" formControlName="genericModelId">
              <mat-option *ngFor="let vehicleGenericModel of dropdowns.vehicleGenericModels"
                [value]="vehicleGenericModel.id">{{vehicleGenericModel.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Color" name="colorId" formControlName="colorId">
              <mat-option *ngFor="let vehicleColour of dropdowns.vehicleColours" [value]="vehicleColour.id">
                {{vehicleColour.colourDescription}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Rooftop" name="rooftop" formControlName="rooftopId">
              <mat-option *ngFor="let rooftop of dropdowns.rooftops" [value]="rooftop.id">{{rooftop.displayCode}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Model" name="modelCode" formControlName="modelCode">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="RPOs" name="rpos" formControlName="rpos">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Unit #" name="unitNumber" formControlName="unitNumber">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <mat-select placeholder="Status" name="status" formControlName="vehicleStatusId">
              <mat-option [value]="'2'">Stock</mat-option>
              <mat-option [value]="'C'">Consignment</mat-option>
              <mat-option [value]="'1,10,9,11,12'">Orders - All</mat-option>
              <mat-option [value]="'12'">Orders - In Transit</mat-option>
              <mat-option [value]="'1'">Orders - Preferenced</mat-option>
              <mat-option [value]="'10'">Orders - Released</mat-option>
              <mat-option [value]="'11'">Orders - Scheduled</mat-option>
              <mat-option [value]="'9'">Orders - Unpreferenced</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Min Year" name="year" formControlName="minYear">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Max Year" name="year" formControlName="maxYear">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Min Price" name="minPrice" formControlName="minPrice">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="Max Price" name="maxPrice" formControlName="maxPrice">
          </mat-form-field>
          <mat-form-field fxFlex="25" fxFlex.xs="50">
            <input matInput placeholder="VIN" name="vin" formControlName="vin" (input)="convertToCaps('vin', $event)">
          </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button #searchButton (click)="getVehicleSearchResults(vehicleSearchForm.value, selectedProspect)"
            [disabled]="(isLoading$ | async)" type="submit" fxLayoutAlign="center center"
            class="mat-elevation-z2 search-button pointer" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="search-icon">search</mat-icon> <span class="search-button-text">Search</span>
          </button>
        </div>
      </form>
    </div>
    <div fxLayoutAlign="space-between center" class="sort-container">
      <div fxLayout="column" fxLayoutAlign="center start">
        <h2>
          Results
        </h2>
        <span class="primary pointer" (click)="clearVehicleSearchResults()">Clear</span>
      </div>
      <mat-form-field>
        <mat-select placeholder="Sort" name="sort" [(ngModel)]="sort"
          (selectionChange)="sortChanged(sort, vehicleSearchForm)">
          <mat-option value="price1">
            Price 1 ASC
          </mat-option>
          <mat-option value="-price1">
            Price 1 DESC
          </mat-option>
          <mat-option value="price2">
            Price 2 ASC
          </mat-option>
          <mat-option value="-price2">
            Price 2 DESC
          </mat-option>
          <mat-option value="price3">
            Price 3 ASC
          </mat-option>
          <mat-option value="-price3">
            Price 3 DESC
          </mat-option>
          <mat-option value="vehicleId">
            Vehicle ID ASC
          </mat-option>
          <mat-option value="-vehicleId">
            Vehicle ID DESC
          </mat-option>
          <mat-option value="vehicleYear">
            Vehicle Year ASC
          </mat-option>
          <mat-option value="-vehicleYear">
            Vehicle Year DESC
          </mat-option>
          <mat-option value="currentMileage">
            Current Mileage ASC
          </mat-option>
          <mat-option value="-currentMileage">
            Current Mileage DESC
          </mat-option>
          <mat-option value="days">
            Days ASC
          </mat-option>
          <mat-option value="-days">
            Days DESC
          </mat-option>
          <mat-option value="vehicleColorDescription">
            Vehicle Color ASC
          </mat-option>
          <mat-option value="-vehicleColorDescription">
            Vehicle Color DESC
          </mat-option>
          <mat-option value="receivedDate">
            Received Date ASC
          </mat-option>
          <mat-option value="-receivedDate">
            Received Date DESC
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="loading-container">
      <mat-progress-spinner diameter="50" *ngIf="(isLoading$ | async)" mode="indeterminate">
      </mat-progress-spinner>
    </div>

    <div fxLayout="column" class="results-container" *ngIf="vehicleSearchResults$ | async as vehicleSearchResults">

      <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="results-inner-container" fxFlex>
        <ng-container
          *ngIf="vsDropdowns.channels != null && defaultPricingSystemControl != null && crmSettings !=null && authenticatedEmployee != null;">

          <crm-vehicle-search-card fxFlex="49" fxFlex.gt-md="32" fxFlex.xs="100"
            *ngFor="let vehicleSearchResult of displayedResults; trackBy:trackByFunction"
            [vehicleSearchResult]="vehicleSearchResult" [autovanceEnabled]="avEnabled" [dropdowns]="vsDropdowns"
            [prospectDropdowns]="data.prospectDropdowns" [defaultPricingSct]="defaultPricingSystemControl"
            [settings]="crmSettings" [localSettings]="data.localSettings"
            [authenticatedEmployee]="authenticatedEmployee" [selectedProspect]="data.selectedProspect">
          </crm-vehicle-search-card>


        </ng-container>
      </div>
      <div style="width:100%;" fxLayoutAlign="center center">
        <mat-paginator [length]="vehicleSearchResults.length" [pageSize]="pageSize" [pageIndex]="page"
          (page)="pageChanged($event)" [pageSizeOptions]="">
        </mat-paginator>
      </div>

    </div>


  </div>
</div>