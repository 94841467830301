import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { DealerPromotion } from '@quorum/models/xs-resource';
import { SalesPlannerStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { of } from 'rxjs';
import { flatMap, mergeAll, switchMap } from 'rxjs/operators';
// eslint-disable-next-line max-len
import * as fromProspectDealerPromotions from '../../selected-activity/prospect-dealer-promotions/prospect-dealer-promotions.actions';
import * as fromSelectedActivity from '../../selected-activity/selected-activity.actions';
import * as fromDealerPromotions from './dealer-promotions.actions';
import { DealerPromotionsState } from './dealer-promotions.interfaces';

@Injectable()
export class DealerPromotionsEffects {
  
  getDealerPromotionsFromServer = createEffect(() => this.dataPersistence.fetch(fromDealerPromotions.GET_DEALER_PROMOTIONS_FROM_SERVER, {
    id: (a: any, state: any) => {
      return 1;
    },
    run: (action: fromDealerPromotions.GetDealerPromotionsFromServer, state: DealerPromotionsState) => {
      return this.apiService
        .get<DealerPromotion[]>(`v/1/crm/dealer-promotion-tasks`, {
          params: action.payload,
        })
        .pipe(
          flatMap((dealerPromotions) => {
            let actions: any[] = [];

            actions.push(new fromDealerPromotions.ClearDealerPromotionsFromState());

            actions.push(
              new fromDealerPromotions.GetDealerPromotionsFromServerSuccess({
                dealerPromotions: dealerPromotions,
              })
            );
            return of(actions).pipe(mergeAll());
          })
        );
    },
    onError: (action: fromDealerPromotions.GetDealerPromotionsFromServer, error) => {
      console.error('Error', error);
    },
  }));

  
  updateDealerPromotionOnServer = createEffect(() => this.dataPersistence.fetch(fromDealerPromotions.UPDATE_DEALER_PROMOTIONS_ON_SERVER, {
    run: (action: fromDealerPromotions.UpdateDealerPromotionOnServer, state: any) => {
      return this.apiService
        .put<DealerPromotion>(
          `v/1/crm/dealer-promotion-tasks/${action.payload.id}/${action.payload.sequence}`,
          action.payload
        )
        .pipe(
          switchMap((promo) => {
            let actions: Action[] = [];
            let toastOptions: any = {
              message: `Dealer Promotion updated.`,
              actionText: null,
              options: { duration: 3000, horizontalPosition: 'left' },
            };

            this.salesPlannerStateService.addToast(toastOptions);

            actions.push(
              new fromSelectedActivity.UpdateSelectedActivityInState({
                id: promo.id,
                changes: { ...promo },
              })
            );
            actions.push(
              new fromDealerPromotions.UpdateDealerPromotionOnServerSuccess({ id: promo.id, changes: { ...promo } })
            );
            actions.push(
              new fromProspectDealerPromotions.UpdateProspectDealerPromotionInState({
                id: promo.id,
                changes: { ...promo },
              })
            );
            return of(actions).pipe(mergeAll());
          })
        );
    },

    onError: (action: fromDealerPromotions.UpdateDealerPromotionOnServer, error) => {
      console.error('Error', error);
    },
  }));

  constructor(
    private dataPersistence: DataPersistence<DealerPromotionsState>,
    private apiService: ApiService,
    private salesPlannerStateService: SalesPlannerStateService
  ) {}
}
