import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ProspectReport } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-prospect-card',
  templateUrl: './prospect-card.component.html',
  styleUrls: ['./prospect-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProspectCardComponent implements OnInit {
  @Input() prospect: ProspectReport;

  constructor() {}

  ngOnInit() {}
}
