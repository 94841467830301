export class SelectedAddDeductQueryParameters {
  id?: string;
  uvc?: string;
  vehicleId?: string;

  constructor(obj?: SelectedAddDeductQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
