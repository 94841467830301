export class AppointmentQueryParameters {
  id?: string;
  appointmentDateFrom?: Date | string;
  appointmentDateTo?: Date | string;
  customerId?: string;
  customerName?: string;
  dates?: string;
  department?: string;
  digitalCheckInStatus?: string;
  embed?: string;
  employeeId?: string;
  excludeCommunicatorConfirmation?: string;
  hasServiceVisitId?: string;
  licenseNumber?: string;
  page?: number;
  pageSize?: number;
  phoneNumber?: string;
  rooftopId?: string;
  salespersonId?: string[];
  search?: string;
  serviceAdvisorId?: string;
  serviceCode?: string;
  serviceVisitId?: string;
  statusId?: string;
  tagNumber?: string;
  technicianGroupId?: string;
  technicianId?: string;
  transportOptionId?: string;
  vehicleId?: string;

  constructor(obj?: AppointmentQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
