import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationStateService } from '@quorum/authentication/services';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { CrmSettingsStateService } from '@quorum/crm-settings/services';
import { environment } from '@quorum/environments';
import { ProspectQueryParameters } from '@quorum/models/xs-query';
import { AuthenticatedUser, Prospect, SalesTeamMaintenance } from '@quorum/models/xs-resource';
import {
  ActivitiesStateService,
  LocalSettingsState,
  LocalSettingsStateService,
  SalesPlannerStateService,
  UiStateService,
} from '@quorum/xsr-salesplanner-legacy/services';
import { CrmEntityStateService } from 'libs/xsr-entities/src/services.barrel';
import { BehaviorSubject, combineLatest, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActivityQueryParameters } from '../../../../xsr-salesplanner-legacy/src/lib/activities.query-model';
declare const noticeable: any;

@Component({
  selector: 'crm-sales-planner-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MasterComponent implements OnInit, OnDestroy {
  activities$: Observable<any[]>;
  activityQueryParams: ActivityQueryParameters;
  authenticatedUser$: Observable<AuthenticatedUser>;
  localSettings$: Observable<any>;
  localSettings: LocalSettingsState;
  localSettingsSubscription$: Subscription;
  overdueActivities: boolean;
  prospectListComponentData$: Observable<any>;
  prospectQueryParams$: BehaviorSubject<ProspectQueryParameters>;
  prospects$: Observable<Prospect[]>;
  sidenav: boolean;
  sidenavStatus$: Subscription;
  combinedObs$: Observable<any>;
  destroy$ = new Subject();
  salesTeamsData$: Observable<{ salesTeams: SalesTeamMaintenance[]; salesTeamsLoading: boolean }>;

  noSalesTeamMessage = `Sales
  Planner
  requires a Sales
  Team for a large
  portion of its functionality. Please return to the DMS and create a Sales Team, adding appropriate Sales
  employees. Contact Quorum support for assistance if required. `;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private crmEntityStateService: CrmEntityStateService,
    private dropdownsStateService: DropdownsStateService,
    private localSettingsStateService: LocalSettingsStateService,
    private uiStateService: UiStateService,
    public activitiesStateService: ActivitiesStateService,
    public authenticationStateService: AuthenticationStateService,
    public salesPlannerStateService: SalesPlannerStateService,
    private settingsStateService: CrmSettingsStateService
  ) {}

  ngOnInit() {
    this.authenticatedUser$ = this.authenticationStateService.selectAuthenticatedUser().pipe(take(1));
    this.localSettings$ = this.localSettingsStateService.selectLocalSettingsFromState();

    this.salesTeamsData$ = combineLatest([
      this.dropdownsStateService.selectSalesTeams(),
      this.dropdownsStateService.selectSalesTeamsLoading().pipe(filter((salesTeamsLoading) => !salesTeamsLoading)),
    ]).pipe(
      map(([salesTeams, salesTeamsLoading]) => {
        return { salesTeams, salesTeamsLoading };
      })
    );

    this.localSettingsSubscription$ = this.localSettings$
      .pipe(takeUntil(this.destroy$))
      .subscribe((localSettingsState: LocalSettingsState) => {
        this.localSettings = localSettingsState;
        this.activityQueryParams = new ActivityQueryParameters({
          activityTypes: localSettingsState.activitiesFilter,
          employeeId: localSettingsState.salesPeople,
          startDate: localSettingsState.startDate,
        });

        this.prospectQueryParams$ = new BehaviorSubject<ProspectQueryParameters>(
          new ProspectQueryParameters({
            employeeId: localSettingsState.salesPeople,
            statusId: localSettingsState.prospectFilter.status,
            typeId: localSettingsState.prospectFilter.typeId,
            page: 0,
            channelId: localSettingsState.prospectFilter.channel,
            sourceId: localSettingsState.prospectFilter.source,
            pageSize: 50,
            sort: localSettingsState.prospectFilter.sort,
            embed: ['associate', 'primaryEmployeeAssociate', 'vehicle'],
          })
        );

        this.activities$ = this.activitiesStateService
          .selectActivitiesList(localSettingsState)
          .pipe(map((openActivities: any) => openActivities.filter((openActivity: any) => openActivity.open == true)));
      });

    this.prospects$ = this.salesPlannerStateService.selectProspectsArray();

    this.prospectListComponentData$ = combineLatest([
      this.salesPlannerStateService.selectPageOfProspects(),
      this.salesPlannerStateService.selectProspectsPageDetails(),
    ]).pipe(
      map(([prospects, pageDetails]) => {
        return { prospects, pageDetails };
      })
    );

    this.sidenavStatus$ = this.uiStateService
      .selectSidenavVisible()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.sidenav = res));

    this.combinedObs$ = forkJoin([
      this.dropdownsStateService.selectSalesTeams().pipe(
        filter((salespeople) => !this.dropdownsStateService.isEmpty(salespeople)),
        map((teamLeads) => teamLeads.filter((teamLead: any) => teamLead.teamLead === true)),
        take(1)
      ),
      this.authenticationStateService.selectAuthenticatedUser().pipe(
        filter((authenticatedUser: AuthenticatedUser) => authenticatedUser != null),
        take(1)
      ),
      this.settingsStateService.selectSettingsFromState().pipe(take(1)),
    ]).pipe(
      map(([teamLeads, authenticatedUser, crmSettings]) => {
        return {
          salesPlannerView: crmSettings.salesPlannerView,
          teamLead:
            teamLeads.find((teamLead: any) => teamLead.employeeId === authenticatedUser.user.xselleratorEmployeeId) !==
            undefined,
        };
      })
    );
  }

  ngAfterViewInit() {
    this.authenticatedUser$
      .pipe(
        switchMap((authenticatedUser) => {
          return this.authenticationStateService.generateJWTTokenForNoticeable(authenticatedUser);
        }),
        take(1)
      )
      .subscribe((ssoToken: any) => {
        noticeable.identify(environment.noticeable.sharedProjectId, ssoToken.endUserSsoToken);
        noticeable.render('widget', environment.noticeable.iconWidgetIdPowerLane, { selector: '#noticeable-icon' });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    noticeable.destroy('widget', environment.noticeable.iconWidgetIdSalesPlanner);
  }

  prospectFiltersChanged(prospectFilters: ProspectQueryParameters) {
    this.prospectQueryParams$.next(prospectFilters);
    this.salesPlannerStateService.clearProspectsFromState();
    this.changeDetectorRef.detectChanges();

    this.localSettingsStateService.updateLocalSettingsInState({
      ...this.localSettings,
      prospectFilter: {
        status: prospectFilters.statusId,
        typeId: prospectFilters.typeId,
        channel: prospectFilters.channelId,
        source: prospectFilters.sourceId,
        sort: prospectFilters.sort,
      },
    });

    this.crmEntityStateService.getProspectsFromServer({
      ...prospectFilters,
      employeeId: this.localSettings.salesPeople,
    });
  }

  prospectPageChanged(prospectFilters: ProspectQueryParameters) {
    this.prospectQueryParams$.next(prospectFilters);
    this.changeDetectorRef.detectChanges();
    this.salesPlannerStateService.checkIfProspectPageExistsInState(prospectFilters);
  }

  activityFiltersChanged(activityFilters: ActivityQueryParameters) {
    this.localSettingsStateService.updateLocalSettingsInState({
      ...this.localSettings,
      startDate: activityFilters.startDate,
      activitiesFilter: activityFilters.activityTypes,
    });

    if (activityFilters.overdueActivities) {
      this.overdueActivities = true;
      this.activitiesStateService.getOverdueActivitiesFromServer(activityFilters, {
        ...this.localSettings,
        startDate: activityFilters.startDate,
        activitiesFilter: activityFilters.activityTypes,
      });
    } else {
      this.overdueActivities = false;
      this.activitiesStateService.getActivitiesFromServer(activityFilters, {
        ...this.localSettings,
        startDate: activityFilters.startDate,
        activitiesFilter: activityFilters.activityTypes,
      });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  toggleSidenav() {
    this.uiStateService.toggleSidenav(!this.sidenav);
  }
}
