import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/equity-mining.reducer';
import { EquityMiningEffects } from './+state/equity-mining.effects';
import { EquityMiningStateService } from './equity-mining-state.service';
import { EquityMiningEnabledGuard } from './equity-mining-enabled.guard';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('equityMining', reducer),
    EffectsModule.forFeature([EquityMiningEffects])
  ],
  providers: [EquityMiningStateService, EquityMiningEnabledGuard]
})
export class CrmEquityMiningModule {}
