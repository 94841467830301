<div *ngIf="{ currentUser: currentUser$ | async, systemControl: systemControl$ | async } as data">
  <div fxLayout="column">
    <div id="header" fxLayout="row" class="header-container" fxLayoutAlign="space-between center">
      <div fxLayout="row">
        <div fxLayout="column">
          <span class="mat-subheading-2">Appointment {{ appointment?.id }}</span>
          <span class="mat-caption" *ngIf="appointment?.completedDate">Completed {{ appointment?.completedDate | moment:
            'dddd, MMM Do, YYYY' }}</span>
        </div>
      </div>
    </div>
    <form fxLayout="row wrap" [formGroup]="appointmentForm">
      <mat-form-field fxFlex="50">
        <input matInput autocomplete="off" [matDatepicker]="picker" placeholder="Date" name="date"
          formControlName="appointmentDate" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input matInput type="time" placeholder="Time" name="time" formControlName="appointmentTime" />
      </mat-form-field>
      <mat-form-field fxFlex="50" fxFlex.xs="100">
        <textarea matInput placeholder="Objectives" name="objective" formControlName="objectives" matTextareaAutosize
          matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <mat-form-field fxFlex="50" fxFlex.xs="100">
        <textarea matInput placeholder="Comments" name="comments" formControlName="comments" matTextareaAutosize
          matAutosizeMinRows="1" matAutosizeMaxRows="5"></textarea>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select placeholder="Attendance" name="attendance" formControlName="attendanceFlag">
          <mat-option value="null"> </mat-option>
          <mat-option value="N"> No Show </mat-option>
          <mat-option value="S"> Show </mat-option>
        </mat-select>
      </mat-form-field>

      <div fxFlex="100" class="confirmation-container" fxLayoutAlign="center center">
        <mat-slide-toggle labelPosition="before" *ngIf="data.systemControl.value === 'Y'"
          [disabled]="!data.currentUser.canConfirmSalesAppointment" formControlName="confirmationFlag">
          Confirmed
        </mat-slide-toggle>
      </div>
    </form>
    <div fxLayout="row" fxLayoutAlign="center center" class="button-container">
      <button mat-raised-button color="primary" (click)="updateAppointment(data.currentUser)">Update</button>
    </div>
  </div>
</div>