import { Component, Input, OnInit } from '@angular/core';
import { PurchaseOrderDetail } from '@quorum/models/xs-resource';

@Component({
  selector: 'crm-activity-purchase-order-details',
  templateUrl: './activity-purchase-order-details.component.html',
  styleUrls: ['./activity-purchase-order-details.component.scss'],
})
export class ActivityPurchaseOrderDetailsComponent implements OnInit {
  @Input() activityPurchaseOrderDetails: PurchaseOrderDetail[];

  constructor() {}

  ngOnInit(): void {}
}
