<div fxFlexFill fxLayout="row">
  <mat-sidenav-container fxFlexFill class="example-container" (backdropClick)="closeSidenav()">
    <mat-sidenav class="sidenav-menu" [(opened)]="sidenav" *ngIf="authenticatedUser$ | async as authenticatedUser">
      <mat-toolbar>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="start center">
          <button class="avatar-button-container" mat-icon-button>
            <sha-avatar [avatarUrl]="authenticatedUser.user.avatarUrl"
              [initials]="[authenticatedUser.user.firstName.charAt(0) + authenticatedUser.user.lastName.charAt(0)]">
            </sha-avatar>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayout="column" fxLayoutAlign="start start">
          <span fxFlex class="mat-title">{{authenticatedUser.user.firstName}}
            {{authenticatedUser.user.lastName}}</span>
          <div fxLayout="row" fxLayoutAlign="space-between center" class="user-details-container">
            <span fxFlex class="mat-body-1 secondary-text">Version: {{authenticatedUser.user.version}}</span>
            <button mat-icon-button id="dropdown" [matMenuTriggerFor]="menu" class="secondary-text">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item id="crm-logout-button" (click)="logout()">
                <span>Logout</span>
              </button>
            </mat-menu>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-divider></mat-divider>
      <!--
        <mat-list-item *ngIf="drilldownEnabled" (click)="salesMetrics()"> Sales Metrics </mat-list-item>
        <mat-list-item *ngIf="drilldownEnabled" (click)="showroomLog()"> Showroom Log </mat-list-item> -->
      <!-- <mat-list-item *ngIf="localSettings$ | async as localSettings" (click)="openSettingsDialog(localSettings)">
          Settings
        </mat-list-item> -->
      <div fxLayout="column" class="nav-link-container" *ngIf="localSettings$ | async as localSettings">
        <div class="nav-link" id="crm-sales-planner-link" [ngClass]="{'selected': primaryRouteUrl === 'sales-planner'}"
          [routerLink]="'sales-planner'" (click)="closeSidenav()">
          <span class="nav-link-text">Sales Planner</span>
        </div>

        <div class="nav-link" id="crm-prospects-link" [ngClass]="{'selected': primaryRouteUrl === 'prospects'}"
          (click)="routeToProspects(localSettings);closeSidenav()">
          <span class="nav-link-text">Prospects</span>
        </div>

        <div *ngIf="prospectLeads$ | async as prospectLeads">
          <div class="child-nav-link" (click)="routeToProspects(localSettings, 'unassigned');closeSidenav()"
            fxLayoutAlign="space-between center">
            <span class="child-nav-link-text">Unassigned Leads</span>
            <div class="lead-count-bubble" fxLayout="row" fxLayoutAlign="center center">
              <span>{{prospectLeads.unassigned}}</span>
            </div>
          </div>

          <div class="child-nav-link" (click)="routeToProspects(localSettings,'unmatched');closeSidenav()"
            fxLayoutAlign="space-between center">
            <span class="child-nav-link-text">Unmatched Leads</span>
            <div class="lead-count-bubble" fxLayout="row" fxLayoutAlign="center center">
              <span>{{prospectLeads.unmatched}}</span>
            </div>
          </div>
        </div>

        <div class="nav-link" id="crm-vehicle-search-link"
          [ngClass]="{'selected': primaryRouteUrl === 'vehicle-search'}" [routerLink]="'vehicle-search'"
          (click)="closeSidenav()">
          <span class="nav-link-text">Vehicle Search</span>
        </div>
        <ng-container *ngIf="leadGeneratorSct$ | async as leadGeneratorEnabled">
          <div id="crm-lead-generator-link" *ngIf="leadGeneratorEnabled == '1';else lgDisabled" class="nav-link"
            [ngClass]="{'selected': primaryRouteUrl === 'lead-generator'}" [routerLink]="'lead-generator'"
            (click)="closeSidenav()">
            <span class="nav-link-text">Lead Generator</span>
          </div>
          <ng-template #lgDisabled>
            <div id="crm-lead-generator-link-disabled" class="crm-lead-generator-link-disabled">
              <span class="nav-link-text">Lead Generator</span>
            </div>
          </ng-template>

        </ng-container>
      </div>

      <mat-divider></mat-divider>
      <div id="crm-settings-link" class="nav-link" *ngIf="localSettings$ | async as localSettings"
        (click)="openSettingsDialog(salespeople$, localSettings)">
        <span>Settings</span>
      </div>

    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>