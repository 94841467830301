export class DealerPromotionQueryParameters {
  communicatorConversationId?: string;
  completedDateFrom?: Date;
  completedDateTo?: Date;
  contactPreferenceId?: string;
  dueDateFrom?: string;
  dueDateTo?: string;
  embed?: Array<String>;
  id?: string;
  open?: string;
  prospectId?: string;
  salespersonId?: string;
  sequence?: string;
  taskEmployeeId?: string[];
  templateId?: string;

  constructor(obj?: DealerPromotionQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
