<div class="container" fxLayout="column" *ngIf="componentData | async as data">
  <mat-nav-list *ngIf="!data.pageDetails.loadingPage; else loadingProspects" fxFlex>
    <div *ngIf="localSettings$ | async as localSettings">
      <ng-container *ngFor="let prospect of data.prospects | orderBy: localSettings.prospectFilter.sort;let i = index;">
        <crm-prospect-list-item [prospect]="prospect" (click)="selectProspect(prospect, localSettings)"></crm-prospect-list-item>
        <mat-divider inset *ngIf="i < data.prospects.length - 1"></mat-divider>
      </ng-container>
    </div>
  </mat-nav-list>
  <ng-template #loadingProspects>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </ng-template>
  <mat-paginator [length]="data.pageDetails.total" [pageSize]="data.pageDetails.pageSize" [pageIndex]="data.pageDetails.activePage"
    (page)="pageChanged($event)" [pageSizeOptions]="">
  </mat-paginator>
</div>