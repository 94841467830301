import { createFeatureSelector } from '@ngrx/store';
import { initialState as prospectsInitialState, ProspectsState } from '@quorum/xsr-entities/state';
import { ActivitiesState } from './activities/activities.interfaces';
import { LocalSettingsState } from './local-settings/local-settings.interfaces';
import { SalesPlannerUIState } from './ui/ui.interfaces';
import { VehicleSearchState } from './vehicle-search/vehicle-search.interfaces';

export interface SalesplannerLegacyState {
  readonly featureLoaded: boolean;
  readonly prospects: ProspectsState;
  readonly activities: ActivitiesState;
  readonly localSettings: LocalSettingsState;
  readonly ui: SalesPlannerUIState;
  readonly vehicleSearch: VehicleSearchState;
}

export const initialState: SalesplannerLegacyState = {
  featureLoaded: false,
  prospects: prospectsInitialState,
  activities: null,
  localSettings: null,
  ui: null,
  vehicleSearch: null
};

export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
