import { Action } from '@ngrx/store';

export const UPDATE_LOCAL_SETTINGS_IN_STATE = '[CRM] Update Local Settings In State';
export class UpdateLocalSettingsInState implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_LOCAL_SETTINGS_IN_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export const RESET_STATE = '[CRM] Reset Local Settings State';
export class ResetLocalSettingsState implements Action {
  readonly feature = 'crm';
  readonly type = RESET_STATE;
  readonly triggerStorageSync = false;
  constructor(public payload: any) {}
}

export type LocalSettingsAction = UpdateLocalSettingsInState | ResetLocalSettingsState;
