<div fxFlexLayout="row wrap" fxLayoutAlign="space-between center" class="qcontainer">
  <mat-form-field fxFlex="40">
    <mat-select
      [(ngModel)]="queryParams.activityTypes"
      placeholder="Activity Types"
      name="Activity Types"
      multiple
      (selectionChange)="filterChanged(queryParams.startDate, queryParams)"
      #activityTypeSelect="ngModel"
    >
      <mat-optgroup>
        <label
          fxFlex
          (click)="toggleSelection((actTypeSelectAll = !actTypeSelectAll), activityTypeSelect, activityTypes)"
        >
          All
        </label>
        <mat-option *ngFor="let activityType of activityTypes" [value]="activityType.id">
          {{ activityType.description }}
        </mat-option>
      </mat-optgroup>
      <mat-select-trigger>
        {{ queryParams.activityTypes | mapSelections: activityTypes }}
      </mat-select-trigger>
    </mat-select>
  </mat-form-field>

  <mat-form-field fxFlex="40">
    <input
      matInput
      autocomplete="off"
      [value]="queryParams.startDate"
      [matDatepicker]="picker"
      placeholder="Start Date"
      (dateChange)="filterChanged($event.value, queryParams)"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <button
    fxFlex="10"
    atTooltip="Toggle Overdue Activities"
    mat-icon-button
    (click)="toggleOverdueActivities()"
    [color]="overdueActivities ? 'primary' : null"
  >
    <mat-icon>history</mat-icon>
  </button>
</div>
