import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';
import { ApiService } from '@quorum/api';
import { Router, ActivatedRouteSnapshot, NavigationEnd, ActivationStart } from '@angular/router';
import { AvailableTabComponent } from '@quorum/crm-equity-mining-ng-ui';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class EquityMiningEffects {
  constructor(
    private actions: Actions,
    private dataPersistence: DataPersistence<any>,
    private apiService: ApiService,
    private router: Router
  ) {}

  // @Effect({ dispatch: false })
  // test = this.dataPersistence.navigation(AvailableTabComponent, {
  //   run: (a: ActivatedRouteSnapshot, state: any) => {
  //     return this.router.events.pipe(
  //       filter(events => {
  //         //console.log(events);
  //         return events instanceof ActivationStart && events.snapshot.routeConfig.path == 'available';
  //       }),
  //       tap((e: any) => (e.snapshot.queryParams = { ...e.queryParams, dank: true }))
  //       // switchMap(d => {
  //       //   console.log(d);
  //       //   return from([]);
  //       // })
  //     );
  //   },
  //   onError: (a: ActivatedRouteSnapshot, e: any) => {
  //     console.log(e);
  //     // we can log and error here and return null
  //     // we can also navigate back
  //     return null;
  //   }
  // });
}
