import { Action } from '@ngrx/store';
import { DirectMessageTemplateQueryParameters } from '@quorum/models/xs-query';
import { DirectMessageTemplate } from '@quorum/models/xs-resource';
export const GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER = '[CRM] Get Direct Message Templates From Server';
export class GetDirectMessageTemplatesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: DirectMessageTemplateQueryParameters) {}
}

export const GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_SUCCESS =
  '[CRM] Get Direct Message Templates From Server Success';
export class GetDirectMessageTemplatesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { directMessageTemplates: DirectMessageTemplate[] }) {}
}

export const GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_FAILURE =
  '[CRM] Get Direct Message Templates From Server Failure';
export class GetDirectMessageTemplatesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_DIRECT_MESSAGE_TEMPLATES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type DirectMessageTemplatesAction =
  | GetDirectMessageTemplatesFromServer
  | GetDirectMessageTemplatesFromServerSuccess
  | GetDirectMessageTemplatesFromServerFailure;
