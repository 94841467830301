import { Action } from '@ngrx/store';
import { VehicleOfInterestQueryParameters } from '@quorum/models/xs-query';
import { VehicleOfInterest } from '@quorum/models/xs-resource';

export const CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER =
  '[CRM] Create Selected Prospect Vehicle Of Interest From Server';
export class CreateSelectedProspectVehicleOfInterestFromServer implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleOfInterest) {}
}

export const CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS =
  '[CRM] Create Selected Prospect Vehicle Of Interest From Server Success';
export class CreateSelectedProspectVehicleOfInterestFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleOfInterest) {}
}

export const CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE =
  '[CRM] Create Selected Prospect Vehicle Of Interest From Server Failure';
export class CreateSelectedProspectVehicleOfInterestFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = CREATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER =
  '[CRM] Get Selected Prospect Vehicle Of Interest From Server';
export class GetSelectedProspectVehicleOfInterestFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleOfInterestQueryParameters) {}
}

export const GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS =
  '[CRM] Get Selected Prospect Vehicle Of Interest From Server Success';
export class GetSelectedProspectVehicleOfInterestFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: { prospectVehicleOfInterest: VehicleOfInterest[] }) {}
}

export const GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE =
  '[CRM] Get Selected Prospect Vehicle Of Interest From Server Failure';
export class GetSelectedProspectVehicleOfInterestFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER =
  '[CRM] Delete Selected Prospect Vehicle Of Interest From Server';
export class DeleteSelectedProspectVehicleOfInterestFromServer implements Action {
  readonly feature = 'crm';
  readonly type = DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleOfInterest) {}
}

export const DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS =
  '[CRM] Delete Selected Prospect Vehicle Of Interest From Server Success';
export class DeleteSelectedProspectVehicleOfInterestFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: number) {}
}

export const DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE =
  '[CRM] Delete Selected Prospect Vehicle Of Interest From Server Failure';
export class DeleteSelectedProspectVehicleOfInterestFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = DELETE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export const UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER =
  '[CRM] Update Selected Prospect Vehicle Of Interest From Server';
export class UpdateSelectedProspectVehicleOfInterestFromServer implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleOfInterest) {}
}

export const UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS =
  '[CRM] Update Selected Prospect Vehicle Of Interest From Server Success';
export class UpdateSelectedProspectVehicleOfInterestFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = false;
  constructor(public payload: VehicleOfInterest) {}
}

export const UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE =
  '[CRM] Update Selected Prospect Vehicle Of Interest From Server Failure';
export class UpdateSelectedProspectVehicleOfInterestFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = UPDATE_SELECTED_PROSPECT_VEHICLE_OF_INTEREST_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type ProspectVehicleOfInterestAction =
  | GetSelectedProspectVehicleOfInterestFromServer
  | GetSelectedProspectVehicleOfInterestFromServerSuccess
  | GetSelectedProspectVehicleOfInterestFromServerFailure
  | DeleteSelectedProspectVehicleOfInterestFromServer
  | DeleteSelectedProspectVehicleOfInterestFromServerSuccess
  | DeleteSelectedProspectVehicleOfInterestFromServerFailure
  | UpdateSelectedProspectVehicleOfInterestFromServer
  | UpdateSelectedProspectVehicleOfInterestFromServerSuccess
  | UpdateSelectedProspectVehicleOfInterestFromServerFailure;
