import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DeepCopyPipe } from '@quorum/common-pipes';
import { DropdownsStateService } from '@quorum/crm-dropdowns/services';
import { DealerPromotion, TaskOutcome } from '@quorum/models/xs-resource';
import { ActivitiesStateService } from '@quorum/xsr-salesplanner-legacy/services';
import { Observable } from 'rxjs';
@Component({
  selector: 'crm-promo-card',
  templateUrl: './promo-card.component.html',
  styleUrls: ['./promo-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoCardComponent implements OnInit, OnChanges {
  @Input() promo: DealerPromotion;
  promoCopy: DealerPromotion = new DealerPromotion();
  taskOutcomes$: Observable<TaskOutcome[]>;

  promoCardForm: FormGroup = this.fb.group({
    dueDate: [],
    outcomeId: [],
    note: [],
    comments: [],
  });

  constructor(
    private activitiesStateService: ActivitiesStateService,
    private dropdownsStateService: DropdownsStateService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.taskOutcomes$ = this.dropdownsStateService.selectTaskOutcomes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.promo.currentValue) {
      this.promoCopy = new DeepCopyPipe().transform(changes.promo.currentValue);
    }

    this.promoCardForm.patchValue({
      dueDate: this.promoCopy.dueDate,
      outcomeId: this.promoCopy.outcomeId,
      note: this.promoCopy.note,
      comments: this.promoCopy.comments,
    });
  }

  updatePromo(promo: DealerPromotion): void {
    if (promo.outcomeId) {
      promo.completedDate = new Date();
    }
    this.activitiesStateService.updateDealerPromotionOnServer({
      ...this.promoCopy,
      comments: promo.comments,
      dueDate: promo.dueDate,
      note: promo.note,
      outcomeId: promo.outcomeId,
      completedDate: promo.completedDate,
    });
  }
}
