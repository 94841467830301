import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProspectAction } from '@quorum/models/xs-resource';

export interface ProspectActionsState extends EntityState<ProspectAction> {}

export const prospectActionsAdapter: EntityAdapter<ProspectAction> = createEntityAdapter<ProspectAction>({
  selectId: (action: ProspectAction) => action.id,
});

export const initialState: ProspectActionsState = prospectActionsAdapter.getInitialState({});
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const selectedProspectActionsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.selectedActivity.prospectActions
);

export const { selectIds, selectEntities, selectAll, selectTotal } = prospectActionsAdapter.getSelectors(
  selectedProspectActionsSelector
);
