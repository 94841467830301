import { Action } from './action.model';
import { Associate } from '../associates/associate.model';

export class ProspectAction {
  appointmentDateTime: Date = null;
  associateEmployeeId: string = null;
  embedded?: ProspectActionEmbed = null;
  enteredDateTime: Date = null;
  id: number = null;
  newSalespersonId: string = null;
  previousSalespersonId: string = null;
  prospectId: number = null;
  taskActionId: number = null;

  constructor(obj?: ProspectAction) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}

class ProspectActionEmbed {
  action?: Action = null;
  employeeAssociate?: Associate = null;
  newSalespersonAssociate?: Associate = null;
  previousSalespersonAssociate?: Associate = null;
}
