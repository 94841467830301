import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DealerPromotion } from '@quorum/models/xs-resource';

export interface DealerPromotionsState extends EntityState<DealerPromotion> {
  loading: boolean;
}

export const dealerPromotionsAdapter: EntityAdapter<DealerPromotion> = createEntityAdapter<DealerPromotion>({
  selectId: (dealerPromotion: DealerPromotion) => dealerPromotion.id + ':' + dealerPromotion.sequence,
});

export const initialState: DealerPromotionsState = dealerPromotionsAdapter.getInitialState({ loading: null });
export const salesplannerLegacyFeatureSelector = createFeatureSelector<any>('salesplannerLegacy');
export const dealerPromotionsSelector = createSelector(
  salesplannerLegacyFeatureSelector,
  (state: any) => state.activities.dealerPromotions
);

export const isDealerPromotionsLoadingSelector = createSelector(
  dealerPromotionsSelector,
  (state: any) => state.loading
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  dealerPromotionsAdapter.getSelectors(dealerPromotionsSelector);
