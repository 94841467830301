import * as fromSalesTeams from './sales-teams.actions';
import { initialState, salesTeamsAdapter, SalesTeamsState } from './sales-teams.interfaces';

export function reducer(
  state: SalesTeamsState = initialState,
  action: fromSalesTeams.SalesTeamsAction
): SalesTeamsState {
  switch (action.type) {
    case fromSalesTeams.GET_SALES_TEAMS_FROM_SERVER_SUCCESS: {
      return {
        ...salesTeamsAdapter.addMany(action.payload.salesTeams, state),
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
