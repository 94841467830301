import { Action } from '@ngrx/store';
import { ClassQueryParameters } from '@quorum/models/xs-query';
import { Class } from '@quorum/models/xs-resource';

export const GET_ASSOCIATE_CLASSES_FROM_SERVER = '[CRM] Get Associate Classes From Server';
export class GetAssociateClassesFromServer implements Action {
  readonly feature = 'crm';
  readonly type = GET_ASSOCIATE_CLASSES_FROM_SERVER;
  readonly triggerStorageSync = false;
  constructor(public payload: ClassQueryParameters) {}
}

export const GET_ASSOCIATE_CLASSES_FROM_SERVER_SUCCESS = '[CRM] Get Associate Classes From Server Success';
export class GetAssociateClassesFromServerSuccess implements Action {
  readonly feature = 'crm';
  readonly type = GET_ASSOCIATE_CLASSES_FROM_SERVER_SUCCESS;
  readonly triggerStorageSync = true;
  constructor(public payload: { associateClasses: Class[] }) {}
}

export const GET_ASSOCIATE_CLASSES_FROM_SERVER_FAILURE = '[CRM] Get Associate Classes From Server Failure';
export class GetAssociateClassesFromServerFailure implements Action {
  readonly feature = 'crm';
  readonly type = GET_ASSOCIATE_CLASSES_FROM_SERVER_FAILURE;
  readonly triggerStorageSync = false;
  constructor(public payload: { error: any }) {}
}

export type AssociateClassesAction =
  | GetAssociateClassesFromServer
  | GetAssociateClassesFromServerSuccess
  | GetAssociateClassesFromServerFailure;
