<div fxFlexFill fxLayout="column">
  <h1 mat-dialog-title>Rooftop Selection</h1>
  <div mat-dialog-content fxFlex>
    <form mat-dialog-content fxLayout="column" fxLayoutAlign="start start" [formGroup]="rooftopIdForm" fxFlex>
      <mat-radio-group fxLayout="column" fxLayoutGap="12px" formControlName="rooftopId" required
        *ngIf="data.dropdowns.rooftops as rooftops">
        <mat-radio-button *ngFor="let rooftop of rooftops" [value]="rooftop.id">
          {{rooftop.displayCode}}
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [mat-dialog-close]="rooftopIdForm.get('rooftopId').value" cdkFocusInitial color="primary"
      [disabled]="!rooftopIdForm.valid">Ok</button>
  </div>
</div>