import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as CommunicatorActions from './communicator.actions';
import { CommunicatorEntity } from './communicator.models';

export const COMMUNICATOR_FEATURE_KEY = 'communicator';

export interface State extends EntityState<CommunicatorEntity> {
  selectedId?: string | number; // which Communicator record has been selected
  loaded: boolean; // has the Communicator list been loaded
  error?: string | null; // last known error (if any)
}

export interface CommunicatorPartialState {
  readonly [COMMUNICATOR_FEATURE_KEY]: State;
}

export const communicatorAdapter: EntityAdapter<CommunicatorEntity> = createEntityAdapter<CommunicatorEntity>();

export const initialState: State = communicatorAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const communicatorReducer = createReducer(
  initialState,
  on(CommunicatorActions.loadCommunicator, (state) => ({ ...state, loaded: false, error: null })),
  on(CommunicatorActions.loadCommunicatorSuccess, (state, { communicator }) =>
    communicatorAdapter.addMany(communicator, { ...state, loaded: true })
  ),
  on(CommunicatorActions.loadCommunicatorFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return communicatorReducer(state, action);
}
