<div *ngIf="dropdowns$ | async as dropdowns">
  <mat-toolbar class="mat-elevation-z3">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button class="toolbar-button" (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <span fxFlex class="toolbar-title">Prospect Management</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="content-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="main-container">
      <div class="filters-container">
        <div class="filters-container-header">
          <h2>
            Filters
          </h2>
          <span class="reset-button" (click)="resetProspectLeadForm(authenticatedEmployee, localSettings)">Reset</span>
        </div>
        <div class="filters-container-form">
          <form #prospectLeadFormRef class="filter-form" [formGroup]="prospectLeadForm" (ngSubmit)="getProspectLeads()"
            (keydown.enter)="prospectLeadForm.valid">
            <div class="form-filters-container">
              <div class="form-field">
                <mat-form-field>
                  <input matInput placeholder="Search (Name, email, phone, etc)" name="search" formControlName="search">
                </mat-form-field>
              </div>
              <div class="form-field" *ngIf="dropdowns.rooftops">
                <mat-form-field>
                  <mat-select placeholder="Rooftop" name="rooftop" formControlName="rooftopId">
                    <mat-option *ngFor="let rooftop of dropdowns.rooftops" [value]="rooftop.id.toString()">
                      {{rooftop.displayCode}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field" *ngIf="dropdowns.salespeople">
                <mat-form-field>
                  <mat-select multiple placeholder="Salesperson" name="salespersonId" formControlName="salespersonId">
                    <mat-option [value]="'0'">
                      Unassigned</mat-option>
                    <mat-option [value]="dropdowns.salespeople.employeeId.EmployeeId">
                      {{dropdowns.salespeople.employeeId.EmployeeName}}</mat-option>
                    <mat-optgroup *ngFor="let salesTeam of dropdowns.salespeople.teams">
                      <label (click)="selectTeam(salesTeam, prospectLeadForm)">{{salesTeam.teamName}}</label>
                      <mat-option *ngFor="let salesperson of salesTeam.children" [value]="salesperson.EmployeeId">
                        {{salesperson.EmployeeName}}</mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Status" formControlName="prospectStatusId" name="prospectStatusId" multiple>
                    <mat-option disableRipple
                      (click)="toggleAllSelection(statusSelectAll=!statusSelectAll, 'prospectStatusId', prospectStatuses)">
                      All
                    </mat-option>
                    <mat-option *ngFor="let status of prospectStatuses" [value]="status.id">
                      {{status.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Type" formControlName="prospectTypeId" name="prospectTypeId" multiple>
                    <mat-option
                      (click)="toggleAllSelection(typeSelectAll=!typeSelectAll, 'prospectTypeId', prospectTypes)">
                      All
                    </mat-option>
                    <mat-option *ngFor="let type of prospectTypes" [value]="type.id">
                      {{type.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Channel" formControlName="prospectChannelId" name="prospectChannelId"
                    multiple>
                    <mat-select-trigger>
                      {{ prospectLeadForm.get('prospectChannelId').value | mapSelections: dropdowns.channels }}
                    </mat-select-trigger>
                    <mat-option
                      (click)="toggleAllSelection(channelSelectAll=!channelSelectAll, 'prospectChannelId', dropdowns.channels)">
                      All
                    </mat-option>
                    <mat-option *ngFor="let channel of dropdowns.channels" [value]="channel.id.toString()">
                      {{channel.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Source" formControlName="prospectSourceId" name="prospectSourceId" multiple>
                    <mat-option
                      (click)="toggleAllSelection(sourceSelectAll=!sourceSelectAll, 'prospectSourceId', dropdowns.sources)">
                      All
                    </mat-option>
                    <mat-select-trigger>
                      {{ prospectLeadForm.get('prospectSourceId').value | mapSelections: dropdowns.sources }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let source of dropdowns.sources" [value]="source.id.toString()">
                      {{source.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Last Action" formControlName="actionId" name="actionId" multiple>
                    <mat-option
                      (click)="toggleAllSelection(actionsSelectAll=!actionsSelectAll, 'actionId', dropdowns.actions)">
                      All
                    </mat-option>
                    <mat-select-trigger>
                      {{ prospectLeadForm.get('actionId').value | mapSelections: dropdowns.actions }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let action of dropdowns.actions" [value]="action.id.toString()">
                      {{action.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Customer Type" formControlName="customerTypeId" name="customerTypeId"
                    multiple>
                    <mat-option
                      (click)="toggleAllSelection(customerTypesSelectAll=!customerTypesSelectAll, 'customerTypeId', prospectCustomerTypes)"
                      [value]="0">
                      All
                    </mat-option>
                    <mat-option *ngFor="let customerType of prospectCustomerTypes" [value]="customerType.id">
                      {{customerType.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Vendor" formControlName="vendorId" name="vendorId" multiple>
                    <mat-option
                      (click)="toggleAllSelection(vendorSelectAll=!vendorSelectAll, 'vendorId', dropdowns.vendors)">
                      All
                    </mat-option>
                    <mat-select-trigger>
                      {{ prospectLeadForm.get('vendorId').value | mapSelections: dropdowns.vendors: 'vendorId' }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let vendor of dropdowns.vendors" [value]="vendor.associateId">
                      {{vendor.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Matched" name="matched" formControlName="matched">
                    <mat-option value="true">Yes</mat-option>
                    <mat-option value="false">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Category" formControlName="categoryId" name="categoryId" multiple>
                    <mat-option
                      (click)="toggleAllSelection(categorySelectAll=!categorySelectAll, 'categoryId', dropdowns.categories)">
                      All
                    </mat-option>
                    <mat-select-trigger>
                      {{ prospectLeadForm.get('categoryId').value | mapSelections: dropdowns.categories: 'categoryId' }}
                    </mat-select-trigger>
                    <mat-option *ngFor="let category of dropdowns.categories" [value]="category.categoryId">
                      {{category.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="form-field">
                <mat-form-field>
                  <input matInput autocomplete="off" [matDatepicker]="enteredFromPicker" placeholder="Entered Date From"
                    name="fromDate" formControlName="prospectEnteredDateFrom">
                  <mat-datepicker-toggle matSuffix [for]="enteredFromPicker"></mat-datepicker-toggle>
                  <mat-datepicker #enteredFromPicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <input matInput autocomplete="off" [matDatepicker]="enteredToPicker" placeholder="Entered Date To"
                    name="toDate" formControlName="prospectEnteredDateTo">
                  <mat-datepicker-toggle matSuffix [for]="enteredToPicker"></mat-datepicker-toggle>
                  <mat-datepicker #enteredToPicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="form-field">
                <mat-form-field>
                  <mat-select placeholder="Tasks" name="openTasks" formControlName="openTasks" multiple>
                    <mat-option
                      (click)="toggleAllSelection(openTasksSelectAll=!openTasksSelectAll, 'openTasks', openTasksOptions)">
                      All</mat-option>
                    <mat-option *ngFor="let openTasksOption of openTasksOptions" [value]="openTasksOption.id">
                      {{openTasksOption.description}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="search-button-container">
              <button #searchButton type="submit" [ngClass]="{'search-button--disabled': prospectLeadForm.invalid}"
                class="search-button mat-elevation-z2">
                <mat-icon [ngClass]="{'search-icon--disabled': prospectLeadForm.invalid}" class="search-icon">
                  search
                </mat-icon> <span class="search-button-text">Search</span>
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="content-container-header">
        <div class="content-container-header-text">
          <h2>
            Results
          </h2>
          <span class="clear-button" (click)="clearSearchResults()">Clear</span>
        </div>
        <div class="form-field">
          <mat-form-field>
            <mat-select placeholder="Sort" name="sort" (selectionChange)="sortChanged(sort, prospectLeadForm)"
              [(ngModel)]="sort">
              <mat-option value="prospectEnteredDate">Entered Date ASC</mat-option>
              <mat-option value="-prospectEnteredDate">Entered Date DESC</mat-option>
              <mat-option value="prospectUpdatedDate">Updated Date ASC</mat-option>
              <mat-option value="-prospectUpdatedDate">Updated Date DESC</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="search-results-container" *ngIf="searchResult">
        <div *ngIf="!(isLoading$ | async)" class="search-result-card-container">
          <div *ngFor="let prospect of searchResult.data;trackBy: trackByFunction" class="result-container">
            <div class="result-container-header">
              <div mat-list-icon class="type-icon header-icon"
                [ngClass]="prospect.prospectTypeId == 3 ? prospect.vendorId && prospect.associateId == null ? 'lead' : 'showroom' : 'retention'"
                fxLayout="row" fxLayoutAlign="center center">
                <div *ngIf="prospect.prospectTypeId == 3; else retention">
                  <div *ngIf="!prospect.vendorId; else lead">S</div>
                  <ng-template #lead>
                    <div *ngIf="prospect.associateId == null; else matchedLead">L</div>
                    <ng-template #matchedLead>
                      <div>S</div>
                    </ng-template>
                  </ng-template>
                </div>
                <ng-template #retention>
                  R
                </ng-template>
              </div>
              <div class="result-container-header-text">
                <div class="flex">
                  <h3 *ngIf="prospect.prospectFirstName; else freeform">
                    {{[prospect.prospectLastName, prospect.prospectFirstName] | nameSafety}} (ID: {{prospect.id}}) -
                    Rooftop: {{prospect.rooftopDisplayCode}}</h3>
                  <ng-template #freeform>
                    <h3>{{[prospect.prospectFreeformName] | nameSafety}} (ID: {{prospect.id}}) -
                      Rooftop: {{prospect.rooftopDisplayCode}}</h3>
                  </ng-template>
                </div>
                <span>Entered on {{prospect.prospectEnteredDate | moment:
                  'MMM DD, YYYY'}} and last updated on {{prospect.prospectUpdatedDate | moment:
                  'MMM DD, YYYY'}}</span>
                <span *ngIf="!prospect.openTasks" class="rounded-full w-max mt-1 text-xs text-red-400">No
                  Tasks</span>
              </div>
              <div class="result-container-header-actions">
                <button mat-icon-button type="button" (click)="openProspect(prospect.id)" [matTooltipPosition]="'above'"
                  [matTooltip]="prospect.salespersonId ? 'Open Prospect' : 'Assign Salesperson'">
                  <mat-icon color="primary" class="prospect-icon">
                    person
                  </mat-icon>
                </button>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="result-container-content">
              <div class="result-container-content-line">
                <mat-icon class="important-icon" [ngClass]="{'bold_label': !prospect.salespersonId}">label_important
                </mat-icon>
                <span [ngClass]="{'bold': !prospect.salespersonId}">
                  {{prospect.salespersonId ? 'Currently assigned to ' + ([prospect.salespersonLastName,
                  prospect.salespersonFirstName] | nameSafety) : 'Please assign this prospect to a salesperson'}}</span>
              </div>

              <div class="result-container-content-line">
                <mat-icon class="important-icon" [ngClass]="{'bold_label': !prospect.associateId}">label_important
                </mat-icon>
                <span [ngClass]="{'bold': !prospect.associateId}">{{prospect.associateId ? 'Matched' :
                  'Unmatched'}}</span>
              </div>

              <div class="result-container-content-line">
                <mat-icon class="important-icon">label_important</mat-icon>
                <span class="secondary-text">Has been in the system for
                  {{prospect.daysOpen > 1 ? prospect.daysOpen + ' days' : prospect.daysOpen + ' day' }}</span>
              </div>

              <div class="result-container-content-line">
                <mat-icon class="important-icon">label_important</mat-icon>
                <span class="secondary-text">Sourced via {{prospect.prospectSourceDescription}} through
                  {{prospect.prospectChannelDescription}}
                  channel</span>
              </div>

              <div class="result-container-content-line">
                <mat-icon class="important-icon">label_important</mat-icon>
                <span *ngIf="prospect.vendorId; else noVendor" class="secondary-text">Added via
                  {{prospect.vendorName}}</span>
                <ng-template #noVendor>
                  <span class="secondary-text">Added by the dealership</span>
                </ng-template>
              </div>


            </div>
          </div>
        </div>
        <div class="result-message-container">
          <span class="mat-title" *ngIf="(isLoading$ | async); else checkError">Loading...</span>
          <ng-template #checkError>
            <span class="mat-title mat-error" *ngIf="(hasError$ | async); else noData">Error retrieving results.</span>
            <ng-template #noData>
              <span class="mat-title" *ngIf="searchResult.data.length === 0 && searchPerformed">No results found.</span>
            </ng-template>
          </ng-template>
        </div>
        <mat-paginator [length]="searchResult.headers?.total" [pageSize]="prospectLeadForm.value.pageSize"
          [pageIndex]="prospectLeadForm.value.page" (page)="pageChanged($event, prospectLeadForm)" [pageSizeOptions]="">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>