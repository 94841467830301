export class WorkOrderQueryParameters {
  appointmentDateFrom?: Date | string;
  appointmentDateTo?: Date | string;
  appointmentId?: string;
  autoAssign?: string;
  claimContinuationId?: string;
  claimSubmissionTypeId?: string;
  customerId?: string;
  dateInFrom?: Date | string;
  dateInTo?: Date | string;
  dateOutFrom?: Date | string;
  dateOutTo?: Date | string;
  embed?: string;
  employeeId?: string;
  hasServiceVisitId?: string;
  id?: string;
  leaseOperatorId?: string;
  licenseNumber?: string;
  page?: number;
  pageSize?: number;
  realDateInFrom?: Date | string;
  realDateInTo?: Date | string;
  rooftopId?: string;
  salesHeaderId?: string;
  serviceAdvisorId?: string;
  serviceCode?: string;
  serviceVisitId?: string;
  sort?: string;
  statusId?: string;
  technicianGroupId?: string;
  technicianId?: string;
  tillId?: string;
  vehicleId?: string;
  vehicleStatusId?: string;
  vehicleTypeId?: string;
  vehicleWarrantyId?: string;
  caseStatusId?: string;

  constructor(obj?: WorkOrderQueryParameters) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
